var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"form-group col-4"},[_c('label',{attrs:{"for":"sigla"}},[_vm._v("Sigla")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grupo.sigla),expression:"grupo.sigla"}],staticClass:"form-control form-control-border",class:{
                'is-invalid':_vm.isSaving && _vm.$v.grupo.sigla.$error},attrs:{"type":"text","id":"sigla"},domProps:{"value":(_vm.grupo.sigla)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.grupo, "sigla", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"valor1"}},[_vm._v("Valor no Estado")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grupo.valor1),expression:"grupo.valor1"}],staticClass:"form-control form-control-border",class:{
                'is-invalid': _vm.isSaving && _vm.$v.grupo.valor1.$error},attrs:{"type":"number","id":"valor1"},domProps:{"value":(_vm.grupo.valor1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.grupo, "valor1", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"valor2"}},[_vm._v("Valor para fora do Estado")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grupo.valor2),expression:"grupo.valor2"}],staticClass:"form-control form-control-border",class:{
                'is-invalid': _vm.isSaving && _vm.$v.grupo.valor2.$error},attrs:{"type":"number","id":"valor2"},domProps:{"value":(_vm.grupo.valor2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.grupo, "valor2", $event.target.value)}}})])]),_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"form-group col-lg"},[_c('label',{attrs:{"for":"descricao"}},[_vm._v("Descrição")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grupo.descricao),expression:"grupo.descricao"}],staticClass:"form-control form-control-border",class:{
                'is-invalid': _vm.isSaving && _vm.$v.grupo.descricao.$error},attrs:{"type":"text","id":"sigla"},domProps:{"value":(_vm.grupo.descricao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.grupo, "descricao", $event.target.value)}}})])]),_c('label',{staticClass:"px-3"},[_vm._v("DECRETO nº 28.862")]),_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"estado"}},[_vm._v("Valor no Estado")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grupo.valorEstado),expression:"grupo.valorEstado"}],staticClass:"form-control form-control-border",class:{
                'is-invalid': _vm.isSaving && _vm.$v.grupo.valorEstado.$error},attrs:{"type":"number","id":"estado"},domProps:{"value":(_vm.grupo.valorEstado)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.grupo, "valorEstado", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"fora_estado"}},[_vm._v("Valor para fora do Estado")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grupo.valorForaEstado),expression:"grupo.valorForaEstado"}],staticClass:"form-control form-control-border",class:{
                'is-invalid': _vm.isSaving && _vm.$v.grupo.valorForaEstado.$error},attrs:{"type":"number","id":"fora_estado"},domProps:{"value":(_vm.grupo.valorForaEstado)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.grupo, "valorForaEstado", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"fora_pais"}},[_vm._v("Valor para fora do País")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grupo.valorForaPais),expression:"grupo.valorForaPais"}],staticClass:"form-control form-control-border",class:{
                'is-invalid': _vm.isSaving && _vm.$v.grupo.valorForaPais.$error},attrs:{"type":"number","id":"fora_pais"},domProps:{"value":(_vm.grupo.valorForaPais)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.grupo, "valorForaPais", $event.target.value)}}})])]),_c('div',{staticClass:"card-body",staticStyle:{"padding-bottom":"0px","padding-right":"0px","padding-left":"0px"}},[_c('button',{staticClass:"btn float-right text-color-principal background-principal ml-1",on:{"click":_vm.salvarGrupo}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Salvar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.LimparGrupo.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-ban"}),_vm._v(" Limpar ")])]),_c('div',{staticClass:" card-body pt-5"},[_c('table',{staticClass:"table table-hover table-responsive-sm table-striped"},[_vm._m(0),_c('tbody',_vm._l((_vm.grupos),function(grupodiaria,i){return _c('tr',{key:i,staticClass:"text-center clicavel",on:{"click":function($event){return _vm.escolherGrupo(grupodiaria.id)}}},[_c('td',[_vm._v(_vm._s(grupodiaria.id))]),_c('td',[_vm._v(_vm._s(grupodiaria.sigla))]),_c('td',[_vm._v(_vm._s(grupodiaria.descricao))]),_c('td',[_vm._v("R$ "+_vm._s(grupodiaria.valor1))]),_c('td',[_vm._v("R$ "+_vm._s(grupodiaria.valor2))]),_c('td',[_vm._v("R$ "+_vm._s(grupodiaria.valorEstado))]),_c('td',[_vm._v("R$ "+_vm._s(grupodiaria.valorForaEstado))]),_c('td',[_vm._v("U$ "+_vm._s(grupodiaria.valorForaPais))])])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Código")]),_c('th',[_vm._v("Sigla")]),_c('th',{staticClass:"col-6 text-center"},[_vm._v("Descrição")]),_c('th',[_vm._v("Valor 1")]),_c('th',[_vm._v("Valor 2")]),_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Fora do estado")]),_c('th',[_vm._v("Fora do país")])])])}]

export { render, staticRenderFns }