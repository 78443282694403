<template>

    <div>

      <b-modal
        id="modal-18"
        ref="modal-18"
         title="Cadastro de Anotação"
        data-backdrop="static"
        size="xl"
        cancel-disabled
        no-close-on-backdrop
        hide-header-close
        header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            "
        content-class="modal-content border-rounded-detail"
        dialog-class="p-3"
        title-class="modal-title mx-auto xl"
        >
        <div class="card-body pt-0">

          <!-- <h6 class="mt-4 mb-3"><b>Funcionário: </b> {{this.$store.state.funcionario.nomePessoaFuncionario}}</h6> -->
          <h6></h6>
          <div class="row">
            
            <!-- <div class="form-group col-lg-3">
              <label for="cadanotacao-matricula">Matricula</label>
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="cadanotacao-matricula"
                placeholder="xxx.xxx.xxx"
                v-model="funcionarioInfo.matricula"
                :class="{
                'is-invalid': isSaving && $v.anotacao.matricula.$error}"
              />
            </div>
               -->
            <div class="form-group col-lg-3">
              <label for="cadanotacao-tipo">Tipo</label>
              <v-select
                class="border-v-select mt-1"
                id="cadanotacao-tipoAnotacao"
                v-model="anotacao.tipoAnotacao"
                :options="tipos"
                label="descricao"
                >
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </div>
              
            <div class="form-group col-lg-3">
              <label for="cadanotacao-tipoDocumento">Documento</label>
              <v-select
                class="border-v-select mt-1"
                id="cadanotacao-tipoDocumento"
                v-model="anotacao.tipoDocumento"
                :options="documentos"
                label="descricao"
                >
                <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
            </div>

            <div class="form-group col-lg-3">
              <label for="cadanotacao-data">Data</label>
              <input
                type="date"
                class="form-control form-control-border border-width-2"
                id="cadanotacao-dataAnotacao"
                placeholder="dd/mm/aaaa"
                v-model="anotacao.dataAnotacao"
              />
            </div>

            
            <div class="form-group col-lg-3">
              <label class="form-control-label" for="cadanotacao-numero"
                >Número
                </label>
                <input
                  type="text"
                  class="form-control form-control-border border-width-2"
                  name="numeroDocumento"
                  id="cadanotacao-numeroDocumento"
                  data-cy="numero"
                  :class="{
                      'is-invalid': isSaving && $v.anotacao.numeroDocumento.$error}"
                      v-model="anotacao.numeroDocumento"
                />
            </div>

          </div>

          <div class="row">
            <div class="form-group col-lg-4">
              <label for="cadanotacao-dataDocumento">Data Documento</label>
              <input
                type="date"
                class="form-control form-control-border border-width-2"
                id="cadanotacao-dataDocumento"
                placeholder="dd/mm/aaaa"
                v-model="anotacao.dataDocumento"
                :class="{
                'is-invalid': isSaving && $v.anotacao.dataDocumento.$error}"
              />
            </div>

            

              <div class="form-group col-lg-4">
                <label class="form-control-label" for="cadanotacao-dataInicio"
                  >Data Início</label>
                    <div class="d-flex">
                      <input
                        type="date" data-cy="dataInicio" class="form-control form-control-border border-width-2" id="cadanotacao-dataInicio"
                        placeholder="dd/mm/aaaa"  v-model="anotacao.dataInicio"
                        :class="{
                        'is-invalid': isSaving && $v.anotacao.dataInicio.$error}"
                      />
                    </div>
              </div>
      
              <div class="form-group col-lg-4">
                <label class="form-control-label" for="cadanotacao-dataFim"
                  >Data Fim
                </label >
                <div class="d-flex">
                  <input
                      type="date" data-cy="dataFim" class="form-control form-control-border border-width-2" id="cadanotacao-dataFim"
                      placeholder="dd/mm/aaaa" v-model="anotacao.dataFim" :class="{
                      'is-invalid': isSaving && $v.anotacao.dataFim.$error}"
                      
                  />
                </div>
              </div>
          </div>

          <div class="row">

            <div  class="form-group col-lg-6" >
                <p><label  for="cadanotacao-observacao1">Informação</label></p>
                <textarea type="text" name="observacao1" row="4" class="form-control" id="cadanotacao-observacao1"
                data-cy="observacao1" :class="{
                'is-invalid': isSaving && $v.anotacao.obs1.$error}" v-model="anotacao.obs1" ></textarea>
            </div>
            
              <div class="form-group col-lg-6">
                <p><label for="cadanotacao-observacao2">Observação  </label></p>
                <textarea type="text" class="form-control" name="obs2" id="cadanotacao-observacao2"
                    data-cy="obs2"  v-model="anotacao.obs2" ></textarea>
              </div>

              <!-- <div class="form-group col-lg-6">
                <label class="form-control-label" for="cadanotacao-observacao">Observação 2 </label>
                <input type="text" class="form-control form-control-border border-width-2" name="obs2" id="cadanotacao-observacao2"
                    data-cy="obs2"  v-model="anotacao.obs2" />
              </div> -->
          
         
            
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label for="anotacao-supervidor">Supervisor(a)</label>
              <v-select class="border-v-select mt-1" id="anotacao-supervidor" v-model="anotacao.supervisorAssinatura"
                    :options="listaSupervisor" :reduce="(listaSupervisor) => listaSupervisor.id" label="nome">
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </div>
          </div>

        </div>


        <template #modal-footer="{ ok, cancel }">

                
          <button @click="[SalvarAnotacao(), ok()]" class="btn text-color-principal background-principal ml-1">
            <i class="far fa-envelope"></i> Salvar
          </button>

          <button @click.prevent="LimparAnotacao" type="reset" class="btn btn-default">
              <i class="fas fa-ban"></i> Limpar
          </button>
          <b-button
                      class="btn btn-default float-right"
          @click="[cancel(), apagarCampos()]"
          >
          <i class="fa-solid fa-x"></i>Fechar
          </b-button>

        </template> 
    </b-modal>
        
 
  </div>
  
</template>

<script>
import {DATE_FORMAT_LOCAL_DATA, } from "@/util/filter";
import dayjs from "dayjs";
import rhAnotacao from "@/service/RHAnotacaoService"
import rhDiarias from "@/service/RhDiariasService"
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import ConsultaFuncionarioService from '@/service/ConsultaFuncionarioService';
import rhService from "@/service/RHService";
import ConsultaAnotacao from "@/components/rh/anotacao/ConsultaAnotacao.vue"
import { EventBus } from "../../../main.js";

export default {
  name: "CadastroAnotacao",

  components:{
    ConsultaAnotacao
  },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    },
  },

  data(){
    return{
      data:{},
      anotacao:{
        funcionarioCadastrante: null,
      },
      submitted: false,
      isSaving: false,
      documentos:[],
      tipos:[],
      idFuncionario: this.$store.state.funcionario.idFuncionarioCadastrado,
      funcionarioInfo: {},
      ferias:{},
      listaSupervisor: {},
    };
  },

  

    validations:{
        anotacao:{
            // matricula: {},
            tipoAnotacao:{},
            tipoDocumento:{},
            dataAnotacao:{},
            numeroDocumento:{},
            dataDocumento:{},
            //informacao:{},
            dataInicio:{},
            dataFim:{},
            obs1:{},
            obs2:{},
        },
    },


  created(){
    this.$store.state.funcionario
    this.consultarInfoFuncionario();
    this.carregarAnotacoes();
    this.buscaSupervisor();
    
  },

  methods:{

    buscaSupervisor() {
        rhService
        .buscaSupervisors()
        .then((response) => {
            this.listaSupervisor = response.data;
        });
    },

    apagarCampos(){
      this.anotacao={}
    },
    consultarInfoFuncionario () {
      consultaFuncionarioService.buscarFuncionarioId(this.$store.state.funcionario.idFuncionarioCadastrado)
      .then((response) => {
        this.funcionarioInfo = response.data
        //console.log(this.funcionarioInfo);
      });
    },
    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

     updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.anotacao[field] = dayjs(
        event.target.value,
          DATE_FORMAT_LOCAL_DATA
       );
     } else {
        this.anotacao[field] = null;
      }
    },

   



    SalvarAnotacao(){

       this.isSaving = true;
      this.$v.$touch();

      if (this.$refs["cadastro-anotacao"]!= undefined){
        this.anotacao = Object.assign(
          {},
          this.anotacao,
          this.$refs["cadastro-anotacao"].anotacao
        );

        this.$refs["cadastro-anotacao"].isSaving = this.isSaving;
        if (this.$v.$ivalid || this.$refs["cadastro-anotacao"].$v.$ivalid){
          return;
        } else{
          if (this.$v.$ivalid){
            return;


          }
        } 

      }

      this.anotacao.funcionarioCadastrante = this.usuario.idFuncionario;
       this.anotacao.funcionario = {
         id : this.idFuncionario
       }
    
      rhAnotacao.criarAnotacao(this.anotacao)
      .then((response)=> {
        this.anotacao= response.data;
        Toast.fire("Cadastrado realizado", "", "success");
        this.anotacao={};
        console.log("anotacao: " + this.anotacao)
        EventBus.$emit("AtualizarCadastroAnotacao");
      })
      .catch(()=>{
            Toast.fire("Falha ao cadastrar anotação", "", "error");
     });
     

    },

    carregarAnotacoes(){
      let user = JSON.parse(localStorage.getItem("user"));
      let token = user.token;
      rhDiarias.getAllTipoAnotacao(token)
        .then((response) => {
          this.tipos = response.data;
      })
        //  .catch(()=>
        //     Toast.fire("Falha ao buscar tipos de anotações", "", "error");
        //   });
     

    

    rhService.getAllTipoDocumento(token)
        .then((response) => {
          this.documentos = response.data;
        })
      //  .catch(()=>{
      //       Toast.fire("Falha ao buscar tipos de documentos", "", "error");
      //     });

    },

   
    LimparAnotacao(){
      this.anotacao = {};
      this.buscarFerias();
     
     
    },

    // consultaFuncionarioMatricula() {
    //   consultaFuncionarioService.buscarFuncionarioMatricula(pagina, matricula).then((response) => {
    //     this.matricula = response.data;
      
    // })
    // },


   

  }

 
  
  

}
</script>

<style scoped>



</style>



