<template>
  <div class="ml-2">
    <content-header title="Carteira Funcional" />
    <section class="content-header">
      <div class="card card-primary card-tabs">
        <cardMenu/>
        <conteudo v-role="'GDOCS'">
          <conteudoEmissor />
        </conteudo>
      </div>
    </section>
  </div>
</template>
  
  <script>
import conteudoEmissor from "@/components/emissor/NovaCarteira.vue";
import cardMenu from "@/components/emissor/MenuEmissor.vue";

export default {
  components: {
    conteudoEmissor,
    cardMenu,
  },

  data() {
    return {
      tituloBusca: "Nova Carteira",
      isModalVisible: false,
    };
  },
};
</script>
  
  <style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}
</style>
  