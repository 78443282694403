<template>
  <div class="card card-primary card-tabs">
    
    <div class="card-header background-principal p-0 pt-1">

      <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
        <li class="nav-item" >
          <a class="nav-link active show" 
          id="custom-tabs-one-tab" data-toggle="pill" href="#consulta" role="tab"
          ref="consulta" aria-controls="custom-tabs-one-profile" aria-selected="true" 
          @click="limparDiaria"
          >Consulta</a>
        </li>
        <li class="nav-item" >
          <a class="nav-link" 
          id="custom-tabs-one-tab" data-toggle="pill" href="#lancamentoalteracao" role="tab"
          aria-controls="custom-tabs-one-home" aria-selected="false" ref="lancamentoalteracao" 
          >Lançamento e Alteração</a>
        </li>
        <li class="nav-item" >
          <a class="nav-link" 
          id="custom-tabs-one-tab" data-toggle="pill" href="#encerramento" role="tab"
          ref="encerramento" aria-controls="custom-tabs-one-profile" aria-selected="false" 
          @click="limparDiaria"
          >Encerramento</a>
        </li>
        <li v-role="'SIGNATARIO_RH'" class="nav-item" >
        <!-- <li class="nav-item" > -->
          <a class="nav-link" id="custom-tabs-one-tab" data-toggle="pill" href="#pendentes" role="tab"
          ref="pendentes" aria-controls="custom-tabs-one-profile" aria-selected="false" @click="limparDiaria">Pendentes</a>
        </li>
        
      </ul>
    </div>
    <div class="card-body">
      <div class="tab-content" id="custom-tabs-one-tabContent" >
        <div class="tab-pane fade active show" id="consulta" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
          <Consulta/>
        </div>
        <div class="tab-pane fade" id="lancamentoalteracao" role="tabpanel" 
          aria-labelledby="custom-tabs-one-home-tab" >   
          <LancamentosAlteracoes/>
        </div>
        <div class="tab-pane fade" id="encerramento" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
          <Encerramento/>
        </div>
        <div class="tab-pane fade" id="pendentes" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
          <Pendentes/>
        </div>
        <div class="tab-pane fade" id="encerramento" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
          <ConsultaServidor/>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>

import Consulta from "@/components/rh/diaria/Consulta.vue";
import Boletim from "@/components/rh/diaria/Boletim.vue";
import Encerramento from "@/components/rh/diaria/Encerramento.vue"
import LancamentosAlteracoes from "@/components/rh/diaria/LancamentosAlteracoes.vue"
import Pendentes from "@/components/rh/diaria/ResultadoDiariasPendentes.vue"
import { EventBus } from '@/main';
import ConsultaServidor from "./ConsultaServidor.vue";
import ListaDiaria from "./ListaDiaria.vue";
/* import Consulta2 from "./Consulta2.vue" */

export default{
  components: {
    ConsultaServidor, Boletim, Encerramento, Pendentes, LancamentosAlteracoes, Consulta
  },
  created(){
    EventBus.$on("Alterar",(id)=>{
      this.$refs.lancamentoalteracao.click(id);
      EventBus.$emit("mostrarDiaria",id);
    })
    EventBus.$on("ListarDiarias", (buscaDiaria)=>{
      EventBus.$emit("listarDiarias", buscaDiaria)
    })
    EventBus.$on("LimparLancamento",()=>{
      EventBus.$emit("limparEdicao")
    })
  },
  data(){
    return{

    }
  },
  methods:{

    limparDiaria(){

      this.$store.commit("setAlternarTabsDiaria", true);
      console.log("limparDiaria");
      // this.$store.commit("setAlternarTabsDiaria", false);
    }

  }
}
</script>