<template>
  <conteudo>
    <section class="content" v-role="'ADMINISTRACAO'">
      <content-header title="Administração de Notificações" />
      <div class="container-fluid p-3">
        <div class="row">
          <div class="col-md-12 p-1">
            <VisualizacaoNotificacao @CadastrarNotificacoes="cadastrarNotificacaoShow" v-if="showNotificacoes" />
            <CadastrarNotificacoes @VisualizarNotificacoes="visualizarNotificaoShow" v-if="!showNotificacoes" />
          </div>
        </div>

      </div>


    </section>
  </conteudo>

</template>

<script>
import CadastrarNotificacoes from '@/components/administracao/notificacoes/CadastrarNotificacoes.vue';
import VisualizacaoNotificacao from '@/components/administracao/notificacoes/VisualizacaoNotificacao.vue';


export default {
  name: "NotificacoesView",
  components: { VisualizacaoNotificacao, CadastrarNotificacoes },
  data() {
    return {
      showNotificacoes: true,
    }
  },
  methods: {
    visualizarNotificaoShow() {
      this.showNotificacoes = true
    },
    cadastrarNotificacaoShow() {
      this.showNotificacoes = false
    }
  }
}
</script>
