<template>

  <body class="hold-transition login-page">
    <form>
      <div div class="login-box">
        <div class="card card-primary">
          <div class="card-header p-0 d-flex justify-content-center background-principal text-color-principal">

            <a href="/">
              <img src="../assets/logo_ssp_login.png" :alt="$store.state.app.name"
                class="img-responsive py-2 px-4 w-100" />
            </a>


          </div>
          <Loader :active="loaderActive" message="Alterando senha..." />
          <div class="card-body" v-if="!loaderActive">
            <div class="pb-1 d-flex justify-content-center col-12">
              <h4 class="text-color-secundary"> Redefina sua senha: </h4>
            </div>
            <div class="m-10 p-0 pb-1">
              <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                No mínimo 8 caracteres.
              </p>
              <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                1 letra minúscula.
              </p>
              <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                1 letra maiúscula.
              </p>
              <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                1 número ou 1 caracter especial ( !, @, #, $, %, &, *, _ ).
              </p>
            </div>
            <div class="form-group">
              <label for="inputNumeroInicioFolha" class="pesquisar-numero-inicio-folha">Digite sua nova senha:</label>
              <input type="password" class="form-control form-control-border border-width-2" id="novaSenha"
                autocomplete="off" placeholder="Digite a nova senha" v-model="novaSenha" required />
            </div>
            <div class="form-group">
              <label for="inputNumeroFimFolha" class="pesquisar-numero-fim-folha">Confirme sua nova senha:</label>
              <input type="password" class="form-control form-control-border border-width-2" id="confirmarNovaSenha"
                autocomplete="off" placeholder="Digite a senha novamente" v-model="confirmeNovaSenha" required />
            </div>
          </div>

          <div class="card-footer">
            <button @click.prevent="resetarSenhaPrimeiroAcesso" v-on:keyup.enter="resetarSenhaPrimeiroAcesso"
              type="submit" class="btn background-principal text-color-principal">
              <i class="fa-solid fa-lock"></i>
              Redefinir Senha
            </button>
          </div>
        </div>

      </div>

    </form>

  </body>
</template>

<script>
import RecuperarSenhaService from '@/service/RecuperarSenhaService';
import Loader from './Loader.vue';

export default {
  name: "ResetSenhaComponent",
  components: { Loader },
  data() {
    return {
      novaSenha: "",
      confirmeNovaSenha: "",
      alerta: false,
      resultado: "",
      loaderActive: false,
    };
  },
  methods: {
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    alertaSenhaDiferente() {
      // eslint-disable-next-line no-undef
      Toast.fire("Preencha os dois campos igualmente!", "", "error");
    },
    alertaSenhaErrada() {
      Toast.fire("Preencha corretamente a senha", "", "error");
    },
    alertaErroCadastrar() {
      Toast.fire("Erro ao tentar redefinir a senha", "", "error");
    },
    alertaSucessoCadastro() {
      Toast.fire("Senha cadastrada com sucesso", "", "success");
    },
    validaSenha() {
      if (this.novaSenha == this.confirmeNovaSenha) {
        if (this.novaSenha.match(/^(?=.*[0-9!@#%&*_])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#%&*_]{8,})$/)) {
          return true;
        }
        else {
          this.alertaSenhaErrada();
          this.alerta = true;
          return false;
        }
      }
      else {
        this.alertaSenhaDiferente();
        return false;
      }
    },
    resetarSenhaPrimeiroAcesso() {
      if (this.validaSenha()) {
        this.showLoader;
        try {
          RecuperarSenhaService.resetarSenhaPrimeiroAcesso(this.$store.state.auth.user.usuario.login, this.novaSenha)
            .then((response) => {
              this.resultado = response.data;
              this.hideLoader
              this.alertaSucessoCadastro();
              this.$emit("irTelaLogin");
            }).catch((e) => {
              this.hideLoader();
              this.alertaErroCadastrar();
            });
        }
        catch (e) {
          this.hideLoader
          alert("ERRO: " + e.message);
        }
      }
    }
  }
}
</script>
