<template>
  <section class="content">
    <content-header title="Auditoria" />
    <div class="ml-2">
      <section class="content-header">
        <filtroPesquisaDocumento />
        <div class="card card-primary card-tabs">
          <cardMenu />
          <conteudo v-role="'AUDITORIA'">
            <conteudoAuditoria />
          </conteudo>
        </div>
      </section>
    </div>
  </section>
</template>
<script>
import conteudoAuditoria from "@/components/Auditoria/ConteudoAuditoria.vue";

export default {
  components: {
    conteudoAuditoria,
  },
};
</script>