<template>
  <div>
    <div class="row">
      <div class="form-group col-lg-4">
        <label for="ano">Ano</label>
        <input
          type="text"
          class="form-control form-control-border border-width-2"
          id="ano"
          v-model="boletim.ano"
          :class="{
            'is-invalid': isSaving && $v.boletim.ano.$error,
          }"
        />
      </div>
      <div class="form-group col-lg-4">
        <label for="mes">Mês</label>
        <v-select
          class="border-v-select mt-1"
          id="mes"
          v-model="boletim.mes"
          :options="meses"
        >
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>
      <div class="form-group col-lg-4">
        <label for="assinatura1">Assinatura 1</label>
        <input
          type="text"
          class="form-control form-control-border border-width-2"
          id="assinatura1"
          v-model="boletim.assinatura1"
          :class="{
            'is-invalid': isSaving && $v.boletim.assinatura1.$error,
          }"
        />
      </div>
    </div>

    <div class="row">
      
      <div class="form-group col-lg-4">
        <label for="cargo1">Cargo 1</label>
        <input
          type="text"
          class="form-control form-control-border border-width-2"
          id="cargo1"
          v-model="boletim.cargo1"
          :class="{
            'is-invalid': isSaving && $v.boletim.cargo1.$error,
          }"
        />
      </div>
      <div class="form-group col-lg-4">
        <label for="assinatura2">Assinatura 2</label>
        <input
          type="text"
          class="form-control form-control-border border-width-2"
          id="assinatura2"
          v-model="boletim.assinatura2"
          :class="{
            'is-invalid': isSaving && $v.boletim.assinatura2.$error,
          }"
        />
      </div>
      <div class="form-group col-lg-4">
        <label for="cargo2">Cargo 2</label>
        <input
          type="text"
          class="form-control form-control-border border-width-2"
          id="cargo2"
          v-model="boletim.cargo2"
          :class="{
            'is-invalid': isSaving && $v.boletim.cargo2.$error,
          }"
        />
      </div>
    </div>

    <div class="float-right">
      <button
        @click="ImprimirBoletim"
        class="btn btn-default dropdown-toggle"
        data-toggle="dropdown"
      >
        <i class="fa fa-print"></i> Imprimir
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item" @click="ImprimirComMissao()"
          >Boletim com missão</a
        >
        <a class="dropdown-item" @click="ImprimirSemMissao()"
          >Boletim sem missão</a
        >
      </div>
      <button
        @click.prevent="LimparBoletim"
        type="reset"
        class="btn btn-default"
      >
        <i class="fas fa-ban"></i> Limpar
      </button>
    </div>
    <pdf-diaria />
  </div>
</template>

<script>
import RHService from "@/service/RHService";
import PdfDiaria from "./PdfDiaria.vue";

export default {
  components: { PdfDiaria },
  name: "Boletim",
  comments: {
    PdfDiaria,
  },

  data() {
    return {
      boletim: {
        ano: "",
        mes: "",
        assinatura1: "",
        assinatura2: "",
        cargo1: "",
        cargo2: "",
      },
      boletimDados: {},
      meses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      submitted: false,
      isSaving: false,
    };
  },
  validations: {
    boletim: {
      ano: {},
      mes: {},
      assinatura1: {},
      assinatura2: {},
      cargo1: {},
      cargo2: {},
      tipo: {},
    },
  },
  methods: {
    ImprimirBoletim() {},
    ImprimirSemMissao() {
      console.log("ImprimirSemMissao");
      console.log(this.boletim);

      let user = JSON.parse(localStorage.getItem("user"));
      let token = user.token;
      let mes = this.getMesInt(this.boletim.mes);

      RHService.getBoletimDados(this.boletim.ano, mes, token).then((resp) => {
        console.log(resp);
        this.boletimDados = resp.data;
        this.$root.$emit("emitirPdfDiarias", this.boletimDados, this.boletim);
      });
    },
    ImprimirComMissao() {
      console.log("ImprimirComMissao");
    },

    LimparBoletim() {
      this.boletim = {};
    },
    getMesInt(mesStr) {
      let mesDict = {
        Janeiro: 1,
        Fevereiro: 2,
        Março: 3,
        Abril: 4,
        Maio: 5,
        Junho: 6,
        Julho: 7,
        Agosto: 8,
        Setembro: 9,
        Outubro: 10,
        Novembro: 11,
        Dezembro: 12,
      };
      return mesDict[mesStr];
    },
  },
  mounted() {},
};
</script>