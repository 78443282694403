<template>
  <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Consulta SIGMA" />
    <conteudo v-role="'CONSULTA_CHEQUE'">
      <div class="container-fluid p-3">
        <DetalhesChequeConsulta v-show="isModalVisible" @closeModalCheque="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisible"></div>
        <div class="row">
          <div class="col-md-3 pr-1">
            <FormChequeConsulta />
          </div>

          <div class="col-md-9 pl-1">
            <ResultadosChequeConsulta @showModalCheque="showModal" />
          </div>
        </div>

      </div>

    </conteudo>

  </section>

</template>

<script>
import DetalhesChequeConsulta from '@/components/consultas/cheque/DetalhesChequeConsulta.vue'
import FormChequeConsulta from '@/components/consultas/cheque/FormChequeConsulta.vue'
import ResultadosChequeConsulta from '@/components/consultas/cheque/ResultadosChequeConsulta.vue'
import { EventBus } from '../../../main.js'
export default {
  name: 'ViewConsultaCheque',
  components: { FormChequeConsulta, ResultadosChequeConsulta, DetalhesChequeConsulta },
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      EventBus.$emit('esconderBoCardComponent')
    }
  }
}
</script>
