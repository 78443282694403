<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-nomeDestino">Destinatário <span
              class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border" name="nomeDestino" id="documento-nomeDestino"
            data-cy="nomeDestino" required v-model="documento.nomeDestino"
            :class="{ 'is-invalid': isSaving && !$v.documento.nomeDestino.required }" />
          <div v-if="isSaving && !$v.documento.nomeDestino.required" class="invalid-feedback">
            Destinatário é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-cargoDestino">Cargo/Função destinatário <span
              class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border" name="cargoDestino" id="documento-cargoDestino"
            data-cy="cargoDestino" v-model="documento.cargoDestino"
            :class="{ 'is-invalid': isSaving && !$v.documento.cargoDestino.required }" />
          <div v-if="isSaving && !$v.documento.nomeDestino.required" class="invalid-feedback">
            Cargo do destinatário é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-tratamento">Tratamento <span
              class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border" name="tratamento" id="documento-tratamento"
            data-cy="tratamento" v-model="documento.tratamento" :class="{
              'is-invalid': isSaving && !$v.documento.tratamento.required,
            }" />
          <div v-if="isSaving && !$v.documento.tratamento.required" class="invalid-feedback">
            Tratamento é obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-enderecoDestino">Endereço <span
              class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border" name="enderecoDestino"
            id="documento-enderecoDestino" data-cy="enderecoDestino" v-model="documento.enderecoDestino" :class="{
              'is-invalid': isSaving && !$v.documento.enderecoDestino.required,
            }" />
          <div v-if="isSaving && !$v.documento.enderecoDestino.required" class="invalid-feedback">
            Endereço é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-controleProcesso">e-Processos</label>
          <input type="text" class="form-control form-control-border" name="controleProcesso"
            id="documento-controleProcesso" data-cy="controleProcesso" v-model="documento.controleProcesso" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-assunto">Signatário <span
              class="text-danger">*</span></label>
          <v-select class="border-v-select" :class="{
            'is-invalid': isSaving && !$v.documento.fkUsuario.required,
          }" id="documento-fkUsuario" data-cy="fkUsuario" name="fkUsuario" v-model="documento.fkUsuario"
            :options="funcionariostodos" label="nome">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="isSaving && !$v.documento.fkUsuario.required" class="invalid-feedback">
            Signatário é obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-cargoSignatario">Cargo/Função Signatário <span
              class="text-danger">*</span></label>
          <v-select class="border-v-select" name="cargoSignatario" id="documento-cargoSignatario"
            data-cy="cargoSignatario" v-model="documento.cargoSignatario" :class="{
              'is-invalid': isSaving && !$v.documento.cargoSignatario.required,
            }" :options="funcoes" :reduce="(funcoes) => funcoes.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="isSaving && !$v.documento.cargoSignatario.required" class="invalid-feedback">
            Cargo/função do signatário é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-referencia">Referência</label>
          <input type="text" class="form-control form-control-border" name="referencia" id="documento-referencia"
            data-cy="referencia" v-model="documento.referencia" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-assunto">Assunto <span class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border" name="assunto" id="documento-assunto"
            data-cy="assunto" v-model="documento.assunto" :class="{
              'is-invalid': isSaving && !$v.documento.assunto.required,
            }" />
          <div v-if="isSaving && !$v.documento.assunto.required" class="invalid-feedback">
            Assunto é obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-fecho">Fecho <span class="text-danger">*</span></label>
          <v-select class="border-v-select" id="documento-fecho" data-cy="fecho" name="fecho"
            v-model="documento.fecho" :class="{ 'is-invalid': isSaving && !$v.documento.fecho.required }"
            :options="fechos" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="isSaving && !$v.documento.fecho.required" class="invalid-feedback">
            Fecho é obrigatório
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import converteFuncionario from "@/util/converteFuncionario";
import { required } from "vuelidate/lib/validators";
import api from "../../service/DocumentoService";

export default {
  name: "Documentos",
  data() {
    return {
      documento: {},
      submitted: false,
      isSaving: false,
      funcionariostodos: [],
      fechos: [],
      funcoes: [],
      listaCopiaDocimento: []
    };
  },
  validations: {
    documento: {
      nomeDestino: { required },
      cargoDestino: { required },
      tratamento: { required },
      enderecoDestino: { required },
      fkUsuario: { required },
      cargoSignatario: { required },
      assunto: { required },
      fecho: { required },
    },
  },
  mounted() {
    // this.carregaSetores();
    this.carregaTodosFuncionario();
    this.carregaFecho();
    this.carregaFuncao();
  },
  methods: {
    carregaTodosFuncionario() {
      const listaFuncionario = api.buscarFuncionario().then((response) => {
        this.funcionariostodos =
          converteFuncionario.funcionarioDtoToFuncionarioArray(response.data);
      });
    },
    carregaFecho() {
      const listaFuncionario = api.buscarFecho().then((response) => {
        this.fechos = response.data;
      });
    },
    carregaFuncao() {
      const listaFuncionario = api.buscarFuncao().then((response) => {
        this.funcoes = response.data;
      });
    }
  },
};
</script>
