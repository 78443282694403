<template>
  <div class="container-fluid p-2">
    <b-card no-body>
      <b-tabs
        card
        nav-wrapper-class="background-principal pt-1"
        active-nav-item-class="text-dark"
        
      >
       <DadosPessoaFuncionario />
        <b-tab
          title="Cadastro de Servidor"
          active
          title-link-class="text-light"
        >
          <b-card-text
            v-if="
              this.$store.state.funcionario
                .visualizarListaFuncionariosExistentes
            "
          >
            <ListaFuncionariosExistentesPessoa />
          </b-card-text>
          <b-card no-body v-else>
            <!-- <DadosPessoaFuncionario /> -->
            <hr />
            <FormCadastroFuncionario />
          </b-card>
        </b-tab>
        <!-- <b-tab
          title="Perfil"
          title-link-class="text-light"
          :disabled="this.$store.state.funcionario.idFuncionarioCadastrado == 0"
        >
          <b-card no-body>
            <PerfilFuncionario />
          </b-card>
        </b-tab> -->
        <!-- !--ANOTAÇÕES-- -->
        <b-tab
          title="Anotações Servidor"
          title-link-class="text-light"
        >
        <AnotacaodeServidor/>
        </b-tab>
        
         <!-- <b-tab
          title="Histórico funcionário"
          title-link-class="text-light"
        >
        <ListaFuncionario/>
        </b-tab> -->
        <b-tab
          title="Férias"
          title-link-class="text-light"
        >
        <ListaFerias/>
        </b-tab>

        <!-- <b-tab
          title="Diárias"
          title-link-class="text-light"
        >
        <ListaDiarias/>
        </b-tab> -->
      
        <CadastroFerias/>
        <TransferenciaFerias/>
        <EditarFerias/>
        <DetalhesFerias/>
        <DetalhesAnotacao/>
        <EditarAnotacao/>
        <FeriasFrancionada/>
        <CadastroAnotacao/>
        <AnexoLimiteDiarias/>
        <Dependentes/>
        <EditarTransferencia/>

      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { EventBus } from "@/main";
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import DadosPessoaFuncionario from "./DadosPessoaFuncionario.vue";
import FormCadastroFuncionario from "./FormCadastroFuncionario.vue";
//import PerfilFuncionario from "./PerfilFuncionario.vue";
import ListaFuncionariosExistentesPessoa from "./ListaFuncionariosExistentesPessoa.vue";
import CadastroAnotacao from "@/components/rh/anotacao/CadastroAnotacao.vue";
// import ListaFuncionario from "@/components/rh/anotacao/ListaAnotacao.vue";
import ConsultaFeriasFuncionario from "@/components/rh/ferias/ConsultaFeriasFuncionario.vue";
import CadastroFerias from "@/components/rh/ferias/FeriasFuncionario.vue";
import TabsFeriasFuncionario from "@/components/rh/ferias/TabsFeriasFuncionario.vue";
import ListaFerias from "@/components/rh/ferias/ConsultaFerias.vue";
import ListaDiarias from "@/components/rh/diaria/ConsultaDiariasFuncionario.vue";
import TransferenciaFerias from "@/components/rh/ferias/TransferenciaFerias.vue";
import EditarFerias from "@/components/rh/ferias/EditarFerias.vue";
import DetalhesFerias from "@/components/rh/ferias/DetalhesFerias.vue";
import FeriasFrancionada from "@/components/rh/ferias/FeriasFracionadas.vue"
import AnotacaodeServidor from "@/components/rh/anotacao/ConsultaAnotacao.vue"
import DetalhesAnotacao from "@/components/rh/anotacao/DetalhesConsultaAnotacao.vue";
import EditarAnotacao from "@/components/rh/anotacao/EditarAnotacao.vue";
import AnexoLimiteDiarias from "@/components/administracao/funcionario/cadastro/AnexoLimiteDiarias.vue";
import Dependentes from "@/components/administracao/funcionario/cadastro/DependentesFuncionario.vue";
import EditarTransferencia from "@/components/rh/ferias/EditarTransferencia";
//import DiariasFuncionario from "@/components/rh/diaria/DiariasFuncionario.vue";

export default {
  name: "TabsCadastoFuncionario",
  components: {
    FormCadastroFuncionario,
    //PerfilFuncionario,
    DadosPessoaFuncionario,
    Loader,
    ListaFuncionariosExistentesPessoa,
    CadastroAnotacao,
    //ListaFuncionario,
    ConsultaFeriasFuncionario,
    CadastroFerias,
    TabsFeriasFuncionario,
    ListaFerias,
    ListaDiarias,
    TransferenciaFerias,
    EditarFerias,
    DetalhesFerias,
    FeriasFrancionada,
    AnotacaodeServidor,
    DetalhesAnotacao,
    EditarAnotacao,
    AnexoLimiteDiarias,
    Dependentes,
    EditarTransferencia,
    //DiariasFuncionario,
    // EditarFerias,
  },
  data() {
    return {
      idPessoa: this.$store.state.funcionario.idPessoaFuncionario,
      idFuncionario: undefined,
      // tabAtual: "cadastrofuncionario",
      // showFuncionarioCadastroConteudo: false,
      // showTabCadastroFuncionario: true,
      // showTabCadastroPerfil: false,
      // showBotaoSalvar: true,
      // showBotaoAvancar: false,
      funcionariosExistente: [],
      showFuncionariosExistentes: false,
      // loaderActive: false,
      edicaoFuncionario: false,
      // edicaoSenha: false,
      funcionarioDetalheReset: "",
      novaSenha: "",
      confirmeNovaSenha: "",
      alerta: false,
      resultado: "",
    };
  },
  created() {
    this.$store.commit("existeFuncionarioAtivo", false);
    this.buscaFuncionariosPessoa();


 
    // console.log(this.$route.params);
    // EventBus.$on("fecharModalEPesquisar", (listener) => {
    //   this.haveIdPessoa(listener)
    // });
    // EventBus.$on("cadastrouPerfis", (listener) => {
    //   this.cadastrouPerfis(listener)
    // })
    // EventBus.$on("desabilitarTabPerfil", () => {
    //   this.showTabCadastroPerfil = false
    // })
    // EventBus.$on("cadastrouFuncionario", (listener) => {
    //   this.idFuncionario=listener
    // })
    // EventBus.$on("editarFuncionario", (funcionario) => {
    //   this.idFuncionario=funcionario.id
    //   this.edicaoFuncionario=true
    //   this.showFuncionariosExistentes = false
    //   this.showFuncionarioCadastroConteudo = true
    // })
  },
  methods: {
    buscaFuncionariosPessoa() {
      if (this.$store.state.funcionario.idFuncionarioCadastrado == 0) {
        consultaFuncionarioService
          .buscarFuncionarioPorPessoaCorrespondenteId(this.idPessoa)
          .then((response) => {
            this.funcionariosExistente = response.data;
            if (this.funcionariosExistente.length !== 0) {
              this.$store.commit(
                "funcionariosExistentes",
                this.funcionariosExistente
              );
              this.loaderActive = false;
              this.$store.commit("visualizarListaFuncionariosExistentes", true);
              // this.showFuncionariosExistentes = true;
            } else {
              this.loaderActive = false;
              this.showFuncionariosExistentes = false;
            }
          })
          .catch((e) => {
            // this.loaderActive = false;
            // this.alertaErroAoPesquisarFuncionarios();
            // this.showFuncionarioCadastroConteudo = true;
          });
      }
    },
    // mostrarEdicaoSenha() {
    //   EventBus.$emit("editarSenhaFuncionario");
    // },
    // voltarVisualizacaoFuncionario() {
    //   EventBus.$emit("voltarVisualizacaoFuncionario");
    // },
    // cadastrouPerfis(listener) {
    //   this.showTabCadastroPerfil = false;
    // },
    // alertaErroAoPesquisarFuncionarios() {
    //   Toast.fire(
    //     "Ocorreu um erro ao pesquisar os funcionários associados a essa pessoa!",
    //     "Continue o cadastro, porém é possível que haja outro usuário associado a esta pessoa",
    //     "error"
    //   );
    // },
    // haveIdPessoa(listener) {
    //   this.loaderActive = true;
    //   this.idPessoa = listener;
    //   if (this.idPessoa !== undefined) {
    //     ConsultaFuncionarioService.buscarFuncionarioPorPessoaCorrespondenteId(
    //       this.idPessoa
    //     )
    //       .then((response) => {
    //         this.funcionariosExistente = response.data;
    //         if (this.funcionariosExistente.length !== 0) {
    //           this.loaderActive = false;
    //           if (!this.edicaoFuncionario) {
    //             this.showFuncionariosExistentes = true;
    //           }
    //         } else {
    //           this.loaderActive = false;
    //           this.showFuncionarioCadastroConteudo = true;
    //         }
    //       })
    //       .catch((e) => {
    //         this.loaderActive = false;
    //         this.alertaErroAoPesquisarFuncionarios();
    //         this.showFuncionarioCadastroConteudo = true;
    //       });
    //   } else {
    //     this.loaderActive = false;
    //     this.showFuncionarioCadastroConteudo = false;
    //   }

    //   // listener !== undefined ? this.showFuncionarioCadastroConteudo = true : this.showFuncionarioCadastroConteudo = false;
    // },
    // continuarCadastroFuncionario() {
    //   this.showFuncionariosExistentes = false;
    //   this.showFuncionarioCadastroConteudo = true;
    // },
    // cancelarCadastroFuncionario() {
    //   this.idPessoa = undefined;
    //   this.idFuncionario = undefined;
    //   this.showFuncionarioCadastroConteudo = false;
    //   this.funcionariosExistente = [];
    //   this.showFuncionariosExistentes = false;
    // },
    // cadastrouFuncionario() {
    //   this.showTabCadastroPerfil = true;
    //   this.showBotaoAvancar = true;
    // },
    // cadastroTabAtual(tab) {
    //   if (tab === "cadastrofuncionario") {
    //     this.tabAtual = "cadastrofuncionario";
    //   } else {
    //     this.tabAtual = "perfilfuncionario";
    //     this.showTabCadastroFuncionario = false;
    //     this.showBotaoAvancar = false;
    //     this.showBotaoSalvar = true;
    //   }
    // },
    // funcaoAvancar() {
    //   this.showBotaoAvancar = false;
    //   this.$refs.perfilfuncionario.click();
    // },
    // funcaoSalvar() {
    //   if (this.tabAtual === "cadastrofuncionario") {
    //     this.tabAtual = "cadastrofuncionario";
    //     EventBus.$emit("funcaoSalvar");
    //   } else {
    //     this.tabAtual = "perfilfuncionario";
    //     EventBus.$emit("funcaoSalvarPerfil");
    //   }
    // },
    // mudarTab() {
    //   this.showBotaoAvancar = false;
    //   this.showTabCadastroFuncionario = false;
    //   this.$refs.perfilfuncionario.click();
    // },
  },
};
</script>
<style scoped>
.text-link {
  color: #fff !important;
}

.text-link:hover {
  color: #fff !important;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}
</style>
