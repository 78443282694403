<template>
     
      <div class="card-body pt-0">
        <div class="ml-2">
          <div class="p-2">
        <FiltroTabelaContrato/>
          </div>
      
      </div>
  
    <Loader :active="loaderActive" message="Consultando..." />
    <table v-show="!loaderActive" class="table table-hover text-nowrap">
      <thead>
        <tr>
          <th>CnpjUg</th>
          <th>Numero do Contrato</th>
          <th>Ano do Contrato</th>
          <th>Cpf e Cnpj</th>
          <th>Ações</th>
          
      
        </tr>
      </thead>
      <tbody>
        <tr class= "clicavel" @click="funcao(contrato.id)" v-for = "(contrato, index) in contratos" :key="index">
          
          <td>{{ contrato.cnpjUg || "Sem registro"}}</td>
          <!--td>{{ contrato.numeroProcesso || "Sem registro"}}</td-->
          <!--td>{{ contrato.anoProcesso || "Sem registro"}}</td-->
          <td>{{ contrato.numeroContrato || "Sem registro" }}</td>
          <td>{{ contrato.anoContrato || "Sem registro" }}</td>
          <!--td>{{ contrato.idContratacao || "Sem registro"}}</td-->
          <!--td>{{ contrato.idContrato || "Sem registro"}}</td-->
          <td>{{ contrato.cpfCnpj || "Sem registro"}}</td>
          <!--td>{{ contrato.objeto || "Sem registro"}}</td-->
          <!--td>{{ updateZonedDateTimeField(contrato.dataAssinatura)}}</td-->
          <!--td>{{ updateZonedDateTimeField(contrato.dataInicio) }}></td-->
          <!--td>{{ updateZonedDateTimeField(contrato.dataFim) }}></td-->
          <!--td>{{ contrato.valor ||"Sem registro" }}</td-->
          <!--td>{{ contrato.tipo || "Sem registro" }}</td-->
          <!--td>{{ contrato.tipoId || "Sem registro" }}</td-->
            <td @click.stop>
            <button @click.prevent="downloadFile(contrato.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm gerar">
              <i class="fa-solid fa-file"></i>
            </button>
             <button @click.prevent="excluir(contrato.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm excluir">
              <i class="fa-solid fa-trash"></i>
            </button>
          </td>
        

         
        </tr>
      </tbody>
    </table>
    </div>

</template>

<script>
import processosService from '@/service/ProcessosService';
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import { EventBus } from '@/main';
import Loader from "../Loader.vue";
import "vue-pdf-app/dist/icons/main.css";
import FiltroTabelaContrato from "@/components/processos/FiltroTabelaContrato.vue";

import axios from 'axios';

export default ({
   components:{
      Loader,
      FiltroTabelaContrato
    },
   data(){
      return{
        loaderActive:false,
        contratos:{},
        contrato:{}
        }
      },

    methods:{

        showLoader() {
          this.loaderActive = true;
        },
        hideLoader() {
          this.loaderActive = false;
        },

       funcao(contrato){
        EventBus.$emit("AlterarTabContrato")
        EventBus.$emit("EnvioContrato",contrato)

      },
        //enviarFormulario(){
          
            //this.dados.push({
                //cnpjUg: this.cnpjUg,
                //numeroProcesso: this.numeroProcesso,
                //anoProcesso: this.anoProcesso,
                //numeroContrato: this.numeroContrato,
                //anoContrato: this.anoContrato,
                //idContratacao:this.idContratacao,
                //idContrato: this.idContrato,
                //cpfCnpj: this.cpfCnpj,
                //objeto: this.objeto,
                //dataAssinatura: this.dataAssinatura,
                //dataInicio: this.dataInicio,
                //dataFim: this.dataFim,
                //valor: this.valor,
                //tipo: this.tipo,
                //tipoId: this.tipoId
               
                
            //})
        //},
       

         updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
      },

      excluir(id){
        this.$swal.fire({
        title: "Você deseja excluir ata?",
        icon:"warning",
        showCancelButton: true,
        confirmButtonColor:"#3085d6",
        confirmButtonText: "#Excluir",
        cancelButtonText: "Cancelar",
        }).then((result) => {

          if(result.isConfirmed){
            processosService.apagarContrato(id).then((response) => {
            this.contratos = response.data;
            Toast.fire("Contrato excluído com sucesso!", "","success");
            this.buscarContrato();
            });

          }
          this.buscarContrato(0);

        });
      },

      buscarContrato(){
        processosService.buscarContrato().then((resp)=>{
        this.contratos= resp.data;
        });
      },
        
      desejaGerarArquivo(){
        let clickouGerar = event.target.classList.contains("gerar")
        if (!clickouGerar) {
          EventBus.$emit("GerarJSON");

      }

    
    },

    downloadFile(id) {
              axios({
                    url: `http://localhost:8089/api/download/contratos/${id}`, // File URL Goes Here
                    method: 'GET',
                   responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'contrato.json');
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
          }
    ,
    },
     

    // filters: {
    //formatarData: function (data) {
     // if (data == "" || data == null) {
      //  return 'Sem data registrada';
      //}
     // else {

       // const [date, horas] = data.split("T");
       // const[ano,mes,dia]=date.split("-");
       // const[hr,min,sec]=horas.split(":");


       // const dataHoraFormatada = `${dia}/${mes}/${ano} ${hr}:${min}:${sec.replace("Z","")}`;
     
       // return dataHoraFormatada;
     // }
   // },
  //},



created(){

  this.downloadFile();
  this.showLoader()
  processosService.buscarContrato(this.contratos).then((resp)=>{
        this.contratos= resp.data;
        this.hideLoader()
      }).catch((e) =>{
         this.hideLoader()
          Toast.fire("Erro ao consultar Contrato", "", "error");
      } );
}



});


</script>

<style scoped>

.clicavel{
  cursor: pointer;
}

</style>