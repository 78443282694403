<template>
<div>
        <b-modal
        id="modal-7"
        ref="modal-7"
        title="Consulta Férias"
        data-backdrop="static"
        size="xl"
        cancel-disabled
        no-close-on-backdrop
        @keydown.esc="closeModalLogin"
        hide-header-close
        header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            "
        content-class="modal-content border-rounded-detail"
        dialog-class="p-3"
        title-class="modal-title mx-auto my-0"
        >
        <div class="card card-primary card-tabs">
            <div class="card-header background-principal p-0 pt-1">

               <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                    <!--<li class="nav-item" >
                        <a class="nav-link active show" 
                        id="custom-tabs-one-home-tab" data-toggle="pill" href="#cadastroferias" role="tab"
                        ref="cadastroferias" aria-controls="custom-tabs-one-home" aria-selected="true" 
                        >Cadastro Férias</a>
                    </li> -->
                    <!-- <li class="nav-item">
                        <a class="nav-link "
                        id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#anotacaoferias" role="tab"
                        aria-controls="custom-tabs-one-profile" aria-selected="false" ref="anotacaoferias"
                        >Anotação Férias</a>
                    </li> -->

                   <li class="nav-item">
                        <a class="nav-link active show "
                        id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#consultaferias" role="tab"
                        aria-controls="custom-tabs-one-profile" aria-selected="false" ref="consultaferias"
                        >Consulta Férias</a>
                    </li> 

                    <!-- <li class="nav-item">
                        <a class="nav-link "
                        id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#transferenciaferias" role="tab"
                        aria-controls="custom-tabs-one-profile" aria-selected="false" ref="transferenciaferias"
                        >Transferência de Férias</a>
                    </li> -->

                    <!-- <li class="nav-item">
                        <a class="nav-link "
                        id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#emissaobi" role="tab"
                        aria-controls="custom-tabs-one-profile" aria-selected="false" ref="emissaobi"
                        >Emissão BI</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link "
                        id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#emissaoci" role="tab"
                        aria-controls="custom-tabs-one-profile" aria-selected="false" ref="emissaoci"
                        >Emissão CI</a>
                    </li> -->

                    <!-- <li class="nav-item">
                        <a class="nav-link "
                        id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#feriasgozadas" role="tab"
                        aria-controls="custom-tabs-one-profile" aria-selected="false" ref="feriasgozadas"
                        >Férias Gozadas</a>
                    </li> -->

                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="custom-tabs-one-tabContent" >
                    <div class="tab-pane fade" id="cadastroferias" role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab"  >
             
                        <CadastroFerias />
                    </div>
          
                <div class="tab-pane fade" id="anotacaoferias" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                    <AnotacaoFerias/>
                </div>

                <div class="tab-pane fade active show" id="consultaferias" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                    <ConsultaFerias/>
                </div>

                <div class="tab-pane fade" id="transferenciaferias" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                    <TransferenciaFerias/>
                </div>

                <!-- <div class="tab-pane fade" id="emissaobi" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                    <EmissaoBI/>
                </div>

                <div class="tab-pane fade" id="emissaoci" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                    <EmissaoCI/>
                </div> -->

                <div class="tab-pane fade" id="editarFerias" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                    <EditarFerias/>
                </div>

                <!-- <div  class="tab-pane fade" id="feriasgozadas" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                    <FeriasGozadas/>
                </div>
                  -->

                
            </div>
             </div>

           
        </div>
          <template #modal-footer="{ cancel }">
                <b-button
                            class="btn btn-default float-right"
                @click="[cancel(), apagarCamposFerias()]"
                @click.prevent="beforeClose"
                >
                <i class="fa-solid fa-x"></i>Fechar
                </b-button>
            </template>

</b-modal></div>
    
</template>

<script>

import CadastroFerias from "@/components/rh/ferias/CadastroFerias.vue";
import AnotacaoFerias from "@/components/rh/ferias/AnotacaoFerias.vue";
// import ConsultaFerias from "@/components/rh/ferias/ConsultaFerias.vue";
import ConsultaFerias from "@/components/rh/ferias/ConsultaFerias.vue";
import TransferenciaFerias from "@/components/rh/ferias/TransferenciaFerias.vue";
import EmissaoBI from "@/components/rh/ferias/EmissaoBI.vue";
import EmissaoCI from "@/components/rh/ferias/EmissaoCI.vue";
import FeriasGozadas from "@/components/rh/ferias/FeriasGozadas.vue";
import EditarFerias from "@/components/rh/ferias/EditarFerias.vue";

import { EventBus } from "@/main";

export default {
  components: {
    CadastroFerias,
    AnotacaoFerias,
    ConsultaFerias,
    TransferenciaFerias,
    //EmissaoBI,
    //EmissaoCI,
    FeriasGozadas,
    EditarFerias,
  },
  data() {
    return {
        // showTabelaFerias:false,
    
    };
  },

  created(){

    EventBus.$on("Ferias", ()=>{
        this.$refs.cadastroferias.click()
    })


  },

  methods:{

    apagarCamposFerias(){
        this.ferias = {};
    },

   
  },

 

  

//   methods:{
//       reRender(){
      
//     }


   
//   }
}
</script>

<style>

