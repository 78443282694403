import Vue from "vue";
import Vuex from "vuex";
// import { auth } from './auth'
import axios from "axios";

import AuthService from "../service/auth/auth.service.js";
import TokenService from "@/service/TokenService";

const user = {};
const valida = JSON.parse(localStorage.getItem("user"));

if (JSON.parse(localStorage.getItem("user"))){

  let token = JSON.parse(localStorage.getItem("user")).access_token;
  let tokenResolvido = TokenService.parseJwt(token);
  user.usuario = tokenResolvido.usuario;
  user.access_token = JSON.parse(localStorage.getItem("user")).access_token;

}

export const Versao = 'main';

export const ApiGateway = (() => {
  if (Versao === "desenvolvimento") {
    return "http://172.20.1.32:8010";
  } else if (Versao === "main") {
    return "https://gateway.ssp.ma.gov.br";
  } else {
    return "https://gatewaydev.ssp.ma.gov.br";
  }
})();

export const UrlAplicacaoFront = (() => {
  if (Versao === "desenvolvimento") {
    return "http://172.20.1.32:8000";
  } else if (Versao === "main") {
    return "https://sigma.ssp.ma.gov.br";
  } else {
    return "https://sigmatreinamento.ssp.ma.gov.br";
  }
})();

export const UrlApiOauth = (() => {
  if (Versao === "desenvolvimento") {
    return "http://172.20.1.32:8001";
  } else if (Versao === "main") {
    return "https://auth.ssp.ma.gov.br";
  } else {
    return "https://sichomoauth.ssp.ma.gov.br";
  }
})();


const auth = valida
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app: {
      name: "SIGMA",
      scope: "openid profile",
      url: UrlAplicacaoFront,
      urlOauth2: UrlApiOauth,
      baseUrlOauth: ApiGateway,
      client: "sigma",
    },
    auth,
    funcionario: {
      idPessoaFuncionario: 0,
      idFuncionarioCadastrado: 0,
      funcionarioSalvo: {},
      funcionariosExistentes: [],
      visualizarListaFuncionariosExistentes: false,
      existeFuncionarioAtivo: false,
      nomePessoaFuncionario: '',
    },
    usuario: {
      idPessoaUsuario: 0,
      idFuncionarioUsuario: 0,
      idUsuarioCadastrado: 0,
      usuarioSalvo: {},
      usuariosExistentes: [],
      visualizarListaUsuariosExistentes: false,
      existeUsuarioAtivo: false,
      nomePessoaUsuario: '',
    },
    fluxoCadastroPessoa: false,
    idPessoaFluxoCadastroPessoa: null,
    cadastroPessoaExterno: false,
    cadastroPessoaExternoVisita: false,
    resultadosBuscaPessoaVisita: [],
    pessoaFuncionario: [],
    pessoaUsuario: [],
    fluxo: null,
    funcionarioUsuario: [],
    cadastroUsuario: false,
    fluxoCadastroPessoaAndVisita: false,
    idPessoaFluxoCadastroPessoaAndVisita: null,
    fluxoCadastroPessoaAndFuncionario: false,
    idPessoaFluxoCadastroPessoaAndFuncionario: null,
    alternarTabsDiaria: false,
  },
  getters: {},
  mutations: {
    setIdPessoaFluxoCadastroPessoa(state, payload) {
      state.idPessoaFluxoCadastroPessoa = payload
    },
    setCadastroPessoaExterno(state, payload) {
      state.cadastroPessoaExterno = payload
    },
    setCadastroPessoaExternoVisita(state, payload) {
      state.cadastroPessoaExternoVisita = payload
    },
    fluxoCadastroPessoa(state, payload) {
      state.fluxoCadastroPessoa = payload
    },
    setResultadosBuscaPessoaVisita(state, payload) {
      state.resultadosBuscaPessoaVisita = payload
    },
    setFluxo(state, payload) {
      state.fluxo = payload
    },
    setPessoaFuncionario(state, payload) {
      state.pessoaFuncionario = payload
    },
    setPessoaUsuario(state, payload) {
      state.pessoaUsuario = payload
    },
    setIdPessoaFluxoCadastroPessoaAndVisita(state, payload) {
      state.idPessoaFluxoCadastroPessoaAndVisita = payload
    },
    fluxoCadastroPessoaAndVisita(state, payload) {
      state.fluxoCadastroPessoaAndVisita = payload
    },
    setIdPessoaFluxoCadastroPessoaAndFuncionario(state, payload) {
      state.idPessoaFluxoCadastroPessoaAndFuncionario = payload
    },
    fluxoCadastroPessoaAndFuncionario(state, payload) {
      state.fluxoCadastroPessoaAndFuncionario = payload
    },
    loginSuccess(state, user) {
      state.auth.user = user;
      state.auth.status.loggedIn = true;
    },
    loginFailure(state) {
      state.auth.status.loggedIn = false;
      state.auth.user = null;
    },
    logout(state) {
      state.auth.status.loggedIn = false;
      state.auth.user = null;
    },
    pessoaFuncionario(state, id) {
      state.funcionario.idPessoaFuncionario = id;
    },
    pessoaUsuario(state, id) {
      state.usuario.idPessoaUsuario = id;
    },
    funcionarioUsuario(state, id) {
      state.usuario.idFuncionarioUsuario = id;
    },
    funcionarioCadastrado(state, id) {
      state.funcionario.idFuncionarioCadastrado = id;
    },
    usuarioCadastrado(state, id) {
      state.usuario.idUsuarioCadastrado = id;
    },
    funcionarioEditar(state, funcionarioSalvo) {
      state.funcionario.funcionarioSalvo = funcionarioSalvo;
    },
    usuarioEditar(state, usuarioSalvo) {
      state.usuario.usuarioSalvo = usuarioSalvo;
    },
    funcionariosExistentes(state, funcionariosExistentes) {
      state.funcionario.funcionariosExistentes = funcionariosExistentes;
    },
    visualizarListaFuncionariosExistentes(state, visualizarListaFuncionariosExistentes) {
      state.funcionario.visualizarListaFuncionariosExistentes = visualizarListaFuncionariosExistentes;
    },
    existeFuncionarioAtivo(state, existeFuncionarioAtivo) {
      state.funcionario.existeFuncionarioAtivo = existeFuncionarioAtivo;
    },
    existeUsuarioAtivo(state, existeFuncionarioAtivo) {
      state.usuario.existeUsuarioAtivo = existeFuncionarioAtivo;
    },
    nomePessoaFuncionario(state, nomePessoaFuncionario) {
      state.funcionario.nomePessoaFuncionario = nomePessoaFuncionario;
    },
    setFuncionarioUsuario(state, funcionarioUsuario) {
      state.funcionarioUsuario = funcionarioUsuario;
    },
    setCadastroUsuario(state, cadastro) {
      state.cadastroUsuario = cadastro
    },
    setAlternarTabsDiaria(state, altera) {
      state.alternarTabsDiaria = altera;
    },
  },
  actions: {
    // login({ commit }, user) {
    //   return AuthService.login(user).then(
    //     (user) => {
    //       commit("loginSuccess", user);
    //       return Promise.resolve(user);
    //     },
    //     (error) => {
    //       console.error(error)
    //       commit("loginFailure");
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // login({ commit }, user) {

    //   return axios
    //   .post("http://127.0.0.1:8030/oauth2/token", user)
    //   .then((response) => {

    //     if (response.data.access_token) {
    //       localStorage.setItem("user", JSON.stringify(response.data));
    //     }

    //     //commit("loginSuccess", TokenService.parseJwt(response.data.access_token));
    //     return response.data;
    //   });

      // return AuthService.login(user).then(
      //   (user) => {
      //     commit("loginSuccess", user);
      //     return Promise.resolve(user);
      //   },
      //   (error) => {
      //     console.error(error)
      //     commit("loginFailure");
      //     return Promise.reject(error);
      //   }
      // );
    // },
    refreshToken({ commit }) {
      AuthService.refreshToken();
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    // consultaPessoaFuncionario({commit }, id) {
    //   commit("pessoaFuncionario", id);
    // },
  },
  modules: {},
});
