<template>
  <div class="modal fade show h-100 overflow-auto" id="modal-xl" style="display: block" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-xl p-3" @click.stop>
      <div class="p-4">
        <div class="modal-content border-rounded-detail">
          <div class="modal-header background-principal text-color-principal card-item-result">
            <h5 class="modal-title mx-auto my-0">Detalhes da Pessoa</h5>
          </div>
          <!-- <loader :active="loaderActive" message="Consultando..." /> -->

          <div class="modal-body p-4 max-height-content">
            <h6 class="mt-1 mb-3"><b>DADOS BÁSICOS: </b></h6>
            <div class="row">
              <div class="col">
                <p class="medium mb-0">
                  <b>Nome Completo: </b>{{ pessoa ? (pessoa.nomeCompleto || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Nome Completo da Mãe: </b>{{ pessoa ? (pessoa.nomeMae || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Data de nascimento: </b> {{ pessoa.dataNascimento | formatarDataNascimento }}
                </p>
                <p class="medium mb-0"><b>CPF: </b> {{ pessoa ? (pessoa.numeroCPF || 'Sem registro')
                    : 'Sem registro'
                }}
                </p>
                <p class="medium mb-0"><b>Sexo: </b> {{ pessoa ? (pessoa.sexo || 'Sem registro')
                    : 'Sem registro'
                }}</p>
                <p class="medium mb-0">
                  <b>Região Fiscal: </b> {{ pessoa ? (pessoa.regiaoFiscal || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Ano Exercicio da Ocupação: </b> {{ pessoa ? (pessoa.anoExercicio || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
              </div>
              <div class="col">
                <p class="medium mb-0">
                  <b>Telefone: </b>{{ pessoa ? (pessoa.telefone || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0"><b>DDD: </b>{{ pessoa ? (pessoa.ddd || 'Sem registro')
                    : 'Sem registro'
                }}</p>
                <p class="medium mb-0">
                  <b>Título de Eleitor: </b>{{ pessoa ? (pessoa.tituloEleitor || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Situação Cadastral: </b> {{ pessoa ? (pessoa.situacaoCadastral || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Ocupação Principal: </b> {{ pessoa ? (pessoa.ocupacaoPrincipal || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Natureza da Ocupacao: </b> {{ pessoa ? (pessoa.naturezaOcupacao || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
              </div>
            </div>
            <hr />
            <h6 class="mt-1 mb-3"><b>ENDEREÇO: </b></h6>
            <div class="row">
              <div class="col">
                <p class="medium mb-0"><b>CEP: </b> {{ pessoa ? (pessoa.cep || 'Sem registro')
                    : 'Sem registro'
                }}</p>
                <p class="medium mb-0">
                  <b>Município: </b> {{ pessoa ? (pessoa.municipio || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0"><b>Bairro: </b> {{ pessoa ? (pessoa.bairro || 'Sem registro')
                    : 'Sem registro'
                }}
                </p>
                <p class="medium mb-0">
                  <b>País de Residencia: </b>{{ pessoa ? (pessoa.paisResidencia || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Unidade da Federação: </b>{{ pessoa ? (pessoa.unidade || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
              </div>
              <div class="col">
                <p class="medium mb-0">
                  <b>Logradouro: </b>{{ pessoa ? (pessoa.logradouro || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Tipo Logradouro: </b>{{ pessoa ? (pessoa.complementoLogradouro || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Complemento do Logradouro: </b>
                  {{ pessoa ? (pessoa.complementoLogradouro || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
                <p class="medium mb-0">
                  <b>Número do Logradouro: </b> {{ pessoa ? (pessoa.numeroLogradouro || 'Sem registro')
                      : 'Sem registro'
                  }}
                </p>
              </div>
            </div>
            <hr />
            <h6 class="mt-1 mb-3"><b>MANDADOS: </b></h6>

            <div v-if="mandados.length == 0" class="row">
              <div class="col">
                Não foram encontrados mandados para esta pessoa.
              </div>
            </div>

            <div v-else class="row">
              <div class="col">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Alcunha</th>
                        <th>Mãe</th>
                        <th>Naturalidade</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :key="index" v-for="(mandado, index) in mandados">
                        <td>{{ mandado.statusPessoa || 'Sem registro' }}</td>
                        <td>{{ mandado.nome || 'Sem registro' }}</td>
                        <td>{{ mandado.cpf || 'Sem registro' }}</td>
                        <td>{{ mandado.alcunha || 'Sem registro' }}</td>
                        <td>{{ mandado.nomeMae || 'Sem registro' }}</td>
                        <td>{{ mandado.naturalidade || 'Sem registro' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <hr />
            <h6 class="mt-1 mb-3"><b>VEÍCULOS: </b></h6>

            <div v-if="veiculos.length == 0" class="row">
              <div class="col">
                Não foram encontrados veículos para esta pessoa.
              </div>
            </div>

            <div v-else class="row">
              <div class="col">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Placa</th>
                        <th>Chassi</th>
                        <th>Renavam</th>
                        <th>Cor</th>
                        <th>Modelo</th>
                        <th>Tipo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :key="index" v-for="(veiculo, index) in veiculos">
                        <td>{{ veiculo.placa || 'Sem registro' }}</td>
                        <td>{{ veiculo.chassi || 'Sem registro' }}</td>
                        <td>{{ veiculo.renavam || 'Sem registro' }}</td>
                        <td>{{ veiculo.cor || 'Sem registro' }}</td>
                        <td>{{ veiculo.marcaModelo || 'Sem registro' }}</td>
                        <td>{{ veiculo.tipoVeiculo || 'Sem registro' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <button @click="limparBuscaEFecharModal" type="button" class="btn btn-default" data-dismiss="modal">
              <i class="fa-solid fa-x"></i>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../main.js";
export default {
  name: "DetalhesConsultaPessoa",

  data() {
    return {
      pessoa: {
        dataNascimento: null
      },
      mandados: [],
      veiculos: [],
      loaderActive: true,
      bos: [],
    };
  },
  created() {
    //veiculos
    EventBus.$on("consultaDetalhePessoaNacional", (response) => {
      this.pessoa = response.data;
    });

    EventBus.$on("consultaMandadosPessoaNacional", (response) => {
      this.mandados = response.data;
    });
    EventBus.$on("consultaVeiculosPessoaNacional", (response) => {
      this.veiculos = response.data;
    });
  },
  filters: {
    formatarDataNascimento: function (dataNascimento) {

      if (dataNascimento !== null && dataNascimento !== '') {
        const dataFormatada = dataNascimento.split('-');
        return dataFormatada[2] + '/' + dataFormatada[1] + '/' + dataFormatada[0]
      } else {
        return "Não Cadastrado"
      }
    }
  },

  methods: {
    limparBuscaEFecharModal() {
      this.pessoa = {};
      this.mandados = [];
      this.bos = [];
      this.veiculos = [];
      this.$emit("closeModalPessoaNacional");
    },
  },
};
</script>

<style scoped>

</style>
