import axios from "axios";

class RhfrequenciaService {

    inserirFrequencia(frequenciaDTO){
        return axios.post("http://172.20.2.188:8081/api/rh/frequencia",frequenciaDTO)
    }

    inserirFrequencia(listaFrequencia){
        return axios.post("http://172.20.2.188:8081/api/rh/frequencia/lista",listaFrequencia)
    }

    listarFuncionariosPorLotacao(idLotacao){
        return axios.get(`http://172.20.2.188:8081/api/rh/funcionarios-resumo/lotacao/${idLotacao}`)
    }

    

}

export default new RhfrequenciaService ();