<template>
  <form>
    <div class="card-body">
      <div class="form-group">
        <label for="exampleSelectBorderWidth2">Tipo</label>
        <select v-model="tipoConsultaNacional" class="custom-select form-control-border border-width-2"
          id="exampleSelectBorderWidth2">
          <option disabled value="">Selecione</option>
          <option value="cpf">CPF</option>
          <option value="nomemae">Nome exato e nome da mãe</option>
        </select>
      </div>
      <transition mode="out-in">
        <div key="comcpf" v-if="tipoConsultaNacional == 'cpf'">
          <div class="form-group">
            <label for="cpfNacional">CPF</label>
            <input type="text" class="form-control form-control-border border-width-2" id="cpfNacional"
              placeholder="xxx.xxx.xxx-xx" v-model="cpfNacional" v-mask="'###.###.###-##'" />
          </div>
        </div>

        <div key="semcpf" v-else>
          <div class="form-group">
            <label for="nome">Nome</label>
            <input type="email" class="form-control form-control-border border-width-2" id="nome"
              placeholder="Buscar por nome..." v-model="nome" />
          </div>

          <div class="form-group">
            <label for="nomemae">Nome da mãe</label>
            <input type="text" class="form-control form-control-border border-width-2" id="nomemae"
              placeholder="Nome da mãe" v-model="nomemae" />
          </div>
        </div>
      </transition>
    </div>
    <div class="card-footer">
      <button @click.prevent="emitirConsultaNacional" v-on:keyup.enter="emitirConsultaNacional" type="submit"
        class="btn col-12 background-principal text-color-principal">
        <i class="fas fa-search"></i>
        Buscar
      </button>
    </div>
  </form>
</template>

<script>
import { EventBus } from "../../../main.js";
import LocalIpService from "@/service/LocalIpService";
export default {
  name: "FormConsultaNacionalPessoa",
  data() {
    return {
      nomemae: "",
      nome: "",
      cpfNacional: "",
      tipoConsultaNacional: "",
      buscarTotal: false
    };
  },
  created(){
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
  methods: {
    mostrarAlerta() {
      Toast.fire("Preencha TODOS os campos!!", "", "error");
    },
    formValido() {
      if (this.tipoConsultaNacional == "") {
        return false
      }else{
        if(this.tipoConsultaNacional == "cpf"){
          if(this.cpfNacional==""){
            return false
          }
        }
        if(this.tipoConsultaNacional == "nomemae"){
          if(this.nome==""||this.nomemae==""){
            return false
          }
        }

      }

      return true;
    },
    emitirConsultaNacional() {
      // const tokenNacional = localStorage.getItem("tokenNacional");

      if (this.formValido()) {
        const params = {
          tipo: this.tipoConsultaNacional,
          chaveNome: this.tipoConsultaNacional == 'cpf' ? this.cpfNacional : this.nome,
          chaveAdd: this.tipoConsultaNacional == 'cpf' ? null : this.nomemae,
          page: this.page
        };

        EventBus.$emit("consultapessoaNacional", params); // emitir evento para o event-bus
      } else {
        this.mostrarAlerta();
      }
    },
  },
};
</script>

<style>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}
</style>
