import instance from "./axiosConfig";

class RhDiariasDiariaService {
  lancarDiaria(diariaDTO) {
    return instance.post("/api/rh/diaria", diariaDTO);
  }

  getAllDiaria() {
    return instance.get("/api/rh/diaria");
  }

  getDiaria(id) {
    return instance.get(`/api/rh/diaria/${id}`);
  }

  getExtratoDiaria(id) {
    return instance.get(`/api/rh/diaria/extrato/${id}`);
  }


  countDiariaByFuncionario(id) {
    return instance.get(`/api/rh/diaria/funcionario/${id}`);
  }

  getDiariasFuncionario(funcionarioId){

    return instance.get(`/api/rh/diaria/servidor/${funcionarioId}`)
  }

  getDiarias(params) {
    return instance.get(`/api/rh/diaria/filtro`, { params: params });
  }

  getDiariaByProcesso(processo) {
    return instance.get(`/api/rh/diaria/processo?processo=${processo}`);
  }

  editarDiaria(id, diariaDTO) {
    return instance.put(`/api/rh/diaria/${id}`, diariaDTO);
  }

  encerrarDiaria(id, diariaDTO) {
    return instance.put(`/api/rh/diaria/${id}`, diariaDTO);
  }

  boletimDiaria(ano, mes) {
    return instance.get(`/api/rh/diaria/boletim?ano=${ano}&mes=${mes}`);
  }

  getAllDiariaGrupo() {
    return instance.get("/api/rh/diaria/diaria-grupo");
  }

  cadastrarDiariaGrupo(diaria_grupoDTO) {
    return instance.post("/api/rh/diaria/diaria-grupo", diaria_grupoDTO);
  }

  getDiariaGrupo(id) {
    return instance.get(`/api/rh/diaria/diaria-grupo/${id}`);
  }

  editarDiariaGrupo(id, diaria_grupoDTO) {
    return instance.put(`/api/rh/diaria/diaria-grupo/${id}`, diaria_grupoDTO);
  }

  cadastrarTipoAnotacao(tipoAnotacaoDto) {
    return instance.post("/api/rh/configuracao/tipo-anotacao", tipoAnotacaoDto);
  }

  getAllTipoAnotacao(token) {
    return instance.get("/api/rh/configuracao/tipo-anotacao");
  }

  editarTipoAnotacao(id, tipoAnotacaoDto) {
    return instance.put(
      `/api/rh/configuracao/tipo-anotacao/${id}`,
      tipoAnotacaoDto
    );
  }

  getTipoAnotacao(id) {
    return instance.get(`/api/rh/configuracao/tipo-anotacao/${id}`);
  }

  DeletarTipoAnotacao(id) {
    return instance.delete(`/api/rh/configuracao/tipo-anotacao/${id}`);
  }

  getAllOrdenadorDespesa() {
    return instance.get("/api/rh/configuracao/ordenador-despesa");
  }

  getOrdenadorDespesa(id) {
    return instance.get(`/api/rh/configuracao/ordenador-despesa/${id}`);
  }

  cadastrarOrdenadorDespesa(ordenador) {
    return instance.post("/api/rh/configuracao/ordenador-despesa", ordenador);
  }
  editarOrdenadorDespesa(ordenador) {
    return instance.put(`/api/rh/configuracao/ordenador-despesa`, ordenador);
  }

  // anexar(arquivo, funcionarioId){
  //     return instance.post(`/api/rh/arquivo`,{
  //         params:{
  //             file: arquivo,
  //             funcionarioId: funcionarioId
  //         }
  //     });
  // }

  // anexar(formData) {

  //     return instance.post(`/api/rh/arquivo`, formData, {
  //         headers: {
  //             'Content-Type': 'multipart/form-data'
  //           }
  //     });
  // }

  anexar(funcionarioId, formData) {
    return instance.post(
      `/api/rh/diaria/arquivo/upload/${funcionarioId}`,
      formData
    );
  }

  buscarAnexos(idFuncionario) {
    return instance.get(`/api/rh/diaria/arquivo/${idFuncionario}`);
  }

  downloadAnexo(id) {
    return instance.get(`/api/rh/diaria/arquivo/download/${id}`, {});
  }

  imprimirRd(idRd) {
    return instance.get("/api/rh/diaria/gerarpdf/" + idRd, {
      responseType: "blob",
    });
  }

  getAllDiariaPendentesAssinatura() {
    return instance.get("/api/rh/diaria/pendentes/assinatura");
  }

  assinarDiaria(senha, id) {
    return instance.get("/api/rh/diaria/pendentes/assinatura/verificar", {
      params: {
        senha: senha,
        id: id,
      },
    });
  }

  getAllCidades() {
    return instance.get("/api/rh/diaria/getallcidades");
  }

  gerarPdf(params) {
    return instance.get("/api/rh/diaria/gerarpdfextrato", {
        params: params,
        responseType: "blob",
    });
}

}
export default new RhDiariasDiariaService();
