<template>
  <div class="wrapper" :class="{ hidden: !user }">
    <div class="tarja-desenvolvimento" v-if="versao == 'desenvolvimento'">
      <h4>DESENVOLVIMENTO</h4>
    </div>
    <div class="tarja-treinamento" v-if="versao == 'treinamento'">
      <h4>TREINAMENTO</h4>
    </div>
    <div class="tarja-homologacao" v-if="versao == 'homologacao'">
      <h4>HOMOLOGAÇÃO</h4>
    </div>
    <div class="tarja-homologacao" v-if="versao == 'rh'">
      <h4>HOMOLOGAÇÃO</h4>
    </div>



    <nav-bar v-if="!primeiroAcessoSenha" />
    <side-bar v-if="!primeiroAcessoSenha" />
    <div v-if="!primeiroAcessoSenha" class="content-wrapper">
      <router-view />
    </div>

    <!--     <footer class="main-footer">
      <div class="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
      <strong
        >Copyright &copy; 2014-2021
        <a href="https://adminlte.io">AdminLTE.io</a>.</strong
      >
      All rights reserved.
    </footer> -->
    <div v-if="versao == 'homologacao'">
      <footer class="main-footer homologacao">

        <div class="text-color-principal">
          <h4><b>HOMOLOGAÇÃO</b></h4>
        </div>
      </footer>

    </div>

    <div v-if="versao == 'desenvolvimento'">
      <footer class="main-footer desenvolvimento">

        <div class=" text-color-principal">
          <h4><b>DESENVOLVIMENTO</b></h4>
        </div>
      </footer>

    </div>

    <div v-if="versao == 'treinamento'">
      <footer class="main-footer treinamento">

        <div class=" text-color-principal">
          <h4><b>TREINAMENTO</b></h4>
        </div>
      </footer>

    </div>

    <aside v-if="!primeiroAcessoSenha" class="control-sidebar control-sidebar-dark"></aside>

    <!-- <NotificacoesCriticas v-if="notificacaoShow" @limparNotificacoesCriticas="buscarNotificacoesCriticas"
      @limparNotificacoesSistemaCriticas="buscarNotificacoesCriticasSistema" />
    <div class="modal-backdrop fade show" v-show="notificacaoShow"></div> -->
    <ResetSenhaComponent v-if="primeiroAcessoSenha" @irTelaLogin="telaLogin" />
  </div>
</template>

<script>
import { Versao } from "@/main";
// import NotificacoesService from "@/service/NotificacoesService";
import TokenService from "@/service/TokenService";
import VueJwtDecode from "vue-jwt-decode";
// import NotificacoesCriticas from "./NotificacoesCriticas.vue";
import ResetSenhaComponent from "./ResetSenhaComponent.vue";

import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
  name: "App",
  // components: { ResetSenhaComponent, NotificacoesCriticas },
  components: { ResetSenhaComponent },
  data() {
    return {
      user: {},
      loading: false,
      message: "",
      tokenTime: {},
      primeiroAcessoSenha: false,
      versao: Versao,
      // notificacoesCriticas: [],
      // notificacaoShow: false,
      // notificacoesSistemaCritica: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    // this.buscarNotificacoesCriticas();
    // this.buscarNotificacoesCriticasSistema();
    let token = JSON.parse(localStorage.getItem("user")).access_token
    let tokenResolvido = TokenService.parseJwt(token)
    if (tokenResolvido.usuario.novaSenhaUsuario === null) {
      this.primeiroAcessoSenha = true;
    }else if(tokenResolvido.usuario.senhaTemporaria){
      this.primeiroAcessoSenha = true;
    } else {
      let dataHoraNovaSenhaUsuario = new Date(tokenResolvido.usuario.novaSenhaUsuario);
      let dataHoraNovaSenhaGerada = new Date(tokenResolvido.usuario.dataSenhaResetada);
      if (dataHoraNovaSenhaUsuario > dataHoraNovaSenhaGerada) {
        this.primeiroAcessoSenha = false
      } else {
        this.primeiroAcessoSenha = true
      }
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.tokenTime = TokenService.parseJwt(JSON.parse(localStorage.getItem("user")).access_token);
    // console.log(this.tokenTime);

  },
  methods: {
    telaLogin() {
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
    // buscarNotificacoesCriticas() {
    //   NotificacoesService.buscarNotificacoesCriticas(this.currentUser.usuario.idFuncionario)
    //     .then((response) => {
    //       this.notificacoesCriticas = response.data;
    //       if (this.notificacoesCriticas.length !== 0) {
    //         this.notificacaoShow = true;
    //       } else {
    //         this.notificacaoShow = false;
    //       }
    //     }).catch((e) => {
    //       this.notificacaoShow = false;
    //     });
    // },
    // buscarNotificacoesCriticasSistema() {
    //   NotificacoesService.buscarNotificacoesCriticasSistema(this.currentUser.usuario.idFuncionario)
    //     .then((response) => {
    //       this.notificacoesSistemaCritica = response.data;
    //       if (this.notificacoesSistemaCritica.length !== 0) {
    //         this.notificacaoShow = true;
    //       } else {
    //         this.notificacaoShow = false;
    //       }
    //     }).catch((e) => {
    //       this.notificacaoShow = false
    //     })
    // }
  }
};
</script>


<style scoped>
.tarja-desenvolvimento {
  position: absolute;
  background-color: #ff0000;
  color: #fff;
  font-weight: bolder;
  height: 35px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg);
  left: -90px;
  top: 80px;
  z-index: 1000000000;
}

.tarja-homologacao {
  position: absolute;
  background-color: #FFC300;
  color: #fff;
  font-weight: bolder;
  height: 35px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg);
  left: -90px;
  top: 80px;
  z-index: 1000000000;
}

.tarja-treinamento {
  position: absolute;
  background-color: #45b435;
  color: #fff;
  font-weight: bolder;
  height: 35px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg);
  left: -90px;
  top: 80px;
  z-index: 1000000000;
}

.homologacao {
  background-color: #FFC300 !important;
}

.desenvolvimento {
  background-color: #f00 !important;
}

.treinamento {
  background-color: #45b435 !important;
}
</style>
