<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>

        <!-- <li class="nav-item">
          <a href="https://wa.me/5598985245926" target="_blanck" >
            <h4 class= "text-light">
                <i class="fa-brands fa-whatsapp p-1"></i>
            </h4> 
          </a>
        </li> -->

    </ul>

    <ul class="navbar-nav ml-auto">
      <vue-toastr ref="mytoast"></vue-toastr>

      <li class="nav-item">
            
            <a href="https://wa.me/5598985245926" target="_blanck" >
              <h4 class= "text-light mb-0">
                  <i class="fa-brands fa-whatsapp p-2"></i>
              </h4> 
            </a>
      </li>


      <li class="nav-item">
        <a href="#" class="nav-link">
          <h4 class="text-light">
            {{ timeLeft }}
          </h4>
        </a>
      </li>
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell notificacoes-color"></i>
          <span v-show="(quantidadeNotificacoes + quantidadeNotificacoesSistema) != '0'"
            class="badge badge-warning navbar-badge">
            {{ (quantidadeNotificacoes + quantidadeNotificacoesSistema) }}</span>
        </a>
        <NotificacoesHeader />
      </li> -->
      

      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-user"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">

          <a class="dropdown-item">
            <router-link class="text-link" to="meuperfil">
              <i class="fas fa-user mr-2"></i> Meu Perfil
            </router-link>
          </a>


          <div class="dropdown-divider"></div>
          <!-- <a href="/login" class="dropdown-item">
            <i class="fas fa-sign-out-alt mr-2"></i> Sair
          </a> -->
          <a href="#" @click="logout" class="dropdown-item">
            <i class="fas fa-sign-out-alt mr-2"></i> Sair
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
// import NotificacoesService from '@/service/NotificacoesService';
// import notificacoesToggle from '@/util/notificacoesToggle';
import timeToken from '@/util/timeToken';
// import NotificacoesHeader from './NotificacoesHeader.vue';
import Auth from '@/service/auth/auth.service';


export default {
  data() {
    return {
      selectedTime: 0,
      timeLeft: "00:00",
      endTime: "0",
      intervalTimer: 0,
      validaRefresh: 0,
      Toast1: {},
      // quantidadeNotificacoes: "",
      // quantidadeNotificacoesSistema: ""
    };
  },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    },
    baseUrlOauth() {
      return this.$store.state.app.urlOauth2;
    },url() {
      return this.$store.state.app.url;
    },
  },
  created() {
    timeToken.$on("resetaTimeToken", (time) => {
      this.setTime(time);
    });
    // notificacoesToggle.$on("buscarNovasNotificacoes", () => {
    //   this.buscarQuantidadeNovasNotificacoes();
    // });
    // notificacoesToggle.$on("buscarNovasNotificacoesSistema", () => {
    //   this.buscarQuantidadeNovasNotificacoesSistema();
    // })
  },
  methods: {
    // buscarQuantidadeNovasNotificacoes() {
    //   NotificacoesService.contagemNotificacacoes(this.usuario.idFuncionario).then((response) => {
    //     this.quantidadeNotificacoes = response.data;
    //   });
    // },
    // buscarQuantidadeNovasNotificacoesSistema() {
    //   NotificacoesService.contagemNotificacoeSistema(this.usuario.idFuncionario)
    //     .then((response) => {
    //       this.quantidadeNotificacoesSistema = response.data;
    //     })
    // },
    setTime(seconds) {
      clearInterval(this.intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);
      this.selectedTime = seconds;
      // this.initialTime = seconds;
      this.displayEndTime(end);
      this.countdown(end);
    },
    countdown(end) {
      this.intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);
        if (secondsLeft < 5 * 60 && this.validaRefresh == 0) {
          // this.refreshToken();
          this.validaRefresh = 1;
          this.toastTimeToken();
        }
        if (secondsLeft === 0) {
          this.validaRefresh = 2;
          this.$router.push("/login");
          this.endTime = 0;
          this.logoutSession();
        }
        if (secondsLeft < 0 && this.validaRefresh <= 2) {
          this.validaRefresh = 3;
          this.$router.push("/login");
          this.logoutSession();
        }
        // if (secondsLeft === 0 && this.validaRefresh === 0) {
        //   this.$router.push("/login");
        //   this.logoutSession();
        // }-
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;
      this.timeLeft = `${this.zeroPadded(minutes)}:${this.zeroPadded(seconds)}`;
    },
    displayEndTime(timestamp) {
      const end = new Date(timestamp);
      const hour = end.getHours();
      const minutes = end.getMinutes();
      this.endTime = `${this.hourConvert(hour)}:${this.zeroPadded(minutes)}`;
    },
    zeroPadded(num) {
      // 4 --> 04
      return num < 10 ? `0${num}` : num;
    },
    hourConvert(hour) {
      // 15 --> 3
      return (hour % 12) || 12;
    },
    refreshToken() {
      // this.$store.dispatch("refreshToken", this.$store.state.auth.user);
      this.$store.dispatch("refreshToken");
      this.setTime(30 * 60);
    },
    toastTimeToken() {
      this.Toast1 = this.$refs.mytoast.Add({
        name: "Toast1",
        msg: "Sua sessão irá expirar em 5 minutos click aqui para renovar",
        type: "warning",
        position: "toast-top-right",
        timeout: 0,
        clickClose: false,
        onClosed: () => {
          // alert("onClosed VooAaa ");
          // this.logoutSession();
        },
        onClicked: () => {
          this.refreshToken();
          this.$refs.mytoast.Close(this.Toast1);
        },
        onMouseOver: function () { }.bind(this),
      });
    },

    logoutSession(){
      this.logout();
      localStorage.clear();
    },
    
    logout() {

      let token = JSON.parse(localStorage.getItem("user")).access_token
      let baseUrlOauth = this.baseUrlOauth;

      const data = new URLSearchParams({
        token: token
      });

      

      // Auth.logout(data);

      let id_token_hint = JSON.parse(localStorage.getItem("user")).id_token;
      let post_logout_redirect_uri = this.url + "/login";
      localStorage.clear();

      window.location.href = baseUrlOauth + `/connect/logout?id_token_hint=${id_token_hint}&post_logout_redirect_uri=${post_logout_redirect_uri}`;
      // window.location.href =  baseUrlOauth + '/auth2/logout-custom';
    }
  },
  // components: { NotificacoesHeader }
};
</script>

<style scoped>
.text-link {
  color: #000
}

.notificacoes-color {
  color: #fff
}

.text-link:hover {
  color: #000
}




</style>
