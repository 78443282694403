class paginacao {

    montarPaginacao(response) {
        let paginacao = {};
        // let data = {};
        let page = {};

        paginacao.data = response.content;
        page.first = response.first;
        page.last = response.last;
        page.totalElements =response.totalElements;
        page.pageNumber = response.pageable.pageNumber;
        page.totalPages = response.totalPages;
        page.number = response.number;

        paginacao.page = page;

        return paginacao;
    }
}


export default new paginacao();