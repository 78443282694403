// import { ApiGateway } from "@/main";
import authHeader from "@/service/auth/auth-header";
import store from "@/store/index.js";
import axios from "axios";
import TokenService from "./TokenService";



import instance from './axiosConfig'

class ConsultaService {
  constructor() {
    this.usuarioLogado =
      store.state.auth.user == null ? null : store.state.auth.user.usuario;
  }

  async buscarVeiculosPessoaBaseNacional(cpfConsulta,ipUsuario,login){
    // const tokenNacional = await TokenService.resolveTokenNacional();
    const idBuscador = this.usuarioLogado.idFuncionario;
    const cpfBuscador = login;
    // if (tokenNacional !== null) {
      // let pathDetalhes = `/cortex/proprietario/${cpfConsulta}/${idBuscador}/${cpfBuscador}?plataforma=SITE&ipUsuario=${ipUsuario}`
    //   const headers = {
    //     "token-nacional": `Bearer ${tokenNacional}`,
    //   };

    const params = {
      chave: cpfConsulta
    }

    const pathDetalhes = `consultas/2/veiculo/emplacamento/proprietario`

    return instance.get(`${pathDetalhes}`, {params: params});
    // }
  }

  async buscarMandados(cpfConsulta,ipUsuario,login) {
    // const tokenNacional = await TokenService.resolveTokenNacional();
    const idBuscador = this.usuarioLogado.idFuncionario;
    const cpfBuscador = login;
    let path = "/{idUser}/{tipo}/{cpf}/{chave}/{chaveAdd}";

    const params = {
      chave: cpfConsulta,
      tipo: "cpf"
    }

    const pathDetalhes = `/consultas/2/mandados/pessoa`

    return instance.get(`${pathDetalhes}`, {params: params});


    // if (tokenNacional !== null) {
      // let pathDetalhes = `/mandados/${idBuscador}/cpf/${cpfBuscador}/${cpfConsulta}/null?plataforma=SITE&ipUsuario=${ipUsuario}`
    //   const headers = {
    //     "token-nacional": `Bearer ${tokenNacional}`,
    //   };
      // return instance.get(pathDetalhes);
    // }
  }

  buscarDetalhesPessoa(cpfConsulta,ipUsuario,login){
    // const tokenNacional = TokenService.resolveTokenNacional();
    const idBuscador = this.usuarioLogado.idFuncionario;
    const cpfBuscador = login;
    // if (tokenNacional !== null) {
      // let pathDetalhes = `/rf/${cpfConsulta}/${cpfBuscador}/${idBuscador}?plataforma=SITE&ipUsuario=${ipUsuario}`
    //   const headers = {
    //     "token-nacional": `Bearer ${tokenNacional}`,
    //   };

    const params = {
      chave: cpfConsulta
    }

    const pathDetalhes = `/consultas/2/pessoas/pessoa`

    return instance.get(`${pathDetalhes}`, {params: params});
    // }

  }

  buscarPessoasNacional(filtro) {
    const authorization = JSON.parse(localStorage.getItem("user")).access_token;
    // const tokenNacional = TokenService.resolveTokenNacional();

    const idFuncionario = this.usuarioLogado.idFuncionario;
    // const cpfFuncionario = login;

    // if (tokenNacional !== null) {
    //   const headers = {
    //     "token-nacional": `Bearer ${tokenNacional}`,
    //   };

      const path = `/consultas/2/pessoas/listagem`;

      return instance.get(`${path}`, {params: filtro});
    // }
  }

  buscarVeiculosNacional(filtro) {
    const authorization = JSON.parse(localStorage.getItem("user")).access_token;
    // const tokenNacional = TokenService.resolveTokenNacional();
    // if (tokenNacional !== null) {
    //   const headers = {
    //     "token-nacional": `Bearer ${tokenNacional}`,
    //   };
    //   // let cpf = this.tirarMascaraCPF(filtro.cpfFuncionario);
      // let cpf = login;
      
      if (filtro.tipoConsultaNacional == 'placa'){

        const params = {
          chave: filtro.parametroPrincipal
        }

        const path = `consultas/2/veiculo/emplacamento/placa`;

        return instance.get(`${path}`, {params: params});
      }
      else if (filtro.tipoConsultaNacional == 'renavam'){
        const path = `consultas/2/veiculo/emplacamento?chave=${filtro.parametroPrincipal}`;

      }
      else if(filtro.tipoConsultaNacional == 'chassi'){
        const path = `consultas/2/veiculo/emplacamento?chave=${filtro.parametroPrincipal}`;
      }

     
    // }
      

     
  }

  tirarMascaraCPF(cpf) {
    let semMascara = cpf.replace(".", "").replace(".", "").replace("-", "");
    return semMascara;
  }

  buscarPessoas(filtro) {
    return instance.get(`/consultas/api/1/1/pessoas/fisica`, {

      params: filtro,
    });
  }

  buscarBoDetalhe(id,ipUsuario) {
    return instance.get(`/web/bos?idBo=${id}&page=1&plataforma=SITE&ipUsuario=${ipUsuario}`);
  }

  buscarOcorrencia(filtro) {
    const params = filtro;

    return instance.get(`/consultas/api/1/1/bos`, {
      params: filtro,
    });
  }

  buscarVeiculos(filtro) {
    return instance.get(`/consultas/api/1/1/veiculos`, {
      params: filtro,
    });
  }

  buscarVeiculoDetalhe(id,ipUsuario) {
    return instance.get(`/web/veiculos/${id}?plataforma=SITE&ipUsuario=${ipUsuario}`);
  }

  detalharPessoa(id,ipUsuario) {
    return instance.get(`/web/consulta-pessoa/fisica/${id}?plataforma=SITE&ipUsuario=${ipUsuario}`);
  }

  buscarCelular(filtro) {
    return instance.get(`/consultas/api/1/1/celulares`, {
      params: filtro,
    });
  }

  buscarCheque(filtro) {
    return instance.get(`/consultas/api/1/1/cheques`, {
      params: filtro,
    });
  }

  buscarArma(filtro) {
    return instance.get(`/consultas/api/1/1/armas`, {
      params: filtro,
    });
  }

  buscarCoisa(filtro) {
    return instance.get(`/consultas/api/1/1/coisas`, {
      params: filtro,
    });
  }

  buscarDocumento(filtro) {
    return instance.get(`/consultas/api/1/1/documentos`, {
      params: filtro,
    });
  }
}
export default new ConsultaService();
