<template>
  <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Consulta SIGMA" />
    <conteudo v-role="'CONSULTA_ARMA'">
      <div class="container-fluid p-3">
        <DetalhesArmaConsulta v-show="isModalVisible" @closeModalArma="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisible"></div>
        <div class="row">
          <div class="col-md-3">
            <FormArmaConsulta />
          </div>

          <div class="col-md-9">
            <ResultadosArmaConsulta @showModalArma="showModal" />
          </div>
        </div>

      </div>

    </conteudo>

  </section>

</template>

<script>
import DetalhesArmaConsulta from '../../../components/consultas/arma/DetalhesArmaConsulta.vue'
import FormArmaConsulta from '../../../components/consultas/arma/FormArmaConsulta.vue'
import ResultadosArmaConsulta from '../../../components/consultas/arma/ResultadosArmaConsulta.vue'
import { EventBus } from '../../../main.js'

export default {
  name: 'ViewConsultaArma',
  components: { DetalhesArmaConsulta, FormArmaConsulta, ResultadosArmaConsulta },
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      EventBus.$emit('esconderBoCardComponent')
    }
  }
}
</script>
