import store from "@/store";
import instance from "./axiosConfig";

class CompartilhamentoDocFuncionariosService {
  constructor() {
    this.usuarioLogado =
      store.state.auth.user == null ? null : store.state.auth.user.usuario;
  }

  buscarFuncionariosCompartilhados() {
    let idFuncionarioPrincipal = this.usuarioLogado.idFuncionario;

    return instance.get(
      `/api/compartilhardoc/funcionarios?id=${idFuncionarioPrincipal}`
    );
  }

  cadastrarFuncionarioParaCompartilhar(relFuncionarioCompartilhar) {
    relFuncionarioCompartilhar.idFuncPrincipal =
      this.usuarioLogado.idFuncionario;

    return instance.post("/api/compartilhardoc", relFuncionarioCompartilhar);
  }

  atualizarFuncionarioCompartilhado(funcionarioCompartilhar) {
    return instance.patch(
      "/api/compartilhardoc/" + funcionarioCompartilhar.id,
      funcionarioCompartilhar
    );
  }

  deleteFuncionarioCompartilhado(idRelFuncionarioCompartilhar) {
    return instance.delete(
      "/api/compartilhardoc/" + idRelFuncionarioCompartilhar
    );
  }

  listarTodosFuncionarios() {
    return instance.get("/api/funcionarios/resumo-view-lista");
  }
}

export default new CompartilhamentoDocFuncionariosService();
