<template>
  <section v-if="imgs" class="img-list-container">
    <img v-for="(imgSrc, idx) in imgs" :key="idx" :src="imgSrc" />
  </section>
</template>

<script>
export default {
  props: {
    imgs: {
      type: Array
    }
  }
};
</script>

