<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="cadastro-orgao-idcontratacao">id Contratacao</label>
          <input
            type="text"
            class="form-control form-control-border"
            name="idContratacao"
            id="cadastro-licitante-idcontratacao"
            data-cy="idContratacao"
            :class="{
              'is-invalid': isSaving && $v.cadastroLicitante.idContratacao.$error,
            }"
            v-model="cadastroLicitante.idContratacao"
          />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="cadastro-licitante-cpfCnpj"
            >Cpf/Cnpj</label
          >
          <input
            type="text"
            class="form-control form-control-border"
            name="cpfCnpj"
            id="cadastro-licitante-cpfCnpj"
            data-cy="cpfCnpj"
            :class="{
              'is-invalid': isSaving && $v.cadastroLicitante.cpfCnpj.$error,
            }"
            v-model="cadastroLicitante.cpfCnpj"
          />
        </div>
      </div>  
      <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="cadastro-licitante-numero"
              >Número</label>
            <input
              type="text"
              class="form-control form-control-border"
              name="numero"
              id="cadastro-licitante-numero"
              data-cy="numero"
              :class="{
              'is-invalid': isSaving && $v.cadastroLicitante.numero.$error,
            }"
              v-model="cadastroLicitante.numero"
            />
          </div>
      </div>
    </div>
      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="cadastro-licitante-ano"
              >Ano</label
            >
            <input
              type="number"
              class="form-control form-control-border"
              name="ano"
              id="cadastro-licitante-ano"
              data-cy="ano"
              :class="{
              'is-invalid': isSaving && $v.cadastroLicitante.ano.$error,
            }"
              v-model.number="cadastroLicitante.ano"
            />
          </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
            <label
              class="form-control-label"
              for="cadastro-licitante-estrangeiro"
              >Estrangeiro: </label>
               <v-select class="border-v-select" id="cadastroLicitante-estrangeiro" :class="{
            'is-invalid': isSaving && $v.cadastroLicitante.estrangeiro.$error,
          }" data-cy="estrangeiro" name="estrangeiro" v-model="cadastroLicitante.estrangeiro" :options="estrangeiro"
            :reduce="estrangeiro => estrangeiro.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-right">
        <!--button class="btn btn-default mr-1">
          <i class="fas fa-pencil-alt"></i> Rascunho
        </button-->
        <button @click="salvarLicitacao" class="btn text-color-principal background-principal ml-1">
          <i class="far fa-envelope"></i> Enviar
        </button>
         <button @click="limparLicitacao" type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Limpar
      </button>
      </div>
      <!--button type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Cancelar
      </button-->
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";


import { DATE_TIME_LONG_FORMAT, DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import dayjs from "dayjs";
import { EventBus } from "@/main";
import processosService from "@/service/ProcessosService";
import TabelaCadastroLicitante from "@/components/processos/TabelaCadastroLicitante.vue"


export default {
  name: "Ata-Registro",
  components: {TabelaCadastroLicitante},
  data() {
    return {
      data:{},
      cadastroLicitante: {},
      submitted: false,
      isSaving: false,
      setores: [],
      funcionarios: [],
      estrangeiro:[
        { id : "S", descricao: "Sim" },
        { id : "N", descricao: "Não" },
      ],
    };
  },

 
  validations: {
    cadastroLicitante: {

      idContratacao: {},
      estrangeiro: {},
      cpfCnpj: {},
      numero: {},
      ano: {},

      //ano: { required }
     
    },
  },

  created(){
    EventBus.$on("EnvioCadastroLicitante",(listener)=>{
       this.getCadastroLicitante(listener);
    }),

    
      EventBus.$on("ApagarCamposCadastroLicitante",()=>{
        this.cadastroLicitante={};
      
    })

  },
  methods: {
    // updateZonedDateTimeField(field, event) {
    //   if (event.target.value) {
    //     this.documento[field] = dayjs(
    //       event.target.value,
    //       DATE_TIME_LONG_FORMAT
    //     );
    //   } else {
    //     this.documento[field] = null;
    //   }
    // },

  salvarLicitacao(){
    console.log("SALVAR CADASTRO LICITANTE")
    this.isSaving = true;
    this.$v.$touch();
 
      if (this.$refs["cadastro-licitante"]!= undefined){
        this.cadastroLicitante = Object.assign(
          {},
          this.cadastroLicitante,
          this.$refs["cadastro-licitante"].cadastroLicitante
        );
 
        this.$refs["cadastro-licitante"].isSaving = this.isSaving;
        if (this.$v.$ivalid || this.$refs["cadastro-licitante"].$v.$ivalid){
          return;
        } else{
          if (this.$v.$ivalid){
            return;
 
 
          }
        }
 
      }
    
      processosService.criarCadastroLicitante(this.cadastroLicitante)
      .then((response)=> {
        this.cadastroLicitante= response.data;
        Toast.fire("Cadastrado realizado", "", "success");
        this.cadastroLicitante={};
 
      })
  
  },

   limparLicitacao(){
       this.cadastroLicitante={};

    },
    


   // buscarCadastroLicitante(){
    //processosService.buscarCadastroLicitante(this.cadastroLicitante).then((resp)=>{
     // this.cadastroLicitante= resp.data;
     // })
  // },

  getCadastroLicitante(id){

      processosService.getCadastroLicitante(id).then((resp)=>{
       this.cadastroLicitante= resp.data;
       console.log(this.cadastroLicitante);
     })
   },


    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.documento[field] = dayjs(
          event.target.value,
          DATE_FORMAT_LOCAL_DATA
        );
      } else {
        this.documento[field] = null;
      }
    },
  },
  // mounted() {
  //   this.carregaSetores();
  //   this.carregaFuncionario();
  // }
};
</script>


