<template>
  <form>

    <div class="modal fade show h-100 overflow-auto" id="modal-default" style="display: block" aria-modal="true"
      role="dialog">
      <div class="modal-dialog" @click.stop>
        <div class="modal-content border-rounded-detail">
          <div class="modal-header background-principal text-color-principal card-item-result">
            <h4 class="modal-title mx-auto"> Redefina sua Senha</h4>
          </div>
          <div class="modal-body">
            <Loader :active="loaderActive" message="Redefinindo Senha..." />
            <div class="m-10 p-0 pb-1" v-if="!loaderActive">
              <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                No mínimo 8 caracteres.
              </p>
              <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                1 letra minúscula.
              </p>
              <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                1 letra maiúscula.
              </p>
              <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                1 número ou 1 caracter especial ( !, @, #, $, %, &, *, _ ).
              </p>
            </div>
            <div class="form-group">
              <label for="inputNumeroInicioFolha" class="pesquisar-numero-inicio-folha">Digite sua nova senha:</label>
              <input type="password" class="form-control form-control-border border-width-2" id="novaSenha"
                autocomplete="off" placeholder="Digite a nova senha" v-model="novaSenha" required />
            </div>
            <div class="form-group">
              <label for="inputNumeroFimFolha" class="pesquisar-numero-fim-folha">Confirme sua nova senha:</label>
              <input type="password" class="form-control form-control-border border-width-2" id="confirmarNovaSenha"
                autocomplete="off" placeholder="Digite a senha novamente" v-model="confirmeNovaSenha" required />
            </div>

          </div>
          <div class="modal-footer justify-content-between">
            <button class="btn btn-default form-group" data-dismiss="modal" type="reset"
              @click="$emit('closeModalMudancaSenha')"><i class="fa-solid fa-x"></i>Fechar</button>

            <button @click.prevent="resetarSenhaPrimeiroAcesso" v-on:keyup.enter="resetarSenhaPrimeiroAcesso"
              type="submit" class="btn background-principal text-color-principal">
              <i class="fa-solid fa-lock"></i>
              Redefinir Senha
            </button>
          </div>
        </div>
      </div>


    </div>
  </form>
</template>

<script>
import RecuperarSenhaService from '@/service/RecuperarSenhaService';
import Loader from './Loader.vue';

export default {
  name: "ModalResetSenhaComponent",
  components: { Loader },
  data() {
    return {
      novaSenha: "",
      confirmeNovaSenha: "",
      alerta: false,
      resultado: "",
      loaderActive: false,
    };
  },
  methods: {
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    alertaSenhaDiferente() {
      Toast.fire("Preencha os dois campos igualmente!", "", "error");
    },
    alertaFalhaNovaSenha() {
      Toast.fire("Falha ao cadastrar nova senha", "", "error");
    },
    alertaSenhaErrada() {
      Toast.fire("Preencha corretamente a senha", "", "error");
    },
    alertaSucessoCadastro() {
      Toast.fire("Senha cadastrada com sucesso", "", "success");
    },
    validaSenha() {
      if (this.novaSenha == this.confirmeNovaSenha) {
        if (this.novaSenha.match(/^(?=.*[0-9!@#%&*_])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#%&*_]{8,})$/)) {
          return true;
        }
        else {
          this.alertaSenhaErrada();
          this.alerta = true;
          return false;
        }
      }
      else {
        this.alertaSenhaDiferente();
        return false;
      }
    },
    resetarSenhaPrimeiroAcesso() {
      if (this.validaSenha()) {
        this.showLoader;
        try {
          RecuperarSenhaService.resetarSenhaPrimeiroAcesso(this.$store.state.auth.user.usuario.login, this.novaSenha)
            .then((response) => {
              this.resultado = response.data;
              this.hideLoader
              this.alertaSucessoCadastro();
              this.$emit('closeModalMudancaSenha')
            }).catch((e) => {
              this.hideLoader()
              this.alertaFalhaNovaSenha()
            })

        }
        catch (e) {
          this.hideLoader()
          alert("ERRO: " + e.message);
        }
      }
    }
  }
}
</script>
