<template>
  <div class="card card-primary card-tabs">
    <div class="card-header background-principal p-0 pt-1">
      <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">

        <li class="nav-item">
          <a class="nav-link active show" id="custom-tabs-one-perfil-tab" data-toggle="pill" href="#lancarfrequencia"
            role="tab" aria-controls="custom-tabs-one-profile" aria-selected="true" ref="lancarfrequencia">Lançar
            Frequência</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="custom-tabs-one-tab" data-toggle="pill" href="#frequenciaunidades" role="tab"
            ref="frequenciaunidades" aria-controls="custom-tabs-one-home" aria-selected="false">Relatórios</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="custom-tabs-one-tab" data-toggle="pill" href="#frequenciacalendario" role="tab"
            ref="frequenciacalendario" aria-controls="custom-tabs-one-home" aria-selected="false">Calendario Frequencia</a>
        </li>

      </ul>
    </div>
    <div class="card-body">
      <div class="tab-content" id="custom-tabs-one-tabContent">
        <div class="tab-pane fade active show" id="lancarfrequencia" role="tabpanel"
          aria-labelledby="custom-tabs-one-home-tab">
          <LancarFrequencia />
        </div>
        <div class="tab-pane fade" id="frequenciaunidades" role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab">
            <ConsultaFrequencia/> 
        </div>
        <div class="tab-pane fade" id="frequenciacalendario" role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab">
            <CalendarioFrequencia/> 
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import LancarFrequencia from '@/components/rh/frequencia/LancarFrequencia.vue';
import ConsultaFrequencia from '@/components/rh/frequencia/ConsultaFrequencia.vue';
import CalendarioFrequencia from '@/components/rh/frequencia/CalendarioFrequencia.vue';
export default {
  components: {
    LancarFrequencia,
    ConsultaFrequencia,
    CalendarioFrequencia,
    
  }
}
</script>

<style></style>
