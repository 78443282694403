import { render, staticRenderFns } from "./DetalhesConsultaPessoaNacional.vue?vue&type=template&id=7ffec5ec&scoped=true&"
import script from "./DetalhesConsultaPessoaNacional.vue?vue&type=script&lang=js&"
export * from "./DetalhesConsultaPessoaNacional.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ffec5ec",
  null
  
)

export default component.exports