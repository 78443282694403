import instance from "./axiosConfig";

class ConsultaListasService {
  buscarListaPorNomeTabela(nome) {
    return instance.get(`/lista/resumo/${nome}`);
  }

  buscarListaUnidadesSSP() {
    return instance.get(`/api/unidades/45`);
  }

  buscarBairrosPorIdCidade(id) {
    return instance.get(`/lista/bairros?cidade=${id}`);
  }

  buscarCidadesPorIdEstado(id) {
    return instance.get(`/lista/cidades?estado=${id}`);
  }

  buscarEmpresas() {
    return instance.get(`/api/empresas`);
  }
}

export default new ConsultaListasService();
