<template>
  <section class="single-video">
    <PhotoCapture buttonsClasses='btn-outline-info' @input="handleDone" />
  </section>
</template>

<script>
import PhotoCapture from "./PhotoCapture.vue";
import ImgList from "./ImgList";

export default {
  name: 'Camera',
  components: {
    PhotoCapture,
    ImgList,
  },
  data() {
    return {
      imgs: [],

    };
  },
  created() {
    // this.imgs = JSON.parse(localStorage.getItem("thumbnails"));
    if (!this.imgs) this.imgs = [];
  },
  methods: {
    handleDone(imgBase64) {
      this.imgs.push(imgBase64);
      // localStorage.setItem("thumbnails", JSON.stringify(this.imgs));
    },
    clear() {
      // localStorage.clear();
      this.imgs = [];
    }
  }
};
</script>


