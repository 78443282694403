<template>
  
    <div class="card card-primary card-tabs">
      <div class="card-header background-principal p-0 pt-1">
        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
          
          <li class="nav-item" @click="reRender()">
              <a class="nav-link active show" 
              id="custom-tabs-one-home-tab" @click.prevent="apagarCampos" data-toggle="pill" href="#tabelasancao" role="tab"
              ref="tabelasancao" aria-controls="custom-tabs-one-home" aria-selected="true"
              >Tabela Sancao </a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
              id="custom-tabs-one-perfil-tab"  data-toggle="pill" href="#cadastrosancao" role="tab"
              aria-controls="custom-tabs-one-profile" aria-selected="false" ref="cadastrosancao"
             >Cadastro Sancao</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="custom-tabs-one-tabContent" >
          <div class="tab-pane fade active show" id="tabelasancao" role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab">
             <TblSancao :key="sancao_reload"/>
          </div>
          <div class="tab-pane fade" id="cadastrosancao" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
            <FormSancao/>
          </div>
        </div>
      </div>
    </div>
  
</template>
<script>
import TblSancao from "@/components/processos/TabelaSancao.vue";
import FormSancao from "@/components/processos/CadastroSancao.vue";
import { EventBus } from "@/main";
export default {
  
  name: "TabSancao",
  components: { TblSancao, FormSancao },
  data() {
    return {
      sancao_reload:1,


    }
  },
  created() {
      EventBus.$on("AlterarTabSancao",()=>{
        this.$refs.cadastrosancao.click()
        
      })

    
  },
  methods: {

    apagarCampos(){
      EventBus.$emit("ApagarCamposSancao")

    },

    reRender(){
      this.sancao_reload++;
    },

  }
}
</script>
<style scoped>
.text-link {
  color: #fff
}

.text-link:hover {
  color: #fff
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}
</style>
