<template>
  <div class="TreeMenu">
    <div :style="indent">
      <div v-if="unidadesInferiores">
        <i class="fa-solid fa-eye pr-2 pointer" @click="clicandoCard(id)"></i>
        {{ descricao }}
        <i @click="toggleChildren" v-if="!showChildren && unidadesInferiores.length !== 0"
          class="fas fa-angle-left px-2 pointer"></i>
        <i @click="toggleChildren" v-if="showChildren && unidadesInferiores.length !== 0"
          class="fas fa-angle-down px-2 pointer"></i>

      </div>


    </div>
    <hr>
    <div v-if="showChildren">
      <TreeMenu v-for="unidade in unidadesInferiores" :key="unidade.id" :unidadesInferiores="unidade.unidadesInferiores"
        :descricao="unidade.descricao" :depth="depth + 1" :id="unidade.id">
      </TreeMenu>
    </div>




  </div>
</template>

<script>
import { EventBus } from '@/main';

export default {
  name: 'TreeMenu',
  data() {
    return {
      showChildren: false,
      iconToggle: true
    }
  },
  props: {
    id: Number,
    unidadesInferiores: Array,
    descricao: String,
    depth: Number

  },
  computed: {
    indent() {
      return { transform: `translate(${this.depth * 50}px)` }
    }
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    clicandoCard(unidadeId) {
      EventBus.$emit('consultaUnidadeDetalhe', unidadeId);
      EventBus.$emit('showModalUnidade')
    },
  }
}
</script>


<style scoped>
.pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}
</style>
