<template>
  <div class="modal fade show h-90 overflow-auto" id="modal-dialog modal-xl" style="display: block" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl p-3" @click.stop>
      <div>
        <div class="modal-content border-rounded-detail">
          <div class="modal-header background-principal text-color-principal card-item-result">
            <h5 class="modal-title mx-auto my-0">Escolher funcionário</h5>
          </div>
          <div class="p-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-fkSetorDestino">Setor Destino <span
                  class="text-danger"></span></label>
              <v-select class="border-v-select" id="documento-fkSetorDestino"
                data-cy="fkSetorDestino" name="fkSetorDestino" v-model="setor" @input="carregaFuncionario"
                :options="setores" label="descricao">
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </div>
            <div class="form-group">
              <label class="form-control-label" for="documento-fkUsuarioDestino">Servidor Destino <span
                  class="text-danger"></span></label>
              <v-select class="border-v-select" id="documento-fkUsuarioDestino"
                data-cy="fkUsuarioDestino" name="fkUsuarioDestino" v-model="funcionario" :options="funcionarios"
                label="nome">
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('closeModalCompartilhar')">
              <i class="fa-solid fa-x"></i>
              Fechar
            </button>

            <button @click.prevent="compartilharDocumento" type="button" class="btn btn-success" data-dismiss="modal">
              <i class="fa fa-share-alt fa-magnifying-glass"></i>
              Compartilhar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import converteFuncionario from "@/util/converteFuncionario";
import api from "../../service/DocumentoService";
export default {
  name: "ModalCompartilharDocumento",
  data() {
    return {
      funcionarios: [],
      funcionario: null,
      setores: [],
      setor: null,
      idDocumento: null
    };
  },
  created() {
    EventBus.$on("capturaIdDocumento",
      (id) => {
        this.idDocumento = id
        this.funcionario = null
      }
    )
  },
  mounted() {
    this.carregaSetores(); // poderá ser flexível a outros setores?
    // this.setor = {id: usuarioLogado.idLotacao, descricao: usuarioLogado.lotacao}

    if (this.setor != null) {
      this.carregaFuncionario(this.setor.id)
    }

  },
  methods: {
    compartilharDocumento() {

      let idDocumento = this.idDocumento;
      let idFuncionario = this.funcionario.id;
      let idSetor = this.setor.id;
      api.compartilharDocumento(idDocumento, idFuncionario, idSetor).then(r => {
        // console.log(r)
        if (r.status == 200) {
          this.$emit('closeModalCompartilhar')
          Toast.fire("Documento compartilhado com sucesso!", "", "success");
        }
      }).catch(e => {
        Toast.fire("Erro ao compartilhar documento: " + e.message, "", "error");
      })

    },
    carregaSetores: function () {
      const listaSetores = api.buscarSetor().then((response) => {
        this.setores = response.data;
      });
    },
    carregaFuncionario(event) {
      if (event != undefined) {
        const listaFuncionario = api
          .buscarFuncionariosetor(event.id)
          .then((response) => {
            this.funcionarios =
              converteFuncionario.funcionarioDtoToFuncionarioArray(
                response.data
              );
          });
      }
    }
    // carregaFuncionario(idSetor) {
    //   if (idSetor != undefined) {
    //     const listaFuncionario = api
    //       .buscarFuncionariosetor(idSetor)
    //       .then((response) => {
    //         this.funcionarios =
    //           converteFuncionario.funcionarioDtoToFuncionarioArray(
    //             response.data
    //           );
    //       });
    //   }
    // },
  },
};
</script>

<style>

</style>
