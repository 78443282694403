<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-fkSetorDestino">Setor Destino <span
              class="text-danger">*</span></label>
          <v-select class="border-v-select" id="documento-fkSetorDestino" data-cy="fkSetorDestino"
            name="fkSetorDestino" v-model="documento.fkSetorDestino" @input="carregaFuncionario" :options="setores"
            label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-fkUsuarioDestino">Servidor Destino <span
              class="text-danger">*</span></label>
          <v-select class="border-v-select" id="documento-fkUsuarioDestino" data-cy="fkUsuarioDestino"
            name="fkUsuarioDestino" :disabled="documento.fkSetorDestino == null" v-model="documento.fkUsuarioDestino"
            :options="funcionarios" label="nome">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-cargoDestino">Cargo/Função Destino <span
              class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border" name="cargoDestino" id="documento-cargoDestino"
            data-cy="cargoDestino" v-model="documento.cargoDestino" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-controleProcesso">e-Processos</label>
          <input type="text" class="form-control form-control-border" name="controleProcesso"
            id="documento-controleProcesso" data-cy="controleProcesso" v-model="documento.controleProcesso" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-assunto">Signatário <span
              class="text-danger">*</span></label>
          <v-select class="border-v-select" id="documento-fkUsuario" data-cy="fkUsuario"
            name="fkUsuario" v-model="documento.fkUsuario" :options="funcionariostodos" label="nome">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-assunto">Assunto <span class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border" name="assunto" id="documento-assunto"
            data-cy="assunto" v-model="documento.assunto" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import converteFuncionario from "@/util/converteFuncionario";
import api from "../../service/DocumentoService";

export default {
  name: "Documentos",
  data() {
    return {
      documento: {},
      listaCopiaDocumento: [],
      listaCopiaDocumentoTabela: [],
      submitted: false,
      isSaving: false,
      setores: [],
      funcionarios: [],
      funcionariosCopia: [],
      funcionariostodos: [],
      fechos: [],
      funcoes: [],
    };
  },
  methods: {
    handleSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    carregaSetores: function () {
      const listaSetores = api.buscarSetor().then((response) => {
        this.setores = response.data;
      });
    },
    carregaFuncionario(event) {
      if (event != undefined) {
        const listaFuncionario = api
          .buscarFuncionariosetor(event.id)
          .then((response) => {
            this.funcionarios =
              converteFuncionario.funcionarioDtoToFuncionarioArray(
                response.data
              );
          });
      }
    },
    carregaTodosFuncionario() {
      const listaFuncionario = api.buscarFuncionario().then((response) => {
        this.funcionariostodos =
          converteFuncionario.funcionarioDtoToFuncionarioArray(response.data);
      });
    },
    carregaFecho() {
      const listaFuncionario = api.buscarFecho().then((response) => {
        this.fechos = response.data;
      });
    },
    carregaFuncao() {
      const listaFuncionario = api.buscarFuncao().then((response) => {
        this.funcoes = response.data;
      });
    },
    preecherListaCopia(event) {
      this.listaCopiaDocumentoTabela.push(converteFuncionario.preencheCopiaDocumento(event))
      this.listaCopiaDocumento.push(event.id);
      // console.log(event);
      // console.log(this.listaCopiaDocumento);
    }
  },
  mounted() {
    this.carregaSetores();
    this.carregaTodosFuncionario();
    this.carregaFecho();
    this.carregaFuncao();
  },
};
</script>
