<template>
  <section v-if="isValid" class="photo-capture">
    <video
      v-show="showVideo"
      ref="player"
      class="camera"
      autoplay
      playsinline
    />
    <canvas v-show="!showVideo" class="preview" ref="canvas" />
    <div v-if="!hideBtns" class="center photo-capture-actions">
      <div class="camera-aberta"></div>
      <div v-if="showVideo">
        <button
          :class="'btn flex-center ' + buttonsClasses"
          @click.prevent="capture"
        >
          {{ captureBtnContent }} <i class="fas fa-camera"></i>
        </button>
        <button
          :class="'btn ml-1 flex-center ' + buttonsClasses"
          @click.prevent="closeCamera"
        >
          {{ captureBtnContent }} <i class="fas fa-close"></i>
        </button>
      </div>

      <div class="controls" v-else>
        <button :class="'btn ' + buttonsClasses" @click.prevent="done">
          {{ doneBtnContent }}
        </button>

        <button :class="'btn ml-1 ' + buttonsClasses" @click.prevent="cancel">
          {{ cancelBtnContent }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { EventBus } from "@/main";
export default {
  name: "PhotoCapture",
  props: {
    hideBtns: {
      type: Boolean,
      isRequired: false,
      default: false,
    },
    styling: {
      type: Object,
      isRequired: false,
    },
    value: {
      default: null,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    buttonsClasses: {
      type: String,
      default: "",
    },
    captureBtnContent: {
      default: "",
    },
    cancelBtnContent: {
      default: "Cancelar",
    },
    doneBtnContent: {
      default: "Ok",
    },
  },
  data() {
    return {
      showVideo: true,
      picture: null,
      isValid: true,
    };
  },
  mounted() {
    this.videoPlayer = this.$refs.player;
    this.canvasElement = this.$refs.canvas;
    this.streamUserMediaVideo();
  },
  methods: {
    closeCamera() {
      EventBus.$emit("closeCamera");
    },
    streamUserMediaVideo() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return;
      }
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => (this.videoPlayer.srcObject = stream))
        .catch((msg) => {
          this.isValid = false;
          alert("Webcam não detectada ou permissão não concedida");
        });
    },
    capture() {
      this.showVideo = false;
      this.canvasElement.width = this.videoPlayer.videoWidth;
      this.canvasElement.height = this.videoPlayer.videoHeight;
      var context = this.canvasElement.getContext("2d");
      context.translate(this.canvasElement.width, 0);
      context.scale(-1, 1);
      context.drawImage(this.$refs.player, 0, 0);
      this.stopVideoStream();
      this.picture = this.$refs.canvas.toDataURL();
    },
    done() {
      this.$emit("input", this.picture);
      EventBus.$emit("fotoTirada", this.picture);
      this.showVideo = true;
      this.streamUserMediaVideo();
    },
    cancel() {
      this.showVideo = true;
      this.streamUserMediaVideo();
    },
    stopVideoStream() {
      if (!(this.$refs.player && this.$refs.player.srcObject)) return;
      this.$refs.player.srcObject.getVideoTracks().forEach((track) => {
        track.stop();
      });
    },
  },
};
</script>

<style scoped>
.photo-capture,
video,
canvas {
  width: 200px;
  max-height: 300px;
}

section > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
