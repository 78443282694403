<template>
  <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Consulta SIGMA" />
    <conteudo v-role="'PESQUISAR_BO'">
      <div class="container-fluid">
        <DetalhesConsultaBo v-show="isModalVisible" @closeModalBo="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisible"></div>

        <div class="row p-3">
          <div class="card-body p-0 col-md-3 col-sm-12">
            <div class="card card-primary">
              <div class="card-header background-principal">
                <h3 class="card-title">
                  {{ tituloBusca }}
                </h3>
              </div>

              <div>
                <FormConsultaBo />
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <ResultadosConsultaBo @showModalBo="showModal" />
          </div>
        </div>
      </div>
    </conteudo>
  </section>
</template>

<script>
import DetalhesConsultaBo from "../../../components/consultas/bo/DetalhesConsultaBo.vue";
import FormConsultaBo from "../../../components/consultas/bo/FormConsultaBo.vue";
import ResultadosConsultaBo from "../../../components/consultas/bo/ResultadosConsultaBo.vue";
export default {
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  components: {
    FormConsultaBo,
    ResultadosConsultaBo,
    DetalhesConsultaBo,
  },

  data() {
    return {
      tituloBusca: "Buscar Ocorrência",
      isModalVisible: false,
    };
  },
};
</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}
</style>
