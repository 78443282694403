<template>
  <div class="d-flex flex-row bd-highlight">
    <div class="p-2">
      <button
        type="button"
        class="btn text-color-principal background-principal"
        @click="openModal"
      >
        <i class="nav-icon fas fa-search text-color-principal"></i> Buscar
        pessoa
      </button>
    </div>
  </div>
  <!-- <div class="card card-outline "  >
    <div class="card-header p-0 pt-1 background-principal text-color-principal pl-2 mb-0" data-toggle="collapse" @click="closeModal"
      href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
      <h6 class="box-title pt-2 pb-2 mb-1">
        <i class="nav-icon fas fa-search text-color-principal"></i> Buscar uma pessoa
      </h6>

    </div>
    <div class="collapse" id="collapseExample">
      <div class="card-body" v-if="loading">
        <loader :active="loading" message="Consultando..." />
      </div>
      <div v-else class="card-body">
        <div class="submit-form">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="form-control-label" for="cpf">CPF</label>
                <input key="cpf" type="text" class="form-control form-control-border border-width-2" id="cpfVisita"
                  v-model="busca.cpf" v-mask="'###.###.###-##'" placeholder="XXX.XXX.XXX-XX" />
              </div>
            </div>
            <div class="form-group col-sm-6">
              <label for="nome">Nome</label>
              <input ref="nomeRef" type="text" id="nome" class="form-control form-control-border border-width-2"
                placeholder="Nome completo" :value="busca.nome" @input="
                  (event) =>
                  (busca.nome = event.target.value
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toUpperCase())
                " />
            </div>
          </div>
        </div>

        <div class="float-right">
          <button @click="limparPesquisa" class="btn btn-default mr-1">
            <i class="fas fa-ban"></i> Limpar
          </button>
          <button @click.prevent="pesquisarPessoa" class="btn text-color-principal background-principal ml-1"
            type="submit">
            <i class="fa fa-search"></i> Pesquisar
          </button>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import Loader from "@/components/Loader.vue";
import { EventBus } from "@/main.js";
import ConsultaFuncionarioService from "@/service/ConsultaPessoaService";

export default {
  name: "BotaoConsultaPessoa",
  components: {
    Loader,
  },

  data() {
    return {
      loading: false,
      busca: {
        nome: "",
        cpf: "",
      },
    };
  },
  methods: {
    openModal() {
      EventBus.$emit("abrirModalConsultaPessoa");
    },
    pesquisarPessoa() {
      this.loading = true;
      EventBus.$emit("selecionarAba", "listavisita");
      if (!this.consultaValida()) {
        Toast.fire("Preencha algum campo para buscar!", "", "error");
        this.loading = false;
        return;
      }
      let parametrosConsulta = { nome: this.busca.nome, cpf: this.busca.cpf };
      ConsultaFuncionarioService.buscarPessoa(parametrosConsulta)
        .then((r) => {
          EventBus.$emit("resultadosBuscaPessoa", r.data);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    limparPesquisa(event) {
      this.busca.nome = "";
      this.busca.cpf = "";
      EventBus.$emit("resetarUltimasVisitas", null);
    },
    consultaValida() {
      return this.busca.nome !== "" || this.busca.cpf !== "";
    },
  },
};
</script>
