<template>
  <div>
    <b-modal
    data-keyboard="false"
    v-model="isVisible"
    id="modal-1"
    ref="modal-1"
    title="Buscar Servidor"
    data-backdrop="static"
    size="lg"
    cancel-disabled
    no-close-on-backdrop
    hide-header-close
    header-class="modal-header
      background-principal
      text-color-principal
      card-item-result
      "
    content-class="modal-content border-rounded-detail"
    dialog-class="p-3"
    title-class="modal-title mx-auto my-0"
    >
      <div class="card-body">
        <div
          v-if="servidorNaoEncontrado"
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          Nenhuma servidor foi encontrado.
        </div>

        <div class="row">
          <div class="form-group col-4">
            <label for="busca">Tipo</label>
            <v-select
            class="border-v-select mt-1"
            id="busca"
            v-model="tipoConsultaServidor"
            :options="opcoesBusca"
            @click="limpaInput()"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>

          <div class="form-group col-8">
            <label v-text="tipoConsultaServidor"></label>
            <input
              v-if="tipoConsultaServidor == 'Nome'"
              type="text"
              class="form-control form-control-border border-width-2"
              :ref="'nomeBusca'"
              v-model="nome"
              :placeholder="'Nome do servidor'"
              :name="'nomeBusca'"
            />

            <input
            v-if="tipoConsultaServidor=='CPF'"
            type="text"
            class="form-control form-control-border border-width-2"
            v-model="cpf"
            :ref="'cpfBusca'"
            :placeholder="'000.000.000-00'"
            :name="'cpfBusca'"
            v-mask="'###.###.###-##'"
            /> 

            <input
            v-if="tipoConsultaServidor=='Matrícula'"
            type="text"
            class="form-control form-control-border border-width-2"
            v-model="matricula"
            :ref="'matriculaBusca'"
            :placeholder="'Matrícula do servidor'"
            :name="'matriculaBusca'"

            /> 
            <input
            v-if="tipoConsultaServidor=='ID'"
            type="text"
            class="form-control form-control-border border-width-2"
            v-model="novaMatricula"
            :ref="'novaMatriculaBusca'"
            :placeholder="'Matrícula do servidor'"
            :name="'novaMatriculaBusca'"

            /> 
          </div>

        </div>
      </div>   
    
      <div v-if="visivel" class="card">
        <div class="card-body table-responsive p-0">
          <table  class="table table-hover" >
            <thead>
              <tr>
                <td>Matrícula</td>
                <td>ID</td>
                <td>Nome</td>
              </tr>
            </thead>
            <tbody>
              <tr data-show="false" @click=" enviarServidor(servidor) " v-for="(servidor,i) in resultados" :key="i" class="clicavel ">
                <td>{{ servidor.matricula }}</td>
                <td>{{ servidor.novaMatricula }}</td>
                <td>{{ servidor.nome }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <template  #modal-footer="{ cancel }">
        <button @click.prevent="[emitirConsulta()]" class="btn text-color-principal background-principal ml-1">
          <i class="fa fa-search"></i> Buscar
        </button>
        <b-button @click="[cancel(), limpaInput()]" class="btn btn-default float-right">
          <i class="fa-solid fa-x"></i> Fechar
        </b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import LancamentosAlteracoes from './LancamentosAlteracoes.vue';

export default {
  components:{
    LancamentosAlteracoes
  },

  data(){
    return {
      servidorNaoEncontrado: false,
      isVisible: this.visible,
      tipoConsultaServidor: "Nome",
      matricula: "",
      novaMatricula:"",
      cpf: "",
      nome: "",
      page: 0,
      opcoesBusca: [ "Nome","Matrícula", "ID"],
      resultados:[],
      visivel:false,
      tipoServidor:null,
    }
  },

  created(){

  },

  methods:{


    emitirConsulta() {

      if(this.tipoConsultaServidor=="Nome"){
        consultaFuncionarioService.buscarFuncionarioNome(this.page,this.nome.toUpperCase())
        .then((resp)=>{
          this.resultados=resp.data
          const indisponivel = resp.status.toString == "503"
          if(indisponivel){
            this.servidorNaoEncontrado = true;
            this.resultados=[];
            this.visivel = false
          }else if(this.resultados.length ===0){
            
            this.servidorNaoEncontrado = true;
            this.resultados=[];
            this.visivel = false
          } else {
            this.servidorNaoEncontrado = false
            this.visivel=true
          }
          
        })
      } else if(this.tipoConsultaServidor=="CPF"){
        consultaFuncionarioService.buscarFuncionarioCPF(this.page,this.cpf)
        .then((resp)=>{
          this.resultados=resp.data
        })
      } else if(this.tipoConsultaServidor=="Matrícula"){
        consultaFuncionarioService.buscarFuncionarioMatricula(this.page,this.matricula)
        .then((resp)=>{
          this.resultados = resp.data
          const indisponivel = resp.status.toString == "503"
          if(indisponivel){
            this.servidorNaoEncontrado = true;
            this.resultados=[];
            this.visivel = false
          }else if(this.resultados.length ===0){
            
            this.servidorNaoEncontrado = true;
            this.resultados=[];
            this.visivel = false
          } else {
            this.servidorNaoEncontrado = false
            this.visivel=true
          }
        })
      } else if(this.tipoConsultaServidor=="ID"){
        consultaFuncionarioService.buscarFuncionarioNovaMatricula(this.page,this.novaMatricula)
        .then((resp)=>{
          this.resultados = resp.data
          const indisponivel = resp.status.toString == "503"
          if(indisponivel){
            this.servidorNaoEncontrado = true;
            this.resultados=[];
            this.visivel = false
          }else if(this.resultados.length ===0){
            
            this.servidorNaoEncontrado = true;
            this.resultados=[];
            this.visivel = false
          } else {
            this.servidorNaoEncontrado = false
            this.visivel=true
          }
        })
      }
    },

    limpaInput(){
      this.tipoConsultaServidor= "Nome",
      this.matricula=""
      this.cpf=""
      this.nome=""
      this.resultados=[]
      this.visivel=false
      this.servidorNaoEncontrado = false
    },

    enviarServidor(servidor){
      this.tipoConsultaServidor= "Nome",
      EventBus.$emit("escolherServidor", servidor);
      this.isVisible = false;
      this.limpaInput()
      
    },

  }
}
</script>