var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-cnpjUg"}},[_vm._v("Cnpj Ug")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contrato.cnpjUg),expression:"contrato.cnpjUg"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.cnpjUg.$error,
          },attrs:{"type":"text","name":"cnpjUg","id":"contrato-cnpjUg","data-cy":"cnpjUg"},domProps:{"value":(_vm.contrato.cnpjUg)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "cnpjUg", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-numeroProcesso"}},[_vm._v("Número Processo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contrato.numeroProcesso),expression:"contrato.numeroProcesso"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.numeroProcesso.$error,
          },attrs:{"type":"text","name":"numeroProcesso","id":"contrato-numeroProcesso","data-cy":"numeroProcesso"},domProps:{"value":(_vm.contrato.numeroProcesso)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "numeroProcesso", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-anoProcesso"}},[_vm._v("Ano Processo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contrato.anoProcesso),expression:"contrato.anoProcesso"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.anoProcesso.$error,
          },attrs:{"type":"number","name":"anoProcesso","id":"contrato-anoProcesso","data-cy":"anoProcesso"},domProps:{"value":(_vm.contrato.anoProcesso)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "anoProcesso", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-numeroContrato"}},[_vm._v("Número Contrato")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.contrato.numeroContrato),expression:"contrato.numeroContrato",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.numeroContrato.$error,
          },attrs:{"type":"number","name":"numeroContrato","id":"contrato-numeroContrato","data-cy":"numeroContrato"},domProps:{"value":(_vm.contrato.numeroContrato)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "numeroContrato", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-anoContrato"}},[_vm._v("Ano Contrato")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.contrato.anoContrato),expression:"contrato.anoContrato",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.anoContrato.$error,
          },attrs:{"type":"number","name":"anoContrato","id":"contrato-anoContrato","data-cy":"anoContrato"},domProps:{"value":(_vm.contrato.anoContrato)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "anoContrato", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-idContratacao"}},[_vm._v("Id Contratacao")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contrato.idContratacao),expression:"contrato.idContratacao"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.idContratacao.$error,
          },attrs:{"type":"text","name":"idContratacao","id":"contrato-idContratacao","data-cy":"idContratacao"},domProps:{"value":(_vm.contrato.idContratacao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "idContratacao", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-idContrato"}},[_vm._v("Id Contrato")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contrato.idContrato),expression:"contrato.idContrato"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.idContrato.$error,
          },attrs:{"type":"text","name":"idContrato","id":"contrato-idContrato","data-cy":"idContrato"},domProps:{"value":(_vm.contrato.idContrato)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "idContrato", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-cpfCnpj"}},[_vm._v("Cpf Cnpj")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contrato.cpfCnpj),expression:"contrato.cpfCnpj"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.cpfCnpj.$error,
          },attrs:{"type":"text","name":"cpfCnpj","id":"contrato-cpfCnpj","data-cy":"cpfCnpj"},domProps:{"value":(_vm.contrato.cpfCnpj)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "cpfCnpj", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-objeto"}},[_vm._v("Objeto")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contrato.objeto),expression:"contrato.objeto"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.objeto.$error,
          },attrs:{"type":"text","name":"objeto","id":"contrato-objeto","data-cy":"objeto"},domProps:{"value":(_vm.contrato.objeto)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "objeto", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-dataAssinatura"}},[_vm._v("Data Assinatura")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.contrato.dataAssinatura.$error,
                    },attrs:{"type":"date","id":"contrato-dataAssinatura","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.contrato.dataAssinatura)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataAssinatura', $event)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-dataInicio"}},[_vm._v("Data Inicio")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.contrato.dataInicio.$error,
                    },attrs:{"type":"date","id":"contrato-dataInicio","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.contrato.dataInicio)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataInicio', $event)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-dataFim"}},[_vm._v("Data Fim")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.dataFim.$error,
                  },attrs:{"type":"date","id":"contrato-dataFim","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.contrato.dataFim)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataFim', $event)}}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-valor"}},[_vm._v("Valor")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.contrato.valor),expression:"contrato.valor",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.contrato.valor.$error,
          },attrs:{"type":"number","name":"valor","id":"contrato-valor","data-cy":"valor"},domProps:{"value":(_vm.contrato.valor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contrato, "valor", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"contrato-tipo"}},[_vm._v("Tipo")]),_c('v-select',{staticClass:"border-v-select",class:{
          //'is-invalid': isSaving && $v.contrato.tipo.$error,
        },attrs:{"id":"contrato-tipo","data-cy":"tipo","name":"tipo","options":_vm.tipo,"reduce":function (tipo) { return tipo.id; },"label":"descricao"},model:{value:(_vm.contrato.tipo),callback:function ($$v) {_vm.$set(_vm.contrato, "tipo", $$v)},expression:"contrato.tipo"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn text-color-principal background-principal ml-1",on:{"click":_vm.salvarContrato}},[_c('i',{staticClass:"far fa-envelope"}),_vm._v(" Enviar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":_vm.limparContrato}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Limpar ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }