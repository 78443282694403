<template>
  <div v-if="bo" class="modal fade show h-100 overflow-auto" id="modal-dialog modal-xl" style="display: block"
    aria-modal="true" role="dialog">
    <div class="modal-dialog modal-xl p-3" @click.stop>
      <div>
        <div class="modal-content border-rounded-detail">
          <div class="modal-header  background-principal text-color-principal card-item-result">
            <h5 class="modal-title mx-auto my-0">
              Ocorrência: {{ bo.numeroBo }}/{{
                  bo.ano ? bo.ano.split("/")[2] : ""
              }}
            </h5>
          </div>

          <div class="row table-responsive max-height-content">
            <div>
              <!-- corpo card -->
              <div class="row p-5">
                <div class="col-6 p-1 d-flex flex-column">
                  <span><b>Data do fato: </b> {{ bo.dataFato }}</span>

                  <span>
                    <b>Horário: </b>
                    {{ bo.horaFato }}
                  </span>
                  <span> <b>Data registro: </b>{{ bo.dataRegistro }} </span>

                  <span>
                    <b>Unidade: </b>
                    {{ bo.unidade }}
                  </span>
                  <span>
                    <b>CEP: </b>
                    {{ bo.cep || "N/I" }}
                  </span>
                </div>
                <div class="col-6 p-1 d-flex flex-column">
                  <span>
                    <b>Bairro: </b>
                    {{ bo.bairro }}
                  </span>

                  <span> <b>Cidade: </b> {{ bo.cidade }} </span>

                  <span> <b>País: </b> {{ bo.pais }} </span>

                  <span><b>Referência: </b> {{ bo.referencia }} </span>

                  <span><b>Local: </b> {{ bo.tipoLocal }} </span>
                </div>
                <br />
                <hr />
                <br />
                <div>
                  <b>Relato: </b>
                  <p class="p-1 text-justify">{{ bo.relato }}</p>
                </div>
                <div>
                  <b>Fatos:</b>
                  <div :key="index" v-for="(fato, index) in bo.fatos" class="p-1">
                    <div>
                      <b>{{ fato.codigo }} {{ fato.descricao }} </b>
                      {{ fato.tentado ? "TENTADO" : "CONSUMADO" }}
                    </div>
                    <ul>
                      <span :key="index" v-for="(envolvido, index) in bo.envolvidosBo">
                        <div v-if="fato.natureza == envolvido.idNatureza">
                          <li>
                            {{ envolvido.nome }} {{ envolvido.envolvimento }}
                          </li>
                        </div>
                      </span>
                    </ul>
                  </div>
                </div>

                <div class="col-12">
                  <b>Objetos:</b><br /><br />
                  <div key="objetos" v-if="bo.envolvimentosPessoaObjeto.length !== 0">
                    <div class="row bo-component-objetos">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-body table-responsive p-0">
                            <table class="table table-hover text-nowrap">
                              <thead>
                                <tr>
                                  <th>Tipo</th>
                                  <th>Proprietário</th>
                                  <th>Envolvimento</th>
                                  <th>Providência</th>
                                  <th>Descrição</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr :key="index" v-for="(
                                    objeto, index
                                  ) in bo.envolvimentosPessoaObjeto">
                                  <td>{{ objeto.tipoObjeto }}</td>
                                  <td>{{ objeto.proprietario }}</td>
                                  <td>{{ objeto.envolvimentoObjeto }}</td>
                                  <td>{{ objeto.providenciaObjeto }}</td>
                                  <td>
                                    {{ objeto.descricaoObjeto }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div key="sem-objetos" v-else>
                    <p>Não há objetos!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('closeModalBo')">
              <i class="fa-solid fa-x"></i>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../main.js";
import Loader from "../../Loader.vue";
import BoCardComponent from "../BoCardComponent.vue";

export default {
  name: "DetalhesConsultaBo",
  components: {
    Loader,
    BoCardComponent,
  },
  data() {
    return {
      loaderActive: true,
      bo: null,
    };
  },
  created() {
    EventBus.$on("consultaDetalheBo", (bo) => {
      this.bo = bo;
    });
  },
  methods: {
    fecharModal() { },

    showLoader() {
      this.pessoa = false;
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
