<template>
  <div>
    <div class="row" >
      <div class="form-group col-lg-2">
        <label for="mes">Mês:</label>
        <v-select
          class="border-v-select"
          id="mes"
          v-model="frequencia.mes"
          :options="meses"
          :reduce="meses => meses.id"
          label="descricao"
          >
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>

      <div class="form-group col-md-2">
        <label for="ano">Ano:</label>
        <input
          type="number"
          class="form-control form-control-border border-width-2"
          id="ano"
          v-model="frequencia.ano"
        />
      </div>

      <div class="form-group col-lg-3">
        <label for="lotacao">Lotação</label>
        <v-select
          class="border-v-select"
          id="lotacao"
          v-model="lotacao"
          :options="lotacoes"
          label="descricao"
          >
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>
      <div size="sm" class="my-4">
        <button @click="consultar" class="btn btn text-color-principal background-principal">
          <i class="fa fa-search"></i> Consultar
        </button>
      </div>
      

    </div>
    
    <div v-if="exbirFrequencia">
      <table  class="table  table-responsive  text-nowrap col-12"  >
        <thead>
          <tr>
            <th class="fixed-column">Funcionário</th>
            <th v-for="i in 31" :key="i">{{i}}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(funcionario,index) in funcionarios" :key="index">
            <td class="fixed-column">{{ funcionario.pessoaCorrespondente.nome}}</td>
            <td v-for="i in 31" :key="i">
              <v-select
                class="border-v-select"
                v-model="frequenciasLista[0].dia[i-1]"
                :options="ValoresDia"
                :reduce="ValoresDia => ValoresDia.valor"
                label="descricao" >
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div >
      <button @click="lancar(frequenciaLista)" class=" btn text-color-principal background-principal">
        <i class="fa fa"></i> Lançar Frequência
      </button>
    </div>

  </div>
</template>

<script>

import RhfrequenciaService from "@/service/RhFrequenciaService";
import ConsultaListasService from "@/service/ConsultaListasService";

export default {
  data(){
    return{
      frequencia:{
        mes:null,
        ano:null,
      },
      lotacao:null,
      meses:[

        { id : 1, descricao: "Janeiro" },
        { id : 2, descricao: "Fevereiro" },
        { id : 3, descricao: "Março" },
        { id : 4, descricao: "Abril" },
        { id : 5, descricao: "Maio" },
        { id : 6, descricao: "Junho" },
        { id : 7, descricao: "Julho" },
        { id : 8, descricao: "Agosto" },
        { id : 9, descricao: "Setembro" },
        { id : 10, descricao: "Outubro" },
        { id : 11, descricao: "Novembro" },
        { id : 12, descricao: "Dezembro" },
      ],
      ValoresDia:[
        {valor:'PRESENCA', descricao: "P"},
        {valor:'FALTA', descricao: "F"},
        {valor:'FALTA_JUSTIFICADA,', descricao: "FJ"},
        {valor:'LICENCA_PREMIO', descricao: "LP"},
        {valor:'LICENCA_TRATAMENTO_SAUDE', descricao: "LS"},
        {valor:'SERVICO_EXTERNO', descricao: "SE"},
        {valor:'LICENCA_SEM_VENCIMENTO', descricao: "LV"},
        {valor:'PROCESSO_EXONERACAO', descricao: "PE"},
        {valor:'FERIADO', descricao: "FR"},
        {valor:'ADICIONAL_NOTURNO', descricao: "AN"},
        {valor:'FERIAS', descricao: "FE"},
        {valor:'PRESO', descricao: "PR"},
        {valor:'ABANDONO_CARGO', descricao: "AC"},
        {valor:'PROCESSO_APOSENTARIA,', descricao: "PA"},
        {valor:'DISPOSICAO_SINDICATO', descricao: "DS"},
        {valor:'MANDADO_ELEITORAL', descricao: "ME"},
        {valor:'MANDATO_CLASSISTO', descricao: "MC"},
        {valor:'LICENCA_GESTANTE', descricao: "LG"},
        {valor:'AFASTAMENTO_SEM_REMUNERACAO', descricao: "AS"},
        {valor:'AFASTAMENTO_COM_REMUNERACAO', descricao: "AR"},
        {valor:'LICENCA_PATERNIDADE', descricao: "LT"},
        {valor:'AFASTAMENTO_APOSENTADORIA', descricao: "AP"},

  
      ],
      lotacoes: [],
      
      funcionarios:{},

      indiceTabela: 0,

      frequenciasLista : [
        {
          mes: null,
          ano: null,
          funcionario: {
            id: null
          },
          dia: []
        },
      ],
      exbirFrequencia:false,
    }
    
  },

  created(){
    this.preencherListasObj({
      lotacoes:"unidade"
    });
  },

/*   validations:{
    frequencia:{
      mes:{},
      ano:{},
      dia:{},

      lotacao:{}
    }
  },
 */
  methods:{

    consultar(){
      RhfrequenciaService.listarFuncionariosPorLotacao(this.lotacao.id)
      .then((r)=>{
        this.funcionarios=r.data;
      })
      if (this.funcionarios!= null){
        this.exbirFrequencia=true
        //for
      }
    },

    lancar(frequenciasLista){
      //RhfrequenciaService.inserirFrequencia(this.frequencia)
    },

    preencherListasObj(obj){
      Object.keys(obj).forEach((lista) => {
        ConsultaListasService.buscarListaPorNomeTabela(obj[lista])
        .then((response) => {
          this[lista] = response.data;
        })
        .catch((e) => alert("ERRO: " + e.message));
      });
    }

  },


}
</script>

<style scoped>

.fixed-column {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: white;
  }

</style>