import authHeader from "@/service/auth/auth-header";
import instance from "./axiosConfig";

class UnidadeService {
  buscarUnidadeId(id) {
    return instance.get(`/api/unidades/${id}`, {
      headers: authHeader(),
    });
  }

  cadastrarUnidade(unidade) {
    return instance.post("/api/unidades", unidade);
  }
  cadastrarUnidadeEndereco(enderecoUnidade) {
    return instance.post("/api/enderecos/unidades", enderecoUnidade);
  }
  cadastrarUnidadeEmail(unidadeEmail) {
    return instance.post("/api/emails/unidades", unidadeEmail);
  }

  cadastrarUnidadeTelefone(telefoneUnidade) {
    return instance.post("/api/telefones/unidades", telefoneUnidade);
  }
  deletarUnidadeTelefone(idTelefone,idUnidade) {
    return instance.delete(`/api/telefones/unidades/${idTelefone}/${idUnidade}`);
  }
  deletarUnidadeEmail(idEmail,idUnidade) {
    return instance.delete(`/api/emails/unidades/${idEmail}/${idUnidade}`);
  }
  editarUnidadeTelefone(telefoneUnidade, idTelefone) {
    return instance.patch(`/api/telefones/${idTelefone}`, telefoneUnidade);
  }

  atualizarUnidade(unidade) {
    return instance.patch("/api/unidades/" + unidade.id, unidade);
  }

  buscarUnidadeDetalheId(id) {
    return instance.get(`/api/unidades/inforesumida/${id}`);
  }

  buscarUnidadeDadosId(id) {
    return instance.get(`/api/unidades-dados/${id}`);
  }
}
export default new UnidadeService();
