<template>
  <div class="card-body pt-0 table-responsive" style="height: 500px;">
    <div class="p-1, .ml-1 (margin-right: 4px;) "></div>
    <table class="table table-hover table-striped text-nowrap table-responsive-sm table-head-fixed">
      <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th>
        </tr>
      </thead>
      <tbody>
        <tr
          @click="selecionarTipo(tipoDocumento.id)"
          v-for="(tipoDocumento, i) in tipos"
          :key="i" class="clicavel"
        >
          <td>{{ tipoDocumento.id }}</td>
          <td>{{ tipoDocumento.descricao }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import RHService from "@/service/RHService";

export default {
  data() {
    return {
      tipoDocumento: {},
      tipos: [],
      loaderActive: false,
    };
  },
  mounted() {
    this.listarTipoDocumento();
  },
  watch: {},
  methods: {
    listarTipoDocumento() {
      let user = JSON.parse(localStorage.getItem("user"));
      let token = user.token;

      RHService.getAllTipoDocumento(token).then((resp) => {
        console.log("aq");

        this.tipos = resp.data;
        console.log(this.tipos);
      });
    },
    selecionarTipo(id) {
      console.log("aqui")
      EventBus.$emit("selecionartipoDocumento", id);
      
    },
  },
};
</script>

<style scoped>
</style>
