<template>
    <div>

    </div>
</template>
  
<script>

import jsPDF from "jspdf";
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";

export default {
    name: "PDFOFICIO",
    components: {},
    data() {
        return {
            pdfSrc: "data:application/pdf;base64,",
        };
    },
    methods: {

        updateZonedDateTimeField(field) {
            if (field == null) {

                return "Sem Registro"
            }
            else {
                return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
            }
        },

        montarParagrafo(item) {

            const infoPortaria = `
        Portaria: ${item.portaria_numero || "-"}
        `;


            const destDestinatario = `
        A Sua Senhoria o(ª) Senhor(ª)
        "NOME"
        ${item.unidade || "-"}
        `;

            const messageContent = `
        Senhor(a),

                Informo a Vossa Senhoria, que os servidores relacionados abaixo, 
        estarão de férias no mês de  ${item.nome_mes || "-"} de ${item.ano || "-"},conforme a escala encaminhada
        a este Setor. Solicito comunicar os mesmos para darem ciências de seus 
        avisos. 
        `;

            const servServidor = `
        ${item.matricula || "-"}      ${item.nome || "-"}
        `;
            console.log(item);

            return { info: infoPortaria, dest: destDestinatario, message: messageContent, serv: servServidor };


        },


        criarNovoCabecalho(doc, pageWidth) {

            const center = pageWidth / 2;
            const image = new Image();
            image.src = require("@/assets/logo-ma-small.png");
            image.width = 20;
            image.height = 20;
            let x = (pageWidth - image.width) / 2;

            doc.addImage(image, "PNG", x, 10, 20, 20, "center");

            console.log(1);
            doc.setTextColor(0);
            doc.setFont("times", "bold");
            doc.setFontSize(12);
            doc.text("Estado do Maranhão", center, 35, null, null, "center");

            doc.setFont("times", "normal");
            doc.setFontSize(9);
            doc.text(
                "Secretaria de Estado da Segurança Pública",
                center,
                39,
                null,
                null,
                "center"
            );

            doc.text(
                "Av. dos Franceses, s/n, Bairro Vila Palmeira, CEP 65036-283, São Luís-MA",
                center,
                43,
                null,
                null,
                "center"
            );
            doc.text("Telefone: (98) 3214 3700", center, 47, null, null, "center");


        },


        async emitirPdf(dados, formDados) {
            const doc = new jsPDF("p", "mm", "a4");
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const margin = 20;
            let cursorY = 70;

            this.criarNovoCabecalho(doc, pageWidth);
            const { info, dest, message } = this.montarParagrafo(dados[0]); // Supondo que todos os itens têm a mesma portaria, etc.

            let linesInfo = doc.splitTextToSize(info, pageWidth - margin * 2);
            doc.setFont("times", "bold").setFontSize(14).text(linesInfo, margin, cursorY);
            cursorY += linesInfo.length * 5 + 10;

            let linesDest = doc.splitTextToSize(dest, pageWidth - margin * 2);
            doc.setFont("times", "bold").setFontSize(14).text(linesDest, margin, cursorY);
            cursorY += linesDest.length * 5 + 15;

            let linesMessage = doc.splitTextToSize(message, pageWidth - margin * 2);
            doc.setFont("times", "normal").setFontSize(14).text(linesMessage, margin, cursorY);
            cursorY += linesMessage.length * 5 + 10;

            if (cursorY >= pageHeight - margin) {
                doc.addPage();
                cursorY = 20; 
            }

        
            for (const item of dados) {
                const servServidor = `${item.matricula || "-"}      ${item.nome || "-"}`;

                if (cursorY >= pageHeight - margin) {
                    doc.addPage();
                    cursorY = 20; 
                }

                doc.setFont("times", "normal").setFontSize(12).text(servServidor, margin, cursorY);
                cursorY += 10; 
            }

            doc.save("Ofício_Ferias.pdf");
        },

        formatarDataAtual() {
            const hoje = new Date();
            const opcoes = { year: 'numeric', month: 'long', day: 'numeric' };
            return hoje.toLocaleDateString('pt-BR', opcoes);
        },


    },
    mounted() {

        this.$root.$on("pdfOficio", this.emitirPdf);
    }
}
</script>
  
  