<template>
  <div class="card card-primary max-height-content">
    <div class="background-principal card-header text-color-principal">
      <h3 class="card-title">Unidades</h3>
    </div>
    <div class="card-body">
      <Loader :active="loaderActive" message="Consultando unidades" />
      <div class="p-3" v-show="!loaderActive">
        <label for="inputUnidadeEscolha">
          <i class="fa-solid fa-magnifying-glass"></i>
          Encontre a unidade desejada:</label>
        <v-select class="border-v-select"  id="inputUnidadeEscolha"
          v-model="unidade" :options="unidades" label="descricao" v-on:input="clicandoOption(unidade.id)">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
        <hr>
        <TreeMenu :descricao="governoEstado.descricao" :unidadesInferiores="governoEstado.unidadesInferiores"
          :id="governoEstado.id" :depth="0">
        </TreeMenu>
        <TreeMenu :descricao="unidadeExterna.descricao" :unidadesInferiores="unidadeExterna.unidadesInferiores"
          :id="unidadeExterna.id" :depth="0">
        </TreeMenu>


      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/Loader.vue';
import { EventBus } from '@/main';
import ConsultaListasService from '@/service/ConsultaListasService';
import UnidadeService from '@/service/UnidadeService';
import TreeMenu from './TreeMenu.vue';


export default {
  name: "VisualizacaoUnidade",
  components: { TreeMenu, TreeMenu, Loader },
  data() {
    return {
      governoEstado: {},
      unidadeExterna: {},
      loaderActive: false,
      unidade: undefined,
      unidades: []
    };
  },
  created() {
    this.carregarArvoreUnidade();
    EventBus.$on('recarregarUnidades', (event) => {
      this.unidade = undefined;
      this.carregarArvoreUnidade();
    });

    ConsultaListasService.buscarListaPorNomeTabela("unidade")
      .then((response) => {
        this.unidades = response.data;
      }).catch((e) => this.alertaErroPesquisarUnidades());
  },

  methods: {
    alertaErroPesquisarUnidades() {
      Toast.fire(
        "Ocorreu um erro ao pesquisar as unidades cadastradas", "",
        "error");
    },

    clicandoOption(unidadeId) {
      EventBus.$emit('', unidadeId);
      EventBus.$emit('showModalUnidade')
    },

    carregarArvoreUnidade() {
      this.loaderActive = true;
      UnidadeService.buscarUnidadeId(2).then((response) => {
        this.governoEstado = response.data;
        this.loaderActive = false;

      }).catch((e) => {
        this.alertaErroPesquisarUnidades;
        this.loaderActive = false;
      });
      UnidadeService.buscarUnidadeId(1).then((response) => {
        this.unidadeExterna = response.data;
        this.loaderActive = false;
      }).catch((e) => {
        this.alertaErroPesquisarUnidades;
        this.loaderActive = false;
      });;
    }
  }
}
</script>


<style scoped>
.select {
  --vs-dropdown-max-height: 150px;

}


</style>
