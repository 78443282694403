<template>
    <div class="card-body ">
      <!-- <button class="btn btn-block btn-primary mb-3" style="background: #0f192c">{{ text }}</button> -->
      <menuProcessos />
    </div>
</template>
<script>

import menuProcessos from "@/components/processos/MenuProcessos.vue";

export default {
  components: {
    menuProcessos
  }
}
</script>