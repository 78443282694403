<template>
  <form>

    <div class="card card-primary">
      <div class="card-header background-principal" s>
        <h3 class="card-title">Busca por Arma</h3>
        <!-- /.card-tools -->
      </div>

      <div class="card-body">
        <div class="form-group">
          <label for="inputNumeroSerie" class="pesquisar-numero-serie">Digite o número de série</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputNumeroSerie"
            placeholder="Digite o número de série" v-model="numeroSerie" />
        </div>
        <div class="form-group">
          <label for="inputModeloArma" class="pesquisar-modelo-arma">Digite o modelo</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputModeloArma"
            placeholder="Digite o modelo da arma" v-model="modeloArma" />
        </div>
        <div class="form-group">
          <label for="inputCapacidadeArma" class="pesquisar-capacidade-arma">Digite a capacidade</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputCapacidadeArma"
            placeholder="Digite a capacidade da arma" v-model="capacidadeArma" />
        </div>
        <div class="form-group">
          <label for="inputQtdCamarasTiro" class="pesquisar-qtd-camaras-tiro">Digite a qtd. de câmaras de tiro</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputQtdCamarasTiro"
            placeholder="Digite a quantidade de câmaras de tiro" v-model="quantidadeCamara" @input="
              (event) =>
              (quantidadeCamara = event.target.value
                .normalize('NFD')
                .replace(/[^0-9]+/g, '')
                .toLowerCase())
            " />

        </div>
        <div class="form-group">
          <label for="inputComprimentoCano" class="pesquisar-comprimento-cano">Digite o comprimento do cano</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputComprimentoCano"
            placeholder="Digite o comprimento do cano" v-model="comprimentoCano" @input="
              (event) =>
              (comprimentoCano = event.target.value
                .normalize('NFD')
                .replace(/[^0-9]+/g, '')
                .toLowerCase())
            " />

        </div>
        <div class="form-group">
          <label for="inputMarca" class="pesquisar-marca">Selecione a marca</label>
          <!-- <select type="select" class="form-control form-control-border border-width-2" id="inputMarca"
          placeholder="Selecione a marca" v-model="marcaArma">
          <option disabled value="">Selecione uma marca de arma</option>
          <option :key="id" v-for="(marcasArma, id) in marcasArma" :value=marcasArma.id> {{ marcasArma.descricao
          }}</option>
        </select> -->
          <v-select class="border-v-select" id="inputCorDoCarro" v-model="marcaArma"
            :disabled="
              marcasArma == null ||
              marcasArma == []
            " :options="marcasArma" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="form-group">
          <label for="inputTipo" class="pesquisar-Tipo">Selecione o tipo</label>
          <!-- <select type="select" class="form-control form-control-border border-width-2" id="inputTipo"
          placeholder="Selecione o tipo" v-model="tipoArma">
          <option disabled value="">Selecione um tipo de arma</option>
          <option :key="id" v-for="(tiposArma, id) in tiposArma" :value=tiposArma.id> {{ tiposArma.descricao }}
          </option>
        </select> -->
          <v-select class="border-v-select" id="inputCorDoCarro" v-model="tipoArma"
            :disabled="
              tiposArma == null ||
              tiposArma == []
            " :options="tiposArma" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="form-group">
          <label for="inputCalibre" class="pesquisar-calibre">Selecione o calibre</label>
          <!-- <select type="select" class="form-control form-control-border border-width-2" id="inputCalibre"
          placeholder="Selecione o calibre" v-model="calibreArma">
          <option disabled value="">Selecione o calibre da arma</option>
          <option :key="id" v-for="(calibresArma, id) in calibresArma" :value=calibresArma.id> {{
              calibresArma.descricao
          }}</option>
        </select> -->
          <v-select class="border-v-select" id="inputCorDoCarro" v-model="calibreArma"
            :disabled="
              calibresArma == null ||
              calibresArma == []
            " :options="calibresArma" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="form-group">
          <label for="inputAcabamento" class="pesquisar-acabamento">Selecione acabamento</label>
          <!-- <select type="select" class="form-control form-control-border border-width-2" id="inputAcabamento"
          placeholder="Selecione o acabamento" v-model="acabamentoArma">
          <option disabled value="">Selecione o acabamento da arma</option>
          <option :key="id" v-for="(acabamentosArma, id) in acabamentosArma" :value=acabamentosArma.id> {{
              acabamentosArma.descricao
          }}</option>
        </select> -->
          <v-select class="border-v-select" id="inputCorDoCarro"
            v-model="acabamentoArma" :disabled="
              acabamentosArma == null ||
              acabamentosArma == []
            " :options="acabamentosArma" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="form-group">
          <label for="inputTipoCano" class="pesquisar-tipo-cano">Selecione o tipo do cano</label>
          <!-- <select type="select" class="form-control form-control-border border-width-2" id="inputTipoCano"
          placeholder="Selecione o tipo do cano" v-model="tipoCanoArma">
          <option disabled value="">Selecione o tipo do cano da arma</option>
          <option :key="id" v-for="(tiposCanoArma, id) in tiposCanoArma" :value=tiposCanoArma.id> {{
              tiposCanoArma.descricao
          }}</option>
        </select> -->
          <v-select class="border-v-select" id="inputCorDoCarro" v-model="tipoCanoArma"
            :disabled="
              tiposCanoArma == null ||
              tiposCanoArma == []
            " :options="tiposCanoArma" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <!-- /.card-header -->
      <!-- </div> -->

      <div class="card-footer">
        <button @click.prevent="emitirConsulta" v-on:keyup.enter="emitirConsulta" type="submit"
          class="btn col-12 background-principal text-color-principal">
          <i class="fas fa-search"></i>
          Buscar
        </button>
      </div>

      <!-- /.card-body -->
      <!-- /.card-footer -->
    </div>
    <!-- /.card -->
  </form>

</template>

<script>
import ConsultaListasService from '@/service/ConsultaListasService.js';
import { EventBus } from '../../../main.js';
import LocalIpService from "@/service/LocalIpService";

export default {
  name: 'FormArmaConsulta',
  data() {
    return {
      numeroSerie: '',
      modeloArma: '',
      capacidadeArma: '',
      quantidadeCamara: '',
      comprimentoCano: '',
      marcaArma: '',
      marcasArma: [],
      tipoArma: '',
      tiposArma: [],
      calibreArma: '',
      calibresArma: [],
      acabamentoArma: '',
      acabamentosArma: [],
      tipoCanoArma: '',
      tiposCanoArma: [],
      page: 1,
      //ipUsuario:null

    }
  },
  created() {
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });


    ConsultaListasService.buscarListaPorNomeTabela('marca_arma')
      .then((response) => {
        this.marcasArma = response.data
      })
      .catch((e) => console.log('ERRO: ' + e.message));

    ConsultaListasService.buscarListaPorNomeTabela('tipo_arma')
      .then((response) => {
        this.tiposArma = response.data
      })
      .catch((e) => console.log('ERRO: ' + e.message));

    ConsultaListasService.buscarListaPorNomeTabela('calibre_arma')
      .then((response) => {
        this.calibresArma = response.data
      })
      .catch((e) => console.log('ERRO: ' + e.message));

    ConsultaListasService.buscarListaPorNomeTabela('acabamento_arma')
      .then((response) => {
        this.acabamentosArma = response.data
      })
      .catch((e) => console.log('ERRO: ' + e.message));

    ConsultaListasService.buscarListaPorNomeTabela('tipo_cano_arma')
      .then((response) => {
        this.tiposCanoArma = response.data
      })
      .catch((e) => console.log('ERRO: ' + e.message));
  },
  methods: {
    mostrarAlerta() {
      // eslint-disable-next-line no-undef
      Toast.fire('Preencha ao menos um campo!!', '', 'error')
    },
    formValido() {
      if (
        this.numeroSerie ||
        this.modeloArma ||
        this.capacidadeArma ||
        this.quantidadeCamara ||
        this.comprimentoCano ||
        this.verificaNuloVazio(this.marcaArma) ||
        this.verificaNuloVazio(this.tipoArma) ||
        this.verificaNuloVazio(this.calibreArma) ||
        this.verificaNuloVazio(this.acabamentoArma) ||
        this.verificaNuloVazio(this.tipoCanoArma)
      ) {
        return true
      }
      return false
    },
    verificaNuloVazio(valor) {
      if (valor === null || valor === "" || valor === {}) {

        return false
      }
      return true
    },
    emitirConsulta() {

      if (this.formValido()) {

        const params = {
          numeroSerie: this.numeroSerie,
          modeloArma: this.modeloArma,
          capacidadeArma: this.capacidadeArma,
          quantidadeCamara: this.quantidadeCamara,
          comprimentoCano: this.comprimentoCano,
          marcaArma: this.marcaArma ? this.marcaArma.id : '',
          tipoArma: this.tipoArma ? this.tipoArma.id : '',
          calibreArma: this.calibreArma ? this.calibreArma.id : '',
          acabamentoArma: this.acabamentoArma ? this.acabamentoArma.id : '',
          tipoCanoArma: this.tipoCanoArma ? this.tipoCanoArma.id : '',
          page: this.page,
          //ipUsuario:this.ipUsuario
        }


        EventBus.$emit('consultaArma', params)
      } else {
        this.mostrarAlerta()
      }
    }
  }
}
</script>

<style>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}
</style>
