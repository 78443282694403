<template>
  
    <div class="card card-primary card-tabs">
      <div class="card-header background-principal p-0 pt-1">
        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">

          <li class="nav-item" @click="reRender()">
              <a class="nav-link active show" 
              id="custom-tabs-one-home-tab" @click.prevent="apagarCampos" data-toggle="pill" href="#tabelacontrato" role="tab"
              ref="tabelacontrato" aria-controls="custom-tabs-one-home" aria-selected="true"
              >Tabela Contrato </a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
              id="custom-tabs-one-perfil-tab"  data-toggle="pill" href="#cadastrocontrato" role="tab"
              aria-controls="custom-tabs-one-profile" aria-selected="false" ref="cadastrocontrato"
             >Cadastro Contrato</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="custom-tabs-one-tabContent" >
          <div class="tab-pane fade active show" id="tabelacontrato" role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab">
             <TblContrato :key="contrato_reload"/>
          </div>
          <div class="tab-pane fade" id="cadastrocontrato" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
            <FormContrato/>
          </div>
        </div>
      </div>

    </div>

</template>
<script>
import TblContrato from "@/components/processos/TabelaContrato.vue";
import FormContrato from "@/components/processos/CadastroContrato.vue";
import { EventBus } from "@/main";
export default {
  
  name: "TabContrato",
  components: { TblContrato, FormContrato },
  data() {
    return {
      contrato_reload:1,
    }
  },
  created() {

    EventBus.$on("AlterarTabContrato",()=>{
        this.$refs.cadastrocontrato.click()
        
      })

   
    
  },
  methods: {

     apagarCampos(){
      EventBus.$emit("ApagarCamposContrato")

    },

    reRender(){
      this.contrato_reload++;
    },
  }
}
</script>
<style scoped>
.text-link {
  color: #fff
}

.text-link:hover {
  color: #fff
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}
</style>
