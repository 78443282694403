<template>
  <div class="pl-4 pr-4">
    <Loader :active="loaderActive" message="Consultando..." />
    <div v-if="pessoa && !loaderActive">
      <h6 class="mt-4 mb-3"><b>DADOS BÁSICOS: </b></h6>
      <div class="row m-10 p-0">
        <!-- <img
          class="img mx-0 px-0 img-card-measure img-cut"
        /> -->

        <img
          class="img mx-0 px-0 img-card-measure img-cut"
          :src="
            url
              ? url
              : 'https://webc.ssp.ma.gov.br/imagens/elementos/icone-imagem-indisponivel2.png'
          "
        />
        <!-- <div class="col-sm-12 col-md-3"> -->
        <div class="col-sm-12 col-md-5">
          <p class="medium mb-0">
            <b>Nome: </b> {{ pessoa.nome || "Sem nome registrado" }}
          </p>
          <p class="medium mb-0">
            <b>Data Nascimento: </b> {{ pessoa.dataNascimento | formatarData }}
          </p>
          <p class="medium mb-0">
            <b>Nome da mãe: </b> {{ pessoa.mae || "Sem nome registrado" }}
          </p>
        </div>
        <div class="col-sm-12 col-md-3">
          <p class="medium mb-0">
            <b>Sexo: </b> {{ pessoa.sexo || "Sem sexo registrado" }}
          </p>
          <p class="medium mb-0">
            <b>Nome do pai: </b> {{ pessoa.pai || "Sem nome registrado" }}
          </p>
          <p class="medium mb-0">
            <b>CPF: </b>
            {{
              pessoa.documentos
                ? pessoa.documentos.find(
                    (o) => o.tipoDocumento.descricao === "CPF"
                  )
                  ? pessoa.documentos.find(
                      (o) => o.tipoDocumento.descricao === "CPF"
                    ).numero
                  : "Sem documento registrado"
                : "Sem documento registrado"
            }}
          </p>
        </div>
        <div class="col-sm-12 col-md-3">
          <p class="medium mb-0">
            <b>RG: </b>
            {{
              pessoa.documentos
                ? pessoa.documentos.find(
                    (o) => o.tipoDocumento.descricao === "RG"
                  )
                  ? pessoa.documentos.find(
                      (o) => o.tipoDocumento.descricao === "RG"
                    ).numero
                  : "Sem documento registrado"
                : "Sem documento registrado"
            }}
          </p>
          <p class="medium mb-0">
            <b>CNH: </b>
            {{
              pessoa.documentos
                ? pessoa.documentos.find(
                    (o) => o.tipoDocumento.descricao === "CNH"
                  )
                  ? pessoa.documentos.find(
                      (o) => o.tipoDocumento.descricao === "CNH"
                    ).numero
                  : "Sem documento registrado"
                : "Sem documento registrado"
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Loader from "@/components/Loader.vue";
import CadastroPessoaService from "@/service/CadastroPessoaService";

export default {
  name: "DadosPessoaFuncionario",
  data() {
    return {
      pessoa: {},
      url: null,
      loaderActive: false,
    };
  },
  created() {
    console.log(this.$route.params);
    if (
      this.$route.params.hasOwnProperty(
        "idPessoaFluxoCadastroPessoaAndFuncionario"
      )
    ) {
      this.buscaPessoaId(
        this.$route.params.idPessoaFluxoCadastroPessoaAndFuncionario
      );
    } else {
      this.buscaPessoaId(this.$store.state.funcionario.idPessoaFuncionario);
      this.pessoa = this.$store.state.pessoaFuncionario;
    }
    // this.buscaPessoaId(this.$store.state.funcionario.idPessoaFuncionario);
    // EventBus.$on("fecharModalEPesquisar", (listener) => {
    //   this.buscaPessoaId(listener)
    // })
  },
  filters: {
    formatarData: function (data) {
      if (data == "" || data == null) {
        return "Sem nascimento registrado";
      } else {
        const [ano, mes, dia] = data.split("-");
        const dataFormatada = `${dia}/${mes}/${ano}`;
        return dataFormatada;
      }
    },
  },
  methods: {
    buscaPessoaId(id) {
      this.loaderActive = true;
      if (id !== undefined && id !== 0) {
        try {
          CadastroPessoaService.buscarPessoaCompletaPorId(id).then(
            (response) => {
              //console.log(response);
              this.pessoa = response.data;
              this.loaderActive = false;
              this.$store.commit("nomePessoaFuncionario", this.pessoa.nome);
              const base64Imagem = this.pessoa.fotos.reduce(function (
                prev,
                current
              ) {
                return prev.id > current.id ? prev : current;
              }).imagem;
              if (base64Imagem) {
                this.url = `data:image/jpeg;charset=utf-8;base64,${base64Imagem}`;
              }
            }
          );
        } catch (e) {
          alert("ERRO: " + e.message);
          this.loaderActive = false;
        }
      } else {
        this.loaderActive = false;
        this.$router.push({ name: "administracaovisualizacaofuncionario" });
      }
    },
  },
  components: { Loader },
};
</script>
<style scoped>
.img-cut {
  width: 10vh;
  height: 10vh;
  /* border-radius: 50%; */
  margin-left: 5px;
}
</style>
