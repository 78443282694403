<template>
  <div v-if="mostrarCards" class="row px-5 d-flex justify-content-around table-responsive max-height-content-detail">
    <div>
      <div class="row mr-0 justify-content-around">
        <div :key="indice" v-for="(bo, indice) in bos"
          class="card card-item bg-light col-sm-12 col-md-12 m-10 rounded-12 p-0 ">
          <a href="#" @click="emitirClickBo(bo.idBo)">
            <div class="text-center">
              <p class="header  background-principal text-color-principal card-item-result">
                {{ bo.numeroBo }}/{{ bo.anoBo }}
              </p>
            </div>
            <div class="row max-height-card-boperson" align="center">
              <div class="d-flex flex-wrap p-2">
                <ul class="mb-0 fa-ul">
                  <li class="medium">
                    <b>Data do fato: </b> {{ bo.dataFato }}
                  </li>
                </ul>
                <ul class="mb-0 fa-ul">
                  <li class="medium">
                    <b>Data registro: </b>{{ bo.dataRegistro }}
                  </li>
                </ul>

                <ul class="mb-0 fa-ul">
                  <li class="medium">
                    <b>Unidade: </b>
                    {{ bo.unidadeRegistro }}
                  </li>
                </ul>
                <ul class="mb-0 fa-ul">
                  <li class="medium">
                    <b>Ano: </b>
                    {{ bo.anoBo }}
                  </li>
                </ul>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../main.js";

export default {
  name: "ListaBo",
  props: ["bos"],
  data() {
    return {
      mostrarCards: true,
    };
  },
  created() {
    EventBus.$on("esconderDetalheBo", this.esconderCards);
  },
  methods: {
    esconderCards() {
      this.mostrarCards = true;
    },
    emitirClickBo(id) {
      this.mostrarCards = false;
      EventBus.$emit("buscarBoEscolhido", id);
    },
  },
};
</script>

<style scoped>
.max-height-card-boperson {
  max-height: 480px
}
</style>
