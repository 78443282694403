<template>
  <div class="modal fade show h-100 overflow-auto" id="modal-xl" style="display: block" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-xl" @click.stop>
      <div v-if="veiculoDetalhe">
        <div class="modal-content border-rounded-detail">
          <div class="
              modal-header
              background-principal
              text-color-principal
              card-item-result
            ">
            <h4 class="modal-title mx-auto">{{ veiculoDetalhe.placa }}</h4>
          </div>
          <div class="modal-body">
            <h6 class="mt-4 mb-3"><b>DADOS BÁSICOS: </b></h6>
            <div class="row m-10 p-0">
              <div class="col-sm-12 col-md-4">
                <p class="medium mb-0">
                  <b>Proprietário: </b> {{ veiculoDetalhe.proprietario }}
                </p>
                <p class="medium mb-0">
                  <b>Chassi: </b> {{ veiculoDetalhe.chassi }}
                </p>
                <p class="medium mb-0">
                  <b>Renavam: </b> {{ veiculoDetalhe.renavam }}
                </p>
                <p class="medium mb-0">
                  <b>Modelo do Veículo: </b> {{ veiculoDetalhe.modeloVeiculo }}
                </p>
                <p class="medium mb-0">
                  <b>Categoria do Veículo: </b>
                  {{ veiculoDetalhe.categoriaveiculo }}
                </p>
              </div>
              <div class="col-sm-12 col-md-4">
                <p class="medium mb-0">
                  <b>Espécie do Veículo: </b>
                  {{ veiculoDetalhe.especieveiculo }}
                </p>
                <p class="medium mb-0">
                  <b>Câmbio: </b> {{ veiculoDetalhe.cambio }}
                </p>
                <p class="medium mb-0">
                  <b>Motor: </b> {{ veiculoDetalhe.motor }}
                </p>
                <p class="medium mb-0"><b>Cor: </b> {{ veiculoDetalhe.cor }}</p>
                <p class="medium mb-0">
                  <b>Situação: </b> {{ veiculoDetalhe.situacao }}
                </p>
              </div>
              <div class="col-sm-12 col-md-4">
                <p class="medium mb-0">
                  <b>Cidade: </b> {{ veiculoDetalhe.cidade }}
                </p>
                <p class="medium mb-0">
                  <b>Estado: </b> {{ veiculoDetalhe.estado }}
                </p>
                <p class="medium mb-0">
                  <b>Ano de Fabricacão: </b>
                  {{ veiculoDetalhe.anoFabricacao | exibirAno }}
                </p>
                <p class="medium mb-0">
                  <b>Ano do Modelo: </b>
                  {{ veiculoDetalhe.anoModelo | exibirAno }}
                </p>
                <p class="medium mb-0">
                  <b>Fabricante: </b> {{ veiculoDetalhe.fabricante }}
                </p>
              </div>
            </div>
            <h6 class="mt-4 mb-3"><b>OCORRÊNCIAS: </b></h6>
            <div>
              <ListaBoComponent :bos="veiculoDetalhe.bos" />
              <BoCardComponent />
            </div>

          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('closeModalVeiculo')">
              <i class="fa-solid fa-x"></i>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConsultaService from '@/service/ConsultaService'
import { EventBus } from '../../../main.js'
import BoCardComponent from '../BoCardComponent.vue'
import ListaBoComponent from '../ListaBoComponent.vue'
import LocalIpService from "@/service/LocalIpService";

export default {
  name: 'DetalhesVeiculosConsulta',
  data() {
    return {
      veiculoDetalhe: {},
      bos: [],
      ipUsuario:null
    }
  },
  created() {
    LocalIpService.getLocalIp()
      .then((response) => {
        this.ipUsuario = response.data.ip;
      })
      .catch((error) => {
        console.log(error);
      });

    EventBus.$on('consultaVeiculoDetalhe', (event) => {
      this.consultarVeiculo(event)
    })
  },
  filters: {
    exibirAno: function (dataCompleta) {
      if (dataCompleta) {
        const apenasAno = dataCompleta.substring(0, 4)
        return apenasAno
      }
    }
  },
  methods: {
    consultarVeiculo(id) {
      try {
        if(this.ipUsuario!=null){
          ConsultaService.buscarVeiculoDetalhe(id)
          .then((response) => {
            this.veiculoDetalhe = response.data
          })
          .catch((e) => this.alertaFalhaConsultarVeiculo())
        }else{
          Toast.fire("Não foi possível fazer a consulta", "", "error");
        }

      } catch (e) {
        this.alertaFalhaConsultarVeiculo()
      }
    },
    alertaFalhaConsultarVeiculo() {
      Toast.fire("Falha ao consultar veículo", "", "error");
    },
  },
  components: { ListaBoComponent, BoCardComponent }
}
</script>
