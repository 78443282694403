<template>
    <div class="card card-primary card-tabs">
        <div class="card-header background-principal p-0 pt-1">
            <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                <li class="nav-item" >
                    <a class="nav-link active show" 
                    id="custom-tabs-one-home-tab" data-toggle="pill" href="#cadastroOrdenadores" role="tab"
                    ref="cadastroOrdenadores" aria-controls="custom-tabs-one-profile" aria-selected="true" 
                    >Cadastro de Ordenadores de Despesas</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link "
                    id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#cadastrogrupos" role="tab"
                    aria-controls="custom-tabs-one-profile" aria-selected="false" ref="cadastrogrupos"
                    >Cadastro Grupos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link "
                    id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#tipodocumentos" role="tab"
                    aria-controls="custom-tabs-one-profile" aria-selected="false" ref="tipodocumentos"
                    >Cadastro Tipo de Documentos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link "
                    id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#tipoanotacao" role="tab"
                    aria-controls="custom-tabs-one-profile" aria-selected="false" ref="tipoanotacao"
                    >Cadastro Tipo de Anotações</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link "
                    id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#localidade" role="tab"
                    aria-controls="custom-tabs-one-profile" aria-selected="false" ref="localidade"
                    >Cadastro de Localidade</a>
                </li>
            </ul>
        </div>
        <div class="card-body" style="    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
">
            <div class="tab-content" id="custom-tabs-one-tabContent" >
                <div class="tab-pane fade active show" id="cadastroOrdenadores" role="tabpanel" 
                    aria-labelledby="custom-tabs-one-home-tab">
                    <CadastroOrdenadores/>
                </div>
                <div class="tab-pane fade" id="cadastrogrupos" role="tabpanel"
                    aria-labelledby="custom-tabs-one-home-tab"  >
                    <CadastroGrupos/>
                </div>
                <div class="tab-pane fade" id="tipodocumentos" role="tabpanel"
                    aria-labelledby="custom-tabs-one-home-tab"  >
                    <TipoDocumentos/>
                </div>
                <div class="tab-pane fade" id="tipoanotacao" role="tabpanel"
                    aria-labelledby="custom-tabs-one-home-tab"  >
                    <TipoAnotacao/>
                </div>
                <div class="tab-pane fade" id="localidade" role="tabpanel"
                    aria-labelledby="custom-tabs-one-home-tab"  >
                    <Localidade/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CadastroOrdenadores from './CadastroOrdenadores.vue';
import CadastroGrupos from './CadastroGrupos.vue';
import TipoDocumentos from './TipoDocumentos.vue';
import TipoAnotacao from './TipoAnotacao.vue';
import Localidade from './Localidade.vue'

export default{
    components:{
        CadastroOrdenadores, CadastroGrupos, TipoDocumentos, TipoAnotacao, Localidade
    },
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>