<template>
  <div class="card card-primary card-tabs">
    <div class="card-header background-principal p-0 pt-1">
      <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
        <li class="nav-item" v-role="'LISTAR_VISITAS_TODAS'">
          <a
            class="nav-link"
            @click="hideFormCadastroVisitaVisible"
            id="tab-listagemvisita"
            data-toggle="tab"
            href="#listavisita"
            role="tab"
            ref="listavisita"
            aria-controls="listavisita"
            aria-selected="true"
            >Todas Visitas</a
          >
        </li>
        <li class="nav-item" v-role="'LISTAR_VISITAS'">
          <!-- <a
            :class="'nav-link ' + (cadastroPessoaExterno ? '' : 'active')" -->
            <a
            class="nav-link active"
            @click="hideFormCadastroVisitaVisible"
            id="tab-listagemvisita"
            data-toggle="tab"
            href="#listavisitaabertas"
            role="tab"
            ref="listavisitaabertas"
            aria-controls="listavisitaabertas"
            aria-selected="true"
            >{{ tituloTab }}</a
          >
        </li>
        <li class="nav-item" v-role="'CADASTRO_VISITA'">
          <a
            class="nav-link"
            id="tab-cadastro"
            data-toggle="tab"
            href="#cadastrofuncionario"
            role="tab"
            ref="cadastrovisita"
            aria-controls="cadastrofuncionario"
            @click="abrirModalConsultaPessoa"
            aria-selected="true"
            >Cadastro de visita</a
          >
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="tab-content" id="tabs">
        <div class="tab-pane fade active show max-height-content" id="listavisitaabertas"
          role="tabpanel"
          aria-labelledby="tab-listagemvisita"
          aria-selected="true"
        >
          <div class="row mx-3 my-3">
            {{ msg }}
          </div>

          <div class="row px-5 d-flex justify-content-around">
            <loading :active="isLoading" :is-full-page="fullPage" />
            <div class="card card-item bg-light col-sm-12 col-md-5 cardpessoa"
              :key="i" v-for="(visita, i) in visitasLista" 
              @click="escolhaCadastroAtualizacao(visita)" v-if="!listaVisitaVazia">
              <div>
                <p
                  class="header card-item-result background-principal text-color-principal"
                >
                  {{ visita.nome }}
                </p>
              </div>
              <div class="row">
                <div class="col-lg-3 col-sm-12">
                  <img
                    v-if="visita.foto"
                    v-bind:src="'data:image/jpeg;base64,' + visita.foto"
                    alt="user-avatar"
                    style="max-width: 90%; margin-left: 5%"
                    class=""
                  />
                  <img
                    v-else
                    src="https://webc.ssp.ma.gov.br/imagens/elementos/icone-imagem-indisponivel2.png"
                    alt="user-avatar"
                    class="img img-thumbnail mx-0 px-0 img-card-measure"
                  />
                </div>

                <div class="col-9 py-3">
                  <div>
                    <p class="mx-0 my-0 py-0"><b>Mãe: </b> {{ visita.mae }}</p>
                    <!-- <p class="mx-0 my-0 py-0">
                      <b>CPF: </b>
                      {{ visita.cpf }}
                    </p> -->
                    <p v-if="visita.observacao" class="mx-0 my-0 py-0">
                      <b>Obs.: </b>
                      {{ visita.observacao }}
                    </p>
                    <p v-if="visita.dataVisita" class="mx-0 my-0 py-0">
                      <b>Dt. da visita: </b>
                      {{ visita.dataVisita }}
                    </p>
                  </div>
                  <div>
                    <p v-if="visita.descricao" class="mx-0 my-0 py-0">
                      <b>Setor visitado: </b>
                      {{ visita.descricao }}
                    </p>
                    <p v-if="visita.nomePessoaVisitada" class="mx-0 my-0 py-0">
                      <b>Visitado(a): </b>
                      {{ visita.nomePessoaVisitada }}
                    </p>
                    <p class="mx-0 my-0 py-0">
                      <b>Data de nascimento:</b>
                      {{ visita.dataNascimento }}
                    </p>
                    <p class="mx-0 my-0 py-0" v-if="visita.dataVisita">
                      <b>
                        {{
                          visita.dataSaida
                            ? "Dt. de saída:"
                            : "Visita em andamento"
                        }}</b
                      >
                      {{ visita.dataSaida ? visita.dataSaida : "" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="listavisita"
          role="tabpanel"
          aria-labelledby="tab-cadastro"
          aria-selected="true"
        >
          <loading :active="isLoading" :is-full-page="fullPage" />

          <ListasTodasVisitas ref="todasVisitas" />
        </div>
        <div
          class="tab-pane fade"
          id="cadastrofuncionario"
          role="tabpanel"
          aria-labelledby="tab-cadastro"
          aria-selected="true"
        >
          <loading :active="isLoading" :is-full-page="fullPage" />

          <div v-show="isFormCadastroVisitaVisible">
            <div class="row">
              <FormDadosBasicosComponent
                :habilitarForms="habilitarForms"
                ref="dadosBasicosRef"
                class="col-sm-12 col-lg-9"
              />
              <CameraComponent
                :habilitarForms="habilitarForms"
                class="col-sm-12 col-lg-3"
                ref="cameraRef"
              />
            </div>

            <FormDocumentoComponent ref="documentoRef" />

            <FormVisitaComponent ref="visitaRef" />

            <button
              @click.prevent="cadastrarVisitante"
              class="btn btn-success float-right ml-1"
              :disabled="isDisabled"
            >
              Cadastrar visita
            </button>
            <button
              @click="limparCampos"
              class="btn btn-default ml-1 float-right"
            >
              <i class="fas fa-ban"></i> Limpar
            </button>
          </div>

          <div v-show="!isFormCadastroVisitaVisible">
            <h1 class="text-center">
              Consulte uma pessoa para cadastrar uma visita
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CadastroVisita from "@/service/CadastroVisita";
import ListasTodasVisitas from "@/components/visita/ListaTodasVisitas.vue";
import Loading from "vue-loading-overlay";
import FormDadosBasicosComponent from "@/components/visita/FormDadosBasicosComponent.vue";
import CameraComponent from "@/components/visita/Camera.vue";
import FormDocumentoComponent from "@/components/visita/FormDocumentoComponent.vue";
import FormVisitaComponent from "@/components/visita/FormVisitaComponent.vue";
import { EventBus } from "@/main";
import CadastroPessoaService from "@/service/CadastroPessoaService";

import { mapState } from 'vuex';

export default {
  name: "TabsCadastroVisita",
  components: {
    ListasTodasVisitas,
    Loading,
    FormDadosBasicosComponent,
    CameraComponent,
    FormDocumentoComponent,
    FormVisitaComponent,
  },
  props: ['listaResultado'],
  data() {
    return {
      tituloTab: "Últimas visitas",
      isFormCadastroVisitaVisible: false,
      msg: "",
      visitasLista: [],
      isLoading: false,
      fullPage: false,
      habilitarForms: true,
      isDisabled: false,
      listaVisitaVazia: true,
      visita: {
        pessoa: {},
        documento: {},
        visita: {},
        foto: {
          imagem: null,
        },
      },
    }
  },
  computed: {
    ...mapState(['cadastroPessoaExterno']),
    ...mapState(['cadastroPessoaExternoVisita']),
    ...mapState(['fluxo']),
  },
  watch: {
    listaResultado:{
      immediate: true,
      handler(newValue) {

        this.visitasLista = [];

        if (newValue[0]){
          this.visitasLista.push(newValue[0]);
          this.listaVisitaVazia = false;
        }else{
          this.visitasLista.push(newValue);
          this.listaVisitaVazia = true;
        }

        

        this.selecionarAba("listavisitaabertas");
        // this.pessoa = newValue;
        // if (this.cadastroPessoaExterno){
        //   this.escolhaCadastroAtualizacao(newValue);
        // }
      }
    }
  },
  created() {
    if (!this.cadastroPessoaExterno && !this.cadastroPessoaExternoVisita){
      this.buscarUltimasVisitas();
    }
  },
  methods: {
    hideFormCadastroVisitaVisible() {
      this.isFormCadastroVisitaVisible = false;
      // this.listavisita ? (this.listaVisitaVazia = true) : (this.listaVisitaVazia = false);
    },
    abrirModalConsultaPessoa() {
      if (this.isFormCadastroVisitaVisible == false) {
        if (this.$store.state.fluxoCadastroPessoaAndVisita != true) {
          this.$store.commit("fluxoCadastroPessoaAndVisita", false);
          this.$bvModal.show("modal-1");
        }
        this.$store.commit("fluxoCadastroPessoaAndVisita", false);
      }
      this.$store.commit("fluxoCadastroPessoaAndVisita", false);
    },
    buscarUltimasVisitas() {
      this.visitasLista = [];
      CadastroVisita.buscarVisitasAbertas().then((r) => {
        //let ultimasVisitas = this.formatarResposta(r.data);
        let ultimasVisitas = r.data;
        this.visitasLista = ultimasVisitas;

        r.data.length == 0 ? (this.listaVisitaVazia = true) : (this.listaVisitaVazia = false);
      });
    },
    limparCampos() {
      this.isLoading = false;
      this.submitted = false;
      this.isDisabled = false;
      this.visita = {
        pessoa: {},
        documento: {},
        visita: {},
      };
      EventBus.$emit("preencherCamposDadosBasicos", {});
      EventBus.$emit("preencherCampoFoto", {});
      EventBus.$emit("preencherURLPreviewImagem", null);
      EventBus.$emit("preencherListaDocumentos", []);
      EventBus.$emit("preencherCamposVisita", {});
      EventBus.$emit("preencherCamposDocumento", {
        tipoDocumento: null,
        numero: "",
        dataEmissao: "",
        dataValidade: "",
      });
      (this.deveValidarDoc = true),
        // this.mostrarbtncadastro = false,
        (this.msg = ""),
        (this.tituloTab = "Últimas visitas"),
        // (this.visitasLista = []),
        EventBus.$emit("esconderCamposDocumento", false);
      this.habilitarForms = true;
      // this.buscarUltimasVisitas();
    },
    escolhaCadastroAtualizacao(visita) {
      this.isLoading = true;
      this.fullPage = true;
      if (visita.dataVisita) {
        visita.dataSaida
          ? this.escolhaCadastro(visita)
          : this.escolhaAtualizacao(visita);
      } else {
        this.buscarPessoaPorId(visita);
      }
    },
    buscarPessoaPorId(visita) {
      let id = visita.id; // id da pessoa vindo da consulta pessoa
      if (visita.idPessoa) {
        // id pessoa vindo da listagem de visita
        id = visita.idPessoa;
      }
      // this.limparCampos();
      // this.mostrarbtncadastro = true;
      CadastroPessoaService.buscarPessoaCompletaPorId(id).then((r) => {
        if (r.data) {
          this.preencherCamposPessoa(r.data);
          this.habilitarForms = false;
          this.isLoading = false;
          this.submitted = false;
          this.isDisabled = false;
          this.buscarUltimasVisitas();
          this.selecionarAba("cadastrovisita");
        }
      });
    },
    preencherCamposPessoa(data) {
      // this.limparCampos();
      let dadosPessoa = {
        id: data.id,
        nome: data.nome,
        sexo: data.sexo,
        mae: data.mae,
        dataNascimento: data.dataNascimento,
      };
      if (data.fotos && data.fotos.length > 0) {
        const ultimaFoto = data.fotos.reduce(function (prev, current) {
          return prev.id > current.id ? prev : current;
        });
        if (ultimaFoto.imagem) {
          var foto = {
            id: ultimaFoto.id,
            imagem: ultimaFoto.imagem
              ? `data:image/jpeg;charset=utf-8;base64,${ultimaFoto.imagem}`
              : null,
          };
        }
      }

      let documentos = data.documentos;

      EventBus.$emit("preencherCamposDadosBasicos", dadosPessoa);
      this.isFormCadastroVisitaVisible = true;
      EventBus.$emit("preencherCampoFoto", foto);
      EventBus.$emit("preencherListaDocumentos", documentos);
    },
    escolhaAtualizacao(visita) {
      const visitaId = {
        id: visita.id,
      };
      this.$swal
        .fire({
          title: "Deseja finalizar esta visita de " + visita.nome + " ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c92f",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            CadastroVisita.atualizarVisitaSaida(visitaId)
              .then((response) => {
                this.buscarUltimasVisitas();
                this.sucessoAtualizarDataSaida();
              })
              .catch((e) => {
                this.falhaAtualizarDataSaida();
              });
          }
        });
    },
    selecionarAba(nomeAbaRef) {
      if (this.$refs[nomeAbaRef]){
        this.$refs[nomeAbaRef].click();

        // if (this.cadastroPessoaExterno){
        
        //   this.$router.push(this.fluxo);

        // }

      }
      
      //this.isFormCadastroVisitaVisible=true;
    },
    cadastrarVisitante(event) {
      if (!this.submitted) {
        // this.showLoader();
        // console(event);

        this.lerCamposComponentesFilhos();
        if (this.mensagensValidacao().length == 0) {
          this.isLoading = true;
          this.submitted = true;
          this.isDisabled = true;

          CadastroVisita.cadastrarVisita(
            this.formatarBodyRequestCadastroVisita()
          )
            .then((r) => {
              this.habilitarForms = true;
              Toast.fire("Visita cadastrada com sucesso !", "", "success");
              this.limparCampos();
              this.habilitarForms = true;
              this.$refs.listavisita.click();
              this.buscarUltimasVisitas();
              this.submitted = false;
              // this.hideLoader();
              this.isLoading = false;
            })
            .catch((e) => {
              let mensagem = e.response.data.mensagem;
              Toast.fire(mensagem || "Erro interno no sistema!", "", "error");
            });
        } else {
          Toast.fire(this.mensagensValidacao().join("; <br>"), "", "error");
          // this.hideLoader();
          this.isLoading = false;
          this.submitted = false;
          this.isDisabled = false;
        }
      }
    },
    formatarBodyRequestCadastroVisita() {
      let obj = {
        pessoaDTO: {
          id: this.visita.pessoa.id,
          nome: this.visita.pessoa.nome,
          sexo: this.visita.pessoa.sexo,
          mae: this.visita.pessoa.mae,
          dataNascimento: this.visita.pessoa.dataNascimento,
        },
        documentoDTO: {
          numero: this.visita.documento.numero,
          tipoDocumento: this.visita.documento.tipoDocumento
            ? this.visita.documento.tipoDocumento
            : null,
          dataExpedicao: this.visita.documento.dataEmissao,
          dataValidade: this.visita.documento.dataValidade,
        },
        visitaDTO: {
          observacao: this.visita.visita.observacao,
          nomePessoaVisitada: this.visita.visita.nomePessoaVisitada,
          setorVisitado: this.visita.visita.setor,
        },
        fotoDTO: {
          imagem: this.visita.foto ? this.visita.foto.imagem : null,
          id: this.visita.foto ? this.visita.foto.id : null,
        },
      };

      return obj;
    },
    lerCamposComponentesFilhos() {
      this.visita.pessoa = this.$refs.dadosBasicosRef.pessoa;
      this.visita.documento = this.$refs.documentoRef.documento;
      this.visita.visita = this.$refs.visitaRef.visita;
      this.visita.foto = {
        imagem: this.$refs.cameraRef.url,
        id: this.$refs.cameraRef.id,
      };
    },
    mensagensValidacao() {
      let campos = this.formatarBodyRequestCadastroVisita();
      let mensagens = [];

      if (!campos.pessoaDTO.nome) {
        mensagens.push("Preencha o nome da pessoa!");
      }
      if (!campos.pessoaDTO.mae) {
        mensagens.push("Preencha o nome da mãe!");
      }

      if (!campos.pessoaDTO.sexo) {
        mensagens.push("Selecione o sexo da pessoa!");
      }
      if (this.deveValidarDoc) {
        if (
          !campos.documentoDTO.tipoDocumento ||
          campos.documentoDTO.tipoDocumento == {}
        ) {
          mensagens.push("Selecione um tipo de documento válido!");
        }
        if (!campos.documentoDTO.numero) {
          if (!campos.pessoaDTO.id) {
            mensagens.push("Preenchar o número do documento!");
          }
        }
      }

      if (campos.visitaDTO) {
        if (!campos.visitaDTO.nomePessoaVisitada) {
          mensagens.push("Preencha o nome da pessoa visitada!");
        }
      }
      if (campos.visitaDTO) {
        if (!campos.visitaDTO.setorVisitado) {
          mensagens.push("Preencha o setor visitado!");
        }
      }

      return mensagens;
    },
  }
}
</script>