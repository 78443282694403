<template>
  <div class="container-fluid p-3">
    <div class="card card-primary p-0 col-md-12 col-sm-12">
      <form>
        <div class="card-header text-color-principal background-principal">
          <h3 class="card-title">Compartilhar Documentos</h3>
          <div class="card-tools  ">
            <button
              type="button"
              v-if="!showAdicionarNovoFuncionario"
              @click.prevent="showAdicionarFunc"
              class="btn btn-success"
            >
              <i class="fa-solid fa-share-nodes"></i>
              Compartilhar
            </button>
            <button
              type="button"
              v-if="showAdicionarNovoFuncionario"
              @click.prevent="closeAdicionarFunc"
              class="btn btn-danger"
            >
              <i class="fa fa-arrow-left"></i>
              Voltar
            </button>
          </div>
        </div>
        <Loader :active="loaderActive" message="Consultando funcionários" />
        <div
          class="
            card-body
            table-responsive
            overflow-auto
            px-2
            max-height-content-compartilhar-doc
          "
          v-show="!showAdicionarNovoFuncionario && !loaderActive"
        >
          <table class="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Matricula</th>
                <th>Data Cadastro</th>
                <th class="text-center">Ativo</th>
                <th class="text-center">Signatário</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="func in funcCompartilhados"
                :key="funcCompartilhados.id"
              >
                <td>{{ func.nomefuncsecundario }}</td>
                <td>{{ func.matriculafuncsecundario }}</td>
                <td>{{ func.datacadastro | formatarDataHora }}</td>

                <td class="text-center">
                  <!-- <input class="form-check-input ml-2" type="checkbox" value="" id="flexCheckDefault"
                      v-model="func.ativo" @click="atualizarRelFuncionarioCompartilhadoAtivo(func)"> -->
                  <b-check
                    id="flexCheckDefault"
                    v-model="func.ativo"
                    @change="atualizarRelFuncionarioCompartilhadoAtivo(func)"
                  >
                  </b-check>
                </td>
                <td class="text-center">
                  <!-- <input class="form-check-input ml-2" type="checkbox" value="" id="flexCheckDefault"
                      v-model="func.signatario" @click="atualizarRelFuncionarioCompartilhadoSignatario(func)"> -->
                  <b-check
                    id="flexCheckDefault"
                    v-model="func.signatario"
                    @change="
                      atualizarRelFuncionarioCompartilhadoSignatario(func)
                    "
                  ></b-check>
                </td>
                <td>
                  <button
                    type="button"
                    class="mx-1 btn btn-danger"
                    @click="deleteRelFuncionarioCompartilhado(func.id)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="card-body"
          v-show="showAdicionarNovoFuncionario && !loaderActive"
        >
          <div class="form-group">
            <label class="form-control-label" for="funcionario-compartilhar"
              >Selecione um funcionário para compartilhar seus documentos:
            </label>
            <v-select
              class="border-v-select"
              id="funcionario-compartilhar"
              name="funcionario-compartilhar"
              v-model="funcCompartilhar"
              :options="funcionariostodos"
              :getOptionLabel="(funcCompartilhar) => funcCompartilhar.nome"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
          <div class="form-group col-12 row">
            <div class="mx-2">
              <label
                class="form-control-label"
                for="funcionario-compartilhar-ativo"
                >Ativo:
              </label>
              <div class="form-check">
                <input
                  class="form-check-input mx-1"
                  type="checkbox"
                  value=""
                  v-model="novoFunc.ativo"
                />
              </div>
            </div>
            <div class="mx-4">
              <label
                class="form-control-label"
                for="funcionario-compartilhar-Signatario"
                >Signatário:
              </label>
              <div class="form-check">
                <input
                  class="form-check-input mx-1"
                  type="checkbox"
                  value=""
                  v-model="novoFunc.signatario"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer" v-show="showAdicionarNovoFuncionario">
          <button
            type="button"
            class="
              mx-1
              btn
              background-principal
              text-color-principal
              float-right
            "
            @click="cadastrarRelFuncionarioCompartilhado"
          >
            <i class="fa fa-plus"></i>
            Cadastrar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import CompartilhamentoDocFuncionariosService from "@/service/CompartilhamentoDocFuncionariosService";

export default {
  name: "CompartilharDoc",
  data() {
    return {
      funcCompartilhados: {},
      showAdicionarNovoFuncionario: false,
      loaderActive: false,
      novoFunc: {
        ativo: false,
        signatario: false,
      },
      funcCompartilhar: {},
      funcionariostodos: [],
    };
  },
  created() {
    this.buscarFuncionariosCompartilhados();
    this.carregaTodosFuncionario();
  },
  filters: {
    formatarDataHora: function (dataHora) {
      if (dataHora == "" || dataHora == null) {
        return "Data não preenchida";
      } else {
        const [date, hour] = dataHora.split("T");
        const [ano, mes, dia] = date.split("-");
        const dataFormatada = `${dia}/${mes}/${ano}`;
        const [hora, minuto, segundo] = hour.split(":");
        const horaFormatada = `${hora}:${minuto}`;
        return dataFormatada + " " + horaFormatada;
      }
    },
  },
  methods: {
    alertaFalhaAtualizarRel() {
      Toast.fire("Falha ao atualizar relação!", "", "error");
    },
    alertaFalhaFuncionarioNaoEscolhido() {
      Toast.fire("Por favor escolha um funcionário!", "", "error");
    },
    alertaFalhaListarRel() {
      Toast.fire("Falha ao listar relações!", "", "error");
    },
    alertaFalhaAoCarregarFunc() {
      Toast.fire("Falha ao listar funcionários!", "", "error");
    },
    alertaFalhaCadastrarRel() {
      Toast.fire("Falha ao cadastrar relação!", "", "error");
    },
    alertaFalhaExcluirRel() {
      Toast.fire("Falha ao excluir relação!", "", "error");
    },
    alertaSucessoCadastrarRel() {
      Toast.fire("Sucesso ao cadastrar relação!", "", "success");
    },
    alertaSucessoAtualizarRel() {
      Toast.fire("Sucesso ao atualizar relação!", "", "success");
    },
    alertaSucessoExcluirRel() {
      Toast.fire("Sucesso ao excluir relação!", "", "success");
    },
    showAdicionarFunc() {
      this.showAdicionarNovoFuncionario = true;
    },
    closeAdicionarFunc() {
      this.showAdicionarNovoFuncionario = false;
    },
    carregaTodosFuncionario() {
      CompartilhamentoDocFuncionariosService.listarTodosFuncionarios()
        .then((response) => {
          this.funcionariostodos = response.data;
        })
        .catch((e) => {
          this.alertaFalhaAoCarregarFunc();
        });
    },
    buscarFuncionariosCompartilhados() {
      this.loaderActive = true;
      CompartilhamentoDocFuncionariosService.buscarFuncionariosCompartilhados()
        .then((response) => {
          this.loaderActive = false;
          this.funcCompartilhados = response.data;
        })
        .catch((e) => {
          this.loaderActive = false;
          this.alertaFalhaListarRel();
        });
    },
    atualizarRelFuncionarioCompartilhadoSignatario(func) {
      this.loaderActive = true;
      const funcPatchAtualizacao = {
        id: func.id,
        signatario: !func.signatario,
      };
      CompartilhamentoDocFuncionariosService.atualizarFuncionarioCompartilhado(
        funcPatchAtualizacao
      )
        .then((response) => {
          this.loaderActive = false;
          this.alertaSucessoAtualizarRel();
        })
        .catch((e) => {
          this.loaderActive = false;
          this.alertaFalhaAtualizarRel();
        });
    },
    atualizarRelFuncionarioCompartilhadoAtivo(func) {
      this.loaderActive = true;
      const funcPatchAtualizacao = {
        id: func.id,
        ativo: !func.ativo,
      };
      CompartilhamentoDocFuncionariosService.atualizarFuncionarioCompartilhado(
        funcPatchAtualizacao
      )
        .then((response) => {
          this.alertaSucessoAtualizarRel();
          this.loaderActive = false;
        })
        .catch((e) => {
          this.loaderActive = false;
          this.alertaFalhaAtualizarRel();
        });
    },
    deleteRelFuncionarioCompartilhado(idRelFuncComp) {
      this.loaderActive = true;
      CompartilhamentoDocFuncionariosService.deleteFuncionarioCompartilhado(
        idRelFuncComp
      )
        .then((response) => {
          this.loaderActive = false;
          this.alertaSucessoExcluirRel();
          this.buscarFuncionariosCompartilhados();
        })
        .catch((e) => {
          this.loaderActive = false;
          this.alertaFalhaExcluirRel();
        });
    },
    cadastrarRelFuncionarioCompartilhado() {
      if (this.novoFunc !== {} && this.funcCompartilhar !== ({} || null)) {
        this.loaderActive = true;
        const novoRelFuncDoc = {
          idFuncSecundario: this.funcCompartilhar.id,
          ativo: this.novoFunc.ativo,
          signatario: this.novoFunc.signatario,
        };
        CompartilhamentoDocFuncionariosService.cadastrarFuncionarioParaCompartilhar(
          novoRelFuncDoc
        )
          .then((response) => {
            this.loaderActive = false;
            this.alertaSucessoCadastrarRel();
            this.showAdicionarNovoFuncionario = false;
            this.buscarFuncionariosCompartilhados();
          })
          .catch((e) => {
            this.loaderActive = false;
            this.alertaFalhaCadastrarRel();
          });
      } else {
        this.alertaFalhaFuncionarioNaoEscolhido();
      }
    },
  },
  components: { Loader },
};
</script>

<style scoped>
.max-height-content-compartilhar-doc {
  max-height: 30vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
