<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card card-primary">
        <div class="card-header background-principal">
          <h3 class="card-title">Buscar Histórico</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="exampleSelectBorderWidth2">Tipo</label>
            <select
              v-model="tipoConsultaFuncionario"
              class="custom-select form-control-border border-width-2"
              id="exampleSelectBorderWidth2"
              v-on:click="limpaInput"
            >
              <option disabled value="">Selecione</option>
              <option value="nome">Nome</option>
              <option value="matricula">Matrícula</option>
              <option value="cpf">CPF</option>
            </select>
          </div>
          <div v-show="tipoConsultaFuncionario == 'nome'">
            <div class="form-group">
              <label for="inputNome" class="pesquisar-quantidade"
                >Digite o Nome</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputNome"
                placeholder="Digite o nome"
                v-model="nomeFuncionario"
                required
                @keyup.enter="consultar"
              />
            </div>
          </div>
          <div v-show="tipoConsultaFuncionario == 'matricula'">
            <div class="form-group">
              <label for="inputMatricula" class="pesquisar-quantidade"
                >Digite a Matrícula</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputMatricula"
                placeholder="Digite o matrícula"
                v-model="matriculaFuncionario"
                @keyup.enter="consultar"
              />
            </div>
          </div>
          <div v-show="tipoConsultaFuncionario == 'cpf'">
            <div class="form-group">
              <label for="inputCPF" class="pesquisar-quantidade"
                >Digite o CPF</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputCPF"
                placeholder="Digite o CPF"
                v-mask="'###.###.###-##'"
                v-model="cpfFuncionario"
                @keyup.enter="consultar"
              />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="form-group">
            <button
              @click.prevent="consultar"
              @keyup.enter="consultar"
              class="btn col-12 background-principal text-color-principal"
            >
              <i class="fas fa-search"></i>
              Buscar Histórico
            </button>
          </div>
          
          <div class="form-group">
            <b-button @click.prevent="cadastrarAnotacao" class="btn btn-success col-12"
              ><i class="fa-solid fa-user-plus"></i>Cadastrar
              Histórico
            </b-button>
          </div>
          
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="card card-primary">
        <div class="background-principal card-header text-color-principal">
          <h3 class="card-title">Histórico</h3>
          <!-- //v-if="showPaginacao" -->
          <div >
            <div class="float-right" v-if="resultados.length > 0">
              <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"
                ><i class="fas fa-angle-left mx-2 text-color-principal"></i
              ></a>
              Página {{ page + 1 }}
              <a href="#" v-on:click.prevent="page++"
                ><i class="fas fa-angle-right mx-2 text-color-principal"></i
              ></a>
            </div>
            <div class="float-right" v-if="resultados.length == 0">
              <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"
                ><i class="fas fa-angle-left mx-2 text-color-principal"></i
              ></a>
            </div>
          </div>
        </div>
         <ConsultaHistorico/>
        <div
          v-if="resultados.length == 0 && semResultados"
          class="mx-auto my-auto sem-resultados py-2"
        >
          <div>Sem resultados!</div>
        </div>
         <!-- <ConsultaAnotacao/> -->
        <div v-if="resultados.length > 0">
          
    
        </div>
      </div>
    </div>
    <loading :active="loaderActive" :is-full-page="fullPage" />
  </div>
</template>
  <script>

import ConsultaAnotacao from "@/components/rh/anotacao/ConsultaAnotacao.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CadastroAnotacoes from "@/components/rh/anotacao/CadastroAnotacao.vue";
import ConsultaHistorico from "@/components/rh/anotacao/ConsultaHistorico.vue";
import { EventBus } from "@/main";

export default {
  name: "FormFuncionarioConsulta",
  components: {
    ConsultaAnotacao,
    Loading,
    CadastroAnotacoes,
    ConsultaHistorico,
    
  },
  data() {
    return {
      tipoConsultaFuncionario: "nome",
      matriculaFuncionario: "",
      cpfFuncionario: "",
      nomeFuncionario: "",
      page: 0,
      resultados: [],
      loaderActive: false,
      fullPage: false,
      semResultados: false,
    };
  },

  
  methods: {
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    
    },

    limpaInput(){},

    
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.img-cut {
  max-width: 100px;
  max-height: 100px;

  height: 10vh;
  width: 10vh;
  object-fit: cover;
  object-position: center top;
}

.cardFuncionario {
  cursor: pointer;
}
</style>