<template>
  
    <div class="card card-primary card-tabs">
      <div class="card-header background-principal p-0 pt-1">
        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">

          <li class="nav-item" @click="reRender()">
              <a class="nav-link active show" 
              id="custom-tabs-one-home-tab" @click.prevent="apagarCampos"  data-toggle="pill" href="#tabelaresultado" role="tab"
              ref="tabelaresultado" aria-controls="custom-tabs-one-home" aria-selected="true"
              >Tabela Resultado </a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
              id="custom-tabs-one-perfil-tab"  data-toggle="pill" href="#cadastroresultado" role="tab"
              aria-controls="custom-tabs-one-profile" aria-selected="false" ref="cadastroresultado"
             >Cadastro Resultado</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="custom-tabs-one-tabContent" >
          <div class="tab-pane fade active show" id="tabelaresultado" role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab">
             <TblResultado :key="resultado_reload"/>
          </div>
          <div class="tab-pane fade" id="cadastroresultado" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
            <FormResultado/>
          </div>
        </div>
      </div>
    </div>
 
</template>
<script>
import TblResultado from "@/components/processos/TabelaResultado.vue";
import FormResultado from "@/components/processos/CadastroResultado.vue";
import { EventBus } from "@/main";
export default {
  
  name: "TabResultado",
  components: { TblResultado, FormResultado },
  data() {
    return {
      resultado_reload:1,


    }
  },
  created() {
     EventBus.$on("AlterarTabResultado",()=>{
        this.$refs.cadastroresultado.click()
        
      })

    
  },
  methods: {

    apagarCampos(){
      EventBus.$emit("ApagarCamposResultado")

    },

    reRender(){
      this.resultado_reload++;
    },

  }
}
</script>
<style scoped>
.text-link {
  color: #fff
}

.text-link:hover {
  color: #fff
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}
</style>
