<template>
  <div class="card-body pt-0">
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="prevPage">Anterior</a>
        </li>
        <li class="page-item" v-for="pageNumber in pages" :key="pageNumber"
          :class="{ active: pageNumber === currentPage }">
          <a class="page-link" href="#" @click.prevent="setPage(pageNumber)">{{ pageNumber }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="nextPage">Próxima</a>
        </li>
      </ul>
    </nav>
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th class="text-center">Ano Exercício</th>
          <th class="text-center">Ano</th>
          <th class="text-center">Mês</th>
          <th class="text-center">Data Início</th>
          <th class="text-center">Data Fim</th>
          <th class="text-center">Anotação</th>
          <th class="text-center">Transferência de Férias</th>
          <th class="text-center">Status</th>
          <th class="text-center">Ações</th>
        </tr>
      </thead>
      <tbody v-if="listaFerias">
        <tr v-for="ferias in paginetedFerias" v-bind:key="ferias.id">
          <td class="text-center">{{ ferias.ano_exercicio }}</td>
          <td class="text-center">{{ ferias.ano }}</td>
          <td class="text-center">{{ ferias.mes }}</td>
          <td class="text-center">
            {{ updateZonedDateTimeField(ferias.data_inicio) }}
          </td>
          <td class="text-center">
            {{ updateZonedDateTimeField(ferias.data_fim) }}
          </td>
          <td class="text-center">
            <i :class="ferias.anotado
          ? 'fa-solid fa-circle-check'
          : 'fa-solid fa-circle-xmark'
        "></i>
          </td>
          <td class="text-center" v-if="ferias.data_inicio_alteracao && ferias.data_fim_alteracao">
            {{ updateZonedDateTimeField(ferias.data_inicio_alteracao) }} -
            {{ updateZonedDateTimeField(ferias.data_fim_alteracao) }}
          </td>
          <td class="text-center" v-else>{{ "Sem transferencia" }}</td>
          <td class="text-center">
            {{
        ferias.data_inicio_alteracao && ferias.suspensao
          ? "Transferencia suspensa"
          : ferias.data_inicio_alteracao && !ferias.suspensao
            ? "Transferencia ativa"
            : ferias.suspensao
              ? "Ferias suspensas"
              : "Ferias ativas"
      }}
          </td>
          <td class="text-center">
            <!-- <button type="button" class="btn btn-default btn-sm transferir" v-b-modal.modal-2
              @click="enviarFeriasTransferencia(ferias, $event)" title="Transferir férias">
              <i class="fa-solid fa-right-left"></i>
            </button>

            <button type="button" class="btn btn-default btn-sm " v-b-modal.modal-40
              @click="editarTransferencia(ferias, $event)" title="Editar Transferencia">
              <i class="fa-solid fa-pencil"></i>
            </button> -->

              <button type="button" class="btn btn-default btn-sm" data-toggle="dropdown" title="Transferência de férias">
                <i class="fa-solid fa-right-left"></i>
              </button>
            <ul class="dropdown-menu" role="menu">
                      <li v-b-modal.modal-2><a @click="enviarFeriasTransferencia(ferias, $event)" class="dropdown-item" href="#"> Transferir férias </a></li>
                      <li v-b-modal.modal-40><a @click="editarTransferencia(ferias, $event)" class="dropdown-item" href="#"> Editar transferência </a></li>
            </ul>

             
            <button type="button" class="btn btn-default btn-sm enviar" v-b-modal.modal-0
              @click="enviarDadosFerias(ferias.id, $event)" title="Editar férias">
              <i class="fa-solid fa-pen-to-square"></i>
            </button>
            <button type="button" :class="[
        ferias.anotado
          ? 'botaoClicado'
          : 'btn btn-default btn-sm anotar',
      ]" @click="anotacaoFerias(ferias, $event)" title="Anotar férias">
              <i class="fa-solid fa-check"></i>
            </button>
            <button type="button" class="btn btn-default btn-sm" @click="suspenderFerias(ferias)"
              title="Suspender férias" v-if="!ferias.suspensao">
              <i class="fa-solid fa-ban"></i>
            </button>
            <button type="button" class="btn btn-default btn-sm" @click="openModal(ferias)"
              title="Historico de transferencias" v-if="ferias.data_inicio_alteracao">
              <i class="fa-solid fa-clock-rotate-left"></i>
            </button>

            <button type="button" class="btn btn-default btn-sm" @click="imprimirTransferencia(ferias, $event)"
              title="Imprimir Transferência">
              <i class="fa-solid fa-print"></i>
            </button>

          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <div class="mx-auto my-auto sem-resultados py-2">
          <div>Sem resultados para sua busca!</div>
        </div>
      </tbody>
    </table>



    <div class="card-footer">
      <div class="float-right">
        <button v-b-modal.modal-6 type="button" class="btn btn-success "><i
            class="fa-solid fa-user-plus pt-1"></i>Cadastrar
          Férias</button>


        <!-- <button class="btn background-principal text-color-principal ml-3" data-toggle="dropdown">
              <i class="far fa-envelope"></i> Imprimir
          </button>
            <ul class="dropdown-menu" role="menu">
                      <li><a class="dropdown-item" href="#"> Tabela de Férias </a></li>
                      <li><a class="dropdown-item" href="#"> Aviso de Férias </a></li>
                      <li><a class="dropdown-item" href="#"> Boletim Informativo </a></li>
                      <li><a class="dropdown-item" href="#"> Ofício </a></li>
                      <li><a class="dropdown-item" href="#"> Transferências de Férias </a></li>
          </ul> -->
      </div>
    </div>

    <div class="modal fade" id="itemModal" tabindex="-1" role="dialog" aria-labelledby="itemModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="itemModalLabel">
              Detalhes do Item Selecionado
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <!-- Timelime example  -->
              <div class="row">
                <div class="col-md-12">
                  <!-- The time line -->
                  <div style="
                      max-height: 100vh;
                      overflow-x: hidden;
                      overflow-y: auto;
                    ">
                    <div class="timeline pr-0 no-padding">
                      <template v-if="ferias">
                        <div class="time-label">
                          <span class="bg-red">
                            <span class="bg-red">
                              {{ updateZonedDateTimeField(ferias.data_cadastro) }}
                            </span>
                          </span>
                        </div>
                        <div>
                          <i class="fas fa-envelope bg-blue"></i>
                          <div class="timeline-item">
                            <span class="time"><i class="fas fa-clock"></i> {{ updateTimeField(ferias.data_cadastro)
                              }}</span>
                            <h3 class="timeline-header">
                              <strong> Ferias cadastrada </strong>
                            </h3>
                            <div class="timeline-body">
                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Data Início: </label><span>
                                      {{ updateZonedDateTimeField(ferias.data_inicio) }} </span>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Data Fim: </label><span>
                                      {{ updateZonedDateTimeField(ferias.data_fim) }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Ano: </label>
                                    <span>
                                      {{ ferias.ano }}
                                    </span>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Ano Exercício: </label>
                                    <span>
                                      {{ ferias.ano_exercicio }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <template v-for="feriasSelecionada in feriasSelecionadaHistorico">
                        <div class="time-label ">
                          <span class="bg-red">
                            <span class="bg-red">
                              {{ updateZonedDateTimeField(feriasSelecionada.data_cadastro) }}
                            </span>
                          </span>
                        </div>
                        <div>
                          <i class="fas fa-envelope bg-blue"></i>
                          <div class="timeline-item clicavel"  >
                            <span class="time"><i class="fas fa-clock"></i> {{
                             updateTimeField(feriasSelecionada.data_cadastro) }}</span>
                            <h3 class="timeline-header">
                              <strong> Transferencia Ferias cadastrada </strong>
                            </h3>
                            <div class="timeline-body">
                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Nova data Início: </label><span>
                                      {{ updateZonedDateTimeField(feriasSelecionada.data_inicio_alteracao) }} </span>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Nova data Fim: </label><span>
                                      {{ updateZonedDateTimeField(feriasSelecionada.data_fim_alteracao) }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Portaria: </label>
                                    <span>
                                      {{ feriasSelecionada.portaria_numero }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <!-- /.col -->
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">
              Fechar
            </button>

            
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import rhService from "@/service/RHService";
import dayjs from "dayjs";
import { EventBus } from "@/main";
import { DATE_FORMAT_LOCAL_DATA, DATE_TIME_FORMAT_LOCAL } from "@/util/filter";
import Loading from "vue-loading-overlay";


export default {
  name: "Ferias",
  components: {
    Loading
  },
  data() {
    return {
      listaFerias: [],
      resultados: false,
      feriasSelecionadaHistorico: null,
      ferias: null,
      page: [],
      transferenciaFerias: {},
      currentPage: 1,
      itemsPerPage: 10

    };
  },


  computed: {
    totalPages() {
      return Math.ceil(this.listaFerias.length / this.itemsPerPage);
    },
    pages() {
      return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    },
    paginetedFerias() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.listaFerias.slice(startIndex, endIndex);
    }
    
  },

  created() {
    this.getFeriasFuncionario(
      this.$store.state.funcionario.idFuncionarioCadastrado
    );

    EventBus.$on("AtualizarTabelaFerias", () => {
      this.getFeriasFuncionario(this.$store.state.funcionario.idFuncionarioCadastrado)

    });

    EventBus.$on("AtualizarHistoricoTransferencia", () => {
      this.getFeriasFuncionario(this.$store.state.funcionario.idFuncionarioCadastrado)

    });

    EventBus.$on("AtualizaCadastroFerias", () => {
      this.getFeriasFuncionario(this.$store.state.funcionario.idFuncionarioCadastrado)

    });

    EventBus.$on("AtualizaFeriasTransferencia", () => {
      this.getFeriasFuncionario(this.$store.state.funcionario.idFuncionarioCadastrado);

    });



  },

  methods: {

    setPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },


    imprimirTransferencia(item) {

      rhService.buscarTransferenciaporFerias(item.id).then((resp) => {
        this.transferenciaFerias = resp.data;
        console.log("transferencia: " + this.transferenciaFerias)

      });

      let transferencia = this.transferenciaFerias;
      let id = transferencia[0].id;
      console.log("data: " + id)

      let data = transferencia[0].dataCadastro;
      console.log("data: " + data)
      this.showLoader();
      rhService
        .imprimirFerias(id)
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          this.hideLoader();
          window.open(url);
        })
        .catch(() => {
          this.hideLoader();
          console.error("Erro ao gerar PDF:", error);
        });
    }, imprimirTransferencia(item) {
      rhService.buscarTransferenciaporFerias(item.id)
        .then((resp) => {
          this.transferenciaFerias = resp.data;

          this.transferenciaFerias.sort((a, b) => new Date(b.dataCadastro) - new Date(a.dataCadastro));

          const transferencia = this.transferenciaFerias[0];
          console.log("transferencia: " + transferencia);

          if (transferencia) {
            const id = transferencia.id;
            const data = transferencia.dataCadastro;
            console.log("id: ", id);
            console.log("data: ", data);

            this.showLoader();
            rhService.imprimirFerias(id)
              .then((response) => {
                const url = window.URL.createObjectURL(response.data);
                this.hideLoader();
                window.open(url);
              })
              .catch((error) => {
                this.hideLoader();
                console.error("Erro ao gerar PDF:", error);
              });
          } else {
            console.log("Nenhuma transferência encontrada");
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar transferência:", error);
        });
    },

    getFeriasFuncionario(idFuncionario) {
      this.listaFerias = [];
      rhService.getFeriasFuncionario(idFuncionario).then((resp) => {
        this.listaFerias = resp.data;
      });
    },

    enviarFeriasTransferencia(item, event) {
      EventBus.$emit("EnvioFeriasTransferencia", item);
      event.stopPropagation();
    },

    enviarDadosFerias(item, event) {
      EventBus.$emit("EnviodeFerias", item);
      event.stopPropagation();
    },

    editarTransferencia(item){

      rhService.buscarTransferenciaporFerias(item.id)
        .then((resp) => {
          this.transferenciaFerias = resp.data;

          this.transferenciaFerias.sort((a, b) => new Date(b.dataCadastro) - new Date(a.dataCadastro));

          const transferencia = this.transferenciaFerias[0];
          console.log("transferencia: " + transferencia);
          const id = transferencia.id;
          EventBus.$emit("EnvioEditarTransferencia", transferencia);
          console.log("id: " + id);
        })
        .catch((error) => {
          console.error("Erro ao buscar transferência:", error);
        });

    },

    anotacaoFerias(item, event) {
      event.stopPropagation();
      this.$swal
        .fire({
          title: "Deseja anotar férias?",
          icon: "warning",
          //showCancelButton: true,
          confirmButtonColor: "#34c92f",
          confirmButtonText: "Sim",
          //cancelButtonColor: "#ff0000",
          //cancelButtonText: "Não"
        })
        .then((result) => {
          if (result.isConfirmed) {
            let feriasEdicao = { ...item };
            feriasEdicao.anotado = !feriasEdicao.anotado;

            if (feriasEdicao.anotado) {
              rhService
                .editarFerias(feriasEdicao)
                .then((resp) => {
                  this.getFeriasFuncionario(
                    this.$store.state.funcionario.idFuncionarioCadastrado
                  );
                  Toast.fire("Sucesso anotar férias", "", "success");

                  console.log("Enviando anotação para feriasId:", item.id);
                  rhService
                    .enviarAnotacaoFerias(
                      item.id,
                      this.$store.state.funcionario.idFuncionarioCadastrado
                    )
                    .then((resp) => {
                      this.item = resp.data;
                      console.log(this.item);
                      EventBus.$emit("AtualizarAnotacaoAposAnotacao")
                    });
                })
                .catch(() => {
                  Toast.fire("Falha ao anotar férias", "", "error");
                });
            }
          }
        });
    },

    suspenderFerias(item) {
      if (item.data_inicio_alteracao) {
        rhService
          .suspenderFeriasTransferencia(item.id)
          .then((resp) => {
            item.suspensao = true;

            const index = this.listaFerias.findIndex(
              (ferias) => ferias.id === item.id
            );
            this.$set(this.listaFerias, index, item);

            Toast.fire(
              "Sucesso ao suspender férias transferencia",
              "",
              "success"
            );
          })
          .catch(() => {
            Toast.fire("Falha ao suspender férias transferencia", "", "error");
          });
      } else {
        rhService
          .suspenderFerias(item.id)
          .then((resp) => {
            item.suspensao = true;

            const index = this.listaFerias.findIndex(
              (ferias) => ferias.id === item.id
            );
            this.$set(this.listaFerias, index, item);

            Toast.fire("Sucesso ao suspender férias", "", "success");
          })
          .catch(() => {
            Toast.fire("Falha ao suspender férias", "", "error");
          });
      }
    },

    updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
    },
    updateTimeField(field) {
      return dayjs(field).format(DATE_TIME_FORMAT_LOCAL);
    },
    openModal(item) {
      this.feriasSelecionadaHistorico = JSON.parse(item.transferencia);
      this.ferias = item;
      //   this.feriasSelecionadaHistorico.sort((a, b) => b.data_cadastro - a.data_cadastro);
      $("#itemModal").modal("show");
    },
    closeModal() {
      this.feriasSelecionadaHistorico = null;
      this.ferias = null;
      $("#itemModal").modal("hide");
    },
  },
};
</script>

<style scoped>
.fa-circle-check {
  color: #34c92f;
}

.fa-circle-xmark {
  color: #ff0000;
}

.clicavel {
  cursor: pointer;
}

.botaoClicado {
  display: none;
}

.suspenderFerias {
  display: none;
}

.card-body {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.table-responsive {
  overflow-y: hidden !important;
}

.table-container {
  overflow-x: auto;
  /* Adicione uma barra de rolagem horizontal quando necessário */
  max-width: 100%;
  /* A tabela se ajustará ao tamanho do contêiner pai */
}
</style>
