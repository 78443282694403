<template>
  <div class="row d-flex justify-content-center">
    <div class="mailbox-controls ml-3" v-if="documentoVisualizado.editar">
      <a type="button" class="fas fa-file-signature pr-1 acaoVisualizacao" title="Editar documento" rel="tooltip"
        data-placement="top" @click="editarDocumento(documentoVisualizado.id)" v-if="documentoVisualizado.editar"></a>
      <a class="fas fa-pen-alt pr-1 acaoVisualizacao" title="Assinar documento" rel="tooltip" data-placement="top"
        @click="assinarDocumento(documentoVisualizado.id)" v-if="documentoVisualizado.editar"></a>
    </div>

    <!-- <a class="fas fa-pen-alt pr-1 acaoVisualizacao" title="Assinar documento" rel="tooltip"
        data-placement="top" @click="assinarDocumento(documento.id)" v-if="documento.editar"></a> -->

    <div class="pl-3" :class="{
      'col-md-8': !documentoVisualizado.editar,
      'col-md-12': documentoVisualizado.editar,
    }">
      <!-- <documento/> -->
      <div class="pb-1" style="height: 81vh">
        <Loader :active="loaderActive" message="Abrindo documento..." />
        <!-- <vue-pdf-app :pdf="pdfsrc" theme="dark" v-if="pdfsrc != ''"></vue-pdf-app> -->
        <!-- <Loader :active="loaderActive" message="Abrindo documento..." /> -->
        <iframe :src="pdfsrc" style="width: 100%; height: 100%; border: none" type="application/pdf">
          Oops! an error has occurred.
        </iframe>
        <!-- <iframe
            :src="pdfsrc"
            frameborder="0" align="center" width="100%" height="100%"
            style="border: none;" type="application/pdf">
        </iframe> -->
      </div>
    </div>
    <div class="col-md-4 pr-2" v-if="!documentoVisualizado.editar">
      <!-- <documento/> -->
      <div class="container">
        <!-- Timelime example  -->
        <div class="row">
          <div class="col-md-12">
            <!-- The time line -->
            <div style="max-height: 80vh; overflow-x: hidden; overflow-y: auto">
              <div class="timeline pr-0 no-padding">
                <template v-for="documento in historico">
                  <div class="time-label" v-if="documento.arquivar">
                    <span class="bg-red"><span class="bg-red">{{
                      updateZonedDateTimeField(documento.dataArquivamento)
                    }}</span></span>
                  </div>
                  <div v-if="documento.arquivar">
                    <i class="fas fa-clock bg-gray"></i>
                    <div class="timeline-item">
                      <h3 class="timeline-header">
                        <strong> Documento arquivado </strong>
                      </h3>
                      <div class="timeline-body">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label>Motivo: </label>
                              <span>
                                {{ documento.motivoArquivamento }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="timeline-footer">
                        <div>
                          <a class="fas fa-folder-open pr-1 acaoVisualizacao" title="Desarquivar documento"
                            rel="tooltip" data-placement="top" @click="desarquivarDocumento(documento.id)" v-if="
                              !documento.editar &&
                              documento.arquivar &&
                              documento.fkUsuarioDestino.id ==
                              usuario.idFuncionario
                            "></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="time-label" v-if="documento.editar">
                    <span class="bg-red"><span class="bg-red"> Em rascunho </span></span>
                  </div>
                  <div class="time-label" v-else>
                    <span class="bg-red"><span class="bg-red">{{
                      updateZonedDateTimeField(documento.dataAssinatura)
                    }}</span></span>
                  </div>
                  <div>
                    <i class="fas fa-envelope bg-blue"></i>
                    <div class="timeline-item">
                      <h3 class="timeline-header">
                        <strong>{{ documento.tipoDocumento }} n°
                          {{ documento.numero }}</strong>
                        - {{ documento.assunto }}
                      </h3>
                      <div class="timeline-body">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group" v-if="documento.fkUsuario != null">
                              <label>Remetente: </label><span>
                                {{ documento.fkUsuario.pessoaResumo.nome }} -
                                {{ documento.fkSetorUsuario.descricao }}</span>
                            </div>
                            <div class="form-group" v-else>
                              <label>Criador: </label><span>
                                {{ documento.fkCriador.pessoaResumo.nome }} -
                                {{ documento.fkSetorCriador.descricao }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group" v-if="documento.origemDocumento == 'Interna'">
                              <label>Destinatário: </label><span>
                                {{
                                  documento.fkUsuarioDestino.pessoaResumo.nome
                                }}
                                - {{ documento.fkSetorDestino.descricao }}</span>
                            </div>
                            <div class="form-group" v-else>
                              <label>Destinatário: </label><span>
                                {{ documento.nomeDestino }}
                                - {{ documento.enderecoDestino }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="timeline-footer">
                        <div>
                          <a class="fas fa-eye pr-1 acaoVisualizacao" title="Visualizar documento" rel="tooltip"
                            data-placement="top" @click="getPDFPath(documento.id)"></a>
                          <!-- <a href="#" class="fas fa-clone pr-1"></a> -->
                          <a class="fas fa-file-signature pr-1 acaoVisualizacao" title="Editar documento" rel="tooltip"
                            data-placement="top" @click="editarDocumento(documento.id)" v-if="documento.editar"></a>
                          <a v-if="
                            documento.tipoDocumento !== 'PR' &&
                            (documento.fkUsuario.id ==
                              usuario.idFuncionario ||
                              documento.fkCriador.id == usuario.idFuncionario)
                          " class="fas fa-copy pr-1 acaoVisualizacao" title="Usar modelo" rel="tooltip"
                            data-placement="top" @click="usarModeloDocumento(documento.id)"></a>
                          <a class="fas fa-pen-alt pr-1 acaoVisualizacao" title="Assinar documento" rel="tooltip"
                            data-placement="top" @click="assinarDocumento(documento.id)" v-if="documento.editar"></a>
                          <a v-if="
                            (documento.fkUsuarioDestino != null &&
                              documento.fkUsuarioDestino.id ==
                              usuario.idFuncionario &&
                              !documento.arquivar ||
                              documento.fkSetorCompartilhar != null &&
                              documento.fkSetorCompartilhar.id == usuario.idLotacao &&
                              documento.fkUsuarioCompartilhar.id ==
                              usuario.idFuncionario &&
                              !documento.arquivar)
                          " class="fa-solid fa-right-left pr-1 acaoVisualizacao" title="Tramitar documento"
                            rel="tooltip" data-placement="top" @click="responderDocumento(documento.id)"></a>
                          <a class="fas fa-archive pr-1 acaoVisualizacao" title="Arquivar documento" rel="tooltip"
                            data-placement="top" data-toggle="modal" data-target="#motivoArquivamento"
                            @click="motivoArquivamento(documento.id)" v-if="
                              documento.fkUsuarioDestino != null &&
                              !documento.editar &&
                              !documento.arquivar &&
                              (!documento.bloqueioArquivamento ||
                                documento.bloqueioArquivamento == null) &&
                              documento.fkUsuarioDestino.id ==
                              usuario.idFuncionario
                            "></a>
                          <a v-if="
                            documento.fkUsuarioDestino != null &&
                            documento.fkUsuarioDestino.id ==
                            usuario.idFuncionario &&
                            !documento.arquivar
                          " class="
                              fa fa-share-alt
                              compartilhar
                              acaoVisualizacao
                            " @click="abrirModalPreenchido(documento.id)" title="Compartilhar documento">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <div class="modal fade" id="motivoArquivamento" ref="motivoArquivamento">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Arquivar documento</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12 div_motivo_arquivamento_enable">
                <div class="form-group div_motivo_arquivamento ckeditor">
                  <label for="situcao">Motivo<span class="text-danger"> </span></label>
                  <textarea class="form-control auto_txt_area" required="required" ref="motivoArquivamentoEnable" style="
                      resize: none;
                      margin-top: 0px;
                      margin-bottom: 0px;
                      height: 172px;
                    " :class="{
                      'is-invalid': motivoArquivamentoNulo,
                    }"></textarea>
                  <div v-if="motivoArquivamentoNulo" class="invalid-feedback">
                    Motivo arquivamento é obrigatorió
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-12 div_motivo_arquivamento_disabled">
                  <div class="form-group div_motivo_arquivamento ckeditor">
                    <label for="situcao">Motivo<span class="text-danger">
                    </span></label>
                    <textarea class="form-control auto_txt_area" id="motivoArquivamentoDisabled" th:disabled='disabled' style="resize: none;margin-top: 0px;margin-bottom: 0px;height: 172px;" ></textarea>
                    <div class="help-block with-errors"></div>
                  </div>
                </div> -->
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="salvarMotivoArquivamento()">
              Arquivar Documento
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import documento from "@/components/documentos/DocumentoVisualizado.vue";
import { EventBus } from "@/main.js";
import { DATE_FORMAT_LOCAL } from "@/util/filter";
import dayjs from "dayjs";
import VuePdfApp from "vue-pdf-app";
import documentoService from "../../service/DocumentoService";
import Loader from "../Loader.vue";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import LocalIpService from "@/service/LocalIpService";

export default {
  name: "Documentos",
  components: {
    documento,
    Loader,
    VuePdfApp,
  },
  data: () => ({
    id: "",
    idDocumento: 0,
    motivoArquivamentoNulo: false,
    pdfsrc: null,
    historico: [],
    loaderActive: false,
    documentoArquivar: {},
    documentoVisualizado: {},
    rascunho: false,
    valorDefaultAssinaturaDocumento: 0,
    ipUsuario:null
  }),
  props: {
    text: {
      type: String,
      default: null,
    },
    acao: {
      type: Number,
      default: null,
    },
  },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    },
  },
  created() {
    LocalIpService.getLocalIp()
      .then((response) => {
        this.ipUsuario = response.data.ip;
      })
      .catch((error) => {
        console.log(error);
      });
    this.id = parseInt(this._props.text);
    this.getPDFPath(this.id);
    this.buscarHistorico(this.id);
  

  },
  methods: {
    mostrarAlerta() {
      Toast.fire("Falha ao carregar documento!", "", "error");
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    getPDFPath(id) {
      this.pdfsrc = "";
      documentoService.buscarDocumento(id).then((response) => {
        this.documentoVisualizado = response.data;
        this.rascunho = response.data.editar;
      });

      this.showLoader();
      LocalIpService.getLocalIp()
      .then((response) => {
      
        this.ipUsuario = response.data.ip;
        documentoService
        .visualizarDocumento(id,this.ipUsuario)
        .then((response) => {
          if (response.status == 204) {
            this.mostrarAlerta();
            this.hideLoader();
          } else {
            this.hideLoader();
            const objUrl = URL.createObjectURL(response.data);
            this.pdfsrc = objUrl;
          }
        })
        .catch((e) => {
          this.$router.push("/found401");
        });
      })
      .catch((error) => {
        console.log(error);
      });

    },
    editarDocumento(id) {
      EventBus.$emit("alteraAcaoConteudo", "ED");
      this.showLoader();
      documentoService
        .buscarDocumento(id)
        .then((response) => {
          this.hideLoader();
          EventBus.$emit("editarUsarModeloDocumento", response.data);
        })
        .catch((e) => {
          this.hideLoader();
        });
    },
    usarModeloDocumento(id) {
      EventBus.$emit("alteraAcaoConteudo", "ED");

      documentoService.buscarDocumentoUsarModelo(id).then((response) => {
        EventBus.$emit("editarUsarModeloDocumento", response.data);
      });
    },
    buscarHistorico(id) {
      this.idDocumento = id;

      documentoService.buscarHistoricoDocumento(id).then((response) => {
        this.historico = response.data;
        // console.log(this.historico);
      });
    },
    updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL);
    },
    assinarDocumento(id) {
      documentoService
        .verificarFuncionarioAssinatura(id)
        .then((retorno) => {
          if (!retorno.data) {
            this.$swal
              .fire({
                icon: 'error',
                title: 'Erro',
                text: 'Usuario não autorizado a assinar esse documento'
              })
          } else {
            documentoService
              .verificarNumerecao(this.documentoVisualizado.tipoDocumento)
              .then((response) => {
                if (!response.data) {
                  this.$swal
                    .fire({
                      title: "Assinar documento",
                      html: '<div class="alert alert-danger" role="alert">Ainda não há documento deste tipo gerado na sua unidade. Informe o último número impresso</div>',
                      input: "number",
                      inputPlaceholder: "Digite o último número impresso",
                      focusConfirm: false,
                      showCancelButton: true,
                      cancelButtonColor: "#28a745",
                      confirmButtonText: "Assinar",
                      cancelButtonText: "Cancelar",
                      showLoaderOnConfirm: true,
                      reverseButtons: true,
                      preConfirm: (numero) => {
                        if (!numero) {
                          // return "You need to write something!";
                          // throw new Error("Numero Invalo");
                          this.$swal.showValidationMessage("Número Inválido");
                        } else {
                          this.$swal
                            .fire({
                              title: "Assinar documento",
                              input: "password",
                              inputPlaceholder: "Digite sua senha",
                              inputAttributes: {
                                autocapitalize: "off",
                              },
                              showCancelButton: true,
                              confirmButtonText: "Assinar",
                              confirmButtonColor: "#0f192c",
                              cancelButtonText: "Cancelar",
                              showLoaderOnConfirm: true,
                              preConfirm: (senha) => {
                                return documentoService
                                  .assinarDocumento(numero, id, senha)
                                  .then((response) => {
                                    if (!response.data) {
                                      throw new Error("Senha Inválida");
                                    }
                                  })
                                  .catch((error) => {
                                    this.$swal.showValidationMessage(error);
                                  });
                              },
                              allowOutsideClick: () => !Swal.isLoading(),
                            })
                            .then((result) => {
                              if (result.isConfirmed) {
                                this.$swal
                                  .fire({
                                    title: `Documento assinado com sucesso`,
                                    icon: "success",
                                  })
                                  .then(function () {
                                    EventBus.$emit("alteraAcaoConteudo", "EV");
                                    EventBus.$emit("abaAtiva", "EV");
                                  });
                              }
                            });
                          this.numeracao = numero;
                        }
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                    })
                    .then((resultado) => {
                      if (resultado.isConfirmed) {
                      }
                    });
                } else {
                  this.$swal
                    .fire({
                      title: "Assinar documento",
                      input: "password",
                      inputPlaceholder: "Digite sua senha",
                      inputAttributes: {
                        autocapitalize: "off",
                      },
                      showCancelButton: true,
                      confirmButtonText: "Assinar",
                      cancelButtonText: "Cancelar",
                      showLoaderOnConfirm: true,
                      preConfirm: (senha) => {
                        return documentoService
                          .assinarDocumento(
                            this.valorDefaultAssinaturaDocumento,
                            id,
                            senha
                          )
                          .then((response) => {
                            EventBus.$emit("contabilizarRascunho", null);
                            if (!response.data) {
                              throw new Error("Senha Inválida");
                            }
                          })
                          .catch((error) => {
                            this.$swal.showValidationMessage(error);
                          });
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        this.$swal
                          .fire({
                            title: `Documento assinado com sucesso`,
                            icon: "success",
                          })
                          .then(function () {
                            EventBus.$emit("contabilizarRascunho", null);
                            EventBus.$emit("alteraAcaoConteudo", "EV");
                            EventBus.$emit("abaAtiva", "Ev");
                          });
                      }
                    });
                }
              });
          }
        });
    },
    responderDocumento(id) {
      EventBus.$emit("alteraAcaoConteudo", "ND");

      documentoService.buscarDocumento(id).then((response) => {
        EventBus.$emit("responderDocumento", response.data);
      });
    },
    motivoArquivamento(id) {
      this.documentoArquivar.id = id;
    },
    salvarMotivoArquivamento() {
      if (this.$refs.motivoArquivamentoEnable.value.trim() != "") {
        this.documentoArquivar.motivo =
          this.$refs.motivoArquivamentoEnable.value;

        documentoService
          .arquivarDocumento(this.documentoArquivar)
          .then((response) => {
            if (response.status == 200) {
              $("#motivoArquivamento").modal("hide");
              this.buscarHistorico(this.idDocumento);
              this.$refs.motivoArquivamentoEnable.value = null;
              this.motivoArquivamentoNulo = false;

              const message = "Documento arquivado com sucesso!!! ";
              Toast.fire(message.toString(), "", "success");

              // console.log("sjdhfojshf");
              EventBus.$emit("contabilizarRascunho", null);
            }
          });
      } else {
        this.motivoArquivamentoNulo = true;
      }
    },
    desarquivarDocumento(id) {
      this.$swal
        .fire({
          title: "Desarquivar documento",
          input: "password",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Desarquivar",
          cancelButtonText: "Cancelar",
          showLoaderOnConfirm: true,
          preConfirm: (senha) => {
            return documentoService
              .desarquivar(id, senha)
              .then((response) => {
                if (!response.data) {
                  throw new Error("Senha Inválida");
                }
              })
              .catch((error) => {
                this.$swal.showValidationMessage(error);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: `Documento desarquivado com sucesso`,
              icon: "success",
            });
            EventBus.$emit("contabilizarRascunho", null);
          }
          this.buscarHistorico(id);
        });
    },
    abrirModalPreenchido(idDocumento) {
      // console.log(idDocumento);
      EventBus.$emit("abrirModalCompartilhar", idDocumento);
    },
  },
};
</script>
<style scoped>
.acaoVisualizacao {
  cursor: pointer;
}
</style>
