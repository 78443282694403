<template>
  <div class="card card-primary">
    <div class="background-principal card-header d-flex text-color-principal">
      <h3 class="card-title mr-auto">Resultados da busca</h3>
      <!-- <span class="text-color-principal" v-if="total">Total de resultados: {{ total }}</span> -->
      <div v-if="resultados.length > 0">
        <a href="#" v-on:click.prevent="page > 1 ? page-- : 1"><i
            class="fas fa-angle-left mx-2 text-color-principal"></i></a>

        Página {{ page }} / {{totalPaginas}}

        <a href="#" v-on:click.prevent="page++"><i class="fas fa-angle-right mx-2 text-color-principal"></i></a>
      </div>
    </div>
    <Loader :active="loaderActive" message="Consultando..." />
    <div class="">
      <div v-if="resultados.length == 0 && semResultados" class="mx-auto my-auto sem-resultados py-2">
        <div>Sem resultados!</div>
      </div>
      <!-- <div class="row"> -->
        <div class="row max-height-content">


        <div class="col-lg-6 col-sm-12 col-md-12 cursor-pointer" v-for="(bo, index) in resultados" :key="index"
          @click.prevent="showModalDetalhe" @click="detalharBo(bo)">
          <div class="">
            <div class="p-0 bg-light card-item">
              <div class="text-center">
                <p class="
                  header
                  background-principal
                  text-color-principal
                  card-item-result
                ">
                  {{ bo.numeroBo }}/{{ bo.ano.split("/")[2] }}
                </p>
              </div>

              <div class="row p-4">
                <div class="col-5 d-flex flex-column">
                  <span><b>Data do fato: </b> {{ bo.dataFato }}</span>

                  <span>
                    <b>Horário do fato: </b>
                    {{ bo.horaFato }}
                  </span>
                </div>
                <div class="col-7 d-flex flex-column">
                  <span> <b>Data registro: </b>{{ bo.dataRegistro }} </span>

                  <span>
                    <b>Bairro: </b>
                    {{ bo.bairro }}
                  </span>
                </div>

                <hr />
                <div class="col-12">
                  <span>
                    <b>Unidade: </b>
                    {{ bo.unidade }}
                  </span>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../main.js";
import ConsultaService from "../../../service/ConsultaService";
import Loader from "../../Loader.vue";

export default {
  name: "ResultadosConsulta",
  components: {
    Loader,
  },

  watch: {
    page(valorAtual, valorAnterior) {
      this.consultarBoSigma();
    },
  },
  data() {
    return {
      semResultados: false,
      tituloBusca: "Buscar pessoa",
      resultados: [],
      loaderActive: false,
      page: 1,
      total: "",
      params: {},
    };
  },

  created() {
    EventBus.$on("consultapessoaOcorrencia", this.listener);
  },

  computed:{
    totalPaginas(){

      const totalPagina = this.total/10;
      return (totalPagina !==Infinity) ? Math.ceil(totalPagina) : 0; 

      
    },
  },
  methods: {
    showModalDetalhe() {
      this.$emit("showModalBo");
    },
    detalharBo(bo) {
      EventBus.$emit("consultaDetalheBo", bo);
    },
    listener(event) {
      this.params = event;
      this.page = event.page;

      this.consultarBoSigma();
    },
    onChangePage(resultados) {
      this.resultados = resultados;
    },
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },

    consultarBoSigma() {
      this.showLoader();
      this.pesquisou = false;
      this.params.page = this.page
      try {
       
          ConsultaService.buscarOcorrencia(this.params)
          .then((response) => {
            if (response.data.conteudo.length == 0) {
              this.semResultados = true;
            }
            this.resultados = response.data.conteudo;
            this.total = response.data.total;
            //console.log(this.total);



            this.hideLoader();
          })
          .catch((e) => {
            this.hideLoader();
            this.semResultados = true;
          });

      } catch (e) {
        alert("ERRO: " + e.message);
        this.semResultados = true;
      } finally {
        this.mostrarLoader = false;
        this.semResultados = false;
      }
    },
  },
};
</script>
