<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-cnpjUg">Cnpj Ug</label>
          <input type="text" class="form-control form-control-border" name="cnpjUg" id="contrato-cnpjUg"
            data-cy="cnpjUg" :class="{
              'is-invalid': isSaving && $v.contrato.cnpjUg.$error,
            }" v-model="contrato.cnpjUg" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-numeroProcesso">Número Processo</label>
          <input type="text" class="form-control form-control-border" name="numeroProcesso" id="contrato-numeroProcesso"
            data-cy="numeroProcesso" :class="{
              'is-invalid': isSaving && $v.contrato.numeroProcesso.$error,
            }" v-model="contrato.numeroProcesso" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-anoProcesso">Ano Processo</label>
          <input type="number" class="form-control form-control-border" name="anoProcesso" id="contrato-anoProcesso"
            data-cy="anoProcesso" :class="{
              'is-invalid': isSaving && $v.contrato.anoProcesso.$error,
            }" v-model="contrato.anoProcesso" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-numeroContrato">Número Contrato</label>
          <input type="number" class="form-control form-control-border" name="numeroContrato"
            id="contrato-numeroContrato" data-cy="numeroContrato" :class="{
              'is-invalid': isSaving && $v.contrato.numeroContrato.$error,
            }" v-model.number="contrato.numeroContrato" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-anoContrato">Ano Contrato</label>
          <input type="number" class="form-control form-control-border" name="anoContrato" id="contrato-anoContrato"
            data-cy="anoContrato" :class="{
              'is-invalid': isSaving && $v.contrato.anoContrato.$error,
            }" v-model.number="contrato.anoContrato" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-idContratacao">Id Contratacao</label>
          <input type="text" class="form-control form-control-border" name="idContratacao" id="contrato-idContratacao"
            data-cy="idContratacao" :class="{
              'is-invalid': isSaving && $v.contrato.idContratacao.$error,
            }" v-model="contrato.idContratacao" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-idContrato">Id Contrato</label>
          <input type="text" class="form-control form-control-border" name="idContrato" id="contrato-idContrato"
            data-cy="idContrato" :class="{
              'is-invalid': isSaving && $v.contrato.idContrato.$error,
            }" v-model="contrato.idContrato" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-cpfCnpj">Cpf Cnpj</label>
          <input type="text" class="form-control form-control-border" name="cpfCnpj" id="contrato-cpfCnpj"
            data-cy="cpfCnpj" :class="{
              'is-invalid': isSaving && $v.contrato.cpfCnpj.$error,
            }" v-model="contrato.cpfCnpj" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-objeto">Objeto</label>
          <input type="text" class="form-control form-control-border" name="objeto" id="contrato-objeto"
            data-cy="objeto" :class="{
              'is-invalid': isSaving && $v.contrato.objeto.$error,
            }" v-model="contrato.objeto" />
        </div>
      </div>

    </div>
    <div class="row">
      <!--div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-dataAssinatura">Data Assinatura</label>
          <div class="d-flex">
            <input id="contrato-dataAssinatura" data-cy="dataAssinatura" type="datetime-local"
              class="form-control form-control-border" name="dataAssinatura" :class="{
                'is-invalid': isSaving && $v.contrato.dataAssinatura.$error,
              }" :value="convertDateTimeFromServer(contrato.dataAssinatura)"
              @change="updateZonedDateTimeField('dataAssinatura', $event)" />
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-dataInicio">Data Inicio</label>
          <div class="d-flex">
            <input id="contrato-dataInicio" data-cy="dataInicio" type="datetime-local"
              class="form-control form-control-border" name="dataInicio" :class="{
                'is-invalid': isSaving && $v.contrato.dataInicio.$error,
              }" :value="convertDateTimeFromServer(contrato.dataInicio)"
              @change="updateZonedDateTimeField('dataInicio', $event)" />
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-dataFim">Data Fim</label>
          <div class="d-flex">
            <input id="contrato-dataFim" data-cy="dataFim" type="datetime-local"
              class="form-control form-control-border" name="dataFim" :class="{
                'is-invalid': isSaving && $v.contrato.dataFim.$error,
              }" :value="convertDateTimeFromServer(contrato.dataFim)"
              @change="updateZonedDateTimeField('dataFim', $event)" />
          </div>
        </div>
      </div-->

      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="contrato-dataAssinatura"
              >Data Assinatura</label
              >
            <div class="d-flex">
              <input
                type="date" class="form-control form-control-border border-width-2" id="contrato-dataAssinatura"
                  placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(contrato.dataAssinatura)" :class="{
                    'is-invalid': isSaving && $v.contrato.dataAssinatura.$error,
                      }"
                  @change="updateZonedDateTimeField('dataAssinatura', $event)"
              />
            </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="contrato-dataInicio"
              >Data Inicio</label
              >
            <div class="d-flex">
              <input
                type="date" class="form-control form-control-border border-width-2" id="contrato-dataInicio"
                  placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(contrato.dataInicio)" :class="{
                    'is-invalid': isSaving && $v.contrato.dataInicio.$error,
                      }"
                  @change="updateZonedDateTimeField('dataInicio', $event)"
              />
            </div>
        </div>
      </div>

    <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-dataFim"
            >Data Fim</label
                    >
        <div class="d-flex">
            <input
              type="date" class="form-control form-control-border border-width-2" id="contrato-dataFim"
              placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(contrato.dataFim)" :class="{
              'is-invalid': isSaving && $v.contrato.dataFim.$error,
                    }"
              @change="updateZonedDateTimeField('dataFim', $event)"
            />
        </div>
      </div>
    </div>

    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-valor">Valor</label>
          <input type="number" class="form-control form-control-border" name="valor" id="contrato-valor" data-cy="valor"
            :class="{
              'is-invalid': isSaving && $v.contrato.valor.$error,
            }" v-model.number="contrato.valor" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="contrato-tipo">Tipo</label>
          <v-select class="border-v-select" id="contrato-tipo" :class="{
            //'is-invalid': isSaving && $v.contrato.tipo.$error,
          }" data-cy="tipo" name="tipo" v-model="contrato.tipo" :options="tipo" :reduce="tipo => tipo.id"
            label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-right">
        <!--button class="btn btn-default mr-1">
          <i class="fas fa-pencil-alt"></i> Rascunho
        </button-->
        <button @click="salvarContrato" class="btn text-color-principal background-principal ml-1">
          <i class="far fa-envelope"></i> Enviar
        </button>
        <button @click="limparContrato" type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Limpar
      </button>
      </div>
      <!--button type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Cancelar
      </button-->
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import { DATE_TIME_LONG_FORMAT, DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import dayjs from "dayjs";
import { EventBus } from "@/main";
import processosService from '@/service/ProcessosService';
import TabelaContrato from "@/components/processos/TabelaAtaRegistro.vue"

export default {
  name: "Ata-Registro",
  components:{TabelaContrato},
  data() {
    return {
      data:{},
      contrato: {},
      submitted: false,
      isSaving: false,
      setores: [],
      funcionarios: [],
      tipo: [
        { descricao: "Alteração Prazo e Valor" },
        { descricao: "Alteração Prazo" },
        { descricao: "Alteração Qualitativa" },
        { descricao: "Alteração Quantitativa" },
        { descricao: "Alteração Valor - Reajuste" },
        { descricao: "Alteração Valor - Repactuação" },
        { descricao: "Alteração Valor - Revisão" },
        { descricao: "Apostilamento" },
        { descricao: "Nota de Empenho" },
        { descricao: "Rescisão Contratual" },
        { descricao: "Subcontrato" },
        { descricao: "Contrato Administrativo" },
      ],
    };
  },

  

  validations: {
    contrato: {
      cnpjUg: {},
      numeroProcesso: {},
      anoProcesso: {},
      numeroContrato: {},
      anoContrato: {},
      idContratacao: {},
      idContrato: {},
      cpfCnpj: {},
      objeto: {},
      dataAssinatura: {},
      dataInicio: {},
      dataFim: {},
      valor: {},

      //valor: { required },
    },
  },
  created() {
      EventBus.$on("EnvioContrato",(listener)=>{
        this.getContrato(listener);
        
      }),

      EventBus.$on("ApagarCamposContrato",()=>{
        this.contrato={};
      
    })
  },
  methods: {
    // updateZonedDateTimeField(field, event) {
    //   if (event.target.value) {
    //     this.documento[field] = dayjs(
    //       event.target.value,
    //       DATE_TIME_LONG_FORMAT
    //     );
    //   } else {
    //     this.documento[field] = null;
    //   }
    // },
    mudarForm() {
      console.log("sjdfnosdf");
    },

     salvarContrato(){
    console.log("SALVAR CONTRATO")
    this.isSaving = true;
    this.$v.$touch();
 
      if (this.$refs["contrato"]!= undefined){
        this.cadastroLiccontratoitante = Object.assign(
          {},
          this.contrato,
          this.$refs["contrato"].contrato
        );
 
        this.$refs["contrato"].isSaving = this.isSaving;
        if (this.$v.$ivalid || this.$refs["contrato"].$v.$ivalid){
          return;
        } else{
          if (this.$v.$ivalid){
            return;
 
 
          }
        }
 
      }
     
      processosService.criarContrato(this.contrato)
      .then((response)=> {
        this.contrato= response.data;
        Toast.fire("Cadastrado realizado", "", "success");
        this.contrato = {};
        document.getElementById("contrato-dataInicio").value="";
        document.getElementById("contrato-dataFim").value="";
        document.getElementById("contrato-dataAssinatura").value="";
 
      })

  },

  limparContrato(){
    this.contrato={};
    document.getElementById("contrato-dataInicio").value="";
    document.getElementById("contrato-dataFim").value="";
    document.getElementById("contrato-dataAssinatura").value="";
    
  },

    getContrato(id){
      processosService.getContrato(id).then((resp)=>{
        this.contrato= resp.data;
      })
    },

    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.contrato[field] = dayjs(
          event.target.value,
          DATE_FORMAT_LOCAL_DATA
        );
      } else {
        this.contrato[field] = null;
      }
    },
  },
  // mounted() {
  //   this.carregaSetores();
  //   this.carregaFuncionario();
  // }
};
</script>
