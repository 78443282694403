<template>
  <section class="content">
    <conteudo>
      <content-header title="Meu Perfil" />

      <!-- <div class="d-flex justify-content-center p-3"> -->
      <div
        class="container-fluid p-3"
        v-on="isModalVisible ? { click: closeModal } : {}"
      >
        <ModalResetSenhaComponent
          v-if="isModalVisible"
          @closeModalMudancaSenha="closeModal"
        />
        <div class="modal-backdrop fade show" v-show="isModalVisible"></div>

        <div class="card card-primary p-0 col-12">
          <div class="background-principal img-fluid card-header d-flex justify-content-center text-color-principal">
            <h5>{{ usuario.nome }}</h5>
          </div>
          <div class="card-body max-height-content-meu-perfil">
            <div class="row">
              <div class="col-sm-1 ml-3 mr-3">
                <img
                v-bind:src="usuario.foto ? 'data:image/jpeg;base64,' + usuario.foto : 'https://webc.ssp.ma.gov.br/imagens/elementos/icone-imagem-indisponivel2.png'"
                class="rounded elevation-2  m-2 img-cut" alt="User Image" />
              </div>
              <div class="col-sm-3 pt-3">
                <p class="medium mb-0"> <b>CPF: </b> {{ usuario.login }}</p>
                <p class="medium mb-0"><b> Matrícula: </b> {{ usuario.matricula }} </p>
                <p class="medium mb-0"> <b>E-mail: </b>{{ usuario.email }}</p>
                <!-- <p class="medium mb-0"> <b>Lotação: </b> {{ usuario.lotacao }}</p>
                <p class="medium mb-0"> <b>Cargo: </b> {{ usuario.cargo }}</p> -->
              </div>
              <div class="col-sm-4 pt-3">
                <p class="medium mb-0"> <b>Lotação: </b> {{ usuario.lotacao }}</p>
                <p class="medium mb-0"> <b>Cargo: </b> {{ usuario.cargo }}</p>
              </div>
            </div>
            <hr />
            <CompartilharDoc />
          </div>
          <div class="card-footer">
            <button @click.prevent="showModal" class="btn col-md-3 col-sm-6 background-principal text-color-principal">
              <i class="fa-solid fa-lock"></i>
              Mudar Senha
            </button>
          </div>

        </div>
      </div>

    </conteudo>
  </section>
</template>

<script>
import CompartilharDoc from "@/components/meuperfil/CompartilharDoc.vue";
import instance from "@/service/axiosConfig";
import ModalResetSenhaComponent from "../components/ModalResetSenhaComponent.vue";
export default {
  name: "MeuPerfilView",
  components: { ModalResetSenhaComponent, CompartilharDoc },
  data() {
    return {
      isModalVisible: false,
    };
  },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    },
  },
  created() {
    instance.get("teste/token/validoUp");
  },
  methods: {
    showCompartilharDoc() {
      this.isCompartilharDocVisible = true;
    },
    closeCompartilharDoc() {
      this.isCompartilharDocVisible = false;
    },

    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped>
.img-cut {
  max-width: 120px;
  max-height: 160px;

  height: 16vh;
  width: 12vh;
  object-fit: cover;
  object-position: center top;
}

.max-height-content-meu-perfil {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
