<template>
  <div v-if="mostrarDetalhe" class="
      row
      mx-0
      d-flex
      justify-content-center
      table-responsive
      max-height-content-detail
    ">
    <loader :active="loaderActive" message="Consultando..." />

    <button @click.prevent="esconderDetalhe" type="button" class="btn background-principal text-color-principal">
      <i class="fas fa-arrow-left"></i>
      Voltar
    </button>
    <div class="m-3 col-sm-12 col-md-12 col-lg-12">
      <div class="bg-light">
        <div class="text-center">
          <p class="
              header
              background-principal
              text-color-principal
              card-item-result
            ">
            {{ bo.numeroBo }}/{{ bo.ano | exibirAno }}
          </p>
        </div>

        <!-- corpo card -->
        <div class="row p-5">
          <div class="col-6 p-1 d-flex flex-column">
            <span><b>Data do fato: </b> {{ bo.dataFato }}</span>

            <span>
              <b>Horário: </b>
              {{ bo.horaFato }}
            </span>
            <span> <b>Data registro: </b>{{ bo.dataRegistro }} </span>

            <span>
              <b>Unidade: </b>
              {{ bo.unidade }}
            </span>
            <span>
              <b>CEP: </b>
              {{ bo.cep }}
            </span>
          </div>
          <div class="col-6 p-1 d-flex flex-column">
            <span>
              <b>Bairro: </b>
              {{ bo.bairro }}
            </span>

            <span> <b>Cidade: </b> {{ bo.cidade }} </span>

            <span> <b>País: </b> {{ bo.pais }} </span>

            <span><b>Referência: </b> {{ bo.referencia }} </span>

            <span><b>Local: </b> {{ bo.tipoLocal }} </span>
          </div>
          <br />
          <hr />
          <br />
          <div>
            <b>Relato: </b>
            <p class="p-1 text-justify">{{ bo.relato }}</p>
          </div>
          <div>
            <b>Fatos:</b>
            <div :key="index" v-for="(fato, index) in bo.fatos" class="p-1">
              <div>
                <b>{{ fato.codigo }} {{ fato.descricao }} </b>
                {{ fato.tentado ? "TENTADO" : "CONSUMADO" }}
              </div>
              <ul>
                <span :key="index" v-for="(envolvido, index) in bo.envolvidosBo">
                  <div v-if="fato.natureza == envolvido.idNatureza">
                    <li>{{ envolvido.nome }} {{ envolvido.envolvimento }}</li>
                  </div>
                </span>
              </ul>
            </div>
          </div>

          <div class="col-12">
            <b>Objetos:</b><br /><br />
            <div v-if="testarPessoaEnvolvimentoBO()">
              <div class="row bo-component-objetos">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body table-responsive p-0">
                      <table class="table table-hover text-nowrap">
                        <thead>
                          <tr>
                            <th>Tipo</th>
                            <th>Proprietário</th>
                            <th>Envolvimento</th>
                            <th>Providência</th>
                            <th>Descrição</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr :key="index" v-for="(
                              objeto, index
                            ) in bo.envolvimentosPessoaObjeto">
                            <td>{{ objeto.tipoObjeto }}</td>
                            <td>{{ objeto.proprietario }}</td>
                            <td>{{ objeto.envolvimentoObjeto }}</td>
                            <td>{{ objeto.providenciaObjeto }}</td>
                            <td>
                              {{ objeto.descricaoObjeto }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p>Não há objetos!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConsultaService from "@/service/ConsultaService.js";
import { EventBus } from "../../main.js";
import Loader from "../Loader.vue";
import LocalIpService from "@/service/LocalIpService";
export default {
  name: "BoCardComponent",
  components: {
    Loader,
  },
  computed: {
    ano() {
      return "";
    },
  },

  data() {
    return {
      bo: {},
      mostrarDetalhe: false,
      loaderActive: true,
      ipUsuario: null
    };
  },
  created() {
    LocalIpService.getLocalIp()
      .then((response) => {
        this.ipUsuario = response.data.ip;
      })
      .catch((error) => {
        console.log(error);
      });

    this.loaderActive = true;
    EventBus.$on("buscarBoEscolhido", this.buscarBoPorId);
    EventBus.$on("esconderBoCardComponent", this.esconderDetalhe);
  },
  filters: {
    exibirAno: function (dataCompleta) {
      if (dataCompleta) {
        const apenasAno = dataCompleta.substring(6, 10);
        return apenasAno;
      }
    },
  },
  methods: {
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    esconderDetalhe() {
      this.bo = {};
      this.mostrarDetalhe = false;
      EventBus.$emit("esconderDetalheBo", true);
    },
    buscarBoPorId(id) {
      this.showLoader()
      ConsultaService.buscarBoDetalhe(id,this.ipUsuario).then((r) => {
        this.hideLoader()
        this.mostrarDetalhe = true;
        this.bo = r.data.conteudo[0];
        this.loaderActive = false;
      }).catch((e) => {
        this.hideLoader();
      })

    },
    testarPessoaEnvolvimentoBO() {
      if (this.bo.envolvimentosPessoaObjeto) {
        if (this.bo.envolvimentosPessoaObjeto.length != 0) {
          return true;
        }
        return false;
      }
    },
  },
};
</script>

<style scoped>
.bo-component-objetos {
  overflow-x: auto;
  max-width: 68vw;
}
</style>
