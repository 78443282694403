<template>
  <div>
    <input v-show="false" ref="file" type="file" @change="onFileChange" />
    <div id="preview">
      <div v-if="tirarFoto">
        <Camera></Camera>
      </div>
      <div v-else>
        <img
          class="img mx-0 px-0 img-card-measure"
          :src="
            url
              ? url
              : 'https://webc.ssp.ma.gov.br/imagens/elementos/icone-imagem-indisponivel2.png'
          "
        />
        <div>
          <div
            @click="$refs.file.click()"
            class="btn btn-primary btn-file file-button"
          >
            <i class="fa fa-upload" aria-hidden="true"></i>
          </div>

          <div
            @click="dispositoEncontrado ? (tirarFoto = true) : null"
            :class="validaCamera"
          >
            <i class="fa fa-camera" aria-hidden="true"></i>
          </div>

          <div
            @click="limparDadosCamera"
            class="btn btn-danger btn-file file-button ml-1"
          >
            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Camera from "@/components/camera/CameraPhotoComponent.vue";
import { EventBus } from "@/main";
export default {
  name: "CameraComponent",
  data() {
    return {
      id: null,
      url: "",
      tirarFoto: false,
      dispositoEncontrado: false,
      foto: {},
    };
  },
  created() {
    EventBus.$on("preencherURLPreviewImagem", valor => this.url = valor)
    EventBus.$on("preencherCampoFoto", this.preencherFotoBackend);
    EventBus.$on("fotoTirada", this.preencherFotoTirada);
    EventBus.$on("limparDadosCamera", this.limparDadosCamera);

    if (navigator.mediaDevices){
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((this.dispositoEncontrado = true))
        .catch((msg) => {
          // alert('Webcam não detectada. Conecte-a e atualize a página.')
          this.dispositoEncontrado = false;
        });
    }
      
  },
  computed: {
    validaCamera() {
      let classeBase = "btn btn-dark btn-file file-button ml-1";
      let classeFinal = this.dispositoEncontrado
        ? classeBase
        : classeBase + " disabled";
      return classeFinal;
    },
  },
  components: { Camera },
  methods: {
    limparDadosCamera() {
      this.url = null;
      this.$refs.file ? (this.$refs.file.value = null) : null;
      this.foto = {};
    },
    preencherFotoBackend(foto) {
      if (foto) {
        let prefixo = foto.imagem.includes('base64') ? '' : 'data:image/jpeg;charset=utf-8;base64,'
        this.url = `${prefixo}${foto.imagem}`;
        this.id = foto.id
      }
    },
    preencherFotoTirada(foto) {
      this.tirarFoto = false;
      this.url = foto;
      EventBus.$emit("fotoCameraTirada", this.url);
    },

    onFileChange(e) {
      this.url = null;
      this.foto = {};
      const file = e.target.files[0];
      const reader = new FileReader();
      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.url = rawImg;
        EventBus.$emit("fotoUploadCarregada", this.url);
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}

#preview > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#preview img {
  width: 100%;
  max-width: 150px;
  max-height: 250px;
  margin-bottom: 15px;
}

.card-header {
  background-color: #0f192c !important;
}
</style>
