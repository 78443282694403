var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"ano"}},[_vm._v("Ano")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boletim.ano),expression:"boletim.ano"}],staticClass:"form-control form-control-border border-width-2",class:{
          'is-invalid': _vm.isSaving && _vm.$v.boletim.ano.$error,
        },attrs:{"type":"text","id":"ano"},domProps:{"value":(_vm.boletim.ano)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.boletim, "ano", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"mes"}},[_vm._v("Mês")]),_c('v-select',{staticClass:"border-v-select mt-1",attrs:{"id":"mes","options":_vm.meses},model:{value:(_vm.boletim.mes),callback:function ($$v) {_vm.$set(_vm.boletim, "mes", $$v)},expression:"boletim.mes"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"assinatura1"}},[_vm._v("Assinatura 1")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boletim.assinatura1),expression:"boletim.assinatura1"}],staticClass:"form-control form-control-border border-width-2",class:{
          'is-invalid': _vm.isSaving && _vm.$v.boletim.assinatura1.$error,
        },attrs:{"type":"text","id":"assinatura1"},domProps:{"value":(_vm.boletim.assinatura1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.boletim, "assinatura1", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"cargo1"}},[_vm._v("Cargo 1")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boletim.cargo1),expression:"boletim.cargo1"}],staticClass:"form-control form-control-border border-width-2",class:{
          'is-invalid': _vm.isSaving && _vm.$v.boletim.cargo1.$error,
        },attrs:{"type":"text","id":"cargo1"},domProps:{"value":(_vm.boletim.cargo1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.boletim, "cargo1", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"assinatura2"}},[_vm._v("Assinatura 2")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boletim.assinatura2),expression:"boletim.assinatura2"}],staticClass:"form-control form-control-border border-width-2",class:{
          'is-invalid': _vm.isSaving && _vm.$v.boletim.assinatura2.$error,
        },attrs:{"type":"text","id":"assinatura2"},domProps:{"value":(_vm.boletim.assinatura2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.boletim, "assinatura2", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"cargo2"}},[_vm._v("Cargo 2")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boletim.cargo2),expression:"boletim.cargo2"}],staticClass:"form-control form-control-border border-width-2",class:{
          'is-invalid': _vm.isSaving && _vm.$v.boletim.cargo2.$error,
        },attrs:{"type":"text","id":"cargo2"},domProps:{"value":(_vm.boletim.cargo2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.boletim, "cargo2", $event.target.value)}}})])]),_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-default dropdown-toggle",attrs:{"data-toggle":"dropdown"},on:{"click":_vm.ImprimirBoletim}},[_c('i',{staticClass:"fa fa-print"}),_vm._v(" Imprimir ")]),_c('div',{staticClass:"dropdown-menu"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.ImprimirComMissao()}}},[_vm._v("Boletim com missão")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.ImprimirSemMissao()}}},[_vm._v("Boletim sem missão")])]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.LimparBoletim.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-ban"}),_vm._v(" Limpar ")])]),_c('pdf-diaria')],1)}
var staticRenderFns = []

export { render, staticRenderFns }