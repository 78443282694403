<template>
  <div class="row">
    <div class="col-12" v-show="!deveEsconderDocumento">
      <hr />
      <h5 class="mb-3">Cadastrar um Documento</h5>
      <form class="row">
        <div class="form-group col-lg-3 col-sm-12">
          <label for="tipodocumento">Tipo do Documento *</label>
          <v-select class="border-v-select" id="tipodocumento"
            v-model="documento.tipoDocumento" :options="tiposDocumento" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>

        </div>
        <div class="form-group col-lg-3 col-sm-12">
          <label for="nome">Número do documento *</label>
          <input v-if="documento.tipoDocumento && documento.tipoDocumento.descricao != 'CPF'" key="nao-cpf" type="text"
            class="form-control form-control-border border-width-2" :class="{
              'is-invalid':
                isDocSaving &&
                !$v.documento.numero.required &&
                documento.tipoDocumento !== '' &&
                documento.tipoDocumento !== null,
            }" id="numerodocumento" v-model="documento.numero" />

          <input v-else key="cpf" type="text" class="form-control form-control-border border-width-2" :class="{
            'is-invalid':
              isDocSaving &&
              !$v.documento.numero.required &&
              documento.tipoDocumento !== '' &&
              documento.tipoDocumento !== null,
          }" id="numerodocumento" v-model="documento.numero" v-mask="'###.###.###-##'" />

          <div v-if="
            isDocSaving &&
            !$v.documento.numero.required &&
            documento.tipoDocumento !== '' &&
            documento.tipoDocumento !== null
          " class="invalid-feedback">
            Número do documento é obrigatório
          </div>
        </div>

        <div class="form-group col-lg-3">
          <label for="dataFatoInicio">Data de emissão</label>
          <input type="date" class="form-control form-control-border border-width-2" id="dataemissao"
            placeholder="dd/mm/aaaa" v-model="documento.dataEmissao" />
        </div>
        <div class="form-group col-lg-3">
          <label for="dataFatoInicio">Data de validade</label>
          <input type="date" class="form-control form-control-border border-width-2" id="datavalidade"
            placeholder="dd/mm/aaaa" v-model="documento.dataValidade" />
        </div>
      </form>
      <hr />


    </div>
    <div  class="col-12">
      <TableDocumentosPessoa />
    </div>

  </div>
</template>

<script>
import TableDocumentosPessoa from "@/components/visita/TableDocumentosPessoa.vue";
import { EventBus } from "@/main";
import ConsultaListasService from "@/service/ConsultaListasService";

export default {
  name: "FormDocumentoComponent",
  components: { TableDocumentosPessoa, TableDocumentosPessoa },
  watch: {
    "documento.tipoDocumento"() {
      this.documento.numero = ""

    }
  },
  data() {
    return {
      documento: {
        tipoDocumento: "",
        numero: "",
        dataEmissao: "",
        dataValidade: ""

      },

      tipoDocumentos: [],
      isDocSaving: false,
      tiposDocumento: [],
      deveEsconderDocumento: false
    };
  },
  created() {
    this.preencherListasObj({ tiposDocumento: "tipo_documento" });
    EventBus.$on('esconderCamposDocumento', (deveEsconderDocumento) => { this.deveEsconderDocumento = deveEsconderDocumento })
    EventBus.$on('preencherCamposDocumento', valor => { this.documento = valor; })
  },
  methods: {
    preencherListasObj(obj) {
      Object.keys(obj).forEach((lista) => {
        ConsultaListasService.buscarListaPorNomeTabela(obj[lista])
          .then((response) => {
            this[lista] = response.data;
          })
          .catch((e) => console.log("ERRO: " + e.message));
      });
    },
  },
};
</script>

<style>

</style>
