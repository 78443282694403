import instance from "./axiosConfig";

class ConsultaUsuarioService {
  buscarUsuarios(page, client) {
    return instance.get(`/usuarios-service/api/usuarios/resumo-view/${client}?page=${page}`);
  }

  buscarUsuarioCPF(page, cpf, client) {
    return instance.get(
      `/usuarios-service/api/usuarios/resumo-view-cpf/${cpf}/${client}?page=${page}`
    );
  }
  buscarUsuarioNome(page, nome, client) {
    return instance.get(
      `/usuarios-service/api/usuarios/resumo-view-nome/${nome}/${client}?page=${page}`
    );
  }

  buscarUsuarioMatricula(page, matricula, client) {
    return instance.get(
      `/usuarios-service/api/usuarios/resumo-view-matricula/${matricula}/${client}?page=${page}`
    );
  }

  buscarUsuarioNovaMatricula(page, novaMatricula) {
    return instance.get(
      `/usuario-service/api/usuarios/resumo-view-novaMatricula/${novaMatricula}?page=${page}`
    );
  }

  buscarUsuarioParametro(tipo, parametro) {
    return instance.get(`/usuario-service/api/usuarios/resumo?${tipo}=${parametro}`);
  }

  buscarUsuarioId(id) {
    return instance.get(`/usuarios-service/api/usuarios/${id}`);
  }

  buscarPerfisPorUsuarioId(id) {
    return instance.get(`/usuarios-service/api/usuarios/perfisbyusuarioid/${id}`);
  }

  buscarUsuarioPorPessoaCorrespondenteId(pessoaCorrespondenteId) {
    return instance.get(
      `/usuario-service/api/usuarios/pessoaCorrespondenteId?id=${pessoaCorrespondenteId}`
    );
  }

  buscarCpfPorLoginUsuario(login) {
    return instance.get(`/usuarios-service/api/pessoasresumo/retorna-cpf/${login}`);
  }
}
export default new ConsultaUsuarioService();
