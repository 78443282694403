<template>
  <div class="card-body pt-0">
    <loading :active="loaderActive" :is-full-page="fullPage" />
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="prevPage">Anterior</a>
        </li>
        <li class="page-item" v-for="pageNumber in pages" :key="pageNumber"
          :class="{ active: pageNumber === currentPage }">
          <a class="page-link" href="#" @click.prevent="setPage(pageNumber)">{{ pageNumber }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="nextPage">Próxima</a>
        </li>
      </ul>
    </nav>
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th>Servidor</th>
          <th>RD</th>
          <th>Inicio</th>
          <th>Fim</th>
          <th>Ordenador</th>
          <th>Total</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody v-if="diarias">
        <tr class="linhaTabela" v-for="(diaria, index) in paginatedDiarias" :key="index">

          <td class="mailbox-subject">
            {{ diaria.funcionario.pessoaCorrespondente.nome }}
          </td>
          <td class="mailbox-subject">
            {{ diaria.id }}
          </td>

          <td class="mailbox-subject">
            {{ diaria.dataInicio | formatarData }}
          </td>

          <td class="mailbox-subject">
            {{ diaria.dataFim | formatarData }}
          </td>

          <td class="mailbox-subject">
            {{ diaria.ordenadorDespesaId.nome }}
          </td>

          <td class="mailbox-subject">
            {{ diaria.total }}
          </td>
          <td class="mailbox-subject">
            <a type="button" class="fas fa-file-signature pr-1 acaoVisualizacao" title="Visualizar diaria" rel="tooltip"
              data-placement="top" @click="visualizarDiaria(diaria.id)"></a>
            <a type="button" class="fas fa-pen-alt pr-1 acaoVisualizacao" title="Assinar diaria" rel="tooltip" 
              data-placement="top" @click="assinarDiaria(diaria.id)"></a>
          </td>

        </tr>

      </tbody>
      <tbody v-else>
        <div class="mx-auto my-auto sem-resultados py-2">
          <div>Sem resultados para sua busca!</div>
        </div>
      </tbody>
    </table>
    <!-- <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th>Servidor</th>
          <th>RD</th>
          <th>Inicio</th>
          <th>Fim</th>
          <th>Ordenador</th>
          <th>Total</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(diaria, index) in paginatedUsers" :key="index">
          <td class="mailbox-subject">
            {{ diaria.funcionario.pessoaCorrespondente.nome }}
          </td>
          <td class="mailbox-subject">
            {{ diaria.id }}
          </td>

          <td class="mailbox-subject">
            {{ diaria.dataInicio | formatarData }}
          </td>

          <td class="mailbox-subject">
            {{ diaria.dataFim | formatarData }}
          </td>

          <td class="mailbox-subject">
            {{ diaria.ordenadorDespesaId.nome }}
          </td>

          <td class="mailbox-subject">
            {{ diaria.total }}
          </td>
          <td class="mailbox-subject">

          </td>
        </tr>
      </tbody>
    </table> -->

    <!-- <button @click="previousPage" :disabled="currentPage === 0">Anterior</button>
    <button @click="nextPage" :disabled="currentPage === pageCount - 1">Próxima</button> -->
  </div>
</template>
<script>

import { EventBus } from '@/main';
import rhDiariasService from '@/service/RhDiariasService';
import Loading from "vue-loading-overlay";

export default {
  name: "ResultadoDiariasPendentes",
  components: {
      Loading
  },
  data() {
    return {
      loaderActive: false,
      fullPage: false,
      diarias: [],
      // pageSize: 10,
      // currentPage: 0
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  mounted() {
    this.buscarDiariasPendentesAssinatura();

    EventBus.$on("AtualizarDiariasPendentes",() => {
      this.buscarDiariasPendentesAssinatura();
    });
   
  },
  computed: {
    totalPages() {
      return Math.ceil(this.diarias.length / this.itemsPerPage);
    },
    pages() {
      return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    },
    paginatedDiarias() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.diarias.slice(startIndex, endIndex);
    }
    // paginatedUsers() {
    //   const startIndex = this.currentPage * this.pageSize;
    //   const endIndex = startIndex + this.pageSize;
    //   return this.diarias.slice(startIndex, endIndex);
    // },
    // pageCount() {
    //   return Math.ceil(this.diarias.length / this.pageSize);
    // }
  },
  filters: {
    formatarData: function (data) {
      if (data == "" || data == null) {
        return "Sem data registrada";
      } else {
        const [ano, mes, dia] = data.split("-");
        const dataFormatada = `${dia}/${mes}/${ano}`;
        return dataFormatada;
      }
    },
  },
  methods: {
    showLoader() {
      this.loaderActive = true;
      this.fullPage = true;
    },
    hideLoader() {
      this.loaderActive = false;
      this.fullPage = false;
    },
    buscarDiariasPendentesAssinatura() {
      rhDiariasService.getAllDiariaPendentesAssinatura().then((resp) => {
        this.diarias = resp.data
      })
    },
    visualizarDiaria(id) {
      this.showLoader();
      rhDiariasService.imprimirRd(id)
        .then((response) => {

          const url = window.URL.createObjectURL(response.data);
          this.hideLoader();
          window.open(url)


        }).catch(() => {
          this.hideLoader();
          console.error('Erro ao gerar PDF:', error);
        });

    },
    assinarDiaria(id) {
      this.$swal
        .fire({
          title: "Assinar diaria",
          input: "password",
          inputPlaceholder: "Digite sua senha",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Assinar",
          confirmButtonColor: "#0f192c",
          cancelButtonText: "Cancelar",
          showLoaderOnConfirm: true,
          preConfirm: (senha) => {
            return rhDiariasService
              .assinarDiaria(senha, id)
              .then((response) => {
                if (!response.data) {
                  throw new Error("Senha Inválida");
                }
              })
              .catch((error) => {
                this.$swal.showValidationMessage(error);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.diarias = this.diarias.filter(item => item.id !== id);
            this.$swal
              .fire({
                title: `Diaria assinada com sucesso`,
                icon: "success",
              });
          }
        });
    },
    // previousPage() {
    //   this.currentPage--;
    // },
    // nextPage() {
    //   this.currentPage++;
    // }
    setPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  }
}
</script>
<style scoped>
.acaoVisualizacao {
  cursor: pointer;
}