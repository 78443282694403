<template>
<div class="card card-outline">

    <div class="card-header p-0 pt-1 background-principal text-color-principal pl-2 mb-0" data-toggle="collapse"
        href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
        <h6 class="box-title pt-2 pb-2 mb-1">
        <i class="nav-icon fas fa-search text-color-principal"></i> Buscar Processos
        </h6>
    </div>
    <div class="collapse" id="collapseExample">
        <div class="card-body" v-if="loading">
            <loader :active="loading" message="Consultando..." />
        </div>
        <div v-else class="card-body">
            <div class="submit-form">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-control-label" for="extrato">Selecione o processo</label>
                            <!-- <input key="extrato" type="text" class="form-control form-control-border border-width-2" id="extratoProcessos"
                            v-model="busca.extrato" v-mask="'########'" placeholder="processo" /> -->
            
                            <select  class="custom-select form-control-border border-width-2"
                                id="exampleSelectBorderWidth2" >
                                <option value="">Selecione</option>
                                <option value="AtaRegistro">Ata Registro</option>
                                <option value="CadastroLicitante">Cadastro Licitante</option>
                                <option value="CadastroOrgao">Cadastro Orgao</option>
                                <option value="Contrato">Contrato</option>
                                <option value="Procedimento">Procedimento</option>
                                <option value="Resultado">Resultado</option>
                                <option value="Sancao">Sanção</option>
                            </select>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        

        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="form-control-label" for="extrato-dataInicio"
                    >Data Inicio</label
                    >
                    <div class="d-flex">
                        <input
                           type="date" class="form-control form-control-border border-width-2" id="datainicio"
                           placeholder="dd/mm/aaaa" :class="{
                            'is-invalid': isSaving && $v.busca.dataInicio.$error,
                            }"
                            @change="updateZonedDateTimeField('dataInicio', $event)"
                        />
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label class="form-control-label" for="extrato-dataInicio"
                    >Data Fim</label
                    >
                    <div class="d-flex">
                        <input
                           type="date" class="form-control form-control-border border-width-2" id="datafim"
                           placeholder="dd/mm/aaaa" :class="{
                            'is-invalid': isSaving && $v.busca.dataFim.$error,
                            }"
                            @change="updateZonedDateTimeField('dataFim', $event)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="float-right">
          <button @click.prevent="buscarProcesso" class="btn text-color-principal background-principal ml-1"
            type="submit">
            <i class="fa fa-search"></i> Buscar
          </button>
        </div>


    </div>
</div>

    
</template>

<script>
import Loader from "@/components/Loader.vue";
import BuscarConteudoAta from "@/components/processos/ConteudoAtaRegistro.vue";
import dayjs from "dayjs";
import {DATE_FORMAT_LOCAL_DATA} from "@/util/filter";


export default ({
     components: {Loader,BuscarConteudoAta},

    data() {
        return{
            loading: false,
            isSaving: false,
            busca: {
                extrato: "",
                dataInicio:"",
    
        }
        }

    },

    validations: {
        dataInicio:{require},
        dataFim:{require},
    },


    methods:{
        buscarProcesso() {
            this.loading = true;
        },
     
     updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
      }
    
        
    }

});

</script>
