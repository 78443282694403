<template>
  <div class="card-body pt-0 ">
    <Loader :active="loaderActive" message="Carregando..." />
    <!-- <div class="tab-content" id="custom-tabs-five-tabContent"> -->
    <!-- <div class="tab-pane fade" role="tabpanel" > -->
    <!-- <div class="table-responsive mailbox-messages"> -->
    <table class="table table-hover table-striped" v-if="!loaderActive">
      <thead>
        <tr>
          <th>Tipo</th>
          <th>Assunto</th>
          <th>Anexo</th>
          <th>Destinatário</th>
          <th>Setor</th>
          <th>Recebido</th>
          <th>Ações</th>
          <!-- <th>Ciencia</th> -->
        </tr>
      </thead>
      <tbody v-if="resultados">
        <tr class="linhaTabela" v-for="documento in documentos" v-bind:key="documento.id"
          @click="visualizar(documento.id)">
          <td class="mailbox-name" v-if="documento.tipoDocumento === 'CI'">
            <i class="fas fa-users" :title="'DOCUMENTO COMPARTILHADO POR: ' + documento.fkUsuario.pessoaResumo.nome " v-if="documento.fkUsuario.id != usuario.idFuncionario"></i>
            Comunicação Interna
          </td>
          <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'OF'">
            <i class="fas fa-users" :title="'DOCUMENTO COMPARTILHADO POR: ' + documento.fkUsuario.pessoaResumo.nome " v-if="documento.fkUsuario.id != usuario.idFuncionario"></i>
            Ofício
          </td>
          <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'DE'">
            <i class="fas fa-users" :title="'DOCUMENTO COMPARTILHADO POR: ' + documento.fkUsuario.pessoaResumo.nome " v-if="documento.fkUsuario.id != usuario.idFuncionario"></i>
            Despacho
          </td>
          <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'PR'">
            <i class="fas fa-users" :title="'DOCUMENTO COMPARTILHADO POR: ' + documento.fkUsuario.pessoaResumo.nome " v-if="documento.fkUsuario.id != usuario.idFuncionario"></i>
            Protocolo
          </td>
          <td class="mailbox-subject">
            {{ documento.assunto }}
          </td>
          <td class="mailbox-attachment">
            <i class="fas fa-paperclip" v-if="documento.anexo"></i>
          </td>
          <td v-if="documento.origemDocumento == 'Interna'" class="mailbox-subject">
            {{ documento.fkUsuarioDestino.pessoaResumo.nome }}
          </td>
          <td v-else class="mailbox-subject">
            {{ documento.nomeDestino }}
          </td>
          <td v-if="documento.origemDocumento == 'Interna'" class="mailbox-subject">
            {{ documento.fkSetorDestino.descricao }}
          </td>
          <td v-else class="mailbox-subject">
            {{ documento.enderecoDestino }}
          </td>
          <td class="mailbox-date">
            {{ updateZonedDateTimeField(documento.dataAssinatura) }}
          </td>
          <td>
            <button @click="desejaApagarRascunho(documento)" type="button" class="btn btn-default btn-sm apagar">
              <i class="far fa-trash-alt apagar"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <div class="mx-auto my-auto sem-resultados py-2">
          <div>Sem documentos em rascunho!</div>
        </div>
      </tbody>
    </table>
  </div>
</template>
<script>
import { EventBus } from "@/main.js";
import { DATE_FORMAT_LOCAL } from "@/util/filter";
import paginacao from "@/util/paginacao";
import dayjs from "dayjs";
import documentoService from "../../service/DocumentoService";
import Loader from "../Loader.vue";

export default {
  name: "Documentos",
  components: { Loader },
  data() {
    return {
      documentos: [],
      resultados: false,
      page: [],
      documentoPesquisa: [],
      loaderActive: false,
    };
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    acao: {
      type: String,
      default: "EN",
    },
  },
  methods: {
    desejaApagarRascunho(doc) {
      let documentoEhRascunho = doc.editar == true || doc.editar == null;
      if (documentoEhRascunho) {
        // console.log(doc)

        this.$swal
          .fire({
            title: "Confirmação de ação",
            html: "<div >Deseja excluir o rascunho de assunto " + doc.assunto + "?</div>",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Excluir",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            reverseButtons: false,
            allowOutsideClick: () => !Swal.isLoading(),
          })
          .then((resultado) => {
            if (resultado.isConfirmed) {

              // console.log("Deve apagar doc com id " + doc.id)
              documentoService.apagarDocumento(doc.id).then(r => {
                if (r.status == 204) {

                  Toast.fire("Rascunho excluido com sucesso!", "", "success");
                  EventBus.$emit('contabilizarRascunho', null);
                }
                this.getDocumentos(0);

              });

            }
          });

      }
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    getDocumentos(pagina) {
      this.showLoader();
      if (this.documentoPesquisa.length == undefined) {

        this.documentoPesquisa.tableConsulta = 'documentos_rascunhos';
        // if (this.documentos.length > 0) {
        //   this.hideLoader();
        //   this.comResultados = true;
        // } else {
        //   this.hideLoader();
        // }

        documentoService.pesquisarDocumento(this.documentoPesquisa, pagina).then((response) => {
          this.hideLoader();
          let retorno = paginacao.montarPaginacao(response.data)

          this.page = retorno.page;
          this.documentos = retorno.data;

          if (this.documentos.length > 0) {
            this.resultados = true;
          }
          EventBus.$emit('contabilizarRascunho', null);
          EventBus.$emit("paginacao", retorno.page);

        });

      } else {

        documentoService.buscarDocumentosRascunhos(pagina).then((response) => {
          this.hideLoader();
          let retorno = paginacao.montarPaginacao(response.data)

          this.page = retorno.page;
          this.documentos = retorno.data;

          if (this.documentos.length > 0) {
            this.resultados = true;
          }
          EventBus.$emit('contabilizarRascunho', null);
          EventBus.$emit("paginacao", retorno.page);

        }).catch((e) => {
          this.hideLoader();
        });
      }
    },

    visualizar(id) {
      let clickouEmApagar = event.target.classList.contains("apagar");
      if (!clickouEmApagar) {
        EventBus.$emit("alteraAcaoConteudo", "VD");
        EventBus.$emit("enviarPdf", id);
        EventBus.$emit('contabilizarRascunho', null)
      }

    },

    updateZonedDateTimeField(field) {
      let retorno = dayjs(field).format(DATE_FORMAT_LOCAL);
      if (retorno != 'Invalid Date') {
        return retorno;
      }
      return "Documento não assinado";
    },
  },

  created() {

    EventBus.$on("filtrarDocumentoRascunhos", (documento) => {
      this.documentoPesquisa = documento;
      this.getDocumentos(0);
    });

    if (this.usuario) {
      this.getDocumentos(0);
    }
  },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    },
  },

  beforeMount() {
    $("body").removeClass("login-page").addClass("sidebar-mini");
  },
  mounted() {
    $(function () {
      $(".checkbox-toggle").click(function () {
        const clicks = $(this).data("clicks");
        if (clicks) {
          $(".mailbox-messages input[type='checkbox']").prop("checked", false);
          $(".checkbox-toggle .far.fa-check-square")
            .removeClass("fa-check-square")
            .addClass("fa-square");
        } else {
          $(".mailbox-messages input[type='checkbox']").prop("checked", true);
          $(".checkbox-toggle .far.fa-square")
            .removeClass("fa-square")
            .addClass("fa-check-square");
        }
        $(this).data("clicks", !clicks);
      });

      $(".mailbox-star").click(function (e) {
        e.preventDefault();
        const $this = $(this).find("a > i");
        const fa = $this.hasClass("fa");

        if (fa) {
          $this.toggleClass("fa-star");
          $this.toggleClass("fa-star-o");
        }
      });
    });
  },
};
</script>
<style scoped>
.linhaTabela {
  cursor: pointer;
}
</style>
