<template>
  <!-- <div v-if="anotacoes"  v-show="showModal" class="modal fade show h-100 overflow-auto" id="modal-dialog modal-xl" style="display: block"
    aria-modal="true" role="dialog" >
    <div class="modal-dialog modal-xl p-3" @click.stop> -->
  <div>
    <b-modal
      id="modal-22"
      ref="modal-22"
      :title="servidor"
      data-backdrop="static"
      size="lg"
      cancel-disabled
      no-close-on-backdrop
      hide-header-close
      header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            "
      content-class="modal-content border-rounded-detail"
      dialog-class="p-3"
      title-class="modal-title mx-auto xl"
    >
      <div class="d-block">
        <div class="row">
          <div class="col-3 p-1 d-flex flex-column">
            <span>
              <b>Data Início: </b
              >{{
                updateZonedDateTimeField(ferias.data_inicio) || ""
              }}
            </span>
          </div>
          <div class="col-3 p-1 d-flex flex-column">
            <span>
              <b>Data Fim: </b
              >{{ updateZonedDateTimeField(ferias.data_fim) || "" }}
            </span>
          </div>
          <div class="col-2 p-1 d-flex flex-column">
            <span> <b>Mês: </b> {{ ferias.mes || "Sem Registro" }} </span>
          </div>
          <div class="col-2 p-1 d-flex flex-column">
            <span> <b>Ano: </b>{{ ferias.ano_exercicio || "Sem Registro" }} </span>
          </div>
          <div class="col-2 p-1 d-flex flex-column">
            <span> <b>Exercício: </b>{{ ferias.ano || "Sem Registro" }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-1 d-flex flex-column">
            <span>
              <b>Período de Transferência: </b
              >{{ updateZonedDateTimeField(ferias.data_inicio_alteracao)|| ""}} - {{updateZonedDateTimeField(ferias.data_fim_alteracao)  || "" }}
            </span> 
          </div>
          
        </div>

        
       
        <div class="row">
           <div class=" p-1 d-flex flex-column">
                  <b>Observação: </b>
                  
                  <p >{{ ferias.observacao || "Sem Registro"}}</p>
            </div>
        </div>

      </div>
      <template #modal-footer="{ cancel }">
        <!-- <button v-b-modal.modal-20 @click="[enviarDadosAnotacao(anotacoes),cancel()]" type="button" class="btn btn-success ml-1">
                    <i class="fa-solid fa-user-plus"></i> Editar
                </button> -->

        <b-button class="btn btn-default float-right" @click="[cancel()]">
          <i class="fas fa-times"></i> Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
  <!-- </div>
  </div> -->
</template>

<script>
import { EventBus } from "../../../main.js";
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
// import Loader from "../../Loader.vue";
// import BoCardComponent from "../BoCardComponent.vue";
//import EditarAnotacao from "@/components/rh/anotacao/EditarAnotacao.vue";
export default {
  components: {
    //Loader,
    //BoCardComponent,
    //EditarAnotacao,
  },
  data() {
    return {
      ///loaderActive: true,
      ferias: {},
      servidor: this.$store.state.funcionario.nomePessoaFuncionario,

      //showModal: true,
    };
  },
  created() {
    EventBus.$on("detalhesFerias", (ferias) => {
      this.ferias = ferias;
      //this.$refs['modal-22'].show();
    });
  },
  methods: {
    // enviarDadosAnotacao(anotacoes){

    //    EventBus.$emit("EnvioDadosAnotacao",anotacoes)
    //  },

    // editarAnotacao(){
    //   EventBus.$emit("EditarAnotacao")
    //   console.log("gjhdfkjg")
    // }

    // fecharModal() {
    //   this.showModal = false
    //  },

    // showLoader() {
    //   //this.pessoa = false;
    //   this.loaderActive = true;
    // },
    // hideLoader() {
    //   this.loaderActive = false;
    // },

    updateZonedDateTimeField(field) {

      if(field == null){

        return "Sem Registro"
      }
      else{
        return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
      }
      

    },

//      filters: {
//     formatarData: function (data) {
//       if (data == "" || data == null) {
//         return 'Sem data registrada';
//       }
//       else {
// //
//         const [date, horas] = data.split("T");
//        const[ano,mes,dia]=date.split("-");
//         const[hr,min,sec]=horas.split(":");


//         const dataHoraFormatada = `${dia}/${mes}/${ano} ${hr}:${min}:${sec.replace("Z","")}`;
     
//         return dataHoraFormatada;
//       }
//     },
//   },
  },
};
</script>
