import instance from "./axiosConfig";

class RHAnotacaoService {

    criarAnotacao(anotacao) {
        return instance.post("/api/rh/anotacao", anotacao);
    }

    getAnotacao(id) {
        return instance.get(`/api/rh/anotacao/${id}`);
    }
    consultaAnotacao(params) {
        return instance.get("/api/rh/anotacao/consulta", {
            params: params,
        });
    }

    buscarAnotacao() {
        return instance.get("/api/rh/anotacao");
    }

    anotacaoFuncionarioId(funcionarioId){
        return instance.get(`/api/rh/anotacao/funcionario/${funcionarioId}`);
        
    }

    
    editarAnotacao(anotacao) {
        return instance.put(`/api/rh/anotacao/${anotacao.id}`, anotacao)
    }

    getPdfFichaAnotacao(idAnotacao){
        return instance.get(`/api/rh/anotacao/pdf/${idAnotacao}`)
    }

}

export default new RHAnotacaoService();
