var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-6",attrs:{"id":"modal-6","title":"Cadastro Férias","data-backdrop":"static","size":"xl","cancel-disabled":"","no-close-on-backdrop":"","hide-header-close":"","header-class":"modal-header\n          background-principal\n          text-color-principal\n          card-item-result\n          ","content-class":"modal-content border-rounded-detail","dialog-class":"p-3","title-class":"modal-title mx-auto xl"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('button',{staticClass:"btn text-color-principal background-principal ml-1",on:{"click":function($event){$event.preventDefault();[_vm.salvarFerias(), ok()]}}},[_c('i',{staticClass:"far fa-envelope"}),_vm._v(" Salvar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.LimparFerias.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-ban"}),_vm._v(" Limpar ")]),_c('b-button',{staticClass:"btn btn-default float-right",on:{"click":function($event){[cancel(), _vm.apagarCamposFerias()]}}},[_c('i',{staticClass:"fa-solid fa-x"}),_vm._v("Fechar ")])]}}])},[_c('DadosPessoaFuncionario'),_c('hr'),_c('h6',[_c('b',[_vm._v("PREENCHA OS CAMPOS ABAIXO: ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-anoExercicio"}},[_vm._v("Ano Exercício ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ferias.anoExercicio),expression:"ferias.anoExercicio"}],staticClass:"form-control form-control-border border-width-2",class:{
              'is-invalid': _vm.isSaving && _vm.$v.ferias.anoExercicio.$error,
            },attrs:{"type":"text","name":"anoExercicio","id":"cadastro-anoExercicio"},domProps:{"value":(_vm.ferias.anoExercicio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ferias, "anoExercicio", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-anoFerias"}},[_vm._v("Ano ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ferias.ano),expression:"ferias.ano"}],staticClass:"form-control form-control-border border-width-2",class:{
              'is-invalid': _vm.isSaving && _vm.$v.ferias.ano.$error,
            },attrs:{"type":"text","name":"anoFerias","id":"cadastro-anoFerias"},domProps:{"value":(_vm.ferias.ano)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ferias, "ano", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"cadastro-mesFerias"}},[_vm._v("Mês Férias")]),_c('v-select',{staticClass:"border-v-select mt-1",attrs:{"id":"cadastro-mesFerias","options":_vm.SelectMes,"reduce":function (SelectMes) { return SelectMes.id; },"label":"descricao"},model:{value:(_vm.ferias.mes),callback:function ($$v) {_vm.$set(_vm.ferias, "mes", $$v)},expression:"ferias.mes"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-6"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-dataInicio"}},[_vm._v("Data Início")]),_c('div',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ferias.dataInicio),expression:"ferias.dataInicio"}],staticClass:"form-control form-control-border border-width-2",class:{
                'is-invalid': _vm.isSaving && _vm.$v.ferias.dataInicio.$error,
              },attrs:{"type":"date","data-cy":"dataInicio","id":"cadastro-dataInicio","placeholder":"dd/mm/aaaa"},domProps:{"value":(_vm.ferias.dataInicio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ferias, "dataInicio", $event.target.value)}}})])]),_c('div',{staticClass:"form-group col-lg-6"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-dataFim"}},[_vm._v("Data Fim ")]),_c('div',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ferias.dataFim),expression:"ferias.dataFim"}],staticClass:"form-control form-control-border border-width-2",class:{
                'is-invalid': _vm.isSaving && _vm.$v.ferias.dataFim.$error,
              },attrs:{"type":"date","data-cy":"dataFim","id":"cadastro-dataFim","placeholder":"dd/mm/aaaa"},domProps:{"value":(_vm.ferias.dataFim)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ferias, "dataFim", $event.target.value)}}})])])]),_c('div',{staticClass:"form-group col-lg"},[_c('label',{attrs:{"for":"cadastro-observacao"}},[_vm._v("Observação")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.ferias.observacao),expression:"ferias.observacao"}],staticClass:"form-control",staticStyle:{"resize":"none"},attrs:{"type":"text","rows":"3","name":"observacao","id":"cadastro-observacao"},domProps:{"value":(_vm.ferias.observacao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ferias, "observacao", $event.target.value)}}})]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ferias-supervidor"}},[_vm._v("Supervisor(a)")]),_c('v-select',{staticClass:"border-v-select mt-1",attrs:{"id":"ferias-supervidor","options":_vm.listaSupervisor,"reduce":function (listaSupervisor) { return listaSupervisor.id; },"label":"nome"},model:{value:(_vm.ferias.supervisorAssinatura),callback:function ($$v) {_vm.$set(_vm.ferias, "supervisorAssinatura", $$v)},expression:"ferias.supervisorAssinatura"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }