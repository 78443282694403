var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.deveEsconderDocumento),expression:"!deveEsconderDocumento"}],staticClass:"col-12"},[_c('hr'),_c('h5',{staticClass:"mb-3"},[_vm._v("Cadastrar um Documento")]),_c('form',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-3 col-sm-12"},[_c('label',{attrs:{"for":"tipodocumento"}},[_vm._v("Tipo do Documento *")]),_c('v-select',{staticClass:"border-v-select",attrs:{"id":"tipodocumento","options":_vm.tiposDocumento,"label":"descricao"},model:{value:(_vm.documento.tipoDocumento),callback:function ($$v) {_vm.$set(_vm.documento, "tipoDocumento", $$v)},expression:"documento.tipoDocumento"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1),_c('div',{staticClass:"form-group col-lg-3 col-sm-12"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Número do documento *")]),(_vm.documento.tipoDocumento && _vm.documento.tipoDocumento.descricao != 'CPF')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.documento.numero),expression:"documento.numero"}],key:"nao-cpf",staticClass:"form-control form-control-border border-width-2",class:{
            'is-invalid':
              _vm.isDocSaving &&
              !_vm.$v.documento.numero.required &&
              _vm.documento.tipoDocumento !== '' &&
              _vm.documento.tipoDocumento !== null,
          },attrs:{"type":"text","id":"numerodocumento"},domProps:{"value":(_vm.documento.numero)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.documento, "numero", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.documento.numero),expression:"documento.numero"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],key:"cpf",staticClass:"form-control form-control-border border-width-2",class:{
          'is-invalid':
            _vm.isDocSaving &&
            !_vm.$v.documento.numero.required &&
            _vm.documento.tipoDocumento !== '' &&
            _vm.documento.tipoDocumento !== null,
        },attrs:{"type":"text","id":"numerodocumento"},domProps:{"value":(_vm.documento.numero)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.documento, "numero", $event.target.value)}}}),(
          _vm.isDocSaving &&
          !_vm.$v.documento.numero.required &&
          _vm.documento.tipoDocumento !== '' &&
          _vm.documento.tipoDocumento !== null
        )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Número do documento é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"form-group col-lg-3"},[_c('label',{attrs:{"for":"dataFatoInicio"}},[_vm._v("Data de emissão")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.documento.dataEmissao),expression:"documento.dataEmissao"}],staticClass:"form-control form-control-border border-width-2",attrs:{"type":"date","id":"dataemissao","placeholder":"dd/mm/aaaa"},domProps:{"value":(_vm.documento.dataEmissao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.documento, "dataEmissao", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-3"},[_c('label',{attrs:{"for":"dataFatoInicio"}},[_vm._v("Data de validade")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.documento.dataValidade),expression:"documento.dataValidade"}],staticClass:"form-control form-control-border border-width-2",attrs:{"type":"date","id":"datavalidade","placeholder":"dd/mm/aaaa"},domProps:{"value":(_vm.documento.dataValidade)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.documento, "dataValidade", $event.target.value)}}})])]),_c('hr')]),_c('div',{staticClass:"col-12"},[_c('TableDocumentosPessoa')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }