<template>
    <div>
        <div class="row">
            <div class="form-group col-lg-4">
                <label for="cidade" style="    padding-left: 20px;
                    ">Cidade</label>
                <input style="
                margin-left: 20px;
                width: 96%;

                " type="text" class="form-control form-control-border" id="cidade" v-model="localidade.cidade" />
            </div>
            <div class="form-group col-2">
                <label for="uf">UF</label>
                <input type="text" class="form-control form-control-border" id="uf" v-model="localidade.uf
                    " />
            </div>
            <div class="form-group col-2">
                <label for="porcento">Porcento</label>
                <input type="text" class="form-control form-control-border" id="porcento" v-model="localidade.porcento
                    " />
            </div>
        </div>

        <div class="card-footer">
            <button @click.prevent="limparLocalidade" type="reset" class="btn btn-default">
                <i class="fas fa-ban"></i> Limpar
            </button>
            <div class="row float-right">

                <button @click="SalvarLocalidade" class="btn text-color-principal background-principal ml-1">
                    <i class="fa fa-save"></i> Salvar
                </button>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            localidade: {}
        }

    },
    validations: {
        localidade: {
            cidade: {},
            uf: {},
            porcento: {},
        }
    },
    methods: {
        SalvarLocalidade() { },
        limparLocalidade() {
            this.localidade = {}
        }
    }
}
</script>