<template>
  <!-- <body class="hold-transition sidebar-mini"><button @click="login">login</button></body> -->
  <body class="hold-transition sidebar-mini"></body>
</template>

<script>
import { Versao } from "@/main";
import Loader from "../components/Loader.vue";
import Auth from '@/service/auth/auth.service';
import tokenService from '@/service/TokenService';

// axios from "axios";
//import CryptoJS from "crypto-js";

import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
  name: "Login",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      message: "",
      loaderActive: false,
      versao: Versao,
    };
  },
  computed: {
    scope() {
      return this.$store.state.app.scope;
    },
    urlOauth() {
      return this.$store.state.app.urlOauth2;
    },
    url() {
      return this.$store.state.app.url;
    },
    client() {
      return this.$store.state.app.client;
    },
    baseUrlOauth() {
      return this.$store.state.app.baseUrlOauth;
    }
  },
  beforeMount() {

    let user = localStorage.getItem("user");

    if (!user){
      const code = new URLSearchParams(this.$route.query).get("code");

      if (code) {
        const state = new URLSearchParams(this.$route.query).get("state");
        const storedCodeVerifier = localStorage.getItem("code_verifier");

        if (!code || !state || !storedCodeVerifier) {
          console.error("Error: Missing parameters.");
          return;
        }

        let url = this.url;

        if (state !== "12345") {
          console.error("Error: Invalid state parameter.");
          return;
        }

        const codeVerifier = storedCodeVerifier;
        const codeChallenge = this.generateCodeChallenge(codeVerifier);

        const clientId = "sigma";
        const redirectUri = url + "/login";

        const tokenEndpoint = this.urlOauth + "/oauth2/token";

        const data = new URLSearchParams({
          grant_type: "authorization_code",
          code: code,
          redirect_uri: redirectUri,
          client_id: clientId,
          code_verifier: codeVerifier,
        });

        Auth.login(tokenEndpoint, data).then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));

          let token = JSON.parse(JSON.stringify(response.data)).access_token;
          let tokenResolvido = [];
          tokenResolvido.usuario = tokenService.parseJwt(token).usuario;

          this.$store.commit("loginSuccess", tokenResolvido);

          this.$router.push("/");
        });

      } else {
        this.login();
      }
    }else {
      this.$router.push("/");
    }
  },
  methods: {
    login() {
      $("body").removeClass("login-page").addClass("sidebar-mini");

      try {
        const codeVerifier = this.generateCodeVerifier();
        const codeChallenge = this.generateCodeChallenge(codeVerifier);

        let scope = this.scope;
        let url = this.url + "/login";
        let client = this.client;
        let baseUrlOauth = this.urlOauth;

        localStorage.setItem("code_verifier", codeVerifier);

        window.location.href = baseUrlOauth + `/oauth2/authorize?response_type=code&client_id=${client}&state=12345&redirect_uri=${url}&scope=${scope}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
      } catch (error) {
        console.error(error);
      }
    },
    generateCodeVerifier() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const randomBytes = new Uint8Array(32);
      window.crypto.getRandomValues(randomBytes);
      const codeVerifier = Array.from(randomBytes)
        .map((byte) => characters[byte % characters.length])
        .join("");
      // localStorage.setItem('code_verifier', codeVerifier);
      return codeVerifier;
    },
    generateCodeChallenge(codeVerifier) {
      const hash = CryptoJS.SHA256(codeVerifier);
      const codeChallenge = hash
        .toString(CryptoJS.enc.Base64)
        .replace(/=/g, "")
        .replace(/\+/g, "-")
        .replace(/\//g, "_");
      return codeChallenge;
    },
    // generateCodeChallenge(codeVerifier) {
    //   const hash = CryptoJS.SHA256(codeVerifier);
    //   const base64 = hash.toString(CryptoJS.enc.Base64);
    //   const codeChallenge = base64
    //       .replace(/\+/g, '-')
    //       .replace(/\//g, '_')
    //       .replace(/=/g, '');
    //   return codeChallenge;
    // },
    decodeIdToken(idToken) {
      const payload = idToken.split(".")[1];
      const decodedPayload = atob(payload);
      const jsonPayload = JSON.parse(decodedPayload);
      return jsonPayload;
    },
  },
};
</script>
