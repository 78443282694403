<template>
  <div>
    <pdf-ficha-cadastro />
    <PdfFichaAnotacoes />

    <loading :active="loaderActive" :is-full-page="fullPage" />
    <div class="pl-4 pr-4 pb-4">
      <h6><b>INFORMAÇÕES PROFISSIONAIS: </b></h6>
      <div class="row">
        <div class="form-group col-lg-3">
          <label for="antigaMatricula">Antiga Matrícula </label>
          <input type="text" class="form-control form-control-border border-width-2" id="matricula"
            placeholder="xxx.xxx.xxx" v-model="funcionario.matricula"
            :class="{ 'is-invalid': $v.funcionario.matricula.$error }" />
          <div v-if="!$v.funcionario.matricula.maxLength" class="invalid-feedback">
            Tamanho máximo de 40 caracteres
          </div>
        </div>

        <div class="form-group col-lg-3">
          <label for="novaMatricula"> ID <span class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border border-width-2" id="novaMatricula"
            placeholder="xxx.xxx.xxx" v-model="funcionario.novaMatricula"
            :class="{ 'is-invalid': $v.funcionario.novaMatricula.$error }" />
          <div v-if="!$v.funcionario.novaMatricula.required" class="invalid-feedback">
            Este campo é obrigatório
          </div>
          <div v-if="!$v.funcionario.novaMatricula.maxLength" class="invalid-feedback">
            Tamanho máximo de 40 caracteres
          </div>
        </div>

        <div class="form-group col-lg-3">
          <label for="tipocontratacao">Tipo de contratação <span class="text-danger">*</span></label>
          <v-select class="border-v-select mt-1" id="tipocontratacao"
            v-model="funcionario.dadosAdicionais.tipoContratacao" :options="[
      'NÃO APLICÁVEL',
      'CONCURSO',
      'ESTAGIARIO',
      'TERCEIRIZADO',
      'COMISSIONADO',
      'CONTRATADO',
    ]" label="descricao" :class="{ 'is-invalid': $v.funcionario.dadosAdicionais.tipoContratacao.$error }">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="!$v.funcionario.dadosAdicionais.tipoContratacao.required" class="invalid-feedback">
            Este campo é obrigatório
          </div>
        </div>
        <div class="form-group col-lg-3">
          <label for="dataDeAdmissao">Data de admissão <span class="text-danger">*</span></label>
          <input type="date" class="form-control form-control-border border-width-2" id="dataDeAdmissao"
            placeholder="dd/mm/aaaa" v-model="funcionario.dadosAdicionais.dataAdmissao"
            :class="{ 'is-invalid': $v.funcionario.dadosAdicionais.dataAdmissao.$error }" />
          <div v-if="!$v.funcionario.dadosAdicionais.dataAdmissao.required" class="invalid-feedback">
            Este campo é obrigatório
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="email">E-mail Funcional</label>
          <input type="email" class="form-control form-control-border  border-width-2" id="emailFuncional"
            placeholder="usuario@email.com" v-model="funcionario.emailFuncional"
            :class="{ 'is-invalid': $v.funcionario.emailFuncional.$error }" @input="(event) =>
      (funcionario.emailFuncional = event.target.value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase())
      " />
          <div v-if="!$v.funcionario.emailFuncional.email" class="invalid-feedback">
            E-mail inválido
          </div>
        </div>
        <div class="form-group col-lg-4">
          <label for="email">E-mail de recuperação </label>
          <input type="email" class="form-control form-control-border border-width-2" id="emailRecuperacao"
            placeholder="usuario@email.com" v-model="funcionario.emailRecuperacao"
            :class="{ 'is-invalid': $v.funcionario.emailRecuperacao.$error }" @input="(event) =>
      (funcionario.emailRecuperacao = event.target.value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase())
      " />
          <div v-if="!$v.funcionario.emailRecuperacao.email" class="invalid-feedback">
            E-mail inválido
          </div>
        </div>
        <div class="form-goup col-lg-3">
          <label for="limitediarias">Limite de Diárias</label>
          <input type="number" class="form-control form-control-border border-width-2" id="limitediarias"
            v-model="funcionario.dadosAdicionais.limiteDiarias" />
        </div>
        <div class="form-group col-lg-1">
          <div @click="atualizarAnexo" v-b-modal.modal-19 class="btn btn-default btn-file ml-3 mt-4">
            <i class="fas fa-paperclip"></i> Anexo
          </div>
        </div>

      </div>
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="observacao">CTPS</label>
          <input type="text" class="form-control form-control-border border-width-2" id="ctps" placeholder=""
            v-model="funcionario.dadosAdicionais.numeroCTPS" />
        </div>
        <div class="form-group col-lg-4">
          <label for="nome">Empresa contratante</label>
          <v-select class="border-v-select mt-1" id="empresaContratante" v-model="funcionario.empresaContratante"
            :options="empresasContratante" label="nomeFantasia">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="form-group col-lg-4">
          <label for="observacao">Observação</label>
          <input type="text" class="form-control form-control-border border-width-2" id="observacao" placeholder=""
            v-model="funcionario.dadosAdicionais.observacao" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="nome">Unidade de Origem <span class="text-danger">*</span></label>
          <v-select class="border-v-select mt-1" id="unidadeDeOrigem" v-model="funcionario.unidadeOrigem"
            :options="unidadesDeOrigem" label="descricao"
            :class="{ 'is-invalid': $v.funcionario.unidadeOrigem.$error }">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="!$v.funcionario.unidadeOrigem.required" class="invalid-feedback">
            Este campo é obrigatório
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="nome">Lotação <span class="text-danger">*</span></label>
          <v-select class="border-v-select mt-1" id="lotacao" v-model="funcionario.lotacao" :options="lotacaos"
            label="descricao" :class="{ 'is-invalid': $v.funcionario.lotacao.$error }">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="!$v.funcionario.lotacao.required" class="invalid-feedback">
            Este campo é obrigatório
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="nome">Cargo</label>
          <v-select class="border-v-select mt-1" id="cargo" v-model="funcionario.cargo" :options="cargos"
            label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="form-group col-lg-3">
          <label for="nome">Função</label>
          <v-select class="border-v-select mt-1" id="funcao" v-model="funcionario.funcao" :options="funcaos" 
            label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="form-group col-lg-2">
          <label for="admissao-ato"> Ato </label>
          <input type="text" class="form-control form-control-border border-width-2" id="admissao-ato"
            v-model="funcionario.dadosAdicionais.admissaoAto" />
        </div>
        <div class="form-group col-lg-3">
          <label for="admissao-classe"> Classe </label>
          <input type="text" class="form-control form-control-border border-width-2" id="admissao-classe"
            v-model="funcionario.dadosAdicionais.admissaoClasse" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-3">
          <label for="admissao-posse">Posse</label>
          <input type="date" class="form-control form-control-border border-width-2" id="admissao-pose"
            placeholder="dd/mm/aaaa hh:mm" v-model="funcionario.admissaoPosse" />
        </div>
        <div class="form-group col-lg-3">
          <label for="admissao-pub">Publicação</label>
          <input type="date" class="form-control form-control-border border-width-2" id="admissao-pub"
            placeholder="dd/mm/aaaa hh:mm" v-model="funcionario.admissaoPub" />
        </div>
        <div class="form-group col-lg-3">
          <label for="admissao-ref"> Referência </label>
          <input type="text" class="form-control form-control-border border-width-2" id="admissao-ref"
            v-model="funcionario.dadosAdicionais.admissaoRef" />
        </div>
        <div class="form-group col-lg-3">
          <label for="cargo-comissionado">Cargo Comissionado</label>
          <input type="text" class="form-control form-control-border border-width-2" id="cargo-comissionado"
            v-model="funcionario.dadosAdicionais.cargoComissionado" />
        </div>
      </div>
      <div class="row">
        <!-- <div class="form-group col-lg-3">
          <label for="grupo">Grupo</label>
          <input
            type="number"
            class="form-control form-control-border border-width-2"
            id="grupo"
            v-model="funcionario.dadosAdicionais.grupo"
          />
        </div> -->
        <div class="form-group col-lg-4">
          <label for="tipocontratacao">Policial <span class="text-danger">*</span></label>
          <v-select class="border-v-select mt-1" id="policial" v-model="funcionario.policial" :options="selectBoolean"
            :reduce="(selectBoolean) => selectBoolean.id" label="descricao"
            :class="{ 'is-invalid': $v.funcionario.policial.$error }">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="!$v.funcionario.policial.required" class="invalid-feedback">
            Este campo é obrigatório
          </div>
        </div>
        <div class="form-group col-lg-4">
          <label for="tipocontratacao">Usuário <span class="text-danger">*</span></label>
          <v-select class="border-v-select mt-1" id="policial" v-model="funcionario.funcionarioExterno"
            :options="selectTipoFuncionario" :reduce="(selectTipoFuncionario) => selectTipoFuncionario.id"
            label="descricao" :class="{ 'is-invalid': $v.funcionario.funcionarioExterno.$error }">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="!$v.funcionario.funcionarioExterno.required" class="invalid-feedback">
            Este campo é obrigatório
          </div>
        </div>
        <div class="form-group col-lg-4">
          <label for="tipocontratacao">Funcionário <span class="text-danger">*</span></label>
          <v-select class="border-v-select mt-1" id="policial" v-model="funcionario.ativo"
            :options="selectFuncionarioAtivoBoolean" :reduce="(selectFuncionarioAtivoBoolean) =>
        selectFuncionarioAtivoBoolean.id
      " label="descricao" :class="{ 'is-invalid': $v.funcionario.ativo.$error }">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="!$v.funcionario.ativo.required" class="invalid-feedback">
            Este campo é obrigatório
          </div>
        </div>
      </div>
      <div class="row"></div>
      <hr />
      <h6 class="mt-4 mb-3"><b>DADOS BANCÁRIOS: </b></h6>
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="banco">Banco</label>
          <v-select class="border-v-select mt-1" id="banco" v-model="funcionario.dadosAdicionais.banco"
            :disabled="bancos == null || bancos == []" :options="bancos" :reduce="(bancos) => bancos.id"
            label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="form-group col-lg-2">
          <label for="banco-ag"> Agência </label>
          <input type="text" class="form-control form-control-border border-width-2" id="banco-ag"
            v-model="funcionario.dadosAdicionais.bancoAg" />
        </div>
        <div class="form-group col-lg-2">
          <label for="banco-conta"> Conta </label>
          <input type="text" class="form-control form-control-border border-width-2" id="banco-conta"
            v-model="funcionario.dadosAdicionais.bancoConta" />
        </div>
        <!-- <div class="form-group col-lg-3">
          <label for="banco-grupo"> Grupo Bancário </label>
          <input
            type="text"
            class="form-control form-control-border border-width-2"
            id="banco-grupo"
            v-model="funcionario.dadosAdicionais.bancoGrupo"
          />
        </div> -->
        <div class="form-group col-6">
          <label for="bancoGrupo">Grupo </label>
          <v-select class="border-v-select mt-1" id="bancoGrupo" v-model="funcionario.dadosAdicionais.bancoGrupo"
            :options="bancoGrupos" :reduce="(bancoGrupos) => bancoGrupos.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>

      <hr />
      <h6 class="mt-4 mb-3"><b>DADOS PESSOAIS: </b></h6>
      <div class="row">
        <div class="col-lg-3">
          <label for="fator-rh">Fator RH</label>
          <v-select class="border-v-select mt-1" id="fator-rh" v-model="funcionario.dadosAdicionais.fatorRh"
            :disabled="fatorRhList == null || fatorRhList == []"
            :options="fatorRhList" :reduce="fatorRhList => fatorRhList.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="col-lg-3">
          <label for="grupo-sanguineo">Grupo Sanguíneo</label>
          <v-select class="border-v-select mt-1" id="grupo-sanguineo"
            v-model="funcionario.dadosAdicionais.grupoSanguineo"
            :disabled="grupoSanguineoList == null || grupoSanguineoList == []"
             :options="grupoSanguineoList " :reduce="grupoSanguineoList => grupoSanguineoList.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div class="col-lg-3">
          <label for="numero-dependentes"> Quantidade de Dependentes </label>
          <input type="number" class="form-control form-control-border border-width-2" id="numero-dependentes"
            v-model="funcionario.dadosAdicionais.numeroDependentes" />
        </div>
        <div class="form-group col-lg-3">
          <div v-show="idFuncionario != 0 && funcionario.dadosAdicionais.numeroDependentes > 0" v-b-modal.modal-38
            class="btn btn-default btn-file ml-3 mt-4">
            <i class="fa-solid fa-people-roof ml-2"></i> Cadastro de Dependentes
          </div>


        </div>
      </div>
    </div>
    <!-- <hr /> -->
    <div class="card-footer">
      <button @click.prevent="cadastroFuncionario" class="btn btn-primary float-right">
        <i class="fa-solid fa-floppy-disk"></i>
        Salvar Funcionário
      </button>
      <!-- <button v-if="idFuncionario != 0" @click.prevent="openModalEdicaoSenha"
        class="btn background-principal text-color-principal btn-primary float-left">
        <i class="fa-solid fa-lock"></i>
        Editar Senha
      </button> -->
      <button v-if="idFuncionario != 0" @click.prevent="imprimirFichaDeCadastro"
        class="btn background-principal text-color-principal btn-primary float-left ml-2">
        <i class="fa-solid fa-file-text"></i>
        Ficha de Cadastro
      </button>

      <!-- <button
        v-if="idFuncionario != 0"
        @click.prevent="imprimirFichaAnotacoes"
        class="btn background-principal text-color-principal btn-primary float-left ml-2" data-toggle="dropdown"
      >
        <i class="fa-solid fa-file-text"></i>
        Ficha de Anotações
      </button>
          <ul class="dropdown-menu" role="menu">
              <li><a class="dropdown-item" href="#comendereco"> Com Endereço </a></li>
              <li><a class="dropdown-item" href="#semendereco"> Sem Endereço</a></li>
               
               
          </ul> -->

    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { EventBus } from "@/main";
import cadastroFuncionarioService from "@/service/CadastroFuncionarioService";
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import ConsultaListasService from "@/service/ConsultaListasService";
import { email, maxLength, required } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PdfFichaCadastro from "@/components/administracao/funcionario/cadastro/PdfFichaCadastro.vue";
import PdfFichaAnotacoes from "@/components/rh/anotacao/PdfFichaAnotacoes.vue";
import RhDiariasService from '@/service/RhDiariasService';


export default {
  name: "FormCadastroFuncionaro",
  components: {
    Loading,
    PdfFichaCadastro,
    PdfFichaAnotacoes,
  },
  data() {
    return {
      idFuncionario: null,
      response: null,
      loaderActive: false,
      fullPage: false,
      id: "",
      nomePessoaFuncionario: "",
      funcionario: {
        funcionarioExterno: "false",
        policial: "false",
        ativo: "true",
        dadosAdicionais: {
          tipoContratacao: "",
          dataAdmissao: "",
          limiteDiarias: "",
        }
      },

      idFuncionario: this.$store.state.funcionario.idFuncionarioCadastrado,
      funcionarioInfo: {},



      selectBoolean: [
        { id: "true", descricao: "SIM" },
        { id: "false", descricao: "NÂO" },
      ],
      selectTipoFuncionario: [
        { id: "true", descricao: "Externo" },
        { id: "false", descricao: "Interno" },
      ],
      selectFuncionarioAtivoBoolean: [
        { id: "true", descricao: "Ativo" },
        { id: "false", descricao: "Não Ativo" },
      ],
      // ativo: false,
      // policial: false,
      // funcionarioExterno: true,
      // pessoaNome: undefined,
      // desabilitaBotaoSalvar: false,
      empresasContratante: [],
      // empresaContratante: "",
      unidadesDeOrigem: [],
      // unidadeDeOrigem: "",
      lotacaos: [],
      // lotacao: "",
      cargos: [],
      // cargo: "",
      funcaos: [],

      bancoGrupos: [],
      // funcao: "",
      // idFuncionario: "",
      bancos: [],
      // banco: "",
      fatorRhList: [
      { id: 1, descricao: "Positivo" },
      { id: 2, descricao: "Negativo" },
    ],
      // fatorRh: "",
      grupoSanguineoList: [
      { id: 1, descricao: "A" },
      { id: 2, descricao: "B" },
      { id: 3, descricao: "AB" },
      { id: 4, descricao: "O" },
    ],
      // grupoSanguineo: "",
    };
  },
  created() {

    this.listarGrupos();

    this.idFuncionario = this.$store.state.funcionario.idFuncionarioCadastrado;
    // EventBus.$on("fecharModalEPesquisar", (listener) => {
    //   this.id = listener;
    // }),
    //   EventBus.$on("editarFuncionario", (listener) => {
    //     this.funcionario = listener;
    //   }),
    this.carregarListas();
    this.validaFuncionario();
    //alert('ERRO: ' + e.message)
  },
  mounted() {
    // EventBus.$on("funcaoSalvar", this.funcaoSalvar);
  },
  methods: {

    atualizarAnexo(){
      EventBus.$emit("AtualizarAnexo")
    },

    atualizarDependentes() {
      EventBus.$emit("AtualizarDependentes")
    },

    listarGrupos() {
      RhDiariasService.getAllDiariaGrupo().then((resp) => {
        this.bancoGrupos = resp.data
      })
    },
    imprimirFichaAnotacoes() {


      consultaFuncionarioService.buscarFuncionarioId(this.$store.state.funcionario.idFuncionarioCadastrado)
        .then((response) => {
          this.funcionarioInfo = response.data
          console.log(this.funcionarioInfo);

          this.$root.$emit("EmitirPdfFichaAnotacoes");
        });

    },
    imprimirFichaDeCadastro() {
        this.$root.$emit("EmitirPdfFichaCadastro", this.funcionarioInfo);
    },
    openModalEdicaoSenha() {
      EventBus.$emit("openModalEdicaoSenha");
    },
    validaFuncionario() {
      this.$store.commit("funcionarioCadastrado", 0);
      this.nomePessoaFuncionario =
        this.$store.state.funcionario.nomePessoaFuncionario;
      this.$store.commit("nomePessoaFuncionario", "");

      if (this.$store.state.funcionario.funcionarioSalvo.id != undefined) {
        this.funcionario = this.$store.state.funcionario.funcionarioSalvo;

        this.funcionario.policial = this.funcionario.policial
          ? "true"
          : "false";
        //console.log(this.funcionario.funcionarioExterno);
        this.funcionario.funcionarioExterno = this.funcionario
          .funcionarioExterno
          ? "true"
          : "false";
        this.funcionario.ativo = this.funcionario.ativo ? "true" : "false";

        this.$store.commit("funcionarioCadastrado", this.funcionario.id);
      }

      this.$store.commit("funcionarioEditar", {});
    },
    cadastroFuncionario() {
      this.showLoader();
      this.funcionario.origemFuncionario = "SIGMA"
      this.funcionario.policial =
        this.funcionario.policial === "true" ? true : false;
      this.funcionario.pessoaCorrespondente = {
        id: this.$store.state.funcionario.idPessoaFuncionario,
      };
      this.funcionario.funcionarioExterno =
        this.funcionarioExterno === "true" ? true : false;
      this.funcionario.ativo = this.funcionario.ativo === "true" ? true : false;
      this.funcionario.cargo = this.funcionario.cargo
        ? this.funcionario.cargo
        : null;
      this.funcionario.funcao = this.funcionario.funcao
        ? this.funcionario.funcao
        : null;

      this.$v.funcionario.$touch();
      if (!this.$v.funcionario.$invalid) {
        if (this.funcionario.id) {
          cadastroFuncionarioService
            .atualizarFuncionario(this.funcionario)
            .then((response) => {
              this.$store.commit("funcionarioCadastrado", response.data.id);
              this.funcionario = response.data;

              this.funcionario.policial = this.funcionario.policial
                ? "true"
                : "false";

              this.funcionario.funcionarioExterno = this.funcionarioExterno
                ? "true"
                : "false";
              this.funcionario.ativo = this.funcionario.ativo
                ? "true"
                : "false";

              // this.$store.commit("funcionarioCadastrado", this.funcionario);
              // this.idFuncionario = response.data.id;
              this.alertaSucessoCadastro();
              // this.desabilitaBotaoSalvar = true;
              // this.envioIdFuncionarioCadastrado(this.idFuncionario);
              this.hideLoader();
              // this.$emit("cadastrouFuncionario");
              // EventBus.$emit("cadastrouFuncionario", this.idFuncionario);
            })
            .catch((e) => {
              this.hideLoader();
              this.alertaFalhaCadastro();
            });
        } else {
          if (this.$store.state.funcionario.existeFuncionarioAtivo) {
            this.$swal
              .fire({
                title:
                  "Todos os funcionarios ativos associados a " +
                  this.nomePessoaFuncionario +
                  " serão desativados",
                text: "Deseja continuar?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#28a745",
                confirmButtonText: "Continuar Cadastro",
                cancelButtonText: "Cancelar",
                reverseButtons: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  cadastroFuncionarioService
                    .cadastroFuncionario(this.funcionario)
                    .then((response) => {
                      this.$store.commit(
                        "funcionarioCadastrado",
                        response.data.id
                      );
                      this.funcionario = response.data;

                      this.funcionario.policial = this.funcionario.policial
                        ? "true"
                        : "false";

                      this.funcionario.funcionarioExterno = this
                        .funcionarioExterno
                        ? "true"
                        : "false";
                      this.funcionario.ativo = this.funcionario.ativo
                        ? "true"
                        : "false";

                      this.$store.commit("existeFuncionarioAtivo", false);

                      // this.$store.commit("funcionarioCadastrado", this.funcionario);
                      // this.idFuncionario = response.data.id;
                      this.alertaSucessoCadastro();
                      // this.desabilitaBotaoSalvar = true;
                      // this.envioIdFuncionarioCadastrado(this.idFuncionario);
                      this.hideLoader();
                      // this.$emit("cadastrouFuncionario");
                      // EventBus.$emit("cadastrouFuncionario", this.idFuncionario);
                    })
                    .catch((e) => {
                      this.hideLoader();
                      this.alertaFalhaCadastro();
                    });
                } else {
                  this.hideLoader();
                }
              });
          } else {
            cadastroFuncionarioService
              .cadastroFuncionario(this.funcionario)
              .then((response) => {
                this.$store.commit("funcionarioCadastrado", response.data.id);
                this.funcionario = response.data;

                this.funcionario.policial = this.funcionario.policial
                  ? "true"
                  : "false";

                this.funcionario.funcionarioExterno = this.funcionarioExterno
                  ? "true"
                  : "false";
                this.funcionario.ativo = this.funcionario.ativo
                  ? "true"
                  : "false";

                // this.$store.commit("funcionarioCadastrado", this.funcionario);
                // this.idFuncionario = response.data.id;
                this.alertaSucessoCadastro();

                if (this.$store.state.cadastroUsuario) {

                  this.$store.commit("pessoaUsuario", response.data.pessoaCorrespondente.id);
                  this.$store.commit("usuarioCadastrado", 0);
                  this.$router.push({ name: "cadastrousuario" });


                }
                // this.desabilitaBotaoSalvar = true;
                // this.envioIdFuncionarioCadastrado(this.idFuncionario);
                this.hideLoader();
                // this.$emit("cadastrouFuncionario");
                // EventBus.$emit("cadastrouFuncionario", this.idFuncionario);
              })
              .catch((e) => {
                this.hideLoader();
                this.alertaFalhaCadastro();
              });
          }
        }
      } else {
        this.hideLoader();
      }
    },
    carregarListas() {
      ConsultaListasService.buscarListaPorNomeTabela("banco")
        .then((response) => {
          this.bancos = response.data;
        })
        .catch((e) => this.alertaFalhaBuscarBanco());

      ConsultaListasService.buscarEmpresas()
        .then((response) => {
          this.empresasContratante = response.data;
        })
        .catch((e) => this.alertaFalhaBuscarEmpresas());

      ConsultaListasService.buscarListaPorNomeTabela("unidade")
        .then((response) => {
          this.unidadesDeOrigem = response.data;
        })
        .catch((e) => this.alertaFalhaBuscarUnidadesDeOrigem());

      ConsultaListasService.buscarListaPorNomeTabela("cargo")
        .then((response) => {
          this.cargos = response.data;
        })
        .catch((e) => this.alertaFalhaBuscarCargo());

      ConsultaListasService.buscarListaPorNomeTabela("unidade")
        .then((response) => {
          this.lotacaos = response.data;
        })
        .catch((e) => this.alertaFalhaBuscarLotacao());

      ConsultaListasService.buscarListaPorNomeTabela("funcao")
        .then((response) => {
          this.funcaos = response.data;
        })
        .catch((e) => this.alertaFalhaBuscarFuncao());
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    alertaSucessoCadastro() {
      Toast.fire("Sucesso ao cadastrar funcionário", "", "success");
    },
    alertaFalhaCadastro() {
      Toast.fire("Falha ao cadastrar funcionário", "", "error");
    },
    alertaFalhaBuscarEmpresas() {
      Toast.fire("Falha ao buscar empresas", "", "error");
    },
    alertaFalhaBuscarUnidadesDeOrigem() {
      Toast.fire("Falha ao buscar unidades de origem", "", "error");
    },
    alertaFalhaBuscarCargo() {
      Toast.fire("Falha ao buscar cargo", "", "error");
    },
    alertaFalhaBuscarLotacao() {
      Toast.fire("Falha ao buscar lotação", "", "error");
    },
    alertaFalhaBuscarFuncao() {
      Toast.fire("Falha ao buscar função", "", "error");
    },
    alertaFalhaBuscarBanco() {
      Toast.fire("Falha ao buscar banco", "", "error");
    },
  },
  validations: {
    funcionario: {
      matricula: {
        maxLength: maxLength(50),
      },

      novaMatricula: {
        required,
        maxLength: maxLength(50),
      },

      emailFuncional: {
        email,
      },
      emailRecuperacao: {
        email,
      },
      dadosAdicionais: {
        dataAdmissao: {
          required,
        },
        tipoContratacao: {
          required,
        },
      },
      policial: {
        required,
      },
      funcionarioExterno: {
        required,
      },
      ativo: {
        required,
      },
      ctps: {},
      unidadeOrigem: {
        required,
      },
      lotacao: {
        required,
      },
    },
  },
};
</script>
