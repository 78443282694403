<template>
     <div class="card-body pt-0">
        <div class=" p-1, .ml-1 (margin-right: 4px;)">
          <FiltroTabelaAtaRegistro/>
         
        </div>
      
      
     <Loader :active="loaderActive" message="Consultando..." />
    <table v-show="!loaderActive" class="table table-hover text-nowrap">
      
      <thead>
        <tr>
          <th>Id de Contratação</th>
          <th>Numero</th>
          <th>Ano</th>
          <th>Valor</th>
          <th>Data de Inicio</th>
          <th>Data Fim</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr class= "clicavel" @click="funcao(ata.id)" v-for = "(ata, index) in atasRegistro" :key="index">
       
          <td > {{ata.idContratacao || "Sem registro"}}</td>
          <td >{{ata.numero || "Sem registro"}} </td>
          <td >{{ata.ano || "Sem registro"}} </td>
          <td >{{ata.valor || "Sem registro"}} </td>
          <td >{{updateZonedDateTimeField(ata.dataInicio)}} </td>
          <td >{{updateZonedDateTimeField(ata.dataFim ) }}</td>
          <td @click.stop>
            <button @click.prevent="downloadFile(ata.id)" type="button" style="border:none;!important" title="Download do Arquivo" class="btn btn-default btn-sm gerar">
              <i class="fa-solid fa-file"></i>
            </button>
            <button @click.prevent="excluir(ata.id)" type="button" style="border:none;!important" title="Excluir Arquivo" class="btn btn-default btn-sm excluir">
              <i class="fa-solid fa-trash"></i>
            </button>
          </td>
          <!--td @click.stop>
            <button @click.prevent="excluir(ata.id)" type="button" class="btn btn-default btn-sm excluir">
              <i class="fa-solid fa-trash"></i>
            </button>
          </td-->

        </tr>
      </tbody>
    </table>
    </div>

</template>

<script>

import processosService from '@/service/ProcessosService';
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import { EventBus } from '@/main';
import Loader from "../Loader.vue";
import FiltroTabelaAtaRegistro from "@/components/processos/FiltroTabelaAtaRegistro.vue";

import axios from 'axios';

export default ({
    name: 'TblAtaRegistro',
    components:{
      Loader,
      FiltroTabelaAtaRegistro
    },
    data(){
      return{
        loaderActive:false,
        atasRegistro:{},
        ata:{},
        ataFiltro:{},
      } 
    },



    methods:{
     
      showLoader() {
        this.loaderActive = true;
      },
      hideLoader() {
        this.loaderActive = false;
      },
      funcao(ata){
        EventBus.$emit("AlterarTabAtaRegistro")
        EventBus.$emit("EnvioAtaRegistro",ata)
        
        

      },
        enviarFormulario(){
            this.dados.push({
                idContratacao: this.idContratacao,
                numero: this.numero,
                ano: this.ano,
                valor: this.valor,
                dataInicio: this.dataInicio,
                dataFim: this.dataFim,
            })
        },

      updateZonedDateTimeField(field) {
        return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
      },

       downloadFile(id) {
              axios({
                    url: `http://localhost:8089/api/download/ata-registros/${id}`, // File URL Goes Here
                    method: 'GET',
                   responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'ata.json');
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
      },

      excluir(id){
          this.$swal.fire({
          title: "Você deseja excluir ata?",
          icon:"warning",
          showCancelButton: true,
          confirmButtonColor:"#3085d6",
          confirmButtonText: "#Excluir",
          cancelButtonText: "Cancelar",
          
          }).then((result) => {
            if(result.isConfirmed){
              processosService.apagarAtaRegistro(id).then((response) => {
              this.atasRegistro = response.data;
              Toast.fire("Ata excluída com sucesso!", "","success");
              this.buscarAtaRegistro();
              
              });
            }
            this.buscarAtaRegistro(0);


          });
        
      },
      buscarAtaRegistro(){

        processosService.buscarAtaRegistro(this.atasRegistro).then((resp)=>{
              this.atasRegistro= resp.data;
        })
      },
    },
      
        
    
//     filters: {
//     formatarData: function (data) {
//       if (data == "" || data == null) {
//         return 'Sem data registrada';
//       }
//       else {
// //
//         const [date, horas] = data.split("T");
//        const[ano,mes,dia]=date.split("-");
//         const[hr,min,sec]=horas.split(":");


//         const dataHoraFormatada = `${dia}/${mes}/${ano} ${hr}:${min}:${sec.replace("Z","")}`;
     
//         return dataHoraFormatada;
//       }
//     },
//   },

  



   
  created(){
      this.downloadFile();
      this.showLoader()
      processosService.buscarAtaRegistro().then((resp)=>{
          this.atasRegistro= resp.data;
          this.hideLoader()
        }).catch((e) =>{
          this.hideLoader()
          
          Toast.fire("Erro ao consultar Atas", "", "error");

        } );

      EventBus.$on("CancelarAtaRegistro");

      EventBus.$on("consultaata",(listener)=>{
        this.ataFiltro=listener;
          this.buscarAtaRegistro();
        });
        
  },

});


</script>

<style scoped>

.clicavel{
  cursor: pointer;
}

</style>
