<template>
  
    <div class="card card-primary card-tabs ">
      <ata :key="ata_reload"/>
      <div class="card-header background-principal p-0 pt-1  ">
        <ul class="nav nav-tabs"   id="custom-tabs-one-tab" role="tablist">

          <li class="nav-item" @click="reRender()">
              <a class="nav-link active show" 
              id="custom-tabs-one-home-tab"  @click.prevent="apagarCampos" data-toggle="pill" href="#tabelaatasregistro" role="tab"
              ref="tabelaatasregistro" aria-controls="custom-tabs-one-home" aria-selected="true" @click="reRender()"
              >Tabela AtaRegistro </a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
              id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#cadastroatasregistro" role="tab"
              aria-controls="custom-tabs-one-profile" aria-selected="false" ref="cadastroatasregistro"
             >Cadastro AtaRegistro</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        
        <div class="tab-content" id="custom-tabs-one-tabContent" >
          <div class="tab-pane fade active show" id="tabelaatasregistro" role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab"  >
             
             <TblAtaRegistro :key="ata_reload" />
          </div>
          
          <div class="tab-pane fade" id="cadastroatasregistro" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
            <FormAtaRegistro/>
          </div>
        </div>
      </div>

      
    </div>
  
</template>
<script>
import TblAtaRegistro from "@/components/processos/TabelaAtaRegistro.vue";
import FormAtaRegistro from "@/components/processos/CadastroAtaRegistro.vue";
import { EventBus } from "@/main";
export default {
  
  name: "TabAtaRegistro",
  components: { TblAtaRegistro, FormAtaRegistro },
  data() {
    return {
      showTabelaAtaRegistro:false,
    
      ata_reload:1,


    }
  },
  created() {
      EventBus.$on("AlterarTabAtaRegistro",()=>{
        this.$refs.cadastroatasregistro.click()
        
      })

  },
  methods: {
    apagarCampos(){
      EventBus.$emit("ApagarCamposAtaRegistro")

    },

    reRender(){
      this.ata_reload++;
      
    }

    

    
  }

  
}
</script>
<style scoped>
.text-link {
  color: #fff
}

.text-link:hover {
  color: #fff
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}
</style>

 