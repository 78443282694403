import Vue from "vue";
import VueGates from "vue-gates";

Vue.use(VueGates);

var userPerfis = [];
class PermissoesService {
  userPermissions() {
    const user = JSON.parse(localStorage.getItem("user"));
    const userWithPermissions = this.parseJwt(user.access_token);
    var userPerfisObject = [];

    if (userWithPermissions) {
      userPerfisObject = userWithPermissions.perfis;
      userPerfisObject.forEach((object) => {
        userPerfis.push(object);
      });
    }

    return Vue.gates.setRoles(userPerfis);
  }

  parseJwt(token) {
    const prefixo = "Bearer ";
    if (token.startsWith(prefixo)) {
      token = token.substring(0, prefixo.length);
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
}

export default new PermissoesService();
