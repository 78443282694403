import { ApiGateway } from "@/main";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

class TokenService {
  async resolveTokenNacional() {
    // const tokenLocal = localStorage.getItem("tokenNacional");
    // // if (tokenLocal != null && this.tokenValido(tokenLocal)) {
    // //   return tokenLocal;
    // // }
    // await this.buscarTokenNacional();
    // return localStorage.getItem("tokenNacional");
  }

  tokenValido(token) {
    if (token) {
      const tokenFormatado = this.parseJwt(token);
      const expEmSegundos = tokenFormatado.exp * 1000;


      if (expEmSegundos >= new Date().getTime()) {
        return true;
      }
      return false;
    }
    return false;
  }

  async buscarTokenNacional() {
    // const authorization = JSON.parse(localStorage.getItem("user")).token;
    // const config = {
    //   method: "get",
    //   url: `${ApiGateway}/api/auth/nacional/token`,
    //   headers: {
    //     Authorization: `Bearer ${authorization}`,
    //   },
    // };

    // await axios(config)
    //   .then((response) => {
    //     localStorage.setItem("tokenNacional", response.data.tokenNacional);
    //   })
    //   .catch(function (error) {
    //     alert("Erro ao gerar token nacional");
    //   });

    return localStorage.getItem("tokenNacional");
  }

  parseJwt(token) {
    // const prefixo = "Bearer ";
    // if (token.startsWith(prefixo)) {
    //   token = token.substring(0, prefixo.length);
    // }
    // const base64Url = token.split(".")[1];
    // const base64Url = token;
    // const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    // const jsonPayload = decodeURIComponent(
    //   window
    //     .atob(base64)
    //     .split("")
    //     .map(function (c) {
    //       return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    //     })
    //     .join("")
    // );

    const jsonPayload = jwtDecode(token);

    // return JSON.parse(jsonPayload);
    return jsonPayload;
  }
}

export default new TokenService();
