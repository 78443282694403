// import axios from "axios";
// const baseApiUrl = "http://172.20.2.90:8086/api/";
import qs from "qs";
import instance from "./axiosConfig";

class DocumentoService {

  criarDocumento(entity, lista, numeracao, assinar) {
    // console.log("criarDocumento inputs: ", entity, lista, numeracao, assinar);
    // return axios.post(`${baseApiUrl}` + 'documentos', entity, { headers: authHeader() }); { headers: authHeader() }, { params: { listaCopia: JSON.parse(lista) } }, {data: entity}
    return instance.post("/api/documentos", entity, {
      params: {
        listaCopia: lista,
        numeracao: numeracao,
        assinar: assinar,
      },
      paramsSerializer: (params) => {
        let retornoParam = qs.stringify(params, { indices: false });
        return retornoParam;
      },
    });
  }

  apagarDocumento(id) {
    return instance.delete(`/api/documentos/${id}`);
  }

  update(entity) {
    return instance.put("/api/documentos/" + entity.documentoDTO.id, entity);
  }
  buscarSetor() {
    return instance.get("/api/documentos/setors/ssp");
  }
  buscarFuncionariosetor(id) {
    return instance.get("/api/documentos/funcionarios/setor/" + id);
  }
  buscarFuncionario() {
    return instance.get("/api/documentos/funcionarios");
  }
  buscarFecho() {
    return instance.get("/api/documentos/fecho");
  }
  buscarFuncao() {
    return instance.get("/api/documentos/funcaos?size=200");
  }
  buscarTratamento() {
    return instance.get("/api/documentos/tratamentos?size=200");
  }
  visualizarDocumento(IdDocumento,ipUsuario) {
 
    return instance.get("/api/documentos/visualizar/" + IdDocumento+`?plataforma=SITE&ipUsuario=${ipUsuario}`, {
      responseType: "blob",
    });
  }
  buscarHistoricoDocumento(IdDocumento) {
    return instance.get("/api/documentos/historico/" + IdDocumento);
  }
  buscarDocumentosRecebidos(pagina) {
    return instance.get("/api/documentos/recebidos", {
      params: {
        page: pagina,
      },
    });
  }
  buscarDocumentosEnviados(pagina) {
    return instance.get("/api/documentos/enviados", {
      params: {
        page: pagina,
      },
    });
  }
  buscarDocumentosRascunhos(pagina) {
    return instance.get("/api/documentos/rascunhos", {
      params: {
        page: pagina,
      },
    });
  }
  buscarDocumentosCompartilhados(pagina) {
    return instance.get("/api/documentos/compartilhados", {
      params: {
        page: pagina,
      },
    });
  }
  compartilharDocumento(idDocumento, idFuncionario, idSetor){
    return instance.patch(`/api/documentos/compartilhar/${idDocumento}/${idFuncionario}/${idSetor}`)
  }
  buscarDocumentosArquivados(pagina) {
    return instance.get("/api/documentos/arquivados", {
      params: {
        page: pagina,
      },
    });
  }
  buscarDocumento(IdDocumento) {
    return instance.get("/api/documentos/" + IdDocumento);
  }
  buscarDocumentoUsarModelo(IdDocumento) {
    return instance.get("/api/documentos/modelo/" + IdDocumento);
  }
  assinarDocumento(numeracao, IdDocumento, senha) {
    return instance.get(
      "/api/documentos/verifica_assinatura/" + IdDocumento,
      {
        params: {
          senha: senha,
          numeracao: numeracao,
        },
      }
    );
  }
  pesquisarDocumento(entity, pagina) {
    return instance.post("/api/documentos/consulta", entity, {
      params: {
        page: pagina,
      },
    });
  }
  arquivarDocumento(entity) {
    return instance.post("/api/documentos/arquivar", entity);
  }
  desarquivar(IdDocumento, senha) {
    return instance.get(
      "/api/documentos/desarquivar/" + IdDocumento + "/" + senha
    );
  }
  verificarNumerecao(tipoDocumento) {
    return instance.get("/api/documentos/verificar/" + tipoDocumento);
  }

  verificarFuncionarioAssinatura(IdDocumento) {
    return instance.get("/api/documentos/verificar_funcionario_assinatura/" + IdDocumento);
  }
  verificarCountDocumento() {
    return instance.get("/api/documentos/countDocumentos");
  }

  contabilizarDocumentosRascunho() {
    return instance.get("/api/documentos/countDocumentosRascunho");
  }


}

export default new DocumentoService();
