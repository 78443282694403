<template>
  <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Cadastro de pessoa física" />
    <conteudo v-role="'CADASTRO_PESSOA' && 'ADMINISTRACAO'">
      <ModalConsultaPessoa ref="modalConsulta" :visible="isModalVisible" />
      <TabsCadastroPessoa />
    </conteudo>
    
  </section>
</template>

<script>
import ModalConsultaPessoa from "./ModalConsultaPessoa.vue";
import TabsCadastroPessoa from "./TabsCadastroPessoa.vue";

import { mapState } from 'vuex';

export default {
  components: { ModalConsultaPessoa, TabsCadastroPessoa },
  data() {
    return {
      isModalVisible: true,
      pessoa: {},
    };
  },
  created() {

    // if(this.cadastroPessoaExterno){
    //   this.isModalVisible = false;
    // }
    this.isModalVisible = !this.cadastroPessoaExterno;
    
  },
  computed: {
    ...mapState(['cadastroPessoaExterno']),
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
    },
  }
}
</script>
