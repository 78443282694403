<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>404 Error Page</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="error-page">
        <h2 class="headline text-warning">404</h2>

        <div class="error-content">
          <h3>
            <i class="fas fa-exclamation-triangle text-warning"></i>Oops! Página
            não encontrada.
          </h3>

          <p>
            A página procurada não existe, entre em contato com o suporte
            <a href="tel:(98) 3214-3772">(98) 3214-3772</a>.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
  <script>
export default {
  name: "Found401View",
  created() {
    //   this.error();
  },
  methods: {
    //   error() {
    //     Toast.fire("Redirecionando", "", "error");
    //   },
  },
};
</script>
