<template>
  <div>
    <!-- <button id="emitirAtestado" @click="emitirAtestado()">
        Emitir Atestado
      </button> -->
    <iframe
      hidden
      class="preview-pane"
      style="width: 1000px; height: 95vh; margin: 0px; padding: 0px"
      :src="pdfSrc + '#zoom=110'"
    >
    </iframe>

    <!-- <img
      src="@/assets/images/logo-ma-small.png"
      style="display: none"
      ref="brasaoImg"
      class="brasaoImg"
    /> -->
  </div>
</template>
  
  <script>
import jsPDF from "jspdf";
// eslint-disable-next-line no-unused-vars
import autoTable from "jspdf-autotable";
// import { data } from "@/components/service.js";

export default {
  name: "PdfFichaAnotacoes",
  components: {},
  props: {},
  data() {
    return {
      pdfSrc: "data:application/pdf;base64,",
      logoBase64: "",
      value: this.values,
      size: 300,
      atestado: {},
      qrcodeValue: "",
    };
  },
  setup() {},
  methods: {
    montarParagrafo(textoDados) {
      let texto = `REQUISIÇÃO DE DIÁRIAS N.° ${textoDados} `;
      return texto;
    },
    emitirPdf() {
      let doc = new jsPDF("p", "mm", "a4");
      const pageWidth = doc.internal.pageSize.getWidth();
      const center = pageWidth / 2;
      //   let colunaAtual = 1;
      //   const pageHeight = doc.internal.pageSize.getHeight();

      const image = new Image();
      //   image.src = this.$refs.brasaoImg.src;
      image.src = require("@/assets/logo-ma-small.png");
      image.width = 20;
      image.height = 20;
      let x = (pageWidth - image.width) / 2;
      doc.addImage(image, "PNG", x, 10, 20, 20, "center");

      doc.setTextColor(0);
      doc.setFont("times", "bold");
      doc.setFontSize(12);
      doc.text("Estado do Maranhão", center, 35, null, null, "center");
      //   console.log(doc.getFontList());
      doc.setFont("times", "normal");
      doc.setFontSize(9);
      doc.text(
        "Secretaria de Estado da Segurança Pública",
        center,
        39,
        null,
        null,
        "center"
      );

      doc.text(
        "Av. dos Franceses, s/n, Bairro Vila Palmeira, CEP 65036-283, São Luís-MA",
        center,
        43,
        null,
        null,
        "center"
      );
      doc.text("Telefone: (98) 3214 3700", center, 47, null, null, "center");

      doc.setFont("times", "bold");
      doc.setFontSize(14);
      doc.text("FICHA DE ANOTAÇÕES", center, 60, null, null, "center");

      let lastY = 75;
      doc.setFontSize(12);
      doc.text("Matrícula:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Nome:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Lotação:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Cargo:", 40, lastY, null, null, "right");

      lastY = 75;
      doc.text("1850387", 41, lastY, null, null, "left");
      lastY += 5;
      doc.text(
        "JONATHAN MONTALVANE SILVA FERREIRA",
        41,
        lastY,
        null,
        null,
        "left"
      );
      lastY += 5;
      doc.text(
        "UNIDADE DE DESENVOLVIMENTO E ARTICULACAO INSTITUCIONAL",
        41,
        lastY,
        null,
        null,
        "left"
      );
      lastY += 5;
      doc.text(
        "ESCRIVÃO DE POLICIA CLASSE B REF. 5",
        41,
        lastY,
        null,
        null,
        "left"
      );

      this.inserirLinhasAssinatura(doc);

      // Definindo os cabeçalhos da tabela
      const headers = [["TIPO", "DATA", "DOC", "OBSERVAÇÃO"]];

      const data = [
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
        [
          "Lotação",
          "12/05/2009",
          "PORTARIA 313/2009",
          "Informação: Conf. Port. nº 313/09-DG/PC/MA. de 12.05.09, Lotar o servidor na Delegacia da Mulher de Viana, pertencente ´Delegacia Regional de Viana, a considerar de 07.04.09. (mbs). \nObs:",
        ],
      ];

      // Definindo a posição inicial da tabela
      const tableY = 100;

      // Definindo a opção de layout da tabela (opcional)
      const tableOptions = {
        startY: tableY, // Posição Y onde a tabela será renderizada
        theme: "grid",
        margin: { top: 30, left: 10, bottom: 30 },
        styles: {
          font: "times",
          fontSize: 11,
          valign: "top",
          lineWidth: 0.1,
          lineColor: 60,
          textColor: 0,
        }, // Estilos da tabela
        headStyles: {
          fontStyle: "bold",
          fillColor: [255, 255, 255],
          textColor: 0,
          halign: "center",
        }, // Estilos do cabeçalho
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 25 },
          2: { cellWidth: 40 },
          3: { cellWidth: 100 },
        }, // Largura das colunas
      };

      // Adicionando a tabela ao documento
      doc.autoTable({
        head: headers,
        body: data,
        ...tableOptions,
      });

      // this.inserirLinhasAssinatura(doc);
      let totalPages = doc.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        this.inserirLinhasAssinatura(doc);
        if (i != 1) {
          doc.setPage(i);
          this.inserirPaginas(
            doc,
            center,
            "JONATHAN MONTALVANE SILVA FERREIRA"
          );
        }
      }

      //   var out = doc.output();
      //   var url = "data:application/pdf;base64," + btoa(out);
      //   this.pdfSrc = url;

      doc.save("Ficha de Anotacoes");
    },
    inserirPaginas(doc, center, nomeFuncionario) {
      // console.log(doc.internal.getCurrentPageInfo());
      let currentPage = doc.getCurrentPageInfo().pageNumber;
      doc.text(
        `(Fls. ${currentPage} da Ficha Funcional de ${nomeFuncionario})`,
        center,
        20,
        null,
        null,
        "center"
      );
    },

    inserirLinhasAssinatura(doc) {
      let x;
      doc.setLineWidth(0.5);
      doc.line(20, 280, 95, 280, "F");
      doc.line(115, 280, 190, 280, "F");
      doc.setFontSize(9);
      doc.setFont("times", "bold");
      let text = "Assinatura 1";
      let textWidth =
        (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (115 - textWidth) / 2;
      doc.text(text, x, 285);
      text = "Cargo 1";
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (115 - textWidth) / 2;
      doc.text(text, x, 290);

      text = "Assinatura 2";
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (305 - textWidth) / 2;
      doc.text(text, x, 285);
      text = "Cargo 2";
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (305 - textWidth) / 2;
      doc.text(text, x, 290);

      doc.setFont("times", "normal");
    },
    justifyLine(doc, text, x, y, maxWidth) {
      const words = text.split(" ");
      const numWords = words.length;
      const totalWidth = doc.getTextWidth(text);
      const spaceWidth = (maxWidth - totalWidth) / (numWords - 1);

      let currentX = x;
      words.forEach((word, index) => {
        doc.text(word, currentX, y);
        currentX += doc.getTextWidth(word) + spaceWidth;

        if (index < numWords - 1) {
          doc.text(" ", currentX, y); // Adicionar espaço entre as palavras
          currentX += doc.getTextWidth(" ");
        }
      });
    },

    getMesPorExtenso(mesInteger) {
      const mesDictionary = {
        1: "Janeiro",
        2: "Fevereiro",
        3: "Março",
        4: "Abril",
        5: "Maio",
        6: "Junho",
        7: "Julho",
        8: "Agosto",
        9: "Setembro",
        10: "Outubro",
        11: "Novembro",
        12: "Dezembro",
      };
      return mesDictionary[mesInteger];
    },
  },
  mounted() {
    // this.emitirPdf();
    this.$root.$on("EmitirPdfFichaAnotacoes", () => {
      this.emitirPdf();
    });
  },
};
</script>
  


