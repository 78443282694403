<template>
  <div class="d-flex justify-content-center m-5 p-5">
    <div class="card card-primary">
      <div class="card-header p-0 d-flex justify-content-center background-principal text-color-principal">
        <div class="w-25">
          <a href="/">
            <img src="../assets/logo_ssp_login.png" :alt="$store.state.app.name"
              class="img-responsive py-2 px-4 w-100" />
          </a>
        </div>

      </div>
      <div class="card-body">
        <div class="p-5 d-flex justify-content-center col-12">
          <h2 class="text-color-secundary">{{ resultado || 'Resposta não recebida =(' }}</h2>
        </div>

      </div>

      <div class="card-footer">
        <button @click.prevent="redirecionarParaLogin" v-on:keyup.enter="redirecionarParaLogin"
          class="btn background-principal text-color-principal">
          <i class="fas fa-sign-out-alt"></i>
          Voltar ao Login
        </button>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: "ResultadoRecuperacaoSenhaComponent",
  props: {
    resultado: {
      type: String
    }
  },
  methods: {
    redirecionarParaLogin() {
      window.location.href = "/login"
    }
  }
}
</script>
