var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"resultado-idContratacao"}},[_vm._v("Id Contratacao")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.resultado.idContratacao),expression:"resultado.idContratacao"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.resultado.idContratacao.$error,
          },attrs:{"type":"text","name":"idContratacao","id":"resultado-idContratacao","data-cy":"idContratacao"},domProps:{"value":(_vm.resultado.idContratacao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.resultado, "idContratacao", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"resultado-licitacao"}},[_vm._v("Licitação")]),_c('v-select',{staticClass:"border-v-select",class:{
          'is-invalid': _vm.isSaving && _vm.$v.resultado.licitacao.$error,
        },attrs:{"id":"resultado-licitacao","data-cy":"licitacao","name":"licitacao","options":_vm.licitacao,"reduce":function (licitacao) { return licitacao.id; },"label":"descricao"},model:{value:(_vm.resultado.licitacao),callback:function ($$v) {_vm.$set(_vm.resultado, "licitacao", $$v)},expression:"resultado.licitacao"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"resultado-data"}},[_vm._v("Data")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.resultado.data.$error,
                    },attrs:{"type":"date","id":"resultado-data","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.resultado.data)},on:{"change":function($event){return _vm.updateZonedDateTimeField('data', $event)}}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"resultado-valor"}},[_vm._v("Valor")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.resultado.valor),expression:"resultado.valor"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.resultado.valor.$error,
          },attrs:{"type":"number","name":"valor","id":"resultado-valor","data-cy":"valor"},domProps:{"value":(_vm.resultado.valor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.resultado, "valor", $event.target.value)}}})])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn text-color-principal background-principal ml-1",on:{"click":_vm.salvarResultado}},[_c('i',{staticClass:"far fa-envelope"}),_vm._v(" Enviar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":_vm.limparResultado}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Limpar ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }