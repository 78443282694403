<template>
  <div>
      

        <div class="row px-3">
           <!-- <div class="col-sm-8">
            <div class="form-group">
              <label for="anotacao-codigo">Codigo</label>
              <input type="text" class="form-control form-control-border border-width-2" id="anotacao-codigo"
                v-model="anotacao.codigo" />
            </div>
          </div> -->

          <div class="col-sm-3">
            <div class="form-group">
              <label for="anotacao-nome">Nome</label>
              <input type="text" class="form-control form-control-border border-width-2" id="anotacao-nome"
                v-model="anotacao.descricao" />
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label for="anotacao-observacao1">Observação 1</label>
              <input type="text" class="form-control form-control-border border-width-2" id="anotacao-observacao1"
                v-model="anotacao.obs1" />
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label for="anotacao-observacao2">Observação 2</label>
              <input type="text" class="form-control form-control-border border-width-2" id="anotacao-observacao2"
                v-model="anotacao.obs2" />
            </div>
          </div>

          <div class="form-group col-lg-3">
            <label 
            class="form-control-label mt-1"
            for="anotacao-presenteBoletim">Presente no Boletim: </label>
            <v-select
              class="border-v-select"
              id="anotacao-presenteBoletim"
              :class="{
              'is-invalid': isSaving && $v.anotacao.presenteBoletim.$error,
              }" data-cy="presenteBoletim" name="presenteBoletim" 
              v-model="anotacao.boletim" 
              :options="presenteBoletim"
              :reduce="presenteBoletim => presenteBoletim.value" 
              label="descricao"
              >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
        </div>

      <div class="card-body">
        <button @click.prevent="LimparTipo" type="reset" class="btn btn-default ml-1">
          <i class="fas fa-ban"></i> Limpar
        </button>
        <div class="float-right">
          <button @click="SalvarTipo" class="btn text-color-principal background-principal ml-1">
            <i class="fa fa-save"></i> Salvar
          </button>
          
        </div>
      </div>
      <ConsultaTipos/>
  </div>
</template>

<script>
import ConsultaTipos from '@/components/rh/configuracao/ConsultaTipos.vue';
import RhDiariasService from '@/service/RhDiariasService';
import { EventBus } from '@/main';
import AnotacaoFeriasVue from '../ferias/AnotacaoFerias.vue';
export default {
  components:{
      ConsultaTipos
  },

  data(){

      return{
          data:{},
          anotacao:{
            descricao: null,
            obs1:null,
            obs2:null,
            boletim:null
          },
          submitted: false,
          isSaving: false,
          presenteBoletim:[
              { value : true, descricao: "Sim" },
              { value : false, descricao: "Não" },
          ],
      }
  },

  validations:{
/*       anotacao:{
          codigo:{},
          nome:{},
          obs1:{},
          obs2:{},
          presenteBoletim:{},
      } */
      
  },

  created(){
    EventBus.$on("selecionarTipoAnotacao",(id)=>{
      this.TipoAnotacao(id)
    })
  },


  methods: {

      SalvarTipo(){
        if('id' in this.anotacao){
          RhDiariasService.editarTipoAnotacao(this.anotacao.id,this.anotacao).then((r)=>{
            RhDiariasService.getTipoAnotacao(this.anotacao.id);
            Toast.fire("Sucesso ao editar Tipo de anotação", "", "success");
          }).catch(()=>{
            Toast.fire("Falha ao editar", "", "error")
          })
        }else{
          RhDiariasService.cadastrarTipoAnotacao(this.anotacao)
          .then((response)=>{
            this.anotacao=response.data;
            Toast.fire("Cadastro realizado", "", "success");
            this.anotacao={}
          })
        }
        
   
      },

      LimparTipo(){
          this.anotacao={};
      },

      TipoAnotacao(id){
        RhDiariasService.getTipoAnotacao(id)
        .then((response)=>this.anotacao=response.data)
      },
      ExcluirTipo(){
        RhDiariasService.DeletarTipoAnotacao()
        
      },
  },
}
</script>
