



<template>
    <div>
        <div class="row px-3">
            <div class="form-group col-lg-3">
                <label for="tipo">Tipo</label>
                <v-select
                class="border-v-select mt-1"
                id="tipo"
                v-model="ordenador.tipo"
                :reduce="tipos=>tipos.v"
                :options="tipos"
                label="descricao"
                >
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
            </div>
            <div class="form-group col-lg-3">
                <label for="nome">Nome</label>
                <input
                type="text"
                class="form-control form-control-border"
                id="nome"
                v-model="ordenador.nome"

                />
            </div>
            <div class="form-group col-lg-3">
                <label for="obs">Observação</label>
                <input
                type="text"
                class="form-control form-control-border"
                id="nome"
                v-model="ordenador.observacao"

                />
            </div>
            <div class="form-group col-lg-3">
                <label for="status">Status</label>
                <v-select
                class="border-v-select mt-1"
                id="status"
                v-model="ordenador.status"
                :options="status"
                :reduce="status=>status.valor"
                label="descricao"
                >
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
            </div>
        </div>
        <div class="card-body" style="
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
">
            <button @click.prevent="LimparOredenador" type="reset" class="btn btn-default" style="
    margin-left: 20px;
"> 
                <i class="fas fa-ban"></i> Limpar
            </button>
            <div class=" float-right">
                <button @click="salvarOrdenador" class="btn text-color-principal background-principal ml-1" style="
    margin-right: 20px;
">
                    <i class="fas fa-save"></i> Salvar
                </button>
<!--                 <button @click="ExcluirOrdenador" class="btn btn-default ml-1">
                    <i class="fas fa-trash"></i> Excluir
                </button> -->
                
            </div>
        </div>


        <div class="card-body pt-5">
            <table  class="table table-hover table-striped table-responsive-sm" >
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Nome</th>
                        <th>Observação</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr @click="escolherOrdenador(ordenadoresDeDespesa.id)" v-for="(ordenadoresDeDespesa, i) in ordenadores" :key="i" class="clicavel">
                        <td>{{ ordenadoresDeDespesa.tipo}}</td>
                        <td>{{ ordenadoresDeDespesa.nome }}</td>
                        <td>{{ ordenadoresDeDespesa.observacao }}</td>
                        <td>{{ ordenadoresDeDespesa.status ? "Ativo" :"Inativo" }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import RhDiariasService from "@/service/RhDiariasService"

export default {
    data(){
        return{
            ordenador:{
                nome:null,
                tipo:null,
                observacao:null,
                status:null,

            },
            ordenadores:[],
            tipos:[{descricao:"Delegacia Geral", v:"DELEGACIA_GERAL"}, {descricao:"Gabinete da SSP", v:"GABINETE_DE_SSP"}],
            status:[{valor:true, descricao:"Ativo"},{valor:false,descricao: "Inativo"}],
        }
    },
    created(){
        this.listarOrdenadores()
    },

    methods:{
        listarOrdenadores(){
            RhDiariasService.getAllOrdenadorDespesa()
            .then((response)=>this.ordenadores=response.data)
        },
        salvarOrdenador(){
            if ('id' in this.ordenador){
                RhDiariasService.editarOrdenadorDespesa(this.ordenador).then((r)=>{
                    RhDiariasService.getOrdenadorDespesa(this.ordenador.id);
                    Toast.fire("Sucesso ao editar Ordenador", "", "success");
                }).catch(()=>{
                    Toast.fire("Falha ao editar Ordenador", "", "error");
                })
            }else {
                RhDiariasService.cadastrarOrdenadorDespesa(this.ordenador)
                .then((response)=>{
                    this.ordenador =response.data;
                    Toast.fire("Cadastro realizado", "", "success");
                    this.listarOrdenadores();
                }).catch(()=>{
                    Toast.fire("Falha ", "", "error");
                })
            }
            this.LimparOredenador()
            this.listarOrdenadores()
        },
        
        ExcluirOrdenador(){},
        LimparOredenador(){
            this.ordenador={}
        },
        escolherOrdenador(id){
            RhDiariasService.getOrdenadorDespesa(id)
            .then((response)=>this.ordenador=response.data)
        },
    }
}
</script>