import instance from "./axiosConfig";

class RecuperarSenhaService {
  recuperarSenha(login, client) {
    return instance.put(`/usuarios-service/api/usuarios/recuperar-senha?login=${login.login}&client=${client}`);
  }

  resetarSenhaPrimeiroAcesso(login, novaSenha) {
    return instance.get("/usuarios-service/api/usuarios/resetarSenhaPrimeiroAcesso", {
      params: {
        login,
        novaSenha,
      },
    });
  }
}

export default new RecuperarSenhaService();
