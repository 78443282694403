<template>
  <div class="container-fluid p-2">
    <div class="card card-primary card-tabs">
      <div class="card-header p-0 pt-1">
        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
          <li class="nav-item">
            <a
              ref="dadosbasicos"
              class="nav-link active"
              id="custom-tabs-one-home-tab"
              data-toggle="pill"
              href="#dados-basicos"
              role="tab"
              aria-controls="custom-tabs-one-home"
              aria-selected="true"
              >Dados básicos</a
            >
          </li>
          <li class="nav-item">
            <a
              ref="enderecos"
              :class="{
                disabled: pessoa.id == undefined,
              }"
              class="nav-link"
              id="custom-tabs-one-profile-tab"
              data-toggle="pill"
              href="#enderecos"
              role="tab"
              aria-controls="custom-tabs-one-profile"
              aria-selected="false"
              >Endereço</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{
                disabled: pessoa.id == undefined,
              }"
              id="custom-tabs-one-messages-tab"
              data-toggle="pill"
              href="#contatos"
              ref="contatos"
              role="tab"
              aria-controls="custom-tabs-one-messages"
              aria-selected="false"
              >Contatos</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="custom-tabs-one-settings-tab"
              :class="{
                disabled: pessoa.id == undefined,
              }"
              data-toggle="pill"
              href="#documentos"
              ref="documentos"
              role="tab"
              aria-controls="custom-tabs-one-settings"
              aria-selected="false"
              >Documentos</a
            >
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="custom-tabs-one-tabContent">
          <div
            class="tab-pane fade active show"
            id="dados-basicos"
            role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab"
          >
            <DadosBasicosCadastroPessoa :pessoaEdicao="pessoaEdicao"></DadosBasicosCadastroPessoa>
          </div>

          <div
            class="tab-pane fade"
            id="enderecos"
            role="tabpanel"
            aria-labelledby="custom-tabs-one-profile-tab"
          >
            <EnderecoCadastroPessoa :pessoaEdicao="pessoaEdicao"></EnderecoCadastroPessoa>
          </div>

          <div
            class="tab-pane fade"
            id="contatos"
            role="tabpanel"
            aria-labelledby="custom-tabs-one-messages-tab"
          >
            <!--CODE-->
            <ContatosCadastroPessoa :pessoaEdicao="pessoaEdicao"/>
          </div>

          <div
            class="tab-pane fade"
            id="documentos"
            role="tabpanel"
            aria-labelledby="custom-tabs-one-settings-tab"
          >
            <DocumentosCadastroPessoa :pessoaEdicao="pessoaEdicao"/>

            <!--CODE-->
          </div>

          <!--DOCUMENTOS-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Camera from "@/components/camera/CameraPhotoComponent.vue";
import DadosBasicosCadastroPessoa from "@/components/administracao/pessoa/DadosBasicosCadastroPessoa.vue";
import EnderecoCadastroPessoa from "@/components/administracao/pessoa/EnderecoCadastroPessoa.vue";
import ContatosCadastroPessoa from "@/components/administracao/pessoa/ContatosCadastroPessoa.vue";
import DocumentosCadastroPessoa from "@/components/administracao/pessoa/DocumentosCadastroPessoa.vue";

import { mapState } from 'vuex';

export default {
  name: "TabsCadastroPessoa",
  components: {
    Camera,
    DadosBasicosCadastroPessoa,
    EnderecoCadastroPessoa,
    ContatosCadastroPessoa,
    DocumentosCadastroPessoa,
  },
  data() {
    return {
      tirarFoto: false,
      dispositoEncontrado: true,
      pessoa: {
        documentos: [],
        emails: [],
        enderecos: [],
      },
    }
  },
  computed: {
    ...mapState(['idPessoaFluxoCadastroPessoa']),

    pessoaEdicao() {

      this.pessoa = this.idPessoaFluxoCadastroPessoa;

      return this.pessoa;
    },
  },
  methods: {
    limparCampos() {
      this.pessoa = {};
      this.endereco = {};
      this.email = {};
      this.telefone = {};
      this.documento = {};
      this.endereco = {
        id: "",
        atual: "",
        estado: null,
        cidade: null,
        pais: null,
        cep: "",
        bairro: null,
        logradouro: "",
        numero: "",
        tipoMoradia: "",
        tipoArea: "",
        tipoEndereco: "",
        complemento: "",
        referencia: "",
      };
      this.documentos = [];
      this.emails = [];
      this.enderecos = [];
      this.telefones = [];
    },
    tabAtiva(tabName) {
      const tabAtiva = this.$refs[tabName].classList.contains("active");
      return tabAtiva;
    },

    retornarViewFuncionario() {
      if (this.pessoa.id !== undefined) {
        EventBus.$emit("fecharModalEPesquisar", this.pessoa.id);
        EventBus.$emit("fecharViewCadastrarPessoa");
      } else {
        EventBus.$emit("fecharViewCadastrarPessoa");
      }
    },

    nenhumCampoPreenchido() {
      let pessoa = this.pessoa;
      let cpfVazio = !pessoa.cpf || pessoa.cpf == "" || pessoa.cpf.length < 14;
      let nomesVazios =
        pessoa.nome == "" &&
        pessoa.nomeMae == "" &&
        pessoa.dataNascimento == "";
      if (cpfVazio || nomesVazios) {
        return true;
      }

      return false;
    },

    proximaAba() {
      let abas = ["dadosbasicos", "enderecos", "contatos", "documentos"];
      let [abaAtiva] = abas.filter((aba) => this.tabAtiva(aba));

      let proxima =
        abas.indexOf(abaAtiva) + 1 > abas.length
          ? null
          : abas[abas.indexOf(abaAtiva) + 1];
      if (proxima != null && proxima != undefined) {
        setTimeout(() => this.$refs[proxima].click(), 500);
      }
    },

    habilitarAbas() {
      if (this.tabAtiva("dadosbasicos")) {
        if (this.nenhumCampoPreenchido()) {
          Toast.fire("Preencha ao menos um campo!", "", "error");
          return;
        }
        if (this.pessoa.id) {
          this.salvarAlteracoesDadosBasicos();
        } else {
          this.cadastrarDadosBasicos();
        }
      } else {
        this.proximaAba();
      }
      this.$forceUpdate();
    },

    preencherListas(nomeTabelas) {
      nomeTabelas.forEach((tabela) => {
        ConsultaListasService.buscarListaPorNomeTabela(tabela)
          .then((response) => {
            this[`${tabela.toString()}s`] = response.data;
          })
          .catch((e) => console.log("ERRO: " + e.message));
      });
    },

    handleSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },

    preencherListasObj(obj) {
      // {atributo : nome_tabela}

      Object.keys(obj).forEach((lista) => {
        ConsultaListasService.buscarListaPorNomeTabela(obj[lista])
          .then((response) => {
            this[lista] = response.data;
          })
          .catch((e) => console.log("ERRO: " + e.message));
      });
    },

    showModal() {
      this.isModalVisible = false;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    buscarPessoaPorId(id) {
      CadastroPessoaService.buscarPessoaCompletaPorId(id).then((r) => {
        if (r) {
          this.preencherCamposPessoa(r);
        }
      });
    },
    preencherCamposPessoa(data) {
      // recebe o data e preenche os valores
      // console.log("Em tab", data);
      this.pessoa = data;
      EventBus.$emit("pessoaParaEditar", this.pessoa);
      EventBus.$emit("preencherDadosBasicos", this.pessoa);
    },
    preencherIdEmTela(pessoa) {
      this.pessoa.id = pessoa.id;
      this.$forceUpdate();
    },
    enviaIdPessoa() {
      EventBus.$emit("emitIdPessoa", this.pessoa.id);
    },
  },
}
</script>

<style scoped>
.card-header {
  background-color: #0f192c !important;
}
</style>
