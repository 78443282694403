<template>
  <div class="p-3" v-on="isModalVisible ? { click: closeModal } : {}">
    <!-- <ViewCadastroPessoa v-show="exibirViewCadastrarPessoa" /> -->
    <section class="content" v-show="!exibirViewCadastrarPessoa">
      <conteudo v-role="'ADMINISTRACAO'">
        <div v-show="!edicaoFuncionario">
          <content-header title="Cadastro de usuário" />
        </div>
        <div v-show="edicaoFuncionario">
          <content-header title="Edição de usuário" />
        </div>
        <!-- <ModalConsultaPessoa v-show="isModalVisible" @fecharModalEPesquisar="buscarPessoaPorId"
            @closeModalCadastro="closeModal" @cadastrarPessoaFisicaFuncionario="showViewPessoa" /> -->
        <ModalConsultaPessoa />
        <!-- <div class="modal-backdrop fade show" v-show="isModalVisible"></div> -->
        <!-- <TabsCadastoFuncionario @openModalConsultaPessoa="showModal" /> -->
        <TabsCadastoUsuario />
      </conteudo>
    </section>
  </div>
</template>

<script>
import TabsCadastoUsuario from '@/components/administracao/usuario/cadastro/TabsCadastoUsuario.vue';
import { EventBus } from '@/main';
import ModalConsultaPessoa from '@/views/cadastros/pessoa/ModalConsultaPessoa.vue';
import ViewCadastroPessoa from '@/views/cadastros/pessoa/ViewCadastroPessoa.vue';
export default {
  name: "ViewCadastroFuncionario",
  components: { ModalConsultaPessoa, ViewCadastroPessoa, TabsCadastoUsuario },
  data() {
    return {
      isModalVisible: true,
      exibirViewCadastrarPessoa: false,
      edicaoFuncionario: false
    };
  },
  created() {
    // console.log(this.$route.params);
    // EventBus.$on('fecharViewCadastrarPessoa', this.closeViewPessoa)
    // EventBus.$on('editarFuncionario', (listener) => {
    //   this.isModalVisible = false
    //   this.exibirViewCadastrarPessoa = false
    //   this.edicaoFuncionario = true
    // })

    // console.log("asigdiadusg");
  },

  methods: {
    buscarPessoaPorId(id) {
      this.closeModal();

      this.id = id;


    },
    showViewPessoa() {
      this.exibirViewCadastrarPessoa = true
    },
    closeViewPessoa() {
      this.exibirViewCadastrarPessoa = false
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
}
</script>