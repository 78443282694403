<template>
  <div class="p-3" v-on="isModalVisible ? { click: closeModal } : {}">
    <!-- <ViewCadastroPessoa v-show="exibirViewCadastrarPessoa" /> -->
    <section class="content" v-show="!exibirViewCadastrarPessoa">
      <conteudo v-role="'RECURSOS_HUMANOS'">
        <div v-show="!edicaoFuncionario">
          <content-header title="Cadastro de servidor" />
        </div>
        <div v-show="edicaoFuncionario">
          <content-header title="Edição de servidor" />
        </div>
        <!-- <ModalConsultaPessoa v-show="isModalVisible" @fecharModalEPesquisar="buscarPessoaPorId"
            @closeModalCadastro="closeModal" @cadastrarPessoaFisicaFuncionario="showViewPessoa" /> -->
            <ModalEdicaoSenha v-show="isModalVisible"/>
        <ModalConsultaPessoa/>
        <!-- <div class="modal-backdrop fade show" v-show="isModalVisible"></div> -->
        <!-- <TabsCadastoFuncionario @openModalConsultaPessoa="showModal" /> -->
        <TabsCadastoFuncionario />
      </conteudo>
    </section>
  </div>
</template>

<script>
import TabsCadastoFuncionario from '@/components/administracao/funcionario/cadastro/TabsCadastoFuncionario.vue';
import { EventBus } from '@/main';
import ModalConsultaPessoa from '@/views/cadastros/pessoa/ModalConsultaPessoa.vue';
import ViewCadastroPessoa from '@/views/cadastros/pessoa/ViewCadastroPessoa.vue';
import ModalEdicaoSenha from '@/components/administracao/funcionario/visualizacao/ModalEdicaoSenha.vue'
export default {
  name: "ViewCadastroFuncionario",
  components: { ModalConsultaPessoa, ViewCadastroPessoa, TabsCadastoFuncionario,ModalEdicaoSenha },
  data() {
    return {
      isModalVisible: false,
      exibirViewCadastrarPessoa: false,
      edicaoFuncionario: false,
      
    };
  },
  created() {
    EventBus.$on("openModalEdicaoSenha",()=>{
      this.isModalVisible=true
    })
    EventBus.$on("closeModalEdicaoSenha",()=>{
      this.isModalVisible=false
    })


 
    // console.log(this.$route.params);
    // EventBus.$on('fecharViewCadastrarPessoa', this.closeViewPessoa)
    // EventBus.$on('editarFuncionario', (listener) => {
    //   this.isModalVisible = false
    //   this.exibirViewCadastrarPessoa = false
    //   this.edicaoFuncionario = true
    // })
  },

  methods: {
    buscarPessoaPorId(id) {
      this.closeModal();

      this.id = id;


    },
    showViewPessoa() {
      this.exibirViewCadastrarPessoa = true
    },
    closeViewPessoa() {
      this.exibirViewCadastrarPessoa = false
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
}
</script>
