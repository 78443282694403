<template>
  
    <div class="card-body table-responsive p-0" style="height: 500px;">

        <table  class="table table-hover table-striped text-nowrap table-responsive-sm table-head-fixed" >
        
            <thead>
                <tr>
                <th style="
    left: 20px;
">Código</th>
                <th>Nome</th>
                <th>Observação 1</th>
                <th>Observação 2</th>
                <th 
    style = "right: 20px;"
>Boletim</th>
                </tr>
            </thead>
            <tbody >
                <tr @click="selecionarTipo(tipoAnotacao.id)" v-for="(tipoAnotacao,i) in tipos" :key="i" class="clicavel">
                    <td>{{ tipoAnotacao.id }}</td>
                    <td>{{ tipoAnotacao.descricao}}</td>
                    <td>{{ tipoAnotacao.obs1}}</td>
                    <td>{{ tipoAnotacao.obs2}}</td>
                    <td>{{ tipoAnotacao.boletim ? "Sim" : "Não"}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import RhDiariasService from '@/service/RhDiariasService';
import { EventBus } from '@/main';

export default {
   
    data(){
      return{
        tipos:[],
        loaderActive:false,
      }

    },
    created(){
        this.listarTiposAnotacao();
    },
    watch:{
        
    },
    methods:{
        listarTiposAnotacao(){
            let user = JSON.parse(localStorage.getItem("user"));
            let token = user.token;
            RhDiariasService.getAllTipoAnotacao(token).then((resp)=>{
                this.tipos = resp.data;
            })
        },
        selecionarTipo(id){
            EventBus.$emit("selecionarTipoAnotacao",id)
        },

    },
    
};



</script>

<style scoped>

</style>
