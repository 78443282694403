<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-fkSetorDestino">Setor Destino <span
              class="text-danger">*</span></label>
          <v-select class="border-v-select" id="documento-fkSetorDestino" :class="{
            'is-invalid': isSaving && !$v.documento.fkSetorDestino.required,
          }" data-cy="fkSetorDestino" name="fkSetorDestino" v-model="documento.fkSetorDestino"
            @input="carregaFuncionario" :options="setores" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="isSaving && !$v.documento.fkSetorDestino.required" class="invalid-feedback">
            Setor destino é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-fkUsuarioDestino">Servidor Destino <span
              class="text-danger">*</span></label>
          <v-select class="border-v-select" :class="{
            'is-invalid': isSaving && !$v.documento.fkSetorDestino.required,
          }" id="documento-fkUsuarioDestino" data-cy="fkUsuarioDestino" name="fkUsuarioDestino"
            v-model="documento.fkUsuarioDestino" :options="funcionarios" label="nome">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="isSaving && !$v.documento.fkUsuarioDestino.required" class="invalid-feedback">
            Usuário destino é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-cargoDestino">Cargo/Função Destino <span
              class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border" name="cargoDestino" id="documento-cargoDestino"
            data-cy="cargoDestino" v-model="documento.cargoDestino" :class="{
              'is-invalid': isSaving && !$v.documento.cargoDestino.required,
            }" />
          <div v-if="isSaving && !$v.documento.cargoDestino.required" class="invalid-feedback">
            Cargo/Função é obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-tratamento">Tratamento <span
              class="text-danger">*</span></label>
          <!-- <input
            type="text"
            class="form-control form-control-border"
            name="tratamento"
            id="documento-tratamento"
            data-cy="tratamento"
            v-model="documento.tratamento"
            :class="{
              'is-invalid': isSaving && !$v.documento.tratamento.required,
            }"
          /> -->
          <v-select class="border-v-select" name="tratamento" id="documento-tratamento"
            data-cy="tratamento" v-model="documento.tratamento" :class="{
              'is-invalid': isSaving && !$v.documento.tratamento.required,
            }" :options="tratamentos" :reduce="(tratamentos) => tratamentos.descricao" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="isSaving && !$v.documento.tratamento.required" class="invalid-feedback">
            Tratamento é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-controleProcesso">e-Processos</label>
          <input type="text" class="form-control form-control-border" name="controleProcesso"
            id="documento-controleProcesso" data-cy="controleProcesso" v-model="documento.controleProcesso" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-assunto">Signatário <span
              class="text-danger">*</span></label>
          <v-select class="border-v-select" :class="{
            'is-invalid': isSaving && !$v.documento.fkUsuario.required,
          }" id="documento-fkUsuario" data-cy="fkUsuario" name="fkUsuario" v-model="documento.fkUsuario"
            :options="funcionariostodos" label="nome">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="isSaving && !$v.documento.fkUsuario.required" class="invalid-feedback">
            Signatário é obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-cargoSignatario">Cargo/Funçao Signatário <span
              class="text-danger">*</span></label>
          <v-select class="border-v-select" name="cargoSignatario" id="documento-cargoSignatario"
            data-cy="cargoSignatario" v-model="documento.cargoSignatario" :class="{
              'is-invalid': isSaving && !$v.documento.cargoSignatario.required,
            }" :options="funcoes" :reduce="(funcoes) => funcoes.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="isSaving && !$v.documento.cargoSignatario.required" class="invalid-feedback">
            Cargo/Função do signatário é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-referencia">Referência</label>
          <input type="text" class="form-control form-control-border" name="referencia" id="documento-referencia"
            data-cy="referencia" v-model="documento.referencia" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-assunto">Assunto <span class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-border" name="assunto" id="documento-assunto"
            data-cy="assunto" v-model="documento.assunto" :class="{
              'is-invalid': isSaving && !$v.documento.assunto.required,
            }" />
          <div v-if="isSaving && !$v.documento.assunto.required" class="invalid-feedback">
            Assunto é obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-fecho">Fecho <span class="text-danger">*</span></label>
          <v-select class="border-v-select" id="documento-fecho" data-cy="fecho" name="fecho"
            v-model="documento.fecho" :class="{ 'is-invalid': isSaving && !$v.documento.fecho.required }"
            :options="fechos" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
          <div v-if="isSaving && !$v.documento.fecho.required" class="invalid-feedback">
            Fecho é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-fecho">Setor Destino Cópia</label>
          <!-- <select class="form-control form-control-border"></select> -->
          <v-select class="border-v-select" id="documento-fkSetorDestinoCopia"
            data-cy="fkSetorDestinoCopia" name="fkSetorDestinoCopia" @input="carregaFuncionarioCopia" :options="setores"
            label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-fecho">Servidor Destino Cópia</label>
          <!-- <select class="form-control form-control-border"></select> -->
          <v-select class="border-v-select" id="documento-fkUsuarioDestinoCopia"
            data-cy="fkUsuarioDestinoCopia" name="fkUsuarioDestinoCopia" @input="preecherListaCopia"
            :options="funcionariosCopia" label="nome">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
    </div>

    <!-- <div
      class="row"
      :class="{ 'hidden': listaCopiaDocumento.length == 0 }"
      id="copia_documento"
      v-if="listaCopiaDocumento.length == 0"
    >
      <div class="col-md-12">
        <div class="box box-solid">
          <div class="box-header with-border">
            <i class="fa fa-users"></i>
            <h3 class="box-title">Com copia para</h3>
          </div>
          <div class="box-body">
            <div class="form-group interno">
              <div class="box-body no-padding">
                <div
                  class="table-responsive mailbox-messages"
                  id="lista_copia_documento"
                >
                  <table
                    class="table table-hover table-striped"
                    id="tabela_copia_documento"
                  >
                    <thead>
                      <tr>
                        <th class="text-center negrito">#</th>
                        <th>Setor destino</th>
                        <th>Servidor destino</th>
                        <th class="text-center">AÃ§Ã£o</th>
                      </tr>
                    </thead>
                    <tbody
                      id="table_body_copia_documento"
                      th:if="${#lists.isEmpty(listaCopiaDocumento)}"
                    >
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row" id="copia_documento" v-if="listaCopiaDocumento.length > 0"
      :class="{ 'hidden': listaCopiaDocumento.length > 0 }">
      <hr align="left">
      <div class="col-md-12">
        <div class="box box-solid">
          <div class="box-header with-border">
            <h5 class="box-title"><i class="fa fa-users"></i> Com cópia para</h5>
          </div>
          <div class="box-body">
            <div class="form-group">
              <div class="box-body no-padding">
                <div class="table-responsive mailbox-messages">
                  <table class="table table-hover table-striped" id="tabela_copia_documento">
                    <thead>
                      <tr>
                        <th class="text-center negrito">#</th>
                        <th>Setor destino</th>
                        <th>Servidor destino</th>
                        <th class="text-center">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="copia in listaCopiaDocumentoTabela" v-bind:key="copia.id">
                        <td></td>
                        <td>{{ copia.fkSetorDestino.descricao }}</td>
                        <td>{{ copia.fkUsuarioDestino.nome }}</td>
                        <td class="text-center">
                          <a name="excluir_copia" id="excluir_copia" title="Remover copia" class="btn-bo-natureza-acoes"
                            data-toogle="tooltip" onclick="removeLinhaCopia(copia.id)"
                            data-value="${copia.fkUsuarioDestino.idFuncionario}"
                            th:attr="data-idCopia=${copia.idDocumentoCopia}">
                            <i class="fa fa-trash text-danger fa-lg"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import converteFuncionario from "@/util/converteFuncionario";
import { required } from "vuelidate/lib/validators";
import api from "../../service/DocumentoService";

export default {
  name: "Documentos",
  data() {
    return {
      documento: {},
      listaCopiaDocumento: [],
      listaCopiaDocumentoTabela: [],
      submitted: false,
      isSaving: false,
      setores: [],
      funcionarios: [],
      funcionariosCopia: [],
      funcionariostodos: [],
      fechos: [],
      funcoes: [],
      tratamentos: [],
    };
  },
  validations: {
    documento: {
      fkSetorDestino: { required },
      fkUsuarioDestino: { required },
      cargoDestino: { required },
      tratamento: { required },
      fkUsuario: { required },
      cargoSignatario: { required },
      assunto: { required },
      fecho: { required },
    },
  },
  methods: {
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      // alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.user));
    },
    carregaSetores: function () {
      const listaSetores = api.buscarSetor().then((response) => {
        this.setores = response.data;
      });
    },
    carregaFuncionario(event) {
      if (event != undefined) {
        const listaFuncionario = api
          .buscarFuncionariosetor(event.id)
          .then((response) => {
            this.funcionarios =
              converteFuncionario.funcionarioDtoToFuncionarioArray(
                response.data
              );
          });
      }
    },
    carregaFuncionarioCopia(event) {
      if (event != undefined) {
        const listaFuncionario = api
          .buscarFuncionariosetor(event.id)
          .then((response) => {
            this.funcionariosCopia =
              converteFuncionario.funcionarioDtoToFuncionarioArray(
                response.data
              );
          });
      }
    },
    carregaTodosFuncionario() {
      const listaFuncionario = api.buscarFuncionario().then((response) => {
        this.funcionariostodos =
          converteFuncionario.funcionarioDtoToFuncionarioArray(response.data);
        // console.log(response.data);
      });
    },
    carregaFecho() {
      const listaFechos = api.buscarFecho().then((response) => {
        this.fechos = response.data;
      });
    },
    carregaFuncao() {
      const listaFuncoes = api.buscarFuncao().then((response) => {
        this.funcoes = response.data;
      });
    },
    carregaTratamento() {
      const listaTratamentos = api.buscarTratamento().then((response) => {
        this.tratamentos = response.data;
      });
    },
    preecherListaCopia(event) {
      this.listaCopiaDocumentoTabela.push(converteFuncionario.preencheCopiaDocumento(event))
      this.listaCopiaDocumento.push(event.id);
      // console.log(event);
      // console.log(this.listaCopiaDocumento);
    }
  },
  mounted() {
    this.carregaSetores();
    this.carregaTodosFuncionario();
    this.carregaFecho();
    this.carregaFuncao();
    this.carregaTratamento();
  },
};
</script>
