<template>

    <div>


        <b-modal
        id="modal-20"
        ref="modal-20"
         title="Editar Anotação"
        data-backdrop="static"
        size="xl"
        cancel-disabled
        no-close-on-backdrop
        hide-header-close
        header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            "
        content-class="modal-content border-rounded-detail"
        dialog-class="p-3"
        title-class="modal-title mx-auto xl"
        >

            <div class="overflow-editar p-2">

                <h6 for="editarAnotacao"><b>EDITAR ANOTAÇÃO:</b></h6>
            
            <div class="row">
                
                <div class="form-group col-lg-4">
                <label for="editar-tipo">Tipo</label>
                <v-select
                    class="border-v-select mt-1"
                    id="editar-tipoAnotacao"
                    v-model="editar.tipoAnotacao"
                    :options="tipos"
                    label="descricao"
                    >
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
                </div>
                
                <div class="form-group col-lg-4">
                <label for="editar-tipoDocumento">Documento</label>
                <v-select
                    class="border-v-select mt-1"
                    id="editar-tipoDocumento"
                    v-model="editar.tipoDocumento"
                    :options="documentos"
                    label="descricao"
                    >
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                    </v-select>
                </div>

                <div class="form-group col-lg-4">
                <label for="editar-data">Data de Anotação</label>
                <input
                    type="date"
                    class="form-control form-control-border border-width-2"
                    id="editar-dataAnotacao"
                    placeholder="dd/mm/aaaa"
                    v-model="editar.dataAnotacao"
                />
                </div>

                
               
            </div>
            <div class="row">

                <div class="form-group col-lg-4">
                <label class="form-control-label" for="editar-numero"
                    >Número
                    </label>
                    <input
                    type="text"
                    class="form-control form-control-border border-width-2"
                    name="numeroDocumento"
                    id="editar-numeroDocumento"
                    data-cy="numero"
                    :class="{
                        'is-invalid': isSaving && $v.editar.numeroDocumento.$error}"
                        v-model="editar.numeroDocumento"
                    />
                </div>

                <div class="form-group col-lg-4">
                    <label class="form-control-label" for="editar-dataInicio"
                    >Data Início</label>
                    <div class="d-flex">
                      <input
                        type="date" data-cy="dataInicio" class="form-control form-control-border border-width-2" id="editar-dataInicio"
                        placeholder="dd/mm/aaaa"  v-model="editar.dataInicio"
                        :class="{
                        'is-invalid': isSaving && $v.editar.dataInicio.$error}"
                      />
                    </div>
                </div>
      
                <div class="form-group col-lg-4">
                    <label class="form-control-label" for="editar-dataFim"
                    >Data Fim
                    </label >
                    <div class="d-flex">
                    <input
                        type="date" data-cy="dataFim" class="form-control form-control-border border-width-2" id="editar-dataFim"
                        placeholder="dd/mm/aaaa" v-model="editar.dataFim" :class="{
                        'is-invalid': isSaving && $v.editar.dataFim.$error}"
                        
                    />
                    </div>
              
                </div>
            </div>

            <div class="row">
                <!-- <div class="form-group col-lg-3">
                <label for="editar-dataDocumento">Data Documento</label>
                <input
                    type="date"
                    class="form-control form-control-border border-width-2"
                    id="editar-dataDocumento"
                    placeholder="dd/mm/aaaa"
                    v-model="editar.dataDocumento"
                    :class="{
                    'is-invalid': isSaving && $v.editar.dataDocumento.$error}"
                />
                </div> -->

                <!-- <div class="form-group col-lg-6">
                    <label class="form-control-label" for="editar-informacao">Informacao</label>
                    <input type="text" class="form-control form-control-border border-width-2" name="informacao" id="editar-informacao"
                    data-cy="informacao" :class="{
                    'is-invalid': isSaving && $v.editar.informacao.$error}" v-model="editar.informacao" />
                </div> -->

                <!-- <div class="form-group col-lg-3">
                    <label class="form-control-label" for="editar-dataInicio"
                    >Data Inicio</label>
                        <div class="d-flex">
                        <input
                            type="datetime-local" data-cy="dataInicio" class="form-control form-control-border border-width-2" id="editar-dataInicio"
                            placeholder="dd/mm/aaaa"  v-model="editar.dataInicio"
                            :class="{
                            'is-invalid': isSaving && $v.editar.dataInicio.$error}"
                        />
                        </div>
                </div> -->
        
                <!-- <div class="form-group col-lg-3">
                    <label class="form-control-label" for="editar-dataFim"
                    >Data Fim
                    </label >
                    <div class="d-flex">
                    <input
                        type="date" data-cy="dataFim" class="form-control form-control-border border-width-2" id="editar-dataFim"
                        placeholder="dd/mm/aaaa" v-model="editar.dataFim" :class="{
                        'is-invalid': isSaving && $v.editar.dataFim.$error}"
                        
                    />
                    </div>
                </div> -->
         

                <!-- <div class="form-group col-lg-6">
                    <label class="form-control-label" for="editar-observacao">Observação  </label>
                    <input type="text" class="form-control form-control-border border-width-2" name="obs1" id="editar-observacao1"
                        data-cy="obs1"  v-model="editar.obs1" />
                </div> -->

                <div  class="form-group col-6" >
                    <p><label  for="editar-observacao1">Informação</label></p>
                    <textarea rows="10" style="resize: none;" type="text" name="observacao1" row="3" class="form-control" id="editar-observacao1"
                    data-cy="observacao1" :class="{
                    'is-invalid': isSaving && $v.editar.obs1.$error}" v-model="editar.obs1"></textarea>
                </div>
          
            
              <div class="form-group col-lg-6">
                <p><label for="editar-observacao2">Observação  </label></p>
                <textarea rows="10" style="resize: none;" type="text" class="form-control" name="observacao2" id="editar-observacao2"
                    data-cy="observacao2"  v-model="editar.obs2" ></textarea>
              </div>

              <!-- <div class="form-group col-lg-6">
                <label class="form-control-label" for="cadanotacao-observacao">Observação 2 </label>
                <input type="text" class="form-control form-control-border border-width-2" name="obs2" id="cadanotacao-observacao2"
                    data-cy="obs2"  v-model="anotacao.obs2" />
              </div> -->

         
            
            </div>

            <div class="form-group col-lg-6">
              <label for="ferias-supervidor">Supervisor(a)</label>
              <v-select class="border-v-select mt-1" id="ferias-supervidor" v-model="editar.supervisorAssinatura"
                    :options="listaSupervisor" :reduce="(listaSupervisor) => listaSupervisor.id" label="nome">
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </div>

            </div>

            <template #modal-footer="{ ok, cancel }">

                
                <button @click.prevent="[alterarAnotacao(editar.anotacaoId), ok()]" class="btn text-color-principal background-principal ml-1">
                    <i class="far fa-envelope"></i> Salvar
                </button>
                <button @click.prevent="LimparEdicao" type="reset" class="btn btn-default">
                    <i class="fas fa-ban"></i> Limpar
                </button>
                <b-button
                            class="btn btn-default float-right"
                @click="[cancel(), limparInput()]"
                >
                <i class="fa-solid fa-x"></i>Fechar
                </b-button>
            </template> 
        


        </b-modal>




    </div>
    
</template>

<script>

import rhService from "@/service/RHService";
import RHDiarias from "@/service/RhDiariasService"
// import {DATE_FORMAT_LOCAL_DATA, } from "@/util/filter";
import { EventBus } from "@/main";
import RHAnotacaoService from '@/service/RHAnotacaoService';

export default {
    
    components:{
        
        
    },
    data(){

        return{
            data:{},
            editar:{},
            tipos:[],
            documentos:[],
            submitted: false,
            isSaving: false,
            anotacaoId:{},
            listaSupervisor: {},

        }

        
        
    },

    validations:{


        editar:{ }

    
    },

    created(){

        this.carregarAnotacoes();
        this.buscaSupervisor();

    EventBus.$on("EnvioDadosAnotacao",(listener)=>{
        
        this.editar = listener;
        console.log(listener)
        
    });
},


    methods:{

        buscaSupervisor() {
        rhService
        .buscaSupervisors()
        .then((response) => {
            this.listaSupervisor = response.data;
        });
    },
        LimparEdicao(){
            this.editar={}
        },

        limparInput(){
             this.editar={}
        },

        carregarAnotacoes(){
            let user = JSON.parse(localStorage.getItem("user"));
            let token = user.token;
            RHDiarias.getAllTipoAnotacao(token)
                .then((response) => {
                this.tipos = response.data;
            })
    

        rhService.getAllTipoDocumento(token)
            .then((response) => {
            this.documentos = response.data;
            })

        },


          getAnotacao(id){
            
              RHAnotacaoService.getAnotacao(id).then(resp=>{
                  this.editar = resp.data;
                  console.log("editar: " + this.editar)
              });
          },

        alterarAnotacao(){

           

            RHAnotacaoService.editarAnotacao(this.editar).then(resp=>{
                this.editar = resp.data;
                this.editar={};
                console.log("editar " + this.editar)
                Toast.fire("Edição realizada", "", "success");
                EventBus.$emit("AtualizarTabelaAnotacao");
              
            }).catch(()=>{
                Toast.fire("Falha ao editar anotações", "", "error");
            });

            EventBus.$emit("AtualizarAnotacao");
            
        },


    
    },

 

   
}
</script>