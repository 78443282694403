import instance from './axiosConfig'

class ConsultaFuncionarioService {


  buscarPessoa(filtro) {
    return instance.get(`/pessoa-service/api/pessoas/consulta-visita?tamanho=12&cpf=${filtro.cpf}&nome=${filtro.nome}`);
  }


}
export default new ConsultaFuncionarioService();
