<template>

    <div>

        <div class="card-body">
          
          <div class="row">
            <div class="form-group col-lg-3">
              <label for="emissaoCI-numeroCI">Numero CI</label>
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="emissaoCI-numeroCI"
                v-model="ferias.numeroCI"
                :class="{
                'is-invalid': isSaving && $v.ferias.numeroCI.$error}"
              />
            </div>

            <div class="form-group col-lg-3">
              <label class="form-control-label" for="emissaoCI-anoFerias"
              >Ano Férias
              </label>
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                name="anoFerias"
                id="emissaoCI-anoFerias"
                data-cy="anoFerias"
                :class="{
                'is-invalid': isSaving && $v.ferias.anoFerias.$error}"
                v-model="ferias.anoFerias"
              />
            </div>

            <div class="form-group col-lg-3">
              <label for="emissaoCI-mesFerias">Mês Férias</label>
              <v-select
                class="border-v-select mt-1"
                id="emissaoCI-mesFerias"
                v-model="ferias.mesFerias"
                :options="mesFerias"
                :reduce="mesFerias => mesFerias.id"
                label="descricao"
                >
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </div>

              <div class="form-group col-lg-3">
                <label class="pesquisar-Cor-carro"  for="emissaoCI-local"  >Local</label>
                <v-select class="border-v-select"   v-model="ferias.local" id="emissaoCI-local"
                  :options="local"  
                  :reduce="local => local.id"
                  label="descricao" >
                  <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
              </div>
  
          </div>

          <div class="row">
           

            <div class="form-group col-lg-4"    >
                <label class="pesquisar-Cor-carro"  for="emissaoCI-predio" >Prédio</label>
                <v-select class="border-v-select"   v-model="ferias.predio" id="emissaoCI-predio"
                  :options="predio" 
                  :reduce="predio => predio.id"
                   label="descricao">
                  <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
            </div>

            <div class="form-group col-lg-4">
                <label for="emissaoCI-do">Do</label>
                <input type="text" class="form-control form-control-border border-width-2" id="emissaoCI-do"
                  v-model="ferias.envioDo" />
            </div>

            <div class="form-group col-lg-4">
                  <label for="emissaoCI-para">Para</label>
                  <input type="text" class="form-control form-control-border border-width-2" id="emissaoCI-para"
                    v-model="ferias.envioPara" />
            </div>

          </div>
        
           
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="emissaoCI-responsavel">Responsável</label>
                <input type="text" class="form-control form-control-border border-width-2" id="emissaoCI-responsavel"
                  v-model="ferias.responsavel" />
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="emissaoCI-cargoResponsavel">Cargo Responsável</label>
                <input type="text" class="form-control form-control-border border-width-2" id="emissaoCI-cargoResponsavel"
                  v-model="ferias.cargoResponsavel" />
              </div>
            </div>
          </div>

            <div class="card-footer">
              <div class="float-right">
                <!-- <button @click.prevent="EmitirCI" class="btn text-color-principal background-principal ml-1">
                  <i class="fa fa-search"></i> Buscar
                </button> -->
                <button @click.prevent="SalvarCI" class="btn text-color-principal background-principal ml-1">
                  <i class="far fa-envelope"></i> Salvar
                </button>
                <button @click.prevent="LimparCI" type="reset" class="btn text-color-principal background-principal ml-1">
                  <i class="fas fa-ban"></i> Limpar
                </button>
              </div>
            </div>




        </div>

    </div>
    
</template>

<script>

import {DATE_FORMAT_LOCAL_DATA, } from "@/util/filter";
import dayjs from "dayjs";

export default {

    data(){

        return{
            data:{},
            ferias:{},
            mesFerias:[

                { id : 1, descricao: "Janeiro" },
                { id : 2, descricao: "Fevereiro" },
                { id : 3, descricao: "Março" },
                { id : 4, descricao: "Abril" },
                { id : 5, descricao: "Maio" },
                { id : 6, descricao: "Junho" },
                { id : 7, descricao: "Julho" },
                { id : 8, descricao: "Agosto" },
                { id : 9, descricao: "Setembro" },
                { id : 10, descricao: "Outubro" },
                { id : 11, descricao: "Novembro" },
                { id : 12, descricao: "Dezembro" },

            ],
            local:[

              { id : 1, descricao: "Capital" },
              { id : 2, descricao: "Interior" },

            ],
            predio:[
              { id : 1, descricao: "Externo" },
              { id : 2, descricao: "Prédio" },
            ],
            submitted: false,
            isSaving: false
        }
        
    },


    validation: {
      ferias:{

        numeroCI:{},
        anoFerias:{},
        mesFerias:{},
        local:{},
        predio:{},
        envioDo:{},
        envioPara:{},
        responsavel:{},
        cargoResponsavel:{},

      },
    },

    
    methods:{
    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

     updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.feria[field] = dayjs(
        event.target.value,
          DATE_FORMAT_LOCAL_DATA
       );
     } else {
        this.feria[field] = null;
      }
    },

    
    EmitirCI(){},
    LimparCI(){
      this.ferias = {};
    },

  },
   
}
</script>