<template>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">Cadastrar dados básicos *</h5>
    </div>
    <div class="form-group col-lg-8">
      <label for="nome">Nome *</label>
      <input
        @blur="validaExistenciaPessoaPorNomeMaeEDataNascimento"
        ref="nomeRef"
        type="text"
        id="nome"
        class="form-control form-control-border border-width-2"
        :class="{
          disabled: !habilitarForms,
        }"
        placeholder="Nome completo"
        :value="pessoa.nome"
        @input="
          (event) =>
            (pessoa.nome = event.target.value
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase())
        "
      />
    </div>
    <div class="form-group col-lg-4">
      <label for="sexo">Sexo *</label>
      <v-select
        class="form-control form-control-border border-width-2"
        :class="{
          disabled: !habilitarForms,
        }"
        id="sexo"
        v-model="pessoa.sexo"
        :options="['M', 'F', 'NI']"
        label="descricao"
      >
        <span slot="no-options">Desculpe, sem opções no momento.</span>
      </v-select>
    </div>
    <div class="form-group col-lg-8">
      <label for="nomemae">Nome da mãe *</label>
      <input
        @blur="validaExistenciaPessoaPorNomeMaeEDataNascimento"
        type="text"
        class="form-control form-control-border border-width-2"
        :class="{
          disabled: !habilitarForms,
        }"
        v-model="pessoa.mae"
        @input="
          (event) =>
            (pessoa.mae = event.target.value
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase())
        "
        id="nomemae"
        placeholder="Nome da mãe"
      />
    </div>
    <div class="form-group col-lg-4">
      <label for="dataNascimento">Data de nascimento *</label>
      <input
        @blur="validaExistenciaPessoaPorNomeMaeEDataNascimento"
        type="date"
        class="form-control form-control-border border-width-2"
        :class="{
          disabled: !habilitarForms,
        }"
        id="dataNascimento"
        placeholder="dd/mm/aaaa"
        v-model="pessoa.dataNascimento"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import CadastroPessoaService from "@/service/CadastroPessoaService";
export default {
  name: "FormDadosBasicosComponent",
  props: {
    habilitarForms: Boolean,
  },
  created() {
    EventBus.$on("preencherCamposDadosBasicos", this.preencherCamposPessoa);
  },
  methods: {
    validaExistenciaPessoaPorNomeMaeEDataNascimento() {
      if (!this.habilitarForms) return;
      if (this.pessoa.nome && this.pessoa.mae && this.pessoa.dataNascimento) {
        CadastroPessoaService.consultarExistenciaPessoaByParameters(
          this.pessoa.nome,
          this.pessoa.mae,
          this.pessoa.dataNascimento
        )
          .then((r) => {
            if (r.data) {
              // console.log('id', r.data)
              EventBus.$emit("pessoaExistePorNomeMaeEDataNascimento", r.data);
            }
          })
          .catch((e) => console.log(e));
      }
    },
    preencherCamposPessoa(dadosBasicos) {
      this.pessoa = dadosBasicos;
    },
  },
  data() {
    return {
      pessoa: {
        nome: "",
        sexo: "",
        mae: "",
        dataNascimento: "",
      },
    };
  },
};
</script>

<style>
</style>
