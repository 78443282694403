<template>
  
    <div class="card-body pt-0">
        <div class="card card-primary p-1, .ml-1 (margin-right: 4px;)">
            <!--FiltroAnotacao/-->
        </div>
        <table  class="table table-hover text-nowrap">
        
            <thead>
                <tr>
                <th>Matrícula</th>
                <th>Notação</th>
                <th>Cargo</th>
                <th>Lotação</th>
                <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>23454</td>
                    <td>hrtuyru</td>
                    <td>rurutu</td>
                    <td>rtuuu</td>
                    <td>
                        <button  type="button" style="border:none;!important" title="Imprimir Arquivo" class="btn btn-default btn-sm gerar">
                            <i class="fa-solid fa-print"></i>
                        </button>
                        <button type="button" style="border:none;!important" title="Excluir Arquivo" class="btn btn-default btn-sm excluir">
                            <i class="fa-solid fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import FiltroAnotacao from "@/components/rh/anotacao/FiltroAnotacao.vue";

export default {
   
    components:{
        FiltroAnotacao
    },
    data(){
      return{
        loaderActive:false,
      }

    },
    
};


</script>

<style scoped>

</style>
