<template>
  <div>
    <div class="card-body">
      <Loader :active="loaderActive" message="Enviando..." />
      <div class="submit-form" v-if="!loaderActive">
        <div>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Nome um <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Nome dois
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Cargo <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Aposentado <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >CPF <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Matrícula <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Tipo Sanguíneio
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Data Nascimento <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >RG <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >UF <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Siape
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Naturalidade <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Naturalidade UF <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Nacionalidade <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Sigla Posto
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Sexo <span class="text-danger">*</span>
                </label>
                <input class="form-control form-control-border"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- <button type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Discard
      </button> -->
    </div>
    <div class="card-footer">
      <div class="float-right">
        <button class="btn btn-default mr-1">
          <i class="fas fa-ban"></i> Cancelar
        </button>
        <button class="btn btn-primary">
          <i class="far fa-envelope"></i> Salvar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../Loader.vue";
export default {
  components: { Loader },
  data() {
    return {
      loaderActive: false,
      submitted: false,
      isSaving: false,
    };
  },
}
</script>