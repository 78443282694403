<template>
    <section class="content">
      <!-- <Loader :active="loaderActive" message="Carregando..." /> -->
      <conteudo>
        <content-header title="500 Internal Server Error" />
        <div class="container-fluid">
          <div class="error-page">
            <!-- <h2 class="headline text-warning">404</h2>
  
            <div class="error-content">
              <h3>
                <i class="fas fa-exclamation-triangle text-warning"></i> Oops!
                Page not found.
              </h3>
            </div> -->
            <!-- /.error-content -->
            <div class="row justify-content-center align-items-center">
              <div class="col-1 mr-3">
                <h1 class="headline text-warning">500 </h1>
              </div>
              <div class="col-8">
                <h3>
                  <i class="fas fa-exclamation-triangle text-warning"></i> Oops!
                  Erro interno no servidor.
                </h3>
                <p align="center">
                  Ocorreu um erro nos servidores do sigma, entre em
                  contato com o suporte
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="row justify-content-center align-items-center">
            <div class="col-1 pr-1"><h1 class="text-warning justify-content-center">401</h1></div>
            <div class="col-4"><h3>
                <i class="fas fa-exclamation-triangle text-warning"></i> Oops!
                Usuario não autorizado.
              </h3></div>
          </div> -->
          <!-- <div class="row justify-content-center align-items-center">
            <div class="col-2">
              <h1 class="text-warning justify-content-center">401</h1>
            </div>
            <div class="col-12">
              <h3>
                <i class="fas fa-exclamation-triangle text-warning"></i> Oops!
                Usuario não autorizado.
              </h3>
            </div>
          </div> -->
        </div>
      </conteudo>
    </section>
  </template>
  <script>
  export default {
    name: "Found401View",
    created() {
    //   this.error();
    },
    methods: {
    //   error() {
    //     Toast.fire("Redirecionando", "", "error");
    //   },
    },
  };
  </script>