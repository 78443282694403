<template>
    <div class="row">
      
    <div class="col-12" >
      
        <ConsultaFerias />
        <div
          v-if="resultados.length == 0 && semResultados"
          class="mx-auto my-auto sem-resultados py-2"
        >
          <div>Sem resultados!</div>
        </div>
         
        <div v-if="resultados.length > 0">
    
        </div>
      </div>
    
    <loading :active="loaderActive" :is-full-page="fullPage" />
  </div>
</template>
<script>

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CadastroFerias from "@/components/rh/ferias/CadastroFerias.vue";
import { EventBus } from "@/main";
import ConsultaFerias from "@/components/rh/ferias/ConsultaFerias.vue";
import FeriasGozadas from "@/components/rh/ferias/FeriasGozadas.vue";

 
export default {

  components: {
    Loading,
    CadastroFerias,
    ConsultaFerias,
    FeriasGozadas
  },
  data() {
    return {
      tipoConsultaFuncionario: "nome",
      matriculaFuncionario: "",
      anoFerias: "",
      nomeFuncionario: "",
      mesFerias:[

                { id : 1, descricao: "Janeiro" },
                { id : 2, descricao: "Fevereiro" },
                { id : 3, descricao: "Março" },
                { id : 4, descricao: "Abril" },
                { id : 5, descricao: "Maio" },
                { id : 6, descricao: "Junho" },
                { id : 7, descricao: "Julho" },
                { id : 8, descricao: "Agosto" },
                { id : 9, descricao: "Setembro" },
                { id : 10, descricao: "Outubro" },
                { id : 11, descricao: "Novembro" },
                { id : 12, descricao: "Dezembro" },

            ],
      data:{},
      ferias:{},
      page: 0,
      resultados: [],
      loaderActive: false,
      fullPage: false,
      semResultados: false,
    };
  },

  // created() {
  //   this.ConsultaFerias();
  // },

  
  methods: {
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    
    },

    consultar(){},

    // cadastrarFerias(){
    //     EventBus.$emit("Ferias");
       
    // },

    // ConsultaFerias() {
    //   this.showPaginacao = true;
    //   this.showLoader();
    //   this.page;
    // },


    limpaInput() {
      this.matriculaFuncionario = "";
      this.cpfFuncionario = "";
      this.nomeFuncionario = "";
    },

     showModal() {
      this.isModalVisible = true;
    },

    
  
  }, 
};
</script>

<style scoped>
li {
  list-style-type: none;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.img-cut {
  max-width: 100px;
  max-height: 100px;

  height: 10vh;
  width: 10vh;
  object-fit: cover;
  object-position: center top;
}

.cardFuncionario {
  cursor: pointer;
}
</style>