var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"form-group col-lg-8"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome *")]),_c('input',{ref:"nomeRef",staticClass:"form-control form-control-border border-width-2",class:{
        disabled: !_vm.habilitarForms,
      },attrs:{"type":"text","id":"nome","placeholder":"Nome completo"},domProps:{"value":_vm.pessoa.nome},on:{"blur":_vm.validaExistenciaPessoaPorNomeMaeEDataNascimento,"input":function (event) { return (_vm.pessoa.nome = event.target.value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase()); }}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"sexo"}},[_vm._v("Sexo *")]),_c('v-select',{staticClass:"form-control form-control-border border-width-2",class:{
        disabled: !_vm.habilitarForms,
      },attrs:{"id":"sexo","options":['M', 'F', 'NI'],"label":"descricao"},model:{value:(_vm.pessoa.sexo),callback:function ($$v) {_vm.$set(_vm.pessoa, "sexo", $$v)},expression:"pessoa.sexo"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1),_c('div',{staticClass:"form-group col-lg-8"},[_c('label',{attrs:{"for":"nomemae"}},[_vm._v("Nome da mãe *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pessoa.mae),expression:"pessoa.mae"}],staticClass:"form-control form-control-border border-width-2",class:{
        disabled: !_vm.habilitarForms,
      },attrs:{"type":"text","id":"nomemae","placeholder":"Nome da mãe"},domProps:{"value":(_vm.pessoa.mae)},on:{"blur":_vm.validaExistenciaPessoaPorNomeMaeEDataNascimento,"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.pessoa, "mae", $event.target.value)},function (event) { return (_vm.pessoa.mae = event.target.value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase()); }]}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"dataNascimento"}},[_vm._v("Data de nascimento *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pessoa.dataNascimento),expression:"pessoa.dataNascimento"}],staticClass:"form-control form-control-border border-width-2",class:{
        disabled: !_vm.habilitarForms,
      },attrs:{"type":"date","id":"dataNascimento","placeholder":"dd/mm/aaaa"},domProps:{"value":(_vm.pessoa.dataNascimento)},on:{"blur":_vm.validaExistenciaPessoaPorNomeMaeEDataNascimento,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pessoa, "dataNascimento", $event.target.value)}}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"mb-3"},[_vm._v("Cadastrar dados básicos *")])])}]

export { render, staticRenderFns }