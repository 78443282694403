<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card card-primary">
        <div class="card-header background-principal">
          <h3 class="card-title">Buscar Servidor</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="exampleSelectBorderWidth2">Tipo</label>
            <select
              v-model="tipoConsultaFuncionario"
              class="custom-select form-control-border border-width-2"
              id="exampleSelectBorderWidth2"
              v-on:click="limpaInput"
            >
              <option disabled value="">Selecione</option>
              <option value="nome">Nome</option>
              <option value="matricula">Matrícula</option>
              <option value="novaMatricula">ID</option>
              <option value="cpf">CPF</option>
            </select>
          </div>
          <div v-show="tipoConsultaFuncionario == 'nome'">
            <div class="form-group">
              <label for="inputNome" class="pesquisar-quantidade"
                >Digite o Nome</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputNome"
                placeholder="Digite o nome"
                v-model="nomeFuncionario"
                required
                @keyup.enter="consultar"
              />
            </div>
          </div>
          <div v-show="tipoConsultaFuncionario == 'matricula'">
            <div class="form-group">
              <label for="inputMatricula" class="pesquisar-quantidade"
                >Digite a Matrícula</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputMatricula"
                placeholder="Digite o matrícula"
                v-model="matriculaFuncionario"
                @keyup.enter="consultar"
              />
            </div>
          </div>
          <div v-show="tipoConsultaFuncionario == 'novaMatricula'">
            <div class="form-group">
              <label for="inputNovaMatricula" class="pesquisar-quantidade"
                >Digite o ID</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputNovaMatricula"
                placeholder="Digite o ID"
                v-model="novaMatriculaFuncionario"
                @keyup.enter="consultar"
              />
            </div>
        </div>
          <div v-show="tipoConsultaFuncionario == 'cpf'">
            <div class="form-group">
              <label for="inputCPF" class="pesquisar-quantidade"
                >Digite o CPF</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputCPF"
                placeholder="Digite o CPF"
                v-mask="'###.###.###-##'"
                v-model="cpfFuncionario"
                @keyup.enter="consultar"
              />
            </div>
          </div>
        <ModalConsultaPessoa :fluxo="funcionario"/>
        </div>
        <div class="card-footer">
          <div class="form-group">
            <button
              @click.prevent="consultar"
              @keyup.enter="consultar"
              class="btn col-12 background-principal text-color-principal"
            >
              <i class="fas fa-search"></i>
              Buscar Servidor
            </button>
          </div>

          

          <div class="form-group">
            <b-button v-b-modal.modal-1 class="btn btn-success col-12"
              ><i class="fa-solid fa-user-plus"></i>Cadastrar
              Servidor</b-button
            >
          </div>

        
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="card card-primary">
        <div class="background-principal card-header text-color-principal">
          <h3 class="card-title">Servidor</h3>
          <div v-if="showPaginacao">
            <div class="float-right" v-if="resultados.length > 0">
              <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"
                ><i class="fas fa-angle-left mx-2 text-color-principal"></i
              ></a>
              Página {{ page + 1 }}
              <a href="#" v-on:click.prevent="page++"
                ><i class="fas fa-angle-right mx-2 text-color-principal"></i
              ></a>
            </div>
            <div class="float-right" v-if="resultados.length == 0">
              <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"
                ><i class="fas fa-angle-left mx-2 text-color-principal"></i
              ></a>
            </div>
          </div>
        </div>
        <div
          v-if="resultados.length == 0 && semResultados"
          class="mx-auto my-auto sem-resultados py-2"
        >
          <div>Sem resultados!</div>
        </div>
        <div v-if="resultados.length > 0">
          
          <div class="card-body px-0 d-flex flex-row">
            <div class="card-body table-responsive p-0 max-height-content">
              <div class="row px-5 d-flex justify-content-around">
                <div
                  :key="id"
                  v-for="(funcionario, id) in resultados"
                  class="card card-item bg-light col-sm-12 col-md-5"
                  @click="editarFuncionario(funcionario)"
                >
                  <a class="cardFuncionario">
                    <div>
                      <p
                        class="
                          header
                          card-item-result
                          background-principal
                          text-color-principal
                        "
                      >
                        {{
                          funcionario
                            ? funcionario.nome || "Não cadastrado"
                            : "Não Cadastrado"
                        }}
                      </p>
                    </div>
                    <div >
                      <div class="col-12 mx-0 row my-2 py-0" >
                        <div class="col-sm-12">
                          <ul class="ml-4 mb-0 fa-ul text-muted" >
                            <li class="medium">
                              <b>Matrícula: </b>
                              {{ funcionario.matricula || "Não cadastrado" }}
                            </li>
                             <li class="medium">
                              <b>Nova Matrícula: </b>
                              {{ funcionario.novaMatricula || "Não cadastrado" }}
                            </li> 
                            <li class="medium">
                              <b> CPF: </b>
                              {{ funcionario.cpf | formatarCPFCincoCaracteres }}
                            </li>
                            <li class="medium">
                              <b>Data de Admissão: </b>
                              {{ funcionario.admissao | formatarData }}
                            </li>
                            <li class="medium">
                              <b>E-mail: </b>
                              {{ funcionario.email || "Não cadastrado" }}
                            </li>
                            <li class="medium">
                              <b>Setor: </b>
                              {{ funcionario.unidade || "Não cadastrado" }}
                            </li>
                            <li>
                              <b>Status: </b>
                              {{ funcionario.ativo ? "Ativo" : "Inativo" }}
                              <i
                                :class="
                                  funcionario.ativo
                                    ? 'fa-solid fa-circle-check green'
                                    : 'fa-solid fa-circle-xmark red'
                                "
                              ></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :active="loaderActive" :is-full-page="fullPage" />
  </div>
</template>
  <script>
import { EventBus } from "@/main";
import ModalConsultaPessoa from "@/views/cadastros/pessoa/ModalConsultaPessoa.vue";
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import dayjs from "dayjs";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CadastroPessoaService from "@/service/CadastroPessoaService";

export default {
  name: "FormFuncionarioConsulta",
  components: {
    ModalConsultaPessoa,
    Loading,
    
  },
  data() {
    return {
      tipoConsultaFuncionario: "nome",
      matriculaFuncionario: "",
      novaMatriculaFuncionario:"",
      cpfFuncionario: "",
      nomeFuncionario: "",
      page: 0,
      resultados: [],
      loaderActive: false,
      fullPage: false,
      semResultados: false,
      funcionario: 'Funcionario',
    };
  },
  watch: {
    page(valorAtual, valorAnterior) {
      this.consultarTodosFuncionarios();
    },
  },
  created() {
    this.consultarTodosFuncionarios();
  },
  filters: {
    formatarData: function (data) {
      if (data == "" || data == null) {
        return "Não cadastrado";
      } else {
        const [ano, mes, dia] = data.split("-");
        const dataFormatada = `${dia}/${mes}/${ano}`;
        return dataFormatada;
      }
    },
    formatarCPFCincoCaracteres: function (cpf) {
      if (cpf !== null) {
        const cpfFormatado = cpf.substring(4, 10);
        return "***." + cpfFormatado + "*-**";
      } else {
        return "Não Cadastrado";
      }
    },
  },
  methods: {
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    consultarTodosFuncionarios() {
      this.showPaginacao = true;
      this.showLoader();
      this.page;
      consultaFuncionarioService
        .buscarFuncionario(this.page)
        .then((response) => {
          this.resultados = response.data;
          this.hideLoader();
        })
        .catch((e) => {
          this.hideLoader();
          this.semResultados = true;
          this.alertaFalhaConsultarTodosFuncionarios();
        });
    },
    alertaFalhaConsultarTodosFuncionarios() {
      Toast.fire("Falha ao carregar funcionários", "", "error");
    },
    editarFuncionario(funcionario) {
      this.showLoader();
      consultaFuncionarioService.buscarFuncionarioId(funcionario.id).then(
        (response) => {
         
          this.$store.commit("funcionarioCadastrado", response.data.id);
          this.$store.commit("funcionarioEditar", response.data);
          this.$store.commit("pessoaFuncionario", response.data.pessoaCorrespondente.id);
          

          CadastroPessoaService.buscarPessoaCompletaPorId(response.data.pessoaCorrespondente.id).then(
          (response) => {
            this.$store.commit("setPessoaFuncionario",  response.data);
            this.$router.push({ name: 'cadastrofuncionario'});
          });
          
          this.$store.commit("visualizarListaFuncionariosExistentes", false);
          //this.$store.commit("CadastrarAnotacao", response.data.id);
          
          
          
          // EventBus.$emit("editarFuncionario", response.data)
          // EventBus.$emit("fecharModalEPesquisar", funcionario.idpessoa);

   

        
        }
      );
    },
    consultar() {  
      this.showLoader();

      const params = {
        tipoConsultaFuncionario: this.tipoConsultaFuncionario,
        parametroPrincipalFuncionario:
          this.nomeFuncionario ||
          this.cpfFuncionario ||
          this.matriculaFuncionario ||
          this.novaMatriculaFuncionario,
      }


      if (params.parametroPrincipalFuncionario === "") {
        this.consultarTodosFuncionarios()
      } else {
        this.showPaginacao = false;
        let parametroPrincipal = params.parametroPrincipalFuncionario.toUpperCase();
        if (params.tipoConsultaFuncionario === 'cpf') {
          this.consultaFuncionarioCPF(parametroPrincipal);
        } if (params.tipoConsultaFuncionario === 'nome') {
          this.consultaFuncionarioNome(parametroPrincipal)

        } if (params.tipoConsultaFuncionario === 'novaMatricula') {
          this.consultaFuncionarioNovaMatricula(parametroPrincipal)
        } else {
          this.consultaFuncionarioMatricula(parametroPrincipal)
        }
      }
    },
    consultaFuncionarioNome(parametro) {
      let pagina = 0;
      consultaFuncionarioService.buscarFuncionarioNome(pagina, parametro).then((response) => {
        this.resultados = response.data;
        this.semResultados = false;
        this.hideLoade();
      }).catch((e) => {
        this.semResultados = true
        this.hideLoader();
      })
    },
    consultaFuncionarioMatricula(parametro) {
      let pagina = 0;
      consultaFuncionarioService.buscarFuncionarioMatricula(pagina, parametro).then((response) => {
        this.resultados = response.data;
        this.semResultados = false;
        this.hideLoader();
      }).catch((e) => {
        this.semResultados = true
        this.hideLoader();
      })

     
    },

    consultaFuncionarioCPF(parametro) {
      let pagina = 0;
      consultaFuncionarioService.buscarFuncionarioCPF(pagina, parametro).then((response) => {
        this.resultados = response.data;
        this.semResultados = false;
        this.hideLoader();
      }).catch((e) => {
        this.semResultados = true
        this.hideLoader();
      })
    },

    consultaFuncionarioNovaMatricula(parametro) {
      let pagina = 0;
      consultaFuncionarioService.buscarFuncionarioNovaMatricula(pagina, parametro).then((response) => {
        this.resultados = response.data;
        this.semResultados = false;
        this.hideLoader();
      }).catch((e) => {
        this.semResultados = true
        this.hideLoader();
      })
    },


    
    // emitirConsulta() {
    //   const params = {
    //     tipoConsultaFuncionario: this.tipoConsultaFuncionario,
    //     parametroPrincipalFuncionario:
    //       this.nomeFuncionario ||
    //       this.cpfFuncionario ||
    //       this.matriculaFuncionario,
    //   };
    //   EventBus.$emit("consultaFuncionario", params);
    // },
    limpaInput() {
      this.matriculaFuncionario = "";
      this.cpfFuncionario = "";
      this.nomeFuncionario = "";
      this.novaMatriculaFuncionario ="";
    },

  
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.img-cut {
  max-width: 100px;
  max-height: 100px;

  height: 10vh;
  width: 10vh;
  object-fit: cover;
  object-position: center top;
}

.cardFuncionario {
  cursor: pointer;
}
</style>
