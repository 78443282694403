<template>
     <div class="card-body pt-0">

        <div class=" p-1, .ml-1 (margin-right: 4px;)">
        <FiltroTabelaCadastroLicitante/>
          
        </div>

        <!--b-form inline>
          <b-form-input  class="mr-3" placeholder="IdContratacao" v-model="filter" ></b-form-input>
          <b-form-input  class="mr-3" placeholder="CpfCnpj" v-model="filter.CpfCnpj" ></b-form-input>
          <b-button @click="filterLicitante" class="mr-2" variant="outline-secondary">
            Buscar
          </b-button>
          <b-button title="Limpar filtro" v-b-tooltip.hover @click="clearFilter"
          ><b-icon-trash class="icon"></b-icon-trash
          ></b-button>
        </b-form-->
  
    <Loader :active="loaderActive" message="Consultando..." />
  <table v-show="!loaderActive" class="table table-hover text-nowrap" >
      <thead>
        <tr>
          <th>Id de Contratação</th>
          <th>Estrangeiro</th>
          <th>Cpf e Cnpj</th>
          <th>Numero</th>
          <th>Ano</th>
          <th>Ações</th>

        </tr>
      </thead>
      <tbody>
        <tr class= "clicavel" @click="funcao(licitante.id)" v-for = "(licitante, index) in cadastrosLicitante" :key="index">

          <td>{{ licitante.idContratacao || "Sem registro" }}</td>
          <td>{{ licitante.estrangeiro || "Sem registro"}}</td>
          <td>{{ licitante.cpfCnpj || "Sem registro"}}</td>
          <td>{{ licitante.numero || "Sem registro"}}</td>
          <td>{{ licitante.ano || "Sem registro"}}</td>
          <td @click.stop>
            <button  @click.prevent="downloadFile(licitante.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm gerar">
              <i class="fa-solid fa-file"></i>
            </button>
            <button @click.prevent="excluir(licitante.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm excluir">
              <i class="fa-solid fa-trash"></i>
            </button>
          </td>
         

        </tr>
      </tbody>
    </table>
    </div>

</template>

<script>
import processosService from '@/service/ProcessosService';
import { EventBus } from '@/main';
import Loader from "../Loader.vue";
import FiltroTabelaCadastroLicitante from "@/components/processos/FiltroTabelaCadastroLicitante.vue";
import axios from 'axios';
import CadastroLicitante from "@/components/processos/CadastroLicitante.vue";


export default ({
  name:'TblCadastroLicitante',
  components:{
      Loader,
      FiltroTabelaCadastroLicitante
    },

    data(){
      return{
        loaderActive:false,
        cadastrosLicitante:{},
        licitante:{},
        tablicitante_reload:1,
        filter:"",
        
      }
    },

    //computed:{
       //cadastrosLicitante(){
        //let valores=[];
        //valores = this.cadastrosLicitante.filter((licitante)=>{
         // return(
            //licitante.idContratacao.toLowerCase().indexOf(this.filter.toLowerCase()) >-1 ||
           // licitante.cpfCnpj.toLowerCase().indexOf(this.filter.toLowerCase()) >-1 ||
           // licitante.numero.toLowerCase().indexOf(this.filter.toLowerCase()) >-1 

         // );
       // });

      //  return valores;

     //  }
   // },

    methods:{

     // clearFilter(){
     //   this.filter = "";
     // },



      showLoader() {
        this.loaderActive = true;
      },
      hideLoader() {
        this.loaderActive = false;
      },

      

      funcao(licitante){
        EventBus.$emit("AlterarTabCadastroLicitante")
        EventBus.$emit("EnvioCadastroLicitante", licitante)

      },

        enviarFormulario(){
            this.dados.push({
                idContratacao: this.idContratacao,
                estrangeiro: this.estrangeiro,
                cpfCnpj:this.cpfCnpj,
                numero: this.numero,
                ano: this.ano,
                
            })
        },

        excluir(id){
          this.$swal.fire({
          title: "Você deseja excluir cadastro?",
          icon:"warning",
          showCancelButton: true,
          confirmButtonColor:"#3085d6",
          confirmButtonText: "#Excluir",
          cancelButtonText: "Cancelar",
          }).then((result) => {

            if(result.isConfirmed){
              processosService.apagarCadastroLicitante(id).then((response) => {
                this.cadastrosLicitante = response.data;
                Toast.fire("Cadastro excluído com sucesso!", "","success");
                this.buscarCadastroLicitante();

            });
            }
            this.buscarCadastroLicitante(0);
            
          });

        },

        buscarCadastroLicitante(){
          processosService.buscarCadastroLicitante().then(response => {
            this.cadastrosLicitante = response.data; 
          
          })
        },

        downloadFile(id) {
              axios({
                    url: `http://localhost:8089/api/download/cadastro-licitantes/${id}`, // File URL Goes Here
                    method: 'GET',
                   responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'cadastroLicitante.json');
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
          },
        

      
        
    },
    created(){

        
      this.showLoader()
      processosService.buscarCadastroLicitante().then((resp)=>{
            this.cadastrosLicitante= resp.data;
            this.hideLoader()
          }).catch((e) =>{
            this.hideLoader()
              Toast.fire("Erro ao consultar Licitação", "", "error");
          } );

      this.downloadFile();
    }



});


</script>

<style scoped>
.clicavel{
  cursor: pointer;
}
</style>
