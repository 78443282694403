<template>
    <div class="card-body pt-0">
        <div class=" p-1, .ml-1 (margin-right: 4px;)">
            <FiltroTabelaProcedimento/>
        </div>


    <Loader :active="loaderActive" message="Consultando..." />
    <table v-show="!loaderActive" class="table table-hover text-nowrap">
      <thead>
        <tr>
            <th>CnpjUg</th>
            <th>Numero do Procedimento</th>
            <th>Numero da Lei</th>
            <th>Cod do Procedimento</th>
            <th>Cpf da Autoridade</th>
            <th>Ações</th>
            
        </tr>
      </thead>
      <tbody>
        <tr class= "clicavel"  @click="funcao(procedimento.id)"  v-for = "(procedimento, index) in procedimentos" :key="index">
          <td>{{ procedimento.cnpjUg || "Sem registro" }}</td>
          <!--td>{{ procedimento.idContratação || "Sem registro" }}</td-->
          <!--td>td>{{ procedimento.numeroProcesso || "Sem registro"}}</td-->
          <!--td>td>{{ procedimento.anoProcesso || "Sem registro"}}</td-->
          <td>{{ procedimento.numeroProcedimento || "Sem registro" }}</td>
          <!--td>td>{{ procedimento.anoProcedimento || "Sem registro"}}</td-->
          <!--td>td>{{ updateZonedDateTimeField(procedimento.dataPublicacao)}}</td-->
          <td>{{ procedimento.numeroLei || "Sem registro"}}</td>
          <!--td>td>{{ procedimento.anoLei || "Sem registro"}}</td-->
          <td>{{ procedimento.codProcedimento || "Sem registro"}}</td>
          <!--td>td>{{ procedimento.criterio || "Sem registro"}}</td-->
          <!--td>td>{{ procedimento.sistemaPregao || "Sem registro" }}</td-->
          <!--td>td>{{ procedimento.finalidade || "Sem registro"}}</td-->
          <!--td>td>{{ updateZonedDateTimeField(procedimento.dataAdesao) }}</td-->
          <!--td>td>{{ procedimento.regimeExecucao || "Sem registro"}}</td-->
          <!--td>td>{{ procedimento.objeto || "Sem registro" }}</td-->
          <!--td>td>{{ procedimento.valorEstimado || "Sem registro"}}</td-->
          <!--td>td>{{ updateZonedDateTimeField(procedimento.dataSessao) }}</td-->
          <td>{{ procedimento.cpfAutoridade || "Sem registro" }}</td>
            <td @click.stop>
              <button  @click.prevent="downloadFile(procedimento.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm gerar">
                <i class="fa-solid fa-file"></i>
              </button>
              <button @click.prevent="excluir(procedimento.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm excluir">
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
         
        </tr>
      </tbody>
    </table>
    </div>

</template>

<script>
import processosService from '@/service/ProcessosService';
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import { EventBus } from '@/main';
import Loader from "../Loader.vue";
import FiltroTabelaProcedimento from "@/components/processos/FiltroTabelaProcedimento.vue"
import axios from 'axios';

export default ({
   components:{
      Loader,
      FiltroTabelaProcedimento
    },
    data(){
       
        return {
            loaderActive:false,
            procedimentos: {},
            procedimento:{}
        }
    },
  

    methods:{

        showLoader() {
          this.loaderActive = true;
        },
        hideLoader() {
          this.loaderActive = false;
        },

       funcao(procedimento){
        EventBus.$emit("AlterarTabProcemento")
        EventBus.$emit("EnvioProcedimento",procedimento)

      },
        enviarFormulario(){
            this.dados.push({

                cnpjUg: this.cnpjUg,
                idContratacao: this.idContratacao,
                numeroProcesso: this.numeroProcesso,
                anoProcesso: this.anoProcesso,
                numeroProcedimento: this.numeroProcedimento,
                anoProcedimento: this.anoProcedimento,
                dataPublicacao: this.dataPublicacao,
                numeroLei: this.numeroLei,
                anoLei: this.anoLei,
                codProcedimento: this.codProcedimento,
                criterio: this.criterio,
                sistemaPregao: this.sistemaPregao,
                finalidade: this.finalidade,
                dataAdesao: this.dataAdesao,
                regimeExecucao: this.regimeExecucao,
                objeto: this.objeto,
                valorEstimado: this.valorEstimado,
                dataSessao: this.dataSessao,
                cpfAutoridade: this.cpfAutoridade,
               
            })
        },

        excluir(id){
          this.$swal.fire({
          title: "Você deseja excluir ata?",
          icon:"warning",
          showCancelButton: true,
          confirmButtonColor:"#3085d6",
          confirmButtonText: "#Excluir",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            processosService.apagarProcedimento(id).then((response) => {
              this.procedimentos = response.data;
              this.buscarProcedimento();
              Toast.fire("Procedimento excluído com sucesso!", "","success");
            });
          }
          this.buscarProcedimento(0);
        });

        },

        buscarProcedimento(){
          processosService.buscarProcedimento(this.procedimentos).then((resp)=>{
          this.procedimentos= resp.data;
          })
        },

      updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
      },

      downloadFile(id) {
              axios({
                    url: `http://localhost:8089/api/download/procedimentos/${id}`, // File URL Goes Here
                    method: 'GET',
                   responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'procedimento.json');
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
          }
    ,
        
    },

    // filters: {
    //formatarData: function (data) {
     // if (data == "" || data == null) {
       // return 'Sem data registrada';
      //}
     // else {

       // const [date, horas] = data.split("T");
       // const[ano,mes,dia]=date.split("-");
       // const[hr,min,sec]=horas.split(":");


      //  const dataHoraFormatada = `${dia}/${mes}/${ano} ${hr}:${min}:${sec.replace("Z","")}`;
     //
       // return dataHoraFormatada;
     // }
    //},
  //},


created(){
  this.downloadFile();
  this.showLoader()
  processosService.buscarProcedimento(this.procedimentos).then((resp)=>{
        this.procedimentos= resp.data;
        this.hideLoader()
      }).catch((e) =>{
         this.hideLoader()
          Toast.fire("Erro ao consultar Procedimento", "", "error");
      } );
}



});


</script>

<style scoped>

.clicavel{
  cursor: pointer;
}

</style>