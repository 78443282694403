<template>
  <section class="content" v-on="isModalVisible || isModalVisibleNacional ? { click: closeModal } : {}">
    <content-header title="Consulta SIGMA" />
    <conteudo v-role="'CONSULTA_INDIVIDUO'">
      <div class="container-fluid pt-3 px-4">
        <DetalhesConsultaPessoa v-show="isModalVisible" @closeModalPessoa="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisible"></div>
        <DetalhesConsultaPessoaNacional v-show="isModalVisibleNacional" @closeModalPessoaNacional="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisibleNacional"></div>

        <div class="row">
          <div class="card-body p-0 col-md-3 col-sm-12">
            <div class="card card-primary ">
              <div class="card-header background-principal">
                <h3 class="card-title">
                  {{ tituloBusca }}
                </h3>
              </div>
              <div class="card-body d-flex justify-content-center row">
                <div class="custom-control custom-radio px-3">
                  <input class="custom-control-input" type="radio" id="buscaSigma" name="tipobusca"
                    v-model="baseDaPesquisa" value="FormConsultaPessoa" checked />
                  <label for="buscaSigma" class="custom-control-label">SIGMA</label>
                </div>
                <div class="custom-control custom-radio px-3">
                  <input class="custom-control-input" type="radio" id="buscaNacional" name="tipobusca"
                    v-model="baseDaPesquisa" value="FormConsultaNacionalPessoa" />
                  <label for="buscaNacional" class="custom-control-label">NACIONAL</label>
                </div>
              </div>
              <transition mode="out-in">
                <component :is="baseDaPesquisa"></component>
              </transition>
            </div>

          </div>
          <div class="col-md-9">
            <transition mode="out-in">
              <component @showModalPessoa="showModal" :is="baseDaPesquisaResultados"></component>
            </transition>
          </div>
        </div>
      </div>
    </conteudo>
  </section>
</template>

<script>
import DetalhesConsultaPessoa from '../../../components/consultas/pessoa/DetalhesConsultaPessoa.vue'
import DetalhesConsultaPessoaNacional from '../../../components/consultas/pessoa/DetalhesConsultaPessoaNacional.vue'
import FormConsultaNacionalPessoa from '../../../components/consultas/pessoa/FormConsultaNacionalPessoa.vue'
import FormConsultaPessoa from '../../../components/consultas/pessoa/FormConsultaPessoa.vue'
import ResultadosConsulta from '../../../components/consultas/pessoa/ResultadosConsulta.vue'
import ResultadosConsultaNacional from '../../../components/consultas/pessoa/ResultadosConsultaNacional.vue'

export default {
  methods: {
    showModal() {
      if (this.baseDaPesquisaResultados == 'ResultadosConsulta') {
        this.isModalVisible = true
      }
      if (this.baseDaPesquisaResultados == 'ResultadosConsultaNacional') {
        this.isModalVisibleNacional = true
      }

    },
    closeModal() {
      this.isModalVisible = false
      this.isModalVisibleNacional = false
    },
  },
  components: {
    ResultadosConsulta,
    ResultadosConsultaNacional,
    FormConsultaPessoa,
    FormConsultaNacionalPessoa,
    DetalhesConsultaPessoa,
    DetalhesConsultaPessoaNacional
  },
  created() { },

  data() {
    return {
      baseDaPesquisa: 'FormConsultaPessoa',
      isModalVisible: false,
      isModalVisibleNacional: false,
      baseDaPesquisaResultados: 'ResultadosConsulta',
      tituloBusca: 'Buscar Pessoa'
    }
  },
  watch: {
    baseDaPesquisa(valorAtual, valorAnterior) {
      const consultaNacional = valorAtual === 'FormConsultaNacionalPessoa'
      if (consultaNacional) {
        this.baseDaPesquisaResultados = 'ResultadosConsultaNacional'
      } else {
        // consulta sigma
        this.baseDaPesquisaResultados = 'ResultadosConsulta'
      }
    }
  }
}
</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}
</style>
