<template>
  <form>

    <div class="card card-primary">
      <div class="card-header background-principal">
        <h3 class="card-title">Busca por Documento</h3>
        <!-- /.card-tools -->
      </div>

      <div class="card-body">

        <div class="form-group">
          <label for="inputTipoDocumento" class="pesquisar-tipo-documento">Selecione o tipo do documento</label>
          <!-- <select type="select" class="form-control form-control-border border-width-2" id="inputMarca"
            placeholder="Selecione o tipo do documento" v-model="tipoDocumento">
            <option disabled value="">Selecione um tipo de documento</option>
            <option :key="id" v-for="(tiposDocumento, id) in tiposDocumento" :value=tiposDocumento.id> {{
                tiposDocumento.descricao
            }}</option>
          </select> -->
          <v-select class="border-v-select" id="inputCorDoCarro" v-model="tipoDocumento"
            :disabled="
              tiposDocumento == null ||
              tiposDocumento == []
            " :options="tiposDocumento" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div v-if="tipoDocumento != ''" class="form-group">
          <label for="inputNumero" class="pesquisar-numero">Digite a numero</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputNumero"
            placeholder="Digite a numero" v-model="numeroDocumento" />
        </div>
      </div>
      <!-- /.card-header -->
      <!-- </div> -->

      <div class="card-footer">
        <button @click.prevent="emitirConsulta" v-on:keyup.enter="emitirConsulta" type="submit"
          class="btn col-12 background-principal text-color-principal">
          <i class="fas fa-search"></i>
          Buscar
        </button>
      </div>

      <!-- /.card-body -->
      <!-- /.card-footer -->
    </div>
    <!-- /.card -->
  </form>

</template>

<script>
import ConsultaListasService from '@/service/ConsultaListasService.js';
import { EventBus } from '../../../main.js';
import LocalIpService from "@/service/LocalIpService";

export default {
  name: 'FormDocumentoConsulta',
  data() {
    return {
      tipoDocumento: '',
      tiposDocumento: [],
      numeroDocumento: '',
      page: 1,
      //ipUsuario:null
    }
  },
  created() {
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    ConsultaListasService.buscarListaPorNomeTabela('tipo_documento')
      .then((response) => {
        this.tiposDocumento = response.data
      })
      .catch((e) => this.alertaFalhaBuscarListaPorNomeTabela());
  },
  methods: {
    mostrarAlerta() {
      // eslint-disable-next-line no-undef
      Toast.fire('Preencha ao menos um campo!!', '', 'error')
    },
    formValido() {
      if (
        this.tipoDocumento ||
        this.numeroDocumento
      ) {
        return true
      }
      return false
    },
    emitirConsulta() {
      if (this.formValido()) {
        const params = {
          tipoDocumento: this.tipoDocumento ? this.tipoDocumento.id : '',
          numeroDocumento: this.numeroDocumento,
          page: this.page,
          //ipUsuario:this.ipUsuario
        }
        EventBus.$emit('consultaDocumento', params)
      } else {
        this.mostrarAlerta()
      }
    },
    alertaFalhaBuscarListaPorNomeTabela() {
      Toast.fire("Falha ao buscar tipo do documento", "", "error");
    },
  }
}
</script>

<style>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}
</style>
