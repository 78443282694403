<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="resultado-idContratacao">Id Contratacao</label>
          <input type="text" class="form-control form-control-border" name="idContratacao" id="resultado-idContratacao"
            data-cy="idContratacao" :class="{
              'is-invalid': isSaving && $v.resultado.idContratacao.$error,
            }" v-model="resultado.idContratacao" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="resultado-licitacao">Licitação</label>
          <v-select class="border-v-select" id="resultado-licitacao" :class="{
            'is-invalid': isSaving && $v.resultado.licitacao.$error,
          }" data-cy="licitacao" name="licitacao" v-model="resultado.licitacao" :options="licitacao"
            :reduce="licitacao => licitacao.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <!--div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="resultado-data">Data</label>
          <div class="d-flex">
            <input id="resultado-data" data-cy="data" type="datetime-local" class="form-control form-control-border"
              name="data" :class="{
                'is-invalid': isSaving && $v.resultado.data.$error,
              }" :model="convertDateTimeFromServer(resultado.data)"
              @change="updateZonedDateTimeField('data', $event)" />
          
          </div>
        </div>
      </div-->

      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="resultado-data"
              >Data</label
              >
            <div class="d-flex">
              <input
                type="date" class="form-control form-control-border border-width-2" id="resultado-data"
                  placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(resultado.data)" :class="{
                    'is-invalid': isSaving && $v.resultado.data.$error,
                      }"
                  @change="updateZonedDateTimeField('data', $event)"
              />
            </div>
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="resultado-valor">Valor</label>
          <input type="number" class="form-control form-control-border" name="valor" id="resultado-valor"
            data-cy="valor" :class="{
              'is-invalid': isSaving && $v.resultado.valor.$error,
            }" v-model="resultado.valor" />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-right">
        <!--button class="btn btn-default mr-1">
          <i class="fas fa-pencil-alt"></i> Rascunho
        </button-->
        <button @click="salvarResultado" class="btn text-color-principal background-principal ml-1">
          <i class="far fa-envelope"></i> Enviar
        </button>
          <button @click="limparResultado" type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Limpar
      </button>
      </div>
      <!--button type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Cancelar
      </button-->
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import { DATE_TIME_LONG_FORMAT, DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import dayjs from "dayjs";
import { EventBus } from "@/main";
import processosService from '@/service/ProcessosService';
import TabelaResultado from "@/components/processos/TabelaResultado";

export default {
  name: "Ata-Registro",
  components: {
    TabelaResultado},
  data() {
    return {
      data:{},
      resultado: {},
      submitted: false,
      isSaving: false,
      setores: [],
      funcionarios: [],
      licitacao: [
        { id: 1, descricao: "Anulada" },
        { id: 2, descricao: "Deserta" },
        { id: 3, descricao: "Fracassada/Cancelada" },
        { id: 4, descricao: "Revogada" },
        { id: 5, descricao: "Homologada/Ratificada" }
      ]
    };
  },

   
  validations: {
    resultado: {
      idContratacao: {},
      licitacao: {},
      data: {},
      valor: {},
      //valor: { required },
      
      
    },
  },

  created() {
      EventBus.$on("EnvioResultado",(listener)=>{
         this.getResultado(listener);
        
      }),

      EventBus.$on("ApagarCamposResultado",()=>{
        this.resultado = {};
      })
     
    },

  methods: {
    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

    limparResultado(){
      this.resultado={};
      document.getElementById("resultado-data").value="";
    },

    getResultado(id){
      processosService.this.BuscarResultado(id).then((resp)=>{
        this.resultado= resp.data;
      })
    },

   
    salvarResultado(){
    console.log("SALVAR RESULTADO")
    this.isSaving = true;
    this.$v.$touch();
 
      if (this.$refs["resultado"]!= undefined){
        this.resultado = Object.assign(
          {},
          this.resultado,
          this.$refs["resultado"].resultado
        );
 
        this.$refs["resultado"].isSaving = this.isSaving;
        if (this.$v.$ivalid || this.$refs["resultado"].$v.$ivalid){
          return;
        } else{
          if (this.$v.$ivalid){
            return;
 
 
          }
        }
 
      }
      processosService.criarResultado(this.resultado)
      .then((response)=> {
        this.resultado= response.data;
        Toast.fire("Cadastrado realizado", "", "success");
        this.resultado={};
        document.getElementById("resultado-data").value="";
 
      })

      },
      
    


    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.resultado[field] = dayjs(
          event.target.value,
          DATE_FORMAT_LOCAL_DATA
        );
      } else {
        this.resultado[field] = null;
      }
    },

  
  // mounted() {
  //   this.carregaSetores();
  //   this.carregaFuncionario();
  // }

}
}


</script>

