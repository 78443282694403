<template>
  <form>
    <div class="card-body">
      <div class="row">
        <div class="col-6 form-group">
          <label for="numeroOcorrencia">Número</label>
          <input type="email" class="form-control form-control-border border-width-2" id="numeroOcorrencia"
            placeholder="Ex.: 102" v-model="numeroOcorrencia" />
        </div>

        <div class="col-6 form-group">
          <label for="ano">Ano</label>
          <input type="text" class="form-control form-control-border border-width-2" id="ano" placeholder="Ex.: 2022"
            v-model="ano" />
        </div>
      </div>

      <div class="row">
        <div class="col-6 form-group mx-0">
          <label for="dataFatoInicio">Início do fato</label>
          <input type="date" class="form-control form-control-border border-width-2" id="dataFatoInicio"
            placeholder="dd/mm/aaaa" v-model="dataFatoInicio" />
        </div>

        <div class="col-6 form-group">
          <label for="dataFatoFim">Fim do fato</label>
          <input type="date" class="form-control form-control-border border-width-2" id="dataFatoFim"
            placeholder="dd/mm/aaaa" v-model="dataFatoFim" />
        </div>
      </div>

      <div class="row">
        <div class="col-6 form-group mx-0">
          <label for="dataRegistroInicio">Início do registro</label>
          <input type="date" class="form-control form-control-border border-width-2" id="dataRegistroInicio"
            placeholder="dd/mm/aaaa" v-model="dataRegistroInicio" />
        </div>

        <div class="col-6 form-group">
          <label for="dataRegistroFim">Fim do registro</label>
          <input type="date" class="form-control form-control-border border-width-2" id="dataRegistroFim"
            placeholder="dd/mm/aaaa" v-model="dataRegistroFim" />
        </div>
      </div>

      <div class="form-group">
        <label class="pesquisar-Cor-carro" for="estado">Estado</label>
        <v-select class="border-v-select" id="estado" v-model="estado"
          :options="estados" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>

      <div class="form-group" v-if="estado !== '' && estado !== null">
        <label class="pesquisar-Cor-carro" for="cidade">Cidade</label>
        <v-select class="border-v-select" id="cidade" v-model="cidade"
          :options="cidades" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>

      <div class="form-group" v-if="cidade !== '' && cidade !== null">
        <label class="pesquisar-Cor-carro" for="bairro">Bairro</label>
        <v-select class="border-v-select" id="bairro" v-model="bairro"
          :options="bairros" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>

      
      <div class="form-group"    >
        <label class="pesquisar-Cor-carro"  for="unidade"  >Unidade</label>
        <v-select class="border-v-select"   v-model="unidade" id="unidadeBo"
        :options="unidades" item-text="text" label="descricao">
         
          <span slot="no-options">Desculpe, sem opções no momento.</span>
<!-- 
          <template v-slot:selection="{unidades, index}">
            <v-chip v-if="index<2">
              <span>{{unidades.title}}</span>
            </v-chip>

            <span  
              v-if="index===2"
              class="text-grey text-caption align-self-center ">
              (+{{unidade.length  -2}} others)
            </span> >

          </template-->
        </v-select>

      </div>
      

      <div class="form-group">
        <label class="pesquisar-Cor-carro" for="grupo">Grupo</label>
        <v-select class="border-v-select" id="grupo" multiple v-model="grupo" 
        :options="grupos"
          label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>

      <div class="form-group">
        <label class="pesquisar-Cor-carro" for="natureza">Natureza</label>
        <v-select class="border-v-select" id="natureza" multiple v-model="natureza"
          :options="naturezas" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>

      <div class="form-group">
        <label class="pesquisar-Cor-carro" for="motivacao">Motivação</label>
        <v-select class="border-v-select" id="motivacao" multiple v-model="motivacao"
          :options="motivacaos" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>


      <div class="form-group">
        <label for="nomeEnvolvido">Nome envolvido</label>
        <input type="text" class="form-control form-control-border border-width-2" id="nomeEnvolvido"
          placeholder="Ex.: Maria das Flores" v-model="nomeEnvolvido" />
      </div>

      <div class="form-group">
        <label for="historicoOcorrencia">Histórico da ocorrência</label>
        <input type="text" class="form-control form-control-border border-width-2" id="historicoOcorrencia"
          placeholder="Ex.: faca" v-model="historicoOcorrencia" />
      </div>
    </div>
    <div class="card-footer">
      <button @click.prevent="emitirConsultaBo" v-on:keyup.enter="emitirConsultaBo" type="submit"
        class="btn col-12 background-principal text-color-principal">
        <i class="fas fa-search"></i>
        Buscar
      </button>
    </div>
  </form>
</template>

<script>
import { EventBus } from "../../../main.js";
import ConsultaListasService from "../../../service/ConsultaListasService";
import LocalIpService from "@/service/LocalIpService";
export default {
  name: "FormConsultaBo",

  created() {
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    this.preencherListas(['estado', 'unidade', 'motivacao', 'natureza', 'grupo']);
  },
  watch: {
    estado() {

      this.buscarCidadesDoEstado(this.estado.id);
      if (this.cidade) {
        this.cidade = ""
        this.bairro = ""
      }

    },

    cidade() {
      this.buscarBairrosDaCidade(this.cidade.id);
      if (this.bairro) {
        this.bairro = ""
      }

    },

  },
  data() {
    return {
      numeroOcorrencia: "",
      ano: "",
      dataFatoInicio: "",
      dataFatoFim: "",
      dataRegistroInicio: "",
      dataRegistroFim: "",
      estados: [],
      estado: {
        descricao:"Maranhão",
        id:10
      },
      cidades: [],
      cidade: "",
      bairros: [],
      bairro: "",
      unidade: "",
      unidades: [],
      grupo: "",
      grupos: [],
      natureza: "",
      naturezas: [],
      motivacao: "",
      motivacaos: [], // erro gramatical proposital
      nomeEnvolvido: "",
      historicoOcorrencia: "",
      page: 1,
      //ipUsuario: null
    };
  },



  methods: {

    buscarCidadesDoEstado(id) {
      ConsultaListasService.buscarCidadesPorIdEstado(id)
        .then((response) => {
          this.cidades = response.data
        })
        .catch((e) => this.alertaFalhaBuscarCidades())
    },
    buscarBairrosDaCidade(id) {
      ConsultaListasService.buscarBairrosPorIdCidade(id)
        .then((response) => {
          this.bairros = response.data
        })
        .catch((e) => this.alertaFalhaBuscarBairros())
    },
    preencherListas(nomeTabelas) {
      nomeTabelas.forEach(tabela => {
        ConsultaListasService.buscarListaPorNomeTabela(tabela)
          .then((response) => {
            this[`${tabela.toString()}s`] = response.data
          })
          .catch((e) => this.alertaFalhaBuscarListaPorNomeTabelas())
      })


    },
    revetDateString(dateStr) {
      if (dateStr) {
        let date = dateStr.split("-");
        return `${date[2]}-${date[1]}-${date[0]}`;
      }
      return "";
    },
    mostrarAlerta() {
      Toast.fire("Preencha ao menos um campo!!", "", "error");
    },
    formValido(params) {

      // this.parametrosValidos(params)
    },
    alertaFalhaBuscarBairros() {
      Toast.fire("Falha ao buscar bairros", "", "error");
    },
    alertaFalhaBuscarCidades() {
      Toast.fire("Falha ao buscar cidades", "", "error");
    },
    alertaFalhaBuscarListaPorNomeTabelas() {
      Toast.fire("Falha ao buscar lista", "", "error");
    },


    emitirConsultaBo() {
      const params = {
        numeroOcorrencia: this.numeroOcorrencia,
        anoOcorrencia: this.ano,
        historicoBo: this.historicoOcorrencia,
        dataRegistroInicio: this.dataRegistroInicio,
        dataRegistroFim: this.dataRegistroFim,
        dataFatoInicio: this.dataFatoInicio,
        dataFatoFim: this.dataFatoFim,
        naturezaFato: this.natureza ? this.natureza.id : '',
        grupoFato: this.grupoFato ? this.grupoFato.id : '',
        unidade: this.unidade ? this.unidade.id : '',
        nomeEnvolvido: this.nomeEnvolvido,
        situacao: this.situacao,
        tipoLocal: this.tipoLocal,
        tipoNatureza: this.tipoNatureza ? this.tipoNatureza.id : '',
        motivacao: this.motivacao ? this.motivacao.id : '',
        pais: this.pais ? this.pais.id : '',
        estado: this.estado ? this.estado.id : '',
        cidade: this.cidade ? this.cidade.id : '',
        bairro: this.bairro ? this.bairro.id : '',
        page: this.page,
        //ipUsuario:this.ipUsuario
      };


      EventBus.$emit("consultapessoaOcorrencia", params); // emitir evento para o event-bus

    },
  },
};
</script>

<style scope>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}

.v-select:hover{
  width: auto !important;
}



</style>


