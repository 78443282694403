<template>
        <div class="card card-primary card-tabs"> 
            <div class="card-header background-principal p-0 pt-1">

                <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">

                     <li class="nav-item " @click="reRender()">
                        <a class="nav-link active show"
                        id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#consultaServidor" role="tab" 
                        aria-controls="custom-tabs-one-profile" aria-selected="false" @click="reRender()" ref="consultaServidor"
                        >Consulta Servidor </a>
                    </li>

                
                </ul>

            </div>
            <div class="card-body">
                <div class="tab-content" id="custom-tabs-one-tabContent" >
                    <div class="tab-pane fade active show" id="consultaServidor" role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab"  >
                        <ListaConsultaFuncionario />
                    </div>
                
               
                </div>
            </div>
        </div>
    
</template>

<script>

import CadastroAnotacao from "@/components/rh/anotacao/CadastroAnotacao.vue";
import ListaAnotacao from "@/components/rh/anotacao/ListaAnotacao.vue";
import ListaConsultaFuncionario from "@/components/administracao/funcionario/visualizacao/ListaConsultaFuncionario.vue";
import { EventBus } from "@/main";

export default {
  components: {
    CadastroAnotacao,
    ListaAnotacao,
    ListaConsultaFuncionario,
  },
  data() {
    return {
    
    };
  },

 

  methods:{
   
  }
}
</script>