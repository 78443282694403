<template>

    <div class = "container-fluid p-0">
    <div class="card card-outline">

        <div class="card-header p-0 pt-0 background-principal text-color-principal pl-4 mb-0" data-toggle="collapse"
            href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
            <h6 class="box-title pt-2 pb-2 mb-1">
            <i class="nav-icon fas fa-search text-color-principal"></i> Filtro Ata Registro
            </h6>
        </div>

        <div class="collapse" id="collapseExample">
            <!--div class="card-body" v-if="loading">
                <loader :active="loading" message="Consultando..." />
            </div-->
            <div class="card-body">
                <div class="submit-form">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="inputIdContracao" class="pesquisar-inputIdContracao">IdContracao</label>
                                <input type="text" class="form-control form-control-border border-width-2" id="inputIdContracao"
                                placeholder="IdContracao" v-model="idContracao" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                    <label for="inputNumero" class="pesquisar-numero">numero</label>
                                    <input type="text" class="form-control form-control-border border-width-2" id="inputNumero"
                                    placeholder="numero" v-model="numero" />
                            </div>
                        </div>
                        <div class="col-sm-4">   
                            <div class="form-group">
                                <label for="inputAno" class="pesquisar-ano">ano</label>
                                <input type="text" class="form-control form-control-border border-width-2" id="inputAno"
                                placeholder="ano" v-model="ano" />
                            </div>
                        </div>
                    </div>
                    <!--div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="inputValor" class="pesquisar-valor">valor</label>
                                <input type="text" class="form-control form-control-border border-width-2" id="inputValor"
                                placeholder="valor" v-model="valor" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="form-control-label" for="ata-dataInicio"
                                >Data Inicio</label
                                >
                                <div class="d-flex">
                                    <input
                                        type="date" class="form-control form-control-border border-width-2" id="datainicio"
                                        placeholder="dd/mm/aaaa" v-model="dataInicio"
                                        @change="updateZonedDateTimeField('dataInicio', $event)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="form-control-label" for="ata-dataInicio"
                                >Data Fim</label
                                >
                                <div class="d-flex">
                                    <input
                                        type="date" class="form-control form-control-border border-width-2" id="dataFim"
                                        placeholder="dd/mm/aaaa" v-model="dataFim" 
                                         @change="updateZonedDateTimeField('dataFim', $event)"
                                        />
                                </div>
                            </div>
                        </div>
                    </div-->
                    <div class="card-footer">
                        <div class="text-center">
                            <button @click.prevent="buscarAta"  class="btn text-color-principal background-principal ml-1"
                                type="submit">
                                <i class="fa fa-search"></i> Buscar
                            </button>
                             <button @click.prevent="LimparAta" type="reset" class="btn btn-default">
                                <i class="fas fa-times"></i> Limpar
                            </button>
                        </div>
                    </div>
                            
                        
                </div>
            </div>
        </div>

       
        
    </div>
    </div>


</template>

<script>

//import { EventBus } from "@/main.js";
export default({
    name: "FiltroAtaRegistro",

    data() {
        return{
            idContracao: "",
            numero: "",
            ano: "",
            valor:"",
            dataInicio: "",
            dataFim: "",
            

        }
        
    },


    methods:{

        MostrarAlerta() {
            Toast.fire('Preencha ao menos um campo!!', '', 'error')
        },
  
    }

})
</script>




