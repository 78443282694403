<template>
  <div class="card card-primary">
    <div class="background-principal card-header text-color-principal">
      <h3 class="card-title">Resultados da busca</h3>
      <span class="text-color-principal" v-if="total"
        >Total de resultados: {{ total }}</span
      >
      <div class="float-right" v-if="resultados.length > 0">
        <a href="#" v-on:click.prevent="page > 1 ? page-- : 0"
          ><i class="fas fa-angle-left mx-2 text-color-principal"></i
        ></a>

        Página {{ page }} / {{totalPaginas}}

        <a href="#" v-on:click.prevent="page++"
          ><i class="fas fa-angle-right mx-2 text-color-principal"></i
        ></a>
      </div>
      <div class="float-right" v-if="resultados.length == 0 && page !== 1">
        <a href="#" v-on:click.prevent="page > 1 ? page-- : 1"
          ><i class="fas fa-angle-left mx-2 text-color-principal"></i
        ></a>
      </div>
    </div>

    
    <loader :active="loaderActive" message="Consultando..." />
    <div class="row">
      <div
        v-if="resultados.length == 0 && semResultados"
        class="mx-auto my-auto sem-resultados py-2"
      >
        <div>Sem resultados!</div>
      </div>

      <div>
        <div class="table-responsive max-height-content">
          <div class="ml-5 row">
            <div
              :key="indice"
              v-for="(pessoa, indice) in resultados"
              v-bind:class="
                resultados.length > 1
                  ? 'card card-item bg-light col-sm-11 col-md-11 col-lg-5'
                  : 'card card-item bg-light col-sm-11 col-md-11 col-lg-6'
              "
              @click.prevent="showModalDetalhe"
              @click="detalharPessoa(pessoa.idPessoa)"
            >
              <a href="#">
                <p
                  class="
                    header
                    card-item-result
                    background-principal
                    text-color-principal
                  "
                >
                  {{ pessoa.nome }}
                </p>

                <div class="row">
                  <div class="col-3 img-div-card">
                    <img
                      v-if="pessoa.foto"
                      v-bind:src="'data:image/jpeg;base64,' + pessoa.foto"
                      alt="user-avatar"
                      class="img img-thumbnail mx-0 px-0 img-card-measure w-100 h-100"
                    />
                    <img
                      v-else
                      src="https://webc.ssp.ma.gov.br/imagens/elementos/icone-imagem-indisponivel2.png"
                      alt="user-avatar"
                      class="img img-thumbnail mx-0 px-0 img-card-measure"
                    />
                  </div>

                  <div class="col-9 py-0 ">
                    <p class="mx-0 my-0 py-0 mt-3">
                      <b>Nome da mãe: </b>
                      {{ pessoa.mae  }}
                    </p>
                    <p class="mx-0 my-0 py-0">
                      <b>Data de nascimento:</b>
                      {{ pessoa.nascimento}}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../main.js";
import ConsultaService from "../../../service/ConsultaService";
import Loader from "../../Loader.vue";

export default {
  name: "ResultadosConsulta",
  components: {
    Loader,
  },

  watch: {
    page(valorAtual, valorAnterior) {
      this.consultarSigma();
    },
  },
  data() {
    return {
      semResultados: false,
      tituloBusca: "Buscar pessoa",
      resultados: [],
      loaderActive: false,
      page: 1,
      total: "",
      params: {},
    };
  },
  filters: {
    // abreviar: function (nomeCompleto) {
    //   const qteNomesExibidos = 3;
    //   if (nomeCompleto == "" || nomeCompleto == null) return "";
    //   const arrayNomeSeparado = nomeCompleto.toString().split(" ");
    //   if (arrayNomeSeparado.length < 3) return nomeCompleto;
    //   let nomeAbreviado = "";
    //   for (let i = 0; i < arrayNomeSeparado.length; i++) {
    //     const parteNome = arrayNomeSeparado[i];
    //     const ultimoNome =
    //       parteNome === arrayNomeSeparado[arrayNomeSeparado.length - 1];
    //     if (i >= qteNomesExibidos && !ultimoNome) {
    //       nomeAbreviado += ` ${parteNome.charAt(0)}.`;
    //       continue;
    //     }
    //     nomeAbreviado += ` ${parteNome}`;
    //   }
    //   return nomeAbreviado;
    // },
    abreviar: function (nomeCompleto) {
      if (nomeCompleto !== null) {
        const nomeFormatado = nomeCompleto.split(" ");
        return (
          nomeFormatado[0] + " ... " + nomeFormatado[nomeFormatado.length - 1]
        );
      } else {
        return "Não Cadastrado";
      }
    },
    formatarCPFCincoCaracteres: function (cpf) {
      if (cpf !== null) {
        const cpfFormatado = cpf.substring(4, 10);
        return "***." + cpfFormatado + "*-**";
      } else {
        return "Não Cadastrado";
      }
    },
    formatarDataNascimento: function (dataNascimento) {
      if (dataNascimento !== null) {
        const dataFormatada = dataNascimento.substring(0, 3);
        return dataFormatada + "**/****";
      } else {
        return "Não Cadastrado";
      }
    },
  },
  created() {
    EventBus.$on("consultapessoa", this.listener);
  },
  beforeDestroy() {
    EventBus.$off("consultapessoa", this.listener);
  },

  computed:{
    totalPaginas(){

      const totalPagina = this.total/10;
      return (totalPagina !==Infinity) ? Math.ceil(totalPagina) : 0; 

      
    },
  },

  methods: {
    showModalDetalhe() {
      this.$emit("showModalPessoa");
    },
    detalharPessoa(id) {
      EventBus.$emit("consultaDetalhePessoa", id);
    },
    listener(event) {
      this.params = event;

      this.page = event.page;
      this.consultarSigma();
    },
    onChangePage(resultados) {
      this.resultados = resultados;
    },
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    consultarNacional() {
      this.params.page = this.page;
      this.showLoader();
      this.pesquisou = false;

      try {

        
          ConsultaService.buscarPessoasNacional(this.params)
          .then((response) => {
            this.resultados = [...response.data];
            this.total = 0;
            this.hideLoader();
          })
          .catch((e) => {
            this.hideLoader();
            this.semResultados = true;
          });

      } catch (e) {
        alert("ERRO: " + e.message);
        this.semResultados = true;
        this.hideLoader();
      } finally {
        this.mostrarLoader = false;
        this.semResultados = false;
        this.hideLoader();
      }
    },

    consultarSigma() {
      this.params.page = this.page;
      this.showLoader();
      this.pesquisou = false;

      try {
       
          ConsultaService.buscarPessoas(this.params)
          .then((response) => {
            if (response.data.conteudo.length == 0) {
              this.semResultados = true;
            }
            this.resultados = response.data.conteudo;
            this.total = response.data.total;
            this.hideLoader();
          })
          .catch((e) => {
            this.hideLoader();
            this.semResultados = true;
          });
        

      } catch (e) {
        alert("ERRO: " + e.message);
        this.semResultados = true;
      } finally {
        this.mostrarLoader = false;
        this.semResultados = false;
      }
    },
  },
};
</script>

<style scoped>
.img-div-card {
  margin: 0;
  max-height: 130px;
}

.img-card-measure {
  padding-left: 10px;
  height: 90%;
}
</style>
