<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card card-primary">
        <div class="card-header background-principal">
          <h3 class="card-title">Buscar Usuário</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="exampleSelectBorderWidth2">Tipo</label>
            <select
              v-model="tipoConsultaUsuario"
              class="custom-select form-control-border border-width-2"
              id="exampleSelectBorderWidth2"
              v-on:click="limpaInput"
            >
              <option disabled value="">Selecione</option>
              <option value="nome">Nome</option>
              <option value="matricula">Matrícula</option>
              <option value="novaMatricula">ID</option>
              <option value="cpf">CPF</option>
            </select>
          </div>
          <div v-show="tipoConsultaUsuario == 'nome'">
            <div class="form-group">
              <label for="inputNome" class="pesquisar-quantidade"
                >Digite o Nome</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputNome"
                placeholder="Digite o nome"
                v-model="nomeUsuario"
                required
                @keyup.enter="consultar"
              />
            </div>
          </div>
          <div v-show="tipoConsultaUsuario == 'matricula'">
            <div class="form-group">
              <label for="inputMatricula" class="pesquisar-quantidade"
                >Digite a Matrícula</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputMatricula"
                placeholder="Digite o matrícula"
                v-model="matriculaUsuario"
                @keyup.enter="consultar"
              />
            </div>
          </div>
          <div v-show="tipoConsultaUsuario == 'novaMatricula'">
            <div class="form-group">
              <label for="inputNovaMatricula" class="pesquisar-quantidade"
                >Digite o ID</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputNovaMatricula"
                placeholder="Digite o ID"
                v-model="novaMatriculaUsuario"
                @keyup.enter="consultar"
              />
            </div>
        </div>
          <div v-show="tipoConsultaUsuario == 'cpf'">
            <div class="form-group">
              <label for="inputCPF" class="pesquisar-quantidade"
                >Digite o CPF</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="inputCPF"
                placeholder="Digite o CPF"
                v-mask="'###.###.###-##'"
                v-model="cpfUsuario"
                @keyup.enter="consultar"
              />
            </div>
          </div>
        <ModalConsultaPessoa :fluxo="usuario"/>
        </div>
        <div class="card-footer">
          <div class="form-group">
            <button
              @click.prevent="consultar"
              @keyup.enter="consultar"
              class="btn col-12 background-principal text-color-principal"
            >
              <i class="fas fa-search"></i>
              Buscar Usuário
            </button>
          </div>

          

          <div class="form-group">
            <b-button v-b-modal.modal-1 class="btn btn-success col-12"
              ><i class="fa-solid fa-user-plus"></i> Cadastrar
              Usuário</b-button
            >
          </div>

        
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="card card-primary">
        <div class="background-principal card-header text-color-principal">
          <h3 class="card-title">Usuários</h3>
          <div v-if="showPaginacao">
            <div class="float-right" v-if="resultados.length > 0">
              <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"
                ><i class="fas fa-angle-left mx-2 text-color-principal"></i
              ></a>
              Página {{ page + 1 }}
              <a href="#" v-on:click.prevent="page++"
                ><i class="fas fa-angle-right mx-2 text-color-principal"></i
              ></a>
            </div>
            <div class="float-right" v-if="resultados.length == 0">
              <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"
                ><i class="fas fa-angle-left mx-2 text-color-principal"></i
              ></a>
            </div>
          </div>
        </div>
        <div
          v-if="resultados.length == 0 && semResultados"
          class="mx-auto my-auto sem-resultados py-2"
        >
          <div>Sem resultados!</div>
        </div>
        <div v-if="resultados.length > 0">
          
          <div class="card-body px-0 d-flex flex-row">
            <div class="card-body table-responsive p-0 max-height-content">
              <div class="row px-5 d-flex justify-content-around">
                <div
                  :key="id"
                  v-for="(usuario, id) in resultados"
                  class="card card-item bg-light col-sm-12 col-md-5"
                  @click="editarusuario(usuario)"
                >
                  <a class="cardUsuario">
                    <div>
                      <p
                        class="
                          header
                          card-item-result
                          background-principal
                          text-color-principal
                        "
                      >
                        {{
                          usuario
                            ? usuario.nome || "Não cadastrado"
                            : "Não Cadastrado"
                        }}
                      </p>
                    </div>
                    <div >
                      <div class="col-12 mx-0 row my-2 py-0" >
                        <div class="col-sm-12">
                          <ul class="ml-4 mb-0 fa-ul text-muted" >
                            <li class="medium">
                              <b>Matrícula: </b>
                              {{ usuario.matricula || "Não cadastrado" }}
                            </li>
                            <!-- <li class="medium">
                              <b>Nova Matrícula: </b>
                              {{ usuario.nova_matricula || "Não cadastrado" }}
                            </li> -->
                            <li class="medium">
                              <b> CPF: </b>
                              {{ usuario.cpf | formatarCPFCincoCaracteres }}
                            </li>
                            <li class="medium">
                              <b>Data de Admissão: </b>
                              {{ usuario.admissao | formatarData }}
                            </li>
                            <li class="medium">
                              <b>E-mail: </b>
                              {{ usuario.email || "Não cadastrado" }}
                            </li>
                            <li class="medium">
                              <b>Setor: </b>
                              {{ usuario.unidade || "Não cadastrado" }}
                            </li>
                            <li>
                              <b>Status: </b>
                              {{ usuario.ativo ? "Ativo" : "Inativo" }}
                              <i
                                :class="
                                  usuario.ativo
                                    ? 'fa-solid fa-circle-check green'
                                    : 'fa-solid fa-circle-xmark red'
                                "
                              ></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :active="loaderActive" :is-full-page="fullPage" />
  </div>
</template>
  <script>
import ModalConsultaPessoa from "@/views/cadastros/pessoa/ModalConsultaPessoa.vue";
import consultaUsuarioService from "@/service/ConsultaUsuarioService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CadastroPessoaService from "@/service/CadastroPessoaService";
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";

export default {
  name: "FormUsuarioConsulta",
  components: {
    ModalConsultaPessoa,
    Loading,
    
  },
  data() {
    return {
      tipoConsultaUsuario: "nome",
      matriculaUsuario: "",
      novaMatriculaUsuario:"",
      cpfUsuario: "",
      nomeUsuario: "",
      page: 0,
      resultados: [],
      loaderActive: false,
      fullPage: false,
      semResultados: false,
      usuario: 'Usuario',
    };
  },
  watch: {
    page(valorAtual, valorAnterior) {
      this.consultarTodosusuarios();
    },
  },
  created() {
    this.consultarTodosusuarios();
  },
  filters: {
    formatarData: function (data) {
      if (data == "" || data == null) {
        return "Não cadastrado";
      } else {
        const [ano, mes, dia] = data.split("-");
        const dataFormatada = `${dia}/${mes}/${ano}`;
        return dataFormatada;
      }
    },
    formatarCPFCincoCaracteres: function (cpf) {
      if (cpf !== null) {
        const cpfFormatado = cpf.substring(4, 10);
        return "***." + cpfFormatado + "*-**";
      } else {
        return "Não Cadastrado";
      }
    },
  },
  methods: {
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    
    alertaFalhaConsultarTodosusuarios() {
      Toast.fire("Falha ao carregar usuários", "", "error");
    },
    editarusuario(usuario) {
      this.showLoader();
      consultaUsuarioService.buscarUsuarioId(usuario.id).then(
        (response) => {
         
          this.$store.commit("usuarioCadastrado", response.data.id);
          this.$store.commit("usuarioEditar", response.data);
          this.$store.commit("pessoaUsuario", response.data.funcionario.pessoaResumo.id);
          // this.$store.commit("funcionarioUsuario", id);

          consultaFuncionarioService.buscarFuncionarioUsuario(response.data.funcionario.id)
          .then((response) => {

            this.$store.commit("funcionarioUsuario", response.data.id);
            this.$store.commit("setFuncionarioUsuario",response.data);
            this.$store.commit("visualizarListaFuncionariosExistentes", false);
            this.$store.commit("existeFuncionarioAtivo", true);

          });

          CadastroPessoaService.buscarPessoaCompletaPorId(response.data.funcionario.pessoaResumo.id).then(
          (response) => {
            this.$store.commit("setPessoaUsuario",  response.data);
            this.$router.push({ name: 'cadastrousuario'});
          });
          
          this.$store.commit("visualizarListaFuncionariosExistentes", false);
          //this.$store.commit("CadastrarAnotacao", response.data.id);
          
          
          
          // EventBus.$emit("editarFuncionario", response.data)
          // EventBus.$emit("fecharModalEPesquisar", funcionario.idpessoa);

   

        
        }
      );
    },
    consultar() {  
      this.showLoader();

      const params = {
        tipoConsultaUsuario: this.tipoConsultaUsuario,
        parametroPrincipalUsuario:
          this.nomeUsuario ||
          this.cpfUsuario ||
          this.matriculaUsuario ||
          this.novaMatriculaUsuario,
      }


      if (params.parametroPrincipalUsuario === "") {
        this.consultarTodosusuarios();
      } else {
        this.showPaginacao = false;
        let parametroPrincipal = params.parametroPrincipalUsuario.toUpperCase();
        if (params.tipoConsultaUsuario === 'cpf') {
          this.consultaUsuarioCPF(parametroPrincipal);
        } if (params.tipoConsultaUsuario === 'nome') {
          this.consultaUsuarioNome(parametroPrincipal)

        } if (params.tipoConsultaUsuario === 'novaMatricula') {
          this.consultaUsuarioNovaMatricula(parametroPrincipal)
        } else {
          this.consultaUsuarioMatricula(parametroPrincipal)
        }
      }
    },
    consultarTodosusuarios() {
      this.showPaginacao = true;
      this.showLoader();
      this.page;
      consultaUsuarioService
        .buscarUsuarios(this.page, "sigma")
        .then((response) => {
          this.resultados = response.data.content;
          this.hideLoader();
        })
        .catch((e) => {
          this.hideLoader();
          this.semResultados = true;
          this.alertaFalhaConsultarTodosusuarios();
        });
    },
    consultaUsuarioNome(parametro) {
      let pagina = 0;
      consultaUsuarioService.buscarUsuarioNome(pagina, parametro, "sigma").then((response) => {
        this.resultados = response.data;
        this.semResultados = false;
        this.hideLoade();
      }).catch((e) => {
        this.semResultados = true
        this.hideLoader();
      })
    },
    consultaUsuarioMatricula(parametro) {
      let pagina = 0;
      consultaUsuarioService.buscarUsuarioMatricula(pagina, parametro, "sigma").then((response) => {
        this.resultados = response.data;
        this.semResultados = false;
        this.hideLoader();
      }).catch((e) => {
        this.semResultados = true
        this.hideLoader();
      })

     
    },

    consultaUsuarioCPF(parametro) {
      let pagina = 0;
      consultaUsuarioService.buscarUsuarioCPF(pagina, parametro, "sigma").then((response) => {
        this.resultados = response.data;
        this.semResultados = false;
        this.hideLoader();
      }).catch((e) => {
        this.semResultados = true
        this.hideLoader();
      })
    },

    consultaUsuarioNovaMatricula(parametro) {
      let pagina = 0;
      consultaUsuarioService.buscarUsuarioNovaMatricula(pagina, parametro, "sigma").then((response) => {
        this.resultados = response.data;
        this.semResultados = false;
        this.hideLoader();
      }).catch((e) => {
        this.semResultados = true
        this.hideLoader();
      })
    },


    
    // emitirConsulta() {
    //   const params = {
    //     tipoConsultaUsuario: this.tipoConsultaUsuario,
    //     parametroPrincipalUsuario:
    //       this.nomeUsuario ||
    //       this.cpfUsuario ||
    //       this.matriculaUsuario,
    //   };
    //   EventBus.$emit("consultaUsuario", params);
    // },
    limpaInput() {
      this.matriculaUsuario = "";
      this.cpfUsuario = "";
      this.nomeUsuario = "";
      this.novaMatriculaUsuario ="";
    },

  
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.img-cut {
  max-width: 100px;
  max-height: 100px;

  height: 10vh;
  width: 10vh;
  object-fit: cover;
  object-position: center top;
}

.cardUsuario {
  cursor: pointer;
}
</style>
