<template>
  <div class="col-12">
    <h4 class="mb-3">Cadastrar/Editar um endereço</h4>
    <form class="row">
      <div class="form-group col-lg-3 col-sm-12">
        <label for="cep">CEP</label>
        <input type="text" class="form-control form-control-border border-width-2" id="cep" placeholder="xxxxx-xxx"
          v-model="endereco.cep" v-mask="'#####-###'" />
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="pais">País</label>
        <v-select class="border-v-select" id="pais" v-model="endereco.pais"
          :options="paises" label="descricao" :class="{ 'is-invalid': $v.endereco.pais.$error, }">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
        <div v-if="!$v.endereco.pais.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>

      <div class="form-group col-lg-3 col-sm-12" v-show="endereco.pais !== '' && endereco.pais !== null">
        <label for="estado">Estado</label>
        <v-select class="border-v-select" id="estado" v-model="endereco.estado"
          :options="estados" label="descricao" :class="{ 'is-invalid': $v.endereco.estado.$error, }">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
        <div v-if="!$v.endereco.estado.required" class="invalid-feedback">Este campo é obrigatório</div>
      </div>

      <div class="form-group col-lg-3 col-sm-12" v-show="endereco.estado !== '' && endereco.estado !== null">
        <label for="cidade">Cidade</label>
        <v-select class="border-v-select" id="cidade" v-model="endereco.cidade"
          :options="cidades" label="descricao" :class="{ 'is-invalid': $v.endereco.cidade.$error, }">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
        <div v-if="!$v.endereco.cidade.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>

      <div class="form-group col-lg-3 col-sm-12" v-show="endereco.cidade !== '' && endereco.cidade !== null">
        <label for="bairro">Bairro</label>
        <v-select class="border-v-select" id="bairro" v-model="endereco.bairro"
          :options="bairros" label="descricao" :class="{ 'is-invalid': $v.endereco.bairro.$error, }">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
        <div v-if="!$v.endereco.bairro.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="logradouro">Logradouro</label>
        <input type="text" class="form-control form-control-border border-width-2" id="logradouro" placeholder=""
          v-model="endereco.logradouro" :class="{ 'is-invalid': $v.endereco.logradouro.$error, }" />
        <div v-if="!$v.endereco.logradouro.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="numero">Número</label>
        <input type="text" class="form-control form-control-border border-width-2" id="numero" placeholder=""
          v-model="endereco.numero" v-mask="'##############'" />
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="tipoMoradia">Tipo moradia</label>
        <v-select class="border-v-select" id="tipoMoradia"
          v-model="endereco.tipoMoradia" :options="['ALUGUEL', 'PROPRIA', 'CEDIDA', 'SITUACAO_RUA']" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>
      <div class="form-group col-lg-3 col-sm-12">
        <label for="tipoArea">Tipo área</label>
        <v-select class="border-v-select" id="tipoArea" v-model="endereco.tipoArea"
          :options="['URBANA', 'RURAL', 'QUILOMBO', 'ALDEIA']" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>
      <div class="form-group col-lg-3 col-sm-12">
        <label for="tipoEndereco">Tipo endereço</label>
        <v-select class="border-v-select" id="tipoEndereco"
          v-model="endereco.tipoEndereco" :options="['RESIDENCIAL', 'COMERCIAL', 'RESIDENCIAL_COMERCIAL']"
          label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>
      <div class="form-group col-lg-3 col-sm-12">
        <label for="complemento">Complemento</label>
        <input type="text" class="form-control form-control-border border-width-2" id="complemento"
          v-model="endereco.complemento" placeholder="" />
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="referencia">Referência</label>
        <input type="text" class="form-control form-control-border border-width-2" id="referencia" placeholder=""
          v-model="endereco.referencia" required />
      </div>

      <div class="form-group col-lg-2 col-sm-12">
        <label for="endAtual">Atual</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="endAtual" v-model="endereco.atual" />
          <label class="form-check-label" for="endAtual"> Sim </label>
        </div>
      </div>

      <div class="form-group col-lg-1" v-if="this.endereco.id === ''">
        <button class="btn btn-primary float-right mt-4" @click.prevent="salvarEndereco">
          <i class="fa-solid fa-floppy-disk"></i>
          Salvar
        </button>
      </div>

      <div class="form-group col-lg-1" v-if="this.endereco.id != ''">
        <button class="btn btn-warning float-right mt-4" @click.prevent="atualizarEndereco">
          <i class="fas fa-check mr-1"></i> Atualizar
        </button>
      </div>
    </form>

    <hr />

    <h4 class="my-4">Endereços já cadastrados:</h4>
    <div class="card">
      <div class="card-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Logradouro</th>
              <th>Bairro</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>País</th>
              <th>Atual</th>
              <th>CEP</th>
              <th>Complemento</th>
              <th>Número</th>
              <th>Tipo de Moradia</th>
              <th>Área</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="end in pessoa.enderecos" :key="end.id">
              <td>{{ end.logradouro }}</td>
              <td>{{ end.bairro ? end.bairro.descricao : "" }}</td>
              <td>{{ end.cidade ? end.cidade.descricao : "" }}</td>
              <td>{{ end.estado ? end.estado.descricao : "" }}</td>
              <td>{{ end.pais ? end.pais.descricao : "" }}</td>
              <td>{{ end.atual ? "Sim" : "Não" }}</td>
              <td>{{ end.cep }}</td>
              <td>{{ end.complemento }}</td>
              <td>{{ end.numero }}</td>
              <td>{{ end.tipoMoradia }}</td>
              <td>{{ end.tipoArea }}</td>

              <td>
                <div @click.prevent="buscarEndereco(end.id)" class="btn btn-primary">
                  <i class="fa fa-edit" aria-hidden="true"></i>
                </div>

                <div @click.prevent="deleteEndereco(end.id)" class="btn btn-danger ml-1">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import CadastroPessoaService from "@/service/CadastroPessoaService";
import ConsultaListasService from "@/service/ConsultaListasService";
import { required } from "vuelidate/lib/validators";
export default {
  name: "EnderecoCadastroPessoa",
  props: ['pessoaEdicao'],
  data() {
    return {
      acao: "criacao",
      pessoa: {
        idPessoa: null,
        enderecos: [],
      },

      endereco: {
        id: "",
        atual: true,
        //estado: null,
        cidade: null,
        pais: { id: 1, descricao: "Brasil" },
        cep: "",
        bairro: null,
        logradouro: "",
        numero: "",
        tipoMoradia: null,
        tipoArea: null,
        tipoEndereco: null,
        complemento: "",
        referencia: "",
      },
      isEndSaving: false,
      paises: [],
      estados: [],
      estado: "",
      cidades: [],
      cidade: "",
      bairros: [],
      bairro: "",
    };
  },
  watch: {
    "endereco.estado"() {
      if (this.endereco.estado) {
        this.buscarCidadesDoEstado(this.endereco.estado.id);
        if (this.acao === "edicao") {
          return;
        }
        if (this.endereco.cidade) {
          this.endereco.cidade = "";
          this.endereco.bairro = "";
        }
      }
    },
    "endereco.cidade"() {
      if (this.endereco.cidade) {
        this.buscarBairrosDaCidade(this.endereco.cidade.id);
        if (this.acao === "edicao") {
          return;
        }
        if (this.endereco.bairro) {
          this.endereco.bairro = "";
        }
      }
    },
    pessoaEdicao:{
      immediate: true,
      handler(pessoa) {
        this.endereco.idPessoa = pessoa.id;
        this.pessoa.enderecos = pessoa.enderecos;
      }
    }
  },
  methods: {
    limparCampos() {
      (this.enderecos = []),
        (this.endereco = {
          id: "",
          atual: "",
          estado: null,
          cidade: null,
          pais: null,
          cep: "",
          bairro: null,
          logradouro: "",
          numero: "",
          tipoMoradia: "",
          tipoArea: "",
          tipoEndereco: "",
          complemento: "",
          referencia: "",
        });
    },
    // formValido() {
    //   if (this.endereco.idPessoa &&(this.endereco.estado ||this.endereco.pais ||this.endereco.bairro ||this.endereco.logradouro))
    //   {
    //     return true;
    //   }
    // },

    buscarEndereco(idEndereco) {
      this.$nextTick(() => { this.$v.$reset() })
      if (idEndereco) {
        CadastroPessoaService.buscarEnderecoEdicao(idEndereco)
          .then((response) => {
            this.acao = "edicao";
            console.info("Endereço buscado: ", response.data);
            this.endereco.cep = response.data.cep;
            this.endereco.atual = response.data.atual;
            this.endereco.estado = response.data.estado;
            this.endereco.bairro = response.data.bairro;
            this.endereco.logradouro = response.data.logradouro;
            this.endereco.numero = response.data.numero;
            this.endereco.complemento = response.data.complemento;
            this.endereco.cidade = response.data.cidade;
            this.endereco.referencia = response.data.referencia;
            this.endereco.id = idEndereco;
            this.endereco.tipoMoradia = response.data.tipoMoradia;
            this.endereco.tipoArea = response.data.tipoArea;
            this.endereco.tipoEndereco = response.data.tipoEndereco;
          })
          .catch((error) => {
            Toast.fire(error.message, "", "error");
          });
      }
    },
    atualizarEndereco() {

      //this.$v.endereco.$touch();
      if (
        !!this.endereco.estado &&
        !!this.endereco.pais &&
        !!this.endereco.cidade &&
        !!this.endereco.bairro &&
        !!this.endereco.logradouro
      ) {
        console.info("Endereço a ser atualizado, ", this.endereco);
        CadastroPessoaService.editarEndereco(this.endereco)
          .then((response) => {
            this.buscarEnredecosPessoa(this.endereco.idPessoa);
            this.endereco = {
              idPessoa: this.endereco.idPessoa,
              id: "",
              atual: "",
              estado: null,
              cidade: null,
              pais: { id: 1, descricao: "Brasil" },
              cep: "",
              bairro: null,
              logradouro: "",
              numero: "",
              tipoMoradia: null,
              tipoArea: null,
              tipoEndereco: null,
              complemento: "",
              referencia: "",
            };
            this.$nextTick(() => { this.$v.$reset() })
            Toast.fire("Endereço atualizado com sucesso!", "", "success");
          })
          .catch((error) => {
            Toast.fire(error.message, "", "error");
          });
      } else {
        Toast.fire(
          "Campos necessários: País; Estado; Cidade; Bairro; Logradouro!",
          "",
          "error"
        );
      }
    },
    deleteEndereco(idEndereco) {
      if (idEndereco && this.endereco.idPessoa) {
        CadastroPessoaService.removerEndereco(idEndereco)
          .then((param) => {
            const message = "Endereco Removido com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
            this.buscarEnredecosPessoa(this.endereco.idPessoa);
            this.$forceUpdate();
          })
          .catch((error) => { });
      } else {
        Toast.fire(
          "Algo deu errado e o endereço não foi deletado, tente novamente mais tarde!",
          "",
          "error"
        );
      }
    },

    buscarCidadesDoEstado(id) {
      if (id) {
        ConsultaListasService.buscarCidadesPorIdEstado(id)
          .then((response) => {
            this.cidades = response.data;
          })
          .catch((e) => alert("ERRO: " + e.message));
      }
    },
    buscarBairrosDaCidade(id) {
      if (id) {
        ConsultaListasService.buscarBairrosPorIdCidade(id)
          .then((response) => {
            this.bairros = response.data;
          })
          .catch((e) => alert("ERRO: " + e.message));
      }
    },
    buscarEnredecosPessoa(id) {
      if (id) {
        CadastroPessoaService.buscarPessoaPorId(id).then((r) => {
          this.pessoa.enderecos = r.data.enderecos;

          console.log(this.pessoa.enderecos)
        });
      }
    },
    salvarEndereco() {
      this.isEndSaving = true;

      this.$v.endereco.$touch();


      if (!this.$v.endereco.$invalid) {
        CadastroPessoaService.cadastrarEndereco(this.endereco)
          .then((param) => {
            this.isEndSaving = false;
            const message = "Endereco Cadastrado com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
            this.buscarEnredecosPessoa(this.endereco.idPessoa);
            this.endereco = {
              idPessoa: this.endereco.idPessoa,
              id: "",
              atual: "",
              estado: null,
              cidade: null,
              pais: { id: 1, descricao: "Brasil" },
              cep: "",
              bairro: null,
              logradouro: "",
              numero: "",
              tipoMoradia: null,
              tipoArea: null,
              tipoEndereco: null,
              complemento: "",
              referencia: "",
            };
          

            this.$nextTick(() => { this.$v.$reset() })
            this.$forceUpdate();
          })
          .catch((error) => {
            Toast.fire(error.message, "", "error");
          });
      } else {
        const msg = "Preencha corretamente os campo ";
        Toast.fire(msg.toString(), "", "error");
      }
    },
    preencherListasObj(obj) {
      // {atributo : nome_tabela}

      Object.keys(obj).forEach((lista) => {
        ConsultaListasService.buscarListaPorNomeTabela(obj[lista])
          .then((response) => {
            this[lista] = response.data;
          })
          .catch((e) => alert("ERRO: " + e.message));
      });
    },
  },
  created() {
    EventBus.$on("cadastrarPessoaDadosBasicos", (listener) => {
      this.pessoa.idPessoa = listener.id
      this.endereco.idPessoa = listener.id
    });
    EventBus.$on("dadosBasicosBuscouPessoa", (pessoa) => {
      console.info('cadastroRealizadoComSucesso ', pessoa)
      this.endereco.idPessoa = pessoa.id;
      this.pessoa.enderecos = pessoa.enderecos;
    });
    EventBus.$on("pessoaParaEditar", (pessoa) => {
      this.endereco.idPessoa = pessoa.id;
      this.pessoa.enderecos = pessoa.enderecos;
    });
    this.preencherListasObj({
      paises: "pais",
      estados: "estado",
    });
  },
  validations: {
    endereco: {
      estado: {
        required,
      },
      pais: {
        required,
      },
      cidade: {
        required,
      },
      bairro: {
        required,
      },
      logradouro: {
        required,
      }

    },
  }
};
</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}

#preview>div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#preview img {
  width: 100%;
  max-width: 150px;
  max-height: 250px;
  margin-bottom: 15px;
}

.card-header {
  background-color: #0f192c !important;
}
</style>
