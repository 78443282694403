<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card card-primary">
        <div class="card-header background-principal">
          <h3 class="card-title">Buscar Anotação</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="exampleSelectBorderWidth2">Matrícula/ID</label>
            <select
              v-model="tipoConsultaAnotacao"
              class="custom-select form-control-border border-width-2"
              id="exampleSelectBorderWidth2"
            >
              <option disabled value="">Selecione</option>
              <option value="antigaMatricula">Antiga Matrícula</option>
              <option value="novaMatricula">ID</option>
            </select>
          </div>

          <div v-show="tipoConsultaAnotacao == 'antigaMatricula'">
            <div class="form-group">
              <label for="antigaMatricula" class="pesquisar-quantidade"
                >Matrícula Antiga</label
              >
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="antigaMatricula"
                v-model="consulta.matricula"
                placeholder="Digite a matrícula"
              />
            </div>
          </div>

          <div v-show="tipoConsultaAnotacao == 'novaMatricula'">
            <div class="form-group">
              <label for="novaMatricula" class="pesquisar-quantidade">ID</label>
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="novaMatricula"
                placeholder="Digite o ID"
                v-model="consulta.novaMatricula"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="nome-consulta">Nome</label>
            <input
              type="text"
              class="form-control form-control-border border-width-2"
              id="nome-consulta"
              v-model="consulta.nome"
            />
          </div>

          <div class="form-group">
            <label for="cpf-consulta">CPF</label>
            <input
              type="text"
              class="form-control form-control-border border-width-2"
              id="cpf-consulta"
              placeholder="XXX.XXX.XXX-XX"
              v-model="consulta.cpf"
            />
          </div>

          <div class="form-group">
            <label for="consulta-tipoAnotacao"
              >Tipo <span class="text-danger">*</span></label
            >
            <v-select
              class="border-v-select mt-1"
              id="consulta-tipoAnotacao"
              v-model="consulta.tipoAnotacaoList"
              :reduce="(tipoAnotacaoList) => tipoAnotacaoList.id"
              :options="tipos"
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
            <div
              v-if="!$v.consulta.tipoAnotacaoList.required"
              class="invalid-feedback"
            >
              Este campo é obrigatório
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="form-group">
            <button
              @click.prevent="consultaAnotacao"
              @keyup.enter="consultaAnotacao"
              class="btn col-12 background-principal text-color-principal"
            >
              <i class="fas fa-search"></i>
              Buscar Anotações
            </button>

          </div>
        </div>
      </div>
    </div>

    <div class="col-md-9">
      <div class="card card-primary">
        <div class="background-principal card-header text-color-principal">
          <h3 class="card-title">Anotações</h3>
          <div v-if="showPaginacao">
            <div class="float-right" v-if="resultados.length > 0">
              <a
                v-if="first != true"
                href="#"
                v-on:click.prevent="consultaAnotacao(page - 1)"
                ><i class="fas fa-angle-left mx-2 text-color-principal"></i
              ></a>
              Página {{ page + 1 }}
              <a
                v-if="last != true"
                href="#"
                v-on:click.prevent="consultaAnotacao(page + 1)"
                ><i class="fas fa-angle-right mx-2 text-color-principal"></i
              ></a>
            </div>
            <div class="float-right" v-if="resultados.length == 0">
              <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"
                ><i class="fas fa-angle-left mx-2 text-color-principal"></i
              ></a>
            </div>
          </div>
        </div>

        <div
          v-if="resultados.length == 0 && semResultados"
          class="mx-auto my-auto sem-resultados py-2"
        >
          <div>Sem resultados!</div>
        </div>
        <!-- <div v-if="resultados.length == 10"> -->
        <div v-else>
          <loading :active="loaderActive" :is-full-page="fullPage" />
          <div class="card-body px-0 d-flex flex-row" v-show="showModal">
            <div class="card-body table-responsive p-0 max-height-content">
              <!-- //overflow-hidden -->
              <div class="row px-5 d-flex justify-content-around">
                <div
                  v-for="(anotacoes, id) in resultados"
                  :key="id"
                  class="card card-item bg-light col-sm-12 col-md-5"
                  v-b-modal.ModalDetalhesConsultaAnotacao
                  @click="infoAnotacao(anotacoes.id)"
                >
                  <a class="cardAnotacao">
                    <div>
                      <p
                        class="header card-item-result background-principal text-color-principal"
                      >
                        {{ anotacoes.tipo_anotacao || "Sem Registro" }}
                      </p>
                    </div>

                    <div>
                      <div class="col-12 mx-0 row my-2 py-0">
                        <div class="col-sm-12">
                          <ul class="ml-4 mb-0 fa-ul text-muted">
                            <li class="medium">
                              <b>Matrícula: </b
                              >{{ anotacoes.matricula || "Sem Registro" }}
                            </li>
                            <li class="medium">
                              <b>Nome: </b
                              >{{ anotacoes.nome || "Sem Registro" }}
                            </li>

                            <li class="medium">
                              <b>Documento </b>
                              {{ anotacoes.tipo_documento || "Sem Registro" }}
                            </li>

                            <li class="medium">
                              <b>Nº do Documento: </b
                              >{{
                                anotacoes.numero_documento || "Sem Registro"
                              }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <PdfFichaFuncional /> -->
  </div>
</template>

<script>
import ConsultaAnotacao from "@/components/rh/anotacao/ConsultaAnotacao.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import rhAnotacao from "@/service/RHAnotacaoService";
import { EventBus } from "../../../main.js";
import RHDiarias from "@/service/RhDiariasService";
import RHAnotacaoService from "@/service/RHAnotacaoService";
//import PdfFichaFuncional from "@/components/rh/ferias/PdfFichaAnotacoes.vue"

export default {
  name: "FormFuncionarioConsulta",
  components: {
    ConsultaAnotacao,
    Loading,
    
  },
  data() {
    return {
      consulta: {
        novaMatricula: "",
        matricula: "",
      },
      tipoConsultaAnotacao: "antigaMatricula",

      tipos: [],
      page: 0,
      first: true,
      last: false,
      resultados: [],
      loaderActive: false,
      fullPage: false,
      semResultados: false,
      showModal: false,
    };
  },

  validations: {
    consulta: {
      tipoAnotacaoList: {},
      nome: {},
      cpf: {},
      matricula: {},
      novaMatricula: {},
    },
  },

  // watch: {
  //   page(valorAtual, valorAnterior) {
  //     this.consultaAnotacao();
  //   },
  // },

  created() {
    this.carregarTipoAnotacoes();
    this.consultaAnotacao(this.page);

    EventBus.$on("AtualizarAnotacao", () => {
      this.consultaAnotacao(this.page);
    });
  },

  methods: {
    // enviarDadosAnotacao(anotacoes){

    //    EventBus.$emit("EnvioDadosAnotacao",anotacoes)
    //  },

    imprimir(){
      this.$root.$emit("EmitirPdfFichaAnotacoes")
    },

    showModalDetalhe() {
      //this.$emit("showModalAnotacao");
      this.showModal = true;
    },

    infoAnotacao(id) {
      //console.log("gdsfgdsf");
       RHAnotacaoService.getAnotacao(id).then(resp=>{
            this.anotacoes = resp.data;
            EventBus.$emit("consultaDetalhadaAnotacao", this.anotacoes);

      })
    },
    showLoader() {
      // this.resultados = [];
      this.loaderActive = true;
    },
    hideLoader() {
      //  this.resultados = [];
      this.loaderActive = false;
    },
    //  hideLoader() {
    //    this.loaderActive = false;

    //  },

    carregarTipoAnotacoes() {
      RHDiarias.getAllTipoAnotacao().then((response) => {
        this.tipos = response.data;
      });
    },

    consultaAnotacao(pagina) {
      this.showLoader();

      this.showPaginacao = true;
      const tipoAnotacaoList = this.consulta.tipoAnotacaoList || [];
      const nome = this.consulta.nome || [];
      const cpf = this.consulta.cpf || [];
      const identidade = this.consulta.identidade || [];
      const matricula = this.consulta.matricula || [];
      const novaMatricula = this.consulta.novaMatricula || [];

      const params = new URLSearchParams();

      params.append("matricula", matricula);
      params.append("novaMatricula", novaMatricula);
      params.append("nome", nome);
      params.append("cpf", cpf);
      params.append("identidade", identidade);
      params.append("estadoCivil", "");
      params.append("sexo", "");
      params.append("formacao", "");
      params.append("cargo", "");
      params.append("lotacao", "");
      params.append("tipoAnotacaoList", tipoAnotacaoList);
      params.append("dataAnotacao", "");
      params.append("tipoDocumento", "");
      params.append("numeroDocumento", "");
      params.append("dataDocumento", "");
      params.append("obs1", "");
      params.append("obs2", "");
      params.append("page", pagina);

      rhAnotacao.consultaAnotacao(params).then((resp) => {
        this.resultados = resp.data.content;

        this.page = resp.data.number;
        this.first = resp.data.first;
        this.last = resp.data.last;

        this.hideLoader();
      });
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.img-cut {
  max-width: 100px;
  max-height: 100px;

  height: 10vh;
  width: 10vh;
  object-fit: cover;
  object-position: center top;
}

.cardAnotacao {
  cursor: pointer;
}
</style>
