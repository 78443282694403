<template>
  <div class="container-fluid">
    <div class="card card-primary">
      <div class="card-header background-principal">
        <h3 class="card-title">Minhas notificações cadastradas</h3>
        <div class="card-tools px-1">
          <button type="button" @click.prevent="cadastrarNotificacoesButton" class="btn btn-success">
            <i class="fa-solid fa-pen-to-square"></i>
            Cadastrar Notificação
          </button>
        </div>
      </div>
      <Loader :active="loaderActive" message="Consultando..." />

      <div class="card-body max-height-content" v-show="!loaderActive">
        <div class="row m-2 justify-content-around" v-show="!loaderActive && notificacoes.length == 0">
          Você não possui notificações criadas!
        </div>
        <div class="row m-2">
          <div :key="id" v-for="(notificacao, id) in notificacoes" class="col-sm-12 col-md-6">
            <div v-if="notificacao.lido !== true">
              <div class="small-box"
                :class="{ 'bg-warning': notificacao.tipoNotificacao === 'AVISO', 'bg-danger': notificacao.tipoNotificacao === 'CRITICO' }">
                <div class="inner">
                  <p>{{ notificacao.tipoServico + ': ' + notificacao.mensagem }}</p>
                  <p class="text-muted text-sm">{{ notificacao.dataCriacao | formatarDataHora }}</p>
                  <button type="button" @click.prevent="delNotificacao(notificacao.id)"
                    class="btn background-principal text-color-principal">
                    <i class="fa-solid fa-trash"></i>
                    Excluir
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/Loader.vue';
import NotificacoesService from '@/service/NotificacoesService';
import notificacoesToggle from '@/util/notificacoesToggle';
export default {
  name: "VisualizacaoNotificacao",
  data() {
    return {
      loaderActive: false,
      notificacoes: [],
    };
  },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    }
  },
  created() {
    this.buscarNotificacoesCriador();
  },
  filters: {
    formatarDataHora: function (dataHora) {
      if (dataHora == "" || dataHora == null) {
        return "Data não preenchida";
      }
      else {
        const [date, hour] = dataHora.split("T");
        const [ano, mes, dia] = date.split("-");
        const dataFormatada = `${dia}/${mes}/${ano}`;
        const [hora, minuto, segundo] = hour.split(":");
        const horaFormatada = `${hora}:${minuto}`;
        return "Enviado em: " + dataFormatada + " às " + horaFormatada;
      }
    },
  },
  methods: {
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    alertaFalhaAoBuscarNotificacoesCriador() {
      Toast.fire("Falha ao Buscar Notificações Criadas pelo Usuário", "", "error");
    },
    alertaSucessoApagarNotificacao() {
      Toast.fire("Sucesso ao Apagar Notificação", "", "success");
    },
    alertaFalhaApagarNotificacao() {
      Toast.fire("Falha ao Apagar Notificação", "", "error");
    },
    buscarNotificacoesCriador() {
      this.showLoader();
      NotificacoesService.notificacoesCriadasPeloUsuario(this.usuario.idFuncionario)
        .then((response) => {
          this.hideLoader();
          this.notificacoes = response.data;
        }).catch((e) => {
          this.hideLoader();
          this.alertaFalhaAoBuscarNotificacoesCriador();
        });
    },
    cadastrarNotificacoesButton() {
      this.$emit("CadastrarNotificacoes");
    },
    delNotificacao(idNotificacao) {
      this.$swal.fire({
        title: "Você realmente deseja excluir esta notificação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c92f3e",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.showLoader();
          NotificacoesService.delNotificacaoForAll(idNotificacao).then((response) => {
            this.hideLoader();
            this.alertaSucessoApagarNotificacao();
            this.buscarNotificacoesCriador();
            notificacoesToggle.$emit("buscarNovasNotificacoes", null);
            notificacoesToggle.$emit("buscarNovasNotificacoesSistema", null);
          }).catch((e) => {
            this.alertaFalhaApagarNotificacao();
            this.hideLoader();
          })
        } else {
          this.hideLoader();
        }
      })

    }

  },
  components: { Loader }
}
</script>
