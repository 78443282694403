<template>
  <div>
    <b-modal
      id="modal-6"
      ref="modal-6"
      title="Cadastro Férias"
      data-backdrop="static"
      size="xl"
      cancel-disabled
      no-close-on-backdrop
      hide-header-close
      header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            "
      content-class="modal-content border-rounded-detail"
      dialog-class="p-3"
      title-class="modal-title mx-auto xl"
    >
      <!-- 
        <h6 class="mt-4 mb-3"><b>Funcionário: </b> {{this.$store.state.funcionario.nomePessoaFuncionario}}</h6> -->

      <DadosPessoaFuncionario />
      <hr />

      <h6><b>PREENCHA OS CAMPOS ABAIXO: </b></h6>
      <div class="card-body">
        <div class="row">
          <!-- <div class="form-group col-lg-3">
                    <label for="cadastro-funcionarioCad">Funcionario Cad</label>
                    <input
                        type="text"
                        class="form-control form-control-border border-width-2"
                        id="cadastro-funcionarioCad"
                        v-model="ferias.funcionarioCadastrante"
                        :class="{
                        'is-invalid': isSaving && $v.ferias.funcionarioCadastrante.$error}"
                    />
                </div> -->

          <!-- <div class="form-group col-lg-3">
                    <label for="cadastro-funcionario">Funcionario</label>
                    <input
                        type="text"
                        class="form-control form-control-border border-width-2"
                        id="cadastro-funcionario"
                        v-model="ferias.funcionario"
                        :class="{
                        'is-invalid': isSaving && $v.ferias.funcionario.$error}"
                    />
                </div>
             -->
          <!-- <div class="form-group col-lg-3">
                    <label for="cadastro-matricula">Matricula</label>
                    <input
                        type="text"
                        class="form-control form-control-border border-width-2"
                        id="cadastro-matricula"
                        v-model="funcionarioInfo.matricula"
                        :class="{
                        'is-invalid': isSaving && $v.ferias.matricula.$error}"
                    />
                </div>  -->

          <div class="form-group col-lg-4">
            <label class="form-control-label" for="cadastro-anoExercicio"
              >Ano Exercício
            </label>
            <input
              type="text"
              class="form-control form-control-border border-width-2"
              name="anoExercicio"
              id="cadastro-anoExercicio"
              :class="{
                'is-invalid': isSaving && $v.ferias.anoExercicio.$error,
              }"
              v-model="ferias.anoExercicio"
            />
          </div>

          <div class="form-group col-lg-4">
            <label class="form-control-label" for="cadastro-anoFerias"
              >Ano
            </label>
            <input
              type="text"
              class="form-control form-control-border border-width-2"
              name="anoFerias"
              id="cadastro-anoFerias"
              :class="{
                'is-invalid': isSaving && $v.ferias.ano.$error,
              }"
              v-model="ferias.ano"
            />
          </div>

          <div class="form-group col-lg-4">
            <label for="cadastro-mesFerias">Mês Férias</label>
            <v-select
              class="border-v-select mt-1"
              id="cadastro-mesFerias"
              v-model="ferias.mes"
              :options="SelectMes"
              :reduce="(SelectMes) => SelectMes.id"
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
        </div>

        <div class="row">
          <!-- <div class="form-group col-lg-3">
                    <label class="form-control-label" for="cadastro-dataCadastro"
                    >Data de Cadastro</label>
                        <div class="d-flex">
                            <input
                                type="date" data-cy="dataCadastro" class="form-control form-control-border border-width-2" id="cadastro-dataCadastro"
                                placeholder="dd/mm/aaaa"  :model="convertDateTimeFromServer(ferias.dataCadastro)" 
                               
                                :class="{
                                'is-invalid': isSaving && $v.ferias.dataCadastro.$error}"
                                @change="updateZonedDateTimeField('dataCadastro', $event)"

                            />
                        </div>
                </div> -->

          <div class="form-group col-lg-6">
            <label class="form-control-label" for="cadastro-dataInicio"
              >Data Início</label
            >
            <div class="d-flex">
              <input
                type="date"
                data-cy="dataInicio"
                class="form-control form-control-border border-width-2"
                id="cadastro-dataInicio"
                placeholder="dd/mm/aaaa"
                v-model="ferias.dataInicio"
                :class="{
                  'is-invalid': isSaving && $v.ferias.dataInicio.$error,
                }"
              />
            </div>
          </div>

          <div class="form-group col-lg-6">
            <label class="form-control-label" for="cadastro-dataFim"
              >Data Fim
            </label>
            <div class="d-flex">
              <input
                type="date"
                data-cy="dataFim"
                class="form-control form-control-border border-width-2"
                id="cadastro-dataFim"
                placeholder="dd/mm/aaaa"
                v-model="ferias.dataFim"
                :class="{
                  'is-invalid': isSaving && $v.ferias.dataFim.$error,
                }"
              />
            </div>
          </div>

          <!-- <div class="form-group col-lg-3">
                    <label for="cadastro-mesFerias">Férias Anotada</label>
                    <v-select
                        class="border-v-select mt-1"
                        id="cadastro-mesFerias"
                        v-model="ferias.anotado"
                        :options="anotado"
                        :reduce="anotado => anotado.id"
                        label="descricao"
                        >
                        <span slot="no-options">Desculpe, sem opções no momento.</span>
                    </v-select>
                </div> -->
        </div>

        <div class="form-group col-lg">
          <label for="cadastro-observacao">Observação</label>
          <textarea
            style="resize: none"
            type="text"
            rows="3"
            class="form-control"
            name="observacao"
            id="cadastro-observacao"
            v-model="ferias.observacao"
          ></textarea>
        </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="ferias-supervidor">Supervisor(a)</label>
              <v-select class="border-v-select mt-1" id="ferias-supervidor" v-model="ferias.supervisorAssinatura"
                    :options="listaSupervisor" :reduce="(listaSupervisor) => listaSupervisor.id" label="nome">
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </div>
          </div>
        

        <!-- <div class="card-footer">
                <div class="float-right">
                    <button @click.prevent="salvarFerias" class="btn text-color-principal background-principal ml-1">
                    <i class="far fa-envelope"></i> Enviar
                    </button>
                    <button @click.prevent="LimparFerias" type="reset" class="btn btn-default">
                    <i class="fas fa-times"></i> Limpar
                    </button>
                    
                </div>
            </div> -->
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button
          @click.prevent="[salvarFerias(), ok()]"
          class="btn text-color-principal background-principal ml-1"
        >
          <i class="far fa-envelope"></i> Salvar
        </button>
        <button
          @click.prevent="LimparFerias"
          type="reset"
          class="btn btn-default"
        >
          <i class="fas fa-ban"></i> Limpar
        </button>
        <b-button
          class="btn btn-default float-right"
          @click="[cancel(), apagarCamposFerias()]"
        >
          <i class="fa-solid fa-x"></i>Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { DATE_FORMAT_LOCAL_DATA, DATE_TIME_LONG_FORMAT } from "@/util/filter";
import dayjs from "dayjs";
import { EventBus } from "@/main";
import rhService from "@/service/RHService";
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import DadosPessoaFuncionario from "@/components/administracao/funcionario/cadastro/DadosPessoaFuncionario.vue";
import rhAnotacao from "@/service/RHAnotacaoService";

export default {
  name: "CadastroFerias",
  computed: {
    usuario() {
      
      return this.$store.state.auth.user.usuario;
    },
  },
  components: {
    consultaFuncionarioService,
    DadosPessoaFuncionario,
  },
  data() {
    return {
      data: {},
      ferias: {
        funcionarioCadastrante: null,
      },
      listaSupervisor: {},
      SelectMes: [
        { id: 1, descricao: "Janeiro" },
        { id: 2, descricao: "Fevereiro" },
        { id: 3, descricao: "Março" },
        { id: 4, descricao: "Abril" },
        { id: 5, descricao: "Maio" },
        { id: 6, descricao: "Junho" },
        { id: 7, descricao: "Julho" },
        { id: 8, descricao: "Agosto" },
        { id: 9, descricao: "Setembro" },
        { id: 10, descricao: "Outubro" },
        { id: 11, descricao: "Novembro" },
        { id: 12, descricao: "Dezembro" },
      ],
      // anotado:[
      //     { id: "true", descricao: "Anotado" },
      //     { id: "false", descricao: "Não Anotado" },
      // ],
      submitted: false,
      isSaving: false,
      idFuncionario: this.$store.state.funcionario.idFuncionarioCadastrado,
      funcionarioInfo: {},
      anotacoes: {
        funcionario: { id: null }, // ou outra estrutura padrão esperada
      },
      
      //funcionarioId:{}
    };
  },

  validations: {
    ferias: {
      anoExercicio: {},
      ano: {},
      mes: {},
      dataInicio: {},
      dataFim: {},
      observacao: {},
      //anotado:{},
      //funcionarioCadastrante:{},
      //dataCadastro:{},
      //funcionario:{},
    },
  },

  created() {
    this.$store.state.funcionario;

    this.consultarInfoFuncionario();

    //this.consultarInfoFuncionario();

    this.buscarAnotacao();
    this.buscaSupervisor();

  },

  methods: {

    buscaSupervisor() {
        rhService
        .buscaSupervisors()
        .then((response) => {
            this.listaSupervisor = response.data;
        });
    },
    buscarAnotacao() {
      this.anotacoes.funcionario = {
        id: this.idFuncionario,
      };

      rhAnotacao.anotacaoFuncionarioId(this.idFuncionario).then((resp) => {
        this.anotacoes = resp.data;
        console.log(resp.data);
      });
    },

    consultarInfoFuncionario() {
      consultaFuncionarioService.buscarFuncionarioId().then((response) => {
        this.funcionarioInfo = response.data;
        //console.log(response.data)
      });
    },
    //pdf

    LimparFerias() {
      this.ferias = {};
    },

    apagarCamposFerias() {
      this.ferias = {};
    },

    salvarFerias() {
      this.isSaving = true;
      this.$v.$touch();

      if (this.$refs["cadastro-ferias"] != undefined) {
        this.ferias = Object.assign(
          {},
          this.ferias,
          this.$refs["cadastro-ferias"].ferias
        );

        this.$refs["cadastro-ferias"].isSaving = this.isSaving;
        if (this.$v.$ivalid || this.$refs["cadastro-ferias"].$v.$ivalid) {
          return;
        } else {
          if (this.$v.$ivalid) {
            return;
          }
        }
      }

      this.ferias.funcionarioCadastrante = this.usuario.idFuncionario;
      this.ferias.funcionario = {
        id: this.idFuncionario,
      };

      rhService
        .criarFerias(this.ferias)
        .then((response) => {
          this.ferias = response.data;
          Toast.fire("Cadastrado realizado", "", "success");
          this.ferias = {};
          console.log(this.ferias)
          EventBus.$emit("AtualizaCadastroFerias");
        })
        .catch(() => {
          Toast.fire("Falha ao cadastrar férias", "", "error");
        });
    },

    // validarCadastro(){
    //     this.$store.commit("funcionarioEditar", {});
    // },
  },
};
</script>


 