<template>
    <div>

        
        <div class="row">
            <div class="form-group col-lg-3">
                <label for="matricula">RD</label>
                <input
                type="text"
                class="form-control form-control-border border-width-2"
                id="matricula"
                placeholder="xxxxx"
                @change="VerDiaria"
                v-model="diaria.id"
                :class="{
            	'is-invalid': isSaving && $v.diaria.id.$error}"
                />
            </div>
			<div class="form-group col-lg-3">
                <label for="nome">Nome</label>
                <input
                disabled
                type="text"
                class="form-control form-control-border border-width-2"
                id="nome"
                v-model="diaria.funcionario.pessoaCorrespondente.nome"
                
                />
            </div>
            <div class="form-group col-sm-2">
                <label for="status">Status</label>
                <v-select
                class="border-v-select mt-1"
                id="status"
                v-model="diaria.status"
                :options="['ABERTO', 'CANCELADO', 'PAGO']"
                label="descricao"
                >
                <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
            </div>
        </div>

        <div>

        </div>

        <div >
        	<div class="row float-right">
          		
          		<button @click="SalvarEncerramento" class="btn btn-success">
            		<i class="fa fa-save"></i> Salvar
          		</button>
				<button @click.prevent="limparEncerramento" type="reset" class="ml-2 btn btn-default">
					<i class="fas fa-ban"></i> Limpar
				</button>
        	</div>
      	</div>
    
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import RhDiariasService from "@/service/RhDiariasService";
import { EventBus } from "@/main";
import CadastroFuncionarioService from "@/service/CadastroFuncionarioService";

export default{
    name: "Encerramento",
    data(){
        return{
            /* servidor: {
                matricula:"",
                nome:"",
                status:null,
            }, */
            diaria:{
                id:null,
                status:null,
                funcionario:{
                    pessoaCorrespondente:{
                        nome:null,
                    },
                },
            },
		    submitted: false,
		    isSaving: false,
        };
    },
    // created(){
    //     EventBus.$on("mostrarDiaria",(id)=>{
    //         this.VerDiaria()
    //     })
    // },

    /* validations:{
        servidor:{
            matricula:{
                required
            },
            nome:{
                required
            },
            status:{
                required
            },
        },
    }, */
    methods:{
        VerDiaria(){
            RhDiariasService.getDiaria(this.diaria.id)
            .then((res)=>this.diaria=res.data);
        },
        limparEncerramento(){
            this.diaria={
                id:null,
                status:null,
                funcionario:{
                    pessoaCorrespondente:{
                        nome:null,
                    },
                },
            }
        },

        buscarServidorID(){
            CadastroFuncionarioService.buscarFuncionarioPorId(this.diaria.funcionario.id,)
            .then((response)=>this.resposta=response.data)
        },

        SalvarEncerramento(){
            RhDiariasService.encerrarDiaria(this.diaria.id,this.diaria).then((resp)=>{
                    Toast.fire("Cadastrado realizado", "", "success");
                    EventBus.$emit("AtualizarDiariasPendentes")
                }).catch(()=>{
                    Toast.fire("Falha ao cadastrar", "", "error");

                });
            EventBus.$emit("AtualizaDiaria")
        },
    }
}

</script>