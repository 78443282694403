<template>
  <div>
    <h5 v-if="documentos.length > 0" class="my-4">Documentos já cadastrados:</h5>
    <div v-if="documentos.length > 0" class="card">
      <div class="card-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Número do documento</th>
              <th>Data de expedição</th>
              <th>Data de validade</th>
              <th>Tipo do documento</th>

            </tr>
          </thead>
          <tbody>
            <tr
              v-for="doc in documentos"
              :key="doc.id"
              @click.prevent="deleteDocumento(doc.id)"
            >
              <td>{{ doc.numero }}</td>
              <td>{{ doc.dataExpedicao }}</td>
              <td>{{ doc.dataValidade }}</td>
              <td>{{ doc.tipoDocumento.descricao }}</td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/main';
export default {
  name: "TableDocumentosPessoa",
  created() {
    EventBus.$on('preencherListaDocumentos', this.preencherTabela)
  },
  methods: {
    preencherTabela(lista){
      this.documentos = lista
      if(this.documentos.length > 0) {
        EventBus.$emit('esconderCamposDocumento', true)
        EventBus.$emit('deveValidarDocumento', false)
      }
    }
  },

  data() {
    return {
      documentos: []
    };
  },
};
</script>

<style></style>
