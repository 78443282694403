<template>
  <div class="container-fluid">

    <form>
      <div class="card card-primary">
        <div class="card-header background-principal">
          <h3 class="card-title">Cadastrar nova notificação</h3>
          <div class="card-tools px-1">
            <button type="button" @click.prevent="voltarVisualizarNotificacoesButton" class="btn btn-danger">
              <i class="fas fa-arrow-left"></i>
              Voltar
            </button>
          </div>
        </div>
        <div class="card-body">
          <Loader :active="loaderActive" message="Consultando..." />
          <div class="form-group" v-show="!loaderActive">
            <div class="row">
              <div class="col-md-6 col-sm-12 mt-3">

                <label>Selecione para quem é a notificação:</label>
                <select class="form-control" v-model="servicoNotificacao">
                  <option value="SETORIAL">Setor</option>
                  <option value="SISTEMA">Sistema (Para todos)</option>
                </select>
              </div>
              <div class="col-md-6 col-sm-12 mt-3">

                <label>Selecione o tipo da notificação:</label>
                <select class="form-control select-border" :class="{
                  'border border-danger': tipoNotificacao === 'CRITICO',
                  'border border-warning': tipoNotificacao === 'AVISO'
                }" v-model="tipoNotificacao">
                  <option value="AVISO">Aviso</option>
                  <option value="CRITICO" :disabled="servicoNotificacao === 'SETORIAL'">Crítico</option>
                </select>
              </div>

            </div>
            <label for="inputMensagem" class="mx-2 digitar-mensagem">{{ "Digite a mensagem (" +
              mensagemNotificacao.length + "/254)"
            }} </label>
            <textarea class="form-control mx-2" rows="3"
              placeholder="Digite a notificação que deseja enviar a todos os usuários..." v-model="mensagemNotificacao"
              size=254 maxlength=254></textarea>



            <div class="col-sm-12 mt-3" v-show="servicoNotificacao === 'SETORIAL'">
              <label>Selecione os setores a serem notificados:</label>
              <v-select class="border-v-select" id="funcao" multiple
                v-model="unidadesSelecionadas" :options="unidades" label="descricao">
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-right">
            <button @click.prevent="cadastrarNotificacao" v-on:keyup.enter="cadastrarNotificacao" type="submit"
              class="btn background-principal col-sm-12 text-color-principal">
              <i class="fa-solid fa-pen-to-square"></i>
              Cadastrar Notificação
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Loader from '@/components/Loader.vue';
import ConsultaListasService from '@/service/ConsultaListasService';
import NotificacoesService from '@/service/NotificacoesService';
import notificacoesToggle from '@/util/notificacoesToggle';

export default {
  name: "CadastrarNotificacoes",
  data() {
    return {
      mensagemNotificacao: "",
      tipoNotificacao: "AVISO",
      loaderActive: false,
      servicoNotificacao: "SISTEMA",
      unidades: [],
      unidadesSelecionadas: [],

    };
  },
  created() {
    // ConsultaListasService.buscarListaPorNomeTabela("unidade")
    //   .then((response) => {
    //     this.unidades = response.data;
    //   }).catch((e) => this.alertaFalhaBuscarUnidades());

    ConsultaListasService.buscarListaUnidadesSSP()
      .then((response) => {
        this.filtrarUnidades(response.data.unidadesInferiores);
      }).catch((e) => this.alertaFalhaBuscarUnidades());
  },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    }
  },

  methods: {
    filtrarUnidades(unidades) {
      let listaUnidades = []
      function percorrerObjetos(lista) {
        lista.forEach(element => {
          let objeto = {
            'id': element.id,
            'descricao': element.descricao
          }
          listaUnidades.push(objeto);
          if (element.unidadesInferiores.length > 0) {
            percorrerObjetos(element.unidadesInferiores)
          }
        });

      };
      percorrerObjetos(unidades);
      this.unidades = listaUnidades;
    },
    voltarVisualizarNotificacoesButton() {
      this.$emit("VisualizarNotificacoes");
    },
    showLoader() {
      alertaFalhaBuscarUnidades()
      this.loaderActive = true
    },
    hideLoader() {
      this.loaderActive = false
    },
    alertaFalhaBuscarUnidades() {
      Toast.fire("Falha ao buscar unidades", "", "error");
    },
    alertaSucessoAoCadastrarNotificacao() {
      Toast.fire("Sua notificação foi cadastrada com sucesso", "", "success");
    },
    alertaFalhaAoCadastrarNotificacao() {
      Toast.fire("Falha ao cadastrar notificação", "", "error");
    },
    alertaFalhaTamanhoNotificacao() {
      Toast.fire("Sua notificação deve ter no mínimo 10 caractéres", "", "error");
    },
    alertaFalhaSelecioneUnidade() {
      Toast.fire("Falha, selecione ao menos um setor", "", "error");
    },
    cadastrarNotificacaoSistema() {
      const notificacao = {
        mensagem: this.mensagemNotificacao,
        tipoNotificacao: this.tipoNotificacao,
        criadorId: this.usuario.idFuncionario,
      };
      NotificacoesService.cadastrarNotificacoesSistema(notificacao).then((response) => {
        this.hideLoader()
        this.alertaSucessoAoCadastrarNotificacao();
        this.mensagemNotificacao = "";
        this.tipoNotificacao = "AVISO";
        this.servicoNotificacao = "SISTEMA";

        notificacoesToggle.$emit("buscarNovasNotificacoes", null);
        notificacoesToggle.$emit("buscarNovasNotificacoesSistema", null)
        this.$emit("VisualizarNotificacoes");
      }).catch((e) => {
        this.hideLoader();
        this.alertaFalhaAoCadastrarNotificacao();
      });
    },
    cadastrarNotificacaoSetorial() {
      const notificacao = {
        mensagem: this.mensagemNotificacao,
        tipoNotificacao: this.tipoNotificacao,
        criadorId: this.usuario.idFuncionario,
        unidades: this.unidadesSelecionadas,
      };
      if (this.unidadesSelecionadas.length !== 0 && this.unidadesSelecionadas !== (undefined && null)) {
        NotificacoesService.cadastrarNotificacoesSetorial(notificacao).then((response) => {
          this.hideLoader()
          this.alertaSucessoAoCadastrarNotificacao();
          this.mensagemNotificacao = "";
          this.tipoNotificacao = "AVISO";
          this.servicoNotificacao = "SISTEMA";
          this.unidadesSelecionadas = [];

          notificacoesToggle.$emit("buscarNovasNotificacoes", null);
          notificacoesToggle.$emit("buscarNovasNotificacoesSistema", null)
          this.$emit("VisualizarNotificacoes");
        }).catch((e) => {
          this.hideLoader();
          this.alertaFalhaAoCadastrarNotificacao();
        });
      } else {
        this.hideLoader();
        this.alertaFalhaSelecioneUnidade();
      }
    },
    cadastrarNotificacao() {
      this.showLoader();
      if (this.mensagemNotificacao.length < 10) {
        this.alertaFalhaTamanhoNotificacao();
        this.hideLoader();
      }
      else {
        if (this.servicoNotificacao === "SISTEMA") {
          this.cadastrarNotificacaoSistema();
        }
        else {
          this.cadastrarNotificacaoSetorial();
        }
      }
    }
  },
  components: { Loader }
}
</script>
