import instance from "./axiosConfig";

class RHService {

    //FERIAS
    criarFerias(feriasDTO) {
        return instance.post("/api/rh/ferias", feriasDTO)
    }

    getFeriasFuncionario(idFuncionario) {
        return instance.get("/api/rh/ferias/transferencia/funcionario/" + idFuncionario);
    }
 
    enviarAnotacaoFerias(idFerias,servidorId){
        return instance.get(`/api/rh/ferias/anotarferias/${idFerias}/${servidorId}`)
    }

    buscarFeriasFiltro() {
        return instance.get("/api/rh/ferias/filtro")
    }

    buscarFerias() {
        return instance.get("/api/rh/ferias/")
    }

    getFeriasPorId(feriasId) {
        return instance.get("/api/rh/ferias/" + feriasId);
    }

    getFeriasPDF(feriasId) {
        return instance.get("/api/rh/ferias/pdf/" + feriasId);
    }

    getOficioPDF(feriasId, mes) {
        return instance.get(`/api/rh/ferias/pdf/${feriasId}/${mes}`);
    }


    editarFerias(ferias) {
        return instance.patch(`/api/rh/ferias/${ferias.id}`, ferias)
    }

    buscarIdCadastrante(idFuncionarioCadastrante) {
        return instance.get("/api/rh/ferias/funcionariocadastrante/" + idFuncionarioCadastrante);
    }

    filtroFerias(params,page){
        return instance.get(`/api/rh/ferias/filtro?page=${page}`,{params: params,});
    }

    //TRANSFERENCIA FERIAS 
    buscarTransferenciaporFerias(idFerias) {
        return instance.get("/api/rh/feriastransferencia/ferias/" + idFerias)
    }

    buscarTransferenciaPDF(idFerias,idTransferencia) {
        return instance.get(`/api/rh/ferias/transferencia/funcionario/pdf/${idFerias}/${idTransferencia}`)
       
    }

    cadastrarTransferencia(transferencia) {
        return instance.post("/api/rh/feriastransferencia", transferencia)
    }

    deletarTransferencia(idFeriasTransferencia) {
        return instance.delete(`/api/rh/feriastransferencia/${idFeriasTransferencia}`);
    }

    editarTransferencia(transferencia) {
        return instance.patch(`/api/rh/feriastransferencia/${transferencia.id}`, transferencia)
    }

    //ANOTACAO
    getAllTipoDocumento() {
        return instance.get("/api/rh/configuracao/tipo-documento");
    }

    criarTipoDocumento(tipoDocumento, token) {
        return instance.post("/api/rh/configuracao/tipo-documento", tipoDocumento);
    }

    editarTipoDocumento(id, tipoDocumento) {
        return instance.put(`/api/rh/configuracao/tipo-documento/${id}`, tipoDocumento);
    }

    getTipoDodumento(id){
        return instance.get(`/api/rh/configuracao/tipo-documento/${id}`)
    }

    // DIARIAS
    getBoletimDados(ano, mes, token) {
        return instance.get(`/api/rh/diaria/boletim?ano=${ano}&mes=${mes}`);
    }

    getBoletimInformativo(ano, mes, supervisorAssinatura, token) {
        return instance.get(`/api/rh/anotacao/boletim-informativo?ano=${ano}&mes=${mes}&supervisorAssinatura=${supervisorAssinatura}`);
    }

    suspenderFerias(id) {
        return instance.put(`/api/rh/ferias/suspensaoferias/${id}`);
    }

    suspenderFeriasTransferencia(id) {
        return instance.put(`/api/rh/feriastransferencia/suspensaotransferiasferias/${id}`);
    }

    suspenderLicenca(id) {
        return instance.put(`/api/rh/anotacao/suspensaoanotacao/${id}`);
    }

    buscaSupervisors() {
        return instance.get("/api/rh/feriastransferencia/buscasupervisor");
    }

    imprimirFerias(idFerias) {
        return instance.get("/api/rh/feriastransferencia/ferias/gerarpdf/" + idFerias, {
            responseType: "blob",
        });
    }
    
    getAllFeriasPendentesAssinatura() {
    return instance.get("/api/rh/feriastransferencia/pendentes/assinatura");
    }

    assinarFerias(senha, id) {
    return instance.get("/api/rh/feriastransferencia/pendentes/assinatura/verificar", {
        params: {
        senha: senha,
        id: id,
        },
    });
    }

    getDiariasFuncionario(idFuncionario) {
        return instance.get("/api/rh/diaria/lista/funcionario/" + idFuncionario);
    }
}

export default new RHService();
