<template>
  <div class="card card-outline">
    <div class="
        card-header
        p-0
        pt-1
        background-principal
        text-color-principal
        pl-2
        mb-0
      " data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
      aria-controls="collapseExample">
      <h6 class="box-title pt-2 pb-2 mb-1">
        <i class="nav-icon fas fa-search text-color-principal"></i> Filtrar
        Pesquisa
      </h6>
    </div>
    <div class="collapse" id="collapseExample">
      <div class="card-body">
        <div class="submit-form">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label class="form-control-label" for="documento-assunto">Tipo Documento <span
                    class="text-danger">*</span>
                </label>
                <v-select class="border-v-select" id="documento-tipoDocumento" :class="{
                  'is-invalid': isSaving && $v.documento.tipoDocumento.$error,
                }" data-cy="tipoDocumento" name="tipoDocumento" v-model="documento.tipoDocumento" @input="mudarForm"
                  :options="tipoDocumento" :reduce="(tipoDocumento) => tipoDocumento.id" label="descricao">
                  <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
                <div v-if="isSaving && !$v.documento.tipoDocumento.required" class="invalid-feedback">
                  Tipo documento é obrigatório
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="form-control-label" for="documento-assunto">Tramitação <span
                    class="text-danger">*</span></label>
                <v-select class="border-v-select" id="documento-tramitacao" data-cy="tramitacao"
                  name="tramitacao" v-model="documento.origemDocumento" :disabled="
                    documento.tipoDocumento == null ||
                    documento.tipoDocumento != 'OF'
                  " :class="{
  'is-invalid':
    isSaving && $v.documento.origemDocumento.$error,
}" @input="mudarFormTramitacao" :options="tipoTramitacao" label="descricao">
                  <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
                <div v-if="isSaving && !$v.documento.origemDocumento.required" class="invalid-feedback">
                  Tramitação documento é obrigatório
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="form-control-label" for="documento-dataExpedicao">Data Expedição <span
                    class="text-danger">*</span></label>
                <!-- <div class="d-flex"> -->
                <input id="documento-dataExpedicao" data-cy="dataExpedicao" type="datetime-local"
                  class="form-control form-control-border" name="dataExpedicao"
                  :model="convertDateTimeFromServer(documento.dataExpedicao)"
                  @change="updateZonedDateTimeField('dataExpedicao', $event)" />
              </div>
            </div>
          </div>
        </div>
        <component :is="componente" ref="corpo-documento-pesquisa" />

        <hr align="left" />

        <div class="float-right">
          <!-- <button class="btn btn-default mr-1">
                        <i class="fas fa-pencil-alt"></i> Rascunho
                    </button> -->
          <button @click="limparPesquisa" class="btn btn-default mr-1">
            <i class="fas fa-ban"></i> Limpar
          </button>
          <button @click="pesquisarDocumento" class="btn text-color-principal background-principal ml-1">
            <i class="fa fa-search"></i> Pesquisar
          </button>
        </div>
        <!-- <button type="reset" class="btn btn-default">
                    <i class="fas fa-times"></i> Cancelar
                </button> -->
      </div>
    </div>
  </div>
  <!-- <div class="card card-outline">
    <div class="card-header d-flex background-principal text-color-principal">
      <h3 class="card-title mr-auto">Filtrar pesquisa</h3>
    </div>
    <div class="card-body">
      <div class="submit-form">
        <div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-right">
        <button class="btn btn-default mr-1">
          <i class="fas fa-pencil-alt"></i> Rascunho
        </button>
        <button class="btn text-color-principal background-principal ml-1">
          <i class="far fa-envelope"></i> Enviar
        </button>
      </div>
      <button type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div> -->
</template>

<script>
import DE from "@/components/documentos/DespachoFormularioDocumentoPesquisa.vue";
import EX from "@/components/documentos/ExternoFormularioDocumentoPesquisa.vue";
import {
  default as CI,
  default as IN
} from "@/components/documentos/InternoFormularioDocumentoPesquisa.vue";
import PR from "@/components/documentos/ProtocoloFormularioDocumentoPesquisa.vue";
import { EventBus } from "@/main.js";
import { DATE_TIME_LONG_FORMAT } from "@/util/filter";
import dayjs from "dayjs";

export default {
  name: "Documentos",
  components: { IN, EX, CI, DE, PR },
  data() {
    return {
      documento: {},
      submitted: false,
      isSaving: false,
      assunto: false,
      componente: "",
      titulo: "Novo Documento",
      tipoDocumento: [
        { descricao: "Comunicação Interna", id: "CI" },
        { descricao: "Ofício", id: "OF" },
        { descricao: "Despacho", id: "DE" },
        { descricao: "Protocolo", id: "PR" },
      ],
      tipoTramitacao: [
        { descricao: "Interna", id: "IN" },
        { descricao: "Externa", id: "EX" },
      ],
    };
  },
  methods: {
    pesquisarDocumento() {
      if (this.$refs["corpo-documento-pesquisa"] != undefined) {
        this.documento = Object.assign(
          {},
          this.documento,
          this.$refs["corpo-documento-pesquisa"].documento
        );

        this.$refs["corpo-documento-pesquisa"].isSaving = this.isSaving;

      }
      // EventBus.$emit("filtrarDocumento", this.documento);
      EventBus.$emit("filtrarDocumento", this.documento);
    },
    mudarForm(event) {

      this.componente = event;
      if (event != "OF") {
        this.documento.origemDocumento = "Interna";
      } else {
        this.documento.origemDocumento = "";
      }
    },
    mudarFormTramitacao(event) {
      this.componente = event.id;
    },

    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_TIME_LONG_FORMAT);
      }
      return null;
    },

    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.documento[field] = dayjs(
          event.target.value,
          DATE_TIME_LONG_FORMAT
        );
      } else {
        this.documento[field] = null;
      }
    },
    limparPesquisa() {
      this.documento = {}
      for (let value in this.documento) {
        switch (typeof this.documento[value]) {
          case "string":
            this.documento[value] = "";
            break;
          case "number":
            this.documento[value] = 0;
            break;
          case "object":
            Array.isArray(this.documento[value])
              ? (this.documento[value] = [])
              : (this.documento[value] = {});
            break;
          default:
        }
        for (let value in this.$refs["corpo-documento-pesquisa"].documento) {
          switch (typeof this.$refs["corpo-documento-pesquisa"].documento[value]) {
            case "string":
              this.$refs["corpo-documento-pesquisa"].documento[value] = "";
              break;
            case "number":
              this.$refs["corpo-documento-pesquisa"].documento[value] = 0;
              break;
            case "object":
              Array.isArray(this.$refs["corpo-documento-pesquisa"].documento[value])
                ? (this.$refs["corpo-documento-pesquisa"].documento[value] = [])
                : (this.$refs["corpo-documento-pesquisa"].documento[value] = {});
              break;
            default:
          }
        }
      }

      this.componente = '';

    },
  },
};
</script>
