<template>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">Dados da visitação *</h5>
    </div>


    <div class="form-group col-lg-4">
      <label for="tipodocumento">Setor do visitado *</label>
      <v-select  id="tipodocumento" v-model="visita.setor"
        :options="setores" label="descricao">
        <span slot="no-options">Desculpe, sem opções no momento.</span>
      </v-select>
    </div>
    <div class="form-group col-lg-4">
      <label for="obs">Visitado *</label>
      <input type="text" id="obs" class="form-control form-control-border border-width-2"
        placeholder="Nome da pessoa que será visitada" v-model="visita.nomePessoaVisitada" @input="
          (event) =>
          (visita.nomePessoaVisitada = event.target.value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase())
        " />
    </div>
    <div class="form-group col-lg-4">
      <label for="obs">Observação</label>
      <input type="text" id="obs" class="form-control form-control-border border-width-2"
        placeholder="Alguma observação" v-model="visita.observacao" @input="
  (event) =>
  (visita.observacao = event.target.value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase())
        " />
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import ConsultaListasService from "@/service/ConsultaListasService";
export default {
  name: "FormVisitaComponent",
  data() {
    return {
      setores: [],
      visita: {

      },
    };
  },
  created() {
    this.preencherListasObj({ setores: "unidade" });
    EventBus.$on('preencherCamposVisita', valor => this.visita = valor)
  },
  methods: {

    preencherListasObj(obj) {
      Object.keys(obj).forEach((lista) => {
        ConsultaListasService.buscarListaPorNomeTabela(obj[lista])
          .then((response) => {
            this[lista] = response.data;

          })
          .catch((e) => console.log("ERRO: " + e.message));
      });
    },

  }

};
</script>

<style>

</style>
