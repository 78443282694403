<template>
  <div>
    <b-modal id="modal-19" ref="modal-19" title="Documentos do Limite de Diárias" data-backdrop="static" size="xl"
      cancel-disabled no-close-on-backdrop hide-header-close header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            " content-class="modal-content border-rounded-detail" dialog-class="p-3"
      title-class="modal-title mx-auto xl">

      <div class="card-body">
        <div class="row">

          <div class="col-10">
            <label class="form-label">Insira o anexo</label>
            <b-form-file class="hidden" name="anexoDocumento" id="anexoDocumento" accept=".pdf" @change="carregarAnexos"
              plain></b-form-file>
          </div>
          <div class="help-block with-errors" v-if="anexoInvalido">
            Permitido apenas arquivos pdf
          </div>
          <div class="form-group float-right">
            <button @click="[EnviarAnexo()]"
              class="float-right btn text-color-principal background-principal ml-3 mt-4">
              <i class="far fa-envelope"></i> Enviar arquivo
            </button>
          </div>
        </div>
        <!--         <div class="row">

          <div class="form-group ">
            <div class="btn btn-default btn-file ml-3 mt-4">
              <i class="fas fa-add fa-paperclip"></i> Adicionar anexo
              <input class="hidden" type="file" accept=".pdf" name="anexoDocumento" id="anexoDocumento" />
              <input type="file" accept=".pdf" @change="carregarAnexos" />
            </div>
            <div class="help-block with-errors" v-if="anexoInvalido">
              Permitido apenas arquivos pdf
            </div>
          </div>

          <div class="form-group">
            <button @click="[EnviarAnexo()]" class="btn text-color-principal background-principal ml-3 mt-4">
              <i class="far fa-envelope"></i> Enviar arquivo
            </button>
          </div>
        </div>
 -->

        <div class="table table-responsive p-0">
          <table class="table table-hover table-striped text-nowrap table-responsive-sm table-head-fixed">

            <thead>
              <tr>
                <th>Nome do Arquivo</th>
                <th>Arquivo</th>

              </tr>
            </thead>
            <tbody>
              <tr class="clicavel" v-for="(arq, index) in anexos" :key="index">
                <td>{{ arq.nome_arquivo }}</td>
                <td>
                  <button @click.prevent="downloadFile(arq)" type="button" style="border:none !important;"
                    title="Download do Arquivo" class="btn btn-default btn-sm gerar">
                    <i class="fa-solid fa-file"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="row" v-if="anxoTemp.length">
                    <div class="box-footer card-footer bg-white">
                        <ul class="mailbox-attachments">
                        <li v-for="anexoDocumento in anxoTemp" v-bind:key="anexoDocumento.id">
                            <span class="mailbox-attachment-icon"><i class="fas fa-file-pdf"></i></span>
                            <div class="mailbox-attachment-info">
                            <a href="#" class="mailbox-attachment-name"><i class="fas fa-paperclip"></i> {{ anexoDocumento.name }}
                            </a>
                            <span class="mailbox-attachment-size clearfix mt-1">
                                <span>{{
                                calaculaTamanhoArquivo(anexoDocumento.size)
                                }}</span>
                                <a @click="removeAnexo(anexoDocumento.id)" class="btn btn-default btn-sm float-right"><i
                                    class="fas fa-trash fa-lg"></i></a>
                            </span>
                            </div>
                        </li>
                        </ul>
                    </div>
                </div> -->
      </div>
      <template #modal-footer="{ cancel }">
        <b-button class="btn btn-default mr-auto" @click="cancel()">
          <i class="fa-solid fa-x"></i>Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import RhDiariasDiariaService from "@/service/RhDiariasService";
import { EventBus } from '@/main';

export default {
  name: "AnexoLimiteDiarias",

  data() {
    return {
      documeto: {
        tipoDocumento: "",
        origemDocumento: "",
        dataExpedicao: "",
        corpo: "",

      },
      anxoTemp: [],
      anexo: [],
      anexoInvalido: false,
      assinar: false,
      anexos: {},
      arq1: {}
    }
  },


  created() {
    this.buscarAnexos(this.$store.state.funcionario.idFuncionarioCadastrado);

    EventBus.$on("LimiteDiarias", () => {
      this.buscarAnexos(this.$store.state.funcionario.idFuncionarioCadastrado);
    })


    EventBus.$on("AtualizarAnexo", () => {
      this.buscarAnexos(this.$store.state.funcionario.idFuncionarioCadastrado);
    })
  },


  methods: {
    carregarAnexos(event) {
      let pdfValido = true;

      Array.from(event.target.files).forEach((file) => {
        if (file.type != "application/pdf") {
          pdfValido = false;
        }
      });
      if (pdfValido) {
        Array.from(event.target.files).forEach((file) => {
          this.arq1 = file
          /*           const reader = new FileReader();
                    reader.onloadend = () => {
                      const base64String = reader.result;
          
                      let arquivoAnexo = {};
                      arquivoAnexo.id = Math.floor(Date.now() * Math.random()).toString(
                        36
                      );
                      arquivoAnexo.arquivo = base64String;
                      this.anxoTemp.push(arquivoAnexo)
          
                      console.log(this.anxoTemp)
          
                      this.anexo = arquivoAnexo
                      //this.anexo.push(arquivoAnexo);
          
                    };
                    this.anexoInvalido = false;
                    reader.readAsDataURL(file); */

        });
      } else {
        this.anexoInvalido = true;
      }
    },
    // calaculaTamanhoArquivo(value) {
    //   let const_term = 1024;
    //   let retorno = (value / const_term ** 2).toFixed(1);
    //   if (retorno < 1) {
    //     return (value / const_term).toFixed(3) + " KB";
    //   }
    //   return retorno + " MB";
    // },
    removeAnexo(anexo) {
      this.anexo = {};
    },

    // EnviarAnexo(){

    //   let idFuncionario = this.$store.state.funcionario.idFuncionarioCadastrado
    //   RhDiariasDiariaService.anexar(this.anexo.arquivo,idFuncionario)

    // },

    EnviarAnexo() {
      let formData = new FormData();
      let idFuncionario = this.$store.state.funcionario.idFuncionarioCadastrado;
      formData.append('arquivo', this.arq1);
      formData.append('funcionarioId', idFuncionario);

      // Exibir dados do formulário
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      RhDiariasDiariaService.anexar(idFuncionario, formData)
        .then(() => {
          EventBus.$emit("LimiteDiarias");
        })
        .catch(error => {
          console.error('Erro ao anexar arquivo:', error);
        });
    },

    buscarAnexos() {
      //let idFuncionario = this.$store.state.funcionario.idFuncionarioCadastrado;
      RhDiariasDiariaService.buscarAnexos(this.$store.state.funcionario.idFuncionarioCadastrado).then((resp) => {
        this.anexos = resp.data;
        console.log(resp.data);
      })
    },

    downloadFile(arq) {
      RhDiariasDiariaService.downloadAnexo(arq.id).then((response) => {
        const filebase64 = response.data
        const urlPDF = `data:application/pdf;base64,${filebase64}`;
        const link = document.createElement('a');
        link.href = urlPDF;
        link.target = '_blank';
        link.download = arq.nome_arquivo;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      })

    }

  }

}
</script>

<style></style>