<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-cnpjUg">Cnpj Ug</label>
          <input type="text" class="form-control form-control-border" name="cnpjUg" id="procedimento-cnpjUg"
            data-cy="cnpjUg" :class="{
              'is-invalid': isSaving && $v.procedimento.cnpjUg.$error,
            }" v-model="procedimento.cnpjUg" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-idContratacao">Id Contratacao</label>
          <input type="text" class="form-control form-control-border" name="idContratacao"
            id="procedimento-idContratacao" data-cy="idContratacao" :class="{
              'is-invalid': isSaving && $v.procedimento.idContratacao.$error,
            }" v-model="procedimento.idContratacao" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-numeroProcesso">Número Processo</label>
          <input type="text" class="form-control form-control-border" name="numeroProcesso"
            id="procedimento-numeroProcesso" data-cy="numeroProcesso" :class="{
              'is-invalid': isSaving && $v.procedimento.numeroProcesso.$error,
            }" v-model="procedimento.numeroProcesso" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-anoProcesso">Ano Processo</label>
          <input type="number" class="form-control form-control-border" name="anoProcesso" id="procedimento-anoProcesso"
            data-cy="anoProcesso" :class="{
              'is-invalid': isSaving && $v.procedimento.anoProcesso.$error,
            }" v-model.number="procedimento.anoProcesso" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-numeroProcedimento">Numero Procedimento</label>
          <input type="number" class="form-control form-control-border" name="numeroProcedimento"
            id="procedimento-numeroProcedimento" data-cy="numeroProcedimento" :class="{
              'is-invalid':
                isSaving && $v.procedimento.numeroProcedimento.$error,
            }" v-model.number="procedimento.numeroProcedimento" />
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-anoProcedimento">Ano Procedimento</label>
          <input type="number" class="form-control form-control-border" name="anoProcedimento"
            id="procedimento-anoProcedimento" data-cy="anoProcedimento" :class="{
              'is-invalid': isSaving && $v.procedimento.anoProcedimento.$error,
            }" v-model.number="procedimento.anoProcedimento" />
        </div>
      </div>
    </div>
    <div class="row">
      <!--div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-dataPublicacao">Data Publicacao</label>
          <div class="d-flex">
            <input id="procedimento-dataPublicacao" data-cy="dataPublicacao" type="datetime-local"
              class="form-control form-control-border" name="dataPublicacao" :class="{
                'is-invalid': isSaving && $v.procedimento.dataPublicacao.$error,
              }" :model="convertDateTimeFromServer($v.procedimento.dataPublicacao)"
              @change="updateZonedDateTimeField('dataPublicacao', $event)" />
          </div>
        </div>
      </div-->

       <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="procedimento-dataPublicacao"
              >Data Publicação</label
              >
            <div class="d-flex">
              <input
                type="date" class="form-control form-control-border border-width-2" id="procedimento-dataPublicacao"
                  placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(procedimento.dataPublicacao)" :class="{
                    'is-invalid': isSaving && $v.procedimento.dataPublicacao.$error,
                      }"
                  @change="updateZonedDateTimeField('dataPublicacao', $event)"
              />
            </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-numeroLei">Número Lei</label>
          <input type="number" class="form-control form-control-border" name="numeroLei" id="procedimento-numeroLei"
            data-cy="numeroLei" :class="{
              'is-invalid': isSaving && $v.procedimento.numeroLei.$error,
            }" v-model.number="procedimento.numeroLei" />
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-anoLei">Ano Lei</label>
          <input type="number" class="form-control form-control-border" name="anoLei" id="procedimento-anoLei"
            data-cy="anoLei" :class="{
              'is-invalid': isSaving && $v.procedimento.anoLei.$error,
            }" v-model.number="procedimento.anoLei" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-codProcedimento">Cod Procedimento</label>
          <v-select class="border-v-select" id="procedimento-codProcedimento" :class="{
            'is-invalid': isSaving && $v.procedimento.codProcedimento.$error,
          }" data-cy="codProcedimento" name="codProcedimento" v-model="procedimento.codProcedimento"
            :options="codProcedimento" :reduce="codProcedimento => codProcedimento.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-criterio">Critério</label>
          <v-select class="border-v-select" id="procedimento-criterio" :class="{
            'is-invalid': isSaving && $v.procedimento.criterio.$error,
          }" data-cy="criterio" name="criterio" v-model="procedimento.criterio" :options="criterio"
            :reduce="criterio => criterio.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-finalidade">Finalidade</label>
          <v-select class="border-v-select" id="procedimento-finalidade" :class="{
            'is-invalid': isSaving && $v.procedimento.finalidade.$error,
          }" data-cy="finalidade" name="finalidade" v-model="procedimento.finalidade" :options="finalidade"
            :reduce="finalidade => finalidade.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-sistemaPregao">Sistema Pregão</label>
          <input type="text" class="form-control form-control-border" name="sistemaPregao"
            id="procedimento-sistemaPregao" data-cy="sistemaPregao" :class="{
              'is-invalid': isSaving && $v.procedimento.sistemaPregao.$error,
            }" v-model="procedimento.sistemaPregao" />
        </div>
      </div>
      <!--div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-dataAdesao">Data Adesão</label>
          <div class="d-flex">
            <input id="procedimento-dataAdesao" data-cy="dataAdesao" type="datetime-local"
              class="form-control form-control-border" name="dataAdesao" :class="{
                'is-invalid': isSaving && $v.procedimento.dataAdesao.$error,
              }" :model="convertDateTimeFromServer($v.procedimento.dataAdesao)"
              @change="updateZonedDateTimeField('dataAdesao', $event)" />
          </div>
        </div>
      </div-->

      <div class="col-sm-4">
         <div class="form-group">
            <label class="form-control-label" for="procedimento-dataAdesao"
              >Data Adesão</label
              >
            <div class="d-flex">
              <input
                type="date" class="form-control form-control-border border-width-2" id="procedimento-dataAdesao"
                  placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(procedimento.dataAdesao)" :class="{
                    'is-invalid': isSaving && $v.procedimento.dataAdesao.$error,
                      }"
                  @change="updateZonedDateTimeField('dataAdesao', $event)"
              />
            </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-regimeExecucao">Regime Execução</label>
          <v-select class="border-v-select" id="procedimento-regimeExecucao" :class="{
            'is-invalid': isSaving && $v.procedimento.regimeExecucao.$error,
          }" data-cy="regimeExecucao" name="regimeExecucao" v-model="procedimento.regimeExecucao"
            :options="regimeExecucao" :reduce="regimeExecucao => regimeExecucao.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-objeto">Objeto</label>
          <input type="text" class="form-control form-control-border" name="objeto" id="procedimento-objeto"
            data-cy="objeto" :class="{
              'is-invalid': isSaving && $v.procedimento.objeto.$error,
            }" v-model="procedimento.objeto" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-valorEstimado">Valor Estimado</label>
          <input type="number" class="form-control form-control-border" name="valorEstimado"
            id="procedimento-valorEstimado" data-cy="valorEstimado" :class="{
              'is-invalid': isSaving && $v.procedimento.valorEstimado.$error,
            }" v-model.number="procedimento.valorEstimado" />
        </div>
      </div>

      <!--div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-dataSessao">Data Sessão</label>
          <div class="d-flex">
            <input id="procedimento-dataSessao" data-cy="dataSessao" type="datetime-local"
              class="form-control form-control-border" name="dataSessao" :class="{
                'is-invalid': isSaving && $v.procedimento.dataSessao.$error,
              }" :model="convertDateTimeFromServer($v.procedimento.dataSessao)"
              @change="updateZonedDateTimeField('dataSessao', $event)" />
          </div>
        </div>
      </div-->

      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="procedimento-dataSessao"
              >Data Sessão</label
              >
            <div class="d-flex">
              <input
                type="date" class="form-control form-control-border border-width-2" id="procedimento-dataSessao"
                  placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(procedimento.dataSessao)" :class="{
                    'is-invalid': isSaving && $v.procedimento.dataSessao.$error,
                      }"
                  @change="updateZonedDateTimeField('dataSessao', $event)"
              />
            </div>
        </div>
      </div>



    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="procedimento-cpfAutoridade">Cpf Autoridade</label>
          <input type="text" class="form-control form-control-border" name="cpfAutoridade"
            id="procedimento-cpfAutoridade" data-cy="cpfAutoridade" :class="{
              'is-invalid': isSaving && $v.procedimento.cpfAutoridade.$error,
            }" v-model="procedimento.cpfAutoridade" />
            
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-right">
        <!--button class="btn btn-default mr-1">
          <i class="fas fa-pencil-alt"></i> Rascunho
        </button-->
        <button @click="salvarProcedimento" class="btn text-color-principal background-principal ml-1">
          <i class="far fa-envelope"></i> Enviar
        </button>
        <button @click="limparProcedimento" type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Limpar
      </button>
      </div>
      <!--button type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Cancelar
      </button-->
    </div>
    
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import { DATE_TIME_LONG_FORMAT, DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import dayjs from "dayjs";
import { EventBus } from "@/main";
import processosService from '@/service/ProcessosService';
import TabelaProcedimento from "@/components/processos/TabelaProcedimento";

export default {
  name: "Ata-Registro",
  components:{TabelaProcedimento},
  data() {
    return {
      data:{},
      procedimento: {},
      submitted: false,
      isSaving: false,
      setores: [],
      funcionarios: [],
      codProcedimento: [
        { id:1,  descricao: "Dispensa" },
        { id:2,  descricao: "Inexigibilidade" },
        { id:4,  descricao: "Concorrência pública" },
        { id:5,  descricao: "Tomada de Preços" },
        { id:6,  descricao: "Carta Convite" },
        { id:7,  descricao: "Concurso" },
        { id:8,  descricao: "Leilão" },
        { id:9,  descricao: "Licitação Internacional" },
        { id:10, descricao: "Pregão Eletrônico" },
        { id:11, descricao: "Pregão Presencial" },
        { id:12, descricao: "Credenciamento;" },
        { id:13, descricao: "RDC Eletrônico" },
        { id:14, descricao: "RDC Presencial" },
        { id:15, descricao: "Adesão a Ata de Registro de Preço" },
        { id:16, descricao: "Diálogo competitivo" },
        { id:17, descricao: "Procedimento da Lei 13.393/2016" }
      ],
      criterio: [
        { id: 1, descricao: " Menor Preço " },
        { id: 2, descricao: " Melhor técnica ou conteúdo artístico " },
        { id: 3, descricao: " Técnica e preço " },
        { id: 4, descricao: " Maior lance ou oferta " },
        { id: 5, descricao: " Menor valor de tarifa " },
        { id: 6, descricao: " Maior oferta outorga " },
        { id: 7, descricao: " Melhor preço " },
        { id: 8, descricao: " Melhor projeto " },
        { id: 9, descricao: " Maior retorno econômico " },
        { id: 10, descricao: " Maior desconto " },
        { id: 11, descricao: " Menor valor tarifa " },
        { id: 12, descricao: " Maior oferta outorga " },
        { id: 13, descricao: " Menor valor tarifa com maior oferta outorga " },
        { id: 14, descricao: " Menor valor tarifa com melhor oferta de pagamento " },
        { id: 15, descricao: " Melhor oferta de pagamento " },
        { id: 16, descricao: " Melhor proposta técnica, com preço fixado no edital " },
        { id: 17, descricao: " Menor valor da tarifa com o de melhor técnica " },
        { id: 18, descricao: " Maior oferta outorga com melhor técnica " },
        { id: 19, descricao: " Maior oferta outorga com melhor oferta de pagamento " },
        { id: 20, descricao: " Nenhum " },
        { id: 21, descricao: " Melhor combinação de técnica e preço " },
        { id: 22, descricao: " Melhor destinação de bens alienados " }
      ],
      finalidade: [
        { id: 1, descricao: " Alienação de bens móveis ou imóveis " },
        { id: 2, descricao: " Aquisição de bens " },
        { id: 3, descricao: " Aquisição de bens e serviços " },
        { id: 4, descricao: " Aquisição de serviços " },
        { id: 5, descricao: " Concessão de direito real de uso " },
        { id: 6, descricao: " Concessão de serviços " },
        { id: 7, descricao: " Concessão de serviços públicos precedida de execução de obra pública " },
        { id: 8, descricao: " Concessão de uso " },
        { id: 9, descricao: " Contratação de obras " },
        { id: 10, descricao: " Contratação de obras e serviços de engenharia " },
        { id: 11, descricao: " Contratação de serviços de engenharia " },
        { id: 12, descricao: " Credenciamento " },
        { id: 13, descricao: " Permissão " },
        { id: 14, descricao: " Pré-qualificação " },
        { id: 15, descricao: " Registro de preços " },
        { id: 16, descricao: " Locação de bens " },
        { id: 17, descricao: " Locação de bens e serviços " },
        { id: 18, descricao: " Locação de serviços " },
      ],
      regimeExecucao: [
        { id: 1, descricao: " Empreitada por preço global " },
        { id: 2, descricao: " Empreitada por preço unitário " },
        { id: 3, descricao: " Empreitada integral " },
        { id: 4, descricao: " Contratação por tarefa " },
        { id: 5, descricao: " Fornecimento " },
        { id: 6, descricao: " Fornecimento e prestação de serviço associado " },
        { id: 7, descricao: " Contratação integrada " },
        { id: 8, descricao: " Contratação semi-integrada " },
        { id: 9, descricao: " Outros " },
      ]
    };
  },

 

  validations: {
    procedimento: {
      cnpjUg: {},
      idContratacao: {},
      numeroProcesso: {},
      anoProcesso: {},
      numeroProcedimento: {},
      anoProcedimento: {},
      dataPublicacao: {},
      numeroLei: {},
      anoLei: {},
      codProcedimento: {},
      criterio: {},
      sistemaPregao: {},
      finalidade: {},
      dataAdesao: {},
      regimeExecucao: {},
      objeto: {},
      valorEstimado: {},
      dataSessao: {},
      cpfAutoridade: {},

      //cpfAutoridade: { required },
    },
  },

  created() {
      EventBus.$on("EnvioProcedimento",(listener)=>{
        this.getProcedimento(listener);
        
      }),

      EventBus.$on("ApagarCamposProcedimento",()=>{
        this.procedimento = {};
      })
    },

  methods: {
    // updateZonedDateTimeField(field, event) {
    //   if (event.target.value) {
    //     this.documento[field] = dayjs(
    //       event.target.value,
    //       DATE_TIME_LONG_FORMAT
    //     );
    //   } else {
    //     this.documento[field] = null;
    //   }
    // },
    mudarForm() {
      // console.log("sjdfnosdf");
    },

    limparProcedimento(){
      this.procedimento = {};
      document.getElementById("procedimento-dataPublicacao").value = "";
      document.getElementById("procedimento-dataAdesao").value = "";
      document.getElementById("procedimento-dataSessao").value = "";

    
    },

    getProcedimento(id){
      processosService.getProcedimento(id)
      .then((resp)=>{
        this.procedimento= resp.data;
      })
    },

  
    salvarProcedimento(){
    console.log("SALVAR PROCEDIMENTO")
    this.isSaving = true;
    this.$v.$touch();
 
      if (this.$refs["procedimento"]!= undefined){
        this.procedimento = Object.assign(
          {},
          this.procedimento,
          this.$refs["procedimento"].procedimento
        );
 
        this.$refs["procedimento"].isSaving = this.isSaving;
        if (this.$v.$ivalid || this.$refs["procedimento"].$v.$ivalid){
          return;
        } else{
          if (this.$v.$ivalid){
            return;
 
 
          }
        }
 
      }
      processosService.criarProcedimento(this.procedimento)
      .then((response)=> {
        this.procedimento= response.data;
        Toast.fire("Cadastrado realizado", "", "success");
        this.procedimento={};
        document.getElementById("procedimento-dataPublicacao").value = "";
        document.getElementById("procedimento-dataAdesao").value = "";
        document.getElementById("procedimento-dataSessao").value = "";
 
      })

  },

    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.procedimento[field] = dayjs(
          event.target.value,
          DATE_FORMAT_LOCAL_DATA
        );
      } else {
        this.procedimento[field] = null;
      }
    },
  },
  // mounted() {
  //   this.carregaSetores();
  //   this.carregaFuncionario();
  // }
};
</script>
