<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card card-primary">
        <div class="card-header background-principal">
          <h3 class="card-title">Buscar Relatório</h3>
        </div>

        <div class="card-body">
          <div class="form-group">
            <label for="antigaMatricula">Matrícula </label>
            <input type="text" class="form-control form-control-border border-width-2" id="matricula"
              placeholder="xxx.xxx.xxx" v-model="frequencia.matricula"
              :class="{ 'is-invalid': $v.frequencia.matricula.$error }" />
            <div v-if="!$v.frequencia.matricula.maxLength" class="invalid-feedback">
              Tamanho máximo de 40 caracteres
            </div>
          </div>


          <div class="form-group">
            <label for="mes">Mês:</label>
            <v-select class="border-v-select" id="mes" v-model="frequencia.mes" :options="meses"
              :reduce="meses => meses.id" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>




          <div class="form-group">
            <label for="ano">Ano:</label>
            <input type="number" class="form-control form-control-border border-width-2" id="ano"
              v-model="frequencia.ano" />

          </div>
          <!-- <div class="form-group">
            <label for="lotacao">Cidades</label>
            <v-select class="border-v-select" id="lotacao" v-model="lotacao" :options="lotacoes" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>


          </div> -->


          <div class="form-group">
            <label for="lotacao">Unidades</label>
            <v-select class="border-v-select" id="lotacao" v-model="frequencia.lotacao" :options="lotacoes" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>


          <!-- <div class="form-group">
            <label for="lotacao">Legendas</label>
            <v-select class="border-v-select" id="lotacao" v-model="legenda" :options="legendas" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div> -->

        </div>


        <div class="card-footer">
          <div class="d-flex justify-content-between">
            <button class="btn btn-default">
              <i class="fa fa-ban"></i>Limpar
            </button>

            <button class="btn text-color-principal background-principal">
              <i class="fa fa-search"></i> Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="card card-primary">
        <div class="background-principal card-header text-color-principal">
          <h3 class="card-title">Relatório</h3>
          <div>
            <div class="float-right">
              
            </div>
            <div class="float-right">

            </div>
          </div>
        </div>
        <div>
          <div>Sem resultados!</div>
        </div>
        <div>

          <div>

          </div>
          <div >
            <div >
              <div >
                <div>
                  <div class="card-body pt-0 table-responsive" style="height: 600px;">
    <div class="p-1, .ml-1 (margin-right: 6px;) "></div>
    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import RhfrequenciaService from "@/service/RhFrequenciaService";
import ConsultaListasService from "@/service/ConsultaListasService";

export default {

  comments:{ConsultaListasService, RhfrequenciaService},
  data() {
    return {
      frequencia: {
        mes: null,
        ano: null,
        matricula: null,
      },
      lotacao: null,
      meses: [

        { id: 1, descricao: "Janeiro" },
        { id: 2, descricao: "Fevereiro" },
        { id: 3, descricao: "Março" },
        { id: 4, descricao: "Abril" },
        { id: 5, descricao: "Maio" },
        { id: 6, descricao: "Junho" },
        { id: 7, descricao: "Julho" },
        { id: 8, descricao: "Agosto" },
        { id: 9, descricao: "Setembro" },
        { id: 10, descricao: "Outubro" },
        { id: 11, descricao: "Novembro" },
        { id: 12, descricao: "Dezembro" },
      ],



      lotacoes: [],
      legendas: [],

      funcionarios: {},

      frequenciasLista: [
        {
          mes: null,
          ano: null,
          funcionario: {
            id: null
          },
          dia: []
        },
      ],
      exbirFrequencia: false,
    }

  },

  validations: {
    frequencia: {
      matricula: {
        required: true,

      }
    }

  },


  created() {
    this.carregarListas();
  },

  
  methods: {

    carregarListas() {
      ConsultaListasService.buscarListaPorNomeTabela("unidade")
        .then((response) => {
          this.lotacoes = response.data;
        

        }).catch(()=>{
          Toast.fire("Falha ao buscar Lotação", "", "error");
        });
            
    },

   

    

  },


}
</script>
  
<style></style>