<template>

  <form>
    <div class="card-body">
      <div class="form-group">
        <label for="inputPlacaDoCarro" class="pesquisar-placa-carro">Digite a placa do carro</label>
        <input type="text" class="form-control form-control-border border-width-2" id="inputPlacaDoCarro"
          placeholder="XXX-0000 / XXX0X00" size=8 maxlength=8 v-model="placa" />
      </div>
      <div class="form-group">
        <label for="inputChassiDoCarro" class="pesquisar-chassi-carro">Digite o chassi do carro</label>
        <input type="text" class="form-control form-control-border border-width-2" id="inputChassiDoCarro"
          placeholder="Chassi..." v-mask="'XXXXXXXXXXXXXXXXX'" v-model="chassi" />
      </div>
      <div class="form-group">
        <label for="inputRenavamDoCarro" class="pesquisar-renavam-carro">Digite o renavam do carro</label>
        <input type="text" class="form-control form-control-border border-width-2" id="inputRenavamDoCarro"
          placeholder="Renavam..." v-mask="'###########'" v-model="renavam" />
      </div>
      <div class="form-group">
        <label for="inputCorDoCarro" class="pesquisar-Cor-carro">Selecione a Cor do carro</label>
        <v-select class="border-v-select" id="inputCorDoCarro" v-model="cor" :disabled="
          cores == null ||
          cores == []
        " :options="cores" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>
    </div>
    <div class="card-footer">
      <button @click.prevent="emitirConsulta" v-on:keyup.enter="emitirConsulta" type="submit"
        class="btn col-12 background-principal text-color-principal">
        <i class="fas fa-search"></i>
        Buscar
      </button>
    </div>


  </form>

</template>

<script>
import ConsultaListasService from '@/service/ConsultaListasService.js'
import { EventBus } from '../../../main.js'
import LocalIpService from "@/service/LocalIpService";

export default {
  name: 'FormVeiculosConsulta',
  data() {
    return {
      tipoConsultaNacional: '',
      baseConsulta: 'sigma',
      placa: '',
      chassi: '',
      renavam: '',
      cores: [],
      cor: '',
      proprietario: '',
      cidade: '',
      estado: '',
      total: '',
      page: 1,
      //ipUsuario:null
    }
  },
  created() {
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    ConsultaListasService.buscarListaPorNomeTabela('cor')
      .then((response) => {
        this.cores = response.data
      })
      .catch((e) => this.alertaFalhaBuscarListaPorNomeTabela())
  },
  methods: {
    mostrarAlerta() {
      // eslint-disable-next-line no-undef
      Toast.fire('Preencha ao menos um campo!!', '', 'error')
    },
    alertaFalhaBuscarListaPorNomeTabela() {
      // eslint-disable-next-line no-undef
      Toast.fire('Falha ao buscar cor!!', '', 'error')
    },
    formValido() {
      if (
        this.placa ||
        this.chassi ||
        this.renavam ||
        this.cor
      ) {
        return true
      }
      return false
    },
    emitirConsulta() {
      if (this.formValido()) {
        const params = {
          placa: this.placa.toUpperCase(),
          chassi: this.chassi.toUpperCase(),
          renavam: this.renavam,
          proprietario: this.proprietario.toUpperCase(),
          cor: this.cor ? this.cor.id : '',
          page: this.page,
          //ipUsuario:this.ipUsuario
        }
        EventBus.$emit('consultaVeiculo', params)
      } else {
        this.mostrarAlerta()
      }
    }
  }
}
</script>

<style>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}
</style>
