<template>
  <div class="card card-primary">
    <div class="background-principal card-header text-color-principal">
      <h3 class="card-title">Resultado da Busca de Veículos</h3>
      <div class="float-right" v-if="resultados.length > 0">
        <a href="#" v-on:click.prevent="page > 1 ? page-- : 1"><i
            class="fas fa-angle-left mx-2 text-color-principal"></i></a>

        Página {{ page }} / {{totalPaginas}}

        <a href="#" v-on:click.prevent="page++"><i class="fas fa-angle-right mx-2 text-color-principal"></i></a>
      </div>
      <div class="float-right" v-if="(resultados.length == 0 && page !== 1)">
        <a href="#" v-on:click.prevent="page > 1 ? page-- : 1"><i
            class="fas fa-angle-left mx-2 text-color-principal"></i></a>


      </div>
      <!-- /.card-tools -->
    </div>
    <Loader :active="loaderActive" message="Consultando..." />

    <div v-if="resultados.length == 0 && semResultados" class="mx-auto my-auto sem-resultados py-2">
      <div>Sem resultados!</div>
    </div>
    <div v-if="resultados.length > 0">

      <div class="card-body px-0 d-flex flex-row">
        <div class="card-body table-responsive p-0 max-height-content">
          <div>
            <div class="row px-5 d-flex justify-content-around">
              <div :key="id" v-for="(veiculo, id) in resultados" class="card card-item bg-light col-sm-12 col-md-5"
                @click.prevent="showModalDetalhe" @click="clicandoCard(veiculo.id)">
                <a href="#">
                  <div>
                    <div>
                      <div>
                        <p class="header card-item-result background-principal text-color-principal">
                          {{ veiculo.placa }}
                        </p>
                      </div>
                      <div class="row">

                        <div class="col-10 mx-0 my-2 py-0">
                          <ul class="ml-4 mb-0 fa-ul text-muted">
                            <li class="medium"><b>Proprietário: </b> {{ veiculo.proprietario }}</li>
                            <li class="medium"> <b>Chassi: </b>
                              {{ veiculo.chassi }}</li>
                            <li class="medium"><b>Renavam: </b> {{ veiculo.renavam  }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConsultaService from '@/service/ConsultaService.js'
import { EventBus } from '../../../main.js'
import Loader from '../../Loader.vue'
export default {
  name: 'ResultadosVeiculosConsulta',
  Components: {
    Loader
  },
  watch: {
    page(valorAtual, valorAnterior) {
      this.consultar()
    }
  },
  data() {
    return {
      semResultados: false,
      resultados: [],
      loaderActive: false,
      page: 1,
      total: '',
      params: {},
      id: ''
    }
  },
  created() {
    EventBus.$on('consultaVeiculo', (event) => {
      this.params = event
      this.page = event.page
      this.consultar()
    })
  },
  filters: {
    abreviar: function (nomeCompleto) {
      if (nomeCompleto !== null) {
        const nomeFormatado = nomeCompleto.split(' ')
        return nomeFormatado[0] + " ... " + nomeFormatado[nomeFormatado.length - 1]
      } else {
        return "Não Cadastrado"
      }
    },
    formatarChassi: function (chassi) {
      if (chassi !== null) {

        const chassiFormatado = chassi.substring(0, 5);
        return chassiFormatado + '************'
      } else {
        return "Não Cadastrado"
      }
    },
    formatarRenavam: function (renavam) {

      if (renavam !== null) {
        const renavamFormatada = renavam.substring(0, 4);
        return renavamFormatada + "*******"
      } else {
        return "Não Cadastrado"
      }
    }
  },

  computed:{
    totalPaginas(){

      const totalPagina = this.total/10;
      return (totalPagina !==Infinity) ? Math.ceil(totalPagina) : 0; 

      
    },
  },
  
  methods: {
    onChangePage(resultados) {
      this.resultados = resultados
    },
    showLoader() {
      this.resultados = []
      this.loaderActive = true
    },
    hideLoader() {
      this.loaderActive = false
    },
    consultar() {
      // eslint-disable-next-line dot-notation
      this.params['page'] = this.page

      this.showLoader()
      try {
        
          ConsultaService.buscarVeiculos(this.params)
          .then((response) => {
            if (response.data.conteudo.length == 0) {
              this.semResultados = true;
            }

            this.resultados = response.data.conteudo
            this.total = response.data.total
            this.hideLoader()
          })
          .catch((e) => {
            this.hideLoader()
            this.semResultados = true
          })

      } catch (e) {
        this.alertaFalhaBuscarVeiculos()
        this.semResultados = true
      } finally {
        this.semResultados = false
      }
    },
    clicandoCard(id) {
      EventBus.$emit('consultaVeiculoDetalhe', id)
    },
    showModalDetalhe() {
      this.$emit('showModalVeiculo')
    },
    alertaFalhaBuscarVeiculos() {
      Toast.fire("Falha ao buscar buscar veículos", "", "error");
    },
  },
  components: { Loader }
}

</script>
