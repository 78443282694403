<template>
  <section class="content">
    <conteudo>
      <content-header title="Visualizar Usuários" />
      <div class="container-fluid p-3">
          <ListaConsultaFuncionario/>
      </div>
    </conteudo>
  </section>
</template>

<script>
// import FormFuncionarioConsulta from "@/components/administracao/funcionario/visualizacao/FormFuncionarioConsulta.vue";
// import ResultadosFuncionarioConsulta from "@/components/administracao/funcionario/visualizacao/ResultadosFuncionarioConsulta.vue";
import ListaConsultaFuncionario from "@/components/administracao/usuario/visualizacao/ListaConsultaUsuario.vue";
export default {
  name: "ViewVisualizacaoFuncionario",
  components: {
    // FormFuncionarioConsulta,
    // ResultadosFuncionarioConsulta,
    ListaConsultaFuncionario,
  },
  data() {
    return {
      isModalVisible: false,
      showCadastroFuncionario: false,
      para: {},
    };
  },
  methods: {
    beforeClose(event) {
      console.log("Closing...");
    },
  },
};
</script>