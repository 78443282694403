<template>
  <div class="ml-2">
    <div class="p-2">
      <!--FiltroConsultaProcessos/-->
    </div>
    <!--content-header title="Processos" /-->
    <conteudo v-role="'GDOCS'">
      <div class="container-fluid">
        <div class="row p-0">
          <div class="card-body p-0 col-md-3 col-sm-12">
            <div class="card card-primary">
              <div class="card-header background-principal">
                <h3 class="card-title">
                  {{ tituloBusca }}
                </h3>
              </div>

              <div>
                <cardMenu />
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <ConteudoProcesso textTitulo="Novo" />
          </div>
        </div>
      </div>
    </conteudo>
  </div>
</template>

<script>

import cardMenu from "@/components/processos/CardMenu.vue";
import ConteudoProcesso from "@/components/processos/NovoProcessos.vue";
import FiltroConsultaProcessos from "@/components/processos/FiltroConsultaProcessos.vue";


export default {
  components: {
    cardMenu,
    ConteudoProcesso,
    FiltroConsultaProcessos
  },

  data() {
    return {
      tituloBusca: "Gestão de Processos", 

      isModalVisible: false,
    };
  },
};
</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}
</style>
