<template>
    <div>
      <b-modal
        id="modal-40"
        ref="modal-40"
        title="Transferência de Férias"
        data-backdrop="static"
        size="xl"
        cancel-disabled
        no-close-on-backdrop
        hide-header-close
        header-class="modal-header
              background-principal
              text-color-principal
              card-item-result
              "
        content-class="modal-content border-rounded-detail"
        dialog-class="p-3"
        title-class="modal-title mx-auto xl"
      > 
        <div class="card-body">
          <div class="row">
            <div class="form-group col-lg-3">
              <label class="form-control-label" for="transferencia-anoAlteracao"
                >Ano Alterado
              </label>
              <input
                type="text"
                class="form-control form-control-border border-width-2"
                name="anoAlteracao"
                id="transferencia-anoAlteracao"
                data-cy="anoAlteracao"
                :class="{
                  'is-invalid':
                    isSaving && $v.feriasTransferencia.anoAlteracao.$error,
                }"
                v-model="feriasTransferencia.anoAlteracao"
              />
            </div>
            <div class="form-group col-lg-3">
              <label for="transferencia-mesAlteracao">Mês Alterado</label>
              <v-select
                class="border-v-select mt-1"
                id="transferencia-mesAlteracao"
                v-model="feriasTransferencia.mesAlteracao"
                :options="mesAlteracao"
                :reduce="(mesAlteracao) => mesAlteracao.id"
                label="descricao"
              >
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </div>
  
  
                  <div class="form-group col-lg-3 ">
                      <label class="form-control-label" for="transferencia-dataInicioAlteracao"
                      >Novo início</label>
                          <div class="d-flex">
                              <input
                                  type="date" data-cy="dataInicioAlteracao" class="form-control form-control-border border-width-2" id="transferencia-dataInicioAlteracao"
                                  placeholder="dd/mm/aaaa"  v-model="feriasTransferencia.dataInicioAlteracao"
                                  :class="{
                                  'is-invalid': isSaving && $v.feriasTransferencia.dataInicioAlteracao.$error}"
                              />
                          </div>
                  </div>
  
                      <div class="form-group col-lg-3">
                          <label class="form-control-label" for="transferencia-dataFimAlteracao"
                          >Novo fim
                          </label >
                          <div class="d-flex">
                              <input
                                  type="date" data-cy="dataFimAlteracao" class="form-control form-control-border border-width-2" id="transferencia-dataFimAlteracao"
                                  placeholder="dd/mm/aaaa" v-model="feriasTransferencia.dataFimAlteracao" :class="{
                                  'is-invalid': isSaving && $v.feriasTransferencia.dataFimAlteracao.$error}"
                                  
                              />
                          </div>
                      </div>
                  </div>
           
                  <div class="row">
                  
                      <div class="col-sm-6 ">
                          <label for="transferencia-portariaNumero">Número Portaria</label>
                          <input type="text" class="form-control form-control-border border-width-2" id="transferencia-portariaNumero"
                          v-model="feriasTransferencia.portariaNumero" />
                      </div>
                  
  
            <div class="col-sm-6">
              <div class="form-group">
                <label for="transferencia-portariaEmissor">Emissor</label>
                <input
                  type="text"
                  class="form-control form-control-border border-width-2"
                  id="transferencia-portariaEmissor"
                  v-model="feriasTransferencia.portariaEmissor"
                />
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="transferencia-portariaCorpo">Corpo</label>
                <textarea
                  style="resize: none"
                  type="text"
                  rows="3"
                  class="form-control"
                  id="transferencia-portariaCorpo"
                  v-model="feriasTransferencia.portariaCorpo"
                ></textarea>
              </div>
            </div>
  
          </div>
  
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="transferencia-supervidorAssinatura"
                  >Supervisor(a)</label
                >
                <v-select class="border-v-select mt-1" id="supervisor" v-model="feriasTransferencia.supervidorAssinatura"
                      :options="listaSupervisor" :reduce="(listaSupervisor) => listaSupervisor.id" label="nome">
                      <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
                
              </div>
            </div>
          </div>
        </div>
         
        <template #modal-footer="{ ok, cancel }">
          <button
            @click="[EditarTransferencia(), ok()]"
            class="btn text-color-principal background-principal ml-1"
          >
            <i class="far fa-envelope"></i> Editar
          </button>
          
          <button
            @click.prevent="LimparTransferencia"
            type="reset"
            class="btn btn-default"
          >
            <i class="fas fa-ban"></i> Limpar
          </button>
  
          <b-button
            class="btn btn-default float-right"
            @click="[cancel(), apagarCamposTransferencia()]"
          >
            <i class="fa-solid fa-x"></i> Fechar
          </b-button>
        </template> 
      </b-modal> 
    </div>
  </template>
  
  <script>
  import { EventBus } from "@/main";
  import ConsultaFerias from "@/components/rh/ferias/ConsultaFerias.vue";
  import rhService from "@/service/RHService";
  
  export default {
    components: {
      ConsultaFerias,
    },
  
    data() {
      return {
        data: {},
        feriasTransferencia: {},
        idTransferencia:{},
        listaSupervisor: {},
        mesAlteracao: [
          { id: 1, descricao: "Janeiro" },
          { id: 2, descricao: "Fevereiro" },
          { id: 3, descricao: "Março" },
          { id: 4, descricao: "Abril" },
          { id: 5, descricao: "Maio" },
          { id: 6, descricao: "Junho" },
          { id: 7, descricao: "Julho" },
          { id: 8, descricao: "Agosto" },
          { id: 9, descricao: "Setembro" },
          { id: 10, descricao: "Outubro" },
          { id: 11, descricao: "Novembro" },
          { id: 12, descricao: "Dezembro" },
        ],
        submitted: false,
        isSaving: false,
      };
    },
  
  
    created() {

        EventBus.$on("EnvioEditarTransferencia",(listener)=>{
        
        this.feriasTransferencia = listener;
        console.log("idTransferencia" + listener)
        
    });
        this.buscarTransferencia();
        this.buscaSupervisor();
     
    },
  
    methods: {
        LimparTransferencia() {
        this.feriasTransferencia = {};
      },
  
      apagarCamposTransferencia() {
        this.feriasTransferencia = {};
      },

      buscarTransferencia(id) {

        rhService.buscarTransferenciaporFerias(id).then(res =>{
            this.feriasTransferencia = res.data;
        })
      },


      EditarTransferencia(){

        
        rhService.editarTransferencia(this.feriasTransferencia).then(resp=>{
                this.feriasTransferencia = resp.data;
                this.feriasTransferencia={};
                console.log("editar " + this.feriasTransferencia)
                Toast.fire("Edição realizada", "", "success");
                EventBus.$emit("AtualizarHistoricoTransferencia");
              
            }).catch(()=>{
                Toast.fire("Falha ao editar transferência", "", "error");
            });


      },
  
      buscaSupervisor() {
          rhService
          .buscaSupervisors()
          .then((response) => {
              this.listaSupervisor = response.data;
          });
      },
  
  
    },
  };
  </script>