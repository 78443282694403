<template>
  <div class="card card-primary card-tabs">
    <div class="card-header p-0 pt-1 background-principal">
      <ul class="nav nav-tabs" role="tablist" id="ul-menu-documento">
        <li class="nav-item menu">
          <a
            class="nav-link item-menu EN active"
            data-toggle="pill"
            @click="novaAcao('EN')"
            role="tab"
            aria-controls="custom-tabs-five-overlay"
            aria-selected="true"
            >Dados Básicos</a
          >
        </li>
        <li class="nav-item menu">
          <a
            class="nav-link item-menu EV"
            data-toggle="pill"
            @click="novaAcao('EV', $event)"
            role="tab"
            aria-controls="custom-tabs-five-overlay-dark"
            aria-selected="false"
            >Foto</a
          >
        </li>
        <li class="nav-item menu">
          <a
            class="nav-link item-menu RS"
            data-toggle="pill"
            @click="novaAcao('RS', $event)"
            role="tab"
            aria-controls="custom-tabs-five-normal"
            aria-selected="false"
            >Assinatura</a
          >
        </li>
        <li class="nav-item menu">
          <a
            class="nav-link item-menu CP"
            data-toggle="pill"
            @click="novaAcao('CP', $event)"
            role="tab"
            aria-controls="custom-tabs-five-normal"
            aria-selected="false"
            >Anomalia</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/main.js";

export default {
  name: "MenuDocumento",
  data() {
    return {
      page: []
    };
  },
  methods: {
    novaAcao(acao) {
      EventBus.$emit("alteraAcaoConteudo", acao);
    },
    mudarPagina(pagina) {
      EventBus.$emit("mudarPagina", pagina);
    },
    ativarAbas(aba) {
      let lista = document.getElementsByClassName("item-menu");
      for (let item of lista) {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        }
        if (item.classList.contains(aba)) {
          item.classList.add("active");
        }
      }
    },
  },
  created() {
    EventBus.$on("paginacao", (page) => {
      this.page = page;
    });
    EventBus.$on("abaAtiva", (aba) => {
      // this.ativo = aba;
      this.ativarAbas(aba);
    });
  },
};
</script>

<style>
.menu {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
}
</style>