<template>
  <div>
    <h4 for="editarUnidadeSubordinada"><b>ENDEREÇO DA UNIDADE:</b></h4>
    <form class="row">
      <div class="form-group col-lg-3 col-sm-12">
        <label for="cep">CEP</label>
        <input type="text" class="form-control form-control-border border-width-2" id="cep" placeholder="xxxxx-xxx"
          v-model="endereco.cep" v-mask="'#####-###'" :class="{ 'is-invalid': $v.endereco.cep.$error, }" />
        <div v-if="!$v.endereco.cep.required" class="invalid-feedback">Este campo é obrigatório</div>
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="pais">País</label>
        <v-select class="border-v-select" id="pais" v-model="endereco.pais"
          :options="paises" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>

      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="estado">Estado</label>
        <v-select class="border-v-select" :class="{
          'disabled': (endereco.pais == ''
            || endereco.pais == null)
        }" id="estado" v-model="endereco.estado" :options="estados" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="cidade">Cidade</label>
        <v-select class="border-v-select" :class="{
          'disabled': (endereco.estado == ''
            || endereco.estado == null)
        }" id="cidade" v-model="endereco.cidade" :options="cidades" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="bairro">Bairro</label>
        <v-select 
          class="border-v-select"
          :class="{ 'disabled': (endereco.cidade == '' || endereco.cidade == null) }" id="bairro"
          v-model="endereco.bairro" :options="bairros" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="logradouro">Logradouro</label>
        <input type="text" class="form-control form-control-border border-width-2" id="logradouro" placeholder=""
          v-model="endereco.logradouro" :class="{ 'is-invalid': $v.endereco.logradouro.$error, }" />
        <div v-if="!$v.endereco.logradouro.required" class="invalid-feedback">Este campo é obrigatório</div>
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="numero">Número</label>
        <input type="text" class="form-control form-control-border border-width-2" id="numero" placeholder=""
          v-model="endereco.numero" />
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="complemento">Complemento</label>
        <input type="text" class="form-control form-control-border border-width-2" id="complemento"
          v-model="endereco.complemento" placeholder="" />
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="referencia">Referência</label>
        <input type="text" class="form-control form-control-border border-width-2" id="referencia" placeholder=""
          v-model="endereco.referencia" required />
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="endAtual">Endereço Atual</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="true" id="endAtual" v-model="endereco.atual" checked />
          <label class="form-check-label" for="endAtual"> Sim
          </label>

        </div>
      </div>

      <div class="form-group col-12">
        <button class="btn background-principal text-color-principal"
          @click.prevent="$emit('FecharAdicaoDados'), $emit('voltarParaUnidadeDetalhe')">
          <i class="fas fa-arrow-left"></i>
          Voltar
        </button>
        <button class="btn btn-primary float-right" @click.prevent="salvarEnderecoUnidade">
          <i class="fa-solid fa-floppy-disk"></i>
          Salvar
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { EventBus } from '@/main';
import ConsultaListasService from '@/service/ConsultaListasService';
import UnidadeService from '@/service/UnidadeService';
import { required } from "vuelidate/lib/validators";

export default {
  name: 'FormCadastroEnderecoUnidadeSubordinada',
  data() {
    return {
      idUnidade: '',
      endereco: {
        pais: '',
        estado: '',
        cidade: "",
        estado: "",
      },
      paises: [],
      estados: [],
      cidades: [],
      bairros: [],
    }
  },
  created() {
    EventBus.$on('ResultadoIdUnidadeCriada', (event) => {
      this.idUnidade = event
    });
    EventBus.$on('unidadeSelecionadaAdicaoEndereco', (event) => {
      this.idUnidade = event
    })
    ConsultaListasService.buscarListaPorNomeTabela('pais')
      .then((response) => {
        this.paises = response.data;
        this.buscandoPais("Brasil");
      })
      .catch((e) => this.alertaFalhaBuscarPais());

    ConsultaListasService.buscarListaPorNomeTabela('estado')
      .then((response) => {
        this.estados = response.data;
        this.buscandoEstado("Maranhão");
      })
      .catch((e) => this.alertaFalhaBuscarEstado());

    EventBus.$on('resetarFormularioUnidade', (event) => { this.resetarForm() })

  },
  watch: {
    "endereco.estado"() {
      if (this.endereco.estado !== null && this.endereco.estado !== '') {
        this.pesquisarCidades(this.endereco.estado.id);
      }

    },
    "endereco.cidade"() {
      if (this.endereco.cidade !== null && this.endereco.cidade !== '') {
        this.pesquisarBairros(this.endereco.cidade.id);

      }
    },
  },
  methods: {
    resetarForm() {
      this.endereco = {
        pais: this.paises.find(x => x.descricao === 'Brasil'),
        estado: this.estados.find(x => x.descricao === "Maranhão"),
        cidade: "",
        estado: "",
      }
    },
    alertaSucessoCadastroEndereco() {
      Toast.fire("Sucesso ao cadastrar endereço da unidade", "", "success");
    },
    alertaFalhaCadastroEndereco() {
      Toast.fire("Falha ao cadastrar endereço da  unidade", "", "error");
    },
    alertaFalhaBuscarPais() {
      Toast.fire("Falha ao buscar país", "", "error");
    },
    alertaFalhaBuscarEstado() {
      Toast.fire("Falha ao buscar estado", "", "error");
    },
    salvarEnderecoUnidade() {
      this.$v.endereco.$touch();
      if (!this.$v.endereco.$invalid) {
        const data = {
          idUnidade: this.idUnidade,
          cep: this.endereco.cep,
          pais: (this.endereco.pais !== '' ? this.endereco.pais : null),
          estado: (this.endereco.estado !== '' ? this.endereco.estado : null),
          cidade: (this.endereco.cidade !== '' ? this.endereco.cidade : null),
          bairro: (this.endereco.bairro !== '' ? this.endereco.bairro : null),
          logradouro: this.endereco.logradouro,
          numero: this.endereco.numero,
          complemento: this.endereco.complemento,
          referencia: this.endereco.referencia,
          atual: (this.endereco.atual == 'true' ? true : false),
        }

        UnidadeService.cadastrarUnidadeEndereco(data).then((response) => {
          this.alertaSucessoCadastroEndereco();
          this.$emit('esconderFormEndereco')
          this.endereco = {
            pais: "",
            estado: "",
            cidade: "",
            estado: "",
          }
          this.$emit('FecharAdicaoDados')
        }).catch((e) => {
          this.alertaFalhaCadastroEndereco();
        })
      }

    },
    buscandoPais(descricaoPais) {
      this.endereco.pais = this.paises.find(x => x.descricao === descricaoPais)
    },
    buscandoEstado(descricaoEstado) {
      this.endereco.estado = this.estados.find(x => x.descricao === descricaoEstado)
    },
    pesquisarCidades(idEstado) {
      if (idEstado) {
        ConsultaListasService.buscarCidadesPorIdEstado(idEstado)
          .then((response) => {
            this.cidades = response.data;
          })
          .catch((e) => alert("ERRO: " + e.message));
      }
    },
    pesquisarBairros(idCidade) {
      if (idCidade) {
        ConsultaListasService.buscarBairrosPorIdCidade(idCidade)
          .then((response) => {
            this.bairros = response.data;
          })
          .catch((e) => alert("ERRO: " + e.message));
      }
    }
  },
  validations: {
    endereco: {
      cep: {
        required,
      },
      logradouro: {
        required
      }
    },
  }
}
</script>
