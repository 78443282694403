<template>
    <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
        <content-header title="Configurações" />
        <conteudo v-role="'RECURSOS_HUMANOS'">
            <TabsConfiguracao/>
        </conteudo>
    </section>
</template>

<script>
import TabsConfiguracao from '@/components/rh/configuracao/TabsConfiguracao.vue';

export default {
    components: {
        TabsConfiguracao
    },
    data(){
        return{
            isModalVisible: false,
        };
    },
    methods:{

    },
}
</script>
