import { render, staticRenderFns } from "./TabsCadastoUsuario.vue?vue&type=template&id=5a4c3936&scoped=true&"
import script from "./TabsCadastoUsuario.vue?vue&type=script&lang=js&"
export * from "./TabsCadastoUsuario.vue?vue&type=script&lang=js&"
import style0 from "./TabsCadastoUsuario.vue?vue&type=style&index=0&id=5a4c3936&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a4c3936",
  null
  
)

export default component.exports