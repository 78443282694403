<template>
  <form>
    <div class="card-body">
      <div class="form-group">
        <label for="cpf">CPF</label>
        <input
          type="text"
          class="form-control form-control-border border-width-2"
          id="buscarCPFConsulta"
          placeholder="xxx.xxx.xxx-xx"
          v-model="cpf"
          v-mask="'###.###.###-##'"
        />
      </div>
      <!-- DADOS BÁSICOS -->
      <div class="form-group">
        <label for="nome">Nome</label>
        <input
          type="email"
          class="form-control form-control-border border-width-2"
          id="nome"
          placeholder="Buscar por nome..."
          v-model="nome"
        />
      </div>

      <div class="form-group">
        <label for="nomemae">Nome da mãe (exato)</label>
        <input
          type="text"
          class="form-control form-control-border border-width-2"
          id="nomemae"
          placeholder="Nome da mãe"
          v-model="nomemae"
        />
      </div>
      <div class="form-group">
        <label for="idademax">Faixa Etária</label>
        <div class="d-flex">
          <input
            min="1"
            max="130"
            type="number"
            class="form-control form-control-border border-width-2 mr-auto col-5"
            id="idademin"
            placeholder="Idade mínima"
            v-model="idademin"
          />

          <input
            min="1"
            max="130"
            type="number"
            class="form-control form-control-border border-width-2 col-5 ml-auto"
            id="idademax"
            placeholder="Idade máxima"
            v-model="idademax"
          />
        </div>
      </div>

      <!-- TELEFONES -->
      <div class="form-group">
        <label for="telefonecelular">Telefone Celular</label>
        <div class="d-flex">
          <input
            type="tel"
            class="form-control form-control-border border-width-2 col-3"
            id="telefonecelularddd"
            placeholder="DDD"
            v-model="dddtelefonecelular"
            v-mask="'(##)'"
          />
          <input
            type="tel"
            class="form-control form-control-border border-width-2 col-8 ml-auto"
            id="telefonecelular"
            placeholder="Digite o número de telefone"
            v-model="telefonecelular"
            v-mask="'#####-####'"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="telefonefixo">Telefone Fixo</label>
        <div class="d-flex">
          <input
            type="text"
            class="form-control form-control-border border-width-2 col-3"
            id="telefonefixoddd"
            placeholder="DDD"
            v-model="dddtelefonefixo"
            v-mask="'(##)'"
          />
          <input
            type="text"
            class="form-control form-control-border border-width-2 col-8 ml-auto"
            id="telefonefixo"
            placeholder="Digite o número de telefone"
            v-model="telefonefixo"
            v-mask="'####-####'"
          />
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button
        @click.prevent="emitirConsulta"
        v-on:keyup.enter="emitirConsulta"
        type="submit"
        class="btn col-12 background-principal text-color-principal"
      >
        <i class="fas fa-search"></i>
        Buscar
      </button>
    </div>
  </form>
</template>


<script>
import { EventBus } from "../../../main.js";
import LocalIpService from "@/service/LocalIpService";

export default {
  name: "FormConsultaPessoa",

  data() {
    return {
      tituloBusca: "Buscar pessoa",
      tipoConsultaNacional: "",
      nome: "",
      cpf: "",
      nomemae: "",
      idademin: "",
      idademax: "",
      total: "",
      dddtelefonecelular: "",
      telefonecelular: "",
      dddtelefonefixo: "",
      telefonefixo: "",
      page: 1,
      buscarTotal: false,
      //ipUsuario: null,
    };
  },
  created() {
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
  methods: {
    mostrarAlerta() {
      Toast.fire("Preencha ao menos um campo!!", "", "error");
    },
    formValido() {
      if (
        this.nome ||
        this.cpf ||
        this.nomemae ||
        this.idademin ||
        this.idademax ||
        this.dddtelefonecelular ||
        this.telefonefixo ||
        this.dddtelefonefixo ||
        this.telefonecelular
      ) {
        return true;
      }

      return false;
    },
    emitirConsulta() {
      if (this.formValido()) {
        const params = {
          nome: this.nome.toUpperCase(),
          cpf: this.cpf,
          nomeMae: this.nomemae,
          idadeMinima: this.idademin,
          idadeMaxima: this.idademax,
          dddCelular: this.dddtelefonecelular,
          telefoneCelular: this.telefonecelular,
          dddFixo: this.dddtelefonefixo,
          telefoneFixo: this.telefonefixo,
          page: this.page,
          buscarTotal: this.buscarTotal,
          //ipUsuario: this.ipUsuario,
        };
        EventBus.$emit("consultapessoa", params); // emitir evento para o event-bus
      } else {
        this.mostrarAlerta();
      }
    },
  },
};
</script>

<style>
</style>
