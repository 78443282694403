<template>
  <div class="card card-primary">
    
    <div class="background-principal card-header text-color-principal">
      <h3 class="card-title">Resultado da Busca de Veículos Nacional</h3>
    </div>
    <div
      v-if="resultados.length == 0 && semResultados"
      class="mx-auto my-auto sem-resultados py-2"
    >
      <div>Sem resultados!</div>
    </div>
    <loading :active="loaderActive" :is-full-page="fullPage" />
    <div class="table-responsive max-height-content">
      
      <div class="ml-5 row">
        <div
          @click.prevent="showModalDetalheNacional"
          @click="clicandoCardNacional(veiculo.id)"
          :key="id"
          v-for="(veiculo, id) in resultados"
          class="card card-item bg-light col-sm-11 col-md-11 col-lg-5"
        >
          <a href="#">
            <div>
              <div>
                <div>
                  <p
                    class="header card-item-result background-principal text-color-principal"
                  >
                    {{ veiculo.placa }}
                  </p>
                </div>
                <div class="row">
                  <div class="col-12 mx-0 my-2 py-0">
                    <div class="col-sm-12 col-md-12">
                      <ul class="ml-4 mb-0 fa-ul text-muted">
                        <li class="medium">
                          <b>Proprietário: </b>
                          {{ veiculo.proprietario.nomeProprietario }}
                        </li>
                        <li class="medium">
                          <b>Marca/Modelo: </b> {{ veiculo.marcaModelo }}
                        </li>
                        <li class="medium"><b>Cor: </b> {{ veiculo.cor }}</li>
                        <li class="medium">
                          <b>Chassi: </b> {{ veiculo.chassi }}
                        </li>
                        <li class="medium">
                          <b>Renavam: </b> {{ veiculo.renavam }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { EventBus } from "../../../main.js";
import ConsultaService from "../../../service/ConsultaService";
import { mapState } from "vuex";
import Loader from '../../Loader.vue'

export default {
  name: "ResultadosVeiculosConsultaNacional",
  components: {
    Loading,
    Loader
  },
  data() {
    return {
      semResultados: false,
      resultados: [],
      loaderActive: false,
      fullPage: false,
      total: "",
      params: {},
    };
  },
  computed: {
    ...mapState(["auth"]),
  },
  created() {
    EventBus.$on("consultaVeiculoNacional", this.listener);
  },
  beforeDestroy() {
    EventBus.$off("consultaVeiculoNacional", this.listener);
  },

  filters: {
    abreviar: function (nomeCompleto) {
      if (nomeCompleto !== null && nomeCompleto !== {}) {
        const nomeExists = nomeCompleto.nomeProprietario;
        const nomeFormatado = nomeExists.split(" ");
        return (
          nomeFormatado[0] + " ... " + nomeFormatado[nomeFormatado.length - 1]
        );
      } else {
        return "Não Cadastrado";
      }
    },
    formatarChassi: function (chassi) {
      if (chassi !== null) {
        const chassiFormatado = chassi.substring(0, 5);
        return chassiFormatado + "************";
      } else {
        return "Não Cadastrado";
      }
    },
    formatarRenavam: function (renavam) {
      if (renavam !== null) {
        const renavamFormatada = renavam.substring(0, 4);
        return renavamFormatada + "*******";
      } else {
        return "Não Cadastrado";
      }
    },
  },
  methods: {
    listener(event) {
      this.params = event;
      this.consultarVeiculoNacional();
    },
    onChangePage(resultados) {
      this.resultados = resultados;
    },
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
      this.fullPage = true;
    },
    hideLoader() {
      this.loaderActive = false;
      this.fullPage = false;
    },
    consultarVeiculoNacional() {
      this.showLoader();
      this.pesquisou = false;
      try {
        let login = this.auth.user.usuario.login;

        ConsultaService.buscarVeiculosNacional(this.params)
          .then((response) => {
            this.resultados = [response.data];
            this.total = this.resultados.length;
            this.hideLoader();
          })
          .catch((e) => {
            this.hideLoader();
            this.semResultados = true;
          });
      } catch (e) {
        this.semResultados = true;
        this.hideLoader();
        alert(e);
      } finally {
        this.mostrarLoader = false;
        this.semResultados = false;
        // this.hideLoader();
      }
      // this.hideLoader();
    },
    clicandoCardNacional(id) {
      const veiculoSelecionado = this.resultados.find((x) => x.id === id);
      EventBus.$emit("consultaVeiculoDetalheNacional", veiculoSelecionado);
    },
    showModalDetalheNacional() {
      this.$emit("showModalVeiculo");
    },
  },
};
</script>