<template>
  <component :is="componente" ref="conteudo-documento" :text="id" />
</template>

<script>
import { EventBus } from "@/main.js";
import EN from "@/components/documentos/ResultadoConsultaDocumentoEntradas.vue";
import EV from "@/components/documentos/ResultadoConsultaDocumentoEnviados.vue";
import RS from "@/components/documentos/ResultadoConsultaDocumentoRascunhos.vue";
import AR from "@/components/documentos/ResultadoConsultaDocumentoArquivados.vue";
import CP from "@/components/documentos/ResultadoConsultaDocumentoCompartilhados.vue";
import{
  default as ND,
  default as ED,
} from "@/components/documentos/NovoDocumento.vue";
import VD from "@/components/documentos/ResultadoVisualizacao.vue";
import NP from "@/components/documentos/NovoProtocolo.vue"
export default {
  name: "Documentos",
  components: { EN, EV, RS, AR, CP, ND, ED, VD, NP },
  data() {
    return {
      componente: "",
      id: "",
      documentoPesquisa: [],
      teste: ""
    };
  },
  created() {

    this.componente = "EN";

    EventBus.$on("alteraAcaoConteudo", (acao) => {
      this.carregaConteudo(acao);
    });

    EventBus.$on("enviarPdf", (id) => {
      this.visualizar(id);
    });

    EventBus.$on("editarDocumento", (id) => {
      this.editarDocumento(id);
    });

    EventBus.$on("mudarPagina", (page) => {
      this.mudarPaginaTeste(page);
    });
    EventBus.$on("filtrarDocumento", (documento) => {

      this.pesquisar(documento);
      // console.log(this.componente);
      // this.teste = "oi";
    });
  },
  updated(){
    // console.log(this.teste);
    // console.log("sidfhgiasudfgh");
  },
  props: {
    text: {
      type: String,
      default: null,
    }
  },
  mounted() {
    // this.componente = this.acao;
  },
  methods: {
    visualizar(id) {
      this.id = id.toString();
    },
    editarDocumento(id) {
      this.id = id.toString();
    },
    carregaConteudo(acao){
      this.componente = null;
      this.componente = acao;
      if (acao == 'ND'){
        this.id = 'novo';
      }
    },
    mudarPaginaTeste(page){

      this.$refs["conteudo-documento"].getDocumentos(page);

    },
    pesquisar(documento){

      switch (this.componente) {
        case 'EN':
          EventBus.$emit("filtrarDocumentoRecebidos", documento);
          break;
        case 'EV':
          EventBus.$emit("filtrarDocumentoEnviados", documento);
          break;
        case 'RS':
          EventBus.$emit("filtrarDocumentoRascunhos", documento);
          break;
        case 'AR':
          EventBus.$emit("filtrarDocumentoArquivados", documento);
          break;
        case 'CP':
          EventBus.$emit("filtrarDocumentoCompartilhados", documento);
          break;
      }
    }
  },
};
</script>