<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-idContratacao">Id Contratação</label>
          <input type="text" class="form-control form-control-border" name="idContratacao" id="sancao-idContratacao"
            data-cy="idContratacao" :class="{
              'is-invalid': isSaving && $v.sancao.idContratacao.$error,
            }" v-model="sancao.idContratacao" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-idContrato">Id Contrato</label>
          <input type="text" class="form-control form-control-border" name="idContrato" id="sancao-idContrato"
            data-cy="idContrato" :class="{
              'is-invalid': isSaving && $v.sancao.idContrato.$error,
            }" v-model="sancao.idContrato" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-cpfCnpj">Cpf Cnpj</label>
          <input type="text" class="form-control form-control-border" name="cpfCnpj" id="sancao-cpfCnpj"
            data-cy="cpfCnpj" :class="{
              'is-invalid': isSaving && $v.sancao.cpfCnpj.$error,
            }" v-model="sancao.cpfCnpj" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-tipo">Tipo</label>
          <v-select class="border-v-select" id="sancao-tipo" :class="{
            'is-invalid': isSaving && $v.sancao.tipo.$error,
          }" data-cy="tipo" name="tipo"  v-model="sancao.tipo" :options="tipo" :reduce="tipo => tipo.id"
            label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-numeroLei">Número Lei</label>
          <input type="number" class="form-control form-control-border" name="numeroLei" id="sancao-numeroLei"
            data-cy="numeroLei" :class="{
              'is-invalid': isSaving && $v.sancao.numeroLei.$error,
            }" v-model.number="sancao.numeroLei" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-anoLei">Ano Lei</label>
          <input type="number" class="form-control form-control-border" name="anoLei" id="sancao-anoLei"
            data-cy="anoLei" :class="{
              'is-invalid': isSaving && $v.sancao.anoLei.$error,
            }" v-model.number="sancao.anoLei" />
        </div>
      </div>
    </div>
    <div class="row">
      <!--div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-dataNotificacao">Data Notificação</label>
          <div class="d-flex">
            <input id="sancao-dataNotificacao" data-cy="dataNotificacao" type="datetime-local"
              class="form-control form-control-border" name="dataNotificacao" :class="{
                'is-invalid': isSaving && $v.sancao.dataNotificacao.$error,
              }" :value="
               convertDateTimeFromServer(sancao.dataNotificacao)
              " @change="updateZonedDateTimeField('dataNotificacao', $event)" />
          </div>
        </div>
      </div-->

      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="sancao-dataNotificacao"
              >Data Notificação</label
              >
            <div class="d-flex">
              <input
                type="date" class="form-control form-control-border border-width-2" id=sancao-dataNotificacao
                  placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(sancao.dataNotificacao)" :class="{
                    'is-invalid': isSaving && $v.sancao.dataNotificacao.$error,
                      }"
                  @change="updateZonedDateTimeField('dataNotificacao', $event)"
              />
            </div>
        </div>
      </div>



      <!--div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-dataInicio">Data Início</label>
          <div class="d-flex">
            <input id="sancao-dataInicio" data-cy="dataInicio" type="datetime-local"
              class="form-control form-control-border" name="dataInicio" :class="{
                'is-invalid': isSaving && $v.sancao.dataInicio.$error,
              }" :value="convertDateTimeFromServer(sancao.dataInicio)"
              @change="updateZonedDateTimeField('dataInicio', $event)" />
          </div>
        </div>
      </div-->

      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="sancao-dataInicio"
              >Data Inicio</label
              >
            <div class="d-flex">
              <input
                type="date" class="form-control form-control-border border-width-2" id="sancao-dataInicio"
                  placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(sancao.dataInicio)" :class="{
                    'is-invalid': isSaving && $v.sancao.dataInicio.$error,
                      }"
                  @change="updateZonedDateTimeField('dataInicio', $event)"
              />
            </div>
        </div>
      </div>

      <!--div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-dataFim">Data Fim</label>
          <div class="d-flex">
            <input id="sancao-dataFim" data-cy="dataFim" type="datetime-local" class="form-control form-control-border"
              name="dataFim" :class="{
                'is-invalid': isSaving && $v.sancao.dataFim.$error,
              }" :value="convertDateTimeFromServer(sancao.dataFim)"
              @change="updateZonedDateTimeField('dataFim', $event)" />
          </div>
        </div>
      </div>
    </div-->

    <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="sancao-dataFim"
              >Data Fim</label
              >
            <div class="d-flex">
              <input
                type="date" class="form-control form-control-border border-width-2" id="sancao-dataFim"
                  placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(sancao.dataFim)" :class="{
                    'is-invalid': isSaving && $v.sancao.dataFim.$error,
                      }"
                  @change="updateZonedDateTimeField('dataFim', $event)"
              />
            </div>
        </div>
    </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="sancao-amplitude">Amplitude</label>
          <v-select class="border-v-select" id="sancao-amplitude" :class="{
            'is-invalid': isSaving && $v.sancao.amplitude.$error,
          }" data-cy="amplitude" name="amplitude" v-model="sancao.amplitude" :options="amplitude"
            :reduce="amplitude => amplitude.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-right">
        <!--button class="btn btn-default mr-1">
          <i class="fas fa-pencil-alt"></i> Rascunho
        </button-->
        <button @click="salvarSancao" class="btn text-color-principal background-principal ml-1">
          <i class="far fa-envelope"></i> Enviar
        </button>
         <button @click="limparSancao" type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Limpar
      </button>
      </div>
      <!--button type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Cancelar
      </button-->
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import { DATE_TIME_LONG_FORMAT, DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import dayjs from "dayjs";
import { EventBus } from "@/main";
import processosService from '@/service/ProcessosService';
import TabelaSancao from "@/components/processos/TabelaSancao";


export default {
  name: "Ata-Registro",
  components: {
    TabelaSancao,},
  data() {
    return {
      data:{},
      sancao: {},
      submitted: false,
      isSaving: false,
      setores: [],
      funcionarios: [],
      tipo: [
        { id: 1, descricao: "Advertência" },
        { id: 2, descricao: "Multa" },
        { id: 3, descricao: "Suspensão Temporária" },
        { id: 4, descricao: "Declaração de Inidoneidade" },
        { id: 5, descricao: "Impedimento de Licitar/Contratar" }
      ],
      amplitude: [
        { id: 1, descricao: "Municipal" },
        { id: 2, descricao: "Estadual" },
        { id: 3, descricao: "Federal" },
        { id: 4, descricao: "Nacional(todos)" }
      ]
    };
  },
  
  validations: {
    sancao: {
      idContratacao: {},
      idContrato: {},
      cpfCnpj: {},
      tipo: {},
      numeroLei: {},
      anoLei: {},
      dataNotificacao: {},
      dataInicio: {},
      dataFim: {},
      amplitude: {},
      //amplitude: { required },
    },
  },

  created() {
      EventBus.$on("EnvioSancao",(listener)=>{
        this.getSancao(listener)
        
      }),

      EventBus.$on("ApagarCamposSancao",()=>{
        this.sancao = {};
      })
    },

  methods: {
    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

    limparSancao(){
      this.sancao = {};
      document.getElementById("sancao-dataInicio").value = "";
      document.getElementById("sancao-dataFim").value = "";
      document.getElementById("sancao-dataNotificacao").value = "";

    },

    getSancao(id){
      processosService.getSancao(id).then((resp)=>{
        this.sancao= resp.data;
      })
    },

    salvarSancao(){
    console.log("SALVAR SANCAO")
    this.isSaving = true;
    this.$v.$touch();
 
      if (this.$refs["sancao"]!= undefined){
        this.sancao = Object.assign(
          {},
          this.sancao,
          this.$refs["sancao"].sancao
        );
 
        this.$refs["sancao"].isSaving = this.isSaving;
        if (this.$v.$ivalid || this.$refs["sancao"].$v.$ivalid){
          return;
        } else{
          if (this.$v.$ivalid){
            return;
 

 
          }
        }
 
      }
      processosService.criarSancao(this.sancao)
      .then((response)=> {
        this.sancao= response.data;
        Toast.fire("Cadastrado realizado", "", "success");
        this.sancao={};
        document.getElementById("sancao-dataInicio").value = "";
        document.getElementById("sancao-dataFim").value = "";
        document.getElementById("sancao-dataNotificacao").value = "";
 
      })
    },

    

    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.sancao[field] = dayjs(
          event.target.value,
          DATE_FORMAT_LOCAL_DATA
        );
      } else {
        this.sancao[field] = null;
      }
    },
  },
  // mounted() {
  //   this.carregaSetores();
  //   this.carregaFuncionario();
  // }
};
</script>
