<template>
  <!-- <div v-if="anotacoes"  v-show="showModal" class="modal fade show h-100 overflow-auto" id="modal-dialog modal-xl" style="display: block"
    aria-modal="true" role="dialog" >
    <div class="modal-dialog modal-xl p-3" @click.stop> -->
  <div>
    <b-modal
      id="ModalDetalhesConsultaAnotacao"
      ref="modal-12"
      title="Anotação Detalhada"
      data-backdrop="static"
      size="lg"
      cancel-disabled
      no-close-on-backdrop
      hide-header-close
      header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            "
      content-class="modal-content border-rounded-detail"
      dialog-class="p-3"
      title-class="modal-title mx-auto xl"
    >
      <div class="card-body">
          <div class="row">
            <div class="col-12 d-flex flex-column">
              <h5>
                <b>Nome: </b
                >{{
                  anotacoes.funcionario != null
                    ? anotacoes.funcionario.pessoaCorrespondente.nome
                    : null
                }}
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex flex-column">
              <span>
                <b>Matrícula: </b
                >{{
                  anotacoes.funcionario == null ? "Sem Registro" : anotacoes.funcionario != null ? anotacoes.funcionario.matricula : "Sem Registro"
                }}
              </span>
            </div>
            <div class="col-6 d-flex flex-column">
              <span>
                <b>Tipo de Anotação: </b
                >{{
                  anotacoes.tipoAnotacao != null ? anotacoes.tipoAnotacao.descricao : "Sem Registro"
                }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex flex-column">
              <span>
                <b>Nº do Documento: </b
                >{{ anotacoes.numeroDocumento != null ? anotacoes.numeroDocumento : 'Sem Registro' }}
              </span>
            </div>
            <div class="col-6 d-flex flex-column">
              <span>
                <b>Documento: </b>
                {{
                  anotacoes.tipoDocumento != null ? anotacoes.tipoDocumento.descricao : "Sem Registro"
                }}
              </span>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-12 d-flex flex-column">
              <b>Informação: </b>
              <textarea rows="6" style="resize: none;background-color: white;" type="text" name="observacao1" class="form-control" disabled>
                  {{ anotacoes.obs1 != null ? anotacoes.obs1 : null }}
                </textarea>
                
            </div>
          </div>
      </div>
      <template #modal-footer="{ cancel }">
        <button
          v-b-modal.modal-20
          @click="[enviarDadosAnotacao(anotacoes), cancel()]"
          type="button"
          class="btn btn-success ml-1"
        >
          <i class="fa-solid fa-user-plus"></i> Editar
        </button>

        <b-button class="btn btn-default float-right" @click="[cancel()]">
          <i class="fas fa-times"></i> Fechar
        </b-button>

        <b-button class="btn btn-default float-right" @click="[suspenderLicenca(anotacoes), cancel()]">
          <i class="fas fa-times"></i> Suspender
        </b-button>

      </template>
    </b-modal>
  </div>

  <!-- </div>
  </div> -->
</template>

<script>
import { EventBus } from "../../../main.js";
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
// import Loader from "../../Loader.vue";
import EditarAnotacao from "@/components/rh/anotacao/EditarAnotacao.vue";
import RHAnotacaoService from "@/service/RHAnotacaoService";
import rhService from "@/service/RHService";
export default {
  components: {
    EditarAnotacao,
    rhService
  },
  data() {
    return {
      anotacoes: {},
    };
  },
  created() {
    EventBus.$on("consultaDetalhadaAnotacao", (anotacoes) => {
      this.anotacoes = anotacoes;
      this.$refs['modal-12'].show();
    });

    EventBus.$on("DetalhesAnotacaoPorServidor", (anotacoes) => {
      this.anotacoes = anotacoes;
      this.$refs['modal-12'].show();
    });
  },
  methods: {
    enviarDadosAnotacao(anotacoes, id) {
      EventBus.$emit("EnvioDadosAnotacao", anotacoes);
    },

    updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
    },
    suspenderLicenca(anotacoes) {
      rhService.suspenderLicenca(anotacoes.id).then((resp) => {
        Toast.fire("Sucesso ao suspender licenças", "", "success");
        
      }).catch(()=>{
          Toast.fire("Falha ao suspender licença", "", "error");
      });
    },
  },
};
</script>
