<template>
     <div class="card-body pt-0">
        <div class="ml-2">
            <div class="p-2">
              <FiltroTabelaResultado/>
            </div>
      
        </div>
    
    <Loader :active="loaderActive" message="Consultando..." />
    <table v-show="!loaderActive" class="table table-hover text-nowrap">
      <thead>
        <tr>
          <th>IdContratação</th>
          <th>Licitação</th>
          <th>Data</th>
          <th>Valor</th>
          <th>Ações</th>
          
        </tr>
      </thead>
      <tbody>
        <tr class="clicavel" @click="funcao(resultado.id)" v-for = "(resultado, index) in resultados" :key="index">

            <td>{{resultado.idContratacao || "Sem registro"}}</td>
            <td>{{resultado.licitacao || "Sem registro"}}</td>
            <td>{{updateZonedDateTimeField(resultado.data) }}</td>
            <td>{{resultado.valor || "Sem registro"}}</td>
            <td @click.stop>
            <button  @click="downloadFile(resultado.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm gerar">
              <i class="fa-solid fa-file"></i>
            </button>
            <button @click.prevent="excluir(resultado.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm excluir">
              <i class="fa-solid fa-trash"></i>
            </button>
           </td>  
        </tr>
      </tbody>
    </table>
    </div>

</template>

<script>
import processosService from '@/service/ProcessosService';
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import { EventBus } from '@/main';
import Loader from "../Loader.vue";
import FiltroTabelaResultado from "@/components/processos/FiltroTabelaResultado.vue";

import axios from 'axios';

export default ({
   components:{
      Loader,
      FiltroTabelaResultado
    },

     data(){
       
        return {
          
            loaderActive:false,
            resultados: {},
            resultado:{}
        }
    },
  

    methods:{

        showLoader() {
          this.loaderActive = true;
        },
        hideLoader() {
          this.loaderActive = false;
        },
        funcao(resultado){
          EventBus.$emit("AlterarTabResultado")
          EventBus.$emit("EnvioResultado",resultado)

        },
        //enviarFormulario(){
           // this.dados.push({
               // idContratacao: this.idContratacao,
               // licitacao: this.licitacao,
               // data:this.data,
               // valor: this.valor,
                
           // })
       // },

      updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
      },

      buscarResultado(){
        processosService.buscarResultado(this.resultados).then((resp)=>{
        this.resultados= resp.data;
        })
      },

      excluir(id){
        this.$swal.fire({
        title: "Você deseja excluir ata?",
        icon:"warning",
        showCancelButton: true,
        confirmButtonColor:"#3085d6",
        confirmButtonText: "#Excluir",
        cancelButtonText: "Cancelar",
        }).then((result) => {
          if(result.isConfirmed){
            processosService.apagarResultado(id).then((response)=>{
              this.resultados = response.data;
              Toast.fire("Resultado excluído com sucesso!", "","success");
              this.buscarResultado();
            });
          }
          this.buscarResultado(0);
        });
      },

      downloadFile(id) {
              axios({
                    url: `http://localhost:8089/api/download/resultados/${id}`, // File URL Goes Here
                    method: 'GET',
                   responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'resultado.json');
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
          }
    ,
        
    },

     //filters: {
  //  formatarData: function (data) {
    //  if (data == "" || data == null) {
     //   return 'Sem data registrada';
     // }
     // else {

     ///  const [date, horas] = data.split("T");
      //  const[ano,mes,dia]=date.split("-");
       // const[hr,min,sec]=horas.split(":");


       // const dataHoraFormatada = `${dia}/${mes}/${ano} ${hr}:${min}:${sec.replace("Z","")}`;
     
       // return dataHoraFormatada;
      //}
    //},
 // },


created(){
  this.downloadFile();
  this.showLoader()
  processosService.buscarResultado(this.resultados).then((resp)=>{
        this.resultados= resp.data;
        this.hideLoader()
        console.log(this.resultados);
      }).catch((e) =>{
         this.hideLoader()
          Toast.fire("Erro ao consultar Resultado", "", "error");
      } );
}



});


</script>

<style scoped>
.clicavel{
  cursor: pointer;
}

</style>