<template>

    <div>

        <b-modal id="modal-38" ref="modal-38" title="Cadastro de Dependentes" data-backdrop="static" size="xl"
            cancel-disabled no-close-on-backdrop hide-header-close header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            " content-class="modal-content border-rounded-detail" dialog-class="p-3"
            title-class="modal-title mx-auto xl">
            <div class="card-body pt-0">


                <div class="row px-3">
                    <div class="form-group col-4">
                        <label for="nome">Nome</label>
                        <input type="text" class="form-control form-control-border" id="nome" v-model="dependente.nome"
                            :class="{
                            'is-invalid': isSaving && $v.dependente.nome.$error
                        }" />
                    </div>
                    <div class="form-group col-lg-4">
                        <label for="cpf">CPF</label>
                        <input type="text" class="form-control form-control-border" id="cpf" v-model="dependente.cpf"
                        placeholder="XXX.XXX.XXX-XX"
                        v-mask="'###.###.###-##'"
                            :class="{
                            'is-invalid': isSaving && $v.dependente.cpf.$error
                        }" />
                    </div>
                    <div class="form-group col-lg-4">
                        <label for="vinculo">Vínculo</label>
                        <input type="text" class="form-control form-control-border" id="vinculo"
                            v-model="dependente.vinculo" :class="{
                            'is-invalid': isSaving && $v.dependente.vinculo.$error
                        }" />
                    </div>
                </div>
               

                
                <div class="card-footer">
                    <div class="float-right">
    
                        <button @click="salvarGrupo" class="btn float-right text-color-principal background-principal ml-1">
                            <i class="fas fa-save"></i> Salvar
                        </button>

                        <button @click.prevent="LimparGrupo" type="reset" class="btn btn-default">
                            <i class="fas fa-ban"></i> Limpar
                        </button>
                    </div>
                </div>
                


            </div>

            <h6 class="mt-4 mb-3 "><b>DEPENDENTES CADASTRADOS: </b></h6>

            <div class="table table-responsive p-0">
                <table class="table table-hover table-striped text-nowrap table-responsive-sm table-head-fixed">
                    <thead>
                        <tr>
                            <!-- <th>Nome</th> -->
                            <th>Dependente</th>
                            <th>CPF</th>
                            <th>Vínculo</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr  v-for="(parente, i) in dependentes" :key="i"
                            class=" clicavel">
                            <!-- <td>{{ parente.nome }}</td> -->
                            <td>{{ parente.dependente }}</td>
                            <td>{{ parente.cpf }}</td>
                            <td>{{ parente.vinculo }}</td>

                        </tr>
                    </tbody>
                </table>
            </div>


            <template #modal-footer="{ cancel }">


                <b-button class="btn btn-default float-right" @click="[cancel(), apagarCampos()]">
                    <i class="fa-solid fa-x"></i>Fechar
                </b-button>

            </template>
        </b-modal>


    </div>

</template>

<script>

import cadastroFuncionarioService from "@/service/CadastroFuncionarioService";
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import { EventBus } from "@/main";

export default {
    data() {
        return {
            dependente: {
                funcionarioCadastrante: null,
            },
            dependentes: [],
            idFuncionario: this.$store.state.funcionario.idFuncionarioCadastrado,
            isSaving: false,
            funcionarioInfo: {},
            funcionarioId:{},
        }
    },

    computed: {
        usuario() {
            return this.$store.state.auth.user.usuario;
        },
    },
    watch: {

    },

    created() {
        this.$store.state.funcionario
        this.buscarFuncionario(this.$store.state.funcionario.idFuncionarioCadastrado);

        EventBus.$on("AtualizarDependentes",()=>{
            this.buscarFuncionario(this.$store.state.funcionario.idFuncionarioCadastrado);
        })

        EventBus.$on("AtualizarListaDependentes",()=>{
            this.buscarFuncionario(this.$store.state.funcionario.idFuncionarioCadastrado);
        })

        this.consultarInfoFuncionario();
    },
    methods: {

        consultarInfoFuncionario() {
            consultaFuncionarioService.buscarFuncionarioId(this.$store.state.funcionario.idFuncionarioCadastrado)
                .then((response) => {
                    this.funcionarioInfo = response.data
                    //console.log(this.funcionarioInfo);
                });
        },


        salvarGrupo() {

            this.dependente.funcionarioCadastrante = this.usuario.idFuncionario;
            this.dependente.funcionario = {
                id: this.funcionarioInfo.id
            }
            cadastroFuncionarioService.cadastroDependente(this.dependente)
                .then((response) => {
                    this.dependente = response.data;
                    Toast.fire("Cadastro realizado", "", "success");
                    this.dependente = {}
                    EventBus.$emit("AtualizarListaDependentes")

                }).catch(() => {
                    Toast.fire("Falha ao cadastrar dependentes", "", "error");
                });


        },

        buscarFuncionario(idFuncionario){
            cadastroFuncionarioService.listarDependenteFuncionario(idFuncionario).then((resp)=>
            {
                this.dependentes = resp.data;
                //console.log(this.dependentes);
            })
        },

        ExcluirGrupo() { },
        LimparGrupo() {
            this.dependente = {}
        },
        apagarCampos() { }


    }
}
</script>
<style></style>