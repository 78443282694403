<template>
  <div class="row mx-3 my-3">
    <div
      class="col-lg-4 col-sm-12 col-md-12 p-3 cardpessoa"
      :key="i"
      v-for="(visita, i) in visitasLista"
      @click="escolhaCadastroAtualizacao(visita)"
    >
      <div>
        <p
          class="
            header
            card-item-result
            background-principal
            text-color-principal
          "
        >
          {{ visita.nome }}
        </p>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <img
            v-if="visita.foto"
            v-bind:src="'data:image/jpeg;base64,' + visita.foto"
            alt="user-avatar"
            style="max-width: 90%; margin-left: 5%"
            class=""
          />
          <img
            v-else
            src="https://webc.ssp.ma.gov.br/imagens/elementos/icone-imagem-indisponivel2.png"
            alt="user-avatar"
            class="img img-thumbnail mx-0 px-0 img-card-measure"
          />
        </div>

        <div class="col-9 py-3">
          <div>
            <p class="mx-0 my-0 py-0"><b>Mãe: </b> {{ visita.mae }}</p>
            <!-- <p class="mx-0 my-0 py-0">
                      <b>CPF: </b>
                      {{ visita.cpf }}
                    </p> -->
            <p v-if="visita.observacao" class="mx-0 my-0 py-0">
              <b>Obs.: </b>
              {{ visita.observacao }}
            </p>
            <p v-if="visita.dataVisita" class="mx-0 my-0 py-0">
              <b>Dt. da visita: </b>
              {{ visita.dataVisita }}
            </p>
          </div>
          <div>
            <p v-if="visita.descricao" class="mx-0 my-0 py-0">
              <b>Setor visitado: </b>
              {{ visita.descricao }}
            </p>
            <p v-if="visita.nomePessoaVisitada" class="mx-0 my-0 py-0">
              <b>Visitado(a): </b>
              {{ visita.nomePessoaVisitada }}
            </p>
            <p class="mx-0 my-0 py-0">
              <b>Data de nascimento:</b>
              {{ visita.dataNascimento  }}
            </p>
            <p class="mx-0 my-0 py-0" v-if="visita.dataVisita">
              <b>
                {{
                  visita.dataSaida ? "Dt. de saída:" : "Visita em andamento"
                }}</b
              >
              {{ visita.dataSaida ? visita.dataSaida : "" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CadastroVisita from "@/service/CadastroVisita";
export default {
  name: "TabsTodasVisitas",
  data() {
    return {
      visitasLista: [],
    }
  },
  created() {
    // EventBus.$on("selecionarAba", this.selecionarAba);
    this.buscarTodasVisitas();
  },
  methods: {
    buscarTodasVisitas() {
      this.visitasLista = [];
      CadastroVisita.buscarVisitasTodas().then((r) => {
        //let ultimasVisitas = this.formatarResposta(r.data);
        let ultimasVisitas = r.data
        this.visitasLista = ultimasVisitas;
      });
    }
  }
};
</script>
<style>
</style>