import { render, staticRenderFns } from "./EmissaoBI.vue?vue&type=template&id=315f5b2c&"
import script from "./EmissaoBI.vue?vue&type=script&lang=js&"
export * from "./EmissaoBI.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports