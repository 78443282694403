<template>
  <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Servidor" />
    <conteudo v-role= "'RECURSOS_HUMANOS'">
      <!-- <ModalConsultaPessoa v-if="this.$route.name.includes('cadastrofuncionario') ? false : true"
        v-show="isModalVisible" @closeModalCadastro="closeModal" /> -->
      <!-- <div class="modal-backdrop fade show" v-if="this.$route.name.includes('cadastrofuncionario') ? false : true"
        v-show="isModalVisible"></div> -->
      <TabsCadastroAnotacao />
    </conteudo>
  </section>
</template>

<script>
import { EventBus } from "@/main";
import TabsCadastroAnotacao from "@/components/rh/anotacao/TabsAnotacao.vue";

export default {
  components: { TabsCadastroAnotacao },
  created() {
    
  },

  data() {
    return {
      isModalVisible: false,
      
    };
  },
  mounted() {},
   
  methods: {
    
  },
};
</script>
