<template>
  <div>
    <!-- <button id="emitirAtestado" @click="emitirAtestado()">
        Emitir Atestado
      </button> -->
    <iframe hidden class="preview-pane" style="width: 1000px; height: 95vh; margin: 0px; padding: 0px"
      :src="pdfSrc + '#zoom=100'">
    </iframe>

    <!-- <img
      src="@/assets/images/logo-ma-small.png"
      style="display: none"
      ref="brasaoImg"
      class="brasaoImg"
    /> -->
  </div>
</template>

<script>
import jsPDF from "jspdf";
import CadastroFuncionarioService from "@/service/CadastroFuncionarioService";
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import CadastroPessoaService from "@/service/CadastroPessoaService";
import ConsultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import ConsultaListasService from "@/service/ConsultaListasService";

export default {
  name: "PdfFichaCadastro",
  components: {},
  props: {},
  data() {
    return {
      pdfSrc: "data:application/pdf;base64,",
      logoBase64: "",
      value: this.values,
      size: 300,
      atestado: {},
      qrcodeValue: "",

      idFuncionario: this.$store.state.funcionario.idFuncionarioCadastrado,
      funcionarioInfo: {},
      funcionario: {},
      pessoa: {},
      bancos: {},
      bancoSelecionado: {},
      estados:{},




    };
  },
  setup() { },
  methods: {

    updateZonedDateTimeField(field) {
        if(field == null){

        return ""
        }
        else{
        return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
        }
    },

    carregarBanco() {

      ConsultaListasService.buscarListaPorNomeTabela("banco")
        .then((response) => {
          this.bancos = response.data;
          console.log("Lista de bancos:", this.bancos);

        })

    },

    buscarEstado(){
      ConsultaListasService.buscarListaPorNomeTabela('estado')
      .then((response) => {
        this.estados = response.data;
        console.log("estados: " + this.estados);
      })
      .catch((e) => this.alertaFalhaBuscarEstado());
    },

    buscarFuncionario1() {
      ConsultaFuncionarioService.buscarFuncionarioId(this.$store.state.funcionario.idFuncionarioCadastrado)
        .then((res) => {
          this.funcionario = res.data;
          console.log("funcionario: ", this.funcionario);

        })
      CadastroPessoaService.buscarPessoaCompletaPorId(this.$store.state.funcionario.idPessoaFuncionario)
        .then((response) => {
          this.pessoa = response.data;
          console.log("pessoa: ", this.pessoa);
        })

      if (this.pessoa.nome == null) {
        this.pessoa.nome = "";
      }
      if (this.pessoa.pai == null) {
        this.pessoa.pai = "";
      }
      if (this.pessoa.mae == null) {
        this.pessoa.mae = "";
      }
      if (this.pessoa.pais == null) {
        this.pessoa.pais = { descricao: "" };
      }
      if (this.funcionario.novaMatricula == null) {
        this.funcionario.novaMatricula = "";
      }
      if (this.funcionario.lotacao == null) {
        this.funcionario.lotacao = { descricao: "" };
      }
      if (this.funcionario.dadosAdicionais == null) {
        this.funcionario.dadosAdicionais = { bancoAg: "" };
      }
      if (this.funcionario.dadosAdicionais == null) {
        this.funcionario.dadosAdicionais = { fatorRh: "" };
      }
      if (this.funcionario.dadosAdicionais == null) {
        this.funcionario.dadosAdicionais = { grupoSanguineo: "" };
      }
      if (this.funcionario.cargo == null) {
        this.funcionario.cargo = { grupoSanguineo: "" };
      }


      // if (this.funcionario.matricula == null) {
      //   this.funcionario.matricula = "";
      // }


    },


    async emitirPdf() {
      await this.carregarBanco();
      //await this.buscarEstado();
      await this.buscarFuncionario1();
      await this.getEstadoUF();



      let doc = new jsPDF("p", "mm", "a4");
      const pageWidth = doc.internal.pageSize.getWidth();
      const center = pageWidth / 2;

      //   const pageHeight = doc.internal.pageSize.getHeight();

      const image = new Image();
      //   image.src = this.$refs.brasaoImg.src;
      image.src = require("@/assets/logo-ma-small.png");
      image.width = 20;
      image.height = 20;
      let x = (pageWidth - image.width) / 2;
      doc.addImage(image, "PNG", x, 10, 20, 20, "center");

      doc.setTextColor(0);
      doc.setFont("times", "bold");
      doc.setFontSize(12);
      doc.text("Estado do Maranhão", center, 35, null, null, "center");
      doc.setFont("times", "normal");
      doc.setFontSize(9);
      doc.text(
        "Secretaria de Estado da Segurança Pública",
        center,
        39,
        null,
        null,
        "center"
      );

      doc.text(
        "Av. dos Franceses, s/n, Bairro Vila Palmeira, CEP 65036-283, São Luís-MA",
        center,
        43,
        null,
        null,
        "center"
      );
      doc.text("Telefone: (98) 3214 3700", center, 47, null, null, "center");

      doc.setFontSize(15);
      doc.setFont("times", "bold");
      doc.text(
        "FICHA DE CADASTRO DE SERVIDOR",
        center,
        60,
        null,
        null,
        "center"
      );
      doc.setLineWidth(0.2);
      doc.line(58, 61, 152.5, 61);

      doc.text("IDENTIFICAÇÃO", center, 140, null, null, "center");
      doc.setLineWidth(0.2);
      doc.line(83, 141, 126.7, 141);

      let lastY = 75;
      doc.setFontSize(12);
      doc.setFont("times", "normal");
      doc.text("Matrícula:", 40, lastY, null, null, "right");
      doc.text("ID:", 110, lastY, null, null, "right");
      lastY += 5;
      doc.text("Nome:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Pai:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Mãe:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Lotação:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Nacionalidade:", 40, lastY, null, null, "right");
      doc.text("Naturalidade:", 110, lastY, null, null, "right");
      doc.text("Data Nasc.:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("Registro N°.:", 40, lastY, null, null, "right");
      doc.text("Livro N°.:", 110, lastY, null, null, "right");
      doc.text("Folha:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("Endereço:", 40, lastY, null, null, "right");
      doc.text("Nº:", 110, lastY, null, null, "right");
      lastY += 5;
      doc.text("Município:", 40, lastY, null, null, "right");
      doc.text("UF:", 110, lastY, null, null, "right");
      lastY += 5;
      doc.text("Bairro:", 40, lastY, null, null, "right");
      doc.text("CEP:", 110, lastY, null, null, "right");
      lastY += 5;
      doc.text("Referência:", 40, lastY, null, null, "right");
      doc.text("Fones:", 110, lastY, null, null, "right");

      lastY = 155;
      doc.text("CPF:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("RG:", 40, lastY, null, null, "right");
      doc.text("Emissor:", 110, lastY, null, null, "right");
      doc.text("Data de Emissão:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("Cart. Profissional:", 40, lastY, null, null, "right");
      doc.text("Série:", 110, lastY, null, null, "right");
      doc.text("UF:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("Cert. Reservista:", 40, lastY, null, null, "right");
      doc.text("Região:", 110, lastY, null, null, "right");
      doc.text("Categoria:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("Título Eleitoral:", 40, lastY, null, null, "right");
      doc.text("Zona:", 110, lastY, null, null, "right");
      doc.text("Seção:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("CNH:", 40, lastY, null, null, "right");
      doc.text("Categoria:", 110, lastY, null, null, "right");
      doc.text("Validade:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("PIS/PASEP:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Banco:", 40, lastY, null, null, "right");
      doc.text("Agência:", 110, lastY, null, null, "right");
      doc.text("Conta:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("Grau de Instrução:", 40, lastY, null, null, "right");
      doc.text("Formação:", 125, lastY, null, null, "right");
      lastY += 5;
      doc.text("Grupo Sanguíneo:", 40, lastY, null, null, "right");
      doc.text("Fator RH:", 110, lastY, null, null, "right");
      lastY += 5;
      doc.text("Estado Civil:", 40, lastY, null, null, "right");
      doc.text("Sexo:", 110, lastY, null, null, "right");
      lastY += 5;
      doc.text("Cônjuge:", 40, lastY, null, null, "right");
      doc.text("N°. de Dependentes:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("Admissão:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Ato:", 40, lastY, null, null, "right");
      doc.text("Data:", 80, lastY, null, null, "right");
      doc.text("Forma:", 125, lastY, null, null, "right");
      doc.text("Pub:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("Cargo:", 40, lastY, null, null, "right");
      lastY += 5;
      doc.text("Quadro:", 40, lastY, null, null, "right");
      doc.text("Posse:", 170, lastY, null, null, "right");
      lastY += 5;
      doc.text("Observações:", 40, lastY, null, null, "right");

      this.inserirLinhasAssinatura(doc);
      this.preencherPdfFichaCadastro(doc);

      //   var out = doc.output();
      //   var url = "data:application/pdf;base64," + btoa(out);
      //   this.pdfSrc = url;
      doc.save("FICHA DE CADASTRO");
    },
    preencherPdfFichaCadastro(doc) {
      let lastY = 75;
      doc.setFontSize(12);
      doc.setFont("times", "bold");
      let matricula = this.funcionario.matricula || "";
      doc.text(`${matricula}`, 41, lastY, null, null, "left");
      let novaMatricula = this.funcionario.novaMatricula || "";
      doc.text(`${novaMatricula}`, 111, lastY, null, null, "left");
      lastY += 5;
      let nome = this.pessoa.nome || " ";
      doc.text(nome, 41, lastY, null, null, "left");
      lastY += 5;
      let pai = this.pessoa.pai || " ";
      doc.text(pai, 41, lastY, null, null, "left");
      lastY += 5;
      let mae = this.pessoa.mae || " ";
      doc.text(mae, 41, lastY, null, null, "left");
      lastY += 5;

      let lotacao = this.funcionario.lotacao.descricao || " ";
      doc.text(lotacao, 41, lastY, null, null, "left");
      lastY += 5;

      let enderecos = this.pessoa.enderecos;

      let enderecoMaisRecente = enderecos.length > 0 ? enderecos.reduce((enderecoRecente, enderecoAtual) => {
        return new Date(enderecoAtual.dataRegistroEndereco) > new Date(enderecoRecente.dataRegistroEndereco) ? enderecoAtual : enderecoRecente;
      }) : null;

      let referencia = enderecoMaisRecente ? (enderecoMaisRecente.referencia || " ") : " ";
      let nacionalidade = enderecoMaisRecente && enderecoMaisRecente.pais ? enderecoMaisRecente.pais.descricao : " ";
      let naturalidade = enderecoMaisRecente && enderecoMaisRecente.estado ? enderecoMaisRecente.estado.descricao : " ";
      let bairro = enderecoMaisRecente && enderecoMaisRecente.bairro ? enderecoMaisRecente.bairro.descricao : " ";
      let municipio = enderecoMaisRecente && enderecoMaisRecente.cidade ? enderecoMaisRecente.cidade.descricao : " ";
      let cep = enderecoMaisRecente ? (enderecoMaisRecente.cep || " ") : " ";
      let rua = enderecoMaisRecente ? (enderecoMaisRecente.logradouro || " ") : " ";
      let numero = enderecoMaisRecente ? (enderecoMaisRecente.numero || " ") : " ";
      let uf = enderecoMaisRecente ? (this.getEstadoUF(enderecoMaisRecente.estado.id) || "") : " ";
      console.log("uf: " + uf)
      



      doc.text(nacionalidade, 41, lastY, null, null, "left");
      doc.text(naturalidade, 111, lastY, null, null, "left");

      let dataNascimento = this.updateZonedDateTimeField(this.pessoa.dataNascimento) || " ";
      doc.text(dataNascimento, 171, lastY, null, null, "left");
      lastY += 5;
      let registro = this.funcionario.dadosAdicionais.registro || " ";
      doc.text(registro, 41, lastY, null, null, "left");
      let livro = this.funcionario.dadosAdicionais.livro || " ";
      doc.text(livro, 111, lastY, null, null, "left");
      let folha = this.funcionario.dadosAdicionais.folha || " ";
      doc.text(folha, 171, lastY, null, null, "left");
      lastY += 5;


      doc.text(rua, 41, lastY, null, null, "left");
      doc.text(numero, 111, lastY, null, null, "left");
      lastY += 5;
      doc.text(municipio, 41, lastY, null, null, "left");
      doc.text(uf, 111, lastY, null, null, "left");
      lastY += 5;
      doc.text(bairro, 41, lastY, null, null, "left");
      doc.text(cep, 111, lastY, null, null, "left");
      lastY += 5;
      doc.text(referencia, 41, lastY, null, null, "left");
      doc.text("", 111, lastY, null, null, "left");

      //IDENTIFICAÇÃO
      lastY = 155;
      //let cpf  = this.pessoa.documentos[0].numero || "";
      let cpf = this.pessoa.documentos;
      let documentosComIdUm = cpf.filter(documento => documento.tipoDocumento.id === 2);
      let descricaoTipoDocumento = documentosComIdUm.map(documento => documento.numero) || "";
      doc.text(descricaoTipoDocumento, 41, lastY, null, null, "left");
      lastY += 5;


      let rg = this.pessoa.documentos;
      let rgId = rg.filter(documento => documento.tipoDocumento.id === 14);
      let descricaoRg = rgId.length > 0 ? rgId[0].numero : "";
      let orgaoEmissor = rgId.length > 0 ? rgId[0].orgaoExpeditor : "";
      let orgao = orgaoEmissor || "";
      let dataEmissao = rgId.length > 0 ? this.updateZonedDateTimeField(rgId[0].dataExpedicao) : "";

      //console.log("dataEmissor: " + orgaoEmissor)
      doc.text(descricaoRg, 41, lastY, null, null, "left");
      doc.text(orgao, 111, lastY, null, null, "left");
      doc.text(dataEmissao, 171, lastY, null, null, "left");
      lastY += 5;

      //Carteira de Trabalho
      let ctps = this.funcionario.dadosAdicionais.numeroCTPS || "";
      doc.text(ctps, 41, lastY, null, null, "left");
      doc.text("", 111, lastY, null, null, "left");
      doc.text("", 171, lastY, null, null, "left");
      lastY += 5;

      //Cateira de Reservista

      let catReservista = this.pessoa.documentos;
      let catReservistaId = catReservista.filter(documento => documento.tipoDocumento.id === 4) || "";
      let descricaoCR = catReservistaId.length > 0 ? catReservistaId[0].numero : "";

      console.log("Descricao: " + descricaoCR);
      let regiao = catReservistaId.length > 0 ?  catReservistaId[0].regiao  : "";
      let regiaoCR  = regiao.toString();
      let categoriaCR = catReservistaId.length > 0 ? catReservistaId[0].categoria  : "";
      console.log("regiao" + regiao);

      doc.text(descricaoCR, 41, lastY, null, null, "left");
      doc.text(regiaoCR, 111, lastY, null, null, "left");
      doc.text(categoriaCR, 171, lastY, null, null, "left");
      lastY += 5;

      //Titulo Eleitoral
      let titulo = this.pessoa.documentos;
      let tituloId = titulo.filter(documento => documento.tipoDocumento.id === 16);

      let descricaoTE = tituloId.length > 0 ? tituloId[0].numero : "";
      let zona = tituloId.length > 0 ? tituloId[0].zona : "";
      let secao = tituloId.length > 0 ? tituloId[0].secao : "";

      doc.text(descricaoTE, 41, lastY, null, null, "left");
      doc.text(zona, 111, lastY, null, null, "left");
      doc.text(secao, 171, lastY, null, null, "left");
      lastY += 5;

      //Carteira CHN

      let cnh = this.pessoa.documentos;
      let cnhId = cnh.filter(documento => documento.tipoDocumento.id === 3);

      let descricaoCNH = cnhId.length > 0 ? cnhId[0].numero : "";
      let categoriaCNH = cnhId.length > 0 ? cnhId[0].categoria : "";
      let validadeCNH = cnhId.length > 0 ? this.updateZonedDateTimeField(cnhId[0].dataValidade) : "";

      doc.text(descricaoCNH, 41, lastY, null, null, "left");
      doc.text(categoriaCNH, 111, lastY, null, null, "left");
      doc.text(validadeCNH, 171, lastY, null, null, "left");
      lastY += 5;


      let pisPasep = this.pessoa.documentos;
      let pisPasepId =  pisPasep.filter(documento => documento.tipoDocumento.id === 18);

      let descricaopisPasep = pisPasepId.length > 0 ? pisPasepId[0].numero : "";
      doc.text(descricaopisPasep, 41, lastY, null, null, "left");
      lastY += 5;

      let bancoSelecionado = null;
      if (this.funcionario && this.funcionario.dadosAdicionais && this.funcionario.dadosAdicionais.banco) {
        bancoSelecionado = this.bancos.find(item => item.id === this.funcionario.dadosAdicionais.banco);
      }

    
      let descricaoBanco = bancoSelecionado ? bancoSelecionado.descricao : "";
      doc.text(descricaoBanco, 41, lastY, null, null, "left");
      let bancoAgencia = this.funcionario.dadosAdicionais.bancoAg || " ";
      doc.text(bancoAgencia, 111, lastY, null, null, "left");
      let bancoConta = this.funcionario.dadosAdicionais.bancoConta || " ";
      doc.text(bancoConta, 171, lastY, null, null, "left");
      lastY += 5;
      doc.setFontSize(11);
      let escolaridade = this.pessoa.escolaridade || " ";
      //let escolaridade =(this.getEscolaridade(this.pessoa.escolaridade)) ||  " ";
      console.log("escolaridade " + escolaridade)
      doc.text(escolaridade, 41, lastY, null, null, "left");
      doc.text("", 125, lastY, null, null, "left");
      lastY += 5;
      doc.setFontSize(12);
      //let fatorRh = this.funcionario.dadosAdicionais.fatorRh || " ";
      let fatorRh =(this.getFatorRh(this.funcionario.dadosAdicionais.fatorRh)) ||  " ";
      doc.text(fatorRh, 41, lastY, null, null, "left");
      //let grupoSanguineo = this.funcionario.dadosAdicionais.grupoSanguineo || " ";
      let grupoSanguineo =(this.getGrupoSanguineo(this.funcionario.dadosAdicionais.grupoSanguineo)) ||  " ";
      doc.text(grupoSanguineo, 111, lastY, null, null, "left");
      lastY += 5;
      let estadoCivil = this.pessoa.estadoCivil || " ";
      doc.text(estadoCivil, 41, lastY, null, null, "left");
      let sexo = this.pessoa.sexo || " ";
      doc.text(sexo, 111, lastY, null, null, "left");
      lastY += 5;
      let conjuge = this.funcionarioInfo.conjuge || " ";
      doc.text(conjuge, 41, lastY, null, null, "left");
      let dependentes = (this.funcionario.dadosAdicionais.numeroDependentes || "").toString();
      doc.text(dependentes, 171, lastY, null, null, "left");
      lastY += 5;
      let dataAdmissao = this.updateZonedDateTimeField(this.funcionario.dadosAdicionais.dataAdmissao) || " ";
      doc.text(dataAdmissao, 41, lastY, null, null, "left");
      lastY += 5;
      let ato = this.funcionario.dadosAdicionais.admissaoAto || " ";
      doc.text(ato, 41, lastY, null, null, "left");
      doc.text("", 80, lastY, null, null, "left");
      let tipoContratacao = this.funcionario.dadosAdicionais.tipoContratacao || " ";
      doc.text(tipoContratacao, 125, lastY, null, null, "left");
      let dataPublicacao = this.updateZonedDateTimeField(this.funcionario.admissaoPub) || " ";
      doc.text(dataPublicacao, 171, lastY, null, null, "left");
      lastY += 5;
      let cargo = this.funcionario.cargo.descricao || " ";
      doc.text(cargo, 41, lastY, null, null, "left");
      lastY += 5;
      let quadro = this.funcionarioInfo.quadro || " ";
      doc.text(quadro, 41, lastY, null, null, "left");
      let posse = this.updateZonedDateTimeField(this.funcionario.admissaoPosse) || " ";
      doc.text(posse, 171, lastY, null, null, "left");
      lastY += 5;
      let observacao = this.funcionario.dadosAdicionais.observacao || "";
      doc.text(observacao, 41, lastY, null, null, "left");
    },
    inserirLinhasAssinatura(doc) {
      let x;
      doc.setLineWidth(0.5);
      doc.line(20, 280, 95, 280);
      doc.line(115, 280, 190, 280);
      doc.setFontSize(9);
      doc.setFont("times", "bold");
      let text = "Assinatura 1";
      let textWidth =
        (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (115 - textWidth) / 2;
      doc.text(text, x, 285);
      text = "Cargo 1";
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (115 - textWidth) / 2;
      doc.text(text, x, 290);

      text = "Assinatura 2";
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (305 - textWidth) / 2;
      doc.text(text, x, 285);
      text = "Cargo 2";
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (305 - textWidth) / 2;
      doc.text(text, x, 290);

      doc.setFont("times", "normal");
    },

    getEstadoUF(estadoInteger) {
      const estadoUF = {
        1: "AC",
        2: "AL",
        3: "AM",
        4: "AP",
        5: "BA",
        6: "CE",
        7: "DF",
        8: "ES",
        9: "GO",
        10: "MA",
        11: "MG",
        12: "MS",
        13: "MT",
        14: "PA",
        15: "PB",
        16: "PE",
        17: "PI",
        18: "PR",
        19: "RJ",
        20: "RN",
        21: "RO",
        22: "RR",
        23: "RS",
        24: "SC",
        25: "SE",
        26: "SP",
        27: "TO",
      };
      return estadoUF[estadoInteger] || " ";
    },

    getFatorRh(fatorRhInteger) {
      const fatorRh = {
        1: "Positivo",
        2: "Negativo"
      };
      return fatorRh[fatorRhInteger] || " ";
    },

    getGrupoSanguineo(grupoSanguineoInteger) {
      const grupoSanguineo = {
        1: "A", 
        2: "B",
        3: "AB",
        4: "O",
      
      };
      return grupoSanguineo[grupoSanguineoInteger] || " ";
    },

   
  },
  mounted() {
    this.$root.$on("EmitirPdfFichaCadastro", () => {
      this.emitirPdf();
    });
  },
};
</script>
