import instance from "./axiosConfig";

class ConsultaFuncionarioService {
  buscarFuncionario(page) {
    return instance.get(`/funcionario-service/api/funcionarios/resumo-view?page=${page}`);
  }

  buscarFuncionarioCPF(page, cpf) {
    return instance.get(
      `/funcionario-service/api/funcionarios/resumo-view-cpf/${cpf}?page=${page}`
    );
  }
  buscarFuncionarioNome(page, nome) {
    return instance.get(
      `/funcionario-service/api/funcionarios/resumo-view-nome/${nome}?page=${page}`
    );
  }

  buscarFuncionarioMatricula(page, matricula) {
    return instance.get(
      `/funcionario-service/api/funcionarios/resumo-view-matricula/${matricula}?page=${page}`
    );
  }

  buscarFuncionarioNovaMatricula(page, novaMatricula) {
    return instance.get(
      `/funcionario-service/api/funcionarios/resumo-view-novaMatricula/${novaMatricula}?page=${page}`
    );
  }

  buscarFuncionarioParametro(tipo, parametro) {
    return instance.get(`/funcionario-service/api/funcionarios/resumo?${tipo}=${parametro}`);
  }

  buscarFuncionarioId(id) {
    return instance.get(`/funcionario-service/api/funcionarios/${id}`);
  }

  buscarPerfisPorFuncionarioId(id) {
    return instance.get(`/funcionario-service/api/perfisbyfuncionarioid/${id}`);
  }

  buscarFuncionarioPorPessoaCorrespondenteId(pessoaCorrespondenteId) {
    return instance.get(
      `/funcionario-service/api/funcionarios/pessoaCorrespondenteId?id=${pessoaCorrespondenteId}`
    );
  }
  buscarFuncionarioUsuario(id) {
    return instance.get(`/funcionario-service/api/funcionarios/buscaFuncionarioUsuario/${id}`);
  }
}
export default new ConsultaFuncionarioService();
