import axios from 'axios';


class ProcessosService{

    //ATA REGISTRO
    criarAtaRegistro(ata){
        return axios.post("http://localhost:8089/api/ata-registros", ata);
    }

    apagarAtaRegistro(id){
        return axios.delete(`http://localhost:8089/api/ata-registros/${id}`);
    }

    updateAtaRegistro(ata){
        return axios.put("http://localhost:8089/api/ata-registros/" + registro.ataRegistroDTO.id, ata);
    }

    buscarAtaRegistro() {
        return axios.get("http://localhost:8089/api/ata-registros?sort=id,desc");


    }

    getAtaRegistro(id) {
        return axios.get("http://localhost:8089/api/ata-registros/" + id);
      }

    downloadAtaRegistro(id){
        return  axios({
            url: `http://localhost:8089/api/download/ata-registros/${id}`, // File URL Goes Here
            method: 'GET',
            responseType: 'blob'})
    }

    buscarAtaRegistroPorIdContratacao(idContratacao){
        return axios.get("http://localhost:8089/api/ata-registros/idContratacao/" + idContratacao);
    }

    buscarAtaRegistroPorNumero(numero){
        return axios.get("http://localhost:8089/api/ata-registros/numero/" + numero);
    }

    buscarAtaRegistroPorAno(ano){
        return axios.get("http://localhost:8089/api/ata-registros/ano/" + ano);
    }
    // CADASTRO LICITANTE
    criarCadastroLicitante(licitacao){
        return axios.post("http://localhost:8089/api/cadastro-licitantes" , licitacao);
    }

    buscarCadastroLicitante() {
        return axios.get("http://localhost:8089/api/cadastro-licitantes?sort=id,desc");
      }

    getCadastroLicitante(id) {
        return axios.get("http://localhost:8089/api/cadastro-licitantes/" + id);
    }


    apagarCadastroLicitante(id){
        return axios.delete(`http://localhost:8089/api/cadastro-licitantes/${id}`); 
    }

    updateCadastroLicitante(licitacao){
        return axios.put("http://localhost:8089/api/cadastrolicitantes/" + licitacao.cadastroLicitanteDTO.id, licitacao);
    }

    buscarCadastroLicitantePorIdContratacao(idContratacao){
        return axios.get("http://localhost:8089/api/cadastro-licitantes/idContratacao/" + idContratacao);
    }

    buscarCadastroLicitantePorCpfCnpj(cpfCnpj){
        return axios.get("http://localhost:8089/api/cadastro-licitantes/cpfCnpj/" + cpfCnpj);
    }
    // CADASTRO ORGAO
    criarCadastroOrgao(orgao){
        return axios.post("http://localhost:8089/api/cadastro-orgaos", orgao);
    }

    buscarCadastroOrgao() {
        return axios.get("http://localhost:8089/api/cadastro-orgaos?sort=id,desc");
      }

    getCadastroOrgao(id) {
        return axios.get("http://localhost:8089/api/cadastro-orgaos/" + id);
    }


    apagarCadastroOrgao(id){
        return axios.delete(`http://localhost:8089/api/cadastro-orgaos/${id}`);
    }

    updateCadastroOrgao(orgao){
        return axios.put("http://localhost:8089/api/cadastro-orgaos/" + orgao.cadastroOrgaoDTO.id,orgao);
    }

    buscarCadastroOrgaoPorCpfCnpj(cpfCnpj){
        return axios.get("http://localhost:8089/api/cadastro-orgaos/cpfCnpj/" + cpfCnpj);
    }

    buscarCadastroOrgaoPorIdContratacao(idContratacao){
        return axios.get("http://localhost:8089/api/cadastro-orgaos/idContratacao/" + idContratacao);
    }

    buscarCadastroOrgaoPorPerfil(perfil){
        return axios.get("http://localhost:8089/api/cadastro-orgaos/perfil/" + perfil);
    }

    

    // CONTRATO
    criarContrato(contrato){
        return axios.post("http://localhost:8089/api/contratos", contrato)
    };
    
    buscarContrato() {
        return axios.get("http://localhost:8089/api/contratos");
    }

    getContrato(id) {
        return axios.get("http://localhost:8089/api/contratos/" + id);
    }

    apagarContrato(id){
        return axios.delete(`http://localhost:8089/api/contratos/${id}`);
    }

    updateContrato(contrato){
        return axios.put("http://localhost:8089/api/contratos/" + contrato.contratoDTO.id, contrato);
    }

    buscarContratoPorCnpjUg(cnpjUg){
        return axios.get("http://localhost:8089/api/contratos/cnpjUg/" + cnpjUg);
    }

    buscarContratoPorNumeroContrato(numeroContrato){
        return axios.get("http://localhost:8089/api/contratos/numeroContrato/" + numeroContrato);
    }

    buscarContratoAnoContrato(anoContrato){
        return axios.get("http://localhost:8089/api/contratos/anoContrato/" + anoContrato);
    }

    buscarContratoPorIdContratacao(idContratacao){
        return axios.get("http://localhost:8089/api/contratos/idContratacao/" + idContratacao);
    }

    buscarContratoPorIdContrato(idContrato){
        return axios.get("http://localhost:8089/api/contratos/idContrato/" + idContrato);
    }

    buscarContratoPorCpfCnpj(cpfCnpj){
        return axios.get("http://localhost:8089/api/contratos/cpfCnpj/" + cpfCnpj);
    }

    // PROCEDIMENTO
    criarProcedimento(procedimento){
        return axios.post("http://localhost:8089/api/procedimentos", procedimento)
           
    }

    buscarProcedimento() {
        return axios.get("http://localhost:8089/api/procedimentos?sort=id,desc");
      }

    getProcedimento(id) {
        return axios.get("http://localhost:8089/api/procedimentos/" + id);
    }

    apagarProcedimento(id){
        return axios.delete(`http://localhost:8089/api/procedimentos/${id}`);
    }

    updateProcedimento(procedimento){
        return axios.put("http://localhost:8089/api/procedimentos/" + procedimento.procedimentoDTO.id, procedimento);
    }

    buscarProcedimentoPorCnpjUg(cnpjUg){
        return axios.get("http://localhost:8089/api/procedimentos/cnpjUg/" + cnpjUg);
    }

    buscarProcedimentoPorIdContratacao(idContratacao){
        return axios.get("http://localhost:8089/api/procedimentos/IdContratacao/" + idContratacao);
    }

    buscarProcedimentoNumeroProcedimento(numeroProcedimento){
        return axios.get("http://localhost:8089/api/procedimentos/numeroProcedimento/" + numeroProcedimento);
    }

    buscarProcedimentoPorAnoProcedimento(anoProcedimento){
        return axios.get("http://localhost:8089/api/procedimentos/anoProcedimento/" + anoProcedimento);
    }

    buscarProcedimentoPorNumeroProcesso(numeroProcesso){
        return axios.get("http://localhost:8089/api/procedimentos/numeroProcesso/" + numeroProcesso);
    }

    buscarProcedimentoPorAnoProcesso(anoProcesso){
        return axios.get("http://localhost:8089/api/procedimentos/anoProcesso/" + anoProcesso);
    }

    buscarProcedimentoPorCodProcedimento(codProcedimento){
        return axios.get("http://localhost:8089/api/procedimentos/codProcedimento/" + codProcedimento);
    }

    // RESULTADO
    criarResultado(resultado){
        return axios.post("http://localhost:8089/api/resultados", resultado)
           
    }

    buscarResultado() {
        return axios.get("http://localhost:8089/api/resultados?sort=id,desc");
      }

    getResultado(id) {
        return axios.get("http://localhost:8089/api/resultados/" + id);
    }

    apagarResultado(id){
        return axios.delete(`http://localhost:8089/api/resultados/${id}`);
    }

    updateResultado(resultado){
        return axios.put("http://localhost:8089/api/resultados/" + resultado.resultadoDTO.id, resultado);
    }

    buscarResultadoPorIdContratacao(idContratacao){
        return axios.get("http://localhost:8089/api/resultados/idContratacao/" + idContratacao);
    }

    buscarResultadoLicitacao(licitacao){
        return axios.get("http://localhost:8089/api/resultados/licitacao/" + licitacao);
    }


    // SANÇÃO
    criarSancao(sancao){
        return axios.post("http://localhost:8089/api/sancoes", sancao)
    }

    buscarSancao() {
        return axios.get("http://localhost:8089/api/sancoes?sort=id,desc");
      }

    getSancao(id) {
        return axios.get("http://localhost:8089/api/sancoes/" + id);
    }

    apagarSancao(id){
        return axios.delete(`http://localhost:8089/api/sancoes/${id}`);
    }

    updateSancao(sancao){
        return axios.put("http://localhost:8089/api/sancoes/" + sancao.sacaoDTO.id, sancao);
    }

    buscarSancaoPorCpfCnpj(cpfCnpj){
        return axios.get("http://localhost:8089/api/sancoes/cpfCnpj/" + cpfCnpj);
    }

    buscarSancaoPorIdContratacao(idContratacao){
        return axios.get("http://localhost:8089/api/sancoes/idContratacao/" + idContratacao);
    }

    buscarSancaoPorIdContrato(idContrato){
        return axios.get("http://localhost:8089/api/sancoes/idContrato/" + idContrato);
    }

    buscarSancaoPorTipo(tipo){
        return axios.get("http://localhost:8089/api/sancoes/tipo/" + tipo);
    }
    






}

export default new ProcessosService();
