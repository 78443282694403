<template>
  <div>

    <body v-if="!showResultado" class="hold-transition login-page">
      <div class="login-box">
        <div class="card card-primary">
          <div class="card-header m-auto p-0 d-flex justify-content-center background-principal text-color-principal">
            <a href="/">
              <img src="../assets/logo_ssp_login.png" :alt="$store.state.app.name"
                class="img-responsive py-2 px-4 w-100" />
            </a>



          </div>
          <Loader :active="loaderActive" message="Recuperando senha..." />
          <div class="card-body" v-if="!loaderActive">

            <div class="row d-flex justify-content-center">
              <h4 class="mb-3 text-color-secundary"><b>Recuperar Senha</b></h4>
            </div>
            <form method="post" @submit.prevent="recuperarSenhaSubmit()">
              <div class="input-group mb-3">
                <input v-model="user.login" type="email" class="form-control" placeholder="Email" name="login"
                   />

                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="col-12">
                  <button type="submit" class="btn background-principal text-color-principal btn-block">
                    <i class="fa-solid fa-lock"></i>
                    Recuperar Senha
                  </button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </body>

    <ResultadoRecuperacaoSenhaComponent v-if="showResultado" :resultado="resultado" />

  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import ResultadoRecuperacaoSenhaComponent from '@/components/ResultadoRecuperacaoSenhaComponent.vue';
import RecuperarSenhaService from '@/service/RecuperarSenhaService';

export default {
  name: "EsqueciSenhaView",
  components: { ResultadoRecuperacaoSenhaComponent, Loader },
  data() {
    return {
      user: {},
      showResultado: false,
      resultado: '',
      loaderActive: false,
    };
  },
  methods: {
    alertaSenhaDiferente() {
      // eslint-disable-next-line no-undef
      Toast.fire("Preencha os dois campos igualmente!", "", "error");
    },
    alertaSenhaErrada() {
      Toast.fire("Preencha corretamente a senha", "", "error");
    },
    alertaErroCadastrar() {
      Toast.fire("Erro ao tentar redefinir a senha", "", "error");
    },
    alertaSucesso() {
      Toast.fire("Sucesso ao solicitar a recuperação de senha", "", "success");
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    recuperarSenhaSubmit() {
      this.showLoader;
      try {
        RecuperarSenhaService.recuperarSenha(this.user, "SIGMA")
          .then((response) => {
            this.resultado = response.data;
            this.showResultado = true
            this.hideLoader;
            this.alertaSucesso
          }).catch((e) => {
            this.hideLoader();
            this.alertaErroCadastrar();
          });
      }
      catch (e) {
        this.hideLoader;
        console.log("ERRO: " + e.message);
      }
    }
  }
}
</script>
