<template>
  <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Consulta SIGMA" />
    <conteudo v-role="'CONSULTA_DOCUMENTO'">
      <div class="container-fluid p-3">
        <DetalhesDocumentoConsulta v-show="isModalVisible" @closeModalDocumento="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisible"></div>
        <div class="row">
          <div class="col-md-3 pr-1">
            <FormDocumentoConsulta />
          </div>

          <div class="col-md-9 pl-1">
            <ResultadosDocumentoConsulta @showModalDocumento="showModal" />
          </div>
        </div>

      </div>

    </conteudo>

  </section>

</template>

<script>
import FormDocumentoConsulta from '@/components/consultas/documento/FormDocumentoConsulta.vue'
import DetalhesDocumentoConsulta from '../../../components/consultas/documento/DetalhesDocumentoConsulta.vue'
import ResultadosDocumentoConsulta from '../../../components/consultas/documento/ResultadosDocumentoConsulta.vue'
import { EventBus } from '../../../main.js'

export default {
  name: 'ViewConsultaDocumento',
  components: { FormDocumentoConsulta, DetalhesDocumentoConsulta, ResultadosDocumentoConsulta },

  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      EventBus.$emit('esconderBoCardComponent')
    }
  }
}
</script>
