<template>
  <div>

  </div>
</template>

<script>
import jsPDF from "jspdf";
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";

//pdf
export default {
  name: "PDFAVISOFÉRIAS",
  components: {},
  data() {
    return {
      pdfSrc: "data:application/pdf;base64,",
    };
  },
  methods: {

    updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
    },


    montarParagrafo(item) {

      const infoContent = `${item.matricula || "SEM REGISTRO"}\n${item.nome || "SEM REGISTRO"}\n${item.unidade || "SEM REGISTRO"}\n${item.cargo || "SEM REGISTRO"}`;


      const messageContent = `            LEVAMOS AO CONHECIMENTO QUE SUAS FÉRIAS RELATIVAS   AO EXERCÍCIO DE ${item.ano_exercicio}, DEVERÃO TER INÍCIO NO DIA ${this.updateZonedDateTimeField(item.data_inicio)} E TÉRMINO  NO  DIA  ${this.updateZonedDateTimeField(item.data_fim)}, CONFORME ESCALA  DE  FÉRIAS APROVADA PELA(O) ${item.unidade || "SEM REGISTRO"}.`;

      return { info: infoContent, message: messageContent };

    },


    criarNovoCabecalho(doc, pageWidth) {

      const center = pageWidth / 2;
      const image = new Image();
      image.src = require("@/assets/logo-ma-small.png");
      image.width = 20;
      image.height = 20;
      let x = (pageWidth - image.width) / 2;

      doc.addImage(image, "PNG", x, 10, 20, 20, "center");

      doc.setTextColor(0);
      doc.setFont("times", "bold");
      doc.setFontSize(12);
      doc.text("Estado do Maranhão", center, 35, null, null, "center");

      doc.setFont("times", "normal");
      doc.setFontSize(12);
      doc.text(
        "Secretaria de Estado da Segurança Pública",
        center,
        39,
        null,
        null,
        "center"
      );

      doc.text(
        "Av. dos Franceses, s/n, Bairro Vila Palmeira, CEP 65036-283, São Luís-MA",
        center,
        43,
        null,
        null,
        "center"
      );
      doc.text("Telefone: (98) 3214 3700", center, 47, null, null, "center");

      doc.setFont("times", "bold");
      doc.setFontSize(12);
      doc.text(
        "AVISO DE FÉRIAS",
        center,
        60,
        null,
        null,
        "center"
      );

    },


    emitirPdf(dados, formDados) {
      const doc = new jsPDF("p", "mm", "a4");
      const pageWidth = doc.internal.pageSize.getWidth();
      const margin = 20; // Margem de 2cm

      let isFirstPage = true;
      let i = 0;

      for (const item of dados) {

        if (!isFirstPage) {
          doc.addPage();
          i++;
        }

        isFirstPage = false;

        this.criarNovoCabecalho(doc, pageWidth);

        let cursorY = 75;
        const { info, message } = this.montarParagrafo(item);
        const infoArray = info.split("\n");

        let maxLabelWidth = 0;
        ["Matrícula:", "Nome:", "Lotação:", "Cargo:"].forEach(label => {
          maxLabelWidth = Math.max(maxLabelWidth, doc.getTextWidth(label));
        });

        doc.setFont("times", "bold").setFontSize(12);
        doc.text("Matrícula:", margin, cursorY);
        doc.text("Nome:", margin, cursorY + 5.7);
        doc.text("Lotação:", margin, cursorY + 11.4);
        doc.text("Cargo:", margin, cursorY + 17.1);

        doc.setFont("times", "normal").setFontSize(12);
        doc.text(infoArray[0], margin + maxLabelWidth + 5, cursorY);
        doc.text(infoArray[1], margin + maxLabelWidth + 5, cursorY + 5.7);
        doc.text(infoArray[2], margin + maxLabelWidth + 5, cursorY + 11.4);
        doc.text(infoArray[3], margin + maxLabelWidth + 5, cursorY + 17.1);

        cursorY += 30; 

        let linesMessage = doc.setFontSize(12).setFont("times", "normal").splitTextToSize(message, pageWidth - margin * 2);
        const numlines = linesMessage.length;
        linesMessage.forEach((element, index) => {
          cursorY += 7.5;
          if (index < numlines - 1) {
            this.justifyLine(doc, element, margin, cursorY, 170)
          } else {
            doc.text(element, margin, cursorY)
          }
        })
        cursorY += 15;



        const dataFormatada = `São Luís, ${this.formatarDataAtual()}`;
        doc.setFont("times", "normal").setFontSize(12).text(dataFormatada, pageWidth / 2, cursorY + 10, null, null, "center");

        this.inserirLinhasAssinatura(doc, dados, i, cursorY + 20, pageWidth);

      }

      doc.save("Aviso_de_Ferias.pdf");
    },



    justifyLine(doc, text, x, y, maxWidth) {
      const words = text.split(" ");
      const numWords = words.length;
      const totalWidth = doc.getTextWidth(text);
      const spaceWidth = (maxWidth - totalWidth) / (numWords - 1);

      let currentX = x;
      words.forEach((word, index) => {
        doc.text(word, currentX, y);
        currentX += doc.getTextWidth(word) + spaceWidth;

        if (index < numWords - 1) {
          doc.text(" ", currentX, y);
          currentX += doc.getTextWidth(" ");
        }
      });
    },

    formatarDataAtual() {
      const hoje = new Date();
      const opcoes = { year: 'numeric', month: 'long', day: 'numeric' };
      return hoje.toLocaleDateString('pt-BR', opcoes);
    },

    inserirLinhasAssinatura(doc, dados, i, cursorY, pageWidth) {
      //const margin = 20; 
      //let x;
      //const larguraLinha = 60;

      const alturaNome = cursorY + 20;
      const alturaCargo = cursorY + 25;

      const center = pageWidth / 2;
      doc.setFontSize(10);
      doc.setFont("times", "bold");

      console.log("Dados:", dados[i]);

      let nomeServidor = dados[i].nome || "Nome do Servidor";
      doc.text(nomeServidor, center - 40, alturaNome, { align: "center" });

      let nomeChefe = dados[i].nome_supervisor || "SUPERVISORA DE RECURSOS HUMANOS";
      doc.text(nomeChefe, center + 40, alturaNome, { align: "center" });

      let cargoServidor = "ASSINATURA DO SERVIDOR";
      doc.text(cargoServidor, center - 40, alturaCargo, { align: "center" });

      let cargoChefe = dados[i].cargo_supervidor || "Cargo do Supervisor";
      doc.text(cargoChefe, center + 40, alturaCargo, { align: "center" });

      doc.setFont("times", "normal");
    }



  },
  mounted() {

    this.$root.$on("pdfAvisodeFerias", this.emitirPdf);
  }
}
</script>
