<template>
  <div>
    <div class="row px-3">
      <div class="form-group col-lg-1">
        <label for="codigo">Código</label>
        <input
          type="text"
          class="form-control form-control-border"
          id="codigo"
          v-model="tipoDocumento.id"
          :class="{
            'is-invalid': $v.tipoDocumento.id.$error,
          }"
        />
      </div>
      <div class="form-group col-lg-4">
        <label for="nome">Nome</label>
        <input
          type="text"
          class="form-control form-control-border"
          id="nome"
          v-model="tipoDocumento.descricao"
          :class="{
            'is-invalid': $v.tipoDocumento.descricao.$error,
          }"
        />
      </div>
    </div>

    <div class="card-body">
      <button
        @click="salvarTipoDodumento"
        class="btn float-right text-color-principal background-principal ml-1"
      >
        <i class="fa-solid fa-floppy-disk"></i> Salvar
      </button>
      <button
        @click.prevent="LimparTipoDodumento"
        type="reset"
        class="btn btn-default"
      >
        <i class="fas fa-ban"></i> Limpar
      </button>
    </div>

    <TabelaTipoDocumento />

  </div>
</template>

<script>
import RHService from "@/service/RHService";
import TabelaTipoDocumento from "@/components/rh/configuracao/TabelaTipoDocumento.vue";
import { EventBus } from '@/main';

export default {
  components: {
    TabelaTipoDocumento,
  },
  data() {
    return {
      tipoDocumento: {},
    };
  },
  validations: {
    tipoDocumento: {
      descricao: {},
      id: {},
    },
  },

  created(){
    EventBus.$on("selecionartipoDocumento",(id)=>{
      this.TipoDocumento(id)
     
    })
  },

  methods: {
    salvarTipoDodumento() {
      let user = JSON.parse(localStorage.getItem("user"));
      let token = user.token;

      let tipoDocReq = {
        id: parseInt(this.tipoDocumento.id),
        descricao: this.tipoDocumento.descricao,
      };

      if (tipoDocReq.id) {
        //PUT
        RHService.editarTipoDocumento(tipoDocReq.id, tipoDocReq, token).then(
          (resp) => {
            Toast.fire("Cadastro realizado", "", "success");
          }
        );
        return;
      }

      //POST
      RHService.criarTipoDocumento(tipoDocReq, token)
        .then((resp) => {
          Toast.fire("Cadastro realizado", "", "success");
        })
        .catch((err) => {

          if (err.response.status === 409) {
            Toast.fire(
              "Já existe um tipo de documento com este nome",
              "",
              "error"
            );
          } else {
            Toast.fire("Falha ao cadastrar", "", "error");
          }
        });
    },
    LimparTipoDodumento() {
      this.tipoDocumento = {};
    },
    TipoDocumento(id){
      RHService.getTipoDodumento(id)
      .then((response)=>{
        this.tipoDocumento = response.data
      })
    }
  },
};
</script>