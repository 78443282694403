var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-orgao-idcontratacao"}},[_vm._v("id Contratacao")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cadastroLicitante.idContratacao),expression:"cadastroLicitante.idContratacao"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.cadastroLicitante.idContratacao.$error,
          },attrs:{"type":"text","name":"idContratacao","id":"cadastro-licitante-idcontratacao","data-cy":"idContratacao"},domProps:{"value":(_vm.cadastroLicitante.idContratacao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cadastroLicitante, "idContratacao", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-licitante-cpfCnpj"}},[_vm._v("Cpf/Cnpj")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cadastroLicitante.cpfCnpj),expression:"cadastroLicitante.cpfCnpj"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.cadastroLicitante.cpfCnpj.$error,
          },attrs:{"type":"text","name":"cpfCnpj","id":"cadastro-licitante-cpfCnpj","data-cy":"cpfCnpj"},domProps:{"value":(_vm.cadastroLicitante.cpfCnpj)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cadastroLicitante, "cpfCnpj", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-licitante-numero"}},[_vm._v("Número")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cadastroLicitante.numero),expression:"cadastroLicitante.numero"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.cadastroLicitante.numero.$error,
          },attrs:{"type":"text","name":"numero","id":"cadastro-licitante-numero","data-cy":"numero"},domProps:{"value":(_vm.cadastroLicitante.numero)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cadastroLicitante, "numero", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-licitante-ano"}},[_vm._v("Ano")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.cadastroLicitante.ano),expression:"cadastroLicitante.ano",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.cadastroLicitante.ano.$error,
          },attrs:{"type":"number","name":"ano","id":"cadastro-licitante-ano","data-cy":"ano"},domProps:{"value":(_vm.cadastroLicitante.ano)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cadastroLicitante, "ano", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-licitante-estrangeiro"}},[_vm._v("Estrangeiro: ")]),_c('v-select',{staticClass:"border-v-select",class:{
          'is-invalid': _vm.isSaving && _vm.$v.cadastroLicitante.estrangeiro.$error,
        },attrs:{"id":"cadastroLicitante-estrangeiro","data-cy":"estrangeiro","name":"estrangeiro","options":_vm.estrangeiro,"reduce":function (estrangeiro) { return estrangeiro.id; },"label":"descricao"},model:{value:(_vm.cadastroLicitante.estrangeiro),callback:function ($$v) {_vm.$set(_vm.cadastroLicitante, "estrangeiro", $$v)},expression:"cadastroLicitante.estrangeiro"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn text-color-principal background-principal ml-1",on:{"click":_vm.salvarLicitacao}},[_c('i',{staticClass:"far fa-envelope"}),_vm._v(" Enviar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":_vm.limparLicitacao}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Limpar ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }