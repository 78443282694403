<template>

    <div>

        <div class="card-footer">
            <div class="float-center">
                <button v-b-modal.modal-6 class="btn text-color-principal background-principal ml-1">
                <i class="fa-solid fa-pen-to-square"></i> Cadastro de Ferias
                </button>
                <h6></h6>
                <button v-b-modal.modal-7 type="reset" class="btn text-color-principal background-principal ml-1">
                <i class="fas fa-search"></i> Consulta de Ferias
                </button>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    
}
</script>