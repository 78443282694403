<template>
  <section class="content">
    <Loader :active="loaderActive" message="Carregando..." />
    <conteudo>
      <!-- <content-header title="Início" /> -->
      <content-header :title="'BEM VINDO(A), '+ usuario.nome " />
      <div class="container-fluid">
        <div class="row">

          <div class="col-md-12 ">
            <div class="card card-outline">
              
              <ImagemHome />

            </div>
          </div>

          <!-- <div class="col-md-12 ">
            <div class="card card-outline">
              <div class="background-principal card-header text-color-principal d-flex justify-content-center">
                <h3 class="text-color-primary">BEM VINDO(A), {{ usuario.nome }} </h3>
              </div>
              <ImagemHome />

            </div>
          </div> -->
          <!--COLOCAÇÃO DO COMPONENTE PARA UMA FUTURA FUNÇÃO DE NOTÍCIAS-->
          <!-- <div class="col-md-4">
            <NotificacoesHome />
          </div> -->
        </div>
      </div>
    </conteudo>

  </section>

</template>

<script>
import ImagemHome from '@/components/home/ImagemHome.vue';
// import NotificacoesHome from '@/components/home/NotificacoesHome.vue';
import Loader from '@/components/Loader.vue';
import instance from '@/service/axiosConfig';
export default {
  name: "HomeView",
  // components: { ImagemHome, Loader, NotificacoesHome },
  components: { ImagemHome, Loader },
  data() {
    return {
      loaderActive: false
    }
  },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    }
  },
  created() {
    // instance.get('teste/token/validoUp');
    this.loaderActive = true;
  },
  mounted() {
    this.loaderActive = false;
  }
}

</script>
