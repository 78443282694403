<template>
  <div class="container-fluid p-2">
    <div class="card card-primary card-tabs">
      <div class="card-header background-principal p-0 pt-1">
        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
          <li class="nav-item">
            
              <a class="nav-link active show" 
              id="custom-tabs-one-home-tab"  data-toggle="pill" href="#cadastrofuncionario" role="tab"
              ref="cadastrofuncionario" aria-controls="custom-tabs-one-home" aria-selected="true"
              >Tab 1 </a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
              id="custom-tabs-one-perfil-tab"  data-toggle="pill" href="#perfilfuncionario" role="tab"
              aria-controls="custom-tabs-one-profile" aria-selected="false" ref="perfilfuncionario"
             >Tab 2</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="custom-tabs-one-tabContent" >
          <div class="tab-pane fade active show" id="cadastrofuncionario" role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab">
             <TblAtaRegistro/>
          </div>
          <div class="tab-pane fade" id="perfilfuncionario" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
            <h1>Tab Content 2</h1>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <h1>Footer</h1>
      </div>
    </div>
  </div>
</template>
<script>
import TblAtaRegistro from "@/components/processos/TabelaAtaRegistro.vue";
export default {
  
  name: "TabsCadastoFuncionario",
  components: { TblAtaRegistro },
  data() {
    return {


    }
  },
  created() {

    
  },
  methods: {

  }
}
</script>
<style scoped>
.text-link {
  color: #fff
}

.text-link:hover {
  color: #fff
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}
</style>
