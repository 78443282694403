<template>
  <div class="modal fade show h-100 overflow-auto" id="modal-xl" style="display: block;" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl p-3" @click.stop>
      <div>
        <div class="modal-content border-rounded-detail">
          <div class="modal-header background-principal
              text-color-principal
              card-item-result">
            <h5 class="modal-title mx-auto my-0">
              {{ pessoa.nome || "N/I" }}
            </h5>
          </div>
          <loader :active="loaderActive" message="Consultando..." />
          <div v-if="pessoa" class="p-2">
            <div class="modal-body max-height-content">
              <h6 class="mt-1 mb-3"><b>DADOS BÁSICOS: </b></h6>
              <div class="row p-0">
                <div class="col-lg-2 col-md-11 col-sm-5" align="center">
                  <img v-if="pessoa.foto" v-bind:src="'data:image/jpeg;base64,' + pessoa.foto" alt="user-avatar"
                    class="img img-thumbnail mx-0 px-0 img-card-measure" />
                  <img v-else src="https://webc.ssp.ma.gov.br/imagens/elementos/icone-imagem-indisponivel2.png"
                    alt="user-avatar" class="img img-thumbnail mx-0 px-0 img-card-measure" />
                </div>

                <div class="col-lg-5 col-md-11 col-sm-5">
                  <p class="medium mb-0"><b>Nome: </b> {{ pessoa.nome }}</p>
                  <p class="medium mb-0">
                    <b>Data de nascimento: </b> {{ pessoa.nascimento || "N/I" }}
                  </p>
                  <p class="medium mb-0">
                    <b>CPF: </b> {{ pessoa.cpf || "N/I" }}
                  </p>
                  <p class="medium mb-0">
                    <b>Idade: </b> {{ pessoa.idade || "N/I" }}
                  </p>
                  <p class="medium mb-0">
                    <b>Apelido: </b> {{ pessoa.apelido || "N/I" }}
                  </p>
                  <p class="medium mb-0">
                    <b>Profissão: </b>
                    {{ pessoa.profissao || "N/I" }}
                  </p>
                </div>
                <div class="col-lg-5 col-md-11 col-sm-11">
                  <div class="">
                    <p class="medium mb-0">
                      <b>Localidade: </b>
                      {{ pessoa.estado }} {{ pessoa.cidade }}
                    </p>

                    <p class="medium mb-0">
                      <b>Mãe: </b> {{ pessoa.mae || "N/I" }}
                    </p>
                    <p class="medium mb-0">
                      <b>Pai: </b> {{ pessoa.pai || "N/I" }}
                    </p>
                    <p class="medium mb-0">
                      <b>RG: </b> {{ pessoa.rg || "N/I" }}
                    </p>
                    <p class="medium mb-0">
                      <b>Endereco: </b> {{ pessoa.endereco || "N/I" }}
                    </p>
                    <p class="medium mb-0">
                      <b>Morto:</b>
                      {{ pessoa.morto == true ? "SIM" : "NÃO" }}
                    </p>
                  </div>
                </div>
              </div>
              <hr />

              <h6 class="mt-4 mb-3">
                <b>OCORRÊNCIAS: </b>

                {{
                    pessoa.bos == false
                      ? "Sem ocorrências relacionadas a esta pessoa."
                      : ""
                }}
              </h6>
              <div class="row">
                <div class="col-12 m-0 d-flex flex-row">
                  <ListaBo :bos="bos" />
                  <BoCardComponent />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('closeModalPessoa')">
              <i class="fa-solid fa-x"></i>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../main.js'
import LocalIpService from "@/service/LocalIpService";
import ConsultaService from '../../../service/ConsultaService'
import Loader from '../../Loader.vue'
import BoCardComponent from '../BoCardComponent.vue'
import ListaBo from './ListaBo.vue'
import axios from "axios";

export default {
  name: 'DetalhesConsultaPessoa',
  components: {
    Loader,
    ListaBo,
    BoCardComponent,
    ipUsuario:null
  },
  data() {
    return {
      pessoa: false,
      loaderActive: true,
      bos: [],
      ipUsuario:null
    }
  },
  created() {
    LocalIpService.getLocalIp()
      .then((response) => {
        this.ipUsuario = response.data.ip;
      })
      .catch((error) => {
        console.log(error);
      });

    EventBus.$on('consultaDetalhePessoa', (id) => {
      this.consultarPessoa(id)
    })
  },
  methods: {
    
    buscarBoCompleto(id) {
      ConsultaService.buscarBoDetalhe(id,this.ipUsuario).then((r) =>
        console.log(r.data.conteudo)
      )
    },
    showLoader() {
      this.pessoa = false
      this.loaderActive = true
    },
    hideLoader() {
      this.loaderActive = false
    },
    consultarPessoa(id) {
      this.showLoader()
      try {
        ConsultaService.detalharPessoa(id,this.ipUsuario)
          .then((response) => {
            this.pessoa = response.data
            this.bos = this.pessoa.bos.filter((bo) => bo.sigiloso == false)

            this.hideLoader()
          })
          .catch((e) => this.alertaFalhaDetalharPessoa())
      } catch (e) {
        this.hideLoader()
        this.alertaFalhaDetalharPessoa()
      }
    },
    alertaFalhaDetalharPessoa() {
      Toast.fire("Falha ao detalhar pessoa", "", "error");
    },
  }
}
</script>

<style scoped>
.img-detail-measure {
  padding-left: 10px;
  margin: 0;
  max-height: 150px
}
</style>
