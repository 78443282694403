<template>
    <div>
      <!-- IFRAME UTIL APENAS NA FASE DE DESENVOLVIMENTO E MODELAÇÃO DO PDF, PARA EXIBIR MODIFICAÇÕES
        SEM A NECESSIDADE DE BAIXAR O PDF (TIRAR COMENTARIO DA ULTIMAS LINHAS DO METODO emitirPdf para isso)-->
      <!-- <iframe
        class="preview-pane"
        style="width: 1000px; height: 95vh; margin: 0px; padding: 0px"
        :src="pdfSrc + '#zoom=100'"
      >
      </iframe> -->
    </div>
  </template>



<script>

import jsPDF from "jspdf";
import { EventBus } from "@/main";

export default {
  name: "PDFVidaFuncional",
  components: {},
  props: {},
  data() {
    return {
      pdfSrc: "data:application/pdf;base64,",
    };
  },



  methods: {

    montarParagrafo(textoDados) {
      let texto = textoDados.informacao;
      texto += "\n\n";

      return texto;
    },
    async emitirPdf() {
      // nova instancia de um objeto jspdf (p(modo retrato),mm(medida de unidade, "pixels" do documento),a4(tamanho padrao da folha))
      let doc = new jsPDF("p", "mm", "a4");
      const pageWidth = doc.internal.pageSize.getWidth();
      const center = pageWidth / 2;

      //adicionando nova imagem(logo MA) no cabeçalho do pdf
      const image = new Image();
      image.src = require("@/assets/LOGO-PC-site.png");
      image.width = 20;
      image.height = 20;
      let x = (pageWidth - image.width) / 2;
      doc.addImage(image, "PNG", x, 10, 20, 20, "center");
      //----------------------------------------------------
      //seta a cor do texto para preto
      doc.setTextColor(0);
      //seta a font e tipo do texto, para pegar a lista de fonts disponiveis: console.log(doc.getFontSize())
      doc.setFont("times", "bold");
      //seta o tamanho da fonte
      doc.setFontSize(12);
      //novo texto("texto",posição X(horizontal) inicial, posição Y(vertical) inicial, "center"(para centralizar))
      doc.text("Estado do Maranhão", center, 35, null, null, "center");
      doc.setFont("times", "normal");
      doc.setFontSize(9);
      doc.text(
        "Secretaria de Estado da Segurança Pública",
        center,
        39,
        null,
        null,
        "center"
      );

      doc.text(
        "Polícia Civil",
        center,
        43,
        null,
        null,
        "center"
      );

      doc.text(
        "Delegacia Geral",
        center,
        47,
        null,
        null,
        "center"
      );
      //doc.text("Telefone: (98) 3214 3700", center, 47, null, null, "center");


      doc.save(` INFORMATIVO`); 
    }



  },

   mounted() {
      //this.emitirPdf();
      //this.$root.$on("pdfAnotacao", this.emitirPdf);
   },

}

</script>