<template>
    <div>
  
    </div>
  </template>
  
  <script>

import jsPDF from "jspdf";
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
  
//pdf
  export default {
    name: "PDFTRANSFERENCIA",
    components: {},
    data() {
      return {
        pdfSrc: "data:application/pdf;base64,",
      };
    },
    methods: {

      updateZonedDateTimeField(field) {
         if(field == null){

            return "Sem Registro"
        }
        else{
            return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
        }
      },

      montarParagrafo(item) {
        // Monta o texto do parágrafo aqui
        const infoTransferencia = `
          Matrícula: ${item.matricula || "-"}
          Nome: ${item.nome || "-"}
          Exercício: ${item.ano_exercicio || "-"}
          Mês/Ano: ${item.mes || "-"} / ${item.ano || "-"}
          Pedido: ${this.updateZonedDateTimeField(item.data_inicio_alteracao) || "Sem Registro"} - ${this.updateZonedDateTimeField(item.data_fim_alteracao) || "Sem Registro"}
          Atual: ${this.updateZonedDateTimeField(item.data_inicio) || "Sem Registro"} - ${this.updateZonedDateTimeField(item.data_fim) || "Sem Registro"}
          Portaria: ${item.portaria_numero || "-"}
          Novo Mês/Ano: ${item.mes_alteracao || "-"} / ${item.ano_alteracao || "-"}
          Transferência: ${this.updateZonedDateTimeField(item.data_inicio_alteracao) || "Sem Registro"} - ${this.updateZonedDateTimeField(item.data_fim_alteracao) || "Sem Registro"}
        `;

    console.log(item);

    //console.log(item.data_inicio_alteracao)

    return infoTransferencia.trim(); 

       
      },
  
  
      criarNovoCabecalho(doc, pageWidth){
  
        const center = pageWidth / 2;
        const image = new Image();
        image.src = require("@/assets/logo-ma-small.png");
        image.width = 20;
        image.height = 20;
        let x = (pageWidth - image.width) / 2;
  
        doc.addImage(image, "PNG", x, 10, 20, 20, "center");
  
        console.log(1);
        doc.setTextColor(0);
        doc.setFont("times", "bold");
        doc.setFontSize(12);
        doc.text("Estado do Maranhão", center, 35, null, null, "center");
  
        doc.setFont("times", "normal");
        doc.setFontSize(9);
        doc.text(
          "Secretaria de Estado da Segurança Pública",
          center,
          39,
          null,
          null,
          "center"
        );
  
        doc.text(
          "Av. dos Franceses, s/n, Bairro Vila Palmeira, CEP 65036-283, São Luís-MA",
          center,
          43,
          null,
          null,
          "center"
        );
        doc.text("Telefone: (98) 3214 3700", center, 47, null, null, "center");
  
        doc.setFont("times", "bold");
        doc.text(
          "TRANSFERÊNCIAS DE FÉRIAS",
          center,
          60,
          null,
          null,
          "center"
        );
  
      },
  
  
      async emitirPdf(dados,formDados ) {
  
        const doc = new jsPDF("p", "mm", "a4");
        const pageWidth = doc.internal.pageSize.getWidth();
        const margin = 20;
  
        let isFirstPage = true;
  
        for (const item of dados) {
        
          if (!isFirstPage) {
            doc.addPage();
          }
  
          isFirstPage = false;
  
          this.criarNovoCabecalho(doc, pageWidth);
  
          let cursorY = 70;
  
     
          const paragrafo = this.montarParagrafo(item);
          console.log(paragrafo);
          //console.log(item);
          const lines = doc.splitTextToSize(paragrafo, pageWidth - margin * 2);
          doc.setFont("times", "normal").setFontSize(10).text(lines, margin, cursorY);
  
          cursorY += lines.length * 10 + 15;
      
        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
       
        
        doc.save("Transferencia_de_Ferias.pdf");
  
      },
  
  
      
    },
    mounted() {
      
      this.$root.$on("pdfTransferenciadeFerias", this.emitirPdf); 
    }
  }
  </script>
  
  