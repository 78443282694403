// import { ApiGateway } from "@/main";
// import authHeader from "@/service/auth/auth-header";
import store from "@/store";
// import axios from "axios";
import instance from "./axiosConfig";

class CadastroFuncionarioService {
  constructor() {
    this.usuarioLogado =
      store.state.auth.user == null ? null : store.state.auth.user.usuario;
  }

  inserirCadastrante(funcionario) {
    funcionario.funcionario = { id: this.usuarioLogado.idFuncionario };
  }

  cadastroFuncionario(funcionario) {
    this.inserirCadastrante(funcionario);
    return instance.post("/funcionario-service/api/funcionarios", funcionario);
  }

  cadastroDependente(dependente){
    return instance.post("/funcionario-service/api/dependentes", dependente);
  }

  listarDependenteFuncionario(funcionarioId){

    return instance.get(`/funcionario-service/api/dependentes/funcionario/${funcionarioId}`);
    
  }

  fichaCadastral(funcionarioId){
    return instance.get(`/funcionario-service/api/funcionario/ficha-cadastral/${funcionarioId}`);
  }
  
  atualizarFuncionario(funcionario) {
    return instance.patch("/funcionario-service/api/funcionarios/" + funcionario.id, funcionario);
  }
  buscarFuncionarioPorNome = async (nome) => {
    let response = await instance.get(
      `/funcionario-service/api/funcionarios/busca-nome?nome=${nome}`
    );

    return response.data;
  };
  buscarFuncionarioPorId(id) {
    return instance.get(`/funcionario-service/api/funcionarios/${id}`);
  };
  cadastroFuncionarioPerfil(idFuncionario, perfis) {
    return instance.post(
      `/funcionario-service/api/funcionario_perfil?idFuncionario=${idFuncionario}`,
      perfis
    );
  }
}
export default new CadastroFuncionarioService();