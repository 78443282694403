var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-idContratacao"}},[_vm._v("Id Contratação")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sancao.idContratacao),expression:"sancao.idContratacao"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.sancao.idContratacao.$error,
          },attrs:{"type":"text","name":"idContratacao","id":"sancao-idContratacao","data-cy":"idContratacao"},domProps:{"value":(_vm.sancao.idContratacao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sancao, "idContratacao", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-idContrato"}},[_vm._v("Id Contrato")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sancao.idContrato),expression:"sancao.idContrato"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.sancao.idContrato.$error,
          },attrs:{"type":"text","name":"idContrato","id":"sancao-idContrato","data-cy":"idContrato"},domProps:{"value":(_vm.sancao.idContrato)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sancao, "idContrato", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-cpfCnpj"}},[_vm._v("Cpf Cnpj")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sancao.cpfCnpj),expression:"sancao.cpfCnpj"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.sancao.cpfCnpj.$error,
          },attrs:{"type":"text","name":"cpfCnpj","id":"sancao-cpfCnpj","data-cy":"cpfCnpj"},domProps:{"value":(_vm.sancao.cpfCnpj)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sancao, "cpfCnpj", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-tipo"}},[_vm._v("Tipo")]),_c('v-select',{staticClass:"border-v-select",class:{
          'is-invalid': _vm.isSaving && _vm.$v.sancao.tipo.$error,
        },attrs:{"id":"sancao-tipo","data-cy":"tipo","name":"tipo","options":_vm.tipo,"reduce":function (tipo) { return tipo.id; },"label":"descricao"},model:{value:(_vm.sancao.tipo),callback:function ($$v) {_vm.$set(_vm.sancao, "tipo", $$v)},expression:"sancao.tipo"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-numeroLei"}},[_vm._v("Número Lei")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.sancao.numeroLei),expression:"sancao.numeroLei",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.sancao.numeroLei.$error,
          },attrs:{"type":"number","name":"numeroLei","id":"sancao-numeroLei","data-cy":"numeroLei"},domProps:{"value":(_vm.sancao.numeroLei)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sancao, "numeroLei", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-anoLei"}},[_vm._v("Ano Lei")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.sancao.anoLei),expression:"sancao.anoLei",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.sancao.anoLei.$error,
          },attrs:{"type":"number","name":"anoLei","id":"sancao-anoLei","data-cy":"anoLei"},domProps:{"value":(_vm.sancao.anoLei)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sancao, "anoLei", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-dataNotificacao"}},[_vm._v("Data Notificação")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.sancao.dataNotificacao.$error,
                    },attrs:{"type":"date","id":"sancao-dataNotificacao","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.sancao.dataNotificacao)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataNotificacao', $event)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-dataInicio"}},[_vm._v("Data Inicio")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.sancao.dataInicio.$error,
                    },attrs:{"type":"date","id":"sancao-dataInicio","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.sancao.dataInicio)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataInicio', $event)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-dataFim"}},[_vm._v("Data Fim")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.sancao.dataFim.$error,
                    },attrs:{"type":"date","id":"sancao-dataFim","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.sancao.dataFim)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataFim', $event)}}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"sancao-amplitude"}},[_vm._v("Amplitude")]),_c('v-select',{staticClass:"border-v-select",class:{
          'is-invalid': _vm.isSaving && _vm.$v.sancao.amplitude.$error,
        },attrs:{"id":"sancao-amplitude","data-cy":"amplitude","name":"amplitude","options":_vm.amplitude,"reduce":function (amplitude) { return amplitude.id; },"label":"descricao"},model:{value:(_vm.sancao.amplitude),callback:function ($$v) {_vm.$set(_vm.sancao, "amplitude", $$v)},expression:"sancao.amplitude"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn text-color-principal background-principal ml-1",on:{"click":_vm.salvarSancao}},[_c('i',{staticClass:"far fa-envelope"}),_vm._v(" Enviar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":_vm.limparSancao}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Limpar ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }