<template>
  <section class="content">
    <conteudo>
      <content-header title="Unidades" />
      <div class="container-fluid p-3" v-on="isModalVisible ? { click: closeModal } : {}">
        <DetalhesUnidade v-show="isModalVisible" @closeModalUnidade="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisible"></div>
        <VisualizacaoUnidade />
      </div>
    </conteudo>
  </section>
</template>
<script>

import DetalhesUnidade from '@/components/administracao/unidade/DetalhesUnidade.vue';
import VisualizacaoUnidade from '@/components/administracao/unidade/VisualizacaoUnidade.vue';
import { EventBus } from '@/main';
export default {
  name: "ViewUnidade",
  components: { VisualizacaoUnidade, DetalhesUnidade },
  data() {
    return {
      isModalVisible: false,
    }
  },
  created() {
    EventBus.$on('showModalUnidade', this.showModal)
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      EventBus.$emit('resetarModalUnidade', null);
      EventBus.$emit('recarregarUnidades', null)
    }
  },

}
</script>
