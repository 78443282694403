<template>
  <li class="nav-item ">
    <router-link class="nav-link sidebar-item-search" :to="link" :class="{ active: isActive() }" @click.prevent="teste">
      <i class="nav-icon" :class="icon" v-if="icon"></i>
      <p>
        <slot />
      </p>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null
    },
    link: {
      type: [String, Object],
      default: null
    }
  },
  methods: {
    isActive() {
      if (typeof this.link === 'object') {
        return this.$route.name === this.link.name
      } else {
        return this.$route.path === this.link
      }
    },
    teste() {

      console.log('teste');

    }
  }

}
</script>

<style></style>
