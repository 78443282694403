<template>
  <div>
    <div class="row">
      <div class="col-lg-10 col-sm-12">
        <div class="row">
          <div class="form-group col-lg-3">
            <label for="cpf">CPF</label>
            <input
              @input="buscarPessoaPorCpf($event)"
              ref="cpfRef"
              type="text"
              :class="
                'form-control form-control-border border-width-2' +
                (this.pessoa.id ? ' disabled' : '')
              "
              id="buscarCPF"
              placeholder="xxx.xxx.xxx-xx"
              v-model="pessoa.cpf"
              v-mask="'###.###.###-##'"
            />
          </div>

          <div class="form-group col-lg-6">
            <label for="nome">Nome *</label>
            <input
              ref="nomeRef"
              type="text"
              class="' form-control form-control-border border-width-2 '"
              :class="{
                'is-invalid': $v.pessoa.nome.$error,
                disabled: !!pessoa.id,
              }"
              id="nome"
              placeholder="Nome completo"
              v-model="pessoa.nome"
              @blur="validaExistenciaPessoaPorNomeMaeEDataNascimento"
              @input="
                (event) =>
                  (pessoa.nome = event.target.value
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toUpperCase())
              "
            />
            <div v-if="!$v.pessoa.nome.required" class="invalid-feedback">
              Este campo é obrigatório
            </div>
          </div>
          <div class="form-group col-lg-3">
            <label for="dataNascimento">Data de nascimento *</label>
            <input
              @blur="validaExistenciaPessoaPorNomeMaeEDataNascimento"
              type="date"
              class="form-control form-control-border border-width-2"
              :class="{ 'is-invalid': $v.pessoa.dataNascimento.$error }"
              id="dataNascimento"
              placeholder="dd/mm/aaaa"
              v-model="pessoa.dataNascimento"
            />
            <div
              v-if="!$v.pessoa.dataNascimento.required"
              class="invalid-feedback"
            >
              Este campo é obrigatório
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-5">
            <label for="nomemae">Nome da mãe *</label>
            <input
              type="text"
              @blur="validaExistenciaPessoaPorNomeMaeEDataNascimento"
              class="form-control form-control-border border-width-2"
              :class="{ 'is-invalid': $v.pessoa.mae.$error }"
              v-model="pessoa.mae"
              @input="
                (event) =>
                  (pessoa.mae = event.target.value
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toUpperCase())
              "
              id="nomemae"
              placeholder="Nome da mãe"
            />
            <div v-if="!$v.pessoa.mae.required" class="invalid-feedback">
              Este campo é obrigatório
            </div>
          </div>

          <div class="form-group col-lg-4">
            <label for="nomeSocial">Nome Social</label>
            <input
              type="text"
              class="form-control form-control-border border-width-2"
              @input="
                (event) =>
                  (pessoa.nomeSocial = event.target.value
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toUpperCase())
              "
              id="nomeSocial"
              placeholder=""
              v-model="pessoa.nomeSocial"
            />
          </div>
          <div class="form-group col-lg-3">
            <label for="orientacaoSexual">Orientação sexual</label>
            <v-select
              class="border-v-select mt-1"
              id="orientacaoSexual"
              v-model="pessoa.orientacaoSexual"
              :options="orientacoes"
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-5">
            <label for="pai">Nome do pai</label>
            <input
              type="text"
              class="form-control form-control-border border-width-2"
              id="pai"
              placeholder=""
              v-model="pessoa.pai"
              @input="
                (event) =>
                  (pessoa.pai = event.target.value
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toUpperCase())
              "
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="sexo">Sexo *</label>
            <v-select
              class="border-v-select mt-1"
              :class="{
                'is-invalid': $v.pessoa.sexo.$error,
              }"
              id="sexo"
              v-model="pessoa.sexo"
              :options="sexos"
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
            <div v-if="!$v.pessoa.sexo.required" class="invalid-feedback">
              Este campo é obrigatório
            </div>
          </div>

          <div class="form-group col-lg-3">
            <label for="profissao">Profissão</label>
            <v-select
              class="border-v-select mt-1"
              id="profissao"
              v-model="pessoa.profissao"
              :options="profissoes"
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>

          <div class="form-group col-lg-2">
            <label for="bairro">Estado civil</label>
            <v-select
              class="border-v-select mt-1"
              id="estadoCivil"
              v-model="pessoa.estadoCivil"
              :options="estadosCivis"
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-sm-12 col-md-6">
        <CameraComponent ref="cameraRef" />
      </div>
      <div class="col-12">
        <div class="row">
          <div class="form-group col-lg-3">
            <label for="escolaridade">Escolaridade</label>
            <v-select
              class="border-v-select"
              id="escolaridade"
              v-model="pessoa.escolaridade"
              :options="escolaridades"
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
          <div class="form-group col-lg-2">
            <label for="pais">País de nascimento</label>
            <v-select
              class="form-control form-control-border border-width-2"
              id="pais"
              v-model="pessoa.pais"
              :options="paises" 
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>

          <div class="form-group col-lg-3">
            <label for="estado">Estado de nascimento</label>
            <v-select
              class="form-control form-control-border border-width-2"
              id="estado"
              v-model="pessoa.estado"
              :options="estados" 
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
          <div class="form-group col-lg-4">
            <label for="cidade">Cidade de nascimento</label>
            <v-select
              class="form-control form-control-border border-width-2"
              id="cidade"
              v-model="pessoa.cidade"
              :options="cidades" 
              label="descricao"
            >
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-12">
        <hr />
        <!-- <button class="btn background-principal text-color-principal" type="button" @click="abrirModal">
          <i class="fas fa-search"></i>
          Buscar pessoa
        </button> -->
        <b-button
          v-b-modal.modal-1
          class="btn background-principal text-color-principal"
          ><i class="fas fa-search"></i> Buscar pessoa</b-button
        >
        <button
          class="btn background-principal text-color-principal"
          @click.prevent="retornarViewFuncionario"
          v-if="this.$route.name.includes('cadastrofuncionario') ? true : false"
        >
          <i class="fa-solid fa-clipboard"></i>
          Cadastrar Funcionário
        </button>

        <button
          v-if="!pessoa.id"
          class="btn btn-primary float-right"
          type="submit"
          @click="salvarNovaPessoa"
        >
          <i class="fa-solid fa-floppy-disk"></i>
          Salvar dados básicos
        </button>

        <button
          v-else
          class="btn btn-warning float-right"
          @click.prevent="atualizarPessoa"
        >
          <i class="fas fa-check mr-1"></i> Atualizar
        </button>

        <button @click="limparCampos" class="btn btn-default mr-2 float-right">
          <i class="fas fa-ban"></i> Limpar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CameraComponent from "@/components/visita/Camera.vue";
import { EventBus } from "@/main";
import CadastroPessoaService from "@/service/CadastroPessoaService";
import ConsultaListasService from "@/service/ConsultaListasService";
import { required } from "vuelidate/lib/validators";

import { mapState } from 'vuex';

export default {
  name: "DadosBasicosCadastroPessoa",
  components: {
    CameraComponent,
  },
  computed: {
    ...mapState(['idPessoaFluxoCadastroPessoa']),
    ...mapState(['cadastroPessoaExterno']),
    ...mapState(['fluxo']),
  },
  created() {
    EventBus.$on("fotoCameraTirada", this.adicionarFotoAPessoa);
    EventBus.$on("fotoUploadCarregada", this.adicionarFotoAPessoa);
    // EventBus.$on("preencherDadosBasicos", (pessoa) => {
    //   this.preencherDadosBasicos(pessoa);
    // });


    // this.preencherDadosBasicos(idPessoaFluxoCadastroPessoa)
    // this.pessoa = this.idPessoaFluxoCadastroPessoa;

    this.preencherListasObj({
      profissoes: "profissao",
      paises: "pais",
      estados: "estado",
    });
  },
  mounted() {
    if (
      this.$route.params.hasOwnProperty("cpfFluxoCadastroPessoaAndFuncionario")
    ) {
      let cpf = this.$route.params.cpfFluxoCadastroPessoaAndFuncionario;
      // alert(cpf);
      this.pessoa.cpf = cpf;
    }
    if (this.$route.params.hasOwnProperty("cpfFluxoCadastroPessoaAndVisita")) {
      let cpf = this.$route.params.cpfFluxoCadastroPessoaAndVisita;
      this.pessoa.cpf = cpf;
    }
  },
  props: ['pessoaEdicao'],
  data() {
    return {
      pessoaCompleta: null,
      acao: "criacao",
      pessoa: null,
      // pessoa: {
      //   fotos: [],
      //   pais: { id: 1, descricao: "Brasil" },
      //   estado: null,
      //   cidade: null,
      // },
      orientacoes: ["HETERO", "HOMOSSEXUAL", "BISSEXUAL", "OUTRA"],
      profissoes: [],
      estadosCivis: [
        "SOLTEIRO",
        "CASADO",
        "SEPARADO",
        "CONVIVENTE",
        "DIVORCIADO",
        "UNIAO_ESTAVEL",
      ],
      escolaridades: [
        { id: "SEM_INSTRUCAO", descricao: "Sem instrução" },
        { id: "ENS_FUND_INCOMPLETO", descricao: "Fundamental Incompleto" },
        { id: "ENS_FUND_COMPLETO", descricao: "Fundamental Completo" },
        { id: "ENS_MED_INCOMPLETO", descricao: "Ens. Médio Incompleto" },
        { id: "ENS_MED_COMPLETO", descricao: "Ens. Médio Completo" },
        { id: "ENS_SUPERIOR_INCOMPLETO", descricao: "Ens. Sup. InCompleto" },
        { id: "ENS_SUPERIOR_COMPLETO", descricao: "Ens. Sup. Completo" },
        { id: "POS_GRADUACAO", descricao: "Pós Graduação" },
      ],
      sexos: [
        { id: "M", descricao: "Masculino" },
        { id: "F", descricao: "Feminino" },
        { id: "NI", descricao: "Não informado" },
      ],
      paises: [],
      estados: [],
      cidades: [],
      tirarFoto: false,
      dispositoEncontrado: true,

      imageBase64: null,
    };
  },
  watch: {
    "pessoa.estado"() {
      if (this.pessoa.estado) {
        this.buscarCidadesDoEstado(this.pessoa.estado.id);
      }
    },
    pessoaEdicao:{
      immediate: true,
      handler(newValue) {
        // this.pessoa = newValue;
        this.preencherDadosBasicos(newValue);
      }
    }
  },
  methods: {
    validaExistenciaPessoaPorNomeMaeEDataNascimento() {
      if (this.acao !== "criacao") {
        return;
      }
      if (this.pessoa.nome && this.pessoa.mae && this.pessoa.dataNascimento) {
        CadastroPessoaService.consultarExistenciaPessoaByParameters(
          this.pessoa.nome,
          this.pessoa.mae,
          this.pessoa.dataNascimento
        )
          .then((r) => {
            if (r.data) {
              // deseja editar?
              this.$swal
                .fire({
                  title: "Confirmação de ação",
                  html: "<div >Esta pessoa já foi cadastrada antes. Deseja editá-la?</div>",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Editar",
                  cancelButtonText: "Cancelar",
                  showLoaderOnConfirm: true,
                  reverseButtons: false,
                  allowOutsideClick: () => !Swal.isLoading(),
                })
                .then((resultado) => {
                  if (resultado.isConfirmed) {
                    this.buscarPessoaPorId(r.data);
                    CadastroPessoaService.buscarPessoaCompletaPorIdSemPromisse(
                      r.data
                    ).then((res) => {
                      this.pessoaCompleta = res.data;
                      EventBus.$emit(
                        "dadosBasicosBuscouPessoa",
                        this.pessoaCompleta
                      );
                    });
                    this.acao = "edicao";
                  } else {
                    this.limparCampos();
                  }
                });
            }
          })
          .catch((e) => console.log(e));
      }
    },
    abrirModal() {
      EventBus.$emit("abrirModalCadastrarPessoa");
    },
    limparCampos() {
      (this.pessoa = {
        id: undefined,
        fotos: [],
        pais: { id: 1, descricao: "Brasil" },
        estado: null,
        cidade: null,
      }),
        EventBus.$emit("limparDadosCamera");
      EventBus.$emit("bloquearAbas", true);
      this.acao = "criacao";
    },
    tabAtiva(tabName) {
      const tabAtiva = this.$refs[tabName].classList.contains("active");
      return tabAtiva;
    },
    formValido() {
      if (
        true ||
        this.endereco.estado ||
        this.endereco.pais ||
        this.endereco.bairro ||
        this.endereco.logradouro
      ) {
        return true;
      }
    },
    retornarViewFuncionario() {
      if (this.pessoa.id !== undefined) {
        EventBus.$emit("fecharModalEPesquisar", this.pessoa.id);
        EventBus.$emit("fecharViewCadastrarPessoa");
      } else {
        EventBus.$emit("fecharViewCadastrarPessoa");
      }
    },

    buscarPessoaPorCpf(event) {
      let cpf = event.target.value;
      const TAMANHO_CPF_MASCARADO = 14;
      if (cpf.length == TAMANHO_CPF_MASCARADO) {
        CadastroPessoaService.buscarPessoaCompletaPorCPF(cpf).then(
          (responsePessoaComDados) => {
            if (responsePessoaComDados) {
              // deseja editar?
              this.$swal
                .fire({
                  title: "Confirmação de ação",
                  html: "<div >Esta pessoa já foi cadastrada antes. Deseja editá-la?</div>",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Editar",
                  cancelButtonText: "Cancelar",
                  showLoaderOnConfirm: true,
                  reverseButtons: false,
                  allowOutsideClick: () => !Swal.isLoading(),
                })
                .then((resultado) => {
                  if (resultado.isConfirmed) {
                    this.preencherDadosBasicos(responsePessoaComDados.data);
                    EventBus.$emit(
                      "dadosBasicosBuscouPessoa",
                      responsePessoaComDados.data
                    );
                  } else {
                    this.limparCampos();
                  }
                });
            }
          }
        );
      }
    },
    nenhumCampoPreenchido() {
      let pessoa = this.pessoa;
      let nomesVazios =
        pessoa.nome == "" &&
        pessoa.nomeMae == "" &&
        pessoa.dataNascimento == "" &&
        pessoa.sexo == "";
      if (nomesVazios) {
        return true;
      }

      return false;
    },

    atualizarPessoa() {
      if (this.pessoa.id) {
        console.log(this.pessoa);
        this.salvarAlteracoesDadosBasicos();
      }
    },
    salvarAlteracoesDadosBasicos() {
      this.pessoa.escolaridade = this.pessoa.escolaridade?.id;
      // this.pessoa.sexo = this.pessoa.sexo?.id;
      this.$v.pessoa.$touch();
      if (!this.$v.pessoa.$invalid) {
        CadastroPessoaService.atualizarPessoaDadosBasicos(
          this.pessoa.id,
          this.pessoa
        )
          .then((r) => {
            if (r.status == 200) {
              // this.proximaAba();
              Toast.fire("Atualização realizado com sucesso!", "", "success");
            }
          })
          .catch((e) => {
            Toast.fire(
              "Erro ao atualizar cadastro: " + e.mensagem,
              "",
              "error"
            );
          });
      } else {
        Toast.fire("Campos incompletos!", "", "error");
      }
    },

    salvarNovaPessoa() {
      this.pessoa.id = null;
      if (this.nenhumCampoPreenchido()) {
        Toast.fire("Nenhum dos campos foi preenchido!", "", "error");
        return;
      }
      this.cadastrarDadosBasicos();
    },
    cadastrarDadosBasicos() {
      let params = { ...this.pessoa };
      params.escolaridade = params.escolaridade?.id;
      params.sexo = params.sexo?.id;
      params.desconhecido = false;
      params.documentos = [
        { numero: this.pessoa.cpf, tipoDocumento: { id: 2, descricao: "CPF" } },
      ];
      // console.info("Pessoa a ser salva: ", this.pessoa);
      this.$v.pessoa.$touch();
      if (!this.$v.pessoa.$invalid) {
        CadastroPessoaService.cadastrarPessoaDadosBasicos(params)
          .then((r) => {
            if (r.status == 201) {
              console.info("Salvou com sucesso, id=: ", r.data.id);
              this.buscarPessoaPorId(r.data.id);
              // EventBus.$emit("cadastrarPessoaDadosBasicos", r.data);
              this.$store.commit("setIdPessoaFluxoCadastroPessoa", r.data);
              Toast.fire(
                `Cadastro de ${r.data.nome} realizado com sucesso!`,
                "",
                "success"
              );

              if(this.cadastroPessoaExterno){

                if (this.fluxo == "cadastrovisita") {
                  this.$store.commit("setResultadosBuscaPessoaVisita", r.data);
                } else {
                  this.$store.commit("pessoaFuncionario", r.data.id);
                  this.$store.commit("funcionarioCadastrado", 0);
                  this.$router.push({ name: "administracaocadastrofuncionario" });
                }
                this.$store.commit("setCadastroPessoaExterno", false);
                this.$router.push(this.fluxo);
              }

              // if (this.$store.state.fluxoCadastroPessoaAndVisita) {
              //   this.$store.commit(
              //     "setIdPessoaFluxoCadastroPessoaAndVisita",
              //     r.data.id
              //   );
              //   this.$router.push("/cadastrovisita");
              // }
              // if (this.$store.state.fluxoCadastroPessoaAndFuncionario) {
              //   this.$store.commit("fluxoCadastroPessoaAndFuncionario", false);
              //   this.$router.push({
              //     name: "administracaocadastrofuncionario",
              //     params: {
              //       idPessoaFluxoCadastroPessoaAndFuncionario: r.data.id,
              //     },
              //   });
              //   // this.$store.commit(
              //   //   "setIdPessoaFluxoCadastroPessoaAndFuncionario",
              //   //   r.data.id
              //   // );
              //   // this.$router.push("/cadastrofuncionario");
              // }
            }
          })
          .catch((e) => {
            console.info("Erro ao salvar pessoa, exception: ", e);
            Toast.fire("Erro: " + e.message, "", "error");
          });
      } else {
        Toast.fire("Campos incompletos!", "", "error");
      }
    },

    buscarPessoaPorId(id) {
      CadastroPessoaService.buscarPessoaCompletaPorId(id).then((r) => {
        if (r) {
          this.preencherDadosBasicos(r);
        }
      });
    },
    preencherDadosBasicos(pessoa) {
      this.acao = "edicao";
      this.pessoa = pessoa;
      if (pessoa.fotos && pessoa.fotos.length > 0) {
        const ultimaFotoObj = pessoa.fotos.reduce(function (prev, current) {
          return prev.id > current.id ? prev : current;
        });

        EventBus.$emit("preencherCampoFoto", ultimaFotoObj);
      }

      if (pessoa.documentos && pessoa.documentos.length > 0) {
        const doc = pessoa.documentos.filter((d) => d.tipoDocumento.id == 2)[0];
        this.pessoa.cpf = doc.numero;
      }
      this.pessoa.escolaridade = this.escolaridades.find(
        (esc) => esc.id == pessoa.escolaridade
      );
      if (this.acao === "criacao") {
        EventBus.$emit("cadastroRealizadoComSucesso", this.pessoa);
      }
    },
    preencherListas(nomeTabelas) {
      nomeTabelas.forEach((tabela) => {
        ConsultaListasService.buscarListaPorNomeTabela(tabela)
          .then((response) => {
            this[`${tabela.toString()}s`] = response.data;
          })
          .catch((e) => Toast.fire("Erro: " + e.message, "", "error"));
      });
    },

    buscarCidadesDoEstado(id) {
      if (id) {
        ConsultaListasService.buscarCidadesPorIdEstado(id)
          .then((response) => {
            this.cidades = response.data;
          })
          .catch((e) => console.log("ERRO: " + e.message));
      }
    },
    preencherListasDados(idPessoa) {
      if (idPessoa) {
        CadastroPessoaService.buscarPessoaCompletaPorId(idPessoa)
          .then((response) => {
            if (response.data) {
              this.documentos = response.data.documentos;
              this.enderecos = response.data.enderecos;
              this.telefones = response.data.telefones;
              this.emails = response.data.emails;
            }
          })
          .catch((e) => Toast.fire("Erro: " + e.message, "", "error"));
      }
    },
    buscarBairrosDaCidade(id) {
      if (id) {
        ConsultaListasService.buscarBairrosPorIdCidade(id)
          .then((response) => {
            this.bairros = response.data;
          })
          .catch((e) => alert("ERRO: " + e.message));
      }
    },

    preencherListasObj(obj) {
      // {atributo : nome_tabela}
      Object.keys(obj).forEach((lista) => {
        ConsultaListasService.buscarListaPorNomeTabela(obj[lista])
          .then((response) => {
            this[lista] = response.data;
          })
          .catch((e) => alert("ERRO: " + e.message));
      });
    },

    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    adicionarFotoAPessoa(fotoBase64) {
      if (fotoBase64) {
        this.pessoa.fotos.push({ imagem: fotoBase64 });
      }
    },
  },
  validations: {
    pessoa: {
      nome: { required },
      dataNascimento: { required },
      mae: { required },
      sexo: { required },
    },
  },
};
</script>
<style scoped>
</style>