<template>
  <div class="col-12">
    <h4 class="mb-3">Cadastrar/Editar Telefone</h4>
    <form class="row">
      <div class="form-group col-lg-3 col-sm-12">
        <label for="nome">Número do telefone *</label>
        <input type="text" class="form-control form-control-border border-width-2" id="numerodocontato"
          v-model="telefone.numero" v-mask="'(##) #####-####'" :class="{ 'is-invalid': $v.telefone.numero.$error, }" />
        <div v-if="!$v.telefone.numero.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="tipodocumento">Tipo do Telefone *</label>
        <v-select class="form-control form-control-border border-width-2" id="tipodocumento"
          v-model="telefone.tipoTelefone" :options="['PESSOAL', 'COMERCIAL', 'CASA', 'RECADO']" label="descricao"
          :class="{ 'is-invalid': $v.telefone.tipoTelefone.$error, }">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
        <div v-if="!$v.telefone.tipoTelefone.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>
      <div class="form-group col-lg-1 col-sm-12">
        <label for="nome">Recados</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="recados" v-model="telefone.recado" />
          <label class="form-check-label" for="recados"> Sim </label>
        </div>
      </div>
      <div class="form-group col-lg-1 col-sm-12">
        <label for="nome">WhatsApp</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="contatowhatsapp" v-model="telefone.whatsapp" />
          <label class="form-check-label" for="contatowhatsapp"> Sim </label>
        </div>
      </div>
      <div class="form-group col-lg-2 col-sm-12">
        <label for="nome">Atual</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="numeroatual" v-model="telefone.atual" />
          <label class="form-check-label" for="numeroatual"> Sim </label>
        </div>
      </div>
      <div class="form-group col-lg-2" v-if="this.telefone.id == ''">
        <button class="btn btn-primary float-right mt-4" @click.prevent="salvarTelefone">
          <i class="fa-solid fa-floppy-disk"></i> Salvar
        </button>
      </div>

      <div class="form-group col-lg-2" v-else>
        <button class="btn btn-warning float-right mt-4" @click.prevent="atualizarTelefone">
          <i class="fas fa-check mr-1"></i> Atualizar
        </button>
      </div>
    </form>

    <hr />

    <h4 class="mb-3">Cadastrar/Editar Email</h4>
    <form class="row">
      <div class="form-group col-lg-3 col-sm-12">
        <label for="nome">Email *</label>

        <input type="text" class="form-control form-control-border border-width-2" id="emailPessoa"
          v-model="email.email" :class="{ 'is-invalid': $v.email.email.$error, }" />
        <div v-if="!$v.email.email.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>
      <div class="form-group col-lg-3 col-sm-12">
        <label for="estado">Tipo de Email *</label>
        <v-select class="form-control form-control-border border-width-2" id="estado" v-model="email.tipoEmail"
          :options="['PESSOAL', 'CORPORATIVO']" label="descricao" :class="{ 'is-invalid': $v.email.tipoEmail.$error, }">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
        <div v-if="!$v.email.tipoEmail.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>

      <div class="form-group col-lg-6" v-if="this.email.id === ''">
        <button class="btn btn-primary float-right mt-4" @click.prevent="salvarEmail">
          <i class="fa-solid fa-floppy-disk"></i> Salvar
        </button>
      </div>

      <div class="form-group col-lg-6" v-if="this.email.id != ''">
        <button class="btn btn-warning float-right mt-4" @click.prevent="atualizarEmail">
          <i class="fas fa-check mr-1"></i>Atualizar
        </button>
      </div>
    </form>
    <hr />
    <h4 class="my-4">Telefones já cadastrados:</h4>
    <div class="card">
      <div class="card-body table-responsive p-0">
        <table class="table table-hover text-nowrap" ref="table">
          <thead>
            <tr>
              <th>Número do Telefone</th>
              <th>Tipo</th>
              <th>Receber recados</th>
              <th>Número atual</th>
              <th>Número de contato do whatsapp</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tel in pessoa.telefones" :key="tel.id">
              <td>{{ tel.numero }}</td>
              <td>{{ tel.tipoTelefone }}</td>
              <td>{{ tel.recado ? "Sim" : "Não" }}</td>
              <td>{{ tel.atual ? "Sim" : "Não" }}</td>
              <td>{{ tel.whatsapp ? "Sim" : "Não" }}</td>
              <td>
                <div @click.prevent="buscarTelefone(tel.id)" class="btn btn-primary">
                  <i class="fa fa-edit" aria-hidden="true"></i>
                </div>
                <div @click.prevent="deleteTelefone(tel.id)" class="btn btn-danger ml-1">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h4 class="my-4">E-mails já cadastrados:</h4>

    <div class="card">
      <div class="card-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>E-mail</th>
              <th>Tipo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="eml in pessoa.emails" :key="eml.id">
              <td>{{ eml.email }}</td>
              <td>{{ eml.tipoEmail }}</td>
              <td>
                <div @click.prevent="buscarEmail(eml.id)" class="btn btn-primary">
                  <i class="fa fa-edit" aria-hidden="true"></i>
                </div>
                <div @click.prevent="deleteEmail(eml.id)" class="btn btn-danger ml-1">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import CadastroPessoaService from "@/service/CadastroPessoaService";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ContatosCadastroPessoa",
  props: ['pessoaEdicao'],
  data() {
    return {
      pessoa: {
        id: null,
        emails: [],
        telefones: [],
      },
      telefone: {
        id: "",
        numero: "",
        idPessoa: "",
        tipoTelefone: null,
        recado: "",
        whatsapp: true,
        atual: true,
      },
      email: {
        id: "",
        idPessoa: "",
        email: "",
        tipoEmail: null,
      },
      isTelSaving: false,
      isEmlSaving: false,
    };
  },
  created() {
    EventBus.$on("cadastrarPessoaDadosBasicos", (listener) => {
      this.pessoa.id = listener.id
      this.telefone.idPessoa = listener.id
      this.email.idPessoa = listener.id
    });
    EventBus.$on("dadosBasicosBuscouPessoa", (pessoa) => {

      this.telefone.idPessoa = pessoa.id;
      this.email.idPessoa = pessoa.id;
      this.pessoa.id = pessoa.id;
      this.pessoa.emails = pessoa.emails;
      this.pessoa.telefones = pessoa.telefones;
    })
    // aqui, cadastramos uma nova pessoa e preenchemos seus dados
    EventBus.$on("cadastroRealizadoComSucesso", (pessoa) => {
      this.telefone.idPessoa = pessoa.id;
      this.email.idPessoa = pessoa.id;
      this.pessoa.id = pessoa.id;
      this.pessoa.emails = pessoa.emails;
      this.pessoa.telefones = pessoa.telefones;
    });
    // aqui, buscamos uma pesso existente e preenchemos seus dados
    EventBus.$on("pessoaParaEditar", (pessoa) => {
      this.telefone.idPessoa = pessoa.id;
      this.email.idPessoa = pessoa.id;
      this.pessoa.id = pessoa.id;
      this.pessoa.emails = pessoa.emails;
      this.pessoa.telefones = pessoa.telefones;
    });
  },
  watch: {
    pessoaEdicao:{
      immediate: true,
      handler(pessoa) {
        this.telefone.idPessoa = pessoa.id;
        this.email.idPessoa = pessoa.id;
        this.pessoa.id = pessoa.id;
        this.pessoa.emails = pessoa.emails;
        this.pessoa.telefones = pessoa.telefones;
      }
    }
  },
  methods: {
    limparCamposEmail() {
      this.email = {
        id: "",
        email: "",
        tipoEmail: null,
      };
    },
    limparCamposTel() {
      this.telefone = {
        id: "",
        numero: "",
        tipoTelefone: null,
        recado: "",
        whatsapp: true,
        atual: true,
      };
    },
    atualizarTelefone() {
      if (
        this.telefone.numero &&
        this.telefone.tipoTelefone &&
        this.telefone.id
      ) {
        CadastroPessoaService.editarTelefone(this.telefone)
          .then((response) => {
            this.telefone = {
              id: "",
              idPessoa: this.pessoa.id,
              numero: "",
              tipoTelefone: null,
              recado: "",
              whatsapp: true,
              atual: true,
            };
            this.buscarTelefonesPessoa(this.telefone.idPessoa);
            Toast.fire("Telefone Atualizado!", "", "success");
          })
          .catch((error) => {
            alert("Erro na atualização: " + error.message);
          });
      } else {
        Toast.fire("Campos incompletos!", "", "error");
      }
    },
    atualizarEmail() {
      if (this.email.email && this.email.tipoEmail && this.email.id) {
        CadastroPessoaService.editarEmail(this.email)
          .then((response) => {
            this.email = {
              id: "",
              email: "",
              tipoEmail: null,
              idPessoa: this.pessoa.id,
            };
            this.buscarEmailsPessoa(this.email.idPessoa);
            const message = "Email Atualizado!!! ";
            Toast.fire(message.toString(), "", "success");
          })
          .catch((error) => {
            alert("Erro na atualização: " + error.message);
          });
      }
    },
    deleteTelefone(idTelefone) {
      if (idTelefone) {
        CadastroPessoaService.removerTelefone(idTelefone)
          .then((param) => {
            const message = "Telefone Removido com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
            this.buscarTelefonesPessoa(this.telefone.idPessoa);
            if (this.telefone.id == idTelefone) {
              this.telefone.id = "";
            }
          })
          .catch((error) => { });
      }
    },
    deleteEmail(idEmail) {
      if (idEmail) {
        CadastroPessoaService.removerEmail(idEmail)
          .then((param) => {
            const message = "Email Removido com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
            this.buscarEmailsPessoa(this.email.idPessoa);
          })
          .catch((error) => {
            Toast.fire("Erro ao deletar email: " + e.message, "", "error");
          });
      }
    },
    buscarTelefonesPessoa(id) {

      if (id) {
        CadastroPessoaService.buscarPessoaPorId(id).then((r) => {
          this.pessoa.telefones = r.data.telefones;
        });
      }
    },
    buscarEmailsPessoa(id) {

      if (id) {
        CadastroPessoaService.buscarPessoaPorId(id).then((r) => {
          this.pessoa.emails = r.data.emails;
        });
      }
    },
    salvarTelefone() {
      this.isTelSaving = true;
      this.$v.telefone.$touch();

      if (!this.$v.telefone.$invalid) {
        CadastroPessoaService.cadastrarTelefone(this.telefone)
          .then((param) => {
            this.isTelSaving = false;
            this.telefone = {
              id: "",
              idPessoa: this.pessoa.id,
              numero: "",
              tipoTelefone: null,
              recado: "",
              whatsapp: true,
              atual: true,
            };

            this.buscarTelefonesPessoa(this.telefone.idPessoa);
            const message = "Telefone Cadastrado com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
            this.$nextTick(() => { this.$v.$reset() })
          })
          .catch((error) => {
            Toast.fire(error.message, "", "error");
            //this.isTelSaving = false;
            // alertService.showHttpError(this, error.response);
          });
      } else {
        Toast.fire(
          "Campos incompletos!",
          "",
          "error"
        );
      }
    },

    salvarEmail() {
      this.$v.email.$touch();
      this.isEmlSaving = true;
      if (!this.$v.email.$invalid) {
        CadastroPessoaService.cadastrarEmail(this.email)
          .then((param) => {
            this.isEmlSaving = false;
            const message = "Email Cadastrado com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
            this.email = {
              id: "",
              email: "",
              tipoEmail: null,
              idPessoa: this.pessoa.id,
            };
            this.buscarEmailsPessoa(this.email.idPessoa);
          })
          .catch((error) => {
            alert("Erro ao salvar email");
          });
        this.$nextTick(() => { this.$v.$reset() })
      } else {
        Toast.fire(
          "Campos incompletos!",
          "",
          "error"
        );
      }
    },
    buscarEmail(idEmail) {
      if (idEmail) {
        CadastroPessoaService.buscarEmailedicao(idEmail)
          .then((response) => {
            this.email.email = response.data.email;
            this.email.tipoEmail = response.data.tipoEmail;
            this.email.id = idEmail;
          })
          .catch((error) => { });
      }
    },
    buscarTelefone(idTelefone) {
      if (idTelefone) {
        CadastroPessoaService.buscarTelefoneEdicao(idTelefone)
          .then((response) => {
            this.telefone.numero = response.data.numero;
            this.telefone.tipoTelefone = response.data.tipoTelefone;
            this.telefone.id = idTelefone;
            this.telefone.atual = response.data.atual;
            this.telefone.whatsapp = response.data.whatsapp;
            this.telefone.recado = response.data.recado;
          })
          .catch((error) => {
            // alertService.showHttpError(this, error.response);
          });
      }
    },
  },
  validations: {
    telefone: {
      numero: {
        required,
      },
      tipoTelefone: {
        required,
      },
    },
    email: {
      email: {
        required
      },
      tipoEmail: {
        required
      }
    }
  }
};
</script>
