var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-cnpjUg"}},[_vm._v("Cnpj Ug")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.procedimento.cnpjUg),expression:"procedimento.cnpjUg"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.cnpjUg.$error,
          },attrs:{"type":"text","name":"cnpjUg","id":"procedimento-cnpjUg","data-cy":"cnpjUg"},domProps:{"value":(_vm.procedimento.cnpjUg)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "cnpjUg", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-idContratacao"}},[_vm._v("Id Contratacao")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.procedimento.idContratacao),expression:"procedimento.idContratacao"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.idContratacao.$error,
          },attrs:{"type":"text","name":"idContratacao","id":"procedimento-idContratacao","data-cy":"idContratacao"},domProps:{"value":(_vm.procedimento.idContratacao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "idContratacao", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-numeroProcesso"}},[_vm._v("Número Processo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.procedimento.numeroProcesso),expression:"procedimento.numeroProcesso"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.numeroProcesso.$error,
          },attrs:{"type":"text","name":"numeroProcesso","id":"procedimento-numeroProcesso","data-cy":"numeroProcesso"},domProps:{"value":(_vm.procedimento.numeroProcesso)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "numeroProcesso", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-anoProcesso"}},[_vm._v("Ano Processo")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.procedimento.anoProcesso),expression:"procedimento.anoProcesso",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.anoProcesso.$error,
          },attrs:{"type":"number","name":"anoProcesso","id":"procedimento-anoProcesso","data-cy":"anoProcesso"},domProps:{"value":(_vm.procedimento.anoProcesso)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "anoProcesso", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-numeroProcedimento"}},[_vm._v("Numero Procedimento")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.procedimento.numeroProcedimento),expression:"procedimento.numeroProcedimento",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid':
              _vm.isSaving && _vm.$v.procedimento.numeroProcedimento.$error,
          },attrs:{"type":"number","name":"numeroProcedimento","id":"procedimento-numeroProcedimento","data-cy":"numeroProcedimento"},domProps:{"value":(_vm.procedimento.numeroProcedimento)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "numeroProcedimento", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-anoProcedimento"}},[_vm._v("Ano Procedimento")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.procedimento.anoProcedimento),expression:"procedimento.anoProcedimento",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.anoProcedimento.$error,
          },attrs:{"type":"number","name":"anoProcedimento","id":"procedimento-anoProcedimento","data-cy":"anoProcedimento"},domProps:{"value":(_vm.procedimento.anoProcedimento)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "anoProcedimento", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-dataPublicacao"}},[_vm._v("Data Publicação")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.procedimento.dataPublicacao.$error,
                    },attrs:{"type":"date","id":"procedimento-dataPublicacao","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.procedimento.dataPublicacao)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataPublicacao', $event)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-numeroLei"}},[_vm._v("Número Lei")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.procedimento.numeroLei),expression:"procedimento.numeroLei",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.numeroLei.$error,
          },attrs:{"type":"number","name":"numeroLei","id":"procedimento-numeroLei","data-cy":"numeroLei"},domProps:{"value":(_vm.procedimento.numeroLei)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "numeroLei", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-anoLei"}},[_vm._v("Ano Lei")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.procedimento.anoLei),expression:"procedimento.anoLei",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.anoLei.$error,
          },attrs:{"type":"number","name":"anoLei","id":"procedimento-anoLei","data-cy":"anoLei"},domProps:{"value":(_vm.procedimento.anoLei)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "anoLei", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-codProcedimento"}},[_vm._v("Cod Procedimento")]),_c('v-select',{staticClass:"border-v-select",class:{
          'is-invalid': _vm.isSaving && _vm.$v.procedimento.codProcedimento.$error,
        },attrs:{"id":"procedimento-codProcedimento","data-cy":"codProcedimento","name":"codProcedimento","options":_vm.codProcedimento,"reduce":function (codProcedimento) { return codProcedimento.id; },"label":"descricao"},model:{value:(_vm.procedimento.codProcedimento),callback:function ($$v) {_vm.$set(_vm.procedimento, "codProcedimento", $$v)},expression:"procedimento.codProcedimento"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-criterio"}},[_vm._v("Critério")]),_c('v-select',{staticClass:"border-v-select",class:{
          'is-invalid': _vm.isSaving && _vm.$v.procedimento.criterio.$error,
        },attrs:{"id":"procedimento-criterio","data-cy":"criterio","name":"criterio","options":_vm.criterio,"reduce":function (criterio) { return criterio.id; },"label":"descricao"},model:{value:(_vm.procedimento.criterio),callback:function ($$v) {_vm.$set(_vm.procedimento, "criterio", $$v)},expression:"procedimento.criterio"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-finalidade"}},[_vm._v("Finalidade")]),_c('v-select',{staticClass:"border-v-select",class:{
          'is-invalid': _vm.isSaving && _vm.$v.procedimento.finalidade.$error,
        },attrs:{"id":"procedimento-finalidade","data-cy":"finalidade","name":"finalidade","options":_vm.finalidade,"reduce":function (finalidade) { return finalidade.id; },"label":"descricao"},model:{value:(_vm.procedimento.finalidade),callback:function ($$v) {_vm.$set(_vm.procedimento, "finalidade", $$v)},expression:"procedimento.finalidade"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-sistemaPregao"}},[_vm._v("Sistema Pregão")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.procedimento.sistemaPregao),expression:"procedimento.sistemaPregao"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.sistemaPregao.$error,
          },attrs:{"type":"text","name":"sistemaPregao","id":"procedimento-sistemaPregao","data-cy":"sistemaPregao"},domProps:{"value":(_vm.procedimento.sistemaPregao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "sistemaPregao", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-dataAdesao"}},[_vm._v("Data Adesão")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.procedimento.dataAdesao.$error,
                    },attrs:{"type":"date","id":"procedimento-dataAdesao","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.procedimento.dataAdesao)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataAdesao', $event)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-regimeExecucao"}},[_vm._v("Regime Execução")]),_c('v-select',{staticClass:"border-v-select",class:{
          'is-invalid': _vm.isSaving && _vm.$v.procedimento.regimeExecucao.$error,
        },attrs:{"id":"procedimento-regimeExecucao","data-cy":"regimeExecucao","name":"regimeExecucao","options":_vm.regimeExecucao,"reduce":function (regimeExecucao) { return regimeExecucao.id; },"label":"descricao"},model:{value:(_vm.procedimento.regimeExecucao),callback:function ($$v) {_vm.$set(_vm.procedimento, "regimeExecucao", $$v)},expression:"procedimento.regimeExecucao"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-objeto"}},[_vm._v("Objeto")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.procedimento.objeto),expression:"procedimento.objeto"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.objeto.$error,
          },attrs:{"type":"text","name":"objeto","id":"procedimento-objeto","data-cy":"objeto"},domProps:{"value":(_vm.procedimento.objeto)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "objeto", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-valorEstimado"}},[_vm._v("Valor Estimado")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.procedimento.valorEstimado),expression:"procedimento.valorEstimado",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.valorEstimado.$error,
          },attrs:{"type":"number","name":"valorEstimado","id":"procedimento-valorEstimado","data-cy":"valorEstimado"},domProps:{"value":(_vm.procedimento.valorEstimado)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "valorEstimado", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-dataSessao"}},[_vm._v("Data Sessão")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.procedimento.dataSessao.$error,
                    },attrs:{"type":"date","id":"procedimento-dataSessao","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.procedimento.dataSessao)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataSessao', $event)}}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"procedimento-cpfAutoridade"}},[_vm._v("Cpf Autoridade")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.procedimento.cpfAutoridade),expression:"procedimento.cpfAutoridade"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.procedimento.cpfAutoridade.$error,
          },attrs:{"type":"text","name":"cpfAutoridade","id":"procedimento-cpfAutoridade","data-cy":"cpfAutoridade"},domProps:{"value":(_vm.procedimento.cpfAutoridade)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.procedimento, "cpfAutoridade", $event.target.value)}}})])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn text-color-principal background-principal ml-1",on:{"click":_vm.salvarProcedimento}},[_c('i',{staticClass:"far fa-envelope"}),_vm._v(" Enviar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":_vm.limparProcedimento}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Limpar ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }