<template>
  <div style="height: 85vh;">
    <iframe :src="pdfsrc" style="width: 100%; height: 100%; border: none;">
      Oops! an error has occurred.
    </iframe>
  </div>
</template>
<script>

import documentoService from "../../service/DocumentoService";
import authHeader from '@/service/auth/auth-header';
import axios from 'axios'
import { EventBus } from "@/main.js";

export default {
  name: 'MyIframe',
  data: () => ({
    pdfsrc: null
    // id:''
  }),
  props: {
    id: {
      type: Number,
      default: null,
    },
    acao: {
      type: String,
      default: "EN",
    },
  }  
};
</script>