<template>
  <div class="modal fade show h-100 overflow-auto" id="modal-xl" style="display: block" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-xl" @click.stop>
      <div v-if="chequeDetalhe">
        <div class="modal-content border-rounded-detail">
          <div class="modal-header background-principal text-color-principal card-item-result">
            <h4 class="modal-title mx-auto"> {{ chequeDetalhe.valor || 'SEM VALOR REGISTRADO' }}</h4>

          </div>
          <div class="modal-body">
            <h6 class="mt-4 mb-3"><b>DADOS BÁSICOS: </b></h6>
            <div class="row m-10 p-0">
              <div class="col-sm-12 col-md-6">
                <p class="medium mb-0"> <b>Banco: </b> {{ chequeDetalhe.banco }}</p>
                <p class="medium mb-0"> <b>Agência: </b>{{ chequeDetalhe.agencia }}</p>
                <p class="medium mb-0"><b>Conta Corrente: </b> {{ chequeDetalhe.contaCorrente }}</p>
              </div>
              <div class="col-sm-12 col-md-6">
                <p class="medium mb-0"> <b>Número Início da Folha: </b> {{ chequeDetalhe.numeroInicioFolha }}</p>
                <p class="medium mb-0"> <b>Número Fim da Folha: </b> {{ chequeDetalhe.numeroFimFolha }}</p>
              </div>
            </div>
            <h6 class="mt-4 mb-3"><b>OCORRÊNCIAS: </b></h6>
            <div>
              <ListaBoComponent :bos="chequeDetalhe.bos" />
              <BoCardComponent />
            </div>
            <!-- <div
              class=" row max-height-content px-5 d-flex justify-content-around table-responsive max-height-content-detail">
              <div :key="idBo" v-for="(bos, idBo) in chequeDetalhe.bos"
                class="card card-item bg-light col-sm-12 col-md-12 m-10 rounded-12 p-0">
                <div>
                  <div>
                    <div class="text-center">
                      <p class="header background-principal text-color-principal card-item-result">
                        {{ bos.numero }}
                      </p>
                    </div>
                    <div class="row">

                      <div class="col-12 mx-0 my-2 p-0">
                        <div class="row">
                          <ul class="col-sm-12 col-md-3 mb-0 fa-ul text-muted">
                            <li class="medium"><b>Data: </b> {{ bos.dataFato }}</li>
                            <li class="medium"> <b>Descricao: </b> {{ bos.descricao }}</li>
                          </ul>
                          <ul class="col-sm-12 col-md-3 mb-0 fa-ul text-muted">
                            <li class="medium"><b>Data Registro: </b>{{ bos.dataRegistro }}
                            </li>
                            <li class="medium"><span class="fa-li"><i class="fas fa-lg fa-location-dot"></i></span>
                              <b>Envolvimento do Objeto: </b> {{ bos.envolvimentoObj }}
                            </li>
                          </ul>
                          <ul class="col-sm-12 col-md-3 mb-0 fa-ul text-muted">
                            <li class="medium"><span class="fa-li"><i class="fas fa-lg fa-earth-americas"></i></span>
                              <b>Providência: </b> {{ bos.providencia }}
                            </li>
                            <li class="medium"><span class="fa-li"><i class="fas fa-lg fa-earth-americas"></i></span>
                              <b>Fato: </b> {{ bos.fato }}
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div> -->
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('closeModalCheque')">
              <i class="fa-solid fa-x"></i>
              Fechar</button>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { EventBus } from '../../../main.js'
import BoCardComponent from '../BoCardComponent.vue'
import ListaBoComponent from '../ListaBoComponent.vue'

export default {
  name: 'DetalhesChequeConsulta',
  data() {
    return {
      chequeDetalhe: {},
      bos: []
    }
  },
  created() {
    EventBus.$on('consultaChequeDetalhe', (event) => {
      this.consultarCheque(event)
    })
  },
  filters: {
    exibirAno: function (dataCompleta) {
      if (dataCompleta) {
        const apenasAno = dataCompleta.substring(0, 4)
        return apenasAno
      }
    }
  },
  methods: {
    consultarCheque(resultChequeSelecionado) {
      this.chequeDetalhe = resultChequeSelecionado
    }
  },
  components: { ListaBoComponent, BoCardComponent }
}
</script>
