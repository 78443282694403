<template>
  <div class="modal fade show" style="display: block" id="modal-xl" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-xl" @click.stop>
      <div class="modal-content border-rounded-detail">
        <div class="modal-header background-principal text-color-principal card-item-result">
          <h4 class="modal-title mx-auto">
            {{ unidadeInfo.descricao || "NÃO CADASTRADO" }}
          </h4>
        </div>
        <div class="modal-body max-height-content" v-show="!editarUnidadeShow">
          <EditarUnidade @fecharEditarUnidade="editarUnidadeHideButton()" />
        </div>

        <div class="modal-body  max-height-content" v-show="!criarUnidadeSubordinadaShow">
          <CadastrarUnidadeSubordinada @fecharCadastrarUnidadeSubordinada="criarUnidadeSubordinadaHideButton()" />
        </div>


        <div class="modal-body  max-height-content" v-show="editarUnidadeShow && criarUnidadeSubordinadaShow">
          <div class="max-height-content px-2">
            <h6 class="mt-4 mb-3"><b>DADOS BÁSICOS: </b></h6>
            <div class="row m-10 p-0">
              <div class="col-sm-12 col-md-2">
                <p class="medium mb-0">
                  <b>SIGLA:</b>
                  {{ unidadeInfo.sigla || "NÃO CADASTRADO" }}
                </p>
              </div>
              <div class="col-sm-12 col-md-4">
                <p class="medium mb-0">
                  <b>TIPO DA UNIDADE:</b>
                  {{ unidadeInfo.tipoUnidade || "NÃO CADASTRADO" }}
                </p>
              </div>
              <div class="col-sm-12 col-md-3">
                <p class="medium mb-0">
                  <b>UNIDADE EXTERNA:</b>
                  <i class="px-2" :class="unidadeInfo.unidadeExterna
                  ? 'fa-solid fa-circle-check green' :
                  'fa-solid fa-circle-xmark red'"></i>

                  {{ unidadeInfo.unidadeExterna ? 'SIM' : 'NÃO' }}

                </p>
              </div>
              <div class="col-sm-12 col-md-3">
                <p class="medium mb-0">
                  <b>UNIDADE POLICIAL:</b>
                  <i class="px-2" :class="unidadeInfo.unidadePolicial
                  ? 'fa-solid fa-circle-check green' :
                  'fa-solid fa-circle-xmark red'"></i>
                  {{ unidadeInfo.unidadePolicial ? 'SIM' : 'NÃO' }}
                </p>
              </div>

              <div class="col-sm-12 col-md-4">
                <p class="medium mb-0">
                  <b>FORÇA:</b>
                  {{ unidadeInfo.forca ? unidadeInfo.forca.descricao : "NÃO CADASTRADO" }}
                </p>
              </div>
            </div>
          </div>
          <h6 class="mt-4 mb-3 px-2"><b>ENDEREÇO: </b></h6>

          <div v-if="enderecoResumo" class="row m-10 px-2">
            <div class="col-sm-12 col-md-4">
              <p class="medium mb-0">
                <b>CIDADE: </b>{{ enderecoResumo.cidade ? enderecoResumo.cidade.descricao : 'NÃO INFORMADO' }}
              </p>
            </div>

            <div class="col-sm-12 col-md-4">
              <p class="medium mb-0">
                <b>BAIRRO: </b>{{ enderecoResumo.bairro ? enderecoResumo.bairro.descricao : 'NÃO INFORMADO' }}
              </p>
            </div>

            <div class="col-sm-12 col-md-4">
              <p class="medium mb-0">
                <b>LOGRADOURO: </b>{{ enderecoResumo.logradouro || 'NÃO INFORMADO' }}
              </p>
            </div>
            <div class="col-sm-12 col-md-4">
              <p class="medium mb-0">
                <b>NÚMERO: </b>{{ enderecoResumo.numero || 'NÃO INFORMADO' }}
              </p>
            </div>
          </div>
          <h6 class="mt-4 mb-3 px-2"><b>TELEFONES: </b></h6>

          <div class="card">
            <div class="card-body table-responsive px-2">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>Número do Telefone</th>
                    <th>Tipo</th>
                    <th>Número Atual</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tel in telefones" :key="tel.id">
                    <td>{{ tel.numero }}</td>
                    <td>{{ tel.tipoTelefone }}</td>


                    <td>

                      <i
                        :class="tel.atual ? 'fa-solid fa-circle-check green ml-4 ' : 'fa-solid fa-circle-xmark red ml-4'"></i>
                      {{ tel.atual ? "SIM" : "NÃO" }}

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h6 class="mt-4 mb-3 px-2"><b>EMAIL: </b></h6>
          <div class="card">
            <div class="card-body table-responsive px-2">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="eml in emails" :key="eml.id">
                    <td>{{ eml.email }}</td>
                    <td>{{ eml.tipoEmail }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <div class="modal-footer justify-content-between">

          <button type="button" class="btn btn-default" data-dismiss="modal"
            @click="$emit('closeModalUnidade'), fecharModal(), editarUnidadeHideButton(), criarUnidadeSubordinadaHideButton()">
            <i class="fa-solid fa-x"></i>
            Fechar
          </button>
          <div v-if="editarUnidadeShow && criarUnidadeSubordinadaShow">
            <button type="button" class="mx-1 btn btn-success" @click="editarUnidadeShowButton()">
              <i class="fa-solid fa-pen-to-square"></i>
              Editar Unidade
            </button>
            <button type="button" class="btn btn-default mx-1 background-principal text-color-principal "
              @click="criarUnidadeSubordinadaShowButton()">
              <i class="fa-solid fa-plus"></i>
              Criar Unidade Subordinada
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { EventBus } from '@/main';
import UnidadeService from '@/service/UnidadeService';
import CadastrarUnidadeSubordinada from './CadastrarUnidadeSubordinada.vue';
import EditarUnidade from './EditarUnidade.vue';
export default {
  name: "DetalhesUnidade",
  data() {
    return {
      idUnidadeDetalhe: '',
      unidadeInfo: {},
      enderecoResumo: {
        logradouro: '',
        bairro: '',
        cidade: '',
        numero: ''
      },
      telefones: [],
      emails: [],
      editarUnidadeShow: true,
      criarUnidadeSubordinadaShow: true,
    };
  },
  created() {
    EventBus.$on("consultaUnidadeDetalhe", (event) => {
      this.idUnidadeDetalhe = event;
      this.consultarUnidadeInfo(event);
      this.consultarUnidadeDados(event);
    });
    EventBus.$on("resetarModalUnidade", (event) => {
      this.fecharModal();
      this.editarUnidadeHideButton();
      this.criarUnidadeSubordinadaHideButton()
    })
  },
  methods: {
    fecharModal() {
      this.unidadeInfo = {};
      this.enderecoResumo = {};
      this.telefones = [];
      this.emails = [];

      EventBus.$emit('resetarFormularioUnidade', null)

    },
    // findTelefoneById(idTelefone) {
    //   for (let index = 0; index < this.telefones.length; index++) {
    //     if (this.telefones[index].id == idTelefone) {
    //       return index
    //     }
    //   }
    //   return null
    // },
    // editarNumeroAtual(idTelefone) {
    //   let index = this.findTelefoneById(idTelefone)
    //   if (index != null) {
    //     this.telefones[index].atual = !this.telefones[index].atual
    //     UnidadeService.editarUnidadeTelefone(this.telefones[index], idTelefone).then((response) => {
    //       Toast.fire("Sucesso ao editar e-mail ", "", "success");
    //     }).catch((e) => alert('ERRO: ' + e.message));
    //   }
    // },
    editarUnidadeShowButton() {
      this.editarUnidadeShow = false;
    },
    editarUnidadeHideButton() {
      this.editarUnidadeShow = true;
      this.consultarUnidadeInfo(this.idUnidadeDetalhe)
      this.consultarUnidadeDados(this.idUnidadeDetalhe)
    },
    criarUnidadeSubordinadaShowButton() {
      this.criarUnidadeSubordinadaShow = false;
    },
    criarUnidadeSubordinadaHideButton() {
      this.criarUnidadeSubordinadaShow = true;
    },
    consultarUnidadeInfo(idUnidade) {
      UnidadeService.buscarUnidadeDetalheId(idUnidade).then((response) => {
        this.unidadeInfo = response.data;
        EventBus.$emit("editarUnidadeInformacoes", this.unidadeInfo)
      });
    },
    consultarUnidadeDados(idUnidade) {
      UnidadeService.buscarUnidadeDadosId(idUnidade).then((response) => {
        this.enderecoRecente(response.data);
        this.telefones = response.data.telefones;
        this.emails = response.data.emails;
        EventBus.$emit("editarUnidadeTelefoneInformacoes", this.telefones);
        EventBus.$emit("editarUnidadeEmailInformacoes", this.emails);
      });
    },
    enderecoRecente(data) {
      if (data.enderecos && data.enderecos.length > 0) {
        const logradouro = data.enderecos.reduce(function (prev, current) {
          return prev.id > current.id ? prev : current;
        }).logradouro;
        const cidade = data.enderecos.reduce(function (prev, current) {
          return prev.id > current.id ? prev : current;
        }).cidade;
        const bairro = data.enderecos.reduce(function (prev, current) {
          return prev.id > current.id ? prev : current;
        }).bairro;
        const numero = data.enderecos.reduce(function (prev, current) {
          return prev.id > current.id ? prev : current;
        }).numero;
        this.enderecoResumo.logradouro = logradouro;
        this.enderecoResumo.cidade = cidade;
        this.enderecoResumo.bairro = bairro;
        this.enderecoResumo.numero = numero;
        return this.enderecoResumo;
      }
    }
  },
  components: { EditarUnidade, CadastrarUnidadeSubordinada }
}
</script>

<style scoped>
.green {
  color: green !important;
}

.red {
  color: red !important;
}
</style>
