<template>
  <div class="card-body pt-0">
    <Loader :active="loaderActive" message="Carregando..." />
    <!-- <div class="tab-content" id="custom-tabs-five-tabContent"> -->
    <!-- <div class="tab-pane fade" role="tabpanel" > -->
    <!-- <div class="table-responsive mailbox-messages"> -->
    <table class="table table-hover table-striped" v-if="!loaderActive">
      <thead>
        <tr>
          <th>Status</th>
          <th>Tipo</th>

          <th>Assunto</th>
          <th>Anexo</th>
          <th>Remetente</th>
          <th>Setor</th>
          <th>Recebido</th>
          <!-- <th>Ação</th> -->

          <!-- <th>Ciencia</th> -->
        </tr>
      </thead>
      <tbody v-if="resultados">
        <tr
          class="linhaTabela"
          v-for="documento in documentos"
          v-bind:key="documento.id"
          @click="visualizar(documento.id)"
          :class="{ 'font-weight-bold': !documento.leitura }"
        >
          <!-- <td>
                    <div class="icheck-primary">
                      <input type="checkbox" value="" id="check1" />
                      <label for="check1"></label>
                    </div>
                  </td> -->
          <!-- <td class="mailbox-star">
                    <a href="#"><i class="fas fa-star text-warning"></i></a>
                  </td> -->
          <td class="mailbox-status" v-if="documento.acao === 'Respondido'">
            <i class="fas fa-users" :title="'DOCUMENTO COMPARTILHADO POR: ' + documento.fkUsuarioDestino.pessoaResumo.nome " v-if="documento.fkUsuarioDestino.id != usuario.idFuncionario"></i>
            <i title="Este documento foi respondido." class="px-3 fa-solid fa-share"></i>
          </td>
          <td class="mailbox-status" v-if="documento.acao !== 'Respondido'">
            <i class="fas fa-users" :title="'DOCUMENTO COMPARTILHADO POR: ' + documento.fkUsuarioDestino.pessoaResumo.nome " v-if="documento.fkUsuarioDestino.id != usuario.idFuncionario"></i>
            <i title="Este documento foi lido."
              class="px-3"
              :class="
                documento.leitura === true
                  ? 'fas fa-envelope-open-text'
                  : 'fas fa-envelope'
              "
            ></i>
          </td>

          <!-- <td class="mailbox-status" v-if="documento.acao === 'Respondido'">
            <i
              title="Este documento foi respondido."
              class="px-3 fa-solid fa-share"
            ></i>
          </td>
          <td class="mailbox-status" v-if="documento.acao !== 'Respondido'">
            <i
              title="Este documento foi lido."
              class="px-3"
              :class="
                documento.leitura === true
                  ? 'fas fa-envelope-open-text'
                  : 'fas fa-envelope'
              "
            ></i>
          </td> -->

          <td class="mailbox-name" v-if="documento.tipoDocumento === 'CI'">
            Comunicação Interna Nº {{ documento.numero }}
          </td>
          <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'OF'">
            Ofício Nº {{ documento.numero }}
          </td>
          <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'DE'">
            Despacho Nº {{ documento.numero }}
          </td>
          <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'PR'">
            Protocolo Nº {{ documento.numero }}
          </td>
          <!-- <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'CI'">
                    Comunicação Interna
                  </td> -->

          <td class="mailbox-subject">
            {{ documento.assunto }}
          </td>
          <td class="mailbox-attachment">
            <i class="fas fa-paperclip" v-if="documento.anexo"></i>
          </td>
          <td class="mailbox-subject" v-if="documento.fkUsuario != null">
            {{ documento.fkUsuario.pessoaResumo.nome }}
          </td>
          <td class="mailbox-subject" v-else>
            {{ documento.fkCriador.pessoaResumo.nome }}
          </td>

          <td class="mailbox-subject" v-if="documento.fkUsuario != null">
            {{ documento.fkSetorUsuario.descricao }}
          </td>
          <td class="mailbox-subject" v-else>
            {{ documento.fkSetorCriador.descricao }}
          </td>

          <td class="mailbox-date">
            {{ updateZonedDateTimeField(documento.dataAssinatura) }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <div class="mx-auto my-auto sem-resultados py-2">
          <div>Sem resultados para sua busca!</div>
        </div>
      </tbody>
    </table>
    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>
<script>
import { EventBus } from "@/main.js";
import { DATE_FORMAT_LOCAL } from "@/util/filter";

import paginacao from "@/util/paginacao";
import dayjs from "dayjs";
import documentoService from "../../service/DocumentoService";
import Loader from "../Loader.vue";

export default {
  name: "Documentos",
  components: { Loader },
  data() {
    return {
      documentos: [],
      resultados: false,
      page: [],
      documentoPesquisa: [],
      loaderActive: false,
    };
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    acao: {
      type: String,
      default: "EN",
    },
  },
  methods: {
    abrirModalPreenchido(idDocumento) {
      // console.log(idDocumento)
      EventBus.$emit("abrirModalCompartilhar", idDocumento);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    getDocumentos(pagina) {
      this.showLoader();

      if (this.documentoPesquisa.length == undefined) {
        this.documentoPesquisa.tableConsulta = "documentos_recebidos";

        documentoService
          .pesquisarDocumento(this.documentoPesquisa, pagina)
          .then((response) => {
            this.hideLoader();
            let retorno = paginacao.montarPaginacao(response.data);

            this.page = retorno.page;
            this.documentos = retorno.data;

            if (this.documentos.length > 0) {
              this.resultados = true;
            }

            EventBus.$emit("paginacao", retorno.page);
          })
          .catch((e) => {
            this.hideLoader();
          });
      } else {
        documentoService
          .buscarDocumentosRecebidos(pagina)
          .then((response) => {
            this.hideLoader();
            let retorno = paginacao.montarPaginacao(response.data);

            this.page = retorno.page;
            this.documentos = retorno.data;

            if (this.documentos.length > 0) {
              this.resultados = true;
            }

            EventBus.$emit("paginacao", retorno.page);
          })
          .catch((e) => {
            this.hideLoader();
          });
      }

      documentoService.verificarCountDocumento().then((response) => {
        // let tttt;

        // tttt = response.data;
        // console.log(tttt);
        EventBus.$emit("countDocumentos", response.data);
      });
    },
    visualizar(id) {
      let clickouEmCompartilhar =
        event.target.classList.contains("compartilhar");
      if (!clickouEmCompartilhar) {
        // documentoService.visualizarDocumento(id).then((response) => {
        EventBus.$emit("alteraAcaoConteudo", "VD");
        EventBus.$emit("enviarPdf", id);
        // this.documentos = response.data;
        // });
      }
    },
    updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL);
    },
  },
  created() {
    EventBus.$on("filtrarDocumentoRecebidos", (documento) => {
      this.documentoPesquisa = documento;
      this.getDocumentos(0);
    });

    if (this.usuario) {
      this.getDocumentos(0);
    }
  },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    },
  },
  beforeMount() {
    $("body").removeClass("login-page").addClass("sidebar-mini");
  },
  mounted() {
    $(function () {
      $(".checkbox-toggle").click(function () {
        const clicks = $(this).data("clicks");
        if (clicks) {
          $(".mailbox-messages input[type='checkbox']").prop("checked", false);
          $(".checkbox-toggle .far.fa-check-square")
            .removeClass("fa-check-square")
            .addClass("fa-square");
        } else {
          $(".mailbox-messages input[type='checkbox']").prop("checked", true);
          $(".checkbox-toggle .far.fa-square")
            .removeClass("fa-square")
            .addClass("fa-check-square");
        }
        $(this).data("clicks", !clicks);
      });
      $(".mailbox-star").click(function (e) {
        e.preventDefault();
        const $this = $(this).find("a > i");
        const fa = $this.hasClass("fa");
        if (fa) {
          $this.toggleClass("fa-star");
          $this.toggleClass("fa-star-o");
        }
      });
    });
  },
};
</script>
<style scoped>
.linhaTabela {
  cursor: pointer;
}
</style>
