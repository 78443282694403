<template>
    <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
      <content-header title="Diárias" />
      <conteudo v-role=" 'DIARIAS'&&'RECURSOS_HUMANOS'">
        <div class="container-fluid">
          <TabsDiaria/>
        </div>
        
      </conteudo>
    </section>
  </template>
  
  <script>
  import TabsDiaria from "@/components/rh/diaria/TabsDiaria.vue"
  
  export default {
    components: {TabsDiaria},
    created() {
  
    },
    
    data() {
      return {
        isModalVisible: false,
          
      }
    },
    mounted() {},
    
    methods: {
        
    },
  }
  </script>