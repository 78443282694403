<template>
  <div>
    <b-modal
      v-model="isVisible"
      ref="modal-1"
      id="modal-1"
      title="Buscar pessoa"
      data-backdrop="static"
      size="xl"
      cancel-disabled
      no-close-on-backdrop
      hide-header-close
      header-class="modal-header
        background-principal
        text-color-principal
        card-item-result
      "
      content-class="modal-content border-rounded-detail"
      dialog-class="p-3"
      title-class="modal-title mx-auto my-0"
    >
      <div
        v-if="pessoaNaoEncontrada"
        class="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        Nenhuma pessoa foi encontrada. Cadastre uma nova.
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="row p-0">
        <!-- <loading :active="isLoading" :is-full-page="fullPage" /> -->
        <div class="form-group col-lg-6">
          <label for="profissao">Tipo consulta</label>
          <v-select
            class="border-v-select"
            id="profissao"
            v-model="tipoConsulta"
            :options="Object.keys(opcoesBusca)"
            label="descricao"
          >
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>

        <div class="form-group col-lg-6">
          <label v-text="tipoConsulta" for="cpf"></label>
          <input
            v-if="tipoConsulta != 'CPF'"
            type="text"
            class="form-control form-control-border border-width-2"
            :ref="opcoesBusca[tipoConsulta].model"
            :id="opcoesBusca[tipoConsulta].model + 'Tipo'"
            :placeholder="opcoesBusca[tipoConsulta].placeholder"
            :name="opcoesBusca[tipoConsulta].model"
            :key="opcoesBusca[tipoConsulta].model"
            @keyup.enter="buscarPessoaButton"
          />

          <input
            v-else
            type="text"
            class="form-control form-control-border border-width-2"
            :ref="opcoesBusca[tipoConsulta].model"
            :placeholder="opcoesBusca[tipoConsulta].placeholder"
            :name="opcoesBusca[tipoConsulta].model"
            v-mask="'###.###.###-##'"
            @keyup.enter="buscarPessoaButton"
          />
        </div>
      </div>

      <div v-if="resultados.length > 0" class="row bo-component-objetos p-1">
        <loading :active="isLoading" :is-full-page="fullPage" />
        <div class="col-12 p-2 float-right">
          <span v-if="this.$route.name.includes('cadastrovisita')"
            >Clique no visitante para selecioná-lo.</span
          >
          <div class="float-right">
            <div v-if="resultados.length > 0">
              <a href="#" v-on:click.prevent="page > 1 ? page-- : 0"
                ><i class="fas fa-angle-left mx-2"></i
              ></a>

              Página {{ page }}

              <a href="#" v-on:click.prevent="page++"
                ><i class="fas fa-angle-right mx-2"></i
              ></a>
            </div>
            <div v-if="resultados.length == 0 && page != 1">
              <a href="#" v-on:click.prevent="page > 1 ? page-- : 1"
                ><i class="fas fa-angle-left mx-2"></i
              ></a>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <div class="card-body table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Nome da mãe</th>
                    <th>CPF</th>
                    <th>Data de nascimento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="clicavel"
                    @click="cadastrarFuncionario(pessoa.id)"
                    :key="pessoa.id"
                    v-for="(pessoa, i) in resultados"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ pessoa.nome }}</td>
                    <td>{{ pessoa.mae }}</td>
                    <td>{{ pessoa.cpf }}</td>
                    <td>{{ pessoa.dataNascimento | formatarData }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button
          class="btn btn-default mr-auto"
          @click="cancel()"
          @click.prevent="closeModal"
        >
          <i class="fa-solid fa-x"></i>Fechar
        </b-button>
        <button
          v-show="pessoaNaoEncontrada"
          type="submit"
          class="btn btn-primary"
          @click.prevent="fluxoCadastroPessoa"
          data-dismiss="modal"
        >
          <i class="fa-solid fa-user-plus"></i>
          Cadastrar Pessoa
        </button>

        <b-button
          class="btn btn-success"
          @click.prevent="buscarPessoaButton"
          v-on:keyup.enter="buscarPessoaButton"
          :disabled="buscou"
        >
          <i class="fa-solid fa-magnifying-glass"></i>Consultar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CadastroPessoaService from "@/service/CadastroPessoaService";
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import Loading from "vue-loading-overlay";


import { mapState } from 'vuex';

export default {
  components: { Loading },
  props: {
    visible: Boolean,
    fluxo: {
      type: String,
      default: "Pessoa"},
  },
  data() {
    return {
      isVisible: this.visible,
      pessoaNaoEncontrada: false,
      buscou: false,
      isLoading: false,
      fullPage: false,
      tipoConsulta: "CPF",
      page: 1,
      resultados: [],
      opcoesBusca: {
        CPF: {
          model: "cpfBusca",
          placeholder: "CPF",
        },
        Nome: {
          model: "nomeBusca",
          placeholder: "Nome da pessoa",
        },
      },
    };
  },
  filters: {
    formatarData: function (data) {
      if (data == "" || data == null) {
        return "Sem nascimento registrado";
      } else {
        const [ano, mes, dia] = data.split("-");
        const dataFormatada = `${dia}/${mes}/${ano}`;
        return dataFormatada;
      }
    },
  },
  computed: {
    ...mapState(['cadastroPessoaExterno']),
  },
  beforeMount() {

    if (this.$route.path != "/cadastrovisita" && this.cadastroPessoaExterno != true) {
      let pessoa = {
        fotos: [],
        pais: { id: 1, descricao: "Brasil" },
        estado: null,
        cidade: null,
      };
      this.$store.commit("setIdPessoaFluxoCadastroPessoa", pessoa);
    }
  },
  methods: {
    buscarPessoaButton() {
      this.buscou = true;
      this.buscarPessoa();
    },
    buscarPessoa() {
      this.showLoader();
      const nomeBusca = this.$refs.nomeBusca;
      const cpfBusca = this.$refs.cpfBusca;
      const params = {
        cpf: cpfBusca ? cpfBusca.value : null,
        nome: nomeBusca ? nomeBusca.value : null,
        pagina: this.page >= 1 ? this.page : null,
      };

      CadastroPessoaService.buscarResumoPessoa(params).then((r) => {
        const indisponivel = r.status.toString() == "503";
        if (indisponivel) {
          this.pessoaNaoEncontrada = true;
          this.resultados = [];

          Toast.fire(error.response.data.detail.toString(), "", "error");
          this.hideLoader();
        } else if (r.data && r.data.length === 0) {
          if (this.page > 1) {
            this.page = 1;
          } else {
            this.pessoaNaoEncontrada = true;
            this.$store.commit("setResultadosBuscaPessoaVisita", r.data);
            this.resultados = [];
          }
          this.hideLoader();
        } else {
          this.pessoaNaoEncontrada = false;
          this.resultados = r.data;
          this.hideLoader();
        }
        this.buscou = false;
      });
    },
    cadastrarFuncionario(id) {
      this.showLoader();

      if(this.fluxo === 'Pessoa'){
        CadastroPessoaService.buscarPessoaCompletaPorId(id).then((r) => {
          if (r) {
            // this.preencherCamposPessoa(r);

            this.$store.commit("setIdPessoaFluxoCadastroPessoa", r.data);
          }
          this.hideLoader();
          this.beforeClose();
          
        });
      }else if(this.fluxo === 'Visita'){

        const params = {
          cpf: this.tipoConsulta == "CPF" ? this.$refs.cpfBusca.value : "",
          nome: this.tipoConsulta == "Nome" ? this.$refs.nomeBusca.value : "",
        };

        CadastroPessoaService.buscarPessoaVisita(params).then((r) => {
          this.hideLoader();
          const indisponivel = r.status.toString() == "503";
          if (indisponivel) {
            this.pessoaNaoEncontrada = true;
            this.resultados = [];

            Toast.fire(error.response.data.detail.toString(), "", "error");
          }
          if (r.data && r.data.length === 0) {
            this.pessoaNaoEncontrada = true;
            this.resultados = [];
          } else {
            this.pessoaNaoEncontrada = false;
            this.isVisible = false;

            // EventBus.$emit("resultadosBuscaPessoa", r.data);
            this.$store.commit("setResultadosBuscaPessoaVisita", r.data);
            this.resultados = [];



            //this.resultados = r.data;
          }
        });

      } else if(this.fluxo === 'Funcionario'){
        
        this.$store.commit("pessoaFuncionario", id);
        this.$store.commit("funcionarioCadastrado", 0);
        this.$router.push({ name: "cadastrofuncionario" });

      } else if(this.fluxo === 'Usuario'){

        let listaFuncionarios = null;

        consultaFuncionarioService.buscarFuncionarioPorPessoaCorrespondenteId(id)
        .then((response) => {
          listaFuncionarios = response.data;

          if (listaFuncionarios.length > 0){

            this.$store.commit("pessoaUsuario", id);
            this.$store.commit("usuarioCadastrado", 0);
            this.$router.push({ name: "cadastrousuario" });
            this.$store.commit("setCadastroUsuario", true);

          }else {
          
            this.$store.commit("pessoaFuncionario", id);
            this.$store.commit("funcionarioCadastrado", 0);
            this.$store.commit("setCadastroUsuario", true);

            // this.$swal('Hello Vue world!!!');

            this.$swal
              .fire({
                title: "Ainda não há servidor cadastrado para essa pessoa.",
                focusConfirm: false,
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Cadastrar Servidor",
                cancelButtonText: "Cancelar",
                reverseButtons: true
                
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$store.commit("visualizarListaFuncionariosExistentes", false);
                  this.$router.push({ name: "cadastrofuncionario" });
                }
              });
          }
        });

        // if (){
        // }

        
        
      }
    },
    fluxoCadastroPessoa() {

      let pessoa = {
            fotos: [],
            pais: { id: 1, descricao: "Brasil" },
            estado: null,
            cidade: null,
          };
          let cpf = "";
          if (this.$refs.hasOwnProperty("cpfBusca")) {
            cpf = this.$refs.cpfBusca.value;
            if (cpf != "") {
              pessoa.cpf = cpf;
            }
          }
         

      if (this.$route.path === "/cadastrovisita") {

        this.$store.commit("setCadastroPessoaExterno", true);
        this.$store.commit("setCadastroPessoaExternoVisita", true);
        this.$store.commit("setIdPessoaFluxoCadastroPessoa", pessoa);
        this.$store.commit("setFluxo", "/cadastrovisita");
        this.$store.commit("setCadastroUsuario", false);
        this.$router.push("/cadastropessoa");

      } else if (this.$route.path === "/servidores") {

        this.$store.commit("setCadastroPessoaExterno", true);
        this.$store.commit("setCadastroPessoaExternoVisita", false);
        this.$store.commit("setIdPessoaFluxoCadastroPessoa", pessoa);
        this.$store.commit("setFluxo", "/cadastroservidor");
        this.$store.commit("setCadastroUsuario", false);
        this.$router.push("/cadastropessoa");

      } else if (this.$route.path === "/visualizacaousuario") {

      this.$store.commit("setCadastroPessoaExterno", true);
      this.$store.commit("setCadastroPessoaExternoVisita", false);
      this.$store.commit("setIdPessoaFluxoCadastroPessoa", pessoa);
      this.$store.commit("setFluxo", "/cadastroservidor");
      this.$store.commit("setCadastroUsuario", true);
      this.$router.push("/cadastropessoa");

      } else {

        this.$store.commit("setIdPessoaFluxoCadastroPessoa", pessoa);
        this.$store.commit("setCadastroUsuario", false);
        this.hideLoader();
        this.beforeClose();
        
      }
    },
    showLoader() {
      this.isLoading = true;
      this.submitted = true;
    },
    hideLoader() {
      this.isLoading = false;
      this.submitted = false;
    },
    beforeClose() {
      //this.$refs.cpfBusca.value = "";
      this.resultados = [];
      this.isVisible = false;
      this.hideLoader();
      this.buscou = false;
    },



    closeModal() {
      history.go(-1);
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
