<template>
  <form>

    <div class="card card-primary">
      <div class="card-header background-principal">
        <h3 class="card-title">Busca por Coisa</h3>
        <!-- /.card-tools -->
      </div>

      <div class="card-body">

        <div class="form-group">
          <label for="inputTipoObjeto" class="pesquisar-tipo-objeto">Selecione o tipo do objeto</label>
          <!-- <select type="select" class="form-control form-control-border border-width-2" id="inputMarca"
           placeholder="Selecione o tipo do objeto" v-model="tipoObjeto">
           <option disabled value="">Selecione um tipo de objeto</option>
           <option :key="id" v-for="(tiposObjeto, id) in tiposObjeto" :value=tiposObjeto.id> {{
               tiposObjeto.descricao
           }}</option>
         </select> -->
          <v-select class="border-v-select" id="inputCorDoCarro" v-model="tipoObjeto"
            :disabled="
              tiposObjeto == null ||
              tiposObjeto == []
            " :options="tiposObjeto" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
        <div v-if="tipoObjeto != ''" class="form-group">
          <label for="inputQuantidade" class="pesquisar-quantidade">Digite a quantidade</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputQuantidade"
            placeholder="Digite a quantidade" v-model="quantidade" @input="
              (event) =>
              (quantidade = event.target.value
                .normalize('NFD')
                .replace(/[^0-9]+/g, '')
                .toLowerCase())
            " />

        </div>
      </div>
      <!-- /.card-header -->
      <!-- </div> -->

      <div class="card-footer">
        <button @click.prevent="emitirConsulta" v-on:keyup.enter="emitirConsulta" type="submit"
          class="btn col-12 background-principal text-color-principal">
          <i class="fas fa-search"></i>
          Buscar
        </button>
      </div>

      <!-- /.card-body -->
      <!-- /.card-footer -->
    </div>
    <!-- /.card -->
  </form>

</template>

<script>
import ConsultaListasService from '@/service/ConsultaListasService.js';
import { EventBus } from '../../../main.js';
import LocalIpService from "@/service/LocalIpService";

export default {
  name: 'FormCoisaConsulta',
  data() {
    return {
      tipoObjeto: '',
      tiposObjeto: [],
      quantidade: '',
      page: 1,
      //ipUsuario:null
    }
  },
  created() {
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    ConsultaListasService.buscarListaPorNomeTabela('tipo_objeto')
      .then((response) => {
        this.tiposObjeto = response.data
      })
      .catch((e) => this.falhaBuscarListaPorNomeTabela());
  },
  methods: {
    mostrarAlerta() {
      // eslint-disable-next-line no-undef
      Toast.fire('Preencha ao menos um campo!!', '', 'error')
    },
    formValido() {
      if (
        this.tipoObjeto ||
        this.quantidade
      ) {
        return true
      }
      return false
    },
    emitirConsulta() {
      if (this.formValido()) {
        const params = {
          tipoObjeto: this.tipoObjeto ? this.tipoObjeto.id : '',
          quantidade: this.quantidade,
          page: this.page,
          //ipUsuario:this.ipUsuario
        }
        console.log("aqui")
        EventBus.$emit('consultaCoisa', params)
      } else {
        this.mostrarAlerta()
      }
    },
    falhaBuscarListaPorNomeTabela() {
      Toast.fire("Falha ao buscar tipo do objeto", "", "error");
    },
  }
}
</script>

<style>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}
</style>
