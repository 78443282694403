import axios from "axios";

import { UrlApiOauth } from "@/main";
import authHeader from "@/service/auth/auth-header";
import tokenService from '@/service/TokenService';
// const API_URL = "http://172.20.1.32:8086";

const basic_auth = "Basic c2lnbWE6czFnbUA1NXBAKiM=";
const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Accept: "*/*",
  Authorization: basic_auth,
};

class AuthService {
  // login(user) {
  //   return axios
  //     .post(ApiGateway + "/api/auth/login", {
  //       login: user.login,
  //       senha: user.senha,
  //     })
  //     .then((response) => {
  //       if (response.data.token) {
  //         localStorage.setItem("user", JSON.stringify(response.data));
  //       }
  //       return response.data;
  //     });
  // }

  login(tokenEndpoint, user) {
    return axios
      .post(tokenEndpoint, user, { headers: headers })
      // .then((response) => {
      //   //localStorage.setItem("user", JSON.stringify(response.data));

      //   //this.$router.push("/");
      // })
      .catch((error) => {
        console.error(error);
      });
  }
  // refreshToken(user) {
  //   axios
  //     .get(ApiGateway + "/api/auth/token/refresh", { headers: authHeader() })
  //     .then((response) => {
  //       if (response.data.token) {
  //         // localStorage.setItem("user", JSON.stringify(response.data));
  //         let usuario = JSON.parse(localStorage.getItem("user"));
  //         usuario.token = response.data.token;
  //         usuario.refreshToken = response.data.refreshToken;
  //         localStorage.setItem("user", JSON.stringify(usuario));
  //       }
  //       return response.data;
  //     });

  //   return "ok";
  // }

  refreshToken() {

    const data = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: JSON.parse(localStorage.getItem("user")).refresh_token,
      // redirect_uri: redirectUri,
      // client_id: clientId,
      // code_verifier: codeVerifier,
    });

    let urlCompleta = UrlApiOauth + "/oauth2/token";

    axios
      .post(urlCompleta, data, { headers })
      .then((response) => {

        console.log(response);

        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));

          let token = JSON.stringify(response.data).access_token;
          let tokenResolvido = tokenService.parseJwt(token);

          this.$store.commit("loginSuccess", tokenResolvido.usuario);
        }
        return response.data;
      });

    return "ok";
  }
  logout(data) {
    axios
      .post(UrlApiOauth + "/oauth2/revoke", data, { headers })
      .then((response) => {
        console.log(response);
      });

    const token = new URLSearchParams({
      token: JSON.parse(localStorage.getItem("user")).access_token,
    });

    axios
      .post(UrlApiOauth + "/oauth2/revoke", token, { headers })
      .then((response) => {
        console.log(response);
      });

  }

}
export default new AuthService();
