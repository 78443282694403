<template>
     <div class="card-body pt-0">
        <div class="ml-2">
          <div class="p-2">
        <FiltroTabelaSancao/>
          </div>
      
      </div>
    
    <Loader :active="loaderActive" message="Consultando..." />
    <table v-show="!loaderActive" class="table table-hover text-nowrap">
      <thead>
        <tr>
          <th>IdContratação</th>
          <th>idContrato</th>
          <th>CpfCnpj</th>
          <th>Numero</th>
          <th>Ano</th>
          <th>Ações</th>
          
        </tr>
      </thead>
      <tbody>
        <tr class= "clicavel" @click.prevent="funcao(sancao.id)" v-for = "(sancao, index) in sancaos" :key="index">
            <td>{{sancao.idContratacao || "Sem registro"}}</td>
            <td>{{sancao.idContrato || "Sem registro"}}</td>
            <td>{{sancao.cpfCnpj || "Sem registro"}}</td>
            <!--td>{{sancao.tipo || "Sem registro"}}</td-->
             <td>{{sancao.numeroLei || "Sem registro"}}</td>
            <td>{{sancao.anoLei || "Sem registro"}}</td>
            <!--td>{{updateZonedDateTimeField(sancao.dataNotificaca)}}</td-->
            <!--td>{{updateZonedDateTimeField(sancao.dataInicio) }}</td-->
            <!--td>{{updateZonedDateTimeField(sancao.dataFim)}}</td-->
            <!--td>{{sancao.amplitude || "Sem registro"}}</td-->
            <td @click.stop>
            <button  @click.prevent="downloadFile(sancao.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm gerar">
              <i class="fa-solid fa-file"></i>
            </button>
            <button @click.prevent="excluir(sancao.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm excluir">
              <i class="fa-solid fa-trash"></i>
            </button>
            </td>
           

        </tr>
      </tbody>
    </table>
    </div>

</template>

<script>
import processosService from '@/service/ProcessosService';
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import { EventBus } from '@/main';
import Loader from "../Loader.vue";
import FiltroTabelaSancao from "@/components/processos/FiltroTabelaSancao.vue";

import axios from 'axios';

export default ({
 components:{
      Loader,
      FiltroTabelaSancao
      
    },
    data(){
        return{
            loaderActive:false,
            sancaos:{},
            sancao:{}
        } 
    
    },

    methods:{

        showLoader() {
          this.loaderActive = true;
        },
        hideLoader() {
          this.loaderActive = false;
        },

       funcao(sancao){
        EventBus.$emit("AlterarTabSancao")
        EventBus.$emit("EnvioSancao",sancao)

      },
        enviarFormulario(){
            this.dados.push({
                idContratacao: this.idContratacao,
                idContrato: this.licitacao,
                cpfCnpj:this.cpfCnpj,
                tipo: this.valor,
                numeroLei: this.numeroLei,
                anoLei: this.anoLei,
                dataNotificacao: this.dataNotificacao,
                dataInicio: this.dataInicio,
                dataFim: this.dataFim,
                amplitude: this.amplitude
                
            })
        },

      updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
      },

      excluir(id){
        this.$swal.fire({
        title: "Você deseja excluir ata?",
        icon:"warning",
        showCancelButton: true,
        confirmButtonColor:"#3085d6",
        confirmButtonText: "#Excluir",
        cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            processosService.apagarSancao(id).then((response) => {
              this.sancaos = response.data;
              Toast.fire("Sancão excluído com sucesso!", "","success");
              this.buscarSancao();
            });
          }
          this.buscarSancao(0);
        });
      },

      buscarSancao(){
        processosService.buscarSancao(this.sancaos).then((resp)=>{
        this.sancaos= resp.data;
        })
      },

      downloadFile(id) {
              axios({
                    url: `http://localhost:8089/api/download/sancoes/${id}`, // File URL Goes Here
                    method: 'GET',
                   responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'sancao.json');
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
          }
    ,
        
    },


created(){

  this.downloadFile();
  this.showLoader()
  processosService.buscarSancao(this.sancaos).then((resp)=>{
        this.sancaos= resp.data;
        this.hideLoader()
        console.log(this.sancaos);
      }).catch((e) =>{
         this.hideLoader()
          Toast.fire("Erro ao consultar Sanção", "", "error");
      } );
}



});


</script>

<style scoped>
.clicavel{
  cursor: pointer;
}
</style>