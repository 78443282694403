<template>
  <div class="card-body">
    <Loader :active="loaderActive" message="Enviando..." />
    <div class="submit-form" v-if="!loaderActive">
      <div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-assunto">Tipo Documento <span
                  class="text-danger">*</span>
              </label>
              <v-select class="border-v-select" id="documento-tipoDocumento" :class="{
                'is-invalid': isSaving && $v.documento.tipoDocumento.$error,
              }" data-cy="tipoDocumento" name="tipoDocumento" v-model="documento.tipoDocumento" @input="mudarForm"
                :options="tipoDocumento" :reduce="(tipoDocumento) => tipoDocumento.id" label="descricao">
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
              <div v-if="isSaving && !$v.documento.tipoDocumento.required" class="invalid-feedback">
                Tipo documento é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-assunto">Tramitação <span
                  class="text-danger">*</span></label>
              <v-select class="border-v-select" id="documento-tramitacao" data-cy="tramitacao"
                name="tramitacao" v-model="documento.origemDocumento" :disabled="
                  documento.tipoDocumento == null ||
                  documento.tipoDocumento != 'OF'
                " :class="{
                  'is-invalid': isSaving && $v.documento.origemDocumento.$error,
                }" @input="mudarFormTramitacao" :options="tipoTramitacao" label="descricao">
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
              <div v-if="isSaving && !$v.documento.origemDocumento.required" class="invalid-feedback">
                Tramitação documento é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-dataExpedicao">Data Expedição <span
                  class="text-danger">*</span></label>
              <!-- <div class="d-flex"> -->
              <input id="documento-dataExpedicao" data-cy="dataExpedicao" type="datetime-local"
                class="form-control form-control-border" name="dataExpedicao" :class="{
                  'is-invalid': isSaving && $v.documento.dataExpedicao.$error,
                }" :model="convertDateTimeFromServer(documento.dataExpedicao)"
                @change="updateZonedDateTimeField('dataExpedicao', $event)" />
              <div v-if="isSaving && !$v.documento.dataExpedicao.required" class="invalid-feedback">
                Data expedição documento é obrigatório
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
        <component :is="componente" ref="corpo-documento" />
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <textarea class="form-control" id="documento-corpo-novo" ref="documento-corpo-novo" data-cy="corpo"
                name="corpo" v-model="documento.corpo" v-if="text == 'novo'">
              </textarea>
              <textarea class="form-control" id="documento-corpo-editar" ref="documento-corpo-editar" data-cy="corpo"
                name="corpo" v-model="documento.corpo" v-else>
              </textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <div class="btn btn-default btn-file">
                <i class="fas fa-paperclip"></i> Anexo
                <input class="hidden" type="file" accept=".pdf" name="anexoDocumento" id="anexoDocumento" multiple />
                <input type="file" accept=".pdf" @change="carregarAnexos" multiple />
              </div>
              <!-- <p class="help-block">Max. 32MB</p> -->
              <div class="help-block with-errors" v-if="anexoInvalido">
                Permitido apenas arquivos pdf
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="anexosDocumentoTemp.length">
          <div class="box-footer card-footer bg-white">
            <ul class="mailbox-attachments">
              <li v-for="anexoDocumento in anexosDocumentoTemp" v-bind:key="anexoDocumento.id">
                <span class="mailbox-attachment-icon"><i class="fas fa-file-pdf"></i></span>
                <div class="mailbox-attachment-info">
                  <a href="#" class="mailbox-attachment-name"><i class="fas fa-paperclip"></i> {{ anexoDocumento.name }}
                  </a>
                  <span class="mailbox-attachment-size clearfix mt-1">
                    <span>{{
                    calaculaTamanhoArquivo(anexoDocumento.size)
                    }}</span>
                    <a @click="removeLinhaAnexo(anexoDocumento.id)" class="btn btn-default btn-sm float-right"><i
                        class="fas fa-trash fa-lg"></i></a>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="float-right">
      <button @click="cancelarNovoDocumento" class="btn btn-default mr-1">
        <i class="fas fa-xmark"></i> Cancelar
      </button>
      <button @click.prevent="salvarDocumento" class="btn btn-primary">
        <i class="fas fa-save"></i> Salvar
      </button>
    </div>
    <!-- <button type="reset" class="btn btn-default">
      <i class="fas fa-times"></i> Discard
    </button> -->
  </div>
</template>
<script>
import DE from "@/components/documentos/DespachoFormularioDocumento.vue";
import EX from "@/components/documentos/ExternoFormularioDocumento.vue";
import {
  default as CI,
  default as IN
} from "@/components/documentos/InternoFormularioDocumento.vue";
import documentoService from "../../service/DocumentoService";

import { required } from "vuelidate/lib/validators";

import { EventBus } from "@/main.js";
import converteFuncionario from "@/util/converteFuncionario";
import { DATE_TIME_LONG_FORMAT } from "@/util/filter";
import dayjs from "dayjs";
import Loader from "../Loader.vue";

export default {
  name: "Documentos",
  components: { IN, EX, CI, DE, Loader },
  computed: {
    usuario() {
      return this.$store.state.auth.user.usuario;
    },
  },
  data() {
    return {
      documento: {},
      novoDocumentoDTO: {},
      listaCopiaDocimento: [],
      anexosDocumento: [],
      anexosDocumentoTemp: [],
      loaderActive: false,
      submitted: false,
      isSaving: false,
      assunto: false,
      atualizou: false,
      modelo: false,
      anexoInvalido: false,
      numeracao: null,
      assinar: false,
      componente: "",
      corpo: "",
      titulo: "Novo Documento",
      tipoDocumento: [
        { descricao: "Comunicação Interna", id: "CI" },
        { descricao: "Ofício", id: "OF" },
        { descricao: "Despacho", id: "DE" },
      ],
      tipoTramitacao: [
        { descricao: "Interna", id: "IN" },
        { descricao: "Externa", id: "EX" },
      ],
    };
  },
  props: {
    text: {
      type: String,
      default: "editar",
    },
  },
  created() {
    EventBus.$on("editarUsarModeloDocumento", (documento) => {

      EventBus.$emit("abaAtiva", "ND");

      if (documento.origemDocumento != "Externa") {
        documento.fkUsuario =
          converteFuncionario.funcionarioDtoToFuncionarioSimples(
            documento.fkUsuario
          );
        documento.fkUsuarioDestino =
          converteFuncionario.funcionarioDtoToFuncionarioSimples(
            documento.fkUsuarioDestino
          );
      }
      switch (documento.origemDocumento) {
        case "IN":
          documento.origemDocumento = "Interna";
          break;
        case "EX":
          documento.origemDocumento = "Externa";
          break;
      }
      this.documento = documento;
      // console.log('Documento data ', this.documento)
      if (documento.id == null) {
        this.modelo = true;
        // this.componente = documento.
      }

      if (documento.tipoDocumento != "OF") {
        this.componente = documento.tipoDocumento;
      } else if (documento.origemDocumento == "Interna") {
        this.componente = "IN";
      } else {
        this.componente = "EX";
      }
      // else {
      //   let event;
      //   if (documento.tipoDocumento != "OF") {
      //     this.componente = documento.tipoDocumento;
      //   } else if (documento.origemDocumento == "Interna") {
      //     this.componente = "IN";
      //   } else {
      //     this.componente = "EX";
      //   }
      // }
      $(function () {
        $("#documento-corpo-editar").summernote({
          lang: "pt-BR",
          height: 400,
          disableResizeEditor: true,
          toolbar: [
            ["history", ["undo", "redo"]],
            ["font", ["bold", "italic", "underline"]],
            ["fontsize", ["fontsize"]],
            ["height", ["height"]],
            ["para", ["paragraph", "ul", "ol"]],
            ["table", ["table"]],
          ],
          nonbreaking_force_tab: true,
        });
        $("#documento-corpo-editar").summernote("fontSize", 12);
        $('#documento-corpo-editar').summernote('fontSizeUnit', 'pt');
        $("#documento-corpo-editar").summernote("fontName", "Times New Roman");
      });
    });
    EventBus.$on("responderDocumento", (documento) => {
      // console.log('Documento depois de clicar ', documento)
      this.documento.fkDocumentoOrigem = documento;

      this.documento.referencia = documento.referencia
    });

    $(function () {
      $("#documento-corpo-novo").summernote({

        lang: "pt-BR",
        height: 400,
        disableResizeEditor: true,
        toolbar: [
          ["history", ["undo", "redo"]],
          ["font", ["bold", "italic", "underline"]],
          ["fontsize", ["fontsize"]],
          ["height", ["height"]],
          ["para", ["paragraph", "ul", "ol"]],
          ["table", ["table"]],
        ],
        nonbreaking_force_tab: true,
        tableClassName: function () {
          this.className = 'table table-bordered';
          this.style.width = '100%'
          this.style.border = 'solid 1px black'
          this.style.fontFamily = 'Times New Roman'
          // this.style.cssText = '';
        }
      });
      $("#documento-corpo-novo").summernote("fontSize", 12);
      $('#documento-corpo-novo').summernote('fontSizeUnit', 'pt');
      $("#documento-corpo-novo").summernote("fontName", "Times New Roman");
    });
  },
  updated() {
    if (!this.atualizou) {
      if (this.$refs["corpo-documento"] != undefined) {
        this.$refs["corpo-documento"].documento = Object.assign(
          {},
          this.$refs["corpo-documento"].documento,
          this.documento
        );
        this.atualizou = true;

        if (this.$refs["corpo-documento"].carregaFuncionario != undefined) {
          this.$refs["corpo-documento"].carregaFuncionario(
            this.documento.fkSetorDestino
          );
        }
      }
    }
  },
  validations: {
    documento: {
      tipoDocumento: { required },
      origemDocumento: { required },
      dataExpedicao: { required },
    },
  },
  methods: {
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    carregaDocumento(documento) {
      this.componente = documento.tipoDocumento;
      this.documento = documento;
    },
    salvarDocumento() {

      this.isSaving = true;
      if (this.documento.id) {
        this.isSaving = true;
        this.$v.$touch();
        if (this.$refs["corpo-documento"] != undefined) {
          this.documento = Object.assign(
            {},
            this.documento,
            this.$refs["corpo-documento"].documento
          );
          this.$refs["corpo-documento"].isSaving = this.isSaving;
          if (this.$v.$invalid || this.$refs["corpo-documento"].$v.$invalid) {
            return;
          }
        } else {
          if (this.$v.$invalid) {
            return;
          }
        }
        this.documento.tipoDocumento = this.documento.tipoDocumento;
        this.documento.corpo = $("#documento-corpo-editar").val();

        this.novoDocumentoDTO.documentoDTO = this.documento;

        this.showLoader();

        documentoService
          .update(this.novoDocumentoDTO)
          .then((param) => {
            this.hideLoader();
            this.isSaving = false;
            EventBus.$emit("alteraAcaoConteudo", "VD");
            EventBus.$emit("abaAtiva", "VD");
            EventBus.$emit("enviarPdf", param.data.id);
            const message = "Documento atualizado com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
          })
          .catch((error) => {
            this.hideLoader();
            this.isSaving = false;
            Toast.fire(error.response.data.detail.toString(), "", "error");
          });
      } else {
        this.isSaving = true;
        this.$v.$touch();
        if (this.$refs["corpo-documento"] != undefined) {
          this.documento = Object.assign(
            {},
            this.documento,
            this.$refs["corpo-documento"].documento
          );

          this.$refs["corpo-documento"].isSaving = this.isSaving;

          this.listaCopiaDocimento =
            this.$refs["corpo-documento"].listaCopiaDocumento;

          if (this.$v.$invalid || this.$refs["corpo-documento"].$v.$invalid) {
            return;
          }
        } else {
          if (this.$v.$invalid) {
            return;
          }
        }
        this.documento.tipoDocumento = this.documento.tipoDocumento;

        if (this.modelo) {
          if (this.$refs["documento-corpo-editar"].value != "") {
            this.documento.corpo = this.$refs["documento-corpo-editar"].value;
          } else {
            this.documento.corpo = this.corpo;
          }

          // this.$refs["documento-corpo-editar"].value;
        } else {
          if (this.$refs["documento-corpo-novo"].value != "") {
            this.documento.corpo = this.$refs["documento-corpo-novo"].value;
          } else {
            this.documento.corpo = this.corpo;
          }
        }

        // this.novoDocumentoDTO.documentoDTO = this.documento;

        if (this.anexosDocumentoTemp.length > 0) {
          // let temp = {};

          this.novoDocumentoDTO.documentoDTO = this.documento;
          this.novoDocumentoDTO.anexosDocumento = this.anexosDocumentoTemp;
          // temp.anexosDocumento = JSON.stringify(this.anexosDocumentoTemp);

          // this.novoDocumentoDTO = temp;
        } else {
          this.novoDocumentoDTO.documentoDTO = this.documento;
        }

        //  this.novoDocumentoDTO.documentoDTO = this.documento;

        // this.novoDocumentoDTO.anexosDocumento = this.anexosDocumentoTemp;

        if (this.documento.fkUsuario.id === this.usuario.idFuncionario) {
          this.$swal
            .fire({
              title: "O que deseja fazer?",
              icon: "warning",
              showCancelButton: true,
              cancelButtonColor: "#28a745",
              confirmButtonText: "Assinar e salvar",
              cancelButtonText: "Salvar",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {

                documentoService
                  .verificarNumerecao(this.documento.tipoDocumento)
                  .then((response) => {
                    // console.log('Response ', response)
                    if (!response.data) {
                      this.$swal
                        .fire({
                          title: "Assinar documento",
                          html: '<div class="alert alert-danger" role="alert">Ainda não há documento deste tipo gerado na sua unidade. Informe o último número impresso</div>',
                          input: "number",
                          focusConfirm: false,
                          showCancelButton: true,
                          cancelButtonColor: "#28a745",
                          confirmButtonText: "Assinar e salvar",
                          cancelButtonText: "Salvar",
                          reverseButtons: true,
                          preConfirm: (numero) => {
                            if (!numero) {
                              return "You need to write something!";
                            } else {
                              this.numeracao = numero;
                            }
                          },
                        })
                        .then((resultado) => {
                          if (resultado.isConfirmed) {
                            this.numeracao = resultado.value;
                            this.assinar = true;
                            this.showLoader();
                            documentoService
                              .criarDocumento(
                                this.novoDocumentoDTO,
                                this.listaCopiaDocimento,
                                this.numeracao,
                                this.assinar
                              )
                              .then((param) => {
                                this.hideLoader();
                                this.isSaving = false;
                                EventBus.$emit("alteraAcaoConteudo", "EN");
                                EventBus.$emit("abaAtiva", "EN");
                                const message =
                                  "Documento criado com sucesso!!! ";
                                Toast.fire(message.toString(), "", "success");
                              })
                              .catch((error) => {
                                this.hideLoader();
                                this.isSaving = false;
                                Toast.fire(
                                  error.response.data.toString(),
                                  "",
                                  "error"
                                );
                              });
                          } else {
                            this.numeracao = 0;
                            this.assinar = false;
                            this.showLoader();
                            documentoService
                              .criarDocumento(
                                this.novoDocumentoDTO,
                                this.listaCopiaDocimento,
                                this.numeracao,
                                this.assinar
                              )
                              .then((param) => {
                                this.hideLoader();
                                this.isSaving = false;
                                EventBus.$emit("alteraAcaoConteudo", "EN");
                                EventBus.$emit("abaAtiva", "EN");
                                const message =
                                  "Documento criado com sucesso!!! ";
                                Toast.fire(message.toString(), "", "success");
                              })
                              .catch((error) => {
                                this.hideLoader();
                                this.isSaving = false;
                                Toast.fire(
                                  error.response.data.toString(),
                                  "",
                                  "error"
                                );
                              });
                          }
                        });
                    } else {
                      this.numeracao = 0;
                      this.assinar = true;
                      this.showLoader();

                      documentoService
                        .criarDocumento(
                          this.novoDocumentoDTO,
                          this.listaCopiaDocimento,
                          this.numeracao,
                          this.assinar
                        )
                        .then((param) => {
                          this.hideLoader();
                          this.isSaving = false;
                          EventBus.$emit("alteraAcaoConteudo", "EN");
                          EventBus.$emit("abaAtiva", "EN");
                          const message = "Documento criado com sucesso!!! ";
                          Toast.fire(message.toString(), "", "success");
                        })
                        .catch((error) => {
                          this.hideLoader();
                          this.isSaving = false;
                          Toast.fire(
                            error.response.data.toString(),
                            "",
                            "error"
                          );
                        });
                    }
                  });
              } else {
                this.numeracao = 0;
                this.assinar = false;
                this.showLoader();
                documentoService
                  .criarDocumento(
                    this.novoDocumentoDTO,
                    this.listaCopiaDocimento,
                    this.numeracao,
                    this.assinar
                  )
                  .then((param) => {
                    this.hideLoader();
                    this.isSaving = false;
                    EventBus.$emit("alteraAcaoConteudo", "EN");
                    EventBus.$emit("abaAtiva", "EN");
                    const message = "Documento criado com sucesso!!! ";
                    Toast.fire(message.toString(), "", "success");
                  })
                  .catch((error) => {
                    this.hideLoader();
                    this.isSaving = false;
                    Toast.fire(error.response.data.toString(), "", "error");
                  });
              }
            });
        } else {
          this.numeracao = 0;
          this.assinar = false;

          if (this.listaCopiaDocimento === undefined) {
            this.listaCopiaDocimento = null
          }

          this.showLoader();
          documentoService
            .criarDocumento(
              this.novoDocumentoDTO,
              this.listaCopiaDocimento,
              this.numeracao,
              this.assinar
            )
            .then((param) => {
              this.hideLoader();
              this.isSaving = false;
              EventBus.$emit("alteraAcaoConteudo", "EN");
              EventBus.$emit("abaAtiva", "EN");
              const message = "Documento criado com sucesso!!! ";
              Toast.fire(message.toString(), "", "success");
            })
            .catch((error) => {
              this.hideLoader();
              this.isSaving = false;
              Toast.fire(error.response.data.toString(), "", "error");
            });
        }
      }
    },
    mudarForm(event) {
      if (event != "OF") {
        this.documento.origemDocumento = "Interna";
        this.componente = event;
      } else {
        this.documento.origemDocumento = "";
      }
    },
    mudarFormTramitacao(event) {
      this.componente = event.id;
      this.documento.origemDocumento = event.descricao;
    },
    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_TIME_LONG_FORMAT);
      }
      return null;
    },
    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.documento[field] = dayjs(
          event.target.value,
          DATE_TIME_LONG_FORMAT
        );
      } else {
        this.documento[field] = null;
      }
    },
    cancelarNovoDocumento() {
      EventBus.$emit("alteraAcaoConteudo", "EN");
      EventBus.$emit("abaAtiva", "EN");
    },
    carregarAnexos(event) {
      if (this.$refs["documento-corpo-novo"].value != "") {
        this.corpo = this.$refs["documento-corpo-novo"].value;
      }

      let pdfValido = true;

      Array.from(event.target.files).forEach((file) => {
        if (file.type != "application/pdf") {
          pdfValido = false;
        }
      });
      if (pdfValido) {
        Array.from(event.target.files).forEach((file) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Use a regex to remove data url part
            const base64String = reader.result;
            // .replace('data:', '')
            // .replace(/^.+,/, '');

            let arquivoAnexo = {};

            arquivoAnexo.id = Math.floor(Date.now() * Math.random()).toString(
              36
            );
            arquivoAnexo.arquivo = base64String;

            this.anexosDocumentoTemp.push(arquivoAnexo);
            // this.anexosDocumentoTemp.id = Math.floor(Date.now() * Math.random()).toString(36);

            // console.log(base64String);
            // Logs wL2dvYWwgbW9yZ...
          };

          reader.readAsDataURL(file);

          // this.anexosDocumentoTemp.push(file);
          // this.anexosDocumento.push(file);
        });
      } else {
        this.anexoInvalido = true;
      }

      // console.log(this.anexosDocumentoTemp);
    },
    calaculaTamanhoArquivo(value) {
      let const_term = 1024;
      let retorno = (value / const_term ** 2).toFixed(1);
      if (retorno < 1) {
        return (value / const_term).toFixed(3) + " KB";
      }
      return retorno + " MB";
    },
    removeLinhaAnexo(linhaAnexo) {
      this.anexosDocumentoTemp = this.anexosDocumentoTemp.filter(
        (item) => item.id !== linhaAnexo
      );
    },
  },
};
</script>

<style scoped>

</style>
