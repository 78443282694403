<template>
     <div class="card-body pt-0">
       <div class=" p-1, .ml-1 (margin-right: 4px;)">
          <FiltroTabelaCadastroOrgao/>
        </div>
   
    <Loader :active="loaderActive" message="Consultando..." />
    <table v-show="!loaderActive" class="table table-hover text-nowrap">
      <thead>
        <tr>
          <th>IdContratação</th>
          <th>Cpf e Cnpj</th>
          <th>Numero</th>
          <th>Ano</th>
          <th>Perfil</th>
          <th>Ações</th>
          
        </tr>
      </thead>
      <tbody>
        <tr class= "clicavel" @click="funcao(orgao.id)"  v-for = "(orgao, index) in cadastrosOrgao" :key="index">
          <td>{{ orgao.idContratacao || "Sem registro" }}</td>
          <td>{{ orgao.cpfCnpj || "Sem registro" }}</td>
          <td>{{ orgao.numero || "Sem registro"}}</td>
          <td>{{ orgao.ano || "Sem registro"}}</td>
          <td>{{ orgao.perfil || "Sem registro" }}</td>
          <td @click.stop>
            <button  @click.prevent="downloadFile(orgao.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm gerar">
              <i class="fa-solid fa-file"></i>
            </button>
             <button @click.prevent="excluir(orgao.id)" type="button" style="border:none;!important" class="btn btn-default btn-sm excluir">
              <i class="fa-solid fa-trash"></i>
            </button>
          </td>
          

         
        </tr>
      </tbody>
    </table>
    </div>

</template>

<script>
import processosService from '@/service/ProcessosService';
import { EventBus } from '@/main';
import Loader from "../Loader.vue";
import FiltroTabelaCadastroOrgao from "@/components/processos/FiltroTabelaCadastroOrgao.vue";
import axios from 'axios';

export default ({
   components:{
      Loader,
      FiltroTabelaCadastroOrgao
    },
    data(){
      return{
        loaderActive:false,
        cadastrosOrgao:{},
        orgao:{}
        }
      },
      
    
    

    methods:{

      showLoader() {
        this.loaderActive = true;
      },
      hideLoader() {
        this.loaderActive = false;
      },

      funcao(orgao){
        EventBus.$emit("AlterarTabCadastroOrgao")
        EventBus.$emit("EnvioCadastroOrgao",orgao)

      },
        enviarFormulario(){
            this.dados.push({
                idContratacao: this.idContratacao,
                cpfCnpj: this.cpfCnpj,
                numero: this.numero,
                ano: this.ano,
                perfil: this.perfil
                
            })
        },

        excluir(id){
          this.$swal.fire({
          title: "Você deseja excluir cadastro?",
          icon:"warning",
          showCancelButton: true,
          confirmButtonColor:"#3085d6",
          confirmButtonText: "#Excluir",
          cancelButtonText: "Cancelar",
          }).then((result) => {
            if(result.isConfirmed){
              processosService.apagarCadastroOrgao(id).then((response)=>{
                this.cadastrosOrgao  = response.data;
                Toast.fire("Cadastro excluída com sucesso!", "","success");
                this.buscarCdastroOrgao();
              });
            }
            this.buscarCdastroOrgao(0);

          });

        },


        buscarCdastroOrgao(){
          processosService.buscarCadastroOrgao(cadastrosOrgao).then((resp)=>{
          this.cadastrosOrgao= resp.data;
          
        })
        },

      
        downloadFile(id) {
              axios({
                    url: `http://localhost:8089/api/download/cadastro-orgaos/${id}`, // File URL Goes Here
                    method: 'GET',
                   responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'cadastroOrgao.json');
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
          }
    ,
        
    },


  created(){
    this.downloadFile();
    this.showLoader()

    processosService.buscarCadastroOrgao().then((resp)=>{
    this.cadastrosOrgao= resp.data;
    this.hideLoader()
    }).catch((e) =>{
    this.hideLoader()
    Toast.fire("Erro ao consultar Orgão", "", "error");
    });
  
  

  
}



});


</script>

<style scoped>
.clicavel{
  cursor: pointer;
}
</style>