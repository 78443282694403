<template>
  <div>
    <h6 for="editarUnidadeSubordinada"><b>E-MAIL DA UNIDADE:</b></h6>
    <form class="row">
      <div class="form-group col-lg-3 col-sm-12">
        <label for="nome">Email</label>

        <input type="text" class="form-control form-control-border border-width-2" id="emailUnidade"
          v-model="email.email" />

      </div>
      <div class="form-group col-lg-3 col-sm-12">
        <label for="estado">Tipo de Email</label>
        <v-select class="border-v-select" id="estado" v-model="email.tipoEmail"
          :options="['PESSOAL', 'CORPORATIVO']" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>

      </div>

      <div class="form-group col-12">
        <button class="btn background-principal text-color-principal"
          @click.prevent="$emit('FecharAdicaoDados'), $emit('voltarParaUnidadeDetalhe')">
          <i class="fas fa-arrow-left"></i>
          Voltar
        </button>
        <button class="btn btn-primary float-right" @click.prevent="salvarEmailUnidade">
          <i class="fa-solid fa-floppy-disk"></i> Salvar
        </button>
      </div>

    </form>
  </div>
</template>
<script>
import { EventBus } from '@/main';
import UnidadeService from '@/service/UnidadeService';

export default {
  name: 'FormCadastroEmailUnidadeSubordinada',
  data() {
    return {
      idUnidade: '',
      email: {},
    }
  },
  created() {
    EventBus.$on('ResultadoIdUnidadeCriada', (event) => {
      this.idUnidade = event
    })
    EventBus.$on('unidadeSelecionadaAdicaoEmail', (event) => {
      this.idUnidade = event
    })
    EventBus.$on('resetarFormularioUnidade', (event) => { this.resetarForm() })
  },
  methods: {
    resetarForm() {
      this.email = {}
    },
    alertaSucessoCadastroEmail() {
      Toast.fire("Sucesso ao cadastrar e-mail da unidade", "", "success");
    },
    alertaFalhaCadastroEmail() {
      Toast.fire("Falha ao cadastrar e-mail da  unidade", "", "error");
    },


    salvarEmailUnidade() {
      const data = {
        idUnidade: this.idUnidade,
        tipoEmail: (this.email.tipoEmail ? this.email.tipoEmail : null),
        email: this.email.email,

      }

      UnidadeService.cadastrarUnidadeEmail(data).then((response) => {
        this.alertaSucessoCadastroEmail();
        this.$emit('esconderFormEmail')
        this.email = {}
        this.$emit('FecharAdicaoDados')
      }).catch((e) => {
        this.alertaFalhaCadastroEmail();
      })
    }
  }
}
</script>
