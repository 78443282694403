<template>
  <div class="ml-2">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-2">
            <h1 class="py-0">
              siduougfsdf<span class="badge badge-warning navbar-badge">15</span>
            </h1>
          </div>
          <div class="col-sm-2 py-0">
            <h1 class="py-0">
              siduougfsdf
            </h1>
          </div>
          <div class="col-sm-2 py-0">
            <h1 class="py-0">
              siduougfsdf
            </h1>
          </div>
          <div class="col-sm-2 py-0">
            <h1 class="py-0">
              siduougfsdf
            </h1>
          </div>
          <div class="col-sm-2 py-0">
            <h1 class="py-0">
              siduougfsdf
            </h1>
          </div>
          <div class="col-sm-2 py-0">
            <h1 class="py-0">
              siduougfsdf
            </h1>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-2 py-0">
            <h1 class="py-0">
              siduougfsdf
            </h1>
          </div>
          <div class="btn-group col-sm-10" role="group" aria-label="Basic example">
            <button type="button" class="btn  btn-primary mb-3 background-principal">Left</button>
            <button type="button" class="btn  btn-primary mb-3 background-principal">Middle</button>
            <button type="button" class="btn  btn-primary mb-3 background-principal">Right</button>
            <button type="button" class="btn  btn-primary mb-3 background-principal">Left</button>
            <button type="button" class="btn  btn-primary mb-3 background-principal">Middle</button>
            <button type="button" class="btn  btn-primary mb-3 background-principal">Right</button>
            <button type="button" class="btn  btn-primary mb-3 background-principal">Left</button>
            <button type="button" class="btn  btn-primary mb-3 background-principal">Middle</button>
          </div>
          <div class="header-titles --titles-desktop col-sm-12" role="group" aria-label="Basic example">
            <a class="header-title homeui-tc-oglobo">Left</a>
            <a class="header-title homeui-tc-oglobo">Middle</a>
            <a class="header-title homeui-tc-oglobo">Right</a>
            <a class="header-title homeui-tc-oglobo">Left</a>
            <a class="header-title homeui-tc-oglobo">Middle</a>
            <a class="header-title homeui-tc-oglobo">Right</a>
            <a class="header-title homeui-tc-oglobo">Left</a>
            <a class="header-title homeui-tc-oglobo">Right</a>
            <a class="header-title homeui-tc-oglobo">Left</a>

          </div>
        </div>
      </div>
    </section>
    <conteudo>
      <div class="container-fluid">
        <div class="row p-2">
          <div class="col-md-8">
            <visualizador />
          </div>
          <div class="col-md-4">
            <visualizador />
          </div>
        </div>
      </div>
    </conteudo>
  </div>
</template>

<script>

import Visualizador from "@/components/documentos/DocumentoVisualizado.vue";

export default {
  components: {
    Visualizador
  }
};
</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}



.header-titles {
	grid-auto-columns: auto;
	grid-template-rows: auto;
	grid-auto-flow: column;
	height: 45px;
	justify-content: space-between
}

.header-titles.\--titles-desktop {
	display: none
}

.header-titles.\--titles-mobile {
	display: flex
}

@media print,
screen and (min-width:40em) {
	.header-titles.\--titles-desktop {
		display: flex;
	}

	.header-titles.\--titles-mobile {
		display: none
	}
}

.header-titles .header-title {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	font-family: var(--font-family-book), var(--font-family-book-fallback);
	font-size: var(--font-size-40-responsive-book);
	letter-spacing: var(--font-size-40-responsive-book-letter-spacing);
	line-height: var(--font-line-height-spaced);
	font-weight: var(--font-weight-bold);
	font-variation-settings: var(--font-variation-settings-book-normal-roman);
	font-feature-settings: var(--font-feature-settings-book-roman);
	margin-bottom: auto;
	margin-top: auto;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	padding: 10px 12px
}

.header-titles .header-title:last-child {
	margin-right: -9px
}

@media print,
screen and (min-width:40em) {
	.header-titles .header-title:last-child {
		margin-right: -5px
	}
}

@media print,
screen and (min-width:64em) {
	.header-titles .header-title:last-child {
		margin-right: -13px
	}
}

@media print,
screen and (min-width:40em) and (max-width:63.99875em) {
	.header-titles .header-title {
		padding: 10px 4px
	}
}

@media print,
screen and (max-width:39.99875em) {
	.header-titles .header-title {
		padding: 10px 8px
	}
}



.homeui-tc-oglobo {
	color: #1e4c9a
}

.homeui-tc-oglobo:hover {
	color: #4b70ae
}

</style>
