<template>
  <div>
    <form autocomplete="off">
      <b-modal
        v-model="isVisible"
        ref="modal-1"
        id="modal-1"
        :title="
          funcionarioDetalhe.pessoaCorrespondente &&
          funcionarioDetalhe.pessoaCorrespondente.nome
            ? funcionarioDetalhe.pessoaCorrespondente.nome
            : 'NÃO INFORMADO'
        "
        data-backdrop="static"
        size="xl"
        cancel-disabled
        no-close-on-backdrop
        hide-header-close
        header-class="modal-header
        background-principal
        text-color-principal
        card-item-result
      "
        content-class="modal-content border-rounded-detail"
        dialog-class="p-3"
        title-class="modal-title mx-auto my-0"
      >
        <div style="display: block" aria-modal="true" role="dialog">
          <div @click.stop>
            <div>
              <Loader :active="loaderActive" message="Consultando..." />
              <div v-show="resetSenhaShow">
                <Loader :active="loaderActive" message="Alterando senha..." />
                <div class="card-body" v-if="!loaderActive">
                  <div class="pb-1 d-flex justify-content-center col-12">
                    <h4 class="text-color-secundary">
                      Redefina a senha do funcionário:
                    </h4>
                  </div>
                  <div class="m-10 p-0 pb-1">
                    <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                      No mínimo 8 caracteres.
                    </p>
                    <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                      1 letra minúscula.
                    </p>
                    <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                      1 letra maiúscula.
                    </p>
                    <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
                      1 número ou 1 caracter especial ( !, @, #, $, %, &, *, _
                      ).
                    </p>
                  </div>
                  <div class="form-group">
                    <label>Digite sua nova senha:</label>
                    <input
                      type="password"
                      class="form-control form-control-border border-width-2"
                      id="novaSenha"
                      autocomplete="chrome-off"
                      placeholder="Digite a nova senha"
                      v-model="novaSenha"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label class="pesquisar-numero-fim-folha"
                      >Confirme sua nova senha:</label
                    >
                    <input
                      type="password"
                      class="form-control form-control-border border-width-2"
                      id="confirmarNovaSenha"
                      autocomplete="new-password"
                      placeholder="Digite a senha novamente"
                      v-model="confirmeNovaSenha"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
          <button
            type="button"
            class="btn btn-default mr-auto"
            data-dismiss="modal"
            @click="cancel()"
            @click.prevent="beforeClose"
          >
            <i class="fa-solid fa-x"></i>
            Fechar
          </button>
          <button
            @click.prevent="resetarSenhaPrimeiroAcesso()"
            type="submit"
            class="btn background-principal text-color-principal"
          >
            <i class="fa-solid fa-lock"></i>
            Redefinir Senha
          </button>
        </template>
      </b-modal>
    </form>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { EventBus } from "@/main";
import CadastroPessoaService from "@/service/CadastroPessoaService";
import ConsultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import RecuperarSenhaService from "@/service/RecuperarSenhaService";

import CadastroFuncionarioService from "@/service/CadastroFuncionarioService";

import ResetSenhaFuncionario from "./ResetSenhaFuncionario.vue";
export default {
  name: "DetalhesFuncionarioConsulta",
  components: { Loader, ResetSenhaFuncionario },
  data() {
    return {
      isVisible: false,
      funcionarioDetalhe: {},
      funcionarioEndereco: {},
      enderecoResumo: {},
      documentos: [],
      telefones: [],
      emails: [],
      resetSenhaShow: true,
      resetFuncionarioShow: false,
      loaderActive: false,
      funcionarioDetalheReset: "",
      novaSenha: "",
      confirmeNovaSenha: "",
      alerta: false,
      resultado: "",
    };
  },
  created() {
    this.novaSenha = "";
    this.confirmeNovaSenha = "";

    if (this.$store.state.funcionario.idFuncionarioCadastrado != 0) {
      CadastroFuncionarioService.buscarFuncionarioPorId(
        this.$store.state.funcionario.idFuncionarioCadastrado
      )
        .then((response) => {
          this.funcionarioDetalhe = response.data;
        })
        .catch(() => {});
    } else {
      this.funcionarioDetalhe.login = null;
    }
    EventBus.$on("openModalEdicaoSenha", () => {
      this.isVisible = true;
    });

    EventBus.$on("consultaFuncionarioDetalhe", (event) => {
      this.consultarFuncionario(event);
    });
    EventBus.$on("editarFuncionario", (listener) => {
      this.funcionarioDetalhe = listener;
    });
  },
  methods: {
    beforeClose() {
      this.novaSenha = "";
      this.confirmeNovaSenha = "";
    },
    fecharModal() {
      this.isVisible = false;
      //EventBus.$emit("closeModalEdicaoSenha")
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    alertaSenhaDiferente() {
      // eslint-disable-next-line no-undef
      Toast.fire("Preencha os dois campos igualmente!", "", "error");
    },
    alertaSenhaErrada() {
      Toast.fire("Preencha corretamente a senha", "", "error");
    },
    alertaErroCadastrar() {
      Toast.fire("Erro ao tentar redefinir a senha", "", "error");
    },
    alertaSucessoCadastro() {
      Toast.fire("Senha cadastrada com sucesso", "", "success");
    },
    validaSenha() {
      if (this.novaSenha == this.confirmeNovaSenha) {
        if (
          this.novaSenha.match(
            /^(?=.*[0-9!@#%&*_])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#%&*_]{8,})$/
          )
        ) {
          return true;
        } else {
          this.alertaSenhaErrada();
          this.alerta = true;
          return false;
        }
      } else {
        this.alertaSenhaDiferente();
        return false;
      }
    },
    resetarSenhaPrimeiroAcesso() {
      if (this.validaSenha()) {
        this.showLoader();
        try {
          RecuperarSenhaService.resetarSenhaPrimeiroAcesso(
            this.funcionarioDetalhe.login,
            this.novaSenha
          )
            .then((response) => {
              this.resultado = response.data;
              this.beforeClose();
              this.hideLoader();
              this.alertaSucessoCadastro();
              this.fecharModal();
            })
            .catch((e) => {
              this.hideLoader();
              this.alertaErroCadastrar();
            });
        } catch (e) {
          this.hideLoader();
          alert("ERRO: " + e.message);
        }
      }
    },
    //017.597.563-93 Netenho123

    mostrarAlertaFalha() {
      Toast.fire("Falha ao buscar informações do funcionário!!", "", "error");
    },
    mostrarAlertaFalhaDados() {
      Toast.fire("Falha ao buscar dados do funcionário!!", "", "error");
    },
    consultarFuncionario(resultFuncionarioSelecionado) {
      this.showLoader();
      ConsultaFuncionarioService.buscarFuncionarioId(
        resultFuncionarioSelecionado.id
      )
        .then((response) => {
          this.funcionarioDetalhe = response.data;
          this.hideLoader();
          EventBus.$emit("EdicaoFuncionarioDetalhe", this.funcionarioDetalhe);
          this.consultarDadosFuncionario(
            this.funcionarioDetalhe.pessoaCorrespondente.id
          );
        })
        .catch((e) => {
          this.hideLoader();
          this.mostrarAlertaFalha();
        });
    },
    consultarDadosFuncionario(idPessoaCorrespondente) {
      CadastroPessoaService.buscarPessoaCompletaPorId(idPessoaCorrespondente)
        .then((response) => {
          this.enderecoRecente(response);
          this.documentos = response.documentos;
          this.telefones = response.telefones;
          this.emails = response.emails;
          this.hideLoader();
        })
        .catch((e) => {
          this.mostrarAlertaFalhaDados();
          this.hideLoader();
        });
    },
    imagemRecente(data) {
      if (data.fotos && data.fotos.length > 0) {
        const base64Imagem = data.fotos.reduce(function (prev, current) {
          return prev.id > current.id ? prev : current;
        }).imagem;
        return base64Imagem;
      }
    },
    enderecoRecente(data) {
      if (data.enderecos && data.enderecos.length > 0) {
        const endereco = data.enderecos.reduce(function (prev, current) {
          return prev.id > current.id ? prev : current;
        });
        this.enderecoResumo = endereco;
        return this.enderecoResumo;
      }
    },
  },
};
</script>

<style scoped>
.img-cut {
  max-width: 200px;
  max-height: 200px;
  height: 20vh;
  width: 20vh;
  object-fit: cover;
  object-position: center top;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}
</style>
