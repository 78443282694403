<template>
  <div v-if="restricoes.length != 0"
    class="row px-5 d-flex justify-content-around table-responsive max-height-content-detail">
    <div :key="id" v-for="(restricoes, id) in restricoes"
      class="card card-item bg-light col-sm-12 col-md-12 m-10 rounded-12 p-0 ">

      <a href="#">
        <div class=" text-center">
          <p class="header background-principal text-color-principal card-item-result">
            {{ restricoes.numeroBO }}
          </p>
        </div>
        <div class="row">

          <div class="col-12 mx-0 my-2 p-0">
            <div class="row">
              <ul class="col-sm-12 col-md-3 mb-0 fa-ul text-muted">
                <li class="medium"><b>Ano do BO: </b> {{ restricoes.anoBO }}</li>
                <li class="medium"> <b>Situacao: </b> {{ restricoes.naturezaOcorrencia }}</li>
                <li class="medium"><b>Data da ocorrência: </b>{{ restricoes.dataOcorrencia }}
                </li>
              </ul>
              <ul class="col-sm-12 col-md-3 mb-0 fa-ul text-muted">
                <li class="medium">
                  <b>Local da ocorrência: </b> {{ restricoes.municipioBO }}
                </li>
                <li class="medium"><b>Declarante: </b>{{ restricoes.nomeDeclarante }}
                </li>
                <li class="medium"><b>Histórico: </b>{{ restricoes.historico }}
                </li>
              </ul>
              <ul class="col-sm-12 col-md-3 mb-0 fa-ul text-muted">
                <li class="medium">
                  <b>DDD contato: </b> {{ restricoes.dddContato }}
                </li>
                <li class="medium">
                  <b>Contato: </b> {{ restricoes.telefoneContato }}
                </li>
              </ul>
            </div>

          </div>
        </div>
      </a>
    </div>

  </div>
  <div v-else>
    <h6>NÃO HÁ RESTRIÇÕES</h6>
  </div>
</template>

<script>

export default {
  name: 'ListaRestricoesVeiculoNacional',
  props: ['restricoes']
}
</script>
