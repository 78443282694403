import "@/style/sigma.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.min.css";
import Vue from "vue";
import VueGates from "vue-gates";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSweetalert2 from "vue-sweetalert2";
import VueTheMask from "vue-the-mask";
import VueToastr from "vue-toastr";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import Content from "./components/Content.vue";
import ContentHeader from "./components/ContentHeader.vue";
import MainLayout from "./components/MainLayout.vue";
import MenuEmail from "./components/MenuEmail.vue";
import Navbar from "./components/Navbar.vue";
import NavItem from "./components/NavItem.vue";
import Sidebar from "./components/Sidebar.vue";
import router from "./router";
import store from "./store";




import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Vue.use(FormCheckboxPlugin)

export const EventBus = new Vue(); // para compartilhamento de informações entre components irmãos

Vue.config.productionTip = false;

// export const Versao = 'main';
export const Versao = 'main';
// export const ApiGateway =
//   Versao === "desenvolvimento"
//     ? "http://172.20.1.15:8010"
//     : "https://gateway.ssp.ma.gov.br";

export const ApiGateway = (() => {
  if (Versao === "desenvolvimento") {
    return "http://172.20.1.32:8010";
  } else if (Versao === "main") {
    return "https://gateway.ssp.ma.gov.br";
  } else {
    return "https://gatewaydev.ssp.ma.gov.br";
  }
})();

export const UrlApiOauth = (() => {
  if (Versao === "desenvolvimento") {
    return "http://172.20.1.32:8001";
  } else if (Versao === "main") {
    return "https://auth.ssp.ma.gov.br";
  } else {
    return "https://sichomoauth.ssp.ma.gov.br";
  }
})();

Vue.component("v-select", vSelect);
Vue.component("vue-toastr", VueToastr);
Vue.component("content-header", ContentHeader);
Vue.component("conteudo", Content);
Vue.component("menu-email", MenuEmail);
Vue.component("main-layout", MainLayout);
Vue.component("nav-bar", Navbar);
Vue.component("nav-item", NavItem);
Vue.component("side-bar", Sidebar);

Vue.use(VueTheMask);
Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(VueGates);

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  timer: 3000,
  timerProgressBar: true,
  showConfirmButton: false,
});
// salva a configuração global na palavra "Toast"
window.Toast = Toast;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
