<template>
 
    <div class="card card-primary card-tabs">
      <div class="card-header background-principal p-0 pt-1 ">
        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">

          <li class="nav-item" @click="reRender()">
              <a class="nav-link active show" 
              id="custom-tabs-one-home-tab" @click.prevent="apagarCampos" data-toggle="pill" href="#tabelalicitantes" role="tab"
              ref="tabelalicitantes" aria-controls="custom-tabs-one-home" aria-selected="true"
              >Tabela Cadastro Licitante </a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
              id="custom-tabs-one-perfil-tab"  data-toggle="pill" href="#cadastrolicitantes" role="tab"
              aria-controls="custom-tabs-one-profile" aria-selected="false" ref="cadastrolicitantes"
             >Cadastro Licitante</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="custom-tabs-one-tabContent" >
          <div class="tab-pane fade active show" id="tabelalicitantes" role="tabpanel"
            aria-labelledby="custom-tabs-one-home-tab">
             <TblCadastroLicitante :key="licitante_reload"/>
          </div>
          <div class="tab-pane fade" id="cadastrolicitantes" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
            <FormCadastroLicitante/>
          </div>
        </div>
      </div>
  </div>
  
</template>
<script>
import TblCadastroLicitante from "@/components/processos/TabelaCadastroLicitante.vue";
import FormCadastroLicitante from "@/components/processos/CadastroLicitante.vue";
import { EventBus } from "@/main";
export default {
  
  name: "TabCadastroLicitante",
  components: { TblCadastroLicitante, FormCadastroLicitante },
  data() {
    return {

      licitante_reload:1,

    }
  },
  created() {

      EventBus.$on("AlterarTabCadastroLicitante",()=>{
        this.$refs.cadastrolicitantes.click()
        
      })
    
    
  },
  methods: {

     apagarCampos(){
      EventBus.$emit("ApagarCamposCadastroLicitante")

    },

    reRender(){
      this.licitante_reload++;
    }



  }
}
</script>
<style scoped>
.text-link {
  color: #fff
}

.text-link:hover {
  color: #fff
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}
</style>
