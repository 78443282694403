<template>
  <div class="card-body pt-0">
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th class="text-center">RD</th>
          <th class="text-center">Data</th>
          <th class="text-center">Data Início</th>
          <th class="text-center">Data Fim</th>
          <th class="text-center">Qnt.</th>
          <th class="text-center">Unitario</th>
          <th class="text-center">Total</th>
          <th class="text-center">Processo</th>
          <th class="text-center">Origem</th>
          <th class="text-center">Destino</th>
        </tr>
      </thead>
      <tbody v-if="listaDiarias">
        <tr v-for="diaria in listaDiarias" v-bind:key="diaria.id">
          <td class="text-center">{{ diaria.id }}</td>
          <td class="text-center">
            {{ updateZonedDateTimeField(diaria.data) }}
          </td>
          <td class="text-center">
            {{ updateZonedDateTimeField(diaria.dataInicio) }}
          </td>
          <td class="text-center">
            {{ updateZonedDateTimeField(diaria.dataFim) }}
          </td>
          <td class="text-center">{{ diaria.quantidade }}</td>
          <td class="text-center">{{ diaria.unitario }}</td>
          <td class="text-center">{{ diaria.total }}</td>
          <td class="text-center">{{ diaria.processo }}</td>
          <td class="text-center">{{ diaria.origem }}</td>
          <td class="text-center">{{ diaria.destino }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <div class="mx-auto my-auto sem-resultados py-2">
          <div>Sem resultados para sua busca!</div>
        </div>
      </tbody>
    </table>

    <!-- <div class="card-footer">
      <div class="float-right">
        <button
          class="btn btn-success mb-1 mx-1 dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
        >
          <strong>Gerar Extrato</strong>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li>
            <a class="dropdown-item" href="#" @click="imprimirFerias(diaria.id)"
              >Completo</a
            >
          </li>

          <li>
            <a
              class="dropdown-item"
              href="#"
              @click="imprimirTransferenciaFerias()"
              >Total</a
            >
          </li>

          <li>
            <a
              class="dropdown-item"
              href="#"
              @click="imprimirTransferenciaFerias()"
              >Filtrado</a
            >
          </li>

        </ul>
      </div>
    </div> -->
  </div>
</template>
<script>
import rhService from "@/service/RHService";
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA, DATE_TIME_FORMAT_LOCAL } from "@/util/filter";
export default {
  name: "Ferias",
  data() {
    return {
      listaDiarias: [],
      resultados: false,
      feriasSelecionadaHistorico: null,
      ferias: null,
      page: [],
    };
  },
  created() {
    this.getDiariasFuncionario(
      this.$store.state.funcionario.idFuncionarioCadastrado
    );
  },

  methods: {
    getDiariasFuncionario(idFuncionario) {
      this.listaDiarias = [];
      rhService.getDiariasFuncionario(idFuncionario).then((resp) => {
        this.listaDiarias = resp.data;
      });
    },

    updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
    },
    updateTimeField(field) {
      return dayjs(field).format(DATE_TIME_FORMAT_LOCAL);
    },
  },
};
</script>

<style scoped>
.fa-circle-check {
  color: #34c92f;
}

.fa-circle-xmark {
  color: #ff0000;
}

.clicavel {
  cursor: pointer;
}

.botaoClicado {
  display: none;
}

.suspenderFerias {
  display: none;
}

.card-body {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.table-responsive {
  overflow-y: hidden !important;
}

.table-container {
  overflow-x: auto;
  /* Adicione uma barra de rolagem horizontal quando necessário */
  max-width: 100%;
  /* A tabela se ajustará ao tamanho do contêiner pai */
}
</style>