<template>
  <div class="ml-2" v-on="isModalVisible ? { click: closeModal } : {}">
    <section class="content-header">
      <modalCompartilharDocumento v-show="isModalVisible" @closeModalCompartilhar="closeModal">
      </modalCompartilharDocumento>
      <div class="modal-backdrop fade show" v-show="isModalVisible"></div>
      <filtroPesquisaDocumento />
      <div class="card card-primary card-tabs">
        <cardMenu />
        <conteudo v-role="'GDOCS'">
          <conteudoDocumento />
        </conteudo>
      </div>
    </section>

  </div>
</template>

<script>
import conteudoDocumento from "@/components/documentos/ConteudoDocumento.vue";
import filtroPesquisaDocumento from "@/components/documentos/FiltroPesquisaDocumento.vue";
import cardMenu from "@/components/documentos/MenuDocumentos.vue";
import modalCompartilharDocumento from "@/components/documentos/ModalCompartilharDocumento.vue";
import { EventBus } from "@/main.js";

export default {
  components: {
    cardMenu,
    conteudoDocumento,
    filtroPesquisaDocumento,
    modalCompartilharDocumento
  },

  data() {
    return {
      tituloBusca: "Gestão de Documentos",
      isModalVisible: false,
    };
  },
  created() {
    EventBus.$on("abrirModalCompartilhar", (id) => {
      this.showModal();
      EventBus.$emit("capturaIdDocumento", id);

    });
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
    },
  }
};
</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}
</style>
