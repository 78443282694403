<template>
  <div>
    <content-header title="Boletim Informativo" />

    <div class="card card-primary p-0 col-12" style="
    border-right-width: 30px;
    margin-left: 20px;
">
      <!--ate aqui -->
      <!--  style="
          width: 95%;
    margin-left: 15px;

    <div class="col-sm-3">
<div class="card">
<div class="card-header">
<h3 class="card-title">Default Card Example</h3>
</div>
<div class="card-body">
The body of the card
</div>
"-->


      <div class="card-body">
        <div class="row">
          <div class="form-group col-lg-4">
            <div class="card">
              <div style="background-color: #0f192ce6;
            color: #fff;
           height: 57.11111px; " class="card-header">Ano</div>
            </div>

            <input type="text" class="form-control form-control-border border-width-2" name="ano" id="boletim-ano"
              data-cy="ano" :class="{
                'is-invalid': isSaving && $v.anotacao.ano.$error,
              }" v-model="anotacao.ano" />



          </div>

          <div class="form-group col-lg-4">

            <div class="card" style="
    margin-bottom: 21px;
">
              <div style="background-color: #0f192ce6;
            color: #fff;
            " class="card-header">

                <label for="boletim-mes">Mês</label>
              </div>
            </div>
            <v-select class="border-v-select mt-1" id="boletim-mes" v-model="anotacao.mes" :options="meses"
              :reduce="(mes) => mes.id" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>


          </div>

          <div class="form-group col-lg-4">
            <div class="card" style="
    margin-bottom: 21px;
">
              <div style="background-color: #0f192ce6;
            color: #fff;
            " class="card-header">

                <label for="anotacao-supervidor">Supervisor(a)</label>
              </div>
            </div>


            <v-select class="border-v-select mt-1" id="anotacao-supervidor" v-model="anotacao.supervisorAssinatura"
              :options="listaSupervisor" :reduce="(listaSupervisor) => listaSupervisor.id" label="nome">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
        </div>
      </div>


      <div class="card-body">
        <div class="row">

          <div class="form-group col-lg-3">

            <div class="card">
              <div style="background-color: #0f192ce6;
color: #fff;
" class="card-header">

                <label class="form-control-label" for="boletim-assinatura1">Assinatura 1</label>
              </div>
            </div>
            <input type="text" class="form-control form-control-border border-width-2" name="assinatura1"
              id="boletim-assinatura1" data-cy="assinatura1" :class="{
                'is-invalid': isSaving && $v.anotacao.assinatura1.$error,
              }" v-model="anotacao.assinatura1" />

          </div>

          <div class="form-group col-lg-3">


            <div class="card">
              <div style="background-color: #0f192ce6;
            color: #fff;
            " class="card-header">

                <label class="form-control-label" for="boletim-cargo1">Cargo 1</label>
              </div>
            </div>
            <input type="text" class="form-control form-control-border border-width-2" name="cargo1" id="boletim-cargo1"
              data-cy="cargo1" :class="{
                'is-invalid': isSaving && $v.anotacao.cargo1.$error,
              }" v-model="anotacao.cargo1" />

          </div>

          <div class="form-group col-lg-3">

            <div class="card">
              <div style="background-color: #0f192ce6;
            color: #fff;
            " class="card-header">

                <label class="form-control-label" for="boletim-assinatura2">Assinatura 2</label>
              </div>
            </div>
            <input type="text" class="form-control form-control-border border-width-2" name="assinatura2"
              id="boletim-assinatura2" data-cy="assinatura2" :class="{
                'is-invalid': isSaving && $v.anotacao.assinatura2.$error,
              }" v-model="anotacao.assinatura2" />

          </div>

          <div class="form-group col-lg-3">

            <div class="card">
              <div style="background-color: #0f192ce6;
            color: #fff;
            " class="card-header">


                <label class="form-control-label" for="boletim-cargo2">Cargo 2</label>
              </div>
            </div>
            <input type="text" class="form-control form-control-border border-width-2" name="cargo2" id="boletim-cargo2"
              data-cy="cargo2" :class="{
                'is-invalid': isSaving && $v.anotacao.cargo2.$error,
              }" v-model="anotacao.cargo2" />

          </div>
        </div>
      </div>





      <div class="card-footer">
        <div class="float-right">
          <button @click="ImprimirBoletim" class="btn text-color-principal background-principal ml-1">
            <i class="far fa-envelope"></i> Imprimir
          </button>
          <button @click.prevent="LimparBoletim" type="reset" class="btn btn-default">
            <i class="fas fa-ban"></i> Limpar
          </button>
        </div>
      </div>
    </div>

    <PdfBoletimInformativo />
  </div>
</template>

<script>
import RHService from "@/service/RHService";
import PdfBoletimInformativo from "@/components/rh/anotacao/PdfBoletimInformativo.vue";
import rhService from "@/service/RHService";

export default {
  components: { PdfBoletimInformativo },
  name: "BoletimServidor",
  data() {
    return {
      data: {},
      anotacao: {
        ano: "",
        mes: "",
        supervisorAssinatura: 0,
        assinatura1: "",
        assinatura2: "",
        cargo1: "",
        cargo2: "",
      },
      listaSupervisor: {},
      submitted: false,
      isSaving: false,
      meses: [
        { id: 1, descricao: "Janeiro" },
        { id: 2, descricao: "Fevereiro" },
        { id: 3, descricao: "Março" },
        { id: 4, descricao: "Abril" },
        { id: 5, descricao: "Maio" },
        { id: 6, descricao: "Junho" },
        { id: 7, descricao: "Julho" },
        { id: 8, descricao: "Agosto" },
        { id: 9, descricao: "Setembro" },
        { id: 10, descricao: "Outubro" },
        { id: 11, descricao: "Novembro" },
        { id: 12, descricao: "Dezembro" },
      ],
    };
  },

  created(){
    this.buscaSupervisor();
  },

  methods: {

    buscaSupervisor() {
        rhService
        .buscaSupervisors()
        .then((response) => {
            this.listaSupervisor = response.data;
        });
    },


    // ImprimirBoletim() {
    //   let user = JSON.parse(localStorage.getItem("user"));
    //   let token = user.token;

    //   RHService.getBoletimInformativo(
    //     this.anotacao.ano,
    //     this.anotacao.mes,
    //     this.anotacao.supervisorAssinatura,
    //     token
    //   ).then((resp) => {

    //     console.log("supervisor: " + this.anotacao.supervisorAssinatura)

    //     const boletimInformativo = resp.data.filter(item => item.supervisorAssinatura === this.anotacao.supervisorAssinatura)
    
    //       this.$root.$emit(
    //       "emitirPdfBoletimInformativo",
    //       boletimInformativo,
    //       this.anotacao

    //      );
        
          
        
      
    //     console.log(this.anotacao)
    //   });
    // },

    ImprimirBoletim() {
      let user = JSON.parse(localStorage.getItem("user"));
      let token = user.token;

      RHService.getBoletimInformativo(
        this.anotacao.ano,
        this.anotacao.mes,
        this.anotacao.supervisorAssinatura,
        token
      ).then((resp) => {

        console.log(resp);

        let teste = resp.data;

        console.log("supervisor: " + this.anotacao.supervisorAssinatura)

        const boletimInformativo = resp.data.filter(item => item.supervisorAssinatura === this.anotacao.supervisorAssinatura)
    
          this.$root.$emit(
          "emitirPdfBoletimInformativo",
          boletimInformativo,
          this.anotacao

         );
        
          
        
      
        console.log(this.anotacao)
      });
    },

    LimparBoletim() {
      this.anotacao = {};
    },
  },
};
</script>
