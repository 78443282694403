<template>
  <div class="card card-primary card-tabs">
    <div class="card-header p-0 pt-1 background-principal">
      <ul class="nav nav-tabs" role="tablist" id="ul-menu-documento">
        <li class="nav-item menu">
          <a class="nav-link item-menu EN active" data-toggle="pill" @click="novaAcao('EN')" role="tab"
            aria-controls="custom-tabs-five-overlay" aria-selected="true">Recebidos
            <sup v-if="recebidos > 0"><span class="badge-primary badge-pill"><b>{{ recebidos }}</b></span></sup>
          </a>

        </li>
        <li class="nav-item menu">
          <a class="nav-link item-menu EV" data-toggle="pill" @click="novaAcao('EV', $event)" role="tab"
            aria-controls="custom-tabs-five-overlay-dark" aria-selected="false">Enviados</a>
        </li>
        <li class="nav-item menu">
          <a class="nav-link item-menu RS" data-toggle="pill" @click="novaAcao('RS', $event)" role="tab"
            aria-controls="custom-tabs-five-normal" aria-selected="false">Rascunhos
            <sup><span v-show="countRascunho !== 0" class="badge-primary badge-pill"><b>{{ countRascunho
            }}</b></span></sup>
          </a>
        </li>
        <li class="nav-item menu">
          <a class="nav-link item-menu CP" data-toggle="pill" @click="novaAcao('CP', $event)" role="tab"
            aria-controls="custom-tabs-five-normal" aria-selected="false">Compartilhados</a>
        </li>
        <li class="nav-item menu">
          <a class="nav-link item-menu AR" data-toggle="pill" @click="novaAcao('AR', $event)" role="tab"
            aria-controls="custom-tabs-five-normal" aria-selected="false">Arquivados</a>
        </li>
        <li class="nav-item menu">
          <a class="nav-link item-menu ND" data-toggle="pill" @click="novaAcao('ND')" role="tab"
            aria-controls="custom-tabs-five-normal" aria-selected="false">Novo Documento</a>
        </li>
        <li class="nav-item menu">
          <a class="nav-link item-menu NP" data-toggle="pill" @click="novaAcao('NP')" role="tab"
            aria-controls="custom-tabs-five-normal" aria-selected="false">Novo Protocolo</a>
        </li>
        <div class="nav-item navbar-nav ml-auto">
          <div class="card-tools">
            <!-- <div class="input-group input-group-sm">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar e-mail"
              />
              <div class="input-group-append">
                <div class="btn background-principal">
                  <i class="nav-icon fas fa-search text-color-principal"></i>
                </div>
              </div>
            </div> -->
            <ul class="pagination pagination-sm pr-4" v-if="page.totalPages > 0">
              <li :class="{ disabled: page.first, menu: !page.first }">
                <a v-if="page.first"><span class="fa fa-chevron-left fa-lg"></span></a>
                <a v-else @click="mudarPagina(page.number - 1)"><span class="fa fa-chevron-left fa-lg"></span></a>
              </li>
              <label class="pr-1 pl-1">Página {{ page.number + 1 }} de {{ page.totalPages }}
              </label>
              <li :class="{ disabled: page.last, menu: !page.last }">
                <a v-if="page.last"><span class="fa fa-chevron-right fa-lg"></span></a>
                <a v-else @click="mudarPagina(page.number + 1)"><span class="fa fa-chevron-right fa-lg"></span></a>
              </li>
            </ul>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/main.js";
import DocumentoService from "@/service/DocumentoService";

export default {
  name: "MenuDocumento",
  data() {
    return {
      page: [],
      recebidos: 0,
      countRascunho: 0,
    };
  },
  methods: {
    contabilizarDocumentosRascunho() {
      DocumentoService.contabilizarDocumentosRascunho().then((response) => {
        this.countRascunho = response.data;
      })
    },
    novaAcao(acao) {
      EventBus.$emit("alteraAcaoConteudo", acao);
      this.contabilizarDocumentosRascunho();
    },
    mudarPagina(pagina) {
      EventBus.$emit("mudarPagina", pagina);
    },
    ativarAbas(aba) {
      let lista = document.getElementsByClassName("item-menu");
      for (let item of lista) {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        }
        if (item.classList.contains(aba)) {
          item.classList.add("active");
        }
      }
    },
  },
  created() {
    EventBus.$on("paginacao", (page) => {
      this.page = page;
    });
    EventBus.$on("abaAtiva", (aba) => {
      // this.ativo = aba;
      this.ativarAbas(aba);
    });
    EventBus.$on("countDocumentos", (count) => {
      // this.ativo = aba;
      this.recebidos = count;
    });
    this.contabilizarDocumentosRascunho();
    EventBus.$on('contabilizarRascunho', (event) => {
      this.contabilizarDocumentosRascunho();
    });
  },
};
</script>

<style>
.menu {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}
</style>
