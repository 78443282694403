<template>
  <div class="col-12">
    <h4 class="mb-3">Cadastrar um Documento</h4>
    <form class="row">
      <div class="form-group col-lg-3 col-sm-12">
        <label for="tipodocumento">Tipo do Documento</label>
        <v-select class="border-v-select" id="tipodocumento" v-model="documento.tipoDocumento"
          :class="{ 'is-invalid': $v.documento.tipoDocumento.$error, }" :options="tiposDocumento" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
        <div v-if="!$v.documento.tipoDocumento.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>
      <div class="form-group col-lg-3 col-sm-12">
        <label for="tipodocumento">Orgão</label>
        <v-select class="border-v-select" id="tipodocumento" v-model="documento.orgaoExpeditor"
          :options="orgaosExpeditores" label="descricao" >
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>
      <div class="form-group col-lg-2 col-sm-12">
        <label for="nome">Número do documento</label>
        <input v-if="documento.tipoDocumento === null" type="text"
          class="form-control form-control-border border-width-2"  />

        <input v-if="documento.tipoDocumento ? (
          documento.tipoDocumento.descricao !== 'CPF' & documento.tipoDocumento.descricao !== 'CNPJ')
          : false" key="nao-cpf" type="text" class="form-control form-control-border border-width-2"
          :class="{ 'is-invalid': $v.documento.numero.$error, }" id="numerodocumento" v-model="documento.numero"
           />

        <input v-if="documento.tipoDocumento ? (
          documento.tipoDocumento.descricao === 'CNPJ')
          : false" key="nao-cpf" type="text" class="form-control form-control-border border-width-2"
          :class="{ 'is-invalid': $v.documento.numero.$error, }" id="numerodocumento" v-model="documento.numero"
           v-mask="'##.###.###/####-##'" />

        <input v-if="documento.tipoDocumento ? (
          documento.tipoDocumento.descricao === 'CPF')
          : false" key="nao-cpf" type="text" class="form-control form-control-border border-width-2"
          :class="{ 'is-invalid': $v.documento.numero.$error, }" id="numerodocumento" v-model="documento.numero"
           v-mask="'###.###.###-##'" />
        <div v-if="!$v.documento.numero.required" class="invalid-feedback">Este campo é obrigatório
        </div>
      </div>

      <div class="form-group col-lg-2">
        <label for="dataFatoInicio">Data de emissão</label>
        <input type="date" class="form-control form-control-border border-width-2" id="dataemissao"
          placeholder="dd/mm/aaaa" v-model="documento.dataEmissao"  />
      </div>
      <div class="form-group col-lg-2">
        <label for="dataFatoInicio">Data de validade</label>
        <input type="date" class="form-control form-control-border border-width-2" id="datavalidade"
          placeholder="dd/mm/aaaa" v-model="documento.dataValidade"
          />
      </div>

      <!-- <div class="form-group col-lg-2">
        <label for="serie">Série</label>
        <input type="text" class="form-control form-control-border border-width-2" id="serie" v-model="documento.serie"
          :disabled="documento.tipoDocumento ? false : true" />
      </div>

      <div class="form-group col-lg-2">
        <label for="uf">UF</label>
        <input type="text" class="form-control form-control-border border-width-2" id="uf" v-model="documento.uf"
          :disabled="documento.tipoDocumento ? false : true" />
      </div> -->
      <div class="form-group col-lg-2"
      v-if="documento.tipoDocumento && documento.tipoDocumento.descricao === 'Carteira de Reservista'">
        <label for="regiao">Região</label>
        <input type="text" class="form-control form-control-border border-width-2" id="regiao" v-model="documento.regiao"
           />
      </div>
      <div class="form-group col-lg-2" v-if="documento.tipoDocumento && ['CNH', 'Carteira de Reservista'].includes(documento.tipoDocumento.descricao)">
      <label for="categoria">Categoria</label>
      <input type="text" class="form-control form-control-border border-width-2" id="categoria" v-model="documento.categoria" />
    </div>

      <div class="form-group col-lg-2"
        v-if="documento.tipoDocumento && documento.tipoDocumento.descricao === 'Título Eleitoral'">
        <label for="zona">Zona</label>
        <input type="text" class="form-control form-control-border border-width-2" id="zona" v-model="documento.zona" />
      </div>

      <div class="form-group col-lg-2"
        v-if="documento.tipoDocumento && documento.tipoDocumento.descricao === 'Título Eleitoral'">
        <label for="secao">Seção</label>
        <input type="text" class="form-control form-control-border border-width-2" id="secao"
          v-model="documento.secao" />
      </div>




      <div class="form-group col-12">
        <button class="btn btn-primary float-right" @click.prevent="salvarDocumento"
          :disabled="documento.tipoDocumento ? false : true">
          <i class="fa-solid fa-floppy-disk"></i> Salvar
        </button>
      </div>
    </form>
    <hr />
    <h4 class="my-4">Documentos já cadastrados:</h4>
    <div class="card">
      <div class="card-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Número do documento</th>
              <th>Data de expedição</th>
              <th>Data de validade</th>
              <th>Tipo do documento</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="doc in pessoa.documentos" :key="doc.id">
              <td>{{ doc.numero }}</td>
              <td>{{ doc.dataExpedicao }}</td>
              <td>{{ doc.dataValidade }}</td>
              <td>
                {{ doc.tipoDocumento ? doc.tipoDocumento.descricao : "" }}
              </td>
              <td>
                <div @click.prevent="deleteDocumento(doc.id)" class="btn btn-danger ml-1">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import CadastroPessoaService from "@/service/CadastroPessoaService";
import ConsultaListasService from "@/service/ConsultaListasService";
import { required } from "vuelidate/lib/validators";
export default {
  name: "DocumentosCadastroPessoa",
  props: ['pessoaEdicao'],
  data() {
    return {
      isDocSaving: false,
      tiposDocumento: [],
      orgaosExpeditores: [],
      pessoa: {
        id: null,
        documentos: [],
      },
      documento: {
        numero: "",
        tipoDocumento: null,
        pessoa: {},
      },


    };
  },

  created() {
    EventBus.$on("cadastrarPessoaDadosBasicos", (listener) => {
      this.pessoa.id = listener.id
      this.documento.pessoa.id = listener.id
    });
    ConsultaListasService.buscarListaPorNomeTabela("tipo_documento")
      .then((response) => {
        this.tiposDocumento = response.data;
      })
      .catch((e) => alert("ERRO: " + e.message));
    ConsultaListasService.buscarListaPorNomeTabela("orgao_expedidor").then(r => {
      this.orgaosExpeditores = r.data
    });
    EventBus.$on("dadosBasicosBuscouPessoa", (pessoa) => {
      this.documento.pessoa.id = pessoa.id;
      this.pessoa.id = pessoa.id;
      this.pessoa.documentos = pessoa.documentos;
    })
    EventBus.$on("cadastroRealizadoComSucesso", (pessoa) => {
      this.documento.pessoa.id = pessoa.id;
      this.pessoa.id = pessoa.id;
      this.pessoa.documentos = pessoa.documentos;
    });
    EventBus.$on("pessoaParaEditar", (pessoa) => {
      this.documento.pessoa.id = pessoa.id;
      this.pessoa.id = pessoa.id;
      this.pessoa.documentos = pessoa.documentos;
    });
  },


  watch: {
    pessoaEdicao: {
      immediate: true,
      handler(pessoa) {
        this.documento.pessoa.id = pessoa.id;
        this.pessoa.id = pessoa.id;
        this.pessoa.documentos = pessoa.documentos;
      }
    },

  },


  methods: {

    mostrarCampos(tipo) {
      return this.documento.tipoDocumento === tipo;
    },
    buscarDocumento(idDocumento) {
      if (idDocumento) {
        CadastroPessoaService.buscarDocumentoEdicao(idDocumento)
          .then((response) => {
            this.documento.id = idDocumento;
            this.documento.numero = response.data.numero;
            //this.documento.tipoDocumento=response.data.tipoDocumento;
            this.documento.orgaoExpeditor = response.data.orgaoExpeditor;
            this.documento.orgaoExpeditorUF = response.data.orgaoExpeditorUF;
            this.documento.dataValidade = response.data.dataValidade;
            this.documento.dataExpedicao = response.data.dataExpedicao;
            this.documento.zona = response.data.zona;
            this.documento.categoria = response.data.categoria;
            this.documento.serie = response.data.serie;
            this.documento.secao = response.data.secao;
            this.documento.regiao = response.data.regiao;
          })
          .catch((error) => {
            // alertService.showHttpError(this, error.response);
          });
      }
    },
    atualizarDocumento() {
      if (this.documento.numero && this.documento.tipoDocumento) {
        CadastroPessoaService.editarDocumento(this.documento)
          .then((response) => {
            this.documento = {
              numero: "",
              tipoDocumento: null,
              pessoa: {
                id: this.pessoa.id,
              },
              orgaoExpeditor: "",
              dataValidade: "",
              dataExpedicao: "",
              zona:"",
              categoria:"",
              serie:"",
              secao:"",
              regiao:"",

            };

            this.buscarDocumentosPessoa(this.pessoa.id);
            const message = "Documento Atualizado!!! ";
            Toast.fire(message.toString(), "", "success");
          })
          .catch((error) => {
            alert("Erro na atualização: " + error.message);
          });
      }
    },
    // FUNÇÕES DE DELETE

    deleteDocumento(idDocumento) {
      if (idDocumento) {
        CadastroPessoaService.removerDocumento(idDocumento)
          .then((param) => {
            const message = "Documento Removido com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
            this.buscarDocumentosPessoa(this.pessoa.id);
          })
          .catch((error) => {
            Toast.fire("Erro ao deletar email: " + e.message, "", "error");
          });
      }
    },
    buscarDocumentosPessoa(id) {
      CadastroPessoaService.buscarDocumentosPessoa(id).then((r) => {
        this.pessoa.documentos = r.data;
      });
    },
    // FUNÇÕES DE SALVAMENTO
    salvarDocumento() {
      this.$v.documento.$touch();
      this.isDocSaving = true;
      if (!this.$v.documento.$invalid) {
        CadastroPessoaService.cadastrarDocumento(this.documento)
          .then((param) => {
            this.isDocSaving = false;
            const message = "Documento Cadastrado com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
            this.documento = {
              numero: "",
              tipoDocumento: null,
              pessoa: {
                id: this.pessoa.id,
              },
              orgaoExpeditor: "",
              dataValidade: "",
              dataExpedicao: "",
              zona:"",
              categoria:"",
              serie:"",
              secao:"",
              regiao:"",
            };

            this.buscarDocumentosPessoa(this.pessoa.id);
            this.$forceUpdate();
            this.$nextTick(() => { this.$v.$reset() })
          })
          .catch((error) => {
            Toast.fire(error.response.data.mensagem, "", "error");
          });
      } else {
        Toast.fire(
          "Campos incompletos!",
          "",
          "error"
        );
      }
    },
  },
  validations: {
    documento: {
      numero: { required },
      tipoDocumento: { required },
    },
  },
};
</script>

<style></style>
