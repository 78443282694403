var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-orgao-id-contratacao"}},[_vm._v("id Contratacao")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.cadastroOrgao.idContratacao),expression:"cadastroOrgao.idContratacao",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.cadastroOrgao.idContratacao.$error,
          },attrs:{"type":"text","name":"idContratacao","id":"cadastro-orgao-id-contratacao","data-cy":"idContratacao"},domProps:{"value":(_vm.cadastroOrgao.idContratacao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cadastroOrgao, "idContratacao", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-orgao-cpfCnpj"}},[_vm._v("Cpf/Cnpj")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cadastroOrgao.cpfCnpj),expression:"cadastroOrgao.cpfCnpj"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.cadastroOrgao.cpfCnpj.$error,
          },attrs:{"type":"text","name":"cpfCnpj","id":"cadastro-orgao-cpfCnpj","data-cy":"cpfCnpj"},domProps:{"value":(_vm.cadastroOrgao.cpfCnpj)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cadastroOrgao, "cpfCnpj", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-orgao-numero"}},[_vm._v("Número")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cadastroOrgao.numero),expression:"cadastroOrgao.numero"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.cadastroOrgao.numero.$error,
          },attrs:{"type":"text","name":"numero","id":"cadastro-orgao-numero","data-cy":"numero"},domProps:{"value":(_vm.cadastroOrgao.numero)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cadastroOrgao, "numero", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-orgao-ano"}},[_vm._v("Ano")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.cadastroOrgao.ano),expression:"cadastroOrgao.ano",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.cadastroOrgao.ano.$error,
          },attrs:{"type":"number","name":"ano","id":"cadastro-orgao-ano","data-cy":"ano"},domProps:{"value":(_vm.cadastroOrgao.ano)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cadastroOrgao, "ano", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"cadastro-orgao-perfil"}},[_vm._v("Perfil")]),_c('v-select',{staticClass:"border-v-select",class:{
          'is-invalid': _vm.isSaving && _vm.$v.cadastroOrgao.perfil.$error,
        },attrs:{"id":"cadastroOrgao-perfil","data-cy":"perfil","name":"perfil","options":_vm.perfil,"reduce":function (perfil) { return perfil.id; },"label":"descricao"},model:{value:(_vm.cadastroOrgao.perfil),callback:function ($$v) {_vm.$set(_vm.cadastroOrgao, "perfil", $$v)},expression:"cadastroOrgao.perfil"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Desculpe, sem opções no momento.")])])],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn text-color-principal background-principal ml-1",on:{"click":_vm.salvarOrgao}},[_c('i',{staticClass:"far fa-envelope"}),_vm._v(" Enviar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":_vm.limparOrgao}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Limpar ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }