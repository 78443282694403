<template>
  <div>
    <div class="pl-4 pr-4 pb-4">
      <h6 class="mt-4 mb-3"><b>DADOS USUÁRIO: </b></h6>
      <div class="row">
        <div class="form-group col-lg-3">
          <label for="email">E-mail <span class="text-danger">*</span></label>
          <input
            type="email"
            class="form-control form-control-border"
            id="emailFuncional"
            placeholder="usuario@email.com"
            v-model="usuario.emailFuncional"
            :class="{ 'is-invalid': $v.usuario.emailFuncional.$error }"
            @input="
              (event) =>
                (usuario.emailFuncional = event.target.value
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase())
            "
          />
          <div
            v-if="!$v.usuario.emailFuncional.required"
            class="invalid-feedback"
          >
            Este campo é obrigatório
          </div>
          <div v-if="!$v.usuario.emailFuncional.email" class="invalid-feedback">
            E-mail inválido
          </div>
        </div>
        <div class="form-group col-lg-3">
          <label for="celular">Celular <span class="text-danger">*</span></label>
          <input
            class="form-control form-control-border"
            type="number"
            v-model="usuario.celular"
            :class="{ 'is-invalid': $v.usuario.celular.$error }"
          />
          <div
            v-if="!$v.usuario.celular.required"
            class="invalid-feedback"
          >
            Este campo é obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        @click.prevent="cadastroUsuario"
        class="btn btn-primary float-right"
      >
        <i class="fa-solid fa-floppy-disk"></i>
        Salvar Usuário
      </button>
      <button
        v-if="id != 0"
        @click.prevent="openModalEdicaoSenha"
        class="btn background-principal text-color-principal btn-primary float-left"
      >
        <i class="fa-solid fa-lock"></i>
        Editar Senha
      </button> 
      
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
// import { EventBus } from "@/main";
import cadastroUsuarioService from "@/service/CadastroUsuarioService";
import { email, maxLength, required } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "DadosUsuario",
  components: { Loading },
  data() {
    return {
      loaderActive: false,
      fullPage: false,
      id: 0,
      nomePessoaFuncionario: "",
      usuario: {},
      lotacaos: [],
    };
  },
  created() {
    // this.carregarListas();
    // this.validaFuncionario();
    // this.$store.commit("usuarioCadastrado", this.$store.state.usuario.idFuncionarioUsuario);
    this.usuario = this.$store.state.usuario.usuarioSalvo;
    this.id = this.$store.state.usuario.usuarioSalvo.id;
  },
  methods: {
    cadastroUsuario() {
      this.showLoader();

      this.$v.usuario.$touch();
      if (!this.$v.usuario.$invalid) {
        if (this.usuario.id) {
          this.usuario.client = 'SIGMA';
          cadastroUsuarioService
            .atualizarUsuario(this.usuario)
            .then((response) => {
              this.$store.commit("usuarioCadastrado", response.data.id);
              this.usuario = response.data;

              this.alertaSucessoCadastro();
              this.hideLoader();
            })
            .catch((e) => {
              this.hideLoader();
              this.alertaFalhaCadastro();
            });
        } else {
          // if (this.$store.state.funcionario.existeFuncionarioAtivo) {
          //   this.$swal
          //     .fire({
          //       title:
          //         "Todos os funcionarios ativos associados a " +
          //         this.nomePessoaFuncionario +
          //         " serão desativados",
          //       text: "Deseja continuar?",
          //       icon: "warning",
          //       showCancelButton: true,
          //       cancelButtonColor: "#28a745",
          //       confirmButtonText: "Continuar Cadastro",
          //       cancelButtonText: "Cancelar",
          //       reverseButtons: true,
          //     })
          //     .then((result) => {
          //       if (result.isConfirmed) {
          //         cadastroFuncionarioService
          //           .cadastroFuncionario(this.usuario)
          //           .then((response) => {
          //             this.$store.commit(
          //               "funcionarioCadastrado",
          //               response.data.id
          //             );
          //             this.usuario = response.data;

          //             this.$store.commit("existeFuncionarioAtivo", false);

          //             this.alertaSucessoCadastro();
          //             this.hideLoader();
          //           })
          //           .catch((e) => {
          //             this.hideLoader();
          //             this.alertaFalhaCadastro();
          //           });
          //       } else {
          //         this.hideLoader();
          //       }
          //     });
          // } else {

            let pessoa = {
              id: this.$store.state.usuario.idPessoaUsuario
            }
            let funcionario = this.$store.state.funcionarioUsuario;

            this.usuario.pessoa = pessoa;
            this.usuario.funcionario = funcionario;
            this.usuario.client = 'SIGMA';

            // this.$store.commit("usuarioCadastrado", this.$store.state.usuario.idFuncionarioUsuario);

            console.log(this.usuario);

            cadastroUsuarioService
              .cadastroUsuario(this.usuario)
              .then((response) => {
                this.$store.commit("usuarioCadastrado", response.data.id);
                this.usuario = response.data;

                console.log(this.usuario);

                this.alertaSucessoCadastro();
                this.hideLoader();
              })
              .catch((e) => {
                this.hideLoader();
                this.alertaFalhaCadastro();
              });
          // }
        }
      } else {
        this.hideLoader();
      }
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    alertaSucessoCadastro() {
      Toast.fire("Sucesso ao cadastrar usuário", "", "success");
    },
    alertaFalhaCadastro() {
      Toast.fire("Falha ao cadastrar usuário", "", "error");
    },
  },
  validations: {
    usuario: {
      emailFuncional: {
        required,
        email,
      },
      celular: {
        required,
      },
    },
  },
};
</script>
