<template>
  <form>

    <div class="card card-primary">
      <div class="card-header background-principal">
        <h3 class="card-title">Busca por Cheque</h3>
        <!-- /.card-tools -->
      </div>
      <!-- <div class="card-body d-flex" style="
          margin-bottom: -25px;
          box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
          margin: 10px;
          padding: 10px;
        "> -->

      <div class="card-body">
        <div class="form-group">
          <label for="inputNumeroInicioFolha" class="pesquisar-numero-inicio-folha">Digite o número início folha</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputInicioFolha"
            placeholder="Digite o número início folha" v-model="numeroInicioFolha" />
        </div>
        <div class="form-group">
          <label for="inputNumeroFimFolha" class="pesquisar-numero-fim-folha">Digite o número início folha</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputFimFolha"
            placeholder="Digite o número fim folha" v-model="numeroFimFolha" />
        </div>
        <div class="form-group">
          <label for="inputAgencia" class="pesquisar-agencia">Digite a agência</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputAgencia"
            placeholder="Digite a Agência" v-model="agencia" />
        </div>
        <div class="form-group">
          <label for="inputValor" class="pesquisar-valor">Digite o valor</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputValor"
            placeholder="Digite o Valor" v-model="valor" />
        </div>
        <div class="form-group">
          <label for="inputContaCorrente" class="pesquisar-conta-corrente">Digite a Conta Corrente</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputModeloCelular"
            placeholder="Digite a Conta Corrente" v-model="contaCorrente" />
        </div>
        <div class="form-group">
          <label for="inputBanco" class="pesquisar-banco">Selecione o Banco</label>
          <!-- <select type="select" class="form-control form-control-border border-width-2" id="inputBanco"
          placeholder="Selecione o Banco" v-model="banco">
          <option disabled value="">Selecione um banco</option>
          <option :key="id" v-for="(bancos, id) in bancos" :value=bancos.id> {{ bancos.descricao }}</option>
        </select> -->
          <v-select class="border-v-select" id="inputCorDoCarro" v-model="banco"
            :disabled="
              bancos == null ||
              bancos == []
            " :options="bancos" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <!-- /.card-header -->
      <!-- </div> -->

      <div class="card-footer">
        <button @click.prevent="emitirConsulta" v-on:keyup.enter="emitirConsulta" type="submit"
          class="btn col-12 background-principal text-color-principal">
          <i class="fas fa-search"></i>
          Buscar
        </button>
      </div>

      <!-- /.card-body -->
      <!-- /.card-footer -->
    </div>
    <!-- /.card -->
  </form>

</template>

<script>
import ConsultaListasService from '@/service/ConsultaListasService.js'
import { EventBus } from '../../../main.js'
import LocalIpService from "@/service/LocalIpService";

export default {
  name: 'FormChequeConsulta',
  data() {
    return {
      numeroInicioFolha: '',
      numeroFimFolha: '',
      agencia: '',
      valor: '',
      contaCorrente: '',
      banco: '',
      bancos: [],
      page: 1,
      //ipUsuario:null
    }
  },
  created() {
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    ConsultaListasService.buscarListaPorNomeTabela('banco')
      .then((response) => {
        this.bancos = response.data
      })
      .catch((e) => this.alertaFalhaBuscarListaPorNomeTabela())
  },
  methods: {
    mostrarAlerta() {
      // eslint-disable-next-line no-undef
      Toast.fire('Preencha ao menos um campo!!', '', 'error')
    },
    formValido() {
      if (
        this.numeroInicioFolha ||
        this.numeroFimFolha ||
        this.agencia ||
        this.valor ||
        this.contaCorrente ||
        this.banco
      ) {
        return true
      }
      return false
    },
    emitirConsulta() {
      if (this.formValido()) {
        const params = {
          numeroInicioFolha: this.numeroInicioFolha,
          numeroFimFolha: this.numeroFimFolha,
          agencia: this.agencia,
          valor: this.valor,
          contaCorrente: this.contaCorrente,
          banco: this.banco ? this.banco.id : '',
          page: this.page,
          //ipUsuario:this.ipUsuario
        }
        EventBus.$emit('consultaCheque', params)
      } else {
        this.mostrarAlerta()
      }
    },
    alertaFalhaBuscarListaPorNomeTabela() {
      Toast.fire("Falha ao buscar banco", "", "error");
    },
  }
}
</script>

<style>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}
</style>
