<template>
  <div>
    <!-- IFRAME UTIL APENAS NA FASE DE DESENVOLVIMENTO E MODELAÇÃO DO PDF, PARA EXIBIR MODIFICAÇÕES
      SEM A NECESSIDADE DE BAIXAR O PDF (TIRAR COMENTARIO DA ULTIMAS LINHAS DO METODO emitirPdf para isso)-->
    <!-- <iframe
      class="preview-pane"
      style="width: 1000px; height: 95vh; margin: 0px; padding: 0px"
      :src="pdfSrc + '#zoom=100'"
    >
    </iframe> -->
  </div>
</template>
  
<script>
//IMPORT da LIB JSPDF (https://www.npmjs.com/package/jspdf)
import jsPDF from "jspdf";
// import { dados } from "@/components/serviceBoletimInformativo.js";

export default {
  name: "PdfBoletimInformativo",
  components: {},
  props: {},
  data() {
    return {
      pdfSrc: "data:application/pdf;base64,",
    };
  },
  setup() {},
  methods: {
    // Gera um parágrafo com base nos dados de texto fornecidos.
    montarParagrafo(textoDados) {
      let texto = textoDados.obs1;
      texto += "\n\n";

      return texto;
    },
    async emitirPdf(dados, formDados) {
      // Nova instância de um objeto jspdf (p(modo retrato),mm(medida de unidade, "pixels" do documento),a4(tamanho padrão da folha))
      let doc = new jsPDF("p", "mm", "a4");
      const pageWidth = doc.internal.pageSize.getWidth();
      const center = pageWidth / 2;

      // Adicionando nova imagem (logo MA) no cabeçalho do pdf
      const image = new Image();
      image.src = require("@/assets/logo-ma-small.png");
      image.width = 20;
      image.height = 20;
      let x = (pageWidth - image.width) / 2;
      doc.addImage(image, "PNG", x, 10, 20, 20, "center");

      // Seta a cor do texto para preto
      doc.setTextColor(0);
      // Seta a font e tipo do texto
      doc.setFont("times", "bold");
      // Seta o tamanho da fonte
      doc.setFontSize(12);
      // Adiciona texto centralizado
      doc.text("Estado do Maranhão", center, 35, null, null, "center");
      doc.setFont("times", "normal");
      doc.setFontSize(9);
      doc.text(
        "Secretaria de Estado da Segurança Pública",
        center,
        39,
        null,
        null,
        "center"
      );

      doc.text(
        "Av. dos Franceses, s/n, Bairro Vila Palmeira, CEP 65036-283, São Luís-MA",
        center,
        43,
        null,
        null,
        "center"
      );
      doc.text("Telefone: (98) 3214 3700", center, 47, null, null, "center");

      doc.setFont("times", "bold");
      doc.text(
        "BOLETIM INFORMATIVO CRIADO PELO DECRETO",
        center,
        60,
        null,
        null,
        "center"
      );
      doc.text(
        "Nº 13202/93 D.0. N º 166 DE 30.08.93 ",
        center,
        65,
        null,
        null,
        "center"
      );

      doc.setFont("times", "bold");
      doc.setFontSize(16);
      let mes = this.getMesPorExtenso(formDados.mes);
      let ano = formDados.ano;
      doc.text(`Mês: ${mes}     Ano: ${ano}`, center, 72, null, null, "center");

      doc.setFontSize(10);
      let textoDados = "";
      let startY = 85;
      let colunaAtual = 1;
      doc.setFontSize(10);

      await dados.forEach((item) => {
        if (item.tipoAnotacao != null && item.tipoAnotacao.boletim) {
          // Define a fonte para o texto que será escrito no documento
          doc.setFont("times", "bold");

          // Extrai as informações do 'item' atual
          let tipoDocumento = item.tipoDocumento ? item.tipoDocumento.descricao : " ";
          let numeroDocumento = item.numeroDocumento;

          // Combina 'tipoDocumento' e 'numeroDocumento' para formar o título
          let titulo = `${tipoDocumento} ${numeroDocumento}`;

          // Escreve o 'titulo' no documento PDF na posição especificada
          if (colunaAtual === 1) {
            doc.text(titulo, 20, startY);
          } else {
            doc.text(titulo, 115, startY);
          }

          // Monta o parágrafo usando o 'item' atual
          textoDados = this.montarParagrafo(item);
          console.log(textoDados);

          // Insere o 'textoDados' no documento e atualiza 'startY' e 'colunaAtual'
          let r = this.inserirParagrafo(
            doc,
            textoDados,
            startY + 6,
            colunaAtual,
            center,
            formDados
          );

          // Atualiza 'startY'
          startY = r.lastY;

          // Obtém o número da página atual
          let pageNumber = doc.internal.getCurrentPageInfo().pageNumber;

          // Reseta 'startY' se o texto estiver chegando no final da página
          if (startY > 265 && pageNumber > 1) {
            startY = 30;
          }

          // Atualiza 'colunaAtual'
          colunaAtual = r.colunaAtual;
        }
      });

      this.inserirLinhasAssinatura(doc, formDados);

      doc.save(`BOLETIM INFORMATIVO`);

      /* EM CONJUNTO COM O IFRAME, TEM UTILIDE APENAS NA FASE DE DESENVOLVIMENTO E MODELAÇÃO DO PDF,
       *PARA EXIBIR MODIFICAÇÕES SEM A NECESSIDADE DE BAIXAR O PDF
       *COMENTAR O MÉTODO doc.save ACIMA para isso
       */
      // var out = doc.output();
      // var url = "data:application/pdf;base64," + btoa(out);
      // this.pdfSrc = url;
    },
    inserirParagrafo(doc, p, startY, colunaAtual, center, formDados) {
      let lines = doc
        .setFontSize(10)
        .setFont("times", "normal")
        .splitTextToSize(p, 85);

      let lastY;
      let startX1 = 20;
      let startX2 = 115;
      let aux = 0;

      lines.forEach((element) => {
        lastY = startY + aux * 4;

        if (colunaAtual === 1) {
          this.justifyLine(doc, element, startX1, lastY, 85);
        } else {
          this.justifyLine(doc, element, startX2, lastY, 85);
        }

        aux++;
        if (lastY > 265) {
          if (colunaAtual === 1) {
            aux = 0;
            colunaAtual = 2;
            startY = 85; // Reseta a altura inicial da segunda coluna para igualar à primeira coluna
          } else {
            startY = 25;
            colunaAtual = 1;
            aux = 0;
            //aux = 10; pra deixar as colunas quase com a mesma altura
            this.inserirLinhasAssinatura(doc, formDados);
            doc.addPage();
            this.inseriPaginas(doc, center);
          }
        }
      });

      return {
        lastY: lastY,
        colunaAtual: colunaAtual,
      };
    },
    inseriPaginas(doc, center) {
      let currentPage = doc.getCurrentPageInfo().pageNumber;
      doc.text(
        `(Fls. ${currentPage} do Boletim)`,
        center,
        15,
        null,
        null,
        "center"
      );
    },

    inserirLinhasAssinatura(doc, formdados) {
      let x;
      doc.setLineWidth(0.5);
      doc.line(20, 280, 105, 280);
      doc.line(115, 280, 200, 280);
      doc.setFontSize(9);
      doc.setFont("times", "bold");
      let text = formdados.assinatura1;
      let textWidth =
        (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (125 - textWidth) / 2;
      doc.text(text, x, 285);
      text = formdados.cargo1;
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (125 - textWidth) / 2;
      doc.text(text, x, 290);

      text = formdados.assinatura2;
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (315 - textWidth) / 2;
      doc.text(text, x, 285);
      text = formdados.cargo2;
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (315 - textWidth) / 2;
      doc.text(text, x, 290);

      doc.setFont("times", "normal");
    },
    justifyLine(doc, text, x, y, maxWidth) {
      const words = text.split(" ");
      const numWords = words.length;
      const totalWidth = doc.getTextWidth(text);
      const spaceWidth = (maxWidth - totalWidth) / (numWords - 1);

      let currentX = x;
      words.forEach((word, index) => {
        doc.text(word, currentX, y);
        currentX += doc.getTextWidth(word) + spaceWidth;

        if (index < numWords - 1) {
          doc.text(" ", currentX, y); // Adicionar espaço entre as palavras
          currentX += doc.getTextWidth(" ");
        }
      });
    },

    getMesPorExtenso(mesInt) {
      const mesDictionary = {
        1: "Janeiro",
        2: "Fevereiro",
        3: "Março",
        4: "Abril",
        5: "Maio",
        6: "Junho",
        7: "Julho",
        8: "Agosto",
        9: "Setembro",
        10: "Outubro",
        11: "Novembro",
        12: "Dezembro",
      };
      return mesDictionary[mesInt];
    },
  },
  mounted() {
    // this.emitirPdf();
    this.$root.$on("emitirPdfBoletimInformativo", this.emitirPdf);
  },
};
</script>
