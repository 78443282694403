<template>


    <div class="card card-outline">

        <div class="card-header p-0 pt-0 background-principal text-color-principal pl-4 mb-0" data-toggle="collapse"
                href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                <h6 class="box-title pt-2 pb-2 mb-1">
                <i class="nav-icon fas fa-search text-color-principal"></i> Filtro Anotação
                </h6>
        </div>

        <div class="collapse" id="collapseExample">
            <div class="card-body">
                <div class="submit-form">
                    <div class="row">
                        

                            <!-- TIPO ANOTACAO -->
                            <div class="col-sm-4">
                                <div class="form-group"    >
                                    <label class="pesquisar-Cor-carro"  for="TipoAnotacao"  >Tipos de Anotações</label>
                                    <v-select class="border-v-select"   v-model="unidade" id="tipoUnidade"
                                    :options="tipos" item-text="text" label="descricao">
                                    
                                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                                    </v-select>
                                </div>
                            </div>
                            <!-- NOME ANOTACAO -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="nome">Nome</label>
                                    <input type="text" class="form-control form-control-border border-width-2" id="nome"
                                    v-model="nome" />
                                </div>
                            </div>

                             <!-- SEXO ANOTACAO -->
                              <div class="col-sm-4">
                                <div class="form-group"    >
                                    <label class="pesquisar-Cor-carro"  for="Sexo"  >Sexo</label>
                                    <v-select class="border-v-select"   v-model="unidade" id="sexo"
                                    :options="sexo" item-text="text" label="descricao">
                                    
                                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- DATA ANOTACAO -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="data">Data</label>
                                    <input type="date" class="form-control form-control-border border-width-2" id="data"
                                    placeholder="dd/mm/aaaa" v-model="data" />
                                </div>
                            </div>

                      

                    </div>

                    <div class="card-footer">
                        <div class="text-center">
                            <button @click.prevent="buscarProcesso" class="btn text-color-principal background-principal ml-1"
                                type="submit">
                                <i class="fa fa-search"></i> Buscar
                            </button>
                             <button @click.prevent="LimparAtaRegistro" type="reset" class="btn btn-default">
                                <i class="fas fa-ban"></i> Limpar
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    
    data(){
        return {
            tipos:[],
            sexo:[],
        };
    },
}
</script>







