<template>

    <div>


        <b-modal id="modal-50" ref="modal-50" title="Cadastrar Supervisor(a)" data-backdrop="static" size=""
            cancel-disabled no-close-on-backdrop hide-header-close header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            " content-class="modal-content border-rounded-detail" dialog-class="p-3"
            title-class="modal-title mx-auto auto">
            <div class="card-body">

                <h6 class="mt-4 mb-3"><b>Selecione um(a) supervisor(a): </b></h6>
                <div class="row">

                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="ferias-supervidor"></label>
                            <v-select class="border-v-select mt-1" id="ferias-supervidor" 
                            v-model="supervisorAssinatura" :options="listaSupervisor" :reduce="(listaSupervisor) => listaSupervisor.id" label="nome">
                                
                                
                                <span slot="no-options">Desculpe, sem opções no momento.</span>
                            </v-select>
                        </div>
                    </div>



                </div>

            </div>
            <template #modal-footer="{ ok, cancel }">

                <button @click.prevent="[alterarSupervisor(ferias.idFerias), ok()]"
                    class="btn text-color-principal background-principal ml-1">
                    <i class="far fa-envelope"></i> Salvar
                </button>
                <button @click.prevent="LimparFerias" type="reset" class="btn btn-default">
                    <i class="fas fa-ban"></i> Limpar
                </button>
                <b-button class="btn btn-default float-right" @click="[cancel(), apagarCamposFerias()]">
                    <i class="fa-solid fa-x"></i> Fechar
                </b-button>
            </template>
        </b-modal>
    </div>

</template>

<script>

import rhService from "@/service/RHService";
import ConsultaFerias from "@/components/rh/ferias/ConsultaFerias.vue";
import { EventBus } from "@/main";
import FeriasFracionadas from "./FeriasFracionadas.vue";

export default {

    components: {


    },
    data() {

        return {
            data: {},
            ferias: {},
            listaFerias: [],
            listaSupervisor: {},
            feriasId: {},
            supervisorAssinatura: 0,
            submitted: false,
            isSaving: false,
            idFerias: {},

        }

    },

    validations: {

        ferias: {
        },
    },

    created() {

        EventBus.$on("EnvioFeriasId", (listener) => {
            for (let id of listener) {
                console.log("ferias: " + id);
                this.getFeriasPorId(id);
            }
        });
        this.buscaSupervisor();

    },


    methods: {

        buscaSupervisor() {
            rhService
                .buscaSupervisors()
                .then((response) => {
                    this.listaSupervisor = response.data;
                });
        },

        getFeriasPorId(feriasId) {
            rhService.getFeriasPorId(feriasId).then((resp) => {
                this.listaFerias.push(resp.data);
                console.log("ferias2: ", this.listaFerias);

            });
        },


        LimparFerias() {
            this.ferias = {};

        },

        apagarCamposFerias() {
            this.ferias = {};
        },
        alterarSupervisor() {
            for (let ferias of this.listaFerias) {
                ferias.supervisorAssinatura=this.supervisorAssinatura
                rhService.editarFerias(ferias)
                .then((resp) => {
                    Toast.fire("Edição realizada", "", "success");
                })
                .catch(() => {
                    Toast.fire("Falha ao editar férias", "", "error");
                });
            }
            this.listaFerias = [];
        },

    },

}
</script>
