<template>
  <section class="conteudo">
    <slot />
  </section>
</template>
<script>
// import notificacoesToggle from "@/util/notificacoesToggle";
import timeToken from "@/util/timeToken";

export default {
  name: "conteudo",
  mounted() {
    const minutos = 30;
    timeToken.$emit("resetaTimeToken", minutos * 60);
    // timeToken.$emit('resetaTimeToken', 6*60)
    // notificacoesToggle.$emit("buscarNovasNotificacoes", null);
    // notificacoesToggle.$emit("buscarNovasNotificacoesSistema", null)
  },
};
</script>
