class converteFuncionario {

    funcionarioDtoToFuncionarioArray(funcionarioDto) {
        let funcionario = [];
        funcionarioDto.forEach(element => {

            funcionario.push(this.funcionarioDtoToFuncionarioSimples(element));
            
        });

        return funcionario;
    }

    funcionarioDtoToFuncionarioSimples(funcionarioDto) {
        let novo = {};

        if (funcionarioDto.pessoaResumo != undefined){

            novo.id = funcionarioDto.id;
            novo.idPessoa = funcionarioDto.pessoaResumo.id;
            novo.nome = funcionarioDto.pessoaResumo.nome;
            novo.fkSetor = funcionarioDto.setorResumo;

            return novo;
        }else{
            return funcionarioDto;
        }
    }

    preencheCopiaDocumento(funcionarioDto){
       let documentoCopia = {};

       documentoCopia.fkSetorDestino = funcionarioDto.fkSetor;
       documentoCopia.fkUsuarioDestino = funcionarioDto;

       return documentoCopia;

    }

}


export default new converteFuncionario();