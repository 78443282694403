<template>
  <div>
    <h6 class="mt-4 mb-3">
      <b>A pessoa selecionada possui os seguintes funcionários registrados: </b>
    </h6>

    <div class="card">
       <div class="card-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Matrícula</th>
              <th>CPF</th>
              <th>Origem</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="func in pessoaEdicao" class="clicavel"
              @click="cadastrarUsuario(func.id)"
              :key="func.id">
              <td>{{ func.nome }}</td>
              <td>{{ func.matricula }}</td>
              <!-- <td>{{ func.novaMatricula }}</td> -->
              <td>{{ func.login }}</td>
              <td>{{ func.origem }}</td>
             
              <td>
                {{ func.ativo ? "Ativo" : "Inativo" }}
                <i
                  :class="
                    func.ativo
                      ? 'fa-solid fa-circle-check green'
                      : 'fa-solid fa-circle-xmark red'
                  "
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-danger float-right"
          @click.prevent="cancelarCadastroFuncionario"
        >
          <i class="fa-solid fa-x"></i>
          Cancelar
        </button>

        <button
          class="mr-2 btn btn-success float-right"
          href="#continuarCadastroFuncionario"
          @click.prevent="continuarCadastroFuncionario"
        >
          <i class="fa-solid fa-arrow-right"></i>
          Continuar Cadastro 
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService"

export default {
  name: "ListaFuncionariosExistentesPessoa",
  data() {
    return {
        listaFuncionariosExistentes: this.$store.state.funcionario.funcionariosExistentes,
    };
  },
  created() {
    // console.log(this.funcionariosExistentes);
    // this.$store.commit("funcionariosExistentes", {});
    // console.log(this.funcionariosExistentes);
  },
  computed: {
    ...mapState(['funcionario']),

    pessoaEdicao() {

      this.listaFuncionariosExistentes = this.funcionario.funcionariosExistentes;

      return this.listaFuncionariosExistentes;
    },
  },
  methods: {
    continuarCadastroFuncionario() {
         this.$store.commit("visualizarListaFuncionariosExistentes", false);
         this.$store.commit("existeFuncionarioAtivo", true);
     },
    cadastrarUsuario(id){
      consultaFuncionarioService.buscarFuncionarioUsuario(id)
      .then((response) => {

        this.$store.commit("funcionarioUsuario", id);
        this.$store.commit("setFuncionarioUsuario",response.data);
        this.$store.commit("visualizarListaFuncionariosExistentes", false);
        this.$store.commit("existeFuncionarioAtivo", true);

      });
    }
  }
};
</script>

<style scoped>
.text-link {
  color: #fff
}

.text-link:hover {
  color: #fff
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.clicavel{
  cursor: pointer;
}
</style>