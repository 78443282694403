import instance from "./axiosConfig";

// const API_LISTAS = "http://172.20.1.32:8086";

class PerfisService {
  cadastroPerfis(idUsuario, perfis) {
    return instance.post(`/usuarios-service/api/usuarios/perfils/usuario_perfil?idUsuario=${idUsuario}`,
      perfis
    );
  }
  carregarTodosPerfilsHierarquicamente() {
    return instance.get("/usuarios-service/api/perfils/hierarchy");
  }
}

export default new PerfisService();
