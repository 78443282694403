
<template>

  <div
    class="card-body px-0 d-flex flex-row max-height-content overflow-hidden justify-content-center align-items-center">
    <div class="h-auto d-flex ">

      <img class="border mt-2 max-height-content figure-img img-fluid rounded" src="
         https://webc.ssp.ma.gov.br/imagens/elementos/HomeSigma/wallpaper.jpg
        " />


    </div>

  </div>

</template>

<script>
export default {
  name: 'ImagemHome'
}
</script>
