<template>
  <div>
   
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="ata-registro-idContratacao"
              >id Contratacao</label
            >
            <input
              type="text"
              class="form-control form-control-border"
              name="idContratacao"
              id="ata-registro-idContratacao"
              data-cy="idContratacao"
              :class="{
              'is-invalid': isSaving && $v.ataRegistro.idContratacao.$error,
              

            }"
              v-model="ataRegistro.idContratacao"
            />
          </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="ata-registro-numero"
            >Número</label
          >
          <input
            type="text"
            class="form-control form-control-border"
            name="numero"
            id="ata-registro-numero"
            data-cy="numero"
            :class="{
              'is-invalid': isSaving && $v.ataRegistro.numero.$error,
            }"
            v-model="ataRegistro.numero"
          />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="ata-registro-ano">Ano</label>
          <input
            type="number"
            class="form-control form-control-border"
            name="ano"
            id="ata-registro-ano"
            data-cy="ano"
            :class="{
              'is-invalid': isSaving && $v.ataRegistro.ano.$error,
            }"
            v-model.number="ataRegistro.ano"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="ata-registro-valor"
            >Valor</label
          >
          <input
            type="number"
            class="form-control form-control-border"
            name="valor"
            id="ata-registro-valor"
            data-cy="valor"
            :class="{
              'is-invalid': isSaving && $v.ataRegistro.valor.$error,
            }"
            v-model.number="ataRegistro.valor"
          />
        </div>
      </div>
      <!--div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="ata-registro-dataInicio"
            >Data Início</label
          >
          <div class="d-flex">
            <input
              id="ata-registro-dataInicio"
              data-cy="dataInicio"
              type="datetime-local"
              class="form-control form-control-border"
              name="dataInicio"
              :class="{
                'is-invalid': isSaving && $v.ataRegistro.dataInicio.$error,
              }"
              :model="convertDateTimeFromServer(ataRegistro.dataInicio)"
              @change="updateZonedDateTimeField('dataInicio', $event)"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="ata-registro-dataFim"
            >Data Fim</label
          >
          <div class="d-flex">
            <input
              id="ata-registro-dataFim"
              data-cy="dataFim"
              type="datetime-local"
              class="form-control form-control-border"
              name="dataFim"
              :class="{
                'is-invalid': isSaving && $v.ataRegistro.dataFim.$error,
              }"
              :model="convertDateTimeFromServer(ataRegistro.dataFim)"
              @change="updateZonedDateTimeField('dataFim', $event)"
            />
          </div>
        </div>
      </div-->

      <div class="col-sm-4">
        <div class="form-group">
            <label class="form-control-label" for="ata-registro-dataInicio"
              >Data Inicio</label
              >
            <div class="d-flex">
              <input
                type="date" data-cy="dataInicio" class="form-control form-control-border border-width-2" id="ata-registro-dataInicio"
                  placeholder="dd/mm/aaaa"  :model=" convertDateTimeFromServer(ataRegistro.dataInicio)" :class="{
                    'is-invalid': isSaving && $v.ataRegistro.dataInicio.$error,
                      }"
                  @change="updateZonedDateTimeField('dataInicio', $event)"
              />
            </div>
        </div>
      </div>

    <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="ata-registro-dataFim"
            >Data Fim</label
                    >
        <div class="d-flex">
            <input
              type="date" data-cy="dataFim" class="form-control form-control-border border-width-2" id="ata-registro-dataFim"
              placeholder="dd/mm/aaaa" :model="convertDateTimeFromServer(ataRegistro.dataFim)" :class="{
              'is-invalid': isSaving && $v.ataRegistro.dataFim.$error,
                    }"
              @change="updateZonedDateTimeField('dataFim', $event)"
            />
        </div>
      </div>
    </div>


      </div>

      
      <div class="card-footer">
      <div class="float-right">
        <!--button class="btn btn-default mr-1">
          <i class="fas fa-pencil-alt"></i> Rascunho
        </button-->
        <button @click="salvarAtaRegistro" class="btn text-color-principal background-principal ml-1">
          <i class="far fa-envelope"></i> Enviar
        </button>
        <button @click.prevent="LimparAtaRegistro" type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Limpar
      </button>
      <!--/div>
      <button @click.prevent="cancelarAtaRegistro" type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div-->
    </div>
      <!--button @click.prevent="LimparAtaRegistro" type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Limpar
      </button-->
    </div>
  </div>

</template>
<script>
import { required } from "vuelidate/lib/validators";

import { DATE_TIME_LONG_FORMAT, DATE_FORMAT_LOCAL_DATA, } from "@/util/filter";
import dayjs from "dayjs";
import { EventBus } from "@/main";
import processosService from '@/service/ProcessosService';
import TabelaAtaRegistro from "@/components/processos/TabelaAtaRegistro.vue"
import FiltroTabelaAtaRegistro from "@/components/processos/FiltroTabelaAtaRegistro.vue";

import router from '@/router';
import TabelaAtaRegistroVue from '@/components/processos/TabelaAtaRegistro.vue';



export default {
  name: "Ata-Registro",
  components:{TabelaAtaRegistro, FiltroTabelaAtaRegistro},
  data() {
    return {
      data:{},
      ataRegistro:{},
      submitted: false,
      isSaving: false,
      setores: [],
      funcionarios: [],
      atasRegistro:[]
    };
  },


  

 
  validations: {
    ataRegistro: {
      numero: {},
      ano: {},
      valor: {},
      dataInicio: {},
      dataFim: {},
      idContratacao: {}
      //idContratacao: { required }
    },

    
  
  },

  created() {
      EventBus.$on("EnvioAtaRegistro",(listener)=>{
        this.getAtaRegistro(listener);
        
        // this.ataRegistro = listener;
        
      })

      //EventBus.$on("ApagarCamposAtaRegistro",()=>{
        //this.ataRegistro = {};
     // })

      
    },


  methods: {
    //;updateZonedDateTimeField(field, event) {
      //if (event.target.value) {
      // this.ataRegistro[field] = dayjs(
      //event.target.value,
        //  DATE_FORMAT_LOCAL_DATA

         
       //);
      //} else {
      //  this.ataRegistro[field] = null;
    //  }
   // },

     convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

    LimparAtaRegistro(){
      
      this.ataRegistro = {};
      document.getElementById('ata-registro-dataFim').value = "";
      document.getElementById('ata-registro-dataInicio').value = "";
      
    },
 
    getAtaRegistro(id){

      processosService.getAtaRegistro(id).then((resp)=>{
       this.ataRegistro= resp.data;
       console.log(this.ataRegistro);
     })
   },

 
   
   

  
    salvarAtaRegistro(){

      console.log("SALVAR ATA REGISTRO")
      this.isSaving = true;
      this.$v.$touch();

      if (this.$refs["ata-registro"]!= undefined){
        this.ataRegistro = Object.assign(
          {},
          this.ataRegistro,
          this.$refs["ata-registro"].ataRegistro
        );

        this.$refs["ata-registro"].isSaving = this.isSaving;
        if (this.$v.$ivalid || this.$refs["ata-registro"].$v.$ivalid){
          return;
        } else{
          if (this.$v.$ivalid){
            return;


          }
        }

      }
      
      processosService.criarAtaRegistro(this.ataRegistro)
      .then((response)=> {
        this.ataRegistro= response.data;
        Toast.fire("Cadastrado realizado", "", "success");
        this.ataRegistro = {};
        document.getElementById('ata-registro-dataFim').value = "";
        document.getElementById('ata-registro-dataInicio').value = "";
       
      
        

      })

      
    },

    

  
    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.ataRegistro[field] = dayjs(
        event.target.value,
          DATE_FORMAT_LOCAL_DATA
       );
     } else {
        this.ataRegistro[field] = null;
      }
    },
  }
  // mounted() {
  //   this.carregaSetores();
  //   this.carregaFuncionario();
  // }
};
</script>