<template>

    <div>

        <div class="card-body">

            <div class="row">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="usuario" :checked="!ferias.boletimInformativo"
                    v-model="ferias.boletimInformativo" id="inlineRadio3" value="false">
                    <label class="form-check-label" for="emissaoBI-inlineRadio3">Boletim Informativo de Férias</label>
                </div>
            </div>
            <h2></h2>
            
            <div class="row">
                <div class="form-group col-lg-3">
                    <label class="form-control-label" for="emissaoBI-anoFerias"
                        >Ano Férias
                        </label>
                        <input
                        type="text"
                        class="form-control form-control-border"
                        name="anoFerias"
                        id="emissaoBI-anoFerias"
                        data-cy="anoFerias"
                        :class="{
                            'is-invalid': isSaving && $v.feria.anoFerias.$error}"
                            v-model="ferias.anoFerias"
                        />
                </div>

                <div class="form-group col-lg-3">
                    <label for="emissaoBI-mesFerias">Mês Férias</label>
                    <v-select
                        class="border-v-select mt-1"
                        id="emissaoBI-mesFerias"
                        v-model="ferias.mesFerias"
                        :options="mesFerias"
                        :reduce="mesFerias => mesFerias.id"
                        label="descricao"
                        >
                        <span slot="no-options">Desculpe, sem opções no momento.</span>
                    </v-select>
                </div>
            </div>

            <div class="row">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="usuario" :checked="ferias.boletimTransferencia"
                    v-model="ferias.boletimTransferencia" id="emissaoBI-inlineRadio4" value="true" >
                    <label class="form-check-label" for="emissaoBI-inlineRadio4">Boletim Informativo de Transferência de Férias</label>
                </div>
            </div>

            <h2></h2>

            <div class="row">

                
                    <div class="form-group col-lg-3">
                        <label class="form-control-label" for="emissaoBI-anoTransferencias"
                        >Ano Transferência
                        </label>
                        <input
                        type="text"
                        class="form-control form-control-border"
                        name="anoTransferencias"
                        id="emissaoBI-anoTransferencias"
                        data-cy="anoTransferencias"
                        :class="{
                        'is-invalid': isSaving && $v.feria.anoTransferencias.$error}"
                        v-model="ferias.anoTransferencias"
                        />
                    </div>

                    <div class="form-group col-lg-3">
                        <label for="emissaoBI-mesesTransferencias">Mês Transferência</label>
                        <v-select
                            class="border-v-select mt-1"
                            id="emissaoBI-mesesTransferencias"
                            v-model="ferias.mesTransferencias"
                            :options="mesTransferencias"
                            :reduce="mesTransferencias => mesTransferencias.id" 
                            label="descricao"
                            >
                            <span slot="no-options">Desculpe, sem opções no momento.</span>
                        </v-select>
                    </div>
            </div>
      
            
            <div class="card-footer">
                <div class="float-right">
                    <!-- <button @click="SalvarFerias" class="btn text-color-principal background-principal ml-1">
                    <i class="far fa-envelope"></i> Enviar
                    </button> -->
                    <button @click.prevent="LimparFerias" type="reset" class="btn btn-default">
                    <i class="fas fa-ban"></i> Limpar
                    </button>
                    <button @click.prevent="ImprimirFerias" type="print" class="btn btn-default">
                    <i class="fas fa-times"></i> Imprimir
                    </button>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

import {DATE_FORMAT_LOCAL_DATA, } from "@/util/filter";
import dayjs from "dayjs";

export default {

    data(){

        return{
            data:{},
            ferias:{},
            mesFerias:[

                 { id : 1, descricao: "Janeiro" },
                { id : 2, descricao: "Fevereiro" },
                { id : 3, descricao: "Março" },
                { id : 4, descricao: "Abril" },
                { id : 5, descricao: "Maio" },
                { id : 6, descricao: "Junho" },
                { id : 7, descricao: "Julho" },
                { id : 8, descricao: "Agosto" },
                { id : 9, descricao: "Setembro" },
                { id : 10, descricao: "Outubro" },
                { id : 11, descricao: "Novembro" },
                { id : 12, descricao: "Dezembro" },

            ],
            mesTransferencias:[

                 { id : 1, descricao: "Janeiro" },
                { id : 2, descricao: "Fevereiro" },
                { id : 3, descricao: "Março" },
                { id : 4, descricao: "Abril" },
                { id : 5, descricao: "Maio" },
                { id : 6, descricao: "Junho" },
                { id : 7, descricao: "Julho" },
                { id : 8, descricao: "Agosto" },
                { id : 9, descricao: "Setembro" },
                { id : 10, descricao: "Outubro" },
                { id : 11, descricao: "Novembro" },
                { id : 12, descricao: "Dezembro" },

            ],
            submitted: false,
            isSaving: false
        }
        
    },

    validations:{

        ferias:{
            novoFerias:{},
            mesFerias:{},
            anoTransferencias:{},
            mesTransferencias:{},
            
        },
    },

    
    methods:{
    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

     updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.feria[field] = dayjs(
        event.target.value,
          DATE_FORMAT_LOCAL_DATA
       );
     } else {
        this.feria[field] = null;
      }
    },
    
    LimparFerias(){
        this.ferias = {};
    },
    ImprimirFerias(){},

  },
   
}
</script>