var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"ata-registro-idContratacao"}},[_vm._v("id Contratacao")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ataRegistro.idContratacao),expression:"ataRegistro.idContratacao"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.ataRegistro.idContratacao.$error,
            

          },attrs:{"type":"text","name":"idContratacao","id":"ata-registro-idContratacao","data-cy":"idContratacao"},domProps:{"value":(_vm.ataRegistro.idContratacao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ataRegistro, "idContratacao", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"ata-registro-numero"}},[_vm._v("Número")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ataRegistro.numero),expression:"ataRegistro.numero"}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.ataRegistro.numero.$error,
          },attrs:{"type":"text","name":"numero","id":"ata-registro-numero","data-cy":"numero"},domProps:{"value":(_vm.ataRegistro.numero)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ataRegistro, "numero", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"ata-registro-ano"}},[_vm._v("Ano")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.ataRegistro.ano),expression:"ataRegistro.ano",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.ataRegistro.ano.$error,
          },attrs:{"type":"number","name":"ano","id":"ata-registro-ano","data-cy":"ano"},domProps:{"value":(_vm.ataRegistro.ano)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ataRegistro, "ano", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"ata-registro-valor"}},[_vm._v("Valor")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.ataRegistro.valor),expression:"ataRegistro.valor",modifiers:{"number":true}}],staticClass:"form-control form-control-border",class:{
            'is-invalid': _vm.isSaving && _vm.$v.ataRegistro.valor.$error,
          },attrs:{"type":"number","name":"valor","id":"ata-registro-valor","data-cy":"valor"},domProps:{"value":(_vm.ataRegistro.valor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ataRegistro, "valor", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"ata-registro-dataInicio"}},[_vm._v("Data Inicio")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
                  'is-invalid': _vm.isSaving && _vm.$v.ataRegistro.dataInicio.$error,
                    },attrs:{"type":"date","data-cy":"dataInicio","id":"ata-registro-dataInicio","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.ataRegistro.dataInicio)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataInicio', $event)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"ata-registro-dataFim"}},[_vm._v("Data Fim")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control form-control-border border-width-2",class:{
            'is-invalid': _vm.isSaving && _vm.$v.ataRegistro.dataFim.$error,
                  },attrs:{"type":"date","data-cy":"dataFim","id":"ata-registro-dataFim","placeholder":"dd/mm/aaaa","model":_vm.convertDateTimeFromServer(_vm.ataRegistro.dataFim)},on:{"change":function($event){return _vm.updateZonedDateTimeField('dataFim', $event)}}})])])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn text-color-principal background-principal ml-1",on:{"click":_vm.salvarAtaRegistro}},[_c('i',{staticClass:"far fa-envelope"}),_vm._v(" Enviar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.LimparAtaRegistro.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Limpar ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }