<template>
  <div v-role="'GDOCS'">
    <content-header :title="titulo" />
    <conteudo>
      <div class="container-fluid">
        <div class="row">
          <menu-email text="Caixa de Entrada" link="/" />
          <!-- /.col -->
          <div class="col-md-9">
            <div class="card card-primary">
              <div class="card-header background-principal text-color-principal">
                <h3 class="card-title">{{ titulo }}</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="submit-form">
                  <div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="form-control-label" for="documento-assunto">Tipo Documento <span
                              class="text-danger">*</span>
                          </label>
                          <!-- <v-select class="form-control form-control-border"
                            id="documento-tipoDocumento"
                            :options="books"
                            label="title"
                          ></v-select> -->
                          <v-select class="border-v-select" id="documento-tipoDocumento" :class="{
                            'is-invalid':
                              isSaving && $v.documento.tipoDocumento.$error,
                          }" data-cy="tipoDocumento" name="tipoDocumento" v-model="documento.tipoDocumento"
                            @input="mudarForm" :options="tipoDocumento" label="descricao">
                            <!-- <option value="" selected>Selecione</option>
                            <option value="CI">Comunicação Interna</option>
                            <option value="OF">Ofício</option>
                            <option value="DE">Despacho</option> -->
                            <span slot="no-options">Desculpe, sem opções no momento.</span>
                          </v-select>
                          <div v-if="isSaving && !$v.documento.tipoDocumento.required" class="invalid-feedback">Tipo
                            documento é obrigatório</div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="form-control-label" for="documento-assunto">Tramitação
                            <span class="text-danger">*</span></label>
                          <select class="form-control form-control-border" id="documento-tramitacao"
                            data-cy="tramitacao" name="tramitacao" v-model="documento.origemDocumento"
                            :disabled="documento.tipoDocumento == null || documento.tipoDocumento.id != 'OF'"
                            @change="mudarForm" :class="{
                              'is-invalid':
                                isSaving && $v.documento.origemDocumento.$error,
                            }">
                            <option value="IN">Interno</option>
                            <option value="EX">Externo</option>
                          </select>
                          <div v-if="isSaving && !$v.documento.origemDocumento.required" class="invalid-feedback">
                            Tramitação documento é obrigatório</div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="form-control-label" for="documento-dataExpedicao">Data Expedição
                            <span class="text-danger">*</span></label>
                          <!-- <div class="d-flex"> -->
                          <input id="documento-dataExpedicao" data-cy="dataExpedicao" type="datetime-local"
                            class="form-control form-control-border" name="dataExpedicao"
                            v-model="documento.dataExpedicao"
                            :class="{ 'is-invalid': isSaving && $v.documento.dataExpedicao.$error, }" />
                          <div v-if="isSaving && !$v.documento.dataExpedicao.required" class="invalid-feedback">Data
                            expedição documento é obrigatório</div>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                    <component :is="componente" ref="corpo-documento" />
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <textarea id="compose-textarea" class="form-control">
                            <h1><u>Heading Of Message</u></h1>
                            <h4>Subheading</h4>
                            <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain
                              was born and I will give you a complete account of the system, and expound the actual teachings
                              of the great explorer of the truth, the master-builder of human happiness. No one rejects,
                              dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know
                              how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again
                              is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain,
                              but because occasionally circumstances occur in which toil and pain can procure him some great
                              pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise,
                              except to obtain some advantage from it? But who has any right to find fault with a man who
                              chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that
                              produces no resultant pleasure? On the other hand, we denounce with righteous indignation and
                              dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so
                              blinded by desire, that they cannot foresee</p>
                            <ul>
                              <li>List item one</li>
                              <li>List item two</li>
                              <li>List item three</li>
                              <li>List item four</li>
                            </ul>
                            <p>Thank you,</p>
                            <p>John Doe</p>
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <div class="btn btn-default btn-file">
                            <i class="fas fa-paperclip"></i> Attachment
                            <input type="file" name="attachment" />
                          </div>
                          <p class="help-block">Max. 32MB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <div class="float-right">
                  <button class="btn btn-default">
                    <i class="fas fa-pencil-alt"></i> Draft
                  </button>
                  <button @click.prevent="salvarDocumento" class="btn btn-primary">
                    <i class="far fa-envelope"></i> Send
                  </button>
                </div>
                <button type="reset" class="btn btn-default">
                  <i class="fas fa-times"></i> Discard
                </button>
              </div>
              <!-- /.card-footer -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </conteudo>
  </div>
</template>
<script>
import documentoService from "../../service/DocumentoService";
// import alertService from '../../util/alert.service';
import DE from "@/components/documentos/DespachoFormularioDocumento.vue";
import EX from "@/components/documentos/ExternoFormularioDocumento.vue";
import { default as CI, default as IN } from "@/components/documentos/InternoFormularioDocumento.vue";

import { required } from "vuelidate/lib/validators";

export default {
  name: "Documentos",
  components: { IN, EX, CI, DE },
  data() {
    return {
      documento: {},
      submitted: false,
      isSaving: false,
      assunto: false,
      componente: "",
      titulo: "Novo Documento",
      tipoDocumento: [
        { descricao: "Comunicação Interna", id: "CI" },
        { descricao: "Ofício", id: "OF" },
        { descricao: "Despacho", id: "DE" }
      ],
    };
  },
  // computed: {
  //   currentUser() {
  //     return this.$store.state.auth.user;
  //   }
  // },
  mounted() {
    // if (!this.currentUser) {
    //   this.$router.push('/login');
    // }
    $(function () {
      $("#compose-textarea").summernote();
    });
  },
  validations: {
    documento: {
      tipoDocumento: { required },
      origemDocumento: { required },
      dataExpedicao: { required }
      // fkSetorDestino: { required },
      // fkUsuarioDestino: { required },
      // cargoDestino: { required },
      // tratamento: { required },
      // fkUsuario: { required},
      // cargoSignatario: { required},
      // assunto: {required}
      // fecho: { required},
      // corpo: { required}
    },
  },
  methods: {
    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.documento[field] = dayjs(
          event.target.value,
          DATE_TIME_LONG_FORMAT
        );
      } else {
        this.documento[field] = null;
      }
    },
    salvarDocumento() {
      this.isSaving = true;
      if (this.documento.id) {
        documentoService
          .update(this.documento)
          .then((param) => {
            this.isSaving = false;
            this.$router.go(-1);
            const message =
              "A Documento is updated with identifier " + param.id;
            return this.$root.$bvToast.toast(message.toString(), {
              toaster: "b-toaster-top-center",
              title: "Info",
              variant: "info",
              solid: true,
              autoHideDelay: 5000,
            });
          })
          .catch((error) => {
            this.isSaving = false;
            alertService.showHttpError(this, error.response);
          });
      } else {
        // let teste = this.$children[2].documento.assunto;

        // Vue.set(this.documento, 'assunto', teste);

        this.isSaving = true;
        this.$v.$touch();

        if (this.$refs["corpo-documento"] != undefined) {

          this.documento = Object.assign(
            {},
            this.documento,
            this.$refs["corpo-documento"].documento
          );

          this.$refs["corpo-documento"].isSaving = this.isSaving;

          if (this.$v.$invalid || this.$refs["corpo-documento"].$v.$invalid) {
            return;
          }

        } else {

          if (this.$v.$invalid) {
            return;
          }

        }

        documentoService
          .criarDocumento(this.documento)
          .then((param) => {
            this.isSaving = false;
            this.$router.go(-1);
            const message = "Documento criado com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
          })
          .catch((error) => {
            this.isSaving = false;
            Toast.fire(error.response.data.detail.toString(), "", "error");
            // alertService.showHttpError(this, error.response);
          });
      }
    },
    mudarForm(event) {
      this.componente = event.id;

      if (event.id != 'OF') {
        this.documento.origemDocumento = 'IN';
      } else {

        this.documento.origemDocumento = '';

      }
    },
  },
};
</script>
