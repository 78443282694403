<template>
  <div>

    <!-- Formulario de endereco de unidade -->
    <FormCadastroEnderecoUnidadeSubordinada v-show="formEnderecoShow" @esconderFormEndereco="esconderEndereco"
      @voltarParaUnidadeDetalhe="voltarParaUnidadeDetalhe" />
    <!-- Formulario de telefone de unidade -->
    <FormCadastroTelefoneUnidadeSubordinada v-show="formTelefoneShow" @esconderFormTelefone="esconderTelefone"
      @voltarParaUnidadeDetalhe="voltarParaUnidadeDetalhe" />
    <!-- Formulario de email de unidade -->
    <FormCadastroEmailUnidadeSubordinada v-show="formEmailShow" @esconderFormEmail="esconderEmail"
      @voltarParaUnidadeDetalhe="voltarParaUnidadeDetalhe" />



  </div>
</template>
<script>
import FormCadastroEmailUnidadeSubordinada from './FormCadastroEmailUnidadeSubordinada.vue';
import FormCadastroEnderecoUnidadeSubordinada from './FormCadastroEnderecoUnidadeSubordinada.vue';
import FormCadastroTelefoneUnidadeSubordinada from './FormCadastroTelefoneUnidadeSubordinada.vue';

export default {
  name: "CadastrarDadosUnidadeSubordinada",
  components: { FormCadastroEnderecoUnidadeSubordinada, FormCadastroTelefoneUnidadeSubordinada, FormCadastroEmailUnidadeSubordinada },
  data() {
    return {
      formEnderecoShow: true,
      formTelefoneShow: false,
      formEmailShow: false,
    };
  },
  methods: {
    esconderEndereco() {
      this.formEnderecoShow = false;
      this.formTelefoneShow = true;
    },
    esconderTelefone() {
      this.formTelefoneShow = false;
      this.formEmailShow = true;
    },
    esconderEmail() {
      this.formEmailShow = false;
      this.$emit('voltarParaUnidadeSuperiorDetalhe')
    },
    voltarParaUnidadeDetalhe() {
      this.$emit('voltarParaUnidadeSuperiorDetalheIncompleto')
    }


  }
}
</script>
