import TokenService from "@/service/TokenService";
import ViewCadastroFuncionarioVue from "@/views/administracao/funcionario/ViewCadastroFuncionario.vue";
import ViewVisualizacaoFuncionarioVue from "@/views/administracao/funcionario/ViewVisualizacaoFuncionario.vue";
import NotificacoesView from "@/views/administracao/NotificacoesView.vue";
import ViewUnidadesVue from "@/views/administracao/unidade/ViewUnidades.vue";
import ViewAuditoria from "@/views/auditoria/Auditoria.vue";
import ViewConsultaArmaVue from "@/views/consultas/armaConsulta/ViewConsultaArma.vue";
import ViewConsultaCelularVue from "@/views/consultas/celularConsulta/ViewConsultaCelular.vue";
import ViewConsultaChequeVue from "@/views/consultas/chequeConsulta/ViewConsultaCheque.vue";
import ViewConsultaCoisaVue from "@/views/consultas/coisaConsulta/ViewConsultaCoisa.vue";
import ViewConsultaDocumentoVue from "@/views/consultas/documentoConsulta/ViewConsultaDocumento.vue";
import ViewConsultaVeiculoVue from "@/views/consultas/veiculosConsulta/ViewConsultaVeiculo.vue";
import NovaCarteira from "@/views/emissor/NovaCarteira.vue";
import EsqueciSenhaView from "@/views/EsqueciSenhaView.vue";
import Found401View from "@/views/Found401View.vue";
import HomeViewVue from "@/views/HomeView.vue";
import MeuPerfilViewVue from "@/views/MeuPerfilView.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "../components/MainLayout.vue";
import ViewCadastroPessoa from "../views/cadastros/pessoa/ViewCadastroPessoa.vue";
import ViewCadatroVisita from "../views/cadastros/visita/ViewCadatroVisita.vue";
import ViewConsultaBo from "../views/consultas/boConsulta/ViewConsultaBo.vue";
import ViewConsultaPessoa from "../views/consultas/pessoaConsulta/ViewConsultaPessoa.vue";
import ListarDocumento from "../views/documentos/ListarDocumento.vue";
import NovoDocumento from "../views/documentos/NovoDocumento.vue";
import VisualizarDocumento from "../views/documentos/VisualizarDocumento.vue";
import LoginView from "../views/LoginView.vue";
import ViewProcessos from "../views/processos/ViewProcessos.vue";
import Page404View from "@/views/Page404View.vue";
import Page500View from "@/views/Page500View.vue";
import Anotacao from "../views/rhs/anotacoes/ViewAnotacoes.vue";
import RhFerias from "../views/rhs/ferias/ViewFerias.vue";
import RhDiarias from "../views/rhs/diarias/ViewDiarias";
import RhConfiguracoes from "../views/rhs/configuracoes/ViewConfiguracoes"
import Boletim from "@/components/rh/anotacao/BoletimServidor.vue";
import ConsultaAnotacao from "@/components/rh/anotacao/TabTipoAnotacao.vue";
import Frequncia from "../views/rhs/frequencia/ViewFrequencia.vue"
import ViewVisualizacaoUsuario from "@/views/administracao/usuario/ViewVisualizacaoUsuario.vue";
import ViewCadastroUsuario from "@/views/administracao/usuario/ViewCadastroUsuario.vue";

import TabsCadastoFuncionario2 from "../views/TabsCadastoFuncionario2.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [

      {
        path: "",
        name: "home",
        component: HomeViewVue,
      },
      {
        path: "/meuperfil",
        name: "meuperfil",
        component: MeuPerfilViewVue,
      },
      {
        path: "/documentos",
        name: "documentos",
        component: ListarDocumento,
        meta: { authorize: ["GDOCS"] },
      },
      {
        path: "/documentosnovo",
        name: "documentosnovo",
        component: NovoDocumento,
        meta: { authorize: ["CADASTRAR_OFICIO"] },
      },
      {
        path: "/visualizardocumento",
        name: "/visualizardocumento",
        component: VisualizarDocumento,
        meta: { authorize: ["CADASTRAR_OFICIO"] },
      },
      {
        path: "/consultaveiculos",
        name: "consultaveiculos",
        component: ViewConsultaVeiculoVue,
        meta: { authorize: ["CONSULTA_VEICULO"] },
      },
      {
        path: "/consultapessoa",
        name: "consultapessoa",
        component: ViewConsultaPessoa,
        meta: { authorize: ["CONSULTA_INDIVIDUO"] },
      },
      {
        path: "/cadastropessoa",
        name: "administracaocadastropessoa",
        component: ViewCadastroPessoa,
        meta: { authorize: ["CADASTRO_PESSOA"] },
      },
      {
        path: "/cadastrovisita",
        name: "cadastrovisita",
        component: ViewCadatroVisita,
        meta: { authorize: ["CADASTRO_VISITA"] },
      },
      {
        path: "/cadastroservidor",
        name: "cadastrofuncionario",
        component: ViewCadastroFuncionarioVue,
        meta: { authorize: ["RECURSOS_HUMANOS"] },
      },
      {
        path: "/cadastrousuario",
        name: "cadastrousuario",
        component: ViewCadastroUsuario,
        meta: { authorize: ["USUARIOS"] },
      },
      {
        path: "/cadastronotificacoes",
        name: "administracaocadastronotificacoessistema",
        component: NotificacoesView,
        meta: { authorize: ["ADMINISTRACAO"] },
      },
      {
        path: "/visualizacaofuncionario",
        name: "administracaovisualizacaofuncionario",
        component: ViewVisualizacaoFuncionarioVue,
        meta: { authorize: ["RECURSOS_HUMANOS"] },
      },
      {
        path: "/unidade",
        name: "administracaounidade",
        component: ViewUnidadesVue,
        meta: { authorize: ["UNIDADES"] },
      },
      {
        path: "/visualizacaousuario",
        name: "administracaovisualizacaousuario",
        component: ViewVisualizacaoUsuario,
        meta: { authorize: ["ADMINISTRACAO"] },
      },
      {
        path: "/consultacelular",
        name: "consultacelular",
        component: ViewConsultaCelularVue,
        meta: { authorize: ["CONSULTA_CELULAR"] },
      },
      {
        path: "/consultacheque",
        name: "consultacheque",
        component: ViewConsultaChequeVue,
        meta: { authorize: ["CONSULTA_CHEQUE"] },
      },
      {
        path: "/consultaarma",
        name: "consultaarma",
        component: ViewConsultaArmaVue,
        meta: { authorize: ["CONSULTA_ARMA"] },
      },
      {
        path: "/consultacoisa",
        name: "consultacoisa",
        component: ViewConsultaCoisaVue,
        meta: { authorize: ["CONSULTA_COISA"] },
      },
      {
        path: "/consultadocumento",
        name: "consultadocumento",
        component: ViewConsultaDocumentoVue,
        meta: { authorize: ["CONSULTA_DOCUMENTO"] },
      },
      {
        path: "/consultabo",
        name: "consultabo",
        component: ViewConsultaBo,
        meta: { authorize: ["PESQUISAR_BO"] },
      },
/*       {
        path: "/processos",
        name: "processos",
        component: ViewProcessos,
      }, */
      {
        path: "/emissor",
        name: "emissor",
        component: NovaCarteira,
      },

      {
        path:"/frequencia",
        name: "frequencia",
        component: Frequncia,
        meta:{ authorize:["FREQUENCIA"]}
      },
      {
        path: "/servidores",
        name: "servidores",
        component: Anotacao,
        meta: { authorize: ["RECURSOS_HUMANOS"] },
      },
      {
        path: "/ferias",
        name: "ferias",
        component: RhFerias,
        meta: { authorize: ["RECURSOS_HUMANOS"] },
      },
      {
        path: "/diarias",
        name: "diarias",
        component: RhDiarias,
        meta: { authorize: ["RECURSOS_HUMANOS"] },
      },
      {
        path: "/configuracoes",
        name: "configuracoes",
        component: RhConfiguracoes,
        meta: { authorize: ["RECURSOS_HUMANOS"] },
      },
      {
        path: "/boletimInformativo",
        name: "boletimInformativo",
        component: Boletim,
        meta: { authorize: ["RECURSOS_HUMANOS"] },
      },
      {
        path: "/anotacoes",
        name: "anotacoes",
        component: ConsultaAnotacao,
        meta: { authorize: ["RECURSOS_HUMANOS"] },
       },
      {
        path: "/found401",
        name: "found401",
        component: Found401View,
        // meta: { authorize: [""] }
      },
      {
        path: "/found404",
        name: "found404",
        component: Page404View,
        // meta: { authorize: [""] }
      },
/*       {
        path: "/auditoria",
        name: "auditoria",
        component: ViewAuditoria,
        // meta: { authorize: [""] }
      }, */


    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/recuperarsenha",
    name: "recuperarsenha",
    component: EsqueciSenhaView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ["/login", "/recuperarsenha"];
//   const authRequired = !publicPages.includes(to.path);

//   const tokenValido = TokenService.tokenValido(
//     localStorage.getItem("user")
//       ? JSON.parse(localStorage.getItem("user")).token
//       : null
//   );

//   const loggedIn = localStorage.getItem("user") && tokenValido;

//   if (authRequired && !loggedIn) {
//     next({ name: "login", query: { redirect: to.path } });
//   } else {
//     next();
//   }
//   next();
// });


router.beforeEach((to, from, next) => {
  // console.log(router.options.routes)
  // if (!rotaExiste(router.options.routes, to.path)) {
  //   return next({ path: "/" });
  // }
  // const publicPages = ["/login", "/recuperarsenha"];
  // const authRequired = !publicPages.includes(to.path);
  // const loggedIn = localStorage.getItem("user");

  // // trying to access a restricted page + not logged in
  // // redirect to login page
  // if (authRequired && !loggedIn) {
  //   next("/login");
  // } else {
  //   next();
  // }
  // next();

//   const { authorize } = to.meta;
//   const currentUser = JSON.parse(localStorage.getItem("user"));
//   let tokenResolvido = "";
//   if (currentUser) {
//     tokenResolvido = TokenService.parseJwt(currentUser.access_token);
//   }
//   if (authorize) {
//     if (!currentUser) {
//       return next({ path: "/login", query: { returnUrl: to.path } });
//     }

//     // if (authorize.length && !authorize.includes(tokenResolvido.perfis.descricao)) {
//     if (
//       authorize.length &&
//       !tokenResolvido.perfis.find(({ descricao }) => descricao === authorize[0])
//     ) {
//       return next({ path: "/found401" });
//     }
//   }

//   if (to.name === "found401") {
//     setTimeout(() => next({ path: "/" }), 5000);

//     // return next();
//   }

//   next();
// });

  const { authorize } = to.meta;
  const currentUser = JSON.parse(localStorage.getItem("user"));
  let tokenResolvido = "";
  if (currentUser) {
    tokenResolvido = TokenService.parseJwt(currentUser.access_token);
  }
  if (authorize) {
    if (!currentUser) {
      return next({ path: "/login", query: { returnUrl: to.path } });
    }

    // if (authorize.length && !authorize.includes(tokenResolvido.perfis.descricao)) {
    if (
      authorize.length &&
      !tokenResolvido.perfis.find(descricao => descricao === authorize[0])
    ) {
      return next({ path: "/found401" });
    }
  }

  if (to.name === "found401") {
    setTimeout(() => next({ path: "/" }), 5000);

    // return next();
  }

  next();
});

const rotaExiste = (rotas, rotaBuscada) => {
  if (rotaExisteNosFilhos(rotas,rotaBuscada)||rotaExisteNosIrmaos(rotas,rotaBuscada)) {
    return (true)
  }else return (false)
}

const rotaExisteNosFilhos=(rotas,rotaBuscada)=>{
  let existeNosFilhos = false
  rotas.forEach(route => {
    if (route.children != undefined) {
      existeNosFilhos = existeNosFilhos||rotaExiste(route.children, rotaBuscada)
    }
  })
  return existeNosFilhos

}
const rotaExisteNosIrmaos=(rotas,rotaBuscada)=>{
  if (rotas.find(({ path }) => path === rotaBuscada) != undefined) {
    return (true)
  }else return (false)
}


export default router;
