<template>
    <div>
        <!--<loading :active="loaderActive" :is-full-page="fullPage" />-->
        <div class="pl-4 pr-4 pb-4" v-show="!loaderActive">
            <div class="row pt-4">
                <div class="form-check" v-for="(item, indexPai) in vetor" :key="item.descricao">
                    <!-- <input
            class="form-check-input"
            type="checkbox"
            :checked="findPaiFilhos(item)"
            value=""
            v-bind:id="`CheckPai${indexPai}`"
            @click="
              checkFilhos(indexPai, item.perfisFilhos, item.descricao, item)
            "
          /> -->
                    <b-form-checkbox :value="findPaiFilhos(item)" v-bind:id="`CheckPerfil${item.id}`" @change="
                        checkFilhos(indexPai, item.perfisFilhos, item.descricao, item)
                        ">
                        <strong>{{ item.descricao }}</strong>
                    </b-form-checkbox>
                    <!-- <label class="form-check-label" for="flexCheckDefault">
            {{ item.descricao }}
          </label> -->
                    <!--Comentário do estagiário-->
                    <!--Este trecho itera sobre o vetor e renderiza na tela todos os checkbox filho-->
                    <div v-if="item.perfisFilhos.length != 0">
                        <div v-for="(filho, indexFilho) in item.perfisFilhos" :key="filho.descricao">
                            <div class="form-check">
                                <div class="row pl-3 ml-3">
                                    <!-- <input
                    class="form-check-input"
                    type="checkbox"
                    :checked="findPaiFilhos(filho)"
                    value=""
                    v-bind:id="`CheckPerfil${item.id}`"
                    @click="
                      checkPai(
                        indexPai,
                        item.perfisFilhos,
                        filho,
                        indexFilho,
                        item
                      )
                    "
                  />
                  <label class="form-check-label" for="flexCheckChecked">
                    {{ filho.descricao }}
                  </label> -->
                                    <b-form-checkbox :value="findPaiFilhos(filho)" v-bind:id="`CheckPerfil${filho.id}`"
                                        @change="
                                            checkPai(
                                                indexPai,
                                                item.perfisFilhos,
                                                filho,
                                                indexFilho,
                                                item
                                            )
                                            ">
                                        {{ filho.descricao }}
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button @click="funcaoSalvarPerfil" class="btn btn-primary float-right">
                <i class="fa-solid fa-floppy-disk"></i>
                Salvar Perfis
            </button>
        </div>
    </div>
</template>
<script>

import perfisService from "@/service/PerfisService";
import consultaUsuarioService from "@/service/ConsultaUsuarioService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from 'vuex';

export default {
    name: "PerfilFuncionario",
    components: { Loading },
    data() {
      return {
        loaderActive: false,
        fullPage: false,
        idUsuario: 0,
        id: "",
        perfilsParaCadastro: [],
        vetor: [],
        desabilitaBotaoSalvar: false,
        //[{ descricao: "Checkbox pai 1", perfisFilhos: [{ descricao: "Checkbox filho 1", perfisFilhos: [] }, { descricao: "Checkbox filho 2", perfisFilhos: [] }, { descricao: "Checkbox filho 3", perfisFilhos: [] }, { descricao: "Checkbox filho 4", perfisFilhos: [] }, { descricao: "Checkbox filho 5", perfisFilhos: [] }, { descricao: "Checkbox filho 6", perfisFilhos: [] }, { descricao: "Checkbox filho 7", perfisFilhos: [] }, { descricao: "Checkbox filho 8", perfisFilhos: [] }, { descricao: "Checkbox filho 9", perfisFilhos: [] }, { descricao: "Checkbox filho 10", perfisFilhos: [] }] }]
      };
    },
    computed: {
    ...mapState(['usuario']),

      usuarioCadastrado() {

        // this.funcionario = this.funcionarioUsuario;
        this.idUsuario = this.usuario.id;

        return this.idUsuario;
      }
    },
    created() {
      this.carregarTodosPerfilsHierarquicamente();
      this.buscarPerfisPorUsuarioId();
    },
    methods: {
      carregarTodosPerfilsHierarquicamente() {
        this.showLoader();
        perfisService
          .carregarTodosPerfilsHierarquicamente()
          .then((response) => {
            this.vetor = response.data;
            this.hideLoader();
          })
          .catch((e) => {
            this.alertaFalhaCarregarPerfis();
            this.hideLoader();
          });
      },
      // findPaiFilhos(item) {
      //   if (this.perfilsParaCadastro.find((o) => o.id == item.id) != undefined) {
      //     return true;
      //   }
      //   return false;
      // },
      findPaiFilhos(item) {
        setTimeout(() => {
          if (
            this.perfilsParaCadastro.find((o) => o.id == item.id) != undefined
          ) {
            document.getElementById("CheckPerfil" + item.id).checked = true;
            return true;
          }
          return false;
        }, 0.5);
      },
      funcaoSalvarPerfil() {
        this.showLoader();
  
        // this.idFuncionario =
        //   this.$store.state.funcionario.idFuncionarioCadastrado;

        const idUsuarioCadastrado = this.usuario.idUsuarioCadastrado;


        perfisService
          .cadastroPerfis(idUsuarioCadastrado, this.perfilsParaCadastro)
          .then(() => {
            this.desabilitaBotaoSalvar = true;
            Toast.fire("Sucesso ao cadastrar perfis do usuário", "", "success");
            this.hideLoader();
          })
          .catch((e) => {
            this.hideLoader();
            Toast.fire("Falha ao cadastrar perfis do usuário", "", "error");
            alert("ERRO: no cadastro de perfis");
          });
      },
      buscarPerfisPorUsuarioId() {
        // this.idFuncionario =
        //   this.$store.state.funcionario.idFuncionarioCadastrado;

        const idUsuarioCadastrado = this.usuario.idUsuarioCadastrado;
        consultaUsuarioService
          .buscarPerfisPorUsuarioId(idUsuarioCadastrado)
          .then((response) => {
            this.perfilsParaCadastro = response.data.perfils;
          })
          .catch((e) => {});
      },
      validacao(perfilsParaCadastro) {
        for (let i = 0; i < perfilsParaCadastro.length; i++) {
          if (perfilsParaCadastro[i].perfisFilhos.length == 0) {
            for (let j = 0; j < perfilsParaCadastro.length; j++) {
              if (perfilsParaCadastro[j].perfisFilhos.length != 0) {
                if (
                  this.findIndex(
                    perfilsParaCadastro[j].perfisFilhos,
                    perfilsParaCadastro[i]
                  ) != null
                ) {
                  return true;
                }
              }
            }
          }
        }
        return false;
      },
      checkFilhos(indexPai, filhos, descricaoDoPai, pai) {
        if (document.getElementById("CheckPerfil" + pai.id).checked == true) {
          if (this.perfilsParaCadastro.find((o) => o.id == pai.id) == undefined) {
            this.perfilsParaCadastro.push(pai);
          }
          for (var i = 0; i < filhos.length; i++) {
            document.getElementById(
              "CheckPerfil" + filhos[i].id
            ).checked = true;
            if (
              this.perfilsParaCadastro.find((o) => o.id == filhos[i].id) ==
              undefined
            ) {
              this.perfilsParaCadastro.push(filhos[i]);
            }
          }
        } else {
          let index = this.findIndex(this.perfilsParaCadastro, pai);
          if (index != null) {
            // only splice array when item is found
            this.perfilsParaCadastro.splice(index, 1); // 2nd parameter means remove one item only
          }
  
          for (var i = 0; i < filhos.length; i++) {
            let index = this.findIndex(this.perfilsParaCadastro, filhos[i]);
            if (index != null) {
              // only splice array when item is found
              this.perfilsParaCadastro.splice(index, 1); // 2nd parameter means remove one item only
            }
            document.getElementById(
              "CheckPerfil" + filhos[i].id
            ).checked = false;
          }
        }
      },
      checkPai(indexPai, filhos, filho, indexFilho, pai) {
        if (
          document.getElementById("CheckPerfil" + filho.id)
            .checked
        ) {
          document.getElementById("CheckPerfil" + pai.id).checked = true;
          if (this.perfilsParaCadastro.find((o) => o.id == pai.id) == undefined) {
            this.perfilsParaCadastro.push(pai);
          }
          if (
            this.perfilsParaCadastro.find((o) => o.id == filho.id) == undefined
          ) {
            this.perfilsParaCadastro.push(filho);
          }
        } else {
          let index = this.findIndex(this.perfilsParaCadastro, filho);
          if (
            this.perfilsParaCadastro.find((o) => o.id == filho.id) != undefined
          ) {
            if (index != null) {
              this.perfilsParaCadastro.splice(index, 1); // 2nd parameter means remove one item only
            }
          }
        }
      },
      // findPaiFilhos(item) {
      //   if (this.perfilsParaCadastro.find((o) => o.id == item.id) != undefined) {
      //     return true;
      //   }
      //   return false;
      // },
      findIndex(vetor, elemento) {
        for (let index = 0; index < vetor.length; index++) {
          if (vetor[index].id == elemento.id) {
            return index;
          }
        }
        return null;
      },
      showLoader() {
        this.loaderActive = true;
      },
      hideLoader() {
        this.loaderActive = false;
      },
      alertaFalhaCarregarPerfis() {
        Toast.fire("Falha ao carregar perfis ", "", "error");
      },
    },
  };
  </script>