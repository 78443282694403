<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card card-primary">
        <div class="card-header background-principal">
          <h3 class="card-title">Buscar Férias</h3>
        </div>
        <div class="card-body">

          <div class="form-group">

            <label for="exampleSelectBorderWidth2">Matrícula/ID</label>
            <select v-model="tipoConsultaAnotacaoFerias" class="custom-select form-control-border border-width-2"
              id="exampleSelectBorderWidth2">

              <option disabled value="">Selecione</option>
              <option value="antigaMatricula">Antiga Matrícula</option>
              <option value="novaMatricula">ID</option>
            </select>
          </div>

          <div v-show="tipoConsultaAnotacaoFerias == 'antigaMatricula'">
            <div class="form-group">
              <label for="antigaMatricula" class="pesquisar-quantidade">Matrícula Antiga</label>
              <input type="text" class="form-control form-control-border border-width-2" id="antigaMatricula"
                v-model="anotacaoFerias.matricula" placeholder="Digite a matrícula" />
            </div>
          </div>

          <div v-show="tipoConsultaAnotacaoFerias == 'novaMatricula'">
            <div class="form-group">
              <label for="novaMatricula" class="pesquisar-quantidade">ID</label>
              <input type="text" class="form-control form-control-border border-width-2" id="novaMatricula"
                placeholder="Digite o ID" v-model="anotacaoFerias.novaMatricula" />
            </div>
          </div>


          <div class="form-group ">
            <label class="form-control-label" for="anotacaoFerias-nome">Nome</label>
            <input type="text" class="form-control form-control-border border-width-2" name="nome"
              id="anotacaoFerias-nome" data-cy="nome" :class="{
              'is-invalid': isSaving && $v.anotacaoFerias.nomeFuncionario.$error
            }" v-model="anotacaoFerias.nomeFuncionario" />
          </div>


          <div class="form-group ">
            <label for="anotacaoFerias-tipo">Lotação</label>
            <v-select class="border-v-select mt-1" id="anotacaoFerias-lotacao" v-model="anotacaoFerias.lotacaoId"
              :reduce="lotacaoId => lotacaoId.id" :options="lotacoes" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>

          <div class="form-group ">
            <label class="form-control-label" for="anotacaoFerias-anoExercicio">Ano Exercício
            </label>
            <input type="text" class="form-control form-control-border border-width-2" name="anoExercicio"
              id="anotacaoFerias-anoExercicio" data-cy="anoExercicio" :class="{
              'is-invalid': isSaving && $v.anotacaoFerias.anoExercicio.$error
            }" v-model="anotacaoFerias.anoExercicio" />
          </div>

          <div class="form-group ">
            <label class="form-control-label" for="anotacaoFerias-anoFerias">Ano Férias
            </label>
            <input type="text" class="form-control form-control-border border-width-2" name="anoFerias"
              id="anotacaoFerias-anoFerias" data-cy="anoFerias" :class="{
              'is-invalid': isSaving && $v.anotacaoFerias.anoFerias.$error
            }" v-model="anotacaoFerias.anoFerias" />
          </div>




          <div class="form-group ">
            <label for="anotacaoFerias-mesFerias">Mês Férias</label>
            <v-select class="border-v-select mt-1" id="anotacaoFerias-mesFerias" v-model="anotacaoFerias.mesFerias"
              :reduce="mesFerias => mesFerias.id" :options="meses" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>



          <div class="form-group ">
            <label class="form-control-label" for="anotacaoFerias-dataInicio">Data Início</label>
            <div class="d-flex">
              <input type="date" data-cy="dataInicio" class="form-control form-control-border border-width-2"
                id="anotacaoFerias-dataInicio" placeholder="dd/mm/aaaa" v-model="anotacaoFerias.dataInicio" :class="{
              'is-invalid': isSaving && $v.anotacaoFerias.dataInicio.$error
            }" />
            </div>
          </div>

          <div class="form-group ">
            <label class="form-control-label" for="anotacaoFerias-dataFim">Data Fim
            </label>
            <div class="d-flex">
              <input type="date" data-cy="dataFim" class="form-control form-control-border border-width-2"
                id="anotacaoFerias-dataFim" placeholder="dd/mm/aaaa" v-model="anotacaoFerias.dataFim" :class="{
              'is-invalid': isSaving && $v.anotacaoFerias.dataFim.$error
            }" />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="form-group">
            <button @click.prevent="consultaAnotacao" @keyup.enter="consultaAnotacao"
              class="btn col-12 background-principal text-color-principal">
              <i class="fas fa-search"></i>
              Buscar Férias
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="card card-primary">
        <div class="background-principal card-header text-color-principal">
          <h3 class="card-title">Anotações Férias</h3>
          <div>
            <div v-if="showPaginacao">
              <div class="float-right" v-if="resultados.length > 0">
                <a v-if="page != 1" href="#" v-on:click.prevent="page > 1 ? page-- : 1"><i
                    class="fas fa-angle-left mx-2 text-color-principal"></i></a>
                Página {{ page }}
                <a v-if="resultados.length == 10" href="#" v-on:click.prevent="page++"><i
                    class="fas fa-angle-right mx-2 text-color-principal"></i></a>
              </div>
              <div class="float-right" v-if="resultados.length == 0">
                <a href="#" v-on:click.prevent="page > 1 ? page-- : 0"><i
                    class="fas fa-angle-left mx-2 text-color-principal"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div v-if="resultados.length == 0 && semResultados" class="mx-auto my-auto sem-resultados py-2">
          <div>Sem resultados!</div>
        </div>
        <div v-if="resultados.length > 0">


          <div class="card-body px-0 d-flex flex-row " v-show="showModal">
            <div class="card-body table-responsive p-0 max-height-content ">

        

              <div class="row px-5 d-flex justify-content-around  ">


                <div @click="cardSelecionado(ferias.id)" :key="id" v-for="(ferias, id) in resultados"
                  :class="['card', 'cardFerias', 'bg-light', 'col-sm-12', 'col-md-5', { 'selected-card': selected.includes(ferias.id) }]">


                  <div class="background-principal check-box">

                    <p class="
                        header
                        card-item-result
                        text-color-principal
                        nome-servidor
                        
                      ">

                      {{ ferias.nomeFuncionario || "Sem Registro" }}

                      <!-- <input class="checkbox clicavel" type="checkbox" :value="ferias.id" v-model="selected"> -->


                    </p>



                  </div>

                  <div>
                    <div class="col-12 mx-0 row my-2 py-0">
                      <div class="col-sm-12">


                        <ul class="ml-4 mb-0 fa-ul text-muted">
                          <li class="medium">
                            <b>Matrícula: </b> {{ ferias.matricula }}
                          </li>
                          <li class="medium">
                            <b>Data Início: </b>
                            {{ updateZonedDateTimeField(ferias.dataInicio) }}
                          </li>
                          <li class="medium">
                            <b>Data Fim: </b>{{ updateZonedDateTimeField(ferias.dataFim) || "Sem Registro" }}
                          </li>
                          <li class="medium">
                            <b>Ano das Férias: </b>{{ ferias.anoFerias || "Sem Registro" }}
                          </li>
                          <li class="medium">
                            <b>Mês de Férias: </b>
                            {{ ferias.mesFerias || "Sem Registro" }}
                          </li>
                          <!-- <li class="medium">
                              <b>Documento </b>
                            {{ ferias.tipo_documento || "Sem Registro"}}
                          </li> -->
                          <!-- <li class="medium">
                              <b>Informacao: </b>
                            {{ ferias.informacao || "Sem Registro"}}
                          </li> -->
                          <!-- <li class="medium">
                            <b>Nº do Documento: </b>{{ ferias.numero_documento || "Sem Registro"}}
                          </li>
                            -->


                        </ul>


                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>


          </div>

          <div class="row px-4 d-flex justify-content-around float-right">
            <div class="float-right">
              <div class="form-group">
                <label class="btn background-principal text-color-principal mb-1 mx-1 " for="btn-check">
                  <input type="checkbox" hidden class="btn-check  mb-1" id="btn-check" v-model="selectAll"
                    @click="select"> Selecionar todos servidores

                </label>

                <label class=" btn background-principal text-color-principal  mb-1 " for="enviar-ferias">
                  <input type="button" hidden class="enviar-ferias  mb-1" id="enviar-ferias" @click="enviarAnotacao()">
                  Anotar Férias

                </label>


                <button class="btn background-principal text-color-principal mb-1 mx-1 dropdown-toggle float-right" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown">
                  <strong>Imprimir</strong>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                  <li><a class="dropdown-item" href="#" @click="imprimirFerias(ferias.id)">Aviso de Férias</a></li>

                </ul>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <PdfAvisoFerias />
    <PdfTranferenciaFerias />
    <PdfOficio />
  </div>
</template>

<script>

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import { EventBus } from "../../../main.js";
import rhService from "@/service/RHService";
import PdfAvisoFerias from "@/components/rh/ferias/PDFAvisoFerias.vue";
import ConsultaListasService from "@/service/ConsultaListasService";
import PdfTranferenciaFerias from "@/components/rh/ferias/PDFTransferenciadeFerias.vue";
import PdfOficio from "@/components/rh/ferias/PDFOFICIO.vue";
import EditarSupervisor from "@/components/rh/ferias/EditarSupervisor.vue";
import RHAnotacaoService from "@/service/RHAnotacaoService";

export default {
  components: { PdfAvisoFerias, PdfTranferenciaFerias, PdfOficio, EditarSupervisor },
  data() {

    return {
      anotacaoFerias: {

        matricula: "",
        novaMatricula: "",
      },
      tipoConsultaAnotacaoFerias: "antigaMatricula",

      submitted: false,
      isSaving: false,
      meses: [
        { id: 1, descricao: "Janeiro" },
        { id: 2, descricao: "Fevereiro" },
        { id: 3, descricao: "Março" },
        { id: 4, descricao: "Abril" },
        { id: 5, descricao: "Maio" },
        { id: 6, descricao: "Junho" },
        { id: 7, descricao: "Julho" },
        { id: 8, descricao: "Agosto" },
        { id: 9, descricao: "Setembro" },
        { id: 10, descricao: "Outubro" },
        { id: 11, descricao: "Novembro" },
        { id: 12, descricao: "Dezembro" },
      ],
      //mesesAlterados:[],
      lotacoes: [],
      page: 1,
      resultados: [],
      loaderActive: false,
      fullPage: false,
      semResultados: false,
      showModal: false,
      selected: [],
      selectAll: false,
      ferias: {},
      id: {},
      anotacoes: {},
      ft_id: {},
      showPaginacao: false,

    }

  },

  validations: {

    anotacaoFerias: {

      matricula: {},
      nomeFuncionario: {},
      lotacaoId: {},
      anoExercicio: {},
      anoFerias: {},
      mesFerias: {},
      dataInicio: {},
      dataFim: {},
      //dataInicioAlteracao:{},
      //dataFimAlteracao:{},

      novaMatricula: {},




    },
  },



  created() {
    this.carregarListas();

    //this.consultaAnotacao()
  },

  watch: {
    page(valorAtual, valorAnterior) {
      this.consultaAnotacao();
    },

    //    selected(newValue, oldValue) {
    //   // Handle changes in individual flavour checkboxes
    //   if (newValue.length === 0) {
    //     this.indeterminate = false
    //     this.allSelected = false

    //   } else if (newValue.length === this.resultados.length) {
    //     this.indeterminate = false
    //     this.allSelected = true

    //   } else {
    //     this.indeterminate = true
    //     this.allSelected = false

    //   }
    // }
  },


  methods: {

    select() {
      this.selected = []

      if (!this.selectAll) {
        for (let i in this.resultados) {
          this.selected.push(this.resultados[i].id);
        }
      }
      // console.log(this.resultados)

    },

    cardSelecionado(id) {
      const index = this.selected.indexOf(id);

      if (index > -1) {
        this.selected.splice(index, 1);
      }
      else {
        this.selected.push(id);
        console.log("Selecionado: " + id);
      }


    },



    enviarAnotacao() {

      if (this.selected) {
        for (let i in this.selected) {

          //console.log(this.selected[i])
          //console.log("sdsg", this.selected[i])
          rhService.enviarAnotacaoFerias(this.selected[i], this.$store.state.funcionario.idFuncionarioCadastrado).then((resp) => {
            this.selected = resp.data
            Toast.fire("Sucesso anotar férias", "", "success");
            //console.log(this.selected)
          })
            .catch(() => {
              Toast.fire("Falha ao anotar férias", "", "error");
            });
        }
      }

    },
    //pdf
    async imprimirFerias() {
      if (this.selected && this.selected.length > 0) {
        const dadosFerias = [];
        const idFerias = [];
        let supervisorInvalido = true;

        for (let id of this.selected) {
          const resp = await rhService.getFeriasPDF(id);
          dadosFerias.push(resp.data);

          if (resp.data && (resp.data.id_surpevisor !== null && resp.data.id_surpevisor !== '')) {
            supervisorInvalido = false;
          } else {
            supervisorInvalido = true;
            idFerias.push(id);
          }
        }

        if (supervisorInvalido) {
          Toast.fire("Cadastrar Supervisor do(s) funcionário(s) selecionado(s)", "", "error");
          this.$root.$emit('bv::show::modal', 'modal-50');
          console.log("IDs dos funcionários sem supervisor: ", idFerias);
          EventBus.$emit("EnvioFeriasId", idFerias);
        } else {
          this.$root.$emit("pdfAvisodeFerias", dadosFerias, this.formDados);
        }
      }
    },







    // async oficio() {
    //   if (this.selected && this.selected.length > 0) {
    //     const dadosOficio = [];

    //     for (let id of this.selected) {

    //       try {

    //         const numeroMes = await rhService.getFeriasPDF(id);
    //         const mes = numeroMes.data.mes;
    //         console.log(mes);

    //         const resp = await rhService.getOficioPDF(id, mes);
    //         dadosOficio.push(resp.data);
    //         console.log(resp.data);


    //       }
    //       catch {
    //         console.error("Erro ao buscar transferência de férias:", erro);
    //       }

    //     }

    //     this.$root.$emit("pdfOficio", dadosOficio, this.formDados);
    //   }
    // },

    //pdf
    async imprimirTransferenciaFerias() {
      if (this.selected && this.selected.length > 0) {
        const transferencia = [];

        for (let id of this.selected) {

          try {

            const idTransferencia = await rhService.getFeriasPDF(id);
            const ft_id = idTransferencia.data.ft_id;
            //console.log(ft_id);

            const resp = await rhService.buscarTransferenciaPDF(id, ft_id);
            transferencia.push(resp.data);

            //console.log(this.transferencia);

          }
          catch {
            console.error("Erro ao buscar transferência de férias:", erro);

          }

        }

        this.$root.$emit("pdfTransferenciadeFerias", transferencia, this.formDados);
      }

    },

    updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
    },

    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;

    },
    listener(event) {
      this.params = event;
      this.page = event.page;

      this.consultaAnotacao();
    },

    onChangePage(resultados) {
      this.resultados = resultados;
    },

    carregarListas() {
      ConsultaListasService.buscarListaPorNomeTabela("unidade")
        .then((response) => {
          this.lotacoes = response.data;
          // })
          // .catch((e) => this.alertaFalhaBuscarLotacao());

        }).catch(() => {
          Toast.fire("Falha ao buscar Lotação", "", "error");
        });

    },



    consultaAnotacao() {
      this.showPaginacao = true;
      this.page;

      // const tipoAnotacaoList =  this.consulta.tipoAnotacaoList || [];
      //const nomeFuncionario = this.anotacaoFerias.nomeFuncionario || [];
      //const matricula = this.anotacaoFerias.matricula || [];
      //const identidade = this.consulta.identidade || [];
      //const mesFerias = this.consulta.mesFerias || [];

      const params = {
        tipoConsultaAnotacaoFerias: this.tipoConsultaAnotacaoFerias,
        matricula: this.anotacaoFerias.matricula ? this.anotacaoFerias.matricula : null,
        novaMatricula: this.anotacaoFerias.novaMatricula ? this.anotacaoFerias.novaMatricula : null,
        nomeFuncionario: this.anotacaoFerias.nomeFuncionario ? this.anotacaoFerias.nomeFuncionario : null,
        anoExercicio: this.anotacaoFerias.anoExercicio ? this.anotacaoFerias.anoExercicio : null,
        anoFerias: this.anotacaoFerias.anoFerias ? this.anotacaoFerias.anoFerias : null,
        mesFerias: this.anotacaoFerias.mesFerias ? this.anotacaoFerias.mesFerias : null,
        dataInicio: this.anotacaoFerias.dataInicio ? this.anotacaoFerias.dataInicio : null,
        dataFim: this.anotacaoFerias.dataFim ? this.anotacaoFerias.dataFim : null,
        lotacaoId: this.anotacaoFerias.lotacaoId ? this.anotacaoFerias.lotacaoId : null,
        //dataInicioAlteracao: this.anotacaoFerias.dataInicioAlteracao ? this.anotacaoFerias
        //dataFimAlteracao:this.anotacaoFerias.dataFimAlteracao ? this.anotacaoFerias
      }



      rhService.filtroFerias(params, this.page).then((resp) => {
        this.resultados = resp.data;
        //console.log(resp.data);

      });
    },
  }
}


</script>

<style scoped>
.selected-card {
  border: 3px solid rgb(21, 190, 21);
}

li {
  list-style-type: none;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.img-cut {
  max-width: 100px;
  max-height: 100px;

  height: 10vh;
  width: 10vh;
  object-fit: cover;
  object-position: center top;
}

.cardFerias {
  border-radius: 12px;
  padding: 0;
  cursor: pointer;
}

.checkbox {

  position: absolute;
  top: 5px;
  right: 5px;


}

.botao-center {

  text-align: center;

}

.fixed-botons {

  position: absolute;
  /* left: 50%; 
  transform: translateX(-50%);
  z-index: 1000; 
  background-color: white; 
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  */

}

.card-container {
  margin-top: 50px;
  position: relative;
}

.invisible-line {
  border: none;
  border-top: 1px solid transparent;
  margin-top: 20px;
}

.fixed-container {
  position: relative;
  z-index: 1;
}







/* .btn input:checked{
  background-color: #10e510 !important;
  color: #10e510 !important;
} */

/* .fa-circle-check {
    color: #34c92f;  
    position: absolute;
    top: 5px;
    right: 5px;
    
} */



.check-box {

  border-radius: 10px 10px 0 0;
}

.nome-servidor {
  margin-right: 15px;
}
</style>
