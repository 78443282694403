<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-nomeDestino"
            >Destinatário <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control-border"
            name="nomeDestino"
            id="documento-nomeDestino"
            data-cy="nomeDestino"
            required
            v-model="documento.nomeDestino"
          />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-controleProcesso"
            >e-Processos</label
          >
          <input
            type="text"
            class="form-control form-control-border"
            name="controleProcesso"
            id="documento-controleProcesso"
            data-cy="controleProcesso"
            v-model="documento.controleProcesso"
          />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-assunto"
            >Signatário <span class="text-danger">*</span></label
          >
          <select
            class="form-control form-control-border"
            id="documento-fkUsuario"
            data-cy="fkUsuario"
            name="fkUsuario"
            v-model="documento.fkUsuario"
          >
            <option>option 1</option>
            <option>option 2</option>
            <option>option 3</option>
            <option>option 4</option>
            <option>option 5</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="documento-assunto"
            >Assunto <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control-border"
            name="assunto"
            id="documento-assunto"
            data-cy="assunto"
            v-model="documento.assunto"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Documentos",
  data() {
    return {
      documento: {},
      submitted: false,
      isSaving: false,
    };
  },
};
</script>