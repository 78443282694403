// import { ApiGateway } from "@/main";
// import authHeader from "@/service/auth/auth-header";
import store from "@/store";
// import axios from "axios";
import instance from "./axiosConfig";

class CadastroUsuarioService {
  constructor() {
    this.usuarioLogado =
      store.state.auth.user == null ? null : store.state.auth.user.usuario;
  }

  // inserirCadastrante(funcionario) {
  //   funcionario.funcionario = { id: this.usuarioLogado.idFuncionario };
  // }

  cadastroUsuario(usuario) {
    // this.inserirCadastrante(funcionario);
    return instance.post("/usuarios-service/api/usuarios", usuario);
  };
  atualizarUsuario(usuario){
    return instance.patch(`/usuarios-service/api/usuarios/${usuario.id}`, usuario)
  }
  // atualizarFuncionario(funcionario) {
  //   return instance.patch("/funcionario-service/api/funcionarios/" + funcionario.id, funcionario);
  // }
  // buscarFuncionarioPorNome = async (nome) => {
  //   let response = await instance.get(
  //     `/funcionario-service/api/funcionarios/busca-nome?nome=${nome}`
  //   );

  //   return response.data;
  // };
  // buscarFuncionarioPorId(id) {
  //   return instance.get(`/funcionario-service/api/funcionarios/${id}`);
  // };
  // cadastroFuncionarioPerfil(idFuncionario, perfis) {
  //   return instance.post(
  //     `/funcionario-service/api/funcionario_perfil?idFuncionario=${idFuncionario}`,
  //     perfis
  //   );
  // }
}
export default new CadastroUsuarioService();
