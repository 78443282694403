<template>


    <div>
        
        <b-modal
        id="modal-3"
        ref="modal-3"
        title="Cadastro de Férias Gozadas"
        data-backdrop="static"
        size="xl"
        cancel-disabled
        no-close-on-backdrop
        hide-header-close
        header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            "
        content-class="modal-content border-rounded-detail"
        dialog-class="p-3"
        title-class="modal-title mx-auto my-0">

        <div class="card-body">
            
            <div class="row">

                <div class="form-group col-lg-3 mx-auto">
                    <label class="form-control-label" for="feriasGozadas-dataInicio"
                    >Data Início</label>
                        <div class="d-flex">
                            <input
                                type="date" data-cy="dataInicio" class="form-control form-control-border border-width-2" id="feriasGozadas-dataInicio"
                                placeholder="dd/mm/aaaa"  v-model="ferias.dataInicio"
                                :class="{
                                'is-invalid': isSaving && $v.ferias.dataInicio.$error}"
                            />
                        </div>
                </div>
      
                <div class="form-group col-lg-3 mx-auto">
                    <label class="form-control-label" for="feriasGozadas-dataFim"
                    >Data Fim
                    </label >
                    <div class="d-flex">
                        <input
                            type="date" data-cy="dataFim" class="form-control form-control-border border-width-2" id="feriasGozadas-dataFim"
                            placeholder="dd/mm/aaaa" v-model="ferias.dataFim" :class="{
                            'is-invalid': isSaving && $v.ferias.dataFim.$error}"
                            
                        />
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group mx-auto">
                        <label for="feriasGozadas-observacao">Observação</label>
                        <input type="text" class="form-control form-control-border border-width-2" id="feriasGozadas-observacao"
                        v-model="ferias.observacao" />
                    </div>
                </div>
                            
            </div>

            
        </div>

        <div class="card-footer">
            <div class="float-right">
                <button @click="SalvarFerias" class="btn text-color-principal background-principal ml-1">
                    <i class="far fa-envelope"></i> Enviar
                </button>

                <button @click.prevent="LimparFerias" type="reset" class="btn btn-default">
                    <i class="fas fa-ban"></i> Limpar
                </button>

                <!-- <button @click.prevent="ImprimirFerias" type="print" class="btn btn-default">
                    <i class="fas fa-times"></i> Imprimir
                </button> -->

                <b-button
                            class="btn btn-default float-right"
                @click="[cancel(), apagarCamposFerias()]"
                >
                <i class="fa-solid fa-x"></i>Fechar
                </b-button>
            </div>

        </div>

            <template #modal-footer="{ cancel }">
                <b-button
                            class="btn btn-default float-right"
                @click="[cancel(), apagarCamposFerias()]"
                >
                <i class="fa-solid fa-x"></i>Fechar
                </b-button>
            </template>
        </b-modal>
    </div>
    
</template>

<script>

import {DATE_FORMAT_LOCAL_DATA, } from "@/util/filter";
import dayjs from "dayjs";
import FeriasGozadas from "@/components/rh/ferias/FeriasGozadas.vue";

import "vue-loading-overlay/dist/vue-loading.css";

export default {

  compoments:{
    FeriasGozadas,
    },
 
    data(){

        return{
            data:{},
            ferias:{},
            submitted: false,
            isSaving: false,
            isVisible: this.visible,
        }
        
    },

     validations:{

        ferias:{
            dataInicio:{},
            dataFim:{},
            observacao:{},
        
            
        },
    },

    

    methods:{
    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

     updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.feria[field] = dayjs(
        event.target.value,
          DATE_FORMAT_LOCAL_DATA
       );
     } else {
        this.feria[field] = null;
      }
    },

    LimparFerias(){
        this.ferias = {};
    },

    apagarCamposFerias(){
        this.ferias = {};
    },

    SalvarFerias(){
        this.ferias = {};
    },

    ImprimirFerias(){

    },
    showModal() {
      this.isModalVisible = true;
    },

   
  },
   
}
</script>