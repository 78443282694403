<template>
  <div>
    <div class="p-3">
      <label for="unidadeSubordinada">Cadastrar Unidade Subordinada</label>
      <div class="row" v-show="desabilitarCamposFormUnidade">
        <div class="form-group col-lg-6">
          <label for="descricao">Nome da Unidade:</label>
          <input text="text" class="form-control form-control-border border-width-2" id="descricao"
            v-model="unidade.descricao" :class="{ 'is-invalid': $v.unidade.descricao.$error, }" />
          <div v-if="!$v.unidade.descricao.required" class="invalid-feedback">Este campo é obrigatório</div>
        </div>
        <div class="form-group col-lg-6">
          <label for="sigla">Sigla da Unidade:</label>
          <input text="text" class="form-control form-control-border border-width-2" id="sigla" v-model="unidade.sigla"
            :class="{ 'is-invalid': $v.unidade.sigla.$error, }" />
          <div v-if="!$v.unidade.sigla.required" class="invalid-feedback">Este campo é obrigatório</div>
        </div>


        <div class="form-group col-lg-6">
          <label class="col-sm-12">Tipo de unidade: </label>
          <v-select class="border-v-select" id="unidadeDeOrigem" v-model="tipoUnidade"
            :options="tiposUnidade" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>

        <div class="form-group col-lg-6">
          <label class="col-sm-12">Força: </label>
          <v-select class="border-v-select" id="unidadeDeOrigem" v-model="forca"
            :options="forcas" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>

        <div class="form-group col-lg-3">
          <label for="sigla">Complemento da Unidade:</label>
          <input text="text" class="form-control form-control-border border-width-2" id="sigla" v-model="unidade.complemento" />
        </div>
        
        <div class="form-group row col-lg-3">
          <label class="col-sm-8">Unidade Policial: </label>
          <div class="col-sm-4">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="unidadePolicial" id="inlineRadio1" value="true"
                v-model="unidade.unidadePolicial" />
              <label class="form-check-label" for="inlineRadio1">Sim</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="unidade.unidadePolicial" name="unidadePolicial"
                id="inlineRadio2" value="false" checked>
              <label class="form-check-label" for="inlineRadio2">Não</label>
            </div>
          </div>
        </div>
        <div class="form-group row col-lg-3">
          <label class="col-sm-8">Unidade Externa: </label>
          <div class="col-sm-4">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="unidadeExterna" id="inlineRadio1" value="true"
                v-model="unidade.unidadeExterna" checked />
              <label class="form-check-label" for="inlineRadio1">Sim</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="unidade.unidadeExterna" name="unidadeExterna"
                id="inlineRadio2" value="false">
              <label class="form-check-label" for="inlineRadio2">Não</label>
            </div>
          </div>

        </div>
      </div>
      <div v-if="sucessoCadastroUnidadeCompleta">
        <h4>Cadastro de nova unidade completo!</h4>
      </div>
      <button @click.prevent="$emit('fecharCadastrarUnidadeSubordinada')" v-show="desabilitarBotaoFechar" type="button"
        class="btn background-principal text-color-principal">
        <i class="fas fa-arrow-left"></i>
        Voltar
      </button>
      <button @click.prevent="cadastrarUnidade" v-show="desabilitarCamposFormUnidade"
        class="btn btn-primary float-right">
        <i class="fa-solid fa-floppy-disk"></i>
        Salvar
      </button>
      <CadastrarDadosUnidadeSubordinada v-show="!desabilitarCamposFormUnidade"
        @voltarParaUnidadeSuperiorDetalhe="cadastroUnidadeCompleto"
        @voltarParaUnidadeSuperiorDetalheIncompleto="voltarUnidadeSuperior" />
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/main';
import ConsultaListasService from '@/service/ConsultaListasService';
import UnidadeService from '@/service/UnidadeService';
import { required } from "vuelidate/lib/validators";
import CadastrarDadosUnidadeSubordinada from './CadastrarDadosUnidadeSubordinada.vue';

export default {
  name: "CadastrarUnidadeSubordinada",
  components: { CadastrarDadosUnidadeSubordinada },
  data() {
    return {
      unidade: {},
      idUnidadeSuperior: null,
      tiposUnidade: [],
      tipoUnidade: null,
      forcas: [],
      forca: null,
      desabilitarCamposFormUnidade: true,
      desabilitarBotaoFechar: true,
      sucessoCadastroUnidadeCompleta: false,
    };
  },
  created() {
    EventBus.$on('resetarFormularioUnidade', (event) => {
      this.unidade = {};
      this.idUnidadeSuperior = null;
      this.tipoUnidade = null;
      this.forca = null,
        this.desabilitarCamposFormUnidade = true;
      this.desabilitarBotaoFechar = true;
      this.sucessoCadastroUnidadeCompleta = false;
    });
    EventBus.$on("consultaUnidadeDetalhe", (event) => {
      this.idUnidadeSuperior = event;
    });
    ConsultaListasService.buscarListaPorNomeTabela("tipo_unidade")
      .then((response) => {
        this.tiposUnidade = response.data;
      }).catch((e) => alert("ERRO: " + e.message));
    ConsultaListasService.buscarListaPorNomeTabela("unidade")
      .then((response) => {
        this.forcas = response.data;
      }).catch((e) => alert("ERRO: " + e.message));

    EventBus.$on('resetarFormularioUnidade', (event) => { this.resetarForm() })
  },
  methods: {
    resetarForm() {
      this.unidade = {};
      this.forca = null;
      this.tipoUnidade = null
    },
    cadastroUnidadeCompleto() {
      this.desabilitarBotaoFechar = true
      this.sucessoCadastroUnidadeCompleta = true
    },
    voltarUnidadeSuperior() {
      this.$emit('fecharCadastrarUnidadeSubordinada');
      this.unidade = {};
      this.idUnidadeSuperior = null;
      this.tipoUnidade = null;
      this.forca = null,
        this.desabilitarCamposFormUnidade = true;
      this.desabilitarBotaoFechar = true;
      this.sucessoCadastroUnidadeCompleta = false;

    },
    alertaSucessoCadastro() {
      Toast.fire("Sucesso ao cadastrar unidade", "", "success");
    },
    alertaFalhaCadastro() {
      Toast.fire("Falha ao cadastrar unidade", "", "error");
    },
    cadastrarUnidade() {
      this.$v.unidade.$touch();
      if (!this.$v.unidade.$invalid) {
        const data = {
          descricao: this.unidade.descricao,
          sigla: this.unidade.sigla.toUpperCase(),
          complemento: this.unidade.complemento,
          unidadePolicial: (this.unidade.unidadePolicial === "true" ? true : false),
          unidadeExterna: (this.unidade.unidadeExterna === "true" ? true : false),
          tipoUnidade: this.tipoUnidade ? this.tipoUnidade : null,
          unidadeSuperior: { id: this.idUnidadeSuperior },
          forca: this.forca ? this.forca : null,
        };
        UnidadeService.cadastrarUnidade(data).then((response) => {
          this.alertaSucessoCadastro();
          this.desabilitarCamposFormUnidade = false;
          this.desabilitarBotaoFechar = false;
          EventBus.$emit('ResultadoIdUnidadeCriada', response.data.id);
          this.unidade = {};
          this.forca = null;
          this.tipoUnidade = null
        }).catch((e) => {
          this.alertaFalhaCadastro;
        });
      }


    }
  },
  validations: {
    unidade: {
      descricao: {
        required,
      },
      sigla: {
        required,
      }
    },
  }
}
</script>
