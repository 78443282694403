<template>
    <div >
        <div class="row px-3">
            <div class="form-group col-4">
                <label for="sigla">Sigla</label>
                <input
                type="text"
                class="form-control form-control-border"
                id="sigla"
                v-model="grupo.sigla"
                :class="{
                'is-invalid':isSaving && $v.grupo.sigla.$error}"
                />
            </div>
            <div class="form-group col-lg-4">
                <label for="valor1">Valor no Estado</label>
                <input
                type="number"
                class="form-control form-control-border"
                id="valor1"
                v-model="grupo.valor1"
                :class="{
                'is-invalid': isSaving && $v.grupo.valor1.$error}"
                />
            </div>
            <div class="form-group col-lg-4">
                <label for="valor2">Valor para fora do Estado</label>
                <input
                type="number"
                class="form-control form-control-border"
                id="valor2"
                v-model="grupo.valor2"
                :class="{
                'is-invalid': isSaving && $v.grupo.valor2.$error}"
                />
            </div>
        </div>
        <div class="row px-3">
            <div class="form-group col-lg">
                <label for="descricao">Descrição</label>
                <input
                type="text"
                class="form-control form-control-border"
                id="sigla"
                v-model="grupo.descricao"
                :class="{
                'is-invalid': isSaving && $v.grupo.descricao.$error}"
                />
            </div>
            
        </div>
        <label class="px-3">DECRETO nº  28.862</label>
        <div class="row px-3">
            <div class="form-group col-lg-4">
                <label for="estado">Valor no Estado</label>
                <input
                type="number"
                class="form-control form-control-border"
                id="estado"
                v-model="grupo.valorEstado"
                :class="{
                'is-invalid': isSaving && $v.grupo.valorEstado.$error}"
                />
            </div>
            <div class="form-group col-lg-4">
                <label for="fora_estado">Valor para fora do Estado</label>
                <input
                type="number"
                class="form-control form-control-border"
                id="fora_estado"
                v-model="grupo.valorForaEstado"
                :class="{
                'is-invalid': isSaving && $v.grupo.valorForaEstado.$error}"
                />
            </div>
            <div class="form-group col-lg-4">
                <label for="fora_pais">Valor para fora do País</label>
                <input
                type="number"
                class="form-control form-control-border"
                id="fora_pais"
                v-model="grupo.valorForaPais"
                :class="{
                'is-invalid': isSaving && $v.grupo.valorForaPais.$error}"
                />
            </div>
        </div>
        <div class="card-body" style="
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
">
            <button @click="salvarGrupo" class="btn float-right text-color-principal background-principal ml-1">
                <i class="fas fa-save"></i> Salvar
            </button>
            <!-- <button @click="ExcluirGrupo" class="btn text-color-principal background-principal ml-1">
                <i class="fas fa-trash"></i> Excluir
            </button> -->
            <button @click.prevent="LimparGrupo" type="reset" class="btn btn-default">
                <i class="fas fa-ban"></i> Limpar
            </button>
        </div>
        <div class=" card-body pt-5">
            <table  class="table table-hover table-responsive-sm table-striped" >
                <thead>
                    <tr>
                        <th >Código</th>
                        <th >Sigla</th>
                        <th class="col-6 text-center" >Descrição</th>
                        <th >Valor 1</th>
                        <th >Valor 2</th>
                        <th >Estado</th>
                        <th >Fora do estado</th>
                        <th >Fora do país</th>
                    </tr>
                </thead>
                <tbody>
                    <tr @click="escolherGrupo(grupodiaria.id)" v-for="(grupodiaria, i) in grupos" :key="i" class="text-center clicavel">
                        <td>{{grupodiaria.id}}</td>
                        <td>{{grupodiaria.sigla}}</td>
                        <td>{{grupodiaria.descricao}}</td>                        
                        <td>R$ {{grupodiaria.valor1}}</td>
                        <td>R$ {{grupodiaria.valor2}}</td>
                        <td>R$ {{grupodiaria.valorEstado}}</td>
                        <td>R$ {{grupodiaria.valorForaEstado}}</td>
                        <td>U$ {{grupodiaria.valorForaPais}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import RhDiariasService from '@/service/RhDiariasService';

export default {
    data(){
        return{
            grupo:{
                sigla: null,
                descricao: null,
                valor1: null,
                valor2: null,
                valorEstado: null,
                valorForaEstado: null,
                valorForaPais: null,
            },
            grupos:[],
            isSaving: false,
        }
    },
    watch:{
        
    },
/*     validations:{
        grupo:{
            codigo:{},
            sigla:{},
            descricao:{},
            estado:{},
            fora_estado:{}
        }
    }, */
    created(){
        this.listarGrupos();
    },
    methods: {
        listarGrupos(){
            RhDiariasService.getAllDiariaGrupo()
            .then((response)=>this.grupos=response.data)
        },

        salvarGrupo(){
            if ('id' in this.grupo){
                RhDiariasService.editarDiariaGrupo(this.grupo.id,this.grupo).then((r)=>{
                    RhDiariasService.getDiariaGrupo(this.grupo.id);
                    Toast.fire("Sucesso ao editar grupo", "", "success");
                }).catch(()=>{
                    Toast.fire("Falha ao editar grupo", "", "error");
                })
            }
            else{
                RhDiariasService.cadastrarDiariaGrupo(this.grupo)
                .then((response)=>{
                    this.grupo = response.data;
                    Toast.fire("Cadastro realizado", "", "success");
                    this.grupo={}
                    this.listarGrupos()
                })
            }
            this.listarGrupos()
        },
        ExcluirGrupo(){},
        LimparGrupo(){
            this.grupo={}
        },

        escolherGrupo(id){
            RhDiariasService.getDiariaGrupo(id)
            .then((response)=>this.grupo=response.data)

        }
    }
}
</script>
<style >

</style>