<template>
  <div class="card-body pt-0">
    <loading :active="loaderActive" :is-full-page="fullPage" />
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="prevPage">Anterior</a>
        </li>
        <li class="page-item" v-for="pageNumber in pages" :key="pageNumber"
          :class="{ active: pageNumber === currentPage }">
          <a class="page-link" href="#" @click.prevent="setPage(pageNumber)">{{ pageNumber }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="nextPage">Próxima</a>
        </li>
      </ul>
    </nav>
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th class="text-center">Servidor</th>
          <th class="text-center">Matricula/ID</th>
          <th class="text-center">Ano Exercício</th>
          <th class="text-center">Ano</th>
          <th class="text-center">Mês</th>
          <th class="text-center">Periodo inicio</th>
          <th class="text-center">Periodo fim</th>
          <th class="text-center">Ação</th>

          <!-- <th>Ciencia</th> -->
        </tr>
      </thead>
      <tbody v-if="ferias">
        <tr class="linhaTabela" v-for="(ferias, index) in paginatedFerias" :key="index">

          <td class="mailbox-subject text-center">
            {{ ferias.nome }}
          </td>
          <td class="mailbox-subject text-center">
            {{ ferias.matricula }}
          </td>

          <td class="mailbox-subject text-center">
            {{ ferias.anoExercicio }}
          </td>

          <td class="mailbox-subject text-center">
            {{ ferias.ano }}
          </td>

          <td class="mailbox-subject text-center">
            {{ ferias.mes }}
          </td>

          <td class="mailbox-subject text-center">
            {{ updateZonedDateTimeField(ferias.dataInicioAlteracao) }}
          </td>

          <td class="mailbox-subject text-center">
            {{ updateZonedDateTimeField(ferias.dataFimAlteracao) }}
          </td>

          <td class="mailbox-subject text-center">
            <a type="button" class="fas fa-file-signature pr-1 acaoVisualizacao" title="Visualizar ferias" rel="tooltip"
              data-placement="top" @click="visualizarFerias(ferias.id)"></a>
            <a type="button" class="fas fa-pen-alt pr-1 acaoVisualizacao" title="Assinar ferias" rel="tooltip" 
              data-placement="top" @click="assinarFerias(ferias.id)"></a>
          </td>

        </tr>

      </tbody>
      <tbody v-else>
        <div class="mx-auto my-auto sem-resultados py-2">
          <div>Sem resultados para sua busca!</div>
        </div>
      </tbody>
    </table>
  </div>
</template>
<script>
import { EventBus } from "@/main.js";
import { DATE_FORMAT_LOCAL, DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import paginacao from "@/util/paginacao";
import dayjs from "dayjs";
import rhService from '@/service/RHService';
import Loading from "vue-loading-overlay";

export default {
  name: "Documentos",
  components: {
      Loading
  },
  data() {
    return {
      documentos: [],
      resultados: false,
      page: [],
      documentoPesquisa: [],
      loaderActive: false,
      fullPage: false,
      ferias: [],
      currentPage: 1,
      itemsPerPage: 10

    };
  },
  mounted() {
    this.buscarFeriasPendentesAssinatura();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.ferias.length / this.itemsPerPage);
    },
    pages() {
      return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    },
    paginatedFerias() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.ferias.slice(startIndex, endIndex);
    }
  },
  methods: {
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },

    updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
    },

    buscarFeriasPendentesAssinatura() {
      rhService.getAllFeriasPendentesAssinatura().then((resp) => {
        this.ferias = resp.data;
      });
    },
    visualizarFerias(id) {
      this.showLoader();
      rhService
        .imprimirFerias(id)
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          this.hideLoader();
          window.open(url);
        })
        .catch(() => {
          this.hideLoader();
          console.error("Erro ao gerar PDF:", error);
        });
    },
    assinarFerias(id) {
      this.$swal
        .fire({
          title: "Assinar ferias",
          input: "password",
          inputPlaceholder: "Digite sua senha",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Assinar",
          confirmButtonColor: "#0f192c",
          cancelButtonText: "Cancelar",
          showLoaderOnConfirm: true,
          preConfirm: (senha) => {
            return rhService
              .assinarFerias(senha, id)
              .then((response) => {
                if (!response.data) {
                  throw new Error("Senha Inválida");
                }
              })
              .catch((error) => {
                this.$swal.showValidationMessage(error);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.ferias = this.ferias.filter((item) => item.id !== id);
            this.$swal.fire({
              title: `Ferias assinada com sucesso`,
              icon: "success",
            });
          }
        });
    },
    setPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>
<style scoped>
.linhaTabela {
  cursor: pointer;
}
</style>
