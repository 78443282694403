<template>
  <section class="content">
    <content-header title="Consulta SIGMA" />
    <conteudo v-role="'CONSULTA_CELULAR'">
      <div class="container-fluid p-3" v-on="isModalVisible ? { click: closeModal } : {}">
        <DetalhesCelularConsulta v-show="isModalVisible" @closeModalCelular="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisible"></div>
        <div class="row">
          <div class="col-md-3 pr-1">
            <FormCelularConsulta />
          </div>

          <div class="col-md-9 pl-1">
            <ResultadosCelularConsulta @showModalCelular="showModal" />
          </div>
        </div>

      </div>

    </conteudo>

  </section>

</template>

<script>
import DetalhesCelularConsulta from '@/components/consultas/celular/DetalhesCelularConsulta.vue'
import FormCelularConsulta from '@/components/consultas/celular/FormCelularConsulta.vue'
import ResultadosCelularConsulta from '@/components/consultas/celular/ResultadosCelularConsulta.vue'
import { EventBus } from '../../../main.js'
export default {
  name: 'ViewConsultaCelular',
  components: { FormCelularConsulta, ResultadosCelularConsulta, DetalhesCelularConsulta },
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      EventBus.$emit('esconderBoCardComponent')
    }
  }
}
</script>
