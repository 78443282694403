<template>
    <div class="pl-4 pr-4 pb-4">
        <h6 class="mt-4 mb-3"><b>DADOS FUNCIONÁRIO: </b></h6>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <p class="medium mb-0">
                    <b>Matrícula: </b> {{ funcionario.matricula || 'Sem nome registrado' }}
                </p>
                
            </div>
            <div class="col-sm-12 col-md-3">
                
                <p class="medium mb-0">
                    <b>Unidade: </b> {{ funcionario.unidade|| 'Sem nome registrado' }}
                </p>
                
            </div>
            <div class="col-sm-12 col-md-3">
                
                <p class="medium mb-0">
                    <b>Tipo Contratação: </b> {{ funcionario.tipoContratacao || 'Sem nome registrado' }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from 'vuex';
export default {
  name: "DadosUsuarioFuncionario",
  components: { Loading },
  data() {
    return {
      loaderActive: false,
      fullPage: false,
      funcionario: this.$store.state.funcionarioUsuario,
    };
  },
  created() {
    // this.carregarListas();
  },
  computed: {
    ...mapState(['funcionarioUsuario']),

    funcionarioUsuario() {

      // this.funcionario = this.funcionarioUsuario;

      return this.funcionarioUsuario;
    },
  },
  methods: {
    carregarListas() {

      ConsultaListasService.buscarListaPorNomeTabela("unidade")
        .then((response) => {
          this.unidadesDeOrigem = response.data;
        })
        .catch((e) => this.alertaFalhaBuscarUnidadesDeOrigem());

    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
