<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card card-primary">
        <div class="card-header background-principal">
          <h3 class="card-title">Buscar Diária</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="registrodiaria">RD</label>
                <input type="text" class="form-control form-control-border border-width-2" id="registrodiaria"
                  v-model="diaria.rd" :class="{
                    'is-invalid': isSaving && $v.diaria.rd.$error,
                  }" />
              </div>
            </div>
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="matriculadiaria">Matrícula</label>
                <input type="text" class="form-control form-control-border border-width-2" id="matriculadiaria"
                  v-model="diaria.matricula" :class="{
                    'is-invalid': isSaving && $v.diaria.matricula.$error,
                  }" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex flex-column">
              <div class="form-group">
                <label for="diariaservidor">Nome</label>
                <input type="text" class="form-control form-control-border border-width-2" id="diariaservidor"
                  v-model="diaria.servidor" :class="{
                    'is-invalid': isSaving && $v.diaria.servidor.$error,
                  }" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="data">Data</label>
                <input type="date" class="form-control form-control-border border-width-2" id="data"
                  placeholder="dd/mm/aaaa" v-model="diaria.data" />
              </div>
            </div>
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="processo">Processo</label>
                <input type="text" class="form-control form-control-border border-width-2" id="processo"
                  v-model="diaria.processo" />
              </div>
            </div>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="meiadiaria" value="" v-model="diaria.meiaDiaria" />
            <label for="meiadiaria">Meia Diária</label>
          </div>
          <div class="row">
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="data1">Data Início</label>
                <input type="date" class="form-control form-control-border border-width-2" id="data1"
                  placeholder="dd/mm/aaaa" v-model="diaria.data1" />
              </div>
            </div>
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="data2">Data Fim</label>
                <input type="date" class="form-control form-control-border border-width-2" id="data2"
                  placeholder="dd/mm/aaaa" v-model="diaria.data2" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="dataInicioViagem">Data Início Viagem</label>
                <input type="date" class="form-control form-control-border border-width-2" id="dataInicioViagem"
                  placeholder="dd/mm/aaaa" v-model="diaria.dataInicioViagem" />
              </div>
            </div>
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="dataFimoViagem">Data Fim Viagem</label>
                <input type="date" class="form-control form-control-border border-width-2" id="dataFimViagem"
                  placeholder="dd/mm/aaaa" v-model="diaria.dataFimViagem" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="origem">Origem</label>
            <v-select class="border-v-select mt-1" id="origem" v-model="diaria.origem" :reduce="(cidades) => cidades.id"
              :options="cidades" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
          <div class="form-group">
            <label for="destino">Destino</label>
            <v-select class="border-v-select mt-1" id="destino" v-model="diaria.destino"
              :reduce="(cidades) => cidades.id" :options="cidades" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
          <div class="form-group">
            <label for="ordenador">Ordenador</label>
            <v-select class="border-v-select mt-1" id="ordenador" v-model="diaria.ordenadorDespesaId"
              :reduce="(ordenadorDespesaId) => ordenadorDespesaId.id" :options="ordenador" label="nome">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>
          <div class="row">
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="tipoOrdenador">Tipo Ordenador</label>
                <v-select class="border-v-select mt-1" id="tipoOrdenador" v-model="diaria.tipoOrdenador"
                  :reduce="(tipos) => tipos.v" :options="tipoOrdenador" label="descricao">
                  <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
              </div>
            </div>
            <div class="col-6 d-flex flex-column">
              <div class="form-group">
                <label for="status">Status</label>
                <v-select class="border-v-select mt-1" id="status" v-model="diaria.status" :options="status"
                  label="descricao">
                  <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-between">
            <button @click="limparBuscaDiarias" class="btn btn-default">
              <i class="fa fa-ban"></i>Limpar
            </button>

            <button @click="buscarDiarias(page)" class="btn text-color-principal background-principal">
              <i class="fa fa-search"></i> Buscar
            </button>

            <!-- <button 
              type="button"
              class="btn text-color-principal background-principal dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-print"></i>Imprimir
            </button>
            <div class="dropdown-menu">
              <a @click="imprimir()" class="dropdown-item" href="#">Listagem</a>
              <a class="dropdown-item" href="#">Requisição</a>
              <a @click="imprimirExtrato()"class="dropdown-item" href="#">Extrato</a>
               <a class="dropdown-item" href="#">Extrato Civil</a> 
              <a class="dropdown-item" href="#">Duplicidade</a>
            </div>  -->

            
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card card-primary">
        <div class="background-principal card-header text-color-principal">
          <h3 class="card-title">Diárias</h3>
          <div v-if="showPaginacao">
            <div class="float-right" v-if="resultados.length > 0">
              <a v-if="first != true" href="#" v-on:click.prevent="buscarDiarias(page - 1)"><i
                  class="fas fa-angle-left mx-2 text-color-principal"></i></a>
              Página {{ page + 1 }}
              <a v-if="last != true" href="#" v-on:click.prevent="buscarDiarias(page + 1)"><i
                  class="fas fa-angle-right mx-2 text-color-principal"></i></a>
            </div>
            <div class="float-right" v-if="resultados.length == 0">
              <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"><i
                  class="fas fa-angle-left mx-2 text-color-principal"></i></a>
            </div>
          </div>
        </div>
        <div v-if="resultados.length == 0 && semResultados" class="mx-auto my-auto sem-resultados py-2">
          <loading :active="loaderActive" :is-full-page="fullPage" />
          <div v-if="consulta">
            <button class="btn btn-success mb-1 mx-1 dropdown-toggle float-right" type="button" id="dropdownMenuButton"
              data-toggle="dropdown">
              <strong>Imprimir</strong>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a class="dropdown-item" href="#" @click="buscarDiarias(1, 2)">Consulta</a>
              </li>
            </ul>
          </div>
          <div>Sem resultados!</div>
        </div>
        <div v-else>
          <loading :active="loaderActive" :is-full-page="fullPage" />
          <div class="btn-group ml-4 mt-3 float-left">
            <button class="btn btn-success mb-1 mx-1 dropdown-toggle float-right" type="button" id="dropdownMenuButton"
              data-toggle="dropdown">
              <strong>Imprimir</strong>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a class="dropdown-item" href="#" @click="buscarDiarias(1, 2)">Consulta</a>
              </li>

              <li>
                <a class="dropdown-item" href="#" @click="buscarDiarias(1, 1)">Extrato</a>
              </li>
            </ul>
          </div>
          <div class="card-body px-0 d-flex flex-row">
            <div class="card-body table-responsive p-0 max-height-content">
              <div class="row px-5 d-flex justify-content-around">
                <div :key="id" v-for="(diaria, id) in resultados"
                  class="card card-item bg-light col-sm-12 col-md-5 clicavel" @click="alterarDiaria(diaria.id)">
                  <div class="header card-item-result background-principal text-color-principal">
                    {{ diaria.nome }}
                  </div>
                  <div>
                    <div class="col-12 row">
                      <div class="col-sm-12">
                        <ul class="mb-2 fa-ul text-muted ml-2 mt-2">
                          <li class="medium">
                            <div class="row">
                              <div class="col-6 d-flex flex-column">
                                <span>
                                  <b>RD: </b>
                                  {{ diaria.id }}
                                </span>
                              </div>
                              <div class="col-6 d-flex flex-column">
                                <span>
                                  <b>Data: </b>
                                  {{ diaria.data | formatarData }}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li class="medium">
                            <div class="row">
                              <div class="col-6 d-flex flex-column">
                                <span>
                                  <b>Início: </b>
                                  {{ diaria.dataInicio | formatarData }}
                                </span>
                              </div>
                              <div class="col-6 d-flex flex-column">
                                <span>
                                  <b>Fim: </b>
                                  {{ diaria.dataFim | formatarData }}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li class="medium">
                            <div class="row">
                              <div class="col-6 d-flex flex-column">
                                <span>
                                  <b>Quantidade: </b>
                                  {{ diaria.quantidade }}
                                </span>
                              </div>
                              <div class="col-6 d-flex flex-column">
                                <span>
                                  <b>Unitário: </b>
                                  {{ diaria.unitario }}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li class="medium">
                            <div class="row">
                              <div class="col-6 d-flex flex-column">
                                <span>
                                  <b>Total: </b>
                                  {{ diaria.total }}
                                </span>
                              </div>
                              <div class="col-6 d-flex flex-column">
                                <span>
                                  <b>Status: </b>
                                  {{ diaria.status }}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li class="medium">
                            <b>Processo: </b>
                            {{ diaria.processo }}
                          </li>
                          <li class="medium">
                            <b>Origem: </b>
                            {{ diaria.origem }}
                          </li>
                          <li class="medium">
                            <b>Destino: </b>
                            {{ diaria.destino }}
                          </li>
                          <li class="medium">
                            <b>Ordenador: </b>
                            {{ diaria.nome_ordenador }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { DATE_TIME_LONG_FORMAT, DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import ConsultaListasService from "@/service/ConsultaListasService";
import LancamentosAlteracoes from "@/components/rh/diaria/LancamentosAlteracoes.vue";
import RhDiariasService from "@/service/RhDiariasService";
import ListaDiaria from "./ListaDiaria.vue";
import { EventBus } from "@/main";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";


export default {
  components: {
    LancamentosAlteracoes,
    ListaDiaria,
    Loading,
  },
  created() {
    this.preencherListasObj({
      cidades: "cidade",
    });

    this.listarOrdenadores();
  },
  data() {
    return {
      diaria: {
        ordenadorDespesaId: null,
        rd: null,
        servidor: null,
        data: null,
        projeto: null,
        processo: null,
        meiaDiaria: null,
        dataInicio: null,
        dataFim: null,
        dataInicioViagem: null,
        dataFimViagem: null,
        tipoOrdenador: null,
        origem: null,
        descricao: null,
        data1: null,
        data2: null,
      },
      temp: [],
      resultados: [],
      semResultados: true,
      submitted: false,
      isSaving: false,
      tipoOrdenador: [
        { descricao: "Delegacia Geral", v: "DELEGACIA_GERAL" },
        { descricao: "Gabinete da SSP", v: "GABINETE_DE_SSP" },
      ],
      ordenador: [],
      status: ["ABERTO", "PAGO", "CANCELADO"],
      cidades: [],
      showPaginacao: false,
      page: 0,
      first: true,
      last: false,
      loaderActive: false,
      fullPage: false,
      ordenadorNome: null,
      consulta: false,
    };
  },
  filters: {
    formatarData: function (data) {
      if (data == "" || data == null) {
        return "Sem data registrada";
      } else {
        const [ano, mes, dia] = data.split("-");
        const dataFormatada = `${dia}/${mes}/${ano}`;
        return dataFormatada;
      }
    },

    formatarCidade: function (cidadeId) {
      if (cidadeId == null) {
        return "Sem cidade cadastrada";
      } else {
        const cidadeNome = cidadeId;
        return cidadeNome;
      }
    },

    formatarOrdenador: function (ordenadorId) {
      if (ordenadorId == null) {
        return "";
      } else {
        RhDiariasService.getOrdenadorDespesa(ordenadorId).then((resp) => {
          const ordenadorNome = resp.data.nome;
          return ordenadorNome;
        });
      }
    },
  },

  methods: {
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    preencherListasObj(obj) {
      Object.keys(obj).forEach((lista) => {
        ConsultaListasService.buscarListaPorNomeTabela(obj[lista])
          .then((response) => {
            this[lista] = response.data;
          })
          .catch((e) => alert("ERRO: " + e.message));
      });
    },

    buscarDiarias(pagina, tipo) {

      this.showLoader();
      this.consulta = true;

      this.showPaginacao = true;
      const params = {
        id: this.diaria.rd ? this.diaria.rd : null,
        nome: this.diaria.servidor ? this.diaria.servidor : null,
        data: this.diaria.data ? this.diaria.data : null,
        processo: this.diaria.processo ? this.diaria.processo : null,
        meiaDiaria: this.diaria.meiaDiaria ? this.diaria.meiaDiaria : null,
        dataInicio: this.diaria.dataInicioViagem
          ? this.diaria.dataInicioViagem
          : null,
        dataFim: this.diaria.dataFimViagem ? this.diaria.dataFimViagem : null,
        origem: this.diaria.origem ? this.diaria.origem : null,
        destino: this.diaria.destino ? this.diaria.destino : null,
        ordenadorDespesaId: this.diaria.ordenadorDespesaId
          ? this.diaria.ordenadorDespesaId
          : null,
        tipoOrdenador: this.diaria.tipoOrdenador
          ? this.diaria.tipoOrdenador
          : null,
        status: this.diaria.status ? this.diaria.status : null,
        matricula: this.diaria.matricula ? this.diaria.matricula : null,
        data1: this.diaria.data1 ? this.diaria.data1 : null,
        data2: this.diaria.data2 ? this.diaria.data2 : null,
        page: pagina,
      };

      if (tipo === undefined) {
        RhDiariasService.getDiarias(params).then((resp) => {
          this.resultados = resp.data.content;

          this.page = resp.data.number;
          this.first = resp.data.first;
          this.last = resp.data.last;

          this.hideLoader();
        });
      } else {
        params.tipo = tipo;

        RhDiariasService.gerarPdf(params).then((resp) => {
          const objUrl = URL.createObjectURL(resp.data);
          const url = window.URL.createObjectURL(resp.data);

          const link = document.createElement("a");
          link.href = url;

          link.download = "Extrato-";

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);

          this.hideLoader();
        });
      }
    },
    limparBuscaDiarias() {
      this.diaria = {};
    },

    alterarDiaria(id) {
      EventBus.$emit("LimparLancamento");
      EventBus.$emit("Alterar", id);
    },

    listarOrdenadores() {
      RhDiariasService.getAllOrdenadorDespesa().then(
        (response) => (this.ordenador = response.data)
      );
    },

    imprimirExtrato(){
      console.log("imprimirExtrato");
    },
  },
};
</script>

<style></style>