<template>
  <div class="card card-outline">
    <!--div class="card-header d-flex background-principal text-color-principal">
      <h3 class="card-title mr-auto">{{ textTitulo }}</h3>
    </div-->
    <div class="card-body p-0 ">
      <div class="submit-form">
        <div>
          <component :is="componente" />
        </div>
      </div>
    </div>
    <!--div class="card-footer">
      <div class="float-right">
        <button class="btn btn-default mr-1">
          <i class="fas fa-pencil-alt"></i> Rascunho
        </button>
        <button @click="salvarProcesso" class="btn text-color-principal background-principal ml-1">
          <i class="far fa-envelope"></i> Enviar
        </button>
      </div>
      <button type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div-->
  </div>
</template>

<script>

// import AR from "@/components/processos/AtaRegistro.vue";
//import CL from "@/components/processos/CadastroLicitante.vue";
//import CO from "@/components/processos/CadastroOrgao.vue";
//import CT from "@/components/processos/Contrato.vue";
//import PR from "@/components/processos/Procedimento.vue";
//import RS from "@/components/processos/Resultado.vue";
//import SC from "@/components/processos/Sancao.vue";
//import TblAtaRegistro from "@/components/processos/TabelaAtaRegistro.vue";
//import TblCadastroLicitante from "@/components/processos/TabelaCadastroLicitante.vue";
//import TblCadastroOrgao from "@/components/processos/TabelaCadastroOrgao.vue";
//import TblContrato from "@/components/processos/TabelaContrato.vue";
//import TblProcedimento from "@/components/processos/TabelaProcedimento.vue";
//import TblResultado from "@/components/processos/TabelaResultado.vue";
//import TblSancao from "@/components/processos/TabelaSancao.vue";
import TabAtaRegistro from "@/components/processos/ConteudoAtaRegistro.vue";
import TabCadastroLicitante from "@/components/processos/ConteudoCadastroLicitante.vue";
import TabCadastroOrgao from "@/components/processos/ConteudoCadastroOrgao.vue";
import TabContrato from "@/components/processos/ConteudoContrato.vue";
import TabProcedimento from "@/components/processos/ConteudoProcedimento.vue";
import TabSancao from "@/components/processos/ConteudoSancao.vue";
import TabResultado from "@/components/processos/ConteudoResultado.vue";


import { EventBus } from "@/main.js";

export default {
  components: {TabAtaRegistro, TabCadastroLicitante, TabCadastroOrgao, TabContrato, TabProcedimento, TabSancao, TabResultado},
  data() {
    return {
      componente: "",
      textTitulo: "Novo"
    }
  },
  created() {
    EventBus.$on("alteraAcaoConteudoProcesso", (acao) => {
      this.textTitulo = acao.titulo
      this.componente = acao.tipo
    });
  },
  methods:{
    //salvarProcesso(){
      
     // switch(this.componente){
        //case 'AR':
         // EventBus.$emit('enviarProcessosAR',null);
         // break;

        //case 'CL': 
          //EventBus.$emit('enviarProcessosCL',null);
          //break;
        //case 'CO':
         // EventBus.$emit('enviarProcessosCO',null);
          //break;

        //case 'CT':
         // EventBus.$emit('enviarProcessosCT',null);
         // break;

        //case 'PR':
         // EventBus.$emit('enviarProcessosPR',null);
          //break;

       // case 'RS':
          //EventBus.$emit('enviarProcessosRS',null);
          //break;

        //case 'SC':
         // EventBus.$emit('enviarProcessosSC',null);
          //break;

        //default:
          //console.loge('encerrou o processo');
      //}
      
    //}

  }
}
</script>


 