<template>
  <div class="card-body pt-0">
    <Loader :active="loaderActive" message="Carregando..." />
    <!-- <div class="tab-content" id="custom-tabs-five-tabContent"> -->
    <!-- <div class="tab-pane fade" role="tabpanel" > -->
    <!-- <div class="table-responsive mailbox-messages"> -->
    <table class="table table-hover table-striped" v-if="!loaderActive">
      <thead>
        <tr>
          <th>Tipo</th>
          <th>Assunto</th>
          <th>Anexo</th>
          <th>Destinatário</th>
          <th>Setor</th>
          <th>Recebido</th>

          <!-- <th>Ciencia</th> -->
        </tr>
      </thead>
      <tbody v-if="resultados">
        <tr class="linhaTabela" v-for="documento in documentos" v-bind:key="documento.id"
          @click="visualizar(documento.id)">
          <td class="mailbox-name" v-if="documento.tipoDocumento === 'CI'">
            Comunicação Interna
          </td>
          <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'OF'">
            Ofício
          </td>
          <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'DE'">
            Despacho
          </td>
          <td class="mailbox-name" v-else-if="documento.tipoDocumento === 'PR'">
            Protocolo
          </td>
          <td class="mailbox-subject">
            {{ documento.assunto }}
          </td>
          <td class="mailbox-attachment">
            <i class="fas fa-paperclip" v-if="documento.anexo"></i>
          </td>
          <td v-if="documento.origemDocumento == 'Interna'" class="mailbox-subject">
            {{ documento.fkUsuarioDestino.pessoaResumo.nome }}
          </td>
          <td v-else class="mailbox-subject">
            {{ documento.nomeDestino }}
          </td>
          <td v-if="documento.origemDocumento == 'Interna'" class="mailbox-subject">
            {{ documento.fkSetorDestino.descricao }}
          </td>
          <td v-else class="mailbox-subject">
            {{ documento.enderecoDestino }}
          </td>
          <td class="mailbox-date">
            {{ updateZonedDateTimeField(documento.dataAssinatura) }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <div class="mx-auto my-auto sem-resultados py-2">
          <div>Sem documentos enviados!</div>
        </div>
      </tbody>
    </table>
  </div>
</template>
<script>
import { EventBus } from "@/main.js";
import { DATE_FORMAT_LOCAL } from "@/util/filter";
import paginacao from "@/util/paginacao";
import dayjs from "dayjs";
import documentoService from "../../service/DocumentoService";
import Loader from "../Loader.vue";

export default {
  name: "Documentos",
  components: { Loader },
  data() {
    return {
      documentos: [],
      resultados: false,
      page: [],
      documentoPesquisa: [],
      loaderActive: false,
    };
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    acao: {
      type: String,
      default: "EN",
    },
  },
  methods: {
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    getDocumentos(pagina) {
      this.showLoader();
      if (this.documentoPesquisa.length == undefined) {

        this.documentoPesquisa.tableConsulta = 'documentos_enviados';

        documentoService.pesquisarDocumento(this.documentoPesquisa, pagina).then((response) => {
          this.hideLoader();
          let retorno = paginacao.montarPaginacao(response.data)

          this.page = retorno.page;
          this.documentos = retorno.data;

          if (this.documentos.length > 0) {
            this.resultados = true;
          }

          EventBus.$emit("paginacao", retorno.page);

        });

      } else {

        documentoService.buscarDocumentosEnviados(pagina).then((response) => {
          this.hideLoader();
          let retorno = paginacao.montarPaginacao(response.data)

          this.page = retorno.page;
          this.documentos = retorno.data;

          if (this.documentos.length > 0) {
            this.resultados = true;
          }

          EventBus.$emit("paginacao", retorno.page);

        }).catch((e) => {
          this.hideLoader();
        });
      }
    },

    visualizar(id) {
      EventBus.$emit("alteraAcaoConteudo", "VD");
      EventBus.$emit("enviarPdf", id);
    },

    updateZonedDateTimeField(field) {
      return dayjs(field).format(DATE_FORMAT_LOCAL);
    },
  },

  created() {

    EventBus.$on("filtrarDocumentoEnviados", (documento) => {
      this.documentoPesquisa = documento;
      this.getDocumentos(0);
    });

    if (this.currentUser) {
      this.getDocumentos(0);
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  beforeMount() {
    $("body").removeClass("login-page").addClass("sidebar-mini");
  },
  mounted() {
    $(function () {
      $(".checkbox-toggle").click(function () {
        const clicks = $(this).data("clicks");
        if (clicks) {
          $(".mailbox-messages input[type='checkbox']").prop("checked", false);
          $(".checkbox-toggle .far.fa-check-square")
            .removeClass("fa-check-square")
            .addClass("fa-square");
        } else {
          $(".mailbox-messages input[type='checkbox']").prop("checked", true);
          $(".checkbox-toggle .far.fa-square")
            .removeClass("fa-square")
            .addClass("fa-check-square");
        }
        $(this).data("clicks", !clicks);
      });

      $(".mailbox-star").click(function (e) {
        e.preventDefault();
        const $this = $(this).find("a > i");
        const fa = $this.hasClass("fa");

        if (fa) {
          $this.toggleClass("fa-star");
          $this.toggleClass("fa-star-o");
        }
      });
    });
  },
};
</script>
<style scoped>
.linhaTabela {
  cursor: pointer;
}
</style>
