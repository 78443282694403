<template>
  <div  >
    <b-modal
     id="modal-5"
     ref="modal-5"
     title="Férias Fracionadas"
     data-backdrop="static"
     size="xl"
     cancel-disabled
     no-close-on-backdrop
     hide-header-close
     header-class="modal-header
      background-principal
      text-color-principal
      card-item-result
      "
     content-class="modal-content border-rounded-detail"
     dialog-class="p-3"
     title-class="modal-title mx-auto xl"
    >
      <div class="card-body container-fluid ">
        <div class="row">

          <div class="form-group col-lg-3">
            <label for="diasGozados">Dias Gozados</label>
            <input
              @onblur="periodoFerias()"
              type="text"  
              class="form-control form-control-border border-width-2"
              name="diasGozados"
              @change="periodoFerias()"
              id="diasGozados"
              :class="{
                  'is-invalid': isSaving && $v.feriasFracionadas.diasGozados.$error}"
              v-model="feriasFracionadas.diasGozados"
            />
          </div>

          <div class="form-group col-lg-3">
            <label for="diasFaltantes">Dias Faltantes</label>
            <input
              type="number" 
              class="form-control form-control-border border-width-2"
              name="diasFaltantes"
              id="diasFaltantes"
              disabled
              :class="{
                  'is-invalid': isSaving && $v.feriasFracionadas.diasFaltantes.$error}"
              v-model="feriasFracionadas.diasFaltantes"
            />
          </div>
        
          <div class="form-group col-lg-3">
            <label>Data Início</label>
            <div class="d-flex">
              <input
              type="date"
              class="form-control form-control-border border-width-2 ml-1" 
              placeholder="dd/mm/aaaa"
              v-model="feriasFracionadas.dataInicio"
            />
            </div>
            
          </div>
          <div class="form-group col-lg-3">
            <label>Data Fim</label>
            <div class="d-flex">
              <input
                  type="date"
                  class="form-control form-control-border border-width-2 ml-1" 
                  placeholder="dd/mm/aaaa"
                  v-model="feriasFracionadas.dataFim"
                />
            </div>
          </div>
        </div>

      </div>
      <template  #modal-footer="{ ok, cancel }">
        <button @click.prevent=" [ok(),salvarferiasgozadas()]" class="btn text-color-principal background-principal ml-1">
          <i class="fa fa-save"></i> Salvar
        </button>
        <button @click="limparferiasgozadas()" class="btn btn-default float-right">
          <i class="fa fa-ban"></i> Limpar
        </button>
        <b-button @click="[cancel(),apagarCampos()]" class="btn btn-default float-right">
          <i class="fa-solid fa-x"></i> Fechar
        </b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import ConsultaFerias from "@/components/rh/ferias/ConsultaFerias.vue";
import rhService from "@/service/RHService";


export default {
  comments:{
    ConsultaFerias,    
  },

  data(){
    return{

      data:{},
      feriasFracionadas:{
        diasGozados:null,
        diasFaltantes:null,
        dataInicio:null,
        dataFim:null,
        
      },
      isSaving: false,
      submitted: false,
    }
  },

  created(){
    //  EventBus.$on("CadastrarFeriasFracionadas", (listener) =>{
    //    this.feriasFracionadas = listener;
    //  });

    this.periodoFerias();
  },
  methods:{


    periodoFerias(){

      if (this.feriasFracionadas.diasGozados !=null) {

        this.feriasFracionadas.diasFaltantes=((this.feriasFracionadas.diasGozados*(-1))+30);
        
      }else{
        return null;
      }

      document.getElementById("diasGozados")
    },

    salvarferiasgozadas(){

      if (this.$refs["ferias-fracionadas"]!= undefined){
            this.feriasFracionadas = Object.assign(
            {},
            this.feriasFracionadas,
            this.$refs["ferias-fracionadas"].feriasFracionadas
            );

            this.$refs["ferias-fracionadas"].isSaving = this.isSaving;
            if (this.$v.$ivalid || this.$refs["ferias-fracionadas"].$v.$ivalid){
            return;
            } else{
            if (this.$v.$ivalid){
                return;


            }
            }

        }

        // const feriasFracionadas ={
        //   ferias: { id : this.feriasFracionadas.id},
        //   funcionarioCadastrante: this.feriasFracionadas.funcionarioCadastrante,
        //   diasGozados: this.feriasFracionadas.diasGozados != '' || null ? this.feriasFracionadas.diasGozados : null,
        //   diasFaltantes:this.feriasFracionadas.diasFaltantes != '' || null ? this.feriasFracionadas.diasFaltantes : null,
        //   dataInicio:this.feriasFracionadas.dataInicio != '' || null ? this.feriasFracionadas.dataInicio : null,
        //   dataFim:this.feriasFracionadas.dataFim != '' || null ? this.feriasFracionadas.dataFim : null,

        // }

        console.log(feriasFracionadas)

      rhService.criarFeriasGozadas(this.feriasFracionadas)
      .then((response)=> {
            this.feriasFracionadas= response.data;
            Toast.fire("Cadastrado realizado", "", "success");
            this.feriasFracionadas={}
        }).catch(()=>{
            Toast.fire("Falha ao casdastrar férias", "", "error");
        });
 

      
      // this.isSaving = true;
      // this.$v.$touch();
    },
    limparferiasgozadas(){
      // feriasFracionadas={
      //   diasGozados:null,
      //   diasFaltantes:null,
      //   dataInicio:null,
      //   dataFim:null,
      //   isSaving: false,
      // }
      this.feriasFracionadas={};
    },

    apagarCampos(){
      this.feriasFracionadas={};
    },
  }
}
</script>

<style>

</style>