// import axios from "axios";

import instance from "./axiosConfig";

// import authHeader from "@/service/auth/auth-header";
import store from "../store/index.js";

class CadastroVisita {
  constructor() {
    this.usuarioLogado =
      store.state.auth.user == null ? null : store.state.auth.user.usuario;
  }

  inserirCadastrante(visita) {
    visita.pessoaDTO.registrante = { id: this.usuarioLogado.idFuncionario };
  }

  cadastrarVisita(visita) {
    this.inserirCadastrante(visita);
    return instance.post("/api/visitas", visita);
  }

  buscarVisitasAbertas() {
    return instance.get("/api/visitas/");
  }

  buscarVisitasTodas() {
    return instance.get("/api/visitas/todas");
  }

  atualizarVisitaSaida(visitaId) {
    return instance.patch("/api/visitas/" + visitaId.id, visitaId);
  }
}

export default new CadastroVisita();
