<template>
  <div>
    <div class="modal fade show h-100 overflow-auto" id="modal-xl" style="display: block" aria-modal="true"
      role="dialog">
      <div class="modal-dialog modal-xl" @click.stop>
        <div v-if="veiculoDetalheNacional">
          <div class="modal-content border-rounded-detail">
            <div class="
              modal-header
              background-principal
              text-color-principal
              card-item-result
            ">
              <h4 class="modal-title mx-auto">{{ veiculoDetalheNacional.placa }}</h4>
            </div>
            <div class="modal-body max-height-content">
              <h6 class="mt-4 mb-3"><b>DADOS DO PROPRIETÁRIO: </b></h6>
              <div class="row m-10 p-0">
                <div class="col-sm-12 col-md-6">
                  <p class="medium mb-0">
                    <b>Proprietário: </b> {{ veiculoDetalheNacional.proprietario.nomeProprietario }}
                  </p>
                  <p class="medium mb-0">
                    <b>Endereço do proprietário: </b> {{ veiculoDetalheNacional.proprietario.enderecoProprietario }}
                  </p>
                </div>
                <div class="col-sm-12 col-md-6">
                  <p class="medium mb-0">
                    <b>Documento: </b> {{ veiculoDetalheNacional.proprietario.tipoDocumentoProprietario }}
                  </p>
                  <p class="medium mb-0">
                    <b>Número do Documento: </b> {{ veiculoDetalheNacional.proprietario.numeroDocumentoProprietario }}
                  </p>
                </div>
              </div>
              <h6 class="mt-4 mb-3"><b>DADOS DO VEÍCULO: </b></h6>
              <div class="row m-10 p-0">
                <div class="col-sm-12 col-md-4">
                  <p class="medium mb-0">
                    <b>Chassi: </b> {{ veiculoDetalheNacional.chassi }}
                  </p>
                  <p class="medium mb-0">
                    <b>Renavam: </b> {{ veiculoDetalheNacional.renavam }}
                  </p>
                  <p class="medium mb-0">
                    <b>Marca/Modelo do Veículo: </b> {{ veiculoDetalheNacional.marcaModelo }}
                  </p>
                  <p class="medium mb-0">
                    <b>Categoria do Veículo: </b>
                    {{ veiculoDetalheNacional.categoria }}
                  </p>
                  <p class="medium mb-0">
                    <b>Tipo do Veículo: </b> {{ veiculoDetalheNacional.tipoVeiculo }}
                  </p>
                  <p class="medium mb-0">
                    <b>Grupo: </b> {{ veiculoDetalheNacional.grupoVeiculo }}
                  </p>
                  <p class="medium mb-0">
                    <b>Carroceria: </b> {{ veiculoDetalheNacional.carroceria }}
                  </p>
                  <p class="medium mb-0">
                    <b>Número da Carroceria: </b> {{ veiculoDetalheNacional.numeroCarroceria }}
                  </p>
                  <p class="medium mb-0">
                    <b>Potência: </b> {{ veiculoDetalheNacional.potencia }}
                  </p>
                  <p class="medium mb-0">
                    <b>Cilindrada: </b> {{ veiculoDetalheNacional.cilindrada }}
                  </p>
                  <p class="medium mb-0">
                    <b>Lotação: </b> {{ veiculoDetalheNacional.lotacao }}
                  </p>
                  <p class="medium mb-0">
                    <b>Capacidade máxima de carga: </b> {{ veiculoDetalheNacional.capacidadeMaximaCarga }}
                  </p>
                </div>
                <div class="col-sm-12 col-md-4">
                  <p class="medium mb-0">
                    <b>Capacidade máxima de tração: </b> {{ veiculoDetalheNacional.capacidadeMaximaTracao }}
                  </p>
                  <p class="medium mb-0">
                    <b>Espécie do Veículo: </b>
                    {{ veiculoDetalheNacional.especie }}
                  </p>
                  <p class="medium mb-0">
                    <b>Combustível: </b> {{ veiculoDetalheNacional.combustivel }}
                  </p>
                  <p class="medium mb-0">
                    <b>Motor: </b> {{ veiculoDetalheNacional.numeroMotor }}
                  </p>
                  <p class="medium mb-0"><b>Cor: </b> {{ veiculoDetalheNacional.cor }}</p>
                  <p class="medium mb-0">
                    <b>Situação: </b> {{ veiculoDetalheNacional.situacaoVeiculo }}
                  </p>
                  <p class="medium mb-0">
                    <b>Peso bruto total: </b> {{ veiculoDetalheNacional.pesoBrutoTotal }}
                  </p>
                  <p class="medium mb-0">
                    <b>Indicador de remarcação do chassi: </b> {{ veiculoDetalheNacional.indicadorRemarcacaoChassi }}
                  </p>
                  <p class="medium mb-0">
                    <b>Número da caixa de câmbio: </b> {{ veiculoDetalheNacional.numeroCaixaCambio }}
                  </p>
                  <p class="medium mb-0">
                    <b>Quantidade de eixos: </b> {{ veiculoDetalheNacional.quantidadeEixo }}
                  </p>
                  <p class="medium mb-0">
                    <b>Número de eixos auxiliares: </b> {{ veiculoDetalheNacional.numeroEixoAuxiliar }}
                  </p>
                  <p class="medium mb-0">
                    <b>Número de eixos traseiros: </b> {{ veiculoDetalheNacional.numeroEixoTraseiro }}
                  </p>
                </div>
                <div class="col-sm-12 col-md-4">
                  <p class="medium mb-0">
                    <b>Tipo da montagem: </b> {{ veiculoDetalheNacional.tipoMontagem
                    }}
                  </p>
                  <p class="medium mb-0">
                    <b>Código do município do emplacamento: </b> {{ veiculoDetalheNacional.codigoMunicipioEmplacamento
                    }}
                  </p>
                  <p class="medium mb-0">
                    <b>Cidade do emplacamento: </b> {{ veiculoDetalheNacional.municipioPlaca }}
                  </p>
                  <p class="medium mb-0">
                    <b>UF de emplacamento: </b> {{ veiculoDetalheNacional.ufEmplacamento }}
                  </p>
                  <p class="medium mb-0">
                    <b>Data do emplacamento: </b> {{ veiculoDetalheNacional.dataEmplacamento | exibirData }}
                  </p>
                  <p class="medium mb-0">
                    <b>Ano de Fabricacão: </b>
                    {{ veiculoDetalheNacional.anoFabricacao }}
                  </p>
                  <p class="medium mb-0">
                    <b>Ano do Modelo: </b>
                    {{ veiculoDetalheNacional.anoModelo }}
                  </p>
                  <p class="medium mb-0">
                    <b>Categoria: </b> {{ veiculoDetalheNacional.categoria }}
                  </p>
                  <p class="medium mb-0">
                    <b>Data atualização do Veículo: </b> {{ veiculoDetalheNacional.dataAtualizacaoVeiculo | exibirData
                    }}
                  </p>
                  <p class="medium mb-0">
                    <b>Data emissão do último CRV: </b> {{ veiculoDetalheNacional.dataEmissaoUltimoCRV | exibirData }}
                  </p>
                </div>
              </div>
              <h6 class="mt-4 mb-3"><b>Restrições: </b></h6>
              <div>
                <ListaRestricoesVeiculoNacional :restricoes="veiculoDetalheNacional.restricao" />
              </div>

            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-default" data-dismiss="modal"
                @click="$emit('closeModalVeiculoNacional')">
                <i class="fa-solid fa-x"></i>
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../main.js'
import ListaRestricoesVeiculoNacional from './ListaRestricoesVeiculoNacional.vue'

export default {
  name: 'DetalhesVeiculosConsultaNacional',
  data() {
    return {
      veiculoDetalheNacional: null,
      restricoes: []
    }
  },
  created() {
    EventBus.$on('consultaVeiculoDetalheNacional', (event) => {
      this.consultarVeiculoNacional(event)
    })
  },
  filters: {
    exibirData: function (dataCompleta) {
      if (dataCompleta) {
        const apenasAno = dataCompleta.substring(0, 4)
        const apenasMes = dataCompleta.substring(5, 7)
        const apenasDia = dataCompleta.substring(8, 10)
        const dataFormatada = `${apenasDia}/${apenasMes}/${apenasAno}`
        return dataFormatada
      }
    }
  },
  methods: {
    consultarVeiculoNacional(resultVeiculoSelecionado) {
      this.veiculoDetalheNacional = resultVeiculoSelecionado
    }
  },
  components: { ListaRestricoesVeiculoNacional }
}
</script>
