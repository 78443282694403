<template>
  <form>

    <div class="card card-primary">
      <div class="card-header background-principal">
        <h3 class="card-title">Busca por Celular</h3>
        <!-- /.card-tools -->
      </div>

      <div class="card-body">
        <div class="form-group">
          <label for="inputImeiCelular" class="pesquisar-imei-celular">Digite o IMEI</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputImeiCelular"
            placeholder="Digite o imei ou parte dele" v-mask="'XXXXXXXXXXXXXXX'" v-model="imei" />
        </div>
        <div class="form-group">
          <label for="inputNumeroCelular" class="pesquisar-numero-celular">Digite o número de Celular</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputNumeroCelular"
            placeholder="Digite o número ou parte dele" v-mask="'(XX) XXXXX-XXXX'" v-model="numero" />
        </div>
        <div class="form-group">
          <label for="inputModeloCelular" class="pesquisar-modelo-celular">Digite o modelo</label>
          <input type="text" class="form-control form-control-border border-width-2" id="inputModeloCelular"
            placeholder="Digite o modelo ou parte dele" v-model="modelo" />
        </div>
        <div class="form-group">
          <label for="inputFabricanteCelular" class="pesquisar-fabricante-carro">Selecione o fabricante</label>
          <v-select class="border-v-select" id="inputCorDoCarro" v-model="fabricante"
            :disabled="
              fabricantes == null ||
              fabricantes == []
            " :options="fabricantes" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
      <!-- /.card-header -->
      <!-- </div> -->

      <div class="card-footer">
        <button @click.prevent="emitirConsulta" v-on:keyup.enter="emitirConsulta" type="submit"
          class="btn col-12 background-principal text-color-principal">
          <i class="fas fa-search"></i>
          Buscar
        </button>
      </div>

      <!-- /.card-body -->
      <!-- /.card-footer -->
    </div>
    <!-- /.card -->
  </form>

</template>

<script>
import ConsultaListasService from '@/service/ConsultaListasService.js'
import { EventBus } from '../../../main.js'
import LocalIpService from "@/service/LocalIpService";

export default {
  name: 'FormCelularConsulta',
  data() {
    return {
      imei: '',
      numero: '',
      modelo: '',
      fabricante: '',
      fabricantes: [],
      page: 1,
      //ipUsuario:null
    }
  },
  created() {
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    ConsultaListasService.buscarListaPorNomeTabela('fabricante_celular')
      .then((response) => {
        this.fabricantes = response.data
      })
      .catch((e) => this.alertaFalhaBuscarListaPorNomeTabela())
  },
  methods: {
    mostrarAlerta() {
      // eslint-disable-next-line no-undef
      Toast.fire('Preencha ao menos um campo!!', '', 'error')
    },
    alertaFalhaBuscarListaPorNomeTabela() {
      Toast.fire('Falha ao buscar fabricante celular!!', '', 'error')
    },
    formValido() {
      if (
        this.imei ||
        this.numero ||
        this.modelo ||
        this.fabricante
      ) {
        return true
      }
      return false
    },
    emitirConsulta() {
      if (this.formValido()) {
        const params = {
          imei: this.imei,
          numeroAparelhoCelular: this.numero,
          modeloCelular: this.modelo.toUpperCase(),
          fabricanteCelular: this.fabricante ? this.fabricante.id : '',
          page: this.page,
          //ipUsuario:this.ipUsuario
        }
        EventBus.$emit('consultaCelular', params)
      } else {
        this.mostrarAlerta()
      }
    }
  }
}
</script>

<style>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}
</style>
