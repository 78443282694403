<template>
  <!-- <div class="col-md-3"> -->
  <!-- <router-link :to="link" class="btn btn-block btn-primary mb-3" >{{ text }}</router-link> -->
  <div>
    <button class="btn btn-block  mb-3 background-principal text-color-principal" @click="novaAcao('ND')">
      Novo Documento
    </button>
    <button class="btn btn-block mb-3 background-principal text-color-principal" @click="novaAcao('NP')">
      Novo Protocolo
    </button>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Pastas</h3>

        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <ul class="nav nav-pills flex-column">
          <li class="nav-item active">
            <a href="#" class="nav-link">
              <i class="fas fa-inbox"></i> Recebidos
              <span class="badge bg-primary float-right">12</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="far fa-envelope"></i> Enviados
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="far fa-file-alt"></i> Rascunhos
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="fas fa-filter"></i> Compartilhados
              <span class="badge bg-warning float-right">65</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="far fa-trash-alt"></i> Arquivados
            </a>
          </li>
        </ul>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->

  <!-- </div> -->
</template>
<script>

import { EventBus } from "@/main.js";

export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    link: {
      type: [String, Object],
      default: null,
    }
  }
};
</script>
