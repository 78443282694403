<template>
  <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Consulta SIGMA" />
    <conteudo v-role="'CONSULTA_COISA'">
      <div class="container-fluid p-3">
        <DetalhesCoisaConsulta v-show="isModalVisible" @closeModalCoisa="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisible"></div>
        <div class="row">
          <div class="col-md-3 pr-1">
            <FormCoisaConsulta />
          </div>

          <div class="col-md-9 pl-1">
            <ResultadosCoisaConsulta @showModalCoisa="showModal" />
          </div>
        </div>

      </div>

    </conteudo>

  </section>

</template>

<script>
import FormCoisaConsulta from '@/components/consultas/coisa/FormCoisaConsulta.vue'
import DetalhesCoisaConsulta from '../../../components/consultas/coisa/DetalhesCoisaConsulta.vue'
import ResultadosCoisaConsulta from '../../../components/consultas/coisa/ResultadosCoisaConsulta.vue'
import { EventBus } from '../../../main.js'

export default {
  name: 'ViewConsultaCoisa',

  components: { FormCoisaConsulta, DetalhesCoisaConsulta, ResultadosCoisaConsulta },
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      EventBus.$emit('esconderBoCardComponent')
    }
  }
}
</script>
