<template>
  <div class="card-body">
    <div class="submit-form">
      <div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-dataExpedicao">Data Expedição <span
                  class="text-danger">*</span></label>
              <!-- <div class="d-flex"> -->
              <input id="documento-dataExpedicao" data-cy="dataExpedicao" type="date"
                class="form-control form-control-border" name="dataExpedicao" :class="{
                  'is-invalid': isSaving && $v.documento.dataExpedicao.$error,
                }" :model="convertDateTimeFromServer(documento.dataExpedicao)"
                @change="updateZonedDateTimeField('dataExpedicao', $event)" />
              <div v-if="isSaving && !$v.documento.dataExpedicao.required" class="invalid-feedback">
                Data expedição documento é obrigatório
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-fkSetorDestino">Setor Destino <span
                  class="text-danger">*</span></label>
              <v-select class="border-v-select" id="documento-fkSetorDestino" :class="{
                'is-invalid':
                  isSaving && !$v.documento.fkSetorDestino.required,
              }" data-cy="fkSetorDestino" name="fkSetorDestino" v-model="documento.fkSetorDestino"
                @input="carregaFuncionario" :options="setores" label="descricao">
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
              <div v-if="isSaving && !$v.documento.fkSetorDestino.required" class="invalid-feedback">
                Setor destino é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-fkUsuarioDestino">Servidor Destino <span
                  class="text-danger">*</span></label>
              <v-select class="border-v-select" :class="{
                'is-invalid':
                  isSaving && !$v.documento.fkSetorDestino.required,
              }" id="documento-fkUsuarioDestino" data-cy="fkUsuarioDestino" name="fkUsuarioDestino"
                v-model="documento.fkUsuarioDestino" :options="funcionarios" label="nome">
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
              <div v-if="isSaving && !$v.documento.fkUsuarioDestino.required" class="invalid-feedback">
                Usuario destino é obrigatório
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-assunto">Tipo Documento <span
                  class="text-danger">*</span>
              </label>
              <v-select class="border-v-select" id="documento-tipoProtocolo" :class="{
                'is-invalid': isSaving && $v.documento.tipoProtocolo.$error,
              }" data-cy="tipoProtocolo" name="tipoProtocolo" v-model="documento.tipoProtocolo"
                :options="tipoDocumento" :reduce="(tipoDocumento) => tipoDocumento.id" label="descricao">
                <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
              <div v-if="isSaving && !$v.documento.tipoProtocolo.required" class="invalid-feedback">
                Tipo documento é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-assunto">Origem documento <span
                  class="text-danger">*</span></label>
              <input type="text"  name="origemDocumento"
                id="documento-origemDocumento" data-cy="origemDocumento" class="form-control form-control-border" v-model="documento.origemDocumento" :class="{
                  'is-invalid':
                    isSaving && !$v.documento.origemDocumento.required,
                }" />
              <div v-if="isSaving && !$v.documento.origemDocumento.required" class="invalid-feedback">
                Origem protocolo é obrigatório
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-assunto">Número/Ano Documento <span
                  class="text-danger">*</span></label>
              <input type="text" class="form-control form-control-border" name="numeroProtocolo"
                id="documento-numeroProtocolo" data-cy="numeroProtocolo" v-model="documento.numeroProtocolo" :class="{
                  'is-invalid':
                    isSaving && !$v.documento.numeroProtocolo.required,
                }" />
              <div v-if="isSaving && !$v.documento.numeroProtocolo.required" class="invalid-feedback">
                Numero/Ano do documento é obrigatório
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-dataExpedicao">Data Recebimento <span
                  class="text-danger">*</span></label>
              <!-- <div class="d-flex"> -->
              <input id="documento-dataAssinatura" data-cy="dataAssinatura" type="datetime-local"
                class="form-control form-control-border" name="dataAssinatura" :class="{
                  'is-invalid': isSaving && $v.documento.dataAssinatura.$error,
                }" :model="convertDateTimeFromServer(documento.dataAssinatura)"
                @change="updateZonedDateTimeField('dataAssinatura', $event)" />
              <div v-if="isSaving && !$v.documento.dataAssinatura.required" class="invalid-feedback">
                Data Recebimento protocolo é obrigatório
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-controleProcesso">e-Processos</label>
              <input type="text" class="form-control form-control-border" name="controleProcesso"
                id="documento-controleProcesso" data-cy="controleProcesso" v-model="documento.controleProcesso" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-referencia">Referência</label>
              <input type="text" class="form-control form-control-border" name="referencia" id="documento-referencia"
                data-cy="referencia" v-model="documento.referencia" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-control-label" for="documento-assunto">Assunto <span
                  class="text-danger">*</span></label>
              <input type="text" class="form-control form-control-border" name="assunto" id="documento-assunto"
                data-cy="assunto" v-model="documento.assunto" :class="{
                  'is-invalid': isSaving && !$v.documento.assunto.required,
                }" />
              <div v-if="isSaving && !$v.documento.assunto.required" class="invalid-feedback">
                Assunto é obrigatorió
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <div class="btn btn-default btn-file">
                <i class="fas fa-paperclip"></i> Anexo
                <input class="hidden" type="file" accept=".pdf" name="anexoDocumento" id="anexoDocumento" multiple />
                <input type="file" accept=".pdf" @change="carregarAnexos" multiple />
              </div>
              <!-- <p class="help-block">Max. 32MB</p> -->
              <div class="help-block with-errors" v-if="anexoInvalido">
                Permitido apenas arquivos pdf
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="anexosDocumentoTemp.length">
          <div class="box-footer card-footer bg-white">
            <ul class="mailbox-attachments">
              <li v-for="anexoDocumento in anexosDocumentoTemp" v-bind:key="anexoDocumento.id">
                <span class="mailbox-attachment-icon"><i class="fas fa-file-pdf"></i></span>
                <div class="mailbox-attachment-info">
                  <a href="#" class="mailbox-attachment-name"><i class="fas fa-paperclip"></i> {{ anexoDocumento.name }}
                  </a>
                  <span class="mailbox-attachment-size clearfix mt-1">
                    <span>{{
                    calaculaTamanhoArquivo(anexoDocumento.size)
                    }}</span>
                    <a @click="removeLinhaAnexo(anexoDocumento.id)" class="btn btn-default btn-sm float-right"><i
                        class="fas fa-trash fa-lg"></i></a>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="float-right">
      <button @click="cancelarNovoDocumento" class="btn btn-default mr-1">
        <i class="fas fa-xmark"></i> Cancelar
      </button>
      <button @click="salvarDocumento" class="btn btn-primary">
        <i class="fas fa-save"></i> Salvar
      </button>
    </div>
    <!-- <button type="reset" class="btn btn-default">
      <i class="fas fa-times"></i> Discard
    </button> -->
  </div>
</template>
<script>
import DE from "@/components/documentos/DespachoFormularioDocumento.vue";
import EX from "@/components/documentos/ExternoFormularioDocumento.vue";
import {
  default as CI,
  default as IN
} from "@/components/documentos/InternoFormularioDocumento.vue";
import documentoService from "../../service/DocumentoService";

import { EventBus } from "@/main.js";
import converteFuncionario from "@/util/converteFuncionario";
import { DATE_TIME_LONG_FORMAT } from "@/util/filter";
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";
import api from "../../service/DocumentoService";

export default {
  name: "Documentos",
  components: { IN, EX, CI, DE },
  data() {
    return {
      documento: {},
      novoDocumentoDTO: {},
      listaCopiaDocimento: [],
      anexosDocumento: [],
      anexosDocumentoTemp: [],
      submitted: false,
      isSaving: false,
      assunto: false,
      atualizou: false,
      modelo: false,
      anexoInvalido: false,
      componente: "",
      setores: [],
      funcionarios: [],
      titulo: "Novo Protocolo",
      tipoDocumento: [
        { id: "OF", descricao: "Ofício" },
        { id: "CR", descricao: "Circular" },
        { id: "EM", descricao: "Email" },
        { id: "PR", descricao: "Portaria" },
        { id: "DE", descricao: "Despacho" },
        { id: "ME", descricao: "Memorando" },
        { id: "RI", descricao: "Relatorio de Inteligencia" },
        { id: "CI", descricao: "Comunicação Interna" },
        { id: "PB", descricao: "Pedido de Busca" },
        { id: "TE", descricao: "Termo de Entrega" },
        { id: "OT", descricao: "Outros" },
      ],
      tipoTramitacao: [
        { descricao: "Interna", id: "IN" },
        { descricao: "Externa", id: "EX" },
      ],
    };
  },
  props: {
    text: {
      type: String,
      default: "editar",
    },
  },
  created() {
    EventBus.$on("editarUsarModeloDocumento", (documento) => {
      this.componente = documento.tipoDocumento;
      documento.fkUsuario =
        converteFuncionario.funcionarioDtoToFuncionarioSimples(
          documento.fkUsuario
        );
      documento.fkUsuarioDestino =
        converteFuncionario.funcionarioDtoToFuncionarioSimples(
          documento.fkUsuarioDestino
        );
      this.documento = documento;
      if (documento.id == null) {
        this.modelo = true;
      }
      $(function () {
        $("#documento-corpo-editar").summernote();
      });
    });
    EventBus.$on("responderDocumento", (documento) => {
      // console.log('Dentro do novo protocolo ', documento)
      this.documento.fkDocumentoOrigem = documento;
    });

    $(function () {
      $("#documento-corpo-novo").summernote();
    });
  },
  updated() {
    if (!this.atualizou) {
      if (this.$refs["corpo-documento"] != undefined) {
        this.$refs["corpo-documento"].documento = Object.assign(
          {},
          this.$refs["corpo-documento"].documento,
          this.documento
        );
        this.atualizou = true;
        this.$refs["corpo-documento"].carregaFuncionario(
          this.documento.fkSetorDestino
        );
      }
    }
  },
  validations: {
    documento: {
      dataExpedicao: { required },
      fkSetorDestino: { required },
      fkUsuarioDestino: { required },
      tipoProtocolo: { required },
      origemDocumento: { required },
      numeroProtocolo: { required },
      dataAssinatura: { required },
      assunto: { required },
    },
  },
  methods: {
    carregaDocumento(documento) {
      this.componente = documento.tipoDocumento;
      this.documento = documento;
    },
    salvarDocumento() {

      this.isSaving = true;
      if (this.documento.id) {
        this.isSaving = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.documento.tipoDocumento = "PR";
        this.novoDocumentoDTO.documentoDTO = this.documento;

        documentoService
          .update(this.novoDocumentoDTO)
          .then((param) => {
            this.isSaving = false;
            EventBus.$emit("alteraAcaoConteudo", "VD");
            EventBus.$emit("abaAtiva", "VD");
            EventBus.$emit("enviarPdf", param.data.id);
            const message = "Documento atualizado com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
          })
          .catch((error) => {
            this.isSaving = false;
            Toast.fire(error.response.data.detail.toString(), "", "error");
          });
      } else {
        this.isSaving = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.documento.tipoDocumento = "PR";

        if (this.anexosDocumentoTemp.length > 0) {
          // let temp = {};

          this.novoDocumentoDTO.documentoDTO = this.documento;
          this.novoDocumentoDTO.anexosDocumento = this.anexosDocumentoTemp;
          // temp.anexosDocumento = JSON.stringify(this.anexosDocumentoTemp);

          // this.novoDocumentoDTO = temp;
        } else {
          this.novoDocumentoDTO.documentoDTO = this.documento;
        }

        //  this.novoDocumentoDTO.documentoDTO = this.documento;

        // this.novoDocumentoDTO.anexosDocumento = this.anexosDocumentoTemp;

        documentoService
          .criarDocumento(this.novoDocumentoDTO, this.listaCopiaDocimento, 0, true)
          .then((param) => {
            this.isSaving = false;
            EventBus.$emit("alteraAcaoConteudo", "EN");
            EventBus.$emit("abaAtiva", "EN");
            const message = "Documento criado com sucesso!!! ";
            Toast.fire(message.toString(), "", "success");
          })
          .catch((error) => {
            this.isSaving = false;
            Toast.fire(error.response.data.toString(), "", "error");
          });
      }
    },
    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_TIME_LONG_FORMAT);
      }
      return null;
    },
    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.documento[field] = dayjs(
          event.target.value,
          DATE_TIME_LONG_FORMAT
        );
      } else {
        this.documento[field] = null;
      }
    },
    cancelarNovoDocumento() {
      EventBus.$emit("alteraAcaoConteudo", "EN");
      EventBus.$emit("abaAtiva", "EN");
    },
    carregarAnexos(event) {
      let pdfValido = true;

      Array.from(event.target.files).forEach((file) => {
        if (file.type != "application/pdf") {
          pdfValido = false;
        }
      });
      if (pdfValido) {
        Array.from(event.target.files).forEach((file) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Use a regex to remove data url part
            const base64String = reader.result;
            // .replace('data:', '')
            // .replace(/^.+,/, '');

            let arquivoAnexo = {};

            arquivoAnexo.id = Math.floor(Date.now() * Math.random()).toString(
              36
            );
            arquivoAnexo.arquivo = base64String;

            this.anexosDocumentoTemp.push(arquivoAnexo);
            // this.anexosDocumentoTemp.id = Math.floor(Date.now() * Math.random()).toString(36);

            // console.log(base64String);
            // Logs wL2dvYWwgbW9yZ...
          };

          reader.readAsDataURL(file);

          // this.anexosDocumentoTemp.push(file);
          // this.anexosDocumento.push(file);
        });
      } else {
        this.anexoInvalido = true;
      }

      // console.log(this.anexosDocumentoTemp);
    },
    calaculaTamanhoArquivo(value) {
      let const_term = 1024;
      let retorno = (value / const_term ** 2).toFixed(1);
      if (retorno < 1) {
        return (value / const_term).toFixed(3) + " KB";
      }
      return retorno + " MB";
    },
    removeLinhaAnexo(linhaAnexo) {
      this.anexosDocumentoTemp = this.anexosDocumentoTemp.filter(
        (item) => item.id !== linhaAnexo
      );
    },
    carregaSetores: function () {
      const listaSetores = api.buscarSetor().then((response) => {
        this.setores = response.data;
      });
    },
    carregaFuncionario(event) {
      if (event != undefined) {
        const listaFuncionario = api
          .buscarFuncionariosetor(event.id)
          .then((response) => {
            this.funcionarios =
              converteFuncionario.funcionarioDtoToFuncionarioArray(
                response.data
              );
          });
      }
    }
  },
  mounted() {
    this.carregaSetores();
  },
};
</script>
