<template>
  <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Férias Servidor" />
    <conteudo v-role="'CADASTRO_PESSOA' && 'ADMINISTRACAO'">
      <!-- <ModalConsultaPessoa v-if="this.$route.name.includes('cadastrofuncionario') ? false : true"
        v-show="isModalVisible" @closeModalCadastro="closeModal" /> -->
      <!-- <div class="modal-backdrop fade show" v-if="this.$route.name.includes('cadastrofuncionario') ? false : true"
        v-show="isModalVisible"></div> -->
      <TabsFerias />
    </conteudo>
  </section>
</template>

<script>
import { EventBus } from "@/main";
import TabsFerias from "@/components/rh/ferias/TabsFerias.vue";

export default {
  components: { TabsFerias },
  created() {
    
  },

  data() {
    return {
      isModalVisible: false,
      
    };
  },
  mounted() {},
   
  methods: {
    
  },
};
</script>
