import axios from "axios";
import authHeader from "@/service/auth/auth-header";
import store from "@/store/index.js";
import instance from './axiosConfig'
import { ApiGateway } from "@/main";
// const ApiGateway = "http://localhost:9000"
class CadastroPessoaService {
  constructor() {
    this.usuarioLogado =
      store.state.auth.user == null ? null : store.state.auth.user.usuario;
  }
  consultarExistenciaPessoaByParameters(nome, mae, dataNascimento) {
    return instance.get("/pessoa-service/api/pessoas/busca-pessoa-chave-composta?nome="+nome+"&mae="+mae+"&dataNascimento="+dataNascimento)
  }

  buscarEmailedicao(IdEmail) {
    return instance.get("/pessoa-service/api/emails/" + IdEmail);
  }
  buscarDocumentoEdicao(IdDocumento) {
    return instance.get("/pessoa-service/api/documento/" + IdDocumento);
  }
  buscarTelefoneEdicao(IdTelefone) {
    return instance.get("/pessoa-service/api/telefones/" + IdTelefone);
  }
  buscarEnderecoEdicao(IdEndereco) {
    return instance.get("/pessoa-service/api/enderecos/" + IdEndereco);
  }
  editarEmail(entity) {
    return instance.patch("/pessoa-service/api/emails/" + entity.id, entity);
  }
  editarEndereco(entity) {
    return instance.put("/pessoa-service/api/enderecos/" + entity.id, entity);
  }
  editarTelefone(entity) {
    return instance.patch("/pessoa-service/api/telefones/" + entity.id, entity);
  }
  editarDocumento(entity) {
    return instance.patch("/pessoa-service/api/documento/" + entity.id, entity);
  }

  buscarDocumentosPessoa(IdPessoa) {
    return instance.get("/pessoa-service/api/documentos-pessoa/"+IdPessoa);
  }
  buscarEmailsPessoa(IdPessoa) {
    return instance.get("/pessoa-service/api/emails");
  }
  buscarEnderecosPessoa(IdPessoa) {
    return instance.get("/pessoa-service/api/enderecos");
  }

  removerEmail(IdEmail) {
    return instance.delete("/pessoa-service/api/emails/" + IdEmail);
  }
  removerDocumento(IdDocumento) {
    return instance.delete("/pessoa-service/api/documento/" + IdDocumento);
  }
  removerEndereco(IdEndereco) {
    return instance.delete("/pessoa-service/api/enderecos/" + IdEndereco);
  }
  removerTelefone(IdTelefone) {
    return instance.delete("/pessoa-service/api/telefones/" + IdTelefone);
  }

  buscarResumoPessoa = async (params) => {
    let response = await instance.get("/pessoa-service/api/pessoas/busca-resumo", {
      params: params,
    });

    return response;
  };

  buscarPessoaCompletaPorId(id){
    return instance.get(`/pessoa-service/api/pessoas/${id}/com-dados`);
  }

  // buscarPessoaCompletaPorId = async (id) => {
  //   let response = await instance.get(
  //     `/pessoa-service/api/pessoas/${id}/com-dados`,
  //   );
  //   let result = response.data;

  //   return result;
  // };

  buscarPessoaCompletaPorIdSemPromisse = (id) => {
   return instance.get(
      `/pessoa-service/api/pessoas/${id}/com-dados`,
    );

  };

  buscarPessoaPorId(id) {
    return instance.get(`/dados-service/api/pessoas/${id}`);
  }


  cadastrarTelefone(entity) {
    return instance.post("/pessoa-service/api/telefones/pessoas", entity);
  }

  cadastrarEmail(entity) {
    return instance.post("/pessoa-service/api/emails/pessoas", entity);
  }

  cadastrarDocumento(entity) {
    return instance.post("/pessoa-service/api/documento", entity);
  }

  cadastrarEndereco(entity) {
    return instance.post("/pessoa-service/api/pessoas/enderecos", entity);
  }

  buscarTelefonesPessoa(IdPessoa) {
    return instance.get("/pessoa-service/api/telefones", { headers: authHeader() });
  }

  atualizarPessoaDadosBasicos(id, pessoa) {
    return instance.patch("/pessoa-service/api/pessoas/" + id, pessoa);
  }
  inserirCadastrante(pessoa) {
    pessoa.registrante = {id: this.usuarioLogado.idFuncionario}
  }
  cadastrarPessoaDadosBasicos(pessoa) {
    this.inserirCadastrante(pessoa);
    return instance.post("/pessoa-service/api/pessoas", pessoa);
  }

  buscarPessoaCompletaPorCPF(cpf) {
    return instance.get(`/pessoa-service/api/pessoas?cpf=${cpf}`);
  }

  buscarPessoaVisita(filtro) {
    return instance.get(`/pessoa-service/api/pessoas/consulta-visita?tamanho=12&cpf=${filtro.cpf}&nome=${filtro.nome}`);
  }
}

export default new CadastroPessoaService();
