<template>
  <div>
    <!-- <button id="emitirAtestado" @click="emitirAtestado()">
        Emitir Atestado
      </button> -->
    <iframe
      hidden
      class="preview-pane"
      style="width: 1000px; height: 95vh; margin: 0px; padding: 0px"
      :src="pdfSrc + '#zoom=70'"
    >
    </iframe>

    <!-- <img
      src="@/assets/images/logo-ma-small.png"
      style="display: none"
      ref="brasaoImg"
      class="brasaoImg"
    /> -->
  </div>
</template>
  
  <script>
import jsPDF from "jspdf";
// import { dados, mussumIpsum } from "@/components/service.js";

export default {
  name: "PdfComponent",
  components: {},
  props: {
    boletimDados: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      pdfSrc: "data:application/pdf;base64,",
      logoBase64: "",
      value: this.values,
      size: 300,
    };
  },
  setup() {},
  methods: {
    montarParagrafo(textoDados) {
      // console.log(textoDados);
      let dataDiaria = new Date(textoDados.data);
      dataDiaria = this.formataData(
        dataDiaria.getDate(),
        dataDiaria.getMonth() + 1,
        dataDiaria.getFullYear()
      );

      let dataInicio = new Date(textoDados.dataInicio);
      dataInicio = this.formataData(
        dataInicio.getDate(),
        dataInicio.getMonth() + 1,
        dataInicio.getFullYear()
      );
      let dataFim = new Date(textoDados.dataFim);
      dataFim = this.formataData(
        dataFim.getDate(),
        dataFim.getMonth() + 1,
        dataFim.getFullYear()
      );

      let texto = `REQUISIÇÃO DE DIÁRIAS N.º ${
        textoDados.id
      } SSP de ${dataDiaria}, PROCESSO Nº ${textoDados.processo}, Conceder a ${
        textoDados.funcionario.pessoaCorrespondente.nome
      }, ${textoDados.funcionario.cargo.descricao.toUpperCase()}, Matrícula nº ${
        textoDados.funcionario.matricula
      }, ${
        textoDados.quantidade
      },0 diárias, deslocando-se de ${textoDados.origem.descricao.toUpperCase()}/${
        textoDados.origem.estado.uf
      } até ${textoDados.destino.descricao.toUpperCase()}/${
        textoDados.destino.estado.uf
      }, no período de ${dataInicio} a ${dataFim}`;
      texto += "\n\n";

      return texto;
    },
    async emitirPdf(boletimDados, formDados) {
      console.log(formDados);
      let dados = "";
      await boletimDados.forEach((item) => {
        dados += this.montarParagrafo(item);
      });

      let doc = new jsPDF("p", "mm", "a4");
      const pageWidth = doc.internal.pageSize.getWidth();
      const center = pageWidth / 2;
      let colunaAtual = 1;
      //   const pageHeight = doc.internal.pageSize.getHeight();

      let textoDados = dados;
      console.log(textoDados);

      const image = new Image();
      //   image.src = this.$refs.brasaoImg.src;
      image.src = require("@/assets/logo-ma-small.png");
      image.width = 20;
      image.height = 20;
      let x = (pageWidth - image.width) / 2;
      doc.addImage(image, "PNG", x, 10, 20, 20, "center");

      console.log(1);
      doc.setTextColor(0);
      doc.setFont("times", "bold");
      doc.setFontSize(12);
      doc.text("Estado do Maranhão", center, 35, null, null, "center");
      //   console.log(doc.getFontList());
      doc.setFont("times", "normal");
      doc.setFontSize(9);
      doc.text(
        "Secretaria de Estado da Segurança Pública",
        center,
        39,
        null,
        null,
        "center"
      );

      doc.text(
        "Av. dos Franceses, s/n, Bairro Vila Palmeira, CEP 65036-283, São Luís-MA",
        center,
        43,
        null,
        null,
        "center"
      );
      doc.text("Telefone: (98) 3214 3700", center, 47, null, null, "center");

      doc.setFont("times", "bold");
      doc.text(
        "BOLETIM INFORMATIVO DE DIÁRIAS PELO DECRETO",
        center,
        60,
        null,
        null,
        "center"
      );

      console.log(2);

      doc.setFont("times", "bold");
      doc.setFontSize(14);
      let mes = formDados.mes;
      let ano = formDados.ano;
      doc.text(`Mês: ${mes} Ano: ${ano}`, center, 66, null, null, "center");

      //   doc.line(20, 270, 190, 270);
      console.log(3);

      let lines = doc
        .setFontSize(10)
        .setFont("times", "normal")
        .splitTextToSize(textoDados, 75);
      let startY = 75;
      let lastY;
      let startX1 = 20;
      let startX2 = 115;
      let aux = 0;
      await lines.forEach((element) => {
        lastY = startY + aux * 4;

        if (colunaAtual === 1) {
          this.justifyLine(doc, element, startX1, lastY, 75);
        } else {
          this.justifyLine(doc, element, startX2, lastY, 75);
        }
        aux++;

        if (lastY > 265) {
          if (colunaAtual === 1) {
            aux = 0;
            colunaAtual = 2;
          } else {
            startY = 30;
            colunaAtual = 1;
            aux = 0;
            this.inserirLinhasAssinatura(doc, formDados);
            doc.addPage();
            this.inseriPaginas(doc, center);
          }
        }
      });
      console.log(4);

      this.inserirLinhasAssinatura(doc, formDados);

      // var out = doc.output();
      // var url = "data:application/pdf;base64," + btoa(out);
      // this.pdfSrc = url;
      doc.save(`BOLETIM`);
    },
    inseriPaginas(doc, center) {
      // console.log(doc.internal.getCurrentPageInfo());
      let currentPage = doc.getCurrentPageInfo().pageNumber;
      doc.text(
        `(Fls. ${currentPage} do Boletim)`,
        center,
        15,
        null,
        null,
        "center"
      );
    },

    inserirLinhasAssinatura(doc, formDados) {
      let x;
      doc.setLineWidth(0.5);
      doc.line(20, 280, 95, 280);
      doc.line(115, 280, 190, 280);
      doc.setFontSize(9);
      doc.setFont("times", "bold");
      console.log(formDados);
      let text = formDados.assinatura1;
      let textWidth =
        (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (115 - textWidth) / 2;
      doc.text(text, x, 285);
      text = formDados.cargo1;
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (115 - textWidth) / 2;
      doc.text(text, x, 290);

      text = formDados.assinatura2;
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (305 - textWidth) / 2;
      doc.text(text, x, 285);
      text = formDados.cargo2;
      textWidth = (doc.getStringUnitWidth(text) * 9) / doc.internal.scaleFactor;
      x = (305 - textWidth) / 2;
      doc.text(text, x, 290);

      doc.setFont("times", "normal");
    },
    justifyLine(doc, text, x, y, maxWidth) {
      const words = text.split(" ");
      const numWords = words.length;
      const totalWidth = doc.getTextWidth(text);
      const spaceWidth = (maxWidth - totalWidth) / (numWords - 1);

      let currentX = x;
      words.forEach((word, index) => {
        doc.text(word, currentX, y);
        currentX += doc.getTextWidth(word) + spaceWidth;

        if (index < numWords - 1) {
          doc.text(" ", currentX, y); // Adicionar espaço entre as palavras
          currentX += doc.getTextWidth(" ");
        }
      });
    },

    getMesPorExtenso(mesInteger) {
      const mesDictionary = {
        1: "Janeiro",
        2: "Fevereiro",
        3: "Março",
        4: "Abril",
        5: "Maio",
        6: "Junho",
        7: "Julho",
        8: "Agosto",
        9: "Setembro",
        10: "Outubro",
        11: "Novembro",
        12: "Dezembro",
      };
      return mesDictionary[mesInteger];
    },
    formataData(dia, mes, ano) {
      if (dia < 10) {
        dia = `0${dia}`;
      }
      if (mes < 10) {
        mes = `0${mes}`;
      }
      return `${dia}/${mes}/${ano}`;
    },
  },
  mounted() {
    // this.emitirPdf();
    this.$root.$on("emitirPdfDiarias", this.emitirPdf);
  },
};
</script>
  


