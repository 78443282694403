<template>
    <div>
        <loading :active="loaderActive" :is-full-page="fullPage" />
        <div class="row">
            <div class="form-group col-sm">
                <label for="diariaservidor">Matrícula Servidor</label>
                <input type="text" class="form-control form-control-border border-width-2" id="diariaservidor"
                    placeholder="xxx.xxx.xxx" disabled v-model="diariaLancamento.funcionario.matricula" :class="{
            'is-invalid':
                isSaving && $v.diariaLancamento.funcionario.matricula.$error,
        }" />
            </div>
            <div class="form-group col-sm">
                <label for="diariaservidor">ID Servidor</label>
                <input type="text" class="form-control form-control-border border-width-2" id="diariaservidor"
                    placeholder="xxx.xxx.xxx" disabled v-model="diariaLancamento.funcionario.novaMatricula" :class="{
            'is-invalid':
                isSaving && $v.diariaLancamento.funcionario.novaMatricula.$error,
        }" />
            </div>
            <div class="form-group col-lg">
                <label for="diariaservidor">Nome do Servidor</label>
                <input type="text" disabled class="form-control form-control-border border-width-2" id="diariaservidor"
                    v-model="diariaLancamento.funcionario.pessoaCorrespondente.nome" :class="{
            'is-invalid':
                isSaving &&
                $v.diariaLancamento.funcionario.pessoaCorrespondente.$error,
        }" />
            </div>
            <button @click="buscarServidor('servidor')" v-b-modal.modal-1
                class="btn mt-4 my-3 text-color-principal background-principal">
                <i class="fas fa-search"></i>
            </button>
        </div>
        <div class="row">
            <div class="form-group col-sm">
                <label for="diariaproponente">Matrícula Proponente</label>
                <input type="text" class="form-control form-control-border border-width-2" id="diariaproponente"
                    placeholder="xxx.xxx.xxx" disabled v-model="diariaLancamento.proponenteId.matricula" :class="{
            'is-invalid':
                isSaving && $v.diariaLancamento.proponenteId.matricula.$error,
        }" />
            </div>
            <div class="form-group col-sm">
                <label for="diariaproponente">ID Proponente</label>
                <input type="text" class="form-control form-control-border border-width-2" id="diariaproponente"
                    placeholder="xxx.xxx.xxx" disabled v-model="diariaLancamento.proponenteId.novaMatricula" :class="{
            'is-invalid':
                isSaving && $v.diariaLancamento.proponenteId.novaMatricula.$error,
        }" />
            </div>
            <div class="form-group col-lg">
                <label for="diariaproponente">Nome do Proponente</label>
                <input type="text" v-model="diariaLancamento.proponenteId.pessoaCorrespondente.nome" disabled
                    class="form-control form-control-border border-width-2" id="diariaproponente" />
            </div>
            <button @click="buscarServidor('proponente')" v-b-modal.modal-1
                class="btn mt-4 my-3 text-color-principal background-principal">
                <i class="fas fa-search"></i>
            </button>
        </div>

        <div class="row">
            <div class="form-group col-lg-3">
                <label for="data">Data</label>
                <input type="date" class="form-control form-control-border border-width-2" id="data"
                    placeholder="dd/mm/aaaa" v-model="diariaLancamento.data" />
            </div>
            <div class="form-group col-lg-3">
                <label for="projeto">Projeto</label>
                <v-select class="border-v-select mt-1" id="projeto" v-model="diariaLancamento.projeto"
                    :reduce="(projetos) => projetos.id" :options="projetos" label="descricao">
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
            </div>
            <div class="form-group col-lg-3">
                <label for="noprocesso">N° Processo</label>
                <input type="text" class="form-control form-control-border border-width-2" id="noprocesso"
                    @change="buscarProcesso()" v-model="diariaLancamento.processo" :class="{
            'is-invalid': isSaving && $v.diariaLancamento.noProcesso.$error,
        }" />
            </div>
            <div class="form-group col-lg-3">
                <label class="form-control-label" for="dataProcesso">Data Processo</label>
                <div class="d-flex">
                    <input type="date" class="form-control form-control-border border-width-2" id="dataProcesso"
                        placeholder="dd/mm/aaaa" v-model="diariaLancamento.dataProcesso" :class="{
            'is-invalid': isSaving && $v.diariaLancamento.dataProcesso.$error,
        }" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-lg-3">
                <label class="form-control-label" for="dataInicio">Data Início</label>
                <div class="d-flex">
                    <input type="date" class="form-control form-control-border border-width-2" id="dataInicio"
                        @change="calcularQuantidade()" placeholder="dd/mm/aaaa" v-model="diariaLancamento.dataInicio"
                        :class="{
            'is-invalid': isSaving && $v.diariaLancamento.dataInicio.$error,
        }" />
                </div>
            </div>
            <div class="form-group col-lg-3">
                <label class="form-control-label" for="dataFim">Data Fim</label>
                <div class="d-flex">
                    <input type="date" class="form-control form-control-border border-width-2" id="dataFim"
                        @change="calcularQuantidade()" placeholder="dd/mm/aaaa" v-model="diariaLancamento.dataFim"
                        :class="{
            'is-invalid': isSaving && $v.diariaLancamento.dataFim.$error,
        }" />
                </div>
            </div>

            <div class="form-group col-lg-3">
                <label for="origem">Origem</label>
                <v-select class="border-v-select mt-1" id="origem" v-model="diariaLancamento.origem.id"
                    :options="cidades" :reduce="(cidades) => cidades.id" label="descricao">
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
            </div>
            <div class="form-group col-lg-3">
                <label for="destino">Destino</label>
                <v-select class="border-v-select mt-1" id="destino" v-model="diariaLancamento.destino.id"
                    :options="cidades" :reduce="(cidades) => cidades.id" label="descricao"
                    @input="calcularQuantidade">
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
            </div>
        </div>

        <div class="row">
            <!-- 			<div class="form-group col-lg-3">
                <label for="tipo">Tipo</label>
                <v-select
                    class="border-v-select"
                    id="tipo"
                    v-model="diariaLancamento.tipo"
                    :options="['Decreto N° 28.862','Antigo']"
                    label="tipo"
				>
                <span slot="no-options">Desculpe, sem opções no momento.</span>
                    </v-select>
            </div> -->
        </div>

        <div class="row">
            <div class="form-group col-lg-1">
                <label for="quantidade">Quantidade</label>
                <input type="number" class="form-control form-control-border border-width-2" id="quantidade"
                    v-model="diariaLancamento.quantidade" @change="calcularValorTotal()" disabled :class="{
            'is-invalid': isSaving && $v.diariaLancamento.quantidade.$error,
        }" />
            </div>
            <div class="form-group col-lg-2">
                <label for="valorunitario">Valor Unitário</label>
                <input type="number" class="form-control form-control-border border-width-2" id="valorunitario"
                    v-model="diariaLancamento.unitario" @change="calcularValorTotal()" disabled :class="{
            'is-invalid': isSaving && $v.diariaLancamento.unitario.$error,
        }" />
            </div>
            <div class="form-group col-lg-2">
                <label for="valortotal">Valor Total</label>
                <input type="number" class="form-control form-control-border border-width-2" id="valortotal"
                    v-model="diariaLancamento.total" disabled :class="{
            'is-invalid': isSaving && $v.diariaLancamento.total.$error,
        }" />
            </div>

            <div class="form-group col-4">
                <label for="ordenador">Ordenador</label>
                <v-select class="border-v-select mt-1" id="ordenador" v-model="diariaLancamento.ordenadorDespesaId.id"
                    :options="ordenador" :reduce="(ordenador) => ordenador.id" label="nome">
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
                </v-select>
            </div>
            <div class="form-group col-lg-2">
                <label>Limite de Diárias:</label>
                <p class="form-control form-control-border border-width-2">
                    {{ qtdDiarias }} de {{ limiteDiarias }}
                </p>
            </div>
            <div class="form-check col-1 my-3">
                <div class="">
                    <input class="form-check-input" type="checkbox" id="meiadiaria"
                        v-model="diariaLancamento.meiaDiaria" @change="calcularValorTotal()" />
                    <label class="form-control-label" for="meiadiaria">
                        Meia Diária
                    </label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-6">
                <label for="missao">Missão</label>
                <textarea style="resize: none" type="text" class="form-control" id="missao" rows="10"
                    v-model="diariaLancamento.missao" :class="{
            'is-invalid': isSaving && $v.diariaLancamento.missao.$error,
        }"></textarea>
            </div>
            <div class="form-group col-6">
                <label for="informacoesadicionais">Informações Adicionais</label>
                <textarea style="resize: none" type="text" class="form-control" id="informacoesadicionais" rows="10"
                    v-model="diariaLancamento.adicionais" :class="{
            'is-invalid': isSaving && $v.diariaLancamento.adicionais.$error,
        }"></textarea>
            </div>
        </div>

        <div class="card-footer">
            <button v-if="diariaLancamento.id" @click="ImprimirLancamento(diariaLancamento.id)"
                class="btn text-color-principal background-principal ml-1 float-right">
                <i class="fa-solid fa-print"></i> Imprimir
            </button>
            <button @click="SalvarLancamento" class="btn text-color-principal background-principal ml-2 float-right">
                <i class="fa-solid fa-floppy-disk"></i> Salvar
            </button>
            <button v-if!="diariaLancamento" @click.prevent="LimparLancamento" type="reset"
                class="btn btn-default float-left">
                <i class="fa fa-ban"></i> Limpar
            </button>
        </div>
    </div>
</template>

<script>
import { DATE_TIME_LONG_FORMAT, DATE_FORMAT_LOCAL_DATA, } from "@/util/filter";
import dayjs from "dayjs";
import ConsultaListasService from "@/service/ConsultaListasService";
import RhDiariasService from '@/service/RhDiariasService';
import { EventBus } from '@/main';
import consultaFuncionarioService from "@/service/ConsultaFuncionarioService";
import ConsultaServidor from "./ConsultaServidor.vue";
import rhAnotacao from "@/service/RHAnotacaoService"
import rhService from "@/service/RHService";
import Loading from "vue-loading-overlay";
import { mapState } from 'vuex';

export default {
    name: "LancamentosAlteracoes",
    components: {
        Loading,
     

    },
    computed: {
        ...mapState(['alternarTabsDiaria']),

        // alternarTabsDiaria() {

        // console.log("teste");

        // this.funcionario = this.funcionarioUsuario;

        // if (alternarTabsDiaria == true) {
        //     return this.LimparLancamento();
        // }




        // },
    },

    watch: {
        alternarTabsDiaria(valorAtual, valorAnterior) {
            if (valorAtual) {
                this.LimparLancamento();
            }

        },
    },
    created() {
        // this.preencherListasObj({
        //     cidades: "cidade",
        // });

        this.preenchecidades();

        this.calcularValorTotal();

        this.listarOrdenadores();

        this.listarGrupos();

        EventBus.$on("mostrarDiaria", (id) => {
            this.getDiariaId(id)
        })

        EventBus.$on("limparEdicao", () => {
            this.LimparLancamento()
        })

        EventBus.$on("escolherServidor", (listener) => {
            if (this.tipoServidor == "servidor") {
                this.diariaLancamento.funcionario.id = listener.id
                this.diariaLancamento.funcionario.novaMatricula = listener.novaMatricula
                this.diariaLancamento.funcionario.ativo = listener.ativo
                this.diariaLancamento.funcionario.matricula = listener.matricula
                this.diariaLancamento.funcionario.pessoaCorrespondente.nome = listener.nome
                this.buscarFuncionario()

            } else if (this.tipoServidor == "proponente") {
                this.diariaLancamento.proponenteId.id = listener.id
                this.diariaLancamento.proponenteId.novaMatricula = listener.novaMatricula
                this.diariaLancamento.proponenteId.ativo = listener.ativo
                this.diariaLancamento.proponenteId.matricula = listener.matricula
                this.diariaLancamento.proponenteId.pessoaCorrespondente.nome = listener.nome
            }


        })

    },

    data() {
        return {
            loaderActive: false,
            fullPage: false,
            data: {},
            diariaLancamento: {

                funcionario: {
                    pessoaCorrespondente: {
                        id: null,
                        nome: null,
                    },
                    id: null,
                    ativo: null,
                    matricula: null,
                    novaMatricula: null,
                },
                cargoComissionado: null,
                proponenteId: {
                    pessoaCorrespondente: {
                        id: null,
                        nome: null,
                    },
                    id: null,
                    ativo: null,
                    matricula: null,
                    novaMatricula: null,
                },
                data: null,
                origem: {
                },
                destino: {
                },
                dataInicio: null,
                dataFim: null,
                projeto: 1,
                quantidade: null,
                unitario: null,
                total: null,
                missao: null,
                adicionais: null,
                processo: null,
                dataProcesso: null,
                status: "ABERTO",
                usuario: null,
                meiaDiaria: false,
                ordenadorDespesaId: {
                    id: null,
                },

            },
            projetos: [
                { id: 1, descricao: "P/A" },
            ],
            limparDiaria: false,
            ordenador: [],
            cidades: [

            ],
            submitted: false,
            isSaving: false,
            tipoServidor: null,
            grupo: null,
            grupos: [],
            qtdDiarias: null,
            limiteDiarias: null,
            anotacoes: [],
            ferias: [],
            //idFuncionario:{}
        };
    },

    methods: {
        showLoader() {
            this.loaderActive = true;
            this.fullPage = true;
        },
        hideLoader() {
            this.loaderActive = false;
            this.fullPage = false;
        },
        buscarServidor(tipo) {
            this.tipoServidor = tipo
        },

        buscarFuncionario() {
            RhDiariasService.countDiariaByFuncionario(this.diariaLancamento.funcionario.id).then((resp) => {
                this.qtdDiarias = resp.data
            })
            consultaFuncionarioService.buscarFuncionarioId(this.diariaLancamento.funcionario.id)
                .then((resp) => {
                    this.diariaLancamento.funcionario.novaMatricula = resp.data.novaMatricula
                    this.grupo = resp.data.dadosAdicionais.bancoGrupo
                    if (resp.data.dadosAdicionais.limiteDiarias == null) {
                        this.limiteDiarias = 60
                    } else {
                        this.limiteDiarias = resp.data.dadosAdicionais.limiteDiarias
                    }
                    this.diariaLancamento.cargoComissionado = resp.data.dadosAdicionais.cargoComissionado
                })
            if (this.grupo < 1 || this.grupo > this.grupos.length) {
                this.diariaLancamento.unitario = 0
            } else {
                this.diariaLancamento.unitario = this.grupos[this.grupo - 1].valorEstado
            }
            this.calcularValorTotal()
            if (this.limiteDiarias == null) {
                this.limiteDiarias = 60
            }

            const funcionarioId = this.diariaLancamento.funcionario.id;
            this.buscarAnotacao(funcionarioId);

//const idFuncionario = this.diariaLancamento.funcionario.id;
           // this.getFeriasFuncionario(idFuncionario)

        },
        getDiariaId(id) {
            RhDiariasService.getDiaria(id)
                .then((response) =>
                    this.diariaLancamento = response.data
                )
        },
        // preencherListasObj(obj) {
        //     Object.keys(obj).forEach((lista) => {
        //         ConsultaListasService.buscarListaPorNomeTabela(obj[lista])
        //             .then((response) => {
        //                 this[lista] = response.data;
        //             })
        //             .catch((e) => alert("ERRO: " + e.message));
        //     });
        // },

        preenchecidades() {
            RhDiariasService.getAllCidades()
                .then((response) =>{
                    this.cidades = response.data;
                })
        },


        listarGrupos() {
            RhDiariasService.getAllDiariaGrupo().then((resp) => {
                this.grupos = resp.data
            })
        },

        listarOrdenadores() {
            RhDiariasService.getAllOrdenadorDespesa().then((response) => {
            this.ordenador = response.data.sort((a, b) => (a.nome > b.nome) ? 1 : -1);
            });
        },

        LimparLancamento() {
            this.diariaLancamento = {
                funcionario: {
                    pessoaCorrespondente: {
                        id: null,
                        nome: null,
                    },
                    id: null,
                    ativo: null,
                    matricula: null,
                },
                cargoComissionado: null,
                proponenteId: {
                    pessoaCorrespondente: {
                        id: null,
                        nome: null,
                    },
                    id: null,
                    ativo: null,
                    matricula: null,
                },
                data: null,
                origem: {
                    id: null,
                    descricao: null
                },
                destino: {
                    id: 0,
                    descricao: null
                },
                dataInicio: null,
                dataFim: null,
                projeto: null,
                quantidade: null,
                unitario: null,
                total: null,
                missao: null,
                adicionais: null,
                processo: null,
                dataProcesso: null,
                status: "ABERTO",
                usuario: null,
                meiaDiaria: false,
                ordenadorDespesaId: {
                    id: null,
                },
                qtdDiarias: null,
                limiteDiarias: null
            }
            this.$store.commit("setAlternarTabsDiaria", false);
        },

        buscarAnotacao(funcionarioId) {
            rhAnotacao.anotacaoFuncionarioId(funcionarioId).then((resp) => {
                this.anotacoes = resp.data;
                console.log(resp.data)
            })
        },

        // getFeriasFuncionario(idFuncionario) {
        //     rhService.getFeriasFuncionario(idFuncionario).then((resp) => {
        //         this.ferias = resp.data;
        //         console.log(resp.data)
        //     });
        // },


        calcularValorTotal() {
            if (this.diariaLancamento.meiaDiaria) {
                this.diariaLancamento.total = (this.diariaLancamento.quantidade * this.diariaLancamento.unitario) * 0.5
            } else {
                this.diariaLancamento.total = this.diariaLancamento.quantidade * this.diariaLancamento.unitario
            }
        },

        calcularQuantidade() {

          let destino = this.cidades.find(cidade => cidade.id === this.diariaLancamento.destino.id);


            if (destino.estado != 10){

                const milissegundosPorDia = 24 * 60 * 60 * 1000;
                const diferencaEmMilissegundos = Math.abs(Date.parse(this.diariaLancamento.dataFim) - Date.parse(this.diariaLancamento.dataInicio));
                const diferencaEmDias = Math.floor(diferencaEmMilissegundos / milissegundosPorDia);
                this.diariaLancamento.quantidade = diferencaEmDias + 1

                this.diariaLancamento.unitario = this.grupos[this.grupo - 1].valorForaEstado

            }else{

                const milissegundosPorDia = 24 * 60 * 60 * 1000;
                const diferencaEmMilissegundos = Math.abs(Date.parse(this.diariaLancamento.dataFim) - Date.parse(this.diariaLancamento.dataInicio));
                const diferencaEmDias = Math.floor(diferencaEmMilissegundos / milissegundosPorDia);
                this.diariaLancamento.quantidade = diferencaEmDias + 1

                if (this.grupo < 1 || this.grupo > this.grupos.length) {
                    this.diariaLancamento.unitario = 0
                } else {
                    this.diariaLancamento.unitario = this.grupos[this.grupo - 1].valorEstado
                }
            }

            this.calcularValorTotal();

        },

        SalvarLancamento() {

            this.showLoader();

            if ('id' in this.diariaLancamento) {
    RhDiariasService.editarDiaria(this.diariaLancamento.id, this.diariaLancamento)
        .then((r) => {
            this.diariaLancamento = r.data;
            // this.hideLoader();

            this.ImprimirLancamento(r.data.id);
            this.hideLoader();

            Toast.fire("Edição realizada", "", "success");
            // this.LimparLancamento()
        }).catch(() => {
            this.hideLoader();
            Toast.fire("Falha ao editar", "", "error");
        });

} else {
    if (this.limiteDiarias == this.qtdDiarias) {
        this.hideLoader();
        Toast.fire("Limite de Diárias atingido ", "", "error");
    } else {
        this.diariaLancamento.usuario = this.$store.state.auth.user.usuario.idFuncionario;
        RhDiariasService.lancarDiaria(this.diariaLancamento)
            .then((resp) => {
                this.diariaLancamento = resp.data;
                this.ImprimirLancamento(resp.data.id);
                // this.hideLoader();
                Toast.fire("Cadastro realizado", "", "success");
                // this.LimparLancamento()
            }).catch(error => {
                this.hideLoader();
                Toast.fire(error.response.data, "", "error");
            });
    }
}

        },

        ImprimirLancamento(id) {

            this.showLoader();

            RhDiariasService.imprimirRd(id)
                .then((response) => {

                    const objUrl = URL.createObjectURL(response.data);
                    // this.pdfsrc = objUrl;
                    // const blob = response.blob();
                    const url = window.URL.createObjectURL(response.data);

                    const link = document.createElement('a');
                    link.href = url;

                    // Define o atributo "download" para o nome do arquivo desejado
                    link.download = 'Diaria-' + id;

                    // Adiciona o elemento <a> ao corpo do documento
                    document.body.appendChild(link);

                    // Simula o clique no link para iniciar o download
                    link.click();

                    // Remove o elemento <a> do corpo do documento
                    document.body.removeChild(link);

                    this.hideLoader();


                    // window.open(url);
                }).catch(() => {
                    this.hideLoader();
                    console.error('Erro ao gerar PDF:', error);
                });
        },

        convertDateTimeFromServer(date) {
            if (date && dayjs(date).isValid()) {
                return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
            }
            return null;
        },

        updateZonedDateTimeField(field, event) {
            if (event.target.value) {
                this.diariaLancamento[field] = dayjs(
                    event.target.value,
                    DATE_FORMAT_LOCAL_DATA
                );
            } else {
                this.diariaLancamento[field] = null;
            }
        },

        buscarProcesso() {

            RhDiariasService.getDiariaByProcesso(this.diariaLancamento.processo).then((resp) => {
                // this.diariaLancamento.proponenteId.novaMatricula = resp.data[0].proponenteId.novaMatricula
                // this.diariaLancamento.proponenteId.matricula = resp.data[0].proponenteId.matricula
                // this.diariaLancamento.proponenteId.pessoaCorrespondente.nome = resp.data[0].proponenteId.pessoaCorrespondente.nome
                // this.diariaLancamento.data = resp.data[0].data
                // this.diariaLancamento.projeto = resp.data[0].projeto
                // this.diariaLancamento.dataProcesso = resp.data[0].dataProcesso
                // this.diariaLancamento.dataInicio = resp.data[0].dataInicio
                // this.diariaLancamento.dataFim = resp.data[0].dataFim
                // this.diariaLancamento.origem.id = resp.data[0].origem.id
                // this.diariaLancamento.destino.id = resp.data[0].destino.id
                this.diariaLancamento.missao = resp.data[0].missao
                // this.diariaLancamento.adicionais = resp.data[0].adicionais
                // this.diariaLancamento.quantidade = resp.data[0].quantidade
                // this.diariaLancamento.ordenadorDespesaId.id = resp.data[0].ordenadorDespesaId.id
                // this.diariaLancamento.meiaDiaria = resp.data[0].meiaDiaria
                // this.diariaLamcamento.unitario = resp.data[0].unitario
            })

            //this.calcularValorTotal()
        }

    },


}
</script>
