<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="cadastro-orgao-id-contratacao">id Contratacao</label>
          <input type="text" class="form-control form-control-border" name="idContratacao"
            id="cadastro-orgao-id-contratacao" data-cy="idContratacao" :class="{
              'is-invalid': isSaving && $v.cadastroOrgao.idContratacao.$error,
            }" v-model.number="cadastroOrgao.idContratacao" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="cadastro-orgao-cpfCnpj">Cpf/Cnpj</label>
          <input type="text" class="form-control form-control-border" name="cpfCnpj" id="cadastro-orgao-cpfCnpj"
            data-cy="cpfCnpj" :class="{
              'is-invalid': isSaving && $v.cadastroOrgao.cpfCnpj.$error,
            }" v-model="cadastroOrgao.cpfCnpj" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="cadastro-orgao-numero">Número</label>
          <input type="text" class="form-control form-control-border" name="numero" id="cadastro-orgao-numero"
            data-cy="numero" :class="{
              'is-invalid': isSaving && $v.cadastroOrgao.numero.$error,
            }" v-model="cadastroOrgao.numero" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="cadastro-orgao-ano">Ano</label>
          <input type="number" class="form-control form-control-border" name="ano" id="cadastro-orgao-ano" data-cy="ano"
            :class="{
              'is-invalid': isSaving && $v.cadastroOrgao.ano.$error,
            }" v-model.number="cadastroOrgao.ano" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="form-control-label" for="cadastro-orgao-perfil">Perfil</label>
          <v-select class="border-v-select" id="cadastroOrgao-perfil" :class="{
            'is-invalid': isSaving && $v.cadastroOrgao.perfil.$error,
          }" data-cy="perfil" name="perfil" v-model="cadastroOrgao.perfil" :options="perfil"
            :reduce="perfil => perfil.id" label="descricao">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="float-right">
        <!--button class="btn btn-default mr-1">
          <i class="fas fa-pencil-alt"></i> Rascunho
        </button-->
        <button @click="salvarOrgao" class="btn text-color-principal background-principal ml-1">
          <i class="far fa-envelope"></i> Enviar
        </button>
         <button @click="limparOrgao" type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Limpar
      </button>
      </div>
      <!--button type="reset" class="btn btn-default">
        <i class="fas fa-times"></i> Cancelar
      </button-->
    </div>
    
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import { DATE_TIME_LONG_FORMAT, DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import dayjs from "dayjs";
import { EventBus } from "@/main";
import processosService from '@/service/ProcessosService';
import TabelaCadastroOrgao from "@/components/processos/TabelaCadastroOrgao.vue"

export default {
  name: "Ata-Registro",
  components:{TabelaCadastroOrgao},
  data() {
    return {
      data:{},
      cadastroOrgao: {},
      submitted: false,
      isSaving: false,
      setores: [],
      funcionarios: [],
      perfil: [
        { id: 'G', descricao: "Gerenciador" },
        { id: 'P', descricao: "Participante" },
        { id: 'NP', descricao: "Não Participante" },
      ],
    };
  },


  validations: {
    cadastroOrgao: {
      numero: {},
      ano: {},
      perfil: {},
      cpfCnpj: {},
      idContratacao: {},
      //idContratacao: { required }
    },
  },

  created() {
      EventBus.$on("EnvioCadastroOrgao",(listener)=>{
        this.getCadastroOrgao(listener);
        
      }),

      EventBus.$on("ApagarCamposCadastroOrgao",()=>{
        this.cadastroOrgao = {};
      })

    },

  methods: {
    // updateZonedDateTimeField(field, event) {
    //   if (event.target.value) {
    //     this.documento[field] = dayjs(
    //       event.target.value,
    //       DATE_TIME_LONG_FORMAT
    //     );
    //   } else {
    //     this.documento[field] = null;
    //   }
    // },
    mudarForm() {

      // console.log("sjdfnosdf");

    },

    
  salvarOrgao(){
    console.log("SALVAR CADASTRO ORGAO")
    this.isSaving = true;
    this.$v.$touch();
 
      if (this.$refs["cadastro-orgao"]!= undefined){
        this.cadastroOrgao = Object.assign(
          {},
          this.cadastroOrgao,
          this.$refs["cadastro-orgao"].cadastroOrgao
        );
 
        this.$refs["cadastro-orgao"].isSaving = this.isSaving;
        if (this.$v.$ivalid || this.$refs["cadastro-orgao"].$v.$ivalid){
          return;
        } else{
          if (this.$v.$ivalid){
            return;
 
 
          }
        }
 
      }
     
      processosService.criarCadastroOrgao(this.cadastroOrgao)
      .then((response)=> {
        this.cadastroOrgao= response.data;
        Toast.fire("Cadastrado realizado", "", "success");
        this.cadastroOrgao={};
 
      })

  },

  limparOrgao(){
    this.cadastroOrgao={};
  },

  getCadastroOrgao(id){
    processosService.getCadastroOrgao(id).then((resp)=>{
      this.cadastroOrgao= resp.data;
    })
  },

   
    convertDateTimeFromServer(date) {
      if (date && dayjs(date).isValid()) {
        return dayjs(date).format(DATE_FORMAT_LOCAL_DATA);
      }
      return null;
    },

    updateZonedDateTimeField(field, event) {
      if (event.target.value) {
        this.documento[field] = dayjs(
          event.target.value,
          DATE_FORMAT_LOCAL_DATA
        );
      } else {
        this.documento[field] = null;
      }
    },
  },
  // mounted() {
  //   this.carregaSetores();
  //   this.carregaFuncionario();
  // }
};
</script>
