 <template>
  <div v-if="mostrarCards" class="row px-5 d-flex justify-content-around table-responsive max-height-content-detail">
    <div :key="idBo" v-for="(bos, idBo) in bos"
      class="card card-item bg-light col-sm-12 col-md-12 m-10 rounded-12 p-0 ">

      <a href="#" @click="emitirClickBo(bos.idBo)">
        <div class=" text-center">
          <p class="header background-principal text-color-principal card-item-result">
            {{ bos.numero }}
          </p>
        </div>
        <div class="row">

          <div class="col-12 mx-0 my-2 p-0">
            <div class="row">
              <ul class="col-sm-12 col-md-3 mb-0 fa-ul text-muted">
                <li class="medium"><b>Data: </b> {{ bos.dataFato }}</li>
                <li class="medium"> <b>Descricao: </b> {{ bos.descricao }}</li>
              </ul>
              <ul class="col-sm-12 col-md-3 mb-0 fa-ul text-muted">
                <li class="medium"><b>Data Registro: </b>{{ bos.dataRegistro }}
                </li>
                <li class="medium">
                  <b>Envolvimento do Objeto: </b> {{ bos.envolvimentoObj }}
                </li>
              </ul>
              <ul class="col-sm-12 col-md-3 mb-0 fa-ul text-muted">
                <li class="medium">
                  <b>Providência: </b> {{ bos.providencia }}
                </li>
                <li class="medium">
                  <b>Fato: </b> {{ bos.fato }}
                </li>
              </ul>
            </div>

          </div>
        </div>
      </a>
    </div>

  </div>
</template>

<script>
import { EventBus } from '../../main.js'

export default {
  name: 'ListaBoArma',
  props: ['bos'],
  data() {
    return {
      mostrarCards: true
    }
  },
  created() {
    EventBus.$on('esconderDetalheBo', this.esconderCards)
  },
  methods: {
    esconderCards() {
      this.mostrarCards = true
    },
    emitirClickBo(id) {
      this.mostrarCards = false
      EventBus.$emit('buscarBoEscolhido', id)
    }
  }
}
</script>
