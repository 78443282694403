<template>
  <div>
    <div class="p-3" v-show="showEditarUnidade">
      <div class="overflow-editar p-2">

        <h6 for="editarUnidadeSubordinada"><b>EDITAR UNIDADE:</b></h6>


        <div class="row">
          <div class="form-group col-lg-6">
            <label for="descricao">Nome da Unidade:</label>
            <input text="text" class="form-control form-control-border border-width-2" id="descricao"
              v-model="unidade.descricao" :class="{ 'is-invalid': $v.unidade.descricao.$error, }" />
            <div v-if="!$v.unidade.descricao.required" class="invalid-feedback">Este campo é obrigatório</div>
          </div>
          <div class="form-group col-lg-6">
            <label for="sigla">Sigla da Unidade:</label>
            <input text="text" class="form-control form-control-border border-width-2" id="sigla"
              v-model="unidade.sigla" :class="{ 'is-invalid': $v.unidade.sigla.$error, }" />
            <div v-if="!$v.unidade.sigla.required" class="invalid-feedback">Este campo é obrigatório</div>
          </div>


          <div class="form-group col-lg-6">
            <label class="col-sm-12">Tipo de unidade: </label>
            <v-select class="border-v-select" id="unidadeDeOrigem"
              v-model="unidade.tipoUnidade" :options="tiposUnidade" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>

          <div class="form-group col-lg-6">
            <label class="col-sm-12">Força: </label>
            <v-select class="border-v-select" id="unidadeDeOrigem"
              v-model="unidade.forca" :options="forcas" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>

          <div class="form-group col-lg-3">
            <label for="sigla">Complemento da Unidade:</label>
            <input text="text" class="form-control form-control-border border-width-2" id="sigla" v-model="unidade.complemento" />
          </div>

          <div class="form-group col-lg-6">
            <label class="col-sm-12">Unidade Superior: </label>
            <v-select class="border-v-select" id="unidadeDeOrigem"
              v-model="unidade.unidadeSuperior" :options="unidadesSuperiores" label="descricao">
              <span slot="no-options">Desculpe, sem opções no momento.</span>
            </v-select>
          </div>

          <div class="form-group row col-lg-3">
            <label class="col-sm-8">Unidade Policial: </label>
            <div class="col-sm-4">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="unidadePolicial" id="inlineRadio1" value="true"
                  v-model="unidade.unidadePolicial" />
                <label class="form-check-label" for="inlineRadio1">Sim</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model="unidade.unidadePolicial" name="unidadePolicial"
                  id="inlineRadio2" value="false">
                <label class="form-check-label" for="inlineRadio2">Não</label>
              </div>
            </div>
          </div>
          <div class="form-group row col-lg-3">
            <label class="col-sm-8">Unidade Externa: </label>
            <div class="col-sm-4">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="unidadeExterna" id="inlineRadio1" value="true"
                  v-model="unidade.unidadeExterna" />
                <label class="form-check-label" for="inlineRadio1">Sim</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model="unidade.unidadeExterna" name="unidadeExterna"
                  id="inlineRadio2" value="false">
                <label class="form-check-label" for="inlineRadio2">Não</label>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex row justify-content-md-between col-12 mb-3 ">
          <button class="btn  background-principal text-color-principal mr-2 mb-3" v-if="!showAdicionarTelefone"
            @click.prevent="adicionarTelefone">
            <i class="fa-solid fa-phone"></i>
            Adicionar Telefone
          </button>
          <button class="btn  background-principal text-color-principal mr-2 mb-3" v-if="!showAdicionarEmail"
            @click.prevent="adicionarEmail">
            <i class="fa-solid fa-at"></i>
            Adicionar E-mail
          </button>
          <button class="btn  background-principal text-color-principal mr-2 mb-3" v-if="!showAdicionarEndereco"
            @click.prevent="adicionarEndereco">
            <i class="fa-solid fa-address-card"></i>
            Adicionar Endereço
          </button>
        </div>
        <h6 class="mt-4 mb-3 px-2"><b>TELEFONES: </b></h6>

        <div class="card">
          <div class="card-body table-responsive px-2">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th>Número do Telefone</th>
                  <th>Tipo</th>
                  <th>Número Atual</th>
                  <th>Remover Telefone</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tel in telefones" :key="tel.id">
                  <td>{{ tel.numero }}</td>
                  <td>{{ tel.tipoTelefone }}</td>


                  <td>
                    <div class="form-check">
                      <i
                        :class="tel.atual ? 'fa-solid fa-circle-check green ml-4 ' : 'fa-solid fa-circle-xmark red ml-4'"></i>
                      <input class="form-check-input ml-2" type="checkbox" value="" id="flexCheckDefault"
                        v-model="tel.atual" @click="editarNumeroAtual(tel.id)">
                    </div>
                  </td>
                  <td>
                    <button type="button" class="mx-1 btn btn-danger" @click="removerNumeroAtual(tel.id)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <h6 class="mt-4 mb-3 px-2"><b>EMAIL: </b></h6>
        <div class="card">
          <div class="card-body table-responsive px-2">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Tipo</th>
                  <th>Remover E-mail</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="eml in emails" :key="eml.id">
                  <td>{{ eml.email }}</td>
                  <td>{{ eml.tipoEmail }}</td>
                  <td>
                    <button type="button" class="mx-1 btn btn-danger" @click="removerEmailAtual(eml.id)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="p-1">

        <button @click.prevent="$emit('fecharEditarUnidade')" type="button"
          class="btn background-principal text-color-principal mx-2">
          <i class="fas fa-arrow-left"></i>
          Voltar
        </button>
        <button @click.prevent="atualizarUnidade" class="btn btn-primary float-right ">
          <i class="fa-solid fa-floppy-disk"></i>
          Salvar
        </button>
      </div>


    </div>

    <div class=" col-12">
      <FormCadastroTelefoneUnidadeSubordinada v-show="showAdicionarTelefone" @FecharAdicaoDados="fecharAdicaoDados" />
      <FormCadastroEmailUnidadeSubordinada v-show="showAdicionarEmail" @FecharAdicaoDados="fecharAdicaoDados" />
      <FormCadastroEnderecoUnidadeSubordinada v-show="showAdicionarEndereco" @FecharAdicaoDados="fecharAdicaoDados" />


    </div>
  </div>
</template>
<script>
import { EventBus } from '@/main';
import ConsultaListasService from '@/service/ConsultaListasService';
import UnidadeService from '@/service/UnidadeService';
import { required } from "vuelidate/lib/validators";
import FormCadastroEmailUnidadeSubordinada from './FormCadastroEmailUnidadeSubordinada.vue';
import FormCadastroEnderecoUnidadeSubordinada from './FormCadastroEnderecoUnidadeSubordinada.vue';
import FormCadastroTelefoneUnidadeSubordinada from './FormCadastroTelefoneUnidadeSubordinada.vue';
export default {
  name: "EditarUnidade",
  data() {
    return {
      unidade: {},
      tiposUnidade: [],
      forcas: [],
      unidadesSuperiores: [],
      telefones: [],
      emails: [],
      desabilitarCamposFormUnidade: true,
      desabilitarBotaoFechar: true,
      showEditarUnidade: true,
      showAdicionarTelefone: false,
      showAdicionarEmail: false,
      showAdicionarEndereco: false,
    };
  },
  created() {
    EventBus.$on("editarUnidadeInformacoes", (event) => {
      this.unidade = event;
    });
    EventBus.$on('editarUnidadeTelefoneInformacoes', (event) => {
      this.telefones = event;
    })
    EventBus.$on('editarUnidadeEmailInformacoes', (event) => {
      this.emails = event;
    })
    ConsultaListasService.buscarListaPorNomeTabela("tipo_unidade")
      .then((response) => {
        this.tiposUnidade = response.data;
      }).catch((e) => alert("ERRO: " + e.message));
    ConsultaListasService.buscarListaPorNomeTabela("unidade")
      .then((response) => {
        this.forcas = response.data;
      }).catch((e) => alert("ERRO: " + e.message));
    ConsultaListasService.buscarListaPorNomeTabela("unidade")
      .then((response) => {
        this.unidadesSuperiores = response.data;
      }).catch((e) => alert("ERRO: " + e.message));
    EventBus.$on("resetarFormularioUnidade", () => { this.resetarForm(), this.fecharAdicaoDados() });
  },
  methods: {
    findTelefoneById(idTelefone) {
      for (let index = 0; index < this.telefones.length; index++) {
        if (this.telefones[index].id == idTelefone) {
          return index
        }
      }
      return null
    },
    editarNumeroAtual(idTelefone) {
      let index = this.findTelefoneById(idTelefone)
      if (index != null) {
        this.telefones[index].atual = !this.telefones[index].atual
        UnidadeService.editarUnidadeTelefone(this.telefones[index], idTelefone).then((response) => {
          Toast.fire("Sucesso ao editar telefone ", "", "success");
        }).catch((e) => this.alertaFalhaEditarUnidadeTelefone());
      }
    },
    removerNumeroAtual(idTelefone) {
      UnidadeService.deletarUnidadeTelefone(idTelefone,this.unidade.id).then((response) => {
        Toast.fire("Sucesso ao remover telefone ", "", "success");
        }).catch((e) =>  Toast.fire("Falha ao remover telefone", "", "error"));
    },
    removerEmailAtual(idEmail) {
      UnidadeService.deletarUnidadeEmail(idEmail,this.unidade.id).then((response) => {
        Toast.fire("Sucesso ao remover e-mail ", "", "success");
      }).catch((e) =>  Toast.fire("Falha ao remover e-mail", "", "error"));
    },
    fecharAdicaoDados() {
      this.showEditarUnidade = true
      this.showAdicionarTelefone = false
      this.showAdicionarEmail = false
      this.showAdicionarEndereco = false
    },
    adicionarTelefone() {
      this.showEditarUnidade = false
      this.showAdicionarTelefone = true
      this.showAdicionarEmail = false
      this.showAdicionarEndereco = false

      EventBus.$emit('unidadeSelecionadaAdicaoTelefone', this.unidade.id)
    },
    adicionarEmail() {
      this.showEditarUnidade = false
      this.showAdicionarTelefone = false
      this.showAdicionarEmail = true
      this.showAdicionarEndereco = false

      EventBus.$emit('unidadeSelecionadaAdicaoEmail', this.unidade.id)
    },
    adicionarEndereco() {
      this.showEditarUnidade = false
      this.showAdicionarTelefone = false
      this.showAdicionarEmail = false
      this.showAdicionarEndereco = true

      EventBus.$emit('unidadeSelecionadaAdicaoEndereco', this.unidade.id)
    },
    resetarForm() {
      this.unidade = {};
    },
    alertaSucessoEdicao() {
      Toast.fire("Sucesso ao editar unidade", "", "success");
    },
    alertaFalhaEdicao() {
      Toast.fire("Falha ao editar unidade", "", "error");
    },
    alertaFalhaEditarUnidadeTelefone() {
      Toast.fire("Falha ao editar telefone", "", "error");
    },
    atualizarUnidade() {
      this.$v.unidade.$touch();
      if (!this.$v.unidade.$invalid) {
        const data = {
          id: this.unidade.id,
          descricao: this.unidade.descricao,
          sigla: this.unidade.sigla.toUpperCase(),
          complemento: this.unidade.complemento,
          unidadePolicial: (this.unidade.unidadePolicial === "true" ? true : false),
          unidadeExterna: (this.unidade.unidadeExterna === "true" ? true : false),
          tipoUnidade: this.unidade.tipoUnidade ? this.unidade.tipoUnidade : null,
          unidadeSuperior: { id: this.unidade.unidadeSuperior.id },
          forca: { id: this.unidade.forca ? this.unidade.forca.id : null }
        };
        UnidadeService.atualizarUnidade(data).then((response) => {
          this.alertaSucessoEdicao();
          this.desabilitarCamposFormUnidade = false;
          this.desabilitarBotaoFechar = false;
          this.$emit("fecharEditarUnidade");
          EventBus.$emit("ResultadoIdUnidadeCriada", response.data.id);
          this.resetarForm();
        }).catch((e) => {
          this.alertaFalhaEdicao;
        });
      }
    }
  },
  validations: {
    unidade: {
      descricao: {
        required,
      },
      sigla: {
        required,
      }
    },
  },
  components: { FormCadastroTelefoneUnidadeSubordinada, FormCadastroEmailUnidadeSubordinada, FormCadastroEnderecoUnidadeSubordinada }
}
</script>

<style scoped>
.green {
  color: green !important;
}

.red {
  color: red !important;
}

.overflow-editar {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
