var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-38",attrs:{"id":"modal-38","title":"Cadastro de Dependentes","data-backdrop":"static","size":"xl","cancel-disabled":"","no-close-on-backdrop":"","hide-header-close":"","header-class":"modal-header\n        background-principal\n        text-color-principal\n        card-item-result\n        ","content-class":"modal-content border-rounded-detail","dialog-class":"p-3","title-class":"modal-title mx-auto xl"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"btn btn-default float-right",on:{"click":function($event){[cancel(), _vm.apagarCampos()]}}},[_c('i',{staticClass:"fa-solid fa-x"}),_vm._v("Fechar ")])]}}])},[_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"form-group col-4"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dependente.nome),expression:"dependente.nome"}],staticClass:"form-control form-control-border",class:{
                        'is-invalid': _vm.isSaving && _vm.$v.dependente.nome.$error
                    },attrs:{"type":"text","id":"nome"},domProps:{"value":(_vm.dependente.nome)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dependente, "nome", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"cpf"}},[_vm._v("CPF")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dependente.cpf),expression:"dependente.cpf"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticClass:"form-control form-control-border",class:{
                        'is-invalid': _vm.isSaving && _vm.$v.dependente.cpf.$error
                    },attrs:{"type":"text","id":"cpf","placeholder":"XXX.XXX.XXX-XX"},domProps:{"value":(_vm.dependente.cpf)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dependente, "cpf", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4"},[_c('label',{attrs:{"for":"vinculo"}},[_vm._v("Vínculo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dependente.vinculo),expression:"dependente.vinculo"}],staticClass:"form-control form-control-border",class:{
                        'is-invalid': _vm.isSaving && _vm.$v.dependente.vinculo.$error
                    },attrs:{"type":"text","id":"vinculo"},domProps:{"value":(_vm.dependente.vinculo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dependente, "vinculo", $event.target.value)}}})])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn float-right text-color-principal background-principal ml-1",on:{"click":_vm.salvarGrupo}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Salvar ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.LimparGrupo.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-ban"}),_vm._v(" Limpar ")])])])]),_c('h6',{staticClass:"mt-4 mb-3 "},[_c('b',[_vm._v("DEPENDENTES CADASTRADOS: ")])]),_c('div',{staticClass:"table table-responsive p-0"},[_c('table',{staticClass:"table table-hover table-striped text-nowrap table-responsive-sm table-head-fixed"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Dependente")]),_c('th',[_vm._v("CPF")]),_c('th',[_vm._v("Vínculo")])])]),_c('tbody',_vm._l((_vm.dependentes),function(parente,i){return _c('tr',{key:i,staticClass:" clicavel"},[_c('td',[_vm._v(_vm._s(parente.dependente))]),_c('td',[_vm._v(_vm._s(parente.cpf))]),_c('td',[_vm._v(_vm._s(parente.vinculo))])])}),0)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }