<template>
  <aside class="main-sidebar h-100 sidebar-dark-primary sidebar-no-expand" >
    <!-- Brand Logo -->
    <a href="" class="brand-link"  >
      <router-link class="text-link" to="/">
        <img src="../assets/icones-sigma-app/sigma-logo.svg" :alt="$store.state.app.name"
          class="brand-image-xs logo-lg mx-3 elevation-3 opacity-sidebar" />
        <span class="brand-text font-weight-bold">SIGMA</span>
      </router-link>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            v-bind:src="usuario.foto ? 'data:image/jpeg;base64,' + usuario.foto : 'https://webc.ssp.ma.gov.br/imagens/elementos/icone-imagem-indisponivel2.png'"
            class="rounded-circle elevation-2 img-cut" alt="User Image" />

        </div>
        <div class="info">
          <p>
            <router-link to="meuperfil" class="d-block">{{ usuario.nome | abreviarSideBar }}</router-link>

          </p>
        </div>
      </div>

      <!-- SidebarSearch Form -->
      <div class="form-inline">
        <div class="input-group">
          <v-select class="form-control background-select form-control-sidebar select" id="inputPesquisarSidebar"
            v-model="sidebarItem" :disabled="
              sidebarItems == null ||
              sidebarItems == []
            " :options="sidebarItems" placeholder="Buscar..." label="nome" v-on:input="irParaPagina">
            <span slot="no-options">Desculpe, sem opções no momento.</span>
          </v-select>

        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          
          <li class="nav-item"
          @click="reloadPage"
          :class="this.$route.name.includes('home') ? 'menu-is-opening menu-open' : ''"
          >
            <nav-item link="/"  icon="fas fa-solid fa-house">

              Início
              <!-- <i class="fas fa-angle-left right"></i> -->
            </nav-item>
          </li>
          <li v-role="'VISITA'" class="nav-item"
            :class="this.$route.name.includes('home') ? 'menu-is-opening menu-open' : ''">
            <nav-item link="/cadastrovisita" icon="fa-solid fa-person-circle-plus">
              Visita
              <!-- <i class="fas fa-angle-left right"></i> -->
            </nav-item>
          </li>
          <li v-role="'GDOCS'" class="nav-item"
            :class="this.$route.name.includes('documentos') ? 'menu-is-opening menu-open' : ''">
            <nav-item link="/documentos" icon="fas fa-envelope">

              Documentos
              <!-- <i class="fas fa-angle-left right"></i> -->
            </nav-item>
            <!-- <ul class="nav nav-treeview">
              <li class="nav-item">
                <nav-item link="/" icon="fas fa-inbox">
                  Caixa de Entrada
                </nav-item>
              </li>
              <li class="nav-item" :class="this.$route.name.includes('novo') ? 'menu-is-opening menu-open' : ''">
                <a href="#" class="nav-link">
                  <i class="fas fa-circle-plus nav-icon"></i>
                  <p>
                    Novo
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <nav-item link="/documentosnovo" icon="fas fa-file-lines">
                      Documento
                    </nav-item>
                  </li>
                  <li class="nav-item">
                    <a href="#" class="nav-link">
                      <i class="fas fa-file-circle-check nav-icon"></i>
                      <p>Protocolo</p>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a href="pages/mailbox/read-mail.html" class="nav-link">
                  <i class="fas fa-envelope-open-text nav-icon"></i>
                  <p>Read</p>
                </a>
              </li>
            </ul>
             -->
          </li>
<!--           <li v-role="'GDOCS'" class="nav-item"
            :class="this.$route.name.includes('processos') ? 'menu-is-opening menu-open' : ''">
            <nav-item link="/processos" icon="fa fa-box-archive">
              Processos
            </nav-item>
          </li> -->
          <!-- <li v-role="'GDOCS'" class="nav-item"
            :class="this.$route.name.includes('emissor') ? 'menu-is-opening menu-open' : ''">
            <nav-item link="/emissor" icon="fas fa-id-card">
              Carteira
              <i class="fas fa-angle-left right"></i>
            </nav-item>
          </li> -->
<!--

           <li v-role="'GDOCS'" class="nav-item"
            :class="this.$route.name.includes('recursoshumanos') ? 'menu-is-opening menu-open' : ''">,,
            <i class="nav-icon fas fa-search"></i>
              <p>
                Recursos Humanos
                <i class="fas fa-angle-left right"></i>
              </p>
          </li>
         -->


          <li v-role="'RECURSOS_HUMANOS'" class="nav-item"
       
            :class="this.$route.name.includes('rh') ? 'menu-is-opening menu-open' : ''">
            <a href="" class="nav-link" >
              <i class="fa-solid fa-users-gear"></i>
              <p>
                Recursos Humanos
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" ref="menuRecursosHumanos">
              <li class="nav-item ml-3">
                <nav-item  v-role="'RECURSOS_HUMANOS'" link="/servidores" class="nav-item" icon="fas fa-users">
                  Servidor
                </nav-item>

                <nav-item v-role="'RECURSOS_HUMANOS'" link="/anotacoes" class="nav-item" icon="fa-solid fa-pen-to-square">
                  Anotações
                </nav-item>


                <nav-item  v-role="'RECURSOS_HUMANOS'" link="/boletimInformativo" class="nav-item" icon="fa-solid fa-note-sticky">
                  Boletim Informativo
                </nav-item>

                <nav-item  v-role="'RECURSOS_HUMANOS'" link="/diarias" icon="fas fa-file">
                  Diárias
                </nav-item>

                <nav-item  v-role="'RECURSOS_HUMANOS'" link="/ferias" icon="fa-sharp fa-solid fa-person-walking-luggage">
                  Férias
                </nav-item>

                <nav-item  v-role="'RECURSOS_HUMANOS'" link="/configuracoes" icon="fas fa-gear">
                  Configurações
                </nav-item>





              </li>
            </ul>
          </li>

          <!-- <li v-role="'FREQUENCIA'" class="nav-item"
            :class="this.$route.name.includes('frequencia') ? 'menu-is-opening menu-open' : ''">
            <nav-item link="/frequencia" icon="fa-solid fa-calendar">
              Frequência
            </nav-item>

             <ul class="nav nav-treeview">
              <li class="nav-item ml-3">
                <nav-item  v-role="'FREQUENCIA'" link="/relatorios" class="nav-item" icon="fa-regular fa-file-lines">
                  Relatórios
                </nav-item>
              

                
              </li>
            </ul> 
          </li>  -->


          <li v-role="'ADMINISTRACAO'" class="nav-item"
           :class="this.$route.name.includes('administracao') ? 'menu-is-opening menu-open' : ''">
            <a href="" class="nav-link">
              <i class="fa-solid fa-users-gear"></i>
              <p>
                Administração
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview  ml-3">
              <li v-role="'CADASTRO_PESSOA' && 'ADMINISTRACAO'" class="nav-item">
                <nav-item  link="/cadastropessoa" icon="fa-solid fa-user-plus">
                  Cadastrar Pessoa
                </nav-item>
              </li>

              <!-- <li v-role="'ADMINISTRACAO' && 'USUARIOS'" class="nav-item">
                <nav-item  link="/visualizacaofuncionario" icon="fa-solid fa-clipboard-user">
                  Funcionários
                </nav-item>
              </li> -->

              <li v-role="'ADMINISTRACAO' && 'UNIDADES'" class="nav-item">
                <nav-item  link="/unidade" icon="fa-solid fa-sitemap">
                  Unidades
                </nav-item>
              </li>
              <li v-role="'ADMINISTRACAO' && 'USUARIOS'" class="nav-item">
                <nav-item  link="/visualizacaousuario" icon="fa-solid fa-clipboard-user">
                  Usuários
                </nav-item>
              </li>

              <!-- <li v-role="'ADMINISTRACAO'" class="nav-item">
                <nav-item  link="/cadastronotificacoes" icon="fa-solid fa-pen-to-square">
                  Notificações Sistema
                </nav-item>
              </li> -->
            </ul>
          </li>

          <li v-role="'CONSULTAS'" class="nav-item"
            :class="this.$route.name.includes('consulta') ? 'menu-is-opening menu-open' : ''">
            <a href="" class="nav-link">
              <i class="nav-icon fas fa-search"></i>
              <p>
                Consultas
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item ml-3">
                <nav-item  v-role="'CONSULTA_INDIVIDUO'" link="/consultapessoa" icon="fas fa-users">
                  Pessoa
                </nav-item>

                <nav-item  v-role="'PESQUISAR_BO'" link="/consultabo" icon="fas fa-file">
                  Ocorrências
                </nav-item>

                <nav-item  v-role="'CONSULTA_VEICULO'" link="/consultaveiculos" icon="fas fa-car">
                  Veículos
                </nav-item>
                <nav-item  v-role="'CONSULTA_CELULAR'" link="/consultacelular" icon="fas fa-mobile">
                  Celular
                </nav-item>
                <nav-item  v-role="'CONSULTA_CHEQUE'" link="/consultacheque" icon="fas fa-id-card">
                  Cheque
                </nav-item>
                <nav-item v-role="'CONSULTA_ARMA'" link="/consultaarma" icon="fas fa-gun">
                  Arma
                </nav-item>
                <nav-item  v-role="'CONSULTA_COISA'" link="/consultacoisa" icon="fas fa-cubes">
                  Coisa
                </nav-item>
                <nav-item  v-role="'CONSULTA_DOCUMENTO'" link="/consultadocumento" icon="fas fa-address-card">
                  Documento
                </nav-item>
              </li>
            </ul>
          </li>
<!--           <li v-role="'AUDITORIA'" class="nav-item"
            :class="this.$route.name.includes('auditoria') ? 'menu-is-opening menu-open' : ''">
            <nav-item link="/auditoria" icon="fas fa-key">
              Auditoria
            </nav-item>
          </li> -->
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import PermissoesService from '../service/PermissoesService';
import NavItem from './NavItem.vue';

export default {
  components: { NavItem },
  name: "SideBar",
  computed: {
    usuario() {

      return this.$store.state.auth.user.usuario
    }
  },
  data() {
    return {
      sidebarItems: [],
      sidebarItem: null,
      items: [],
      
    }
  },
  filters: {
    abreviarSideBar: function (nomeCompleto) {


      if (nomeCompleto.length > 20) {
        let nomeSeparado = nomeCompleto.split(" ");

        if ((nomeSeparado[0] + nomeSeparado[nomeSeparado.length - 1]).length > 20) {
          return nomeSeparado[0]
        } else {
          return nomeSeparado[0] + " " + nomeSeparado[nomeSeparado.length - 1]
        }
      } else {
        return nomeCompleto
      }
    },
  },
  created() {
    PermissoesService.userPermissions()
  },
  mounted() {
    // if (localStorage.getItem('reloaded')) {
    //   // The page was just reloaded. Clear the value from local storage
    //   // so that it will reload the next time this page is visited.
    //   localStorage.removeItem('reloaded');
    // } else {
    //   // Set a flag so that we know not to reload the page twice.
    //   localStorage.setItem('reloaded', '1');
    //   location.reload();
    // }
    this.items = document.querySelectorAll(".sidebar-item-search")

    for (let item = 0; item < this.items.length; item++) {
      this.sidebarItems.push({
        nome: this.items[item].text,
        route: this.items[item].pathname
      })
    }

  },
  methods: {

    reloadPage(){
      window.location.reload();
    },
   
  
    
    irParaPagina() {
      if (this.sidebarItem !== null) {
        this.$router.push({ path: this.sidebarItem.route })
      }
    },

  }
};
</script>

<style scoped>
.opacity-sidebar {
  opacity: 0.8
}

.img-cut {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: top;
}

/* .input-group{
  background: #808080 !important;
  color: #fff !important;
}

.background-principal:hover {
  background-color: #0f192ce3 !important;
  color: #808080 !important;
} */

.text-link {
  color: #fff
}

.text-link:hover {
  color: rgba(255, 255, 255, 0.801)
}



.select {
  --vs-controls-color: #c2c7d0;
  --vs-border-color:  #0f192c;




  --vs-dropdown-bg: #0f192c;
  --vs-dropdown-color: #c2c7d0;
  --vs-dropdown-option-color: #c2c7d0;



  --vs-dropdown-option--active-bg: #4b4c4d;
  --vs-dropdown-option--active-color: #eeeeee;

  --vs-dropdown-max-height: 100px;
}

.background-select {
  background-color: #0f192c;
  color: #c2c7d0
}

.fechar-menu {
  display: none; 
}

</style>
