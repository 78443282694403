<template>

    <div class="card-body  pt-0 "   >
        
        <b-form-group
          label="Buscar"
          label-for="filter-input"
          label-cols-sm="8"
          label-align-sm="right"
          label-size="sm"
          class="mb-1"
            >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <div class=" text-center">
        <b-table  
            :items="anotacaoLista" :fields="fields"
            id="my-table"
            :per-page="perPage"
            :filter="filter"
            :current-page="currentPage"
            small
            responsive
            @row-clicked="rowClicked"
            v-b-modal.modal-12
            
            >
            <!-- //select-mode="multi" -->

            <template v-slot:cell(data_anotacao)="row">
                {{ updateZonedDateTimeField(row.item.data_anotacao) || "Sem Registro" }}
            </template>
            
        
            <!-- <template #cell(selected)="data">
                <b-form-checkbox
                
                @change="handleCheckboxChange(data, $event)"
                v-model="data.rowSelected" 
                >
                </b-form-checkbox>          
            </template> -->

            <!-- <template #cell(imprimir)="data">
                <button  type="button" style="border:none;!important" title="Imprimir Arquivo" class="btn btn-default btn-sm gerar">
                            <i class="fa-solid fa-print"></i>
                </button>
            </template> -->
        </b-table>
        
        <b-pagination
        
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            label-align-sm="center"
            
            >
        </b-pagination>
    </div>
        <p class="mt-3">Página: {{ currentPage }}</p>
        

        <div class ="card-footer" >

            <div class="float-right">
                <button v-b-modal.modal-18 id="imprimirAnotacao" type="button" class="btn btn-success "
                    ><i class="fa-solid fa-user-plus pt-1"></i>Cadastrar
                    Anotação</button>


                <!-- <button class="btn background-principal text-color-principal ml-3" data-toggle="dropdown">
                    <i class="far fa-envelope"></i> Imprimir
                </button> -->

                <!-- <button class="btn background-principal text-color-principal ml-3"  @click.prevent="enviarAnotacao()">
                    <i class="far fa-envelope"></i> Ficha Funcional
                </button>

                <button id="button" @click="ImprimirVidaFuncional()" class="btn background-principal text-color-principal ml-3">
                    <i class="far fa-envelope"></i> Vida Funcional </button> -->
                <!-- <ul class="dropdown-menu" role="menu">
                            <li><a class="dropdown-item" href="#"> Tabela de Férias </a ></li>
                            <li><a class="dropdown-item" href="#"> Aviso de Férias </a></li>
                            <li><a class="dropdown-item" href="#"> Boletim Informativo </a></li>
                            <li><a class="dropdown-item" href="#"> Ofício </a></li>
                            <li><a class="dropdown-item" href="#"> Transferências de Férias </a></li>
                </ul> -->
            </div>
        </div>
        <PDFVidaFuncional />
    </div>
    
</template>

<script>
import FiltroAnotacao from "@/components/rh/anotacao/FiltroAnotacao.vue";
import rhAnotacao from "@/service/RHAnotacaoService"
import dayjs from "dayjs";
import { DATE_FORMAT_LOCAL_DATA } from "@/util/filter";
import PDFVidaFuncional from "@/components/rh/anotacao/PDFVidaFuncional.vue";
import { EventBus } from "../../../main.js";
import DetalhesAnotacao from "@/components/rh/anotacao/DetalhesConsultaAnotacao.vue";

export default {

    components:{
        FiltroAnotacao,
        PDFVidaFuncional,
        DetalhesAnotacao
       
    },
    data(){
      return{
        loaderActive:false,
        idAnotacao:{},
        anotacaoLista:[],
        idFuncionario:{},
        anotacoes:{},
        search: "",
        perPage: 5,
        currentPage: 1,
        filter: null,
        selectMode: 'multi',
        // selected: [],
        fields: [  
        // { key: 'selected', label: 'Selecionar', sortable: false },
        { key: 'tipoanotacao', label: 'Tipo de Anotação' },
        { key: 'tipodocumento', label: 'Tipo de Documento' },
        { key: 'data_anotacao', label: 'Data de Anotação' },
        { key: 'numero_documento', label: 'Número do Documento' },
        // { key: 'imprimir', label: 'Ações' },
        
      ],
        
      
        dadosSelecionados: [],
         
       

      }

    },

    computed: {
      rows() {
        return this.anotacaoLista.length
      },
      sortOptions() {
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },


    created(){

        
        this.buscarAnotacao(this.$store.state.funcionario.idFuncionarioCadastrado);
        EventBus.$on("AtualizarTabelaAnotacao",()=>{
             this.buscarAnotacao(this.$store.state.funcionario.idFuncionarioCadastrado);

        });

        EventBus.$on("AtualizarAnotacaoAposAnotacao",()=>{
             this.buscarAnotacao(this.$store.state.funcionario.idFuncionarioCadastrado);

        });

        EventBus.$on("AtualizarCadastroAnotacao",()=>{
             this.buscarAnotacao(this.$store.state.funcionario.idFuncionarioCadastrado);

        });


    },

    
  
    methods:{


      rowClicked(item){

        rhAnotacao.getAnotacao(item.id).then(resp=>{
            this.anotacoes = resp.data;
            EventBus.$emit("consultaDetalhadaAnotacao", this.anotacoes);
            //console.log(this.anotacoes);
            //console.log(item.id);

      })
      },
        
      handleCheckboxChange(data, event) {
        
      if (data.rowSelected) {
        this.dadosSelecionados.push(data.item);
      } else {
        this.dadosSelecionados = this.dadosSelecionados.filter(
          (item) => item !== data.item
        );
      }

      event.stopPropagation();
    },
    ImprimirVidaFuncional() {

    //   this.$root.$$emit(
    //       "pdfAnotacao"
          
    //     );

    
   
      console.log('Dados das linhas selecionadas:', this.dadosSelecionados);
    },
  
         onFiltered(filteredItems) {

         this.totalRows = filteredItems.length
         this.currentPage = 1
       },


   select(){
    console.log(this.selected)
   },

    updateZonedDateTimeField(field) {
        if(field == null){

        return "Sem Registro"
        }
        else{
        return dayjs(field).format(DATE_FORMAT_LOCAL_DATA);
        }
    },




    buscarAnotacao(funcionarioId){
       
        rhAnotacao.anotacaoFuncionarioId(funcionarioId).then((resp)=>{
                this.anotacaoLista =resp.data;
                //console.log(resp.data)


            })

    },


    },

};


</script>

<style scoped>

 .card-body{
    overflow-x: hidden !important;
    overflow-y: hidden !important;
} 

 .table-responsive{
    overflow-y: hidden !important;
} 

.table-container {
  overflow-x: auto; /* Adicione uma barra de rolagem horizontal quando necessário */
  max-width: 100%; /* A tabela se ajustará ao tamanho do contêiner pai */
}



</style>
