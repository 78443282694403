
<template>
  <section class="content" v-on="isModalVisible || isModalNacionalVisible ? { click: closeModal } : {}">
    <content-header title="Consulta SIGMA" />
    <conteudo v-role="'CONSULTA_VEICULO'">
      <div class="container-fluid pt-2 px-3">
        <DetalhesVeiculosConsulta v-show="isModalVisible" @closeModalVeiculo="closeModal" />
        <DetalhesVeiculosConsultaNacional v-show="isModalNacionalVisible" @closeModalVeiculoNacional="closeModal" />
        <div class="modal-backdrop fade show" v-show="isModalVisible || isModalNacionalVisible"></div>
        <div class="row p-2">
          <div class="card-body p-0 col-md-3 col-sm-12">
            <div class="card card-primary">
              <div class="card-header background-principal">
                <h3 class="card-title">
                  {{ tituloBusca }}
                </h3>
              </div>
              <div class="card-body d-flex justify-content-center row">

                <div class="custom-control custom-radio px-3">
                  <input class="custom-control-input" type="radio" id="buscaSigma" name="tipobusca"
                    v-model="baseDaPesquisa" value="FormVeiculosConsulta" checked />
                  <label for="buscaSigma" class="custom-control-label">SIGMA</label>
                </div>
                <div class="custom-control custom-radio px-3">
                  <input class="custom-control-input" type="radio" id="buscaNacional" name="tipobusca"
                    v-model="baseDaPesquisa" value="FormVeiculosConsultaNacional" />
                  <label for="buscaNacional" class="custom-control-label">NACIONAL</label>
                </div>

              </div>
              <transition mode="out-in">
                <component :is="baseDaPesquisa"></component>
              </transition>
            </div>

          </div>
          <div class="col-md-9">
            <transition mode="out-in">
              <component :is="baseDaPesquisaResultados" @showModalVeiculo="showModal"></component>
            </transition>
          </div>
        </div>
      </div>

    </conteudo>
  </section>
</template>

<script>
import DetalhesVeiculosConsulta from '@/components/consultas/veiculos/DetalhesVeiculosConsulta.vue'
import DetalhesVeiculosConsultaNacional from '@/components/consultas/veiculos/DetalhesVeiculosConsultaNacional.vue'
import FormVeiculosConsulta from '@/components/consultas/veiculos/FormVeiculosConsulta.vue'
import FormVeiculosConsultaNacional from '@/components/consultas/veiculos/FormVeiculosConsultaNacional.vue'
import ResultadosVeiculosConsulta from '@/components/consultas/veiculos/ResultadosVeiculosConsulta.vue'
import ResultadosVeiculosConsultaNacional from '@/components/consultas/veiculos/ResultadosVeiculosConsultaNacional.vue'
import { EventBus } from '../../../main.js'
export default {
  name: 'ViewConsultaVeiculo',
  components: {
    ResultadosVeiculosConsulta,
    DetalhesVeiculosConsulta,
    FormVeiculosConsulta,
    ResultadosVeiculosConsultaNacional,
    FormVeiculosConsultaNacional,
    DetalhesVeiculosConsultaNacional
  },
  data() {
    return {
      isModalVisible: false,
      isModalNacionalVisible: false,
      baseDaPesquisa: 'FormVeiculosConsulta',
      baseDaPesquisaResultados: 'ResultadosVeiculosConsulta',
      tituloBusca: 'Buscar Veículo'
    }
  },
  methods: {
    showModal() {
      if (this.baseDaPesquisaResultados === 'ResultadosVeiculosConsulta') {
        this.isModalVisible = true
      } else {
        this.isModalNacionalVisible = true
      }
    },
    closeModal() {
      this.isModalVisible = false
      EventBus.$emit('esconderBoCardComponent')
      this.isModalNacionalVisible = false
    }
  },
  watch: {
    baseDaPesquisa(valorAtual, valorAnterior) {
      const consultaNacional = valorAtual === 'FormVeiculosConsultaNacional'
      if (consultaNacional) {
        this.baseDaPesquisaResultados = 'ResultadosVeiculosConsultaNacional'
      } else {
        this.baseDaPesquisaResultados = 'ResultadosVeiculosConsulta'
      }
    }
  }
}
</script>
