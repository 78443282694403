<template>
  <div class="card card-primary">
    <div class="background-principal card-header text-color-principal">
      <h3 class="card-title">Resultados da busca na base nacional</h3>
      <span class="text-color-principal" v-if="total">Total de resultados: {{ total }}</span>
    </div>

    <div v-if="resultados.length == 0 && semResultados" class="mx-auto my-auto sem-resultados py-2">
      <div>Sem resultados!</div>
    </div>

    <div class="table-responsive max-height-content">
      <loading :active="loaderActive" :is-full-page="fullPage" />
      <div class="ml-5 row">
        <div @click="detalharPessoa(pessoa.numeroCPF)" :key="indice" v-for="(pessoa, indice) in resultados"
          class="card card-item bg-light col-sm-11 col-md-11 col-lg-5">
          <a href="#">
            <p class="header card-item-result background-principal text-color-principal">
              {{ pessoa ? pessoa.nomeCompleto : "Não Cadastrado" }}
            </p>

            <div class="row">
              <div class="col-3 img-div-card">
                <img src="https://webc.ssp.ma.gov.br/imagens/elementos/icone-imagem-indisponivel2.png" alt="user-avatar"
                  class="img img-thumbnail mx-0 px-0 img-card-measure" />
              </div>

              <div class="col-9 py-0">
                <p class="mx-0 my-0 py-0 mt-3">
                  <b>Nome da mãe: </b>
                  {{ pessoa.nomeMae }}
                </p>
                <p class="mx-0 my-0 py-0">
                  <b>Nascimento: </b>
                  {{ pessoa.dataNascimento | formatarDataNascimento }}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../main.js";
import ConsultaService from "../../../service/ConsultaService";
import Loading from "vue-loading-overlay";
import LocalIpService from "@/service/LocalIpService";
import consultaUsuarioService from "../../../service/ConsultaUsuarioService";
import { mapState } from 'vuex';

export default {
  name: "ResultadosConsulta",
  components: {
    Loading,
  },

  data() {
    return {
      semResultados: false,
      tituloBusca: "Buscar pessoa",
      resultados: [],
      loaderActive: false,
      fullPage: false,
      total: "",
      params: {},
      ipUsuario: null
    };
  },
  computed: {
    ...mapState(['auth'])
  },

  filters: {
    abreviar: function (nomeCompleto) {
      if (nomeCompleto !== null) {
        const nomeFormatado = nomeCompleto.split(" ");
        return (
          nomeFormatado[0] + " ... " + nomeFormatado[nomeFormatado.length - 1]
        );
      } else {
        return "Não Cadastrado";
      }
    },
    formatarCPFCincoCaracteres: function (cpf) {
      if (cpf !== null) {
        const cpfFormatado = cpf.substring(3, 8);
        return "***" + cpfFormatado + "***";
      } else {
        return "Não Cadastrado";
      }
    },
    formatarDataNascimento: function (dataNascimento) {
      if (dataNascimento !== null) {
        const [ano, mes, dia] = dataNascimento.split("-");
        const dataFormatada = `${dia}/${mes}/${ano}`;
        return dataFormatada;
      } else {
        return "Não Cadastrado";
      }
    },
  },
  created() {
    LocalIpService.getLocalIp()
      .then((response) => {
        this.ipUsuario = response.data.ip;
      })
      .catch((error) => {
        console.log(error);
      });


    EventBus.$on("consultapessoaNacional", this.listener);
  },
  beforeDestroy() {
    EventBus.$off("consultapessoaNacional", this.listener);
  },
  methods: {
    listener(event) {
      this.params = event;
      this.consultarNacional();
    },
    onChangePage(resultados) {
      this.resultados = resultados;
    },
    showLoader() {
      this.resultados = [];
      this.loaderActive = true;
      this.fullPage = true;
    },
    showLoaderResultado() {
      this.loaderActive = true;
      this.fullPage = true;
    },
    hideLoader() {
      this.loaderActive = false;
      this.fullPage = false;
    },
    detalharPessoa(cpf) {

      this.showLoaderResultado();

      let login = this.auth.user.usuario.login;

      consultaUsuarioService.buscarCpfPorLoginUsuario(login).then((response) => {

        ConsultaService.buscarDetalhesPessoa(cpf, this.ipUsuario, response.data).then(
          (r) => {
            EventBus.$emit("consultaDetalhePessoaNacional", r);

            ConsultaService.buscarVeiculosPessoaBaseNacional(cpf, this.ipUsuario, response.data).then((r) => {
              EventBus.$emit("consultaVeiculosPessoaNacional", r);

              this.hideLoader();

              this.$emit("showModalPessoa");

              ConsultaService.buscarMandados(cpf, this.ipUsuario, response.data).then(
                (r) => {
                  EventBus.$emit("consultaMandadosPessoaNacional", r);
                });

            });
          });
      });
    },
    consultarNacional() {
      this.showLoader();
      this.pesquisou = false;
      try {
        // if (this.params.ipUsuario != null) {

          let login = this.auth.user.usuario.login;

          // consultaUsuarioService.buscarCpfPorLoginUsuario(login).then((response) => {

            ConsultaService.buscarPessoasNacional(this.params)
              .then((response) => {
                this.resultados = [...response.data];
                this.total = resultados.length;
                this.hideLoader();
              })
              .catch((e) => {
                this.hideLoader();
                this.semResultados = true;
              });

          // });
        // }
      } catch (e) {
        this.semResultados = true;
      } finally {
        this.mostrarLoader = false;
        this.semResultados = false;
      }
    },
  },
};
</script>

<style scoped>
.card-item:hover {
  transform: scale(1.04);
  box-shadow: 1px 0.5px 5px black;
}

.card-item {
  transition: 0.2s;
  margin: 1rem;
}
</style>
