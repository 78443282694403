<template>
  <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Cadastro de visitante" />
    <ModalConsultaPessoa :visible="isModalVisible" :fluxo="Visita" @closeModalCadastro="closeModal" />
    <!-- <div class="modal-backdrop fade show" v-show="isModalVisible"></div> -->
    <conteudo>
      <!-- <BotaoConsultaPessoa /> -->
      <TabsCadastroVisita :listaResultado="resultadoConsultaPessoaVisita"/>
    </conteudo>
  </section>
</template>

<script>
import BotaoConsultaPessoa from "@/components/visita/BotaoConsultaPessoa.vue";
import { EventBus } from "@/main";
// import ModalConsultaPessoa from "./ModalConsultaPessoaVisita.vue";
import ModalConsultaPessoa from "@/views/cadastros/pessoa/ModalConsultaPessoa.vue";
import TabsCadastroVisita from "./TabsCadastroVisita.vue";

import { mapState } from 'vuex';

export default {
  components: { TabsCadastroVisita, ModalConsultaPessoa, TabsCadastroVisita, BotaoConsultaPessoa },
  created() {
 
    EventBus.$on('resultadosBuscaPessoa', ()=>{
      this.isModalVisible = false;
    });
    EventBus.$on('abrirModalConsultaPessoa',()=>{

      this.isModalVisible = true;
    })


  },
  data() {
    return {
      isModalVisible: false,
      pessoa: {},
      visitasLista: [],
      Visita: 'Visita',

    };
  },
  computed: {
    ...mapState(['resultadosBuscaPessoaVisita']),

    resultadoConsultaPessoaVisita() {

      this.visitasLista = this.resultadosBuscaPessoaVisita;

      return this.visitasLista;
    },
  },
  methods: {
    fecharModalEPesquisar(id) {
      this.closeModal();
      //emitirId
      EventBus.$emit('consultarPessoaCompleta', id);
    },

    showModal() {
      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
    },
  }
}
</script>
