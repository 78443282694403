<template>
    <section class="content" v-on="isModalVisible ? { click: closeModal } : {}">
    <content-header title="Frequência" />
    <conteudo v-role="'FREQUENCIA'">
      <TabsFrequencia />
    </conteudo>
  </section>
</template>

<script>
import TabsFrequencia from '@/components/rh/frequencia/TabsFrequencia.vue';
export default {
  components:{
    TabsFrequencia
  },

  data() {
  return {
    isModalVisible: false,
    
  };
  },

}
</script>

<style>

</style>