<template>
  <div class="card card-primary">
    <div class="background-principal card-header text-color-principal">
      <h3 class="card-title">Resultado da Busca por Documento</h3>
      <div class="float-right" v-if="resultados.length > 0">
        <a href="#" v-on:click.prevent="page > 1 ? page-- : 1"><i
            class="fas fa-angle-left mx-2 text-color-principal"></i></a>

        Página {{ page }} / {{totalPaginas}}

        <a href="#" v-on:click.prevent="page++"><i class="fas fa-angle-right mx-2 text-color-principal"></i></a>
      </div>
      <div class="float-right" v-if="(resultados.length == 0 && page !== 0)">
        <a href="#" v-on:click.prevent="page > 1 ? page-- : 1"><i
            class="fas fa-angle-left mx-2 text-color-principal"></i></a>


      </div>

    </div>

    <Loader :active="loaderActive" message="Consultando..." />

    <div v-if="resultados.length == 0 && semResultados" class="mx-auto my-auto sem-resultados py-2">
      <div>Sem resultados para sua busca!</div>
    </div>

    <div v-if="resultados.length > 0">
      <div class="card-body px-0 d-flex flex-row">
        <div class="card-body table-responsive p-0 max-height-content">
          <div>
            <div class="row px-5 d-flex justify-content-around">
              <div :key="idObjeto" v-for="(documento, idObjeto) in resultados"
                class="card card-item bg-light col-sm-12 col-md-5" @click.prevent="showModalDetalhe"
                @click="clicandoCard(documento.idObjeto)">
                <a href="#">
                  <div>
                    <div>
                      <div>
                        <p class="header card-item-result background-principal text-color-principal">
                          {{ documento.tipo }}
                        </p>
                      </div>
                      <div class="row">

                        <div class="col-10 mx-0 my-2 py-0">
                          <div class="row">
                            <ul class="ml-4 mb-0 fa-ul text-muted">
                              <li class="medium"> <b>numero: </b> {{ documento.numero }}</li>
                            </ul>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ConsultaService from '@/service/ConsultaService.js'
import { EventBus } from '../../../main.js'
import Loader from '../../Loader.vue'
export default {
  name: 'ResultadosDocumentoConsulta',
  Components: {
    Loader
  },
  watch: {
    page(valorAtual, valorAnterior) {
      this.consultar()
    }
  },
  data() {
    return {
      semResultados: false,
      resultados: [],
      loaderActive: false,
      page: 0,
      total: '',
      params: {},
      id: ''
    }
  },
  created() {
    EventBus.$on('consultaDocumento', (event) => {
      
      this.params = event
      this.page = event.page
      this.consultar()
    })
  },

  computed:{
    totalPaginas(){

      const totalPagina = this.total/10;
      return (totalPagina !==Infinity) ? Math.ceil(totalPagina) : 0; 

      
    },
  },
  methods: {
    onChangePage(resultados) {
      this.resultados = resultados
    },
    showLoader() {
      this.resultados = []
      this.loaderActive = true
    },
    hideLoader() {
      this.loaderActive = false
    },
    consultar() {
      // eslint-disable-next-line dot-notation
      this.params['page'] = this.page

      this.showLoader()
      try {

        
          ConsultaService.buscarDocumento(this.params)
          .then((response) => {
            this.resultados = response.data.conteudo
            this.total = response.data.total
            this.hideLoader()
          })
          .catch((e) => {
            this.hideLoader()
            this.semResultados = true
          })

      } catch (e) {
        this.alertaFalhaBuscarDocumento()
        this.semResultados = true
      } finally {
        this.semResultados = false
      }
    },
    clicandoCard(id) {
      const documentoSelecionado = this.resultados.find(x => x.idObjeto === id)
      EventBus.$emit('consultaDocumentoDetalhe', documentoSelecionado)
    },
    showModalDetalhe() {
      this.$emit('showModalDocumento')
    },
    alertaFalhaBuscarDocumento() {
      Toast.fire("Falha ao buscar documento", "", "error");
    },
  },
  components: { Loader }
}

</script>
