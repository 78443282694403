<template>
    <div class="card card-primary">
        <div class="background-principal card-header text-color-principal">
            <h3 class="card-title">Diárias</h3>
            <div v-if="showPaginacao">
                <div class="float-right" v-if="resultados.length > 0">
                    <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"><i
                            class="fas fa-angle-left mx-2 text-color-principal"></i></a>
                    Página {{ page + 1 }}
                    <a href="#" v-on:click.prevent="page++"><i class="fas fa-angle-right mx-2 text-color-principal"></i></a>
                </div>
                <div class="float-right" v-if="resultados.length == 0">
                    <a href="#" v-on:click.prevent="page > 0 ? page-- : 0"><i
                            class="fas fa-angle-left mx-2 text-color-principal"></i></a>
                </div>
            </div>
        </div>
        <div v-if="resultados.length == 0 && semResultados" class="mx-auto my-auto sem-resultados py-2">
            <div>Sem resultados!</div>
        </div>
        <div v-if="resultados.length > 0"></div>
        <div class="card-body px-0 d-flex flex-row">
            <div class="card-body table-responsive p-0 overflow-hidden">
                <div class="row px-5 d-flex justify-content-around">
                    <div class="card card-item bg-light col-sm-12 col-md-5" @click="alterarDiaria()">
                        <div class="header card-item-result background-principal text-color-principal">
                            Fulano
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>RD: </b>
                                        000000
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card card-item bg-light col-sm-12 col-md-5">
                        <div class="header card-item-result background-principal text-color-principal">
                            RD: 00000
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>Nome: </b>
                                        fulano
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card card-item bg-light col-sm-12 col-md-5">
                        <div class="header card-item-result background-principal text-color-principal">
                            Fulano
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>RD: </b>
                                        000000
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card card-item bg-light col-sm-12 col-md-5">
                        <div class="header card-item-result background-principal text-color-principal">
                            RD: 00000
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>Nome: </b>
                                        fulano
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card card-item bg-light col-sm-12 col-md-5">
                        <div class="header card-item-result background-principal text-color-principal">
                            Fulano
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>RD: </b>
                                        000000
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card card-item bg-light col-sm-12 col-md-5">
                        <div class="header card-item-result background-principal text-color-principal">
                            RD: 00000
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>Nome: </b>
                                        fulano
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card card-item bg-light col-sm-12 col-md-5">
                        <div class="header card-item-result background-principal text-color-principal">
                            Fulano
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>RD: </b>
                                        000000
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card card-item bg-light col-sm-12 col-md-5">
                        <div class="header card-item-result background-principal text-color-principal">
                            RD: 00000
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>Nome: </b>
                                        fulano
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card card-item bg-light col-sm-12 col-md-5">
                        <div class="header card-item-result background-principal text-color-principal">
                            Fulano
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>RD: </b>
                                        000000
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card card-item bg-light col-sm-12 col-md-5">
                        <div class="header card-item-result background-principal text-color-principal">
                            RD: 00000
                        </div>
                        <div class="col-12 mx-0 row my-2 py-0">
                            <div class="row col-sm-12">
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>Nome: </b>
                                        fulano
                                    </li>
                                    <li class="medium">
                                        <b>Data: </b>
                                        13/05/23
                                    </li>
                                    <li class="medium">
                                        <b>Origem: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Destino: </b>
                                        Cidade - UF
                                    </li>
                                    <li class="medium">
                                        <b>Início: </b>
                                        dd/mm/aa
                                    </li>
                                    <li class="medium">
                                        <b>Fim: </b>
                                        dd/mm/aa
                                    </li>
                                </ul>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="medium">
                                        <b>QTD: </b>
                                        0
                                    </li>
                                    <li class="medium">
                                        <b>Unitário: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Total: </b>
                                        00,00
                                    </li>
                                    <li class="medium">
                                        <b>Processo: </b>
                                        0/0000
                                    </li>
                                    <li class="medium">
                                        <b>Status: </b>
                                        Aberto
                                    </li>
                                    <li class="medium">
                                        <b>Ordenador: </b>
                                        Fulano de tal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RhService from "@/service/RhDiariasService";

export default {
    name: "ListaDiaria",
    components: {},

    data() {
        return {
            resultados: [],
            semResultados: false,
        };
    },

    watch: {},

    created() {
        this.consultarTodasDiarias();
    },

    methods: {
        consultarTodasDiarias() {
            RhService.getAllDiaria().then((response) => {
                this.resultados = response.data;
            });
        },
    },
};
</script>

<style></style>
