import authHeader from "./auth/auth-header";
import instance from "./axiosConfig";

class NotificacoesService {
  buscarNotificacoes(usuarioId) {
    return instance.get(
      `/api/notificacao-service/notificacoesusuario/${usuarioId}`,
      {
        headers: authHeader(),
      }
    );
  }

  buscarRelacaoNotificacao(usuarioId, notificacaoId) {
    return instance.get(
      `/api/notificacao-service/rel-usuario-notificacoes/${usuarioId}/${notificacaoId}`,
      {
        headers: authHeader(),
      }
    );
  }

  buscarNotificacoesCriticas(usuarioId) {
    return instance.get(
      `/api/notificacao-service/notificacoescriticas/${usuarioId}`,
      {
        headers: authHeader(),
      }
    );
  }

  buscarNotificacoesAvisos(usuarioId) {
    return instance.get(
      `/api/notificacao-service/notificacoesinformacoes/${usuarioId}`,
      {
        headers: authHeader(),
      }
    );
  }

  buscarNotificacoesInformacoes(usuarioId) {
    return instance.get(
      `/api/notificacao-service/notificacoesavisos/${usuarioId}`,
      {
        headers: authHeader(),
      }
    );
  }

  atualizarStatusNotificacao(usuarioId, notificacaoId, patchAtualizacao) {
    return instance.patch(
      `/api/notificacao-service/rel-usuario-notificacoes/${usuarioId}/${notificacaoId}`,
      patchAtualizacao
    );
  }

  contagemNotificacacoes(usuarioId) {
    return instance.get(
      `/api/notificacao-service/countnotificacoes/${usuarioId}`,
      {
        headers: authHeader(),
      }
    );
  }

  contagemNotificacoeSistema(usuarioId) {
    return instance.get(
      `/api/notificacao-service/countnotificacoessistema/${usuarioId}`,
      {
        headers: authHeader(),
      }
    );
  }

  cadastrarNotificacoesSistema(notificacaoSistema) {
    return instance.post(
      "/api/notificacao-service/notificacao-sistema",
      notificacaoSistema
    );
  }

  cadastrarNotificacoesSetorial(notificacaoSetorial) {
    return instance.post(
      "/api/notificacao-service/notificacao-setorial",
      notificacaoSetorial
    );
  }
  buscarNotificacoesSistema(usuarioId) {
    return instance.get(
      `/api/notificacao-service/notificacoessistema/${usuarioId}`,
      {
        headers: authHeader(),
      }
    );
  }

  buscarNotificacoesCriticasSistema(usuarioId) {
    return instance.get(
      `/api/notificacao-service/notificacoessistemacriticas/${usuarioId}`,
      {
        headers: authHeader(),
      }
    );
  }
  cadastrarRelacaoNotificacoesSistemaUsuario(relNotificacaoUsuario) {
    return instance.post(
      "/api/notificacao-service/rel-usuario-notificacoes",
      relNotificacaoUsuario
    );
  }

  notificacoesCriadasPeloUsuario(usuarioId) {
    return instance.get(
      `/api/notificacao-service/notificacoes-criador/${usuarioId}`,
      {
        headers: authHeader(),
      }
    );
  }

  delNotificacaoForAll(notificacaoId) {
    return instance.delete(
      `/api/notificacao-service/delnotificacaoforall/${notificacaoId}`,
      {
        headers: authHeader(),
      }
    );
  }
}
export default new NotificacoesService();
