<template>
  <form>
    <div class="card-body">
      <div class="form-group">
        <label for="exampleSelectBorderWidth2">Tipo</label>
        <select v-model="tipoConsultaNacional" class="custom-select form-control-border border-width-2"
          id="exampleSelectBorderWidth2">
          <option disabled value="">Selecione</option>
          <option value="placa">Placa</option>
          <option value="renavam">Renavam</option>
          <option value="chassi">Chassi</option>
        </select>
      </div>

      <div v-if="tipoConsultaNacional == 'placa'">
        <div class="form-group">
          <label for="placaNacional">Placa</label>
          <input type="text" class="form-control form-control-border border-width-2" id="placaNacional"
            placeholder="Buscar por placa" maxlength="7" v-model="placa" />
        </div>
      </div>

      <div v-if="tipoConsultaNacional == 'renavam'">
        <div class="form-group">
          <label for="renavam">Renavam</label>
          <input type="number" class="form-control form-control-border border-width-2" id="renavam"
            placeholder="Buscar por Renavam" maxlength="11" v-model="renavam" />
        </div>
      </div>
      <div v-if="tipoConsultaNacional == 'chassi'">
        <div class="form-group">
          <label for="chassi">Chassi</label>
          <input type="text" class="form-control form-control-border border-width-2" id="chassi"
            placeholder="Buscar por Chassi" maxlength="17" v-model="chassi" />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button @click.prevent="emitirConsultaVeiculoNacional" v-on:keyup.enter="emitirConsultaVeiculoNacional"
        type="submit" class="btn col-12 background-principal text-color-principal">
        <i class="fas fa-search"></i>
        Buscar
      </button>
    </div>
  </form>
</template>

<script>
import { EventBus } from '../../../main.js'
import LocalIpService from "@/service/LocalIpService";

export default {
  name: 'FormVeiculosConsultaNacional',
  data: function () {
    return {
      tipoConsultaNacional: '',
      placa: '',
      renavam: '',
      chassi: '',
      //ipUsuario:null
    }
  },
  created(){
    // LocalIpService.getLocalIp()
    //   .then((response) => {
    //     this.ipUsuario = response.data.ip;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
  computed: {
    usuario() {

      return this.$store.state.auth.user.usuario
    }
  },
  methods: {
    mostrarAlerta() {
      Toast.fire('Preencha ao menos um campo!! ', '', 'error')
    },
      formValido() {
        if(this.tipoConsultaNacional=="placa"){
          return this.placa==""?false:true
        }else  if(this.tipoConsultaNacional=="renavam"){
          return this.renavam==""?false:true
        }else  if(this.tipoConsultaNacional=="chassi"){
          return this.chassi==""?false:true
        }
      },
      
      parametroPrincipal(){
        if(this.tipoConsultaNacional=="placa"){
          return this.placa
        }else  if(this.tipoConsultaNacional=="renavam"){
          return this.renavam
        }else  if(this.tipoConsultaNacional=="chassi"){
          return this.chassi
        }
      },

      emitirConsultaVeiculoNacional() {
      if (this.formValido()) {
        const params = {
          parametroPrincipal: this.parametroPrincipal(),
          tipoConsultaNacional: this.tipoConsultaNacional,
          idFuncionario: this.usuario.idFuncionario,
          cpfFuncionario: this.usuario.login,
          //ipUsuario:this.ipUsuario
        }
        EventBus.$emit('consultaVeiculoNacional', params) // emitir evento para o event-bus
      } else {
        this.mostrarAlerta()
      }
    }

  }
}
</script>

<style>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s;
}
</style>
