<template>
  <div>

    <form>
      <Loader :active="loaderActive" message="Alterando senha..." />
      <div class="card-body" v-if="!loaderActive">
        <div class="pb-1 d-flex justify-content-center col-12">
          <h4 class="text-color-secundary"> Redefina a senha do funcionário: </h4>
        </div>
        <div class="m-10 p-0 pb-1">
          <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
            No mínimo 8 caracteres.
          </p>
          <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
            1 letra minúscula.
          </p>
          <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
            1 letra maiúscula.
          </p>
          <p class="medium mb-0" :class="alerta ? 'text-danger' : ''">
            1 número ou 1 caracter especial ( !, @, #, $, %, &, *, _ ).
          </p>
        </div>
        <div class="form-group">
          <label for="inputNumeroInicioFolha" class="pesquisar-numero-inicio-folha">Digite sua nova senha:</label>
          <input type="password" class="form-control form-control-border border-width-2" id="novaSenha"
            autocomplete="off" placeholder="Digite a nova senha" v-model="novaSenha" required />
        </div>
        <div class="form-group">
          <label for="inputNumeroFimFolha" class="pesquisar-numero-fim-folha">Confirme sua nova senha:</label>
          <input type="password" class="form-control form-control-border border-width-2" id="inputFimFolha"
            autocomplete="off" placeholder="Digite a senha novamente" v-model="confirmeNovaSenha" required />
        </div>
      </div>

      <div class="card-footer">
        <button class="btn btn-default form-group" @click="$emit('fecharResetFuncionarioComponent')" type="reset">
          <i class="fa-solid fa-arrow-left"></i>
          Voltar
        </button>
        <button @click.prevent="resetarSenhaPrimeiroAcesso" @click="$emit('fecharResetFuncionarioComponent')"
          type="submit" class="btn background-principal text-color-principal float-right">
          <i class="fa-solid fa-lock"></i>
          Redefinir Senha
        </button>
      </div>

    </form>
  </div>


</template>

<script>
import Loader from '@/components/Loader.vue';
import { EventBus } from '@/main';
import RecuperarSenhaService from '@/service/RecuperarSenhaService';


export default {
  name: "ResetSenhaFuncionario",
  components: { Loader },
  data() {
    return {
      funcionarioDetalheReset: "",
      novaSenha: "",
      confirmeNovaSenha: "",
      alerta: false,
      resultado: "",
      loaderActive: false,
    };
  },
  created() {
    EventBus.$on('EdicaoFuncionarioDetalhe', (event) => {
      this.consultarFuncionario(event)
    })
  },

  methods: {
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    alertaSenhaDiferente() {
      // eslint-disable-next-line no-undef
      Toast.fire("Preencha os dois campos igualmente!", "", "error");
    },
    alertaSenhaErrada() {
      Toast.fire("Preencha corretamente a senha", "", "error");
    },
    alertaErroCadastrar() {
      Toast.fire("Erro ao tentar redefinir a senha", "", "error");
    },
    alertaSucessoCadastro() {
      Toast.fire("Senha cadastrada com sucesso", "", "success");
    },
    validaSenha() {
      if (this.novaSenha == this.confirmeNovaSenha) {
        if (this.novaSenha.match(/^(?=.*[0-9!@#%&*_])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#%&*_]{8,})$/)) {
          return true;
        }
        else {
          this.alertaSenhaErrada();
          this.alerta = true;
          return false;
        }
      }
      else {
        this.alertaSenhaDiferente();
        return false;
      }
    },
    consultarFuncionario(resultFuncionarioSelecionado) {
      this.funcionarioDetalheReset = resultFuncionarioSelecionado;
    },
    resetarSenhaPrimeiroAcesso() {
      if (this.validaSenha()) {
        this.showLoader;
        try {
          RecuperarSenhaService.resetarSenhaPrimeiroAcesso(this.funcionarioDetalheReset.login, this.novaSenha)
            .then((response) => {
              this.resultado = response.data;
              this.hideLoader
              this.alertaSucessoCadastro();
            }).catch((e) => {
              this.hideLoader();
              this.alertaErroCadastrar();
            });
        }
        catch (e) {
          this.hideLoader
          alert("ERRO: " + e.message);
        }
      }
    }
  }
}
</script>
