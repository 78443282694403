<template>
<div class="container-fluid">
      <div class="card card-primary card-tabs "> 
             <content-header title="Anotações Servidor" />
             <div class="card-header background-principal p-0 pt-1">

                <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">

                     <li class="nav-item " @click="reRender()">
                        <a class="nav-link"
                        id="custom-tabs-one-perfil-tab"   data-toggle="pill" href="#cadastrotipoanotacao" role="tab" 
                        aria-controls="custom-tabs-one-profile" aria-selected="false" @click="reRender()" ref="cadastrotipoanotacao"
                        > Anotações </a>
                     </li>
                    
                </ul>

              </div>
            <div class="card-body p-2 pt-3">
                <div class="tab-content" id="custom-tabs-one-tabContent" >
                    <div class="tab-pane fade active show" id="cadastrotipoanotacao" role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab" >
                        <TipoAnotacao  />
                    </div>

                    <!-- <ResultadosConsulta/> -->
                    <DetalhesConsultaAnotacao />
                    <EditarAnotacao/>  
                   
               
                </div>
            </div>
      </div>
</div>
    
</template>

<script>

import TipoAnotacao from "@/components/rh/anotacao/ListaConsultaAnotacao.vue";
//import ResultadosConsulta from "@/components/rh/anotacao/ResultadoConsultaAnotacao.vue"
import DetalhesConsultaAnotacao from "@/components/rh/anotacao/DetalhesConsultaAnotacao.vue"
import { EventBus } from "@/main";
import EditarAnotacao from "@/components/rh/anotacao/EditarAnotacao.vue";
export default {
  components: {
    TipoAnotacao,
    //ResultadosConsulta,
    DetalhesConsultaAnotacao,
    EditarAnotacao,
    
  },
  data() {
    return {
      //isModalVisible: false
    };
  },

//  created(){

//     EventBus.$on("EditarAnotacao", ()=>{
//         this.$refs.editarAnotacao.click()
//     })


//   },

  methods:{


   
    // showModal() {
    //   this.isModalVisible = true;
    // },
    // closeModal() {
    //   this.isModalVisible = false;
    // },
   
  }
}
</script>