<template>
  <div>
    <h4 for="editarUnidadeSubordinada"><b>TELEFONE DA UNIDADE:</b></h4>
    <form class="row">
      <div class="form-group col-lg-3 col-sm-12">
        <label for="nome">Número do telefone</label>
        <input type="text" class="form-control form-control-border border-width-2" id="numerocontato"
          v-model="telefone.numero" v-mask="'(##) #####-####'" />

      </div>

      <div class="form-group col-lg-3 col-sm-12">
        <label for="tipodocumento">Tipo do Telefone</label>
        <v-select class="border-v-select" id="tipodocumento"
          v-model="telefone.tipoTelefone" :options="['PESSOAL', 'COMERCIAL', 'CASA', 'RECADO']" label="descricao">
          <span slot="no-options">Desculpe, sem opções no momento.</span>
        </v-select>
      </div>
      <div class="form-group col-lg-3 col-sm-12">
        <label for="nome">Receber recados</label>
        <div class="form-check">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="telefoneRecado" id="inlineRadio1" value="true"
              v-model="telefone.recado" />
            <label class="form-check-label" for="inlineRadio1">Sim</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="telefone.recado" name="telefoneRecado"
              id="inlineRadio2" value="false" checked>
            <label class="form-check-label" for="inlineRadio2">Não</label>
          </div>
        </div>
      </div>
      <div class="form-group col-lg-3 col-sm-12">
        <label for="nome">Número de contato do WhatsApp</label>
        <div class="form-check">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="telefoneWhatsapp" id="inlineRadio1" value="true"
              v-model="telefone.whatsapp" />
            <label class="form-check-label" for="inlineRadio1">Sim</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="telefone.whatsapp" name="telefoneWhatsapp"
              id="inlineRadio2" value="false" checked>
            <label class="form-check-label" for="inlineRadio2">Não</label>
          </div>
        </div>
      </div>
      <div class="form-group col-lg-3 col-sm-12">
        <label for="nome">Número atual</label>
        <div class="form-check">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="numeroAtual" id="inlineRadio1" value="true"
              v-model="telefone.atual" />
            <label class="form-check-label" for="inlineRadio1">Sim</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="telefone.atual" name="numeroAtual" id="inlineRadio2"
              value="false" checked>
            <label class="form-check-label" for="inlineRadio2">Não</label>
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <button class="btn background-principal text-color-principal"
          @click.prevent="$emit('FecharAdicaoDados'), $emit('voltarParaUnidadeDetalhe')">
          <i class="fas fa-arrow-left"></i>
          Voltar
        </button>
        <button class="btn btn-primary float-right" @click.prevent="salvarTelefoneUnidade">
          <i class="fa-solid fa-floppy-disk"></i> Salvar
        </button>
      </div>

    </form>
  </div>
</template>
<script>
import { EventBus } from '@/main';
import UnidadeService from '@/service/UnidadeService';

export default {
  name: 'FormCadastroTelefoneUnidadeSubordinada',
  data() {
    return {
      idUnidade: '',

      telefone: {},
    }
  },
  created() {
    EventBus.$on('ResultadoIdUnidadeCriada', (event) => {
      this.idUnidade = event
    });

    EventBus.$on('unidadeSelecionadaAdicaoTelefone', (event) => {
      this.idUnidade = event
    })

    EventBus.$on('resetarFormularioUnidade', (event) => { this.resetarForm() })
  },
  methods: {
    resetarForm() {
      this.telefone = {}
    },
    alertaSucessoCadastroTelefone() {
      Toast.fire("Sucesso ao cadastrar telefone da unidade", "", "success");
    },
    alertaFalhaCadastroTelefone() {
      Toast.fire("Falha ao cadastrar telefone da  unidade", "", "error");
    },
    salvarTelefoneUnidade() {
      const data = {
        idUnidade: this.idUnidade,
        numero: this.telefone.numero,
        recado: (this.telefone.recado == 'true' ? true : false),
        tipoTelefone: (this.telefone.tipoTelefone ? this.telefone.tipoTelefone : null),
        whatsapp: (this.telefone.whatsapp == 'true' ? true : false),
        atual: (this.telefone.atual == 'true' ? true : false),
      }

      UnidadeService.cadastrarUnidadeTelefone(data).then((response) => {
        this.alertaSucessoCadastroTelefone();
        this.$emit('esconderFormTelefone')
        this.telefone = {}
        this.$emit('FecharAdicaoDados')

      }).catch((e) => {
        this.alertaFalhaCadastroTelefone();
      })
    },
  }
}
</script>
